angular.module('services').factory "OneTimePopupModal",
  ($q, $location, $modal, IcnIdentity, OneTimePopups) ->

    displayPopup = (popup) ->
      defer = $q.defer()
      opts =
        templateUrl: "dialogs/one_time_popup_dialog/one_time_popup_dialog.html"
        controller: "OneTimePopupDialogCtrl"
        windowClass: "one-time-popup-modal"
        resolve:
          oneTimePopup: -> popup

      $modal.open(opts).result.catch ((result) ->
        OneTimePopups.markAsRead(result).then(->
          defer.resolve()
        , (response) ->
          defer.reject(response)
        )
      )

      defer.promise

    hasPopupsToShow = ->
      notLogoutPath = $location.path() != '/logout'
      termsAcceptedOrNonUser = IcnIdentity.user.active_main_site_terms_accepted ||
        IcnIdentity.user.active_wlp_terms_accepted ||
        IcnIdentity.user.non_user

      notLogoutPath &&
      IcnIdentity.user &&
      IcnIdentity.user.accredited &&
      termsAcceptedOrNonUser

    show = ->
      defer = $q.defer()

      if !hasPopupsToShow()
        defer.resolve()
      else
        OneTimePopups.mostRecent().then (data) ->
          oneTimePopups = data.one_time_popups

          if oneTimePopups.length
            popup_promises = []

            for popup in oneTimePopups.slice(0).reverse()
              promise = displayPopup(popup)
              popup_promises.push(promise)

            $q.all(popup_promises).then(->
              defer.resolve()
            ).catch((response) ->
              defer.reject(response)
            )
          else
            defer.resolve()
        , (response) ->
          defer.reject(response)

      defer.promise

    { show: show }
