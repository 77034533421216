angular.module 'icn.paf_admin'
  .directive 'redemptionList', ($location, PafAdminNavSvc, FundSelectorService, $routeParams, Redemptions, IcnIdentity) ->
    restrict: 'E'
    templateUrl: 'areas/paf_admin/tabs/common/redemptions/pages/' +
      'dashboard/components/redemption_list/redemption_list.html'
    link: (scope, element, attrs) ->
      scope.enableRedemptions = IcnIdentity.enableRedemptions()
      scope.fundType = $routeParams.fund_type
      scope.group = $location.search().group || $routeParams.group
      scope.fundId = $location.search().private_access_fund_id || $routeParams.private_access_fund_id
      scope.minDate = $location.search().minDate || $routeParams.minDate
      scope.maxDate = $location.search().maxDate || $routeParams.maxDate

      pafAdminUse = (IcnIdentity.isPafAdmin() && !scope.fundId && !!scope.group)
      normalUse = scope.fundId

      if scope.enableRedemptions && IcnIdentity.canLoadRedemptions() && (pafAdminUse || normalUse)
        Redemptions.dashboard(scope.group, scope.fundId, scope.fundType,
          scope.minDate, scope.maxDate).then (response) ->
            scope.activeRedemptions = _.sortBy(response, 'value_date')
            scope.dashboardData = _.map(_.groupBy(scope.activeRedemptions,
              (redemption) ->  redemption['fund_id'] + redemption['value_date'] ),(redemptions) ->
                _.reduce redemptions, (sub_total, redemption) ->
                  {
                    paf_id: redemption['fund_id'],
                    paf_name: redemption['fund_name'],
                    nav_value_date: redemption['value_date'],
                    num_withdrawals: sub_total['num_withdrawals'] + 1,
                    total_withdrawals: sub_total['total_withdrawals'] + redemption['redemption_request'],
                    net_wired: sub_total['net_wired'] + redemption['net_wired'],
                    total_held_back: sub_total['total_held_back'] + redemption['hold_back_amount']
                  }
                ,
                  {
                    num_withdrawals: 0,
                    total_withdrawals: 0.0,
                    net_wired: 0.0,
                    total_held_back: 0.0
                  }
            )

            scope.calculateTotal()

      scope.calculateTotal = ->
        scope.totals = {
          numWithdrawals: total('num_withdrawals'),
          totalWithdrawals: total('total_withdrawals'),
          netWired: total('net_wired')
          totalHeldBack: total('total_held_back')
        }

      total = (attr) ->
        _.reduce(scope.dashboardData, (sum, row) ->
          sum + row[attr]
        , 0
        )

      scope.advanceToRedemptions = (fund_id) ->
        $location.path(
          "/paf_admin/#{scope.fundType}/redemptions/#{fund_id}"
        ).search(minDate: $routeParams.minDate, maxDate: $routeParams.maxDate, group: $routeParams.group)
