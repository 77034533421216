angular.module('controllers').controller "cashOverCommittedExportCtrl",
  ($scope, $modalInstance, header, confirmLabel, cancelLabel, onSelectedItem, CashOverCommittedService) ->

    $scope.header = header
    $scope.confirmLabel = confirmLabel
    $scope.cancelLabel = cancelLabel
    $scope.onSelectedItem = onSelectedItem

    $scope.availableMonths = []
    $scope.availableYears = []
    $scope.dateCombination = []
    $scope.selectableMonths = []
    $scope.selectableYears = []
    $scope.selectablePartners = []

    $scope.selectedFields =  {
      month: '',
      year: '',
      filename: '',
      partners: '',
    }

    getReportDates = ->
      CashOverCommittedService.getValidDates().then (response) ->
        for period in response.dates
          pushYearToArray(period.year)
          pushMonthToArray(period.month)
          pushDateCombination(period.year, period.month)
      $scope.selectableMonths = $scope.availableMonths
      $scope.selectableYears = $scope.availableYears

    getReportPartners = ->
      CashOverCommittedService.getValidPartners().then (response) ->
        $scope.selectablePartners = response['partners']

    pushYearToArray = (year) ->
      if $scope.availableYears.length == 0
        $scope.availableYears.push({name: year, id: year})
        return
      unless year in $scope.availableYears.map((e) -> e.name)
        $scope.availableYears.push({name: year, id: year})

    pushMonthToArray = (month) ->
      month_name = moment().month(month - 1).format('MMMM')
      if $scope.availableMonths.length == 0
        $scope.availableMonths.push({name: month_name, id: month})
        return
      unless month_name in $scope.availableMonths.map((e) -> e.name)
        $scope.availableMonths.push({name: month_name, id: month})

    pushDateCombination = (year, month) ->
      $scope.dateCombination.push({year: year, month: month})

    $scope.onYearChange = ->
      months = $scope.dateCombination.filter((e) -> e.year == $scope.selectedFields.year).map((e) -> e.month)
      $scope.selectableMonths = $scope.availableMonths.filter((e) -> e.id in months)

    $scope.onMonthChange = ->
      years = $scope.dateCombination.filter((e) -> e.month == $scope.selectedFields.month).map((e) -> e.year)
      $scope.selectableYears = $scope.availableYears.filter((e) -> e.id in years)

    $scope.validSelection = ->
      return !!$scope.selectedFields.year && !!$scope.selectedFields.month && !!$scope.selectedFields.partners

    $scope.cancel = ->
      $modalInstance.dismiss "no"

    $scope.submit = (selectedFields) ->
      year = $scope.selectedFields.year
      month = $scope.selectedFields.month
      day = new Date(year, month, 0).getDate()
      $scope.selectedFields.closeDate = moment("#{year}-#{month}-#{day}").format("MM/DD/YYYY")
      $modalInstance.close(selectedFields)

    $scope.selectAllPartners = ->
      $scope.selectedFields.partners = $scope.selectablePartners.map((record) -> record.id)

    $scope.deselectAllPartners = ->
      $scope.selectedFields.partners = []

    init = ->
      getReportDates()
      getReportPartners()
    init()
