angular.module "icn.paf_admin.components"
  .directive 'icnPafAdminNav', ->
    restrict: 'E'
    templateUrl: 'areas/paf_admin/components/nav/nav.html'
    scope:
      type: "@"
    controller: ($scope, $location, RoutesUtilService, PafAdminNavSvc, $routeParams, IcnIdentity, Menu) ->
      PafAdminNavSvc.selectedFundId = $routeParams.private_access_fund_id
      PafAdminNavSvc.currentPath = $location.path()

      $scope.navItems = []
      $scope.isPafApprover = IcnIdentity.isPafApprover()
      $scope.adminPE = IcnIdentity.canAdministerPeFunds()
      $scope.adminHedge = IcnIdentity.canAdministerHedgeFunds()

      if !$scope.adminPE
        $scope.navHeader = 'Hedge Fund Administration'
      else
        $scope.navHeader = 'PE Fund Administration'

      $scope.$watch('type', (newVal, oldVal) ->
        if oldVal && oldVal != newVal
          if newVal == 'pe'
            if RoutesUtilService.onHedgeFundsPage()
              $location.path('/paf_admin/pe/funds')
            else
              $location.url('/paf_admin/pe/redemptions_dashboard')
          else if newVal == 'hf'
            if RoutesUtilService.onPrivateEquityFundsPage()
              $location.path('/paf_admin/hf/funds')
            else
              $location.url('/paf_admin/hf/redemptions_dashboard')
      )

      init = ->
        if ($scope.type == 'pe' || $scope.type == 'hf')
          Menu.fund_administration_menu($scope.type).then (res) ->
            angular_items = _.filter(res, (opt) -> !opt.new)
            $scope.navItems = _.map(angular_items, (opt) ->
              entry = { label: opt.label, path: opt.url, active: PafAdminNavSvc.isActive(opt.url) }
              if opt.react_page
                entry.target = '_self'

              return entry
            )
        else
          $scope.navItems = PafAdminNavSvc.navItems($scope.type)

      init()
