angular.module('directives').directive 'icnSelect', ($modal) ->
  restrict: 'E'
  templateUrl: 'directives/icn_select/icn_select.html'
  require: "ngModel"
  scope:
    name: '@'
    label: '@'
    options: '='
    model: '=ngModel'
    optionId: '@'
    optionLabel: '@'
    onChange: '&'
    disabled: '=ngDisabled'
    dropdownHover: '@'
    buttonHover: '@'
    defaultValue: '@'
    hide: '='
    width: '@'
    hideCaret: '='

  controller: ($scope, $element) ->

    $(document).click (event) ->
      isClickedElementChildOfDropdown = $element.find(event.target).length > 0
      return if isClickedElementChildOfDropdown
      $scope.$apply(-> $scope.dropdownClass = '')

    $scope.onClick = (event) ->
      toggleDropdownStatus()

    $scope.getLabel = (item) ->
      if $scope.optionLabel
        return item[$scope.optionLabel]
      else
        return item

    $scope.getDropdownTitle = (item) ->
      if $scope.dropdownHover == 'true'
        return item
      else
        return null

    $scope.getButtonTitle = ->
      if $scope.buttonHover == 'true'
        return $scope.selectedLabel
      else
        return null

    $scope.getObjectId = (item) ->
      return unless typeof item == "object"

      return item[$scope.optionId] if $scope.optionId

      item.id

    $scope.setValue = (item) ->
      return if item == undefined
      if typeof item == 'string' && $scope.options && typeof $scope.options[0] == 'object'
        itemSearch = _.findWhere($scope.options, {"#{$scope.optionId}": item})
      else
        itemSearch = item
      if itemSearch == undefined
        itemSearch = angular.fromJson(item)
      if $scope.optionId
        value = itemSearch[$scope.optionId]
      else
        value = itemSearch
      $scope.model = value
      $scope.selectedLabel = $scope.getLabel(item)

      if $scope.onChange
        $scope.onChange({object: value})

      $scope.dropdownClass = ''

    $scope.hideItem = (item) ->
      return false unless $scope.hide

      data = $scope.hide
      searchResult = _.find(data, (element) ->
        element == item
      )

      !!searchResult

    defineValues = ->
      if angular.isDefined($scope.model) && $scope.model != ''
        $scope.setValue($scope.model)
      else if $scope.defaultValue
        $scope.setValue($scope.defaultValue)

    defineValues()

    $scope.$watch('options', (newVal) ->
      if newVal
        defineValues()
    )

    $scope.$watch('model', (item) ->
      return if item == undefined
      if typeof item == 'string' && $scope.options && typeof $scope.options[0] == 'object'
        item = _.findWhere($scope.options, {"#{$scope.optionId}": item})
      if $scope.optionId
        value = item[$scope.optionId]
      else
        value = item
      $scope.selectedLabel = $scope.getLabel(item)
    )

    toggleDropdownStatus = ->
      $scope.dropdownClass = if $scope.dropdownClass == 'open' then '' else 'open'
