angular.module('services').factory "geoRegions", ($resource, $q, $filter) ->
  fields = [
    "id"
    "name"
  ]
  resource = $resource("/api/v1/icn_geo_regions/:id.json",
    id: "@id"
    "fields[]": fields
  ,
    query:
      method: "GET"
      isArray: true
      cache: true
  )
  resource.mapForSearch = ->
    dfd = $q.defer()
    resource.query().$promise.then (d) ->
      regions = _.map(d, (obj, index) ->
        obj.route = "icn_geo_regions"
        obj
      )
      dfd.resolve regions
      return

    dfd.promise

  resource

