angular.module('services').factory "Delegation", ($resource, $q) ->
  resource = $resource("/api/v1/delegation/:id", { id: "@id" }
    query:
      method: "GET"
  )

  getDelegates = (user_id) ->
    resource.query({user_id: user_id}).$promise

  getDelegators = ->
    resource.query({delegators: true}).$promise

  {
    getDelegators: getDelegators
    getDelegates: getDelegates
  }
