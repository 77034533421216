angular.module('services').factory "InvestorAcknowledgementModal",
  ($q, $modal, Session, IcnIdentity) ->

    isModalOpen = false

    needsToInvestorAcknowledge = ->
      IcnIdentity.user &&
      !IcnIdentity.is_imposter &&
      IcnIdentity.user.user_acknowledgements.investor &&
      !isModalOpen

    show = ->
      defer = $q.defer()

      if !needsToInvestorAcknowledge()
        defer.resolve()
      else
        isModalOpen = true

        opts =
          templateUrl: "dialogs/investor_acknowledgement_dialog/investor_acknowledgement_dialog.html"
          controller: "InvestorAcknowledgementCtrl"
          windowClass: "large-modal"

        $modal.open(opts).result.then((result) ->
          defer.resolve(result)
        ).catch((result) ->
          opts =
            templateUrl: "dialogs/locked_account_dialog/locked_account_dialog.html"
            controller: "LockedAccountCtrl"
            windowClass: "small-modal"

          $modal.open(opts).result.then((result) ->
            defer.resolve(result)
          ).catch ((result) -> Session.logout('/login'))
        )

      defer.promise

    { show: show }
