angular.module('services').factory "PlacementFeeRangeSelector", ->
  isInsideFeeRange = (feeRange, amount) ->
    commitment_range_end =
      feeRange.commitment_range_end? && feeRange.commitment_range_end || Infinity
    amount >= feeRange.commitment_range_start && amount <= commitment_range_end

  feeRangeForCommitment = (feeRanges, amount) ->
    _.find feeRanges, (feeRange) -> isInsideFeeRange(feeRange, amount)

  hasPlacementFee = (investment) ->
    !!investment.firm_fee_agreement?.placement_fee_type

  forAmount = (investment, amount) ->
    feeRangeForCommitment(investment.firm_fee_agreement?.placement_fee_ranges, amount)

  return {
    hasPlacementFee: hasPlacementFee,
    forAmount: forAmount
  }
