angular.module('controllers').controller 'UserManagementReferralCodesCtrl', ($scope, $modal, $routeParams, $location, Users, ReferralCode) ->

  $scope.getUser = ->
    Users.find($routeParams.user_id).then (response) ->
      $scope.user = response.user
      $scope.userName = response.user.user_name
      $scope.referralCodes = response.user.referral_codes
      $scope.currentPrimary = _.find($scope.referralCodes, (code) -> code.primary == true)
      $scope.currentPrimaryId = $scope.currentPrimary.id if $scope.currentPrimary

  $scope.unassignCode = (referralCode) ->
    ReferralCode.unassignReferralCode(referralCode.id, $scope.user.id).$promise.then (response) ->
      $scope.referralCodes = _.reject($scope.referralCodes, (code) -> code.id == referralCode.id)
      $scope.message = {msgs: ["Referral code removed from user."], cssClass: 'alert-success'}

  $scope.makePrimary = (referralCode) ->
    ReferralCode.makePrimary(referralCode.id, $scope.user.id, $scope.currentPrimaryId).$promise.then (response) ->
      $scope.message = {msgs: ["Primary referral code updated."], cssClass: 'alert-success'}

  $scope.openAssignReferralCodeDialog = ->
    $modal.open(
          templateUrl: "areas/user_management/assign_referral_code_dialog.html"
          controller: "assignReferralCodeCtrl"
          windowClass: "small-modal"
          resolve:
            user: -> $scope.user
            currentPrimaryId: -> $scope.currentPrimaryId
        ).result.then (response) ->
            if response.status == 200
              $scope.referralCodes.push(response.referral_code)
              $scope.message = {msgs: ["Referral code assigned to user."], cssClass: 'alert-success'}
            else
              $scope.message = {msgs: response.data.errors.messages, cssClass: 'alert-danger'}

  $scope.returnToListing = ->
    $location.path('/user_management')

  init = ->
    $scope.getUser()
    $scope.message = {}

  init()
