angular.module('controllers').controller "OneTimePopupDialogCtrl",
  ($scope, $modalInstance, ModalDialogInstancesObserver, $window,
  Users, Session, oneTimePopup, $sce) ->
    $scope.oneTimePopup = oneTimePopup
    $scope.publishDate = moment(oneTimePopup.publish_date).format('MMMM D, YYYY')
    $scope.modalTitle = $sce.trustAsHtml(oneTimePopup.title)
    $scope.modalBody = $sce.trustAsHtml(oneTimePopup.body)
    # dismiss modal dialog box when navigate to other page
    ModalDialogInstancesObserver.setModalDialogInstance($modalInstance)

    $scope.accept = -> $modalInstance.dismiss($scope.oneTimePopup)

    $scope.$on('modal.closing', (event, reason) ->
      event.preventDefault() if reason == 'cancel'
      if reason is 'backdrop click' or reason is 'escape key press'
        event.preventDefault()
        $modalInstance.dismiss($scope.oneTimePopup)
    )
