angular.module('services').factory "UserSubscriptionReviewViews", ($resource) ->
  resource = $resource("/api/v1/user_subscription_review_views/:id", id: '@id',
    create:
      url: '/api/v1/user_subscription_review_views'
      method: 'POST'

    update:
      method: 'PATCH'

    index:
      url: '/api/v1/user_subscription_review_views'
      method: 'GET'
  )

  create = (data) ->
    resource.create(data).$promise

  update = (id, data) ->
    resource.update(id, data).$promise

  index = (pafId) ->
    resource.index(pafId).$promise

  {
    create: create
    update: update
    index: index
  }
