angular.module('directives').directive "currency", ->
  restrict: "A"
  require: "ngModel"
  link: (scope, element, attrs, ctrl) ->
    ctrl.$parsers.unshift (inputValue) ->
      inputVal = element.val()
      #clearing left side zeros
      inputVal = inputVal.substr(1)  while inputVal.charAt(0) is "0"
      inputVal = inputVal.replace(/[^\d.]/g, "")
      point = inputVal.indexOf(".")
      inputVal = inputVal.slice(0, point + 3)  if point >= 0
      decimalSplit = inputVal.split(".")
      intPart = decimalSplit[0]
      decPart = decimalSplit[1]
      if decPart is undefined
        decPart = ""
      else
        decPart = "." + decPart
      res = intPart + decPart
      res
