angular.module('services').factory 'TransferAmountService', (TransferService) ->
  svc = {

    ZeroValue: 0.0

    Totals: null

    FullTransferPercentage: 1.0

    RoundUnits: 4
    RoundPercentage: 2
    RoundAmount: 2

    Amount: 'amount'
    Percent: 'percent'
    Units: 'units'

    init: (totalAmount, totalUnits) ->
      svc.Totals = {
        amount: totalAmount,
        percent: svc.FullTransferPercentage,
        units: totalUnits
      }

    calculateAmount: (calculationType, transfer) ->
      if calculationType == svc.Amount
        return unless svc.canCalculateByAmount(transfer)
        amount = transfer.transfer_amount
      else if calculationType == svc.Percent
        return unless svc.canCalculateByPercent(transfer)
        amount = transfer.percentage
      else if calculationType == svc.Units
        return unless svc.canCalculateByUnits(transfer)
        amount = transfer.units

      TransferService.calculateAmount({
        amount: amount,
        calculation_type: calculationType,
        total_investment_amount: transfer.investment_amount,
        total_units: svc.Totals.units,
      }).then (results) ->
        svc.updateTransferFromResults(transfer, results)
        svc.calculateTransfereesAmounts(transfer)
      .catch (data, status) ->
        console.error("calculateAmount", status, data)

    calculateTransfereesAmounts: (transfer) ->
      _.each transfer.transferees, (transferee) ->
        if transfer.calculation_type == svc.Amount
          svc.calculateTransfereeAmount(transfer, transferee)
        else if transfer.calculation_type == svc.Percent
          svc.calculateTransfereePercentage(transfer, transferee)
        else if transfer.calculation_type == svc.Units
          svc.calculateTransfereeUnits(transfer, transferee)
        else
          console.error("calculateAmount: Unknown calculation type.")

    updateTransferFromResults: (transfer, results) ->
      transfer.percentage = results.percentage
      transfer.transfer_amount = results.amount
      transfer.units = results.units

    updateTransfereeFromResults: (transferee, results) ->
      transferee.percentage = results.percentage
      transferee.amount = results.amount
      transferee.units = results.units

    canCalculateByAmount: (transfer) ->
      if transfer.transfer_amount == svc.ZeroValue || transfer.investment_amount == svc.ZeroValue
        svc.resetToZeroTransfer(transfer)
        return false
      return true

    canCalculateByPercent: (transfer) ->
      if transfer.percentage == undefined
        transfer.percentage = transfer.totals.percent
        return false
      if transfer.percentage == svc.ZeroValue
        svc.resetToZeroTransfer(transfer)
      else
        return true
      return false

    canCalculateByUnits: (transfer) ->
      if transfer.units == undefined
        transfer.units = transfer.totals.units
        return false
      if svc.Totals.units == svc.ZeroValue || transfer.units == svc.ZeroValue
        return svc.resetToZeroTransfer(transfer)
      else
        return true
      return false

    resetToZeroTransfer: (transfer) ->
      transfer.percentage = svc.ZeroValue
      transfer.transfer_amount = svc.ZeroValue
      transfer.units = svc.ZeroValue

    resetToFullTransfer: (transfer) ->
      transfer.percentage = svc.Totals.percent
      transfer.transfer_amount = svc.Totals.amount
      transfer.units = svc.Totals.units

    splitAmount: (transfer) ->
      size = transfer.transferees.length
      percentage = 1 / size
      amount = transfer.transfer_amount / size
      units = parseFloat((transfer.units / size).toFixed(svc.RoundUnits), 10)
      _.each transfer.transferees, (transferee) ->
        transferee.percentage = percentage
        transferee.amount = amount
        transferee.units = units
      svc.updateTotals(transfer)

    calculateTransfereePercentage: (transfer, transferee) ->
      return unless transfer.calculation_type == svc.Percent
      sumPercentages = _.reduce(transfer.transferees, (sum, transferee) ->
        sum + transferee.percentage
      , svc.ZeroValue)
      limit = 1 - (sumPercentages - transferee.percentage)
      transferee.percentage = Math.min(limit, transferee.percentage)
      TransferService.calculateAmount({
        amount: transferee.percentage,
        calculation_type: transfer.calculation_type,
        total_investment_amount: transfer.transfer_amount,
        total_units: transfer.units
      }).then (results) ->
        svc.updateTransfereeFromResults(transferee, results)
        svc.updateTotals(transfer)
      .catch (data, status) ->
        console.error("calculateTransfereePercentage", status, data)

    calculateTransfereeUnits: (transfer, transferee) ->
      return unless transfer.calculation_type == svc.Units
      if transfer.transferees.length == 1
        transferee.units = transfer.units
      else
        sumUnits = _.reduce(transfer.transferees, (sum, transferee) ->
          sum + transferee.units
        , svc.ZeroValue)
        limit = transfer.units - (sumUnits - transferee.units)
        transferee.units = Math.min(limit, transferee.units)
      TransferService.calculateAmount({
        amount: transferee.units,
        calculation_type: transfer.calculation_type,
        total_investment_amount: transfer.transfer_amount,
        total_units: transfer.units
      }).then (results) ->
        svc.updateTransfereeFromResults(transferee, results)
        svc.updateTotals(transfer)
      .catch (data, status) ->
        console.error("calculateTransfereeUnits", status, data)

    calculateTransfereeAmount: (transfer, transferee) ->
      return unless transfer.calculation_type == svc.Amount
      if transfer.transferees.length == 1
        transferee.amount = transfer.transfer_amount
      else
        sumAmounts = _.reduce(transfer.transferees, (sum, transferee) ->
          sum + transferee.amount
        , svc.ZeroValue)
        limit = transfer.transfer_amount - (sumAmounts - transferee.amount)
        transferee.amount = Math.min(limit, transferee.amount)
      TransferService.calculateAmount({
        amount: transferee.amount,
        calculation_type: transfer.calculation_type,
        total_investment_amount: transfer.transfer_amount,
        total_units: transfer.units
      }).then (results) ->
        svc.updateTransfereeFromResults(transferee, results)
        svc.updateTotals(transfer)
      .catch (data, status) ->
        console.error("calculateTransfereeAmount", status, data)

    updateTotals: (transfer) ->
      transfer.totals.amount = _.reduce(transfer.transferees, (sum, transferee) ->
        sum + transferee.amount
      , svc.ZeroValue)
      transfer.totals.percentage = _.reduce(transfer.transferees, (sum, transferee) ->
        sum + transferee.percentage
      , svc.ZeroValue)
      transfer.totals.units = _.reduce(transfer.transferees, (sum, transferee) ->
        sum + transferee.units
      , svc.ZeroValue)
      transfer.totals.units = parseFloat(transfer.totals.units.toFixed(svc.RoundUnits), 10)
  }

  return svc
