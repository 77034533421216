angular.module 'icn.models'
.factory "AdminFundDocument",
  ($resource, $q, $upload, IcnUtility) ->
    service = $resource "/api/v1/admin_fund_documents/:id", {id: '@id'}

    service.upload = (paf, file, type = 'AdminFundDocument', reportDate = null, sendNotifications = false) ->
      $upload.upload(
        url: "/api/v1/admin_fund_documents/upload"
        file: file[0]
        data:
          private_access_fund_id: paf.id
          type: type
          report_date: IcnUtility.parseDate(reportDate)
          name: file[0].name
          send_notifications: sendNotifications
      )

    service
