angular.module('controllers').controller "refreshFundsCtrl",
  ($scope, $modalInstance, header, confirmLabel, cancelLabel, pafs) ->

    $scope.header = header
    $scope.confirmLabel = confirmLabel
    $scope.cancelLabel = cancelLabel
    $scope.pafs = pafs
    $scope.fields = {}
    $scope.fields.pafIds = []
    $scope.fields.reportDate = null
    $scope.fields.publishType = null

    $scope.publishTypes = ['Loaded', 'Estimate', 'Final', 'FinalRevised', 'PrelimNAV', 'FinalNAV', 'InternalReviewed', 'PreliminaryEstimate']

    $scope.cancel = ->
      $modalInstance.dismiss "no"

    $scope.canSubmit = ->
      $scope.fields.pafIds.length > 0 &&
      $scope.fields.reportDate? &&
      $scope.fields.publishType?

    $scope.submit = (fields) ->
      $modalInstance.close(fields)

    $scope.selectAllPafs = ->
      $scope.fields.pafIds = $scope.pafs.map((fc) -> fc.id)

    $scope.deselectAllPafs = ->
      $scope.fields.pafIds = []
