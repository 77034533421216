angular.module('controllers').controller "riaUserEmailDialogCtrl",
  ($scope, $modalInstance, ModalDialogInstancesObserver, InvestmentService, investmentId, isEditing, riaEmail) ->

    $scope.investmentId = investmentId
    $scope.isEditing = isEditing
    $scope.riaEmail = riaEmail
    $scope.error = null

    $scope.cancelDialog = ->
      $scope.error = null
      $modalInstance.dismiss()

    $scope.confirm = ->
      endpoint = if isEditing == true then InvestmentService.editRia else InvestmentService.addRia

      $scope.error = null
      endpoint.call(InvestmentService, investmentId, $scope.riaEmail).then (response) ->
        if response.errors
          $scope.error = response.errors[0]
        else
          $modalInstance.close(true)
