angular.module('services').factory 'TransferDateService', ->
  service = {

    Months: [
        { id: 1, name: 'January', end_of_quarter: false, begin_of_quarter: true },
        { id: 2, name: 'February', end_of_quarter: false, begin_of_quarter: false },
        { id: 3, name: 'March', end_of_quarter: true, begin_of_quarter: false },
        { id: 4, name: 'April', end_of_quarter: false, begin_of_quarter: true },
        { id: 5, name: 'May', end_of_quarter: false, begin_of_quarter: false },
        { id: 6, name: 'June', end_of_quarter: true, begin_of_quarter: false },
        { id: 7, name: 'July', end_of_quarter: false, begin_of_quarter: true },
        { id: 8, name: 'August', end_of_quarter: false, begin_of_quarter: false },
        { id: 9, name: 'September', end_of_quarter: true, begin_of_quarter: false },
        { id: 10, name: 'October', end_of_quarter: false, begin_of_quarter: true },
        { id: 11, name: 'November', end_of_quarter: false, begin_of_quarter: false },
        { id: 12, name: 'December', end_of_quarter: true, begin_of_quarter: false },
      ]

    CurrentMonth: new Date().getMonth() + 1
    CurrentYear: new Date().getFullYear()

    initMonths: ->
      this.Months

    initYears: (isPafAdmin) ->
      years = []
      years.push(this.CurrentYear - 1) if isPafAdmin
      years.concat(this.CurrentYear + num for num in [0..9])

  }

  return service
