angular.module('controllers').controller "NewIssuesProfileCtrl", ($scope, $routeParams, $rootScope, $location,
                                                IcnIdentity, Users, ContactCard, InvestorProfileService,
                                                NewIssuesProfile, WhiteLabelPartnerService, InvestmentService) ->
  $scope.currentStep = parseInt($routeParams.id) or 1
  $scope.invProfId = $routeParams.invProfId
  $scope.investorProfile = undefined
  $scope.participation = undefined
  $scope.nip =
    data:
      question1: {}
      question2: {}
    investor_profile_id: undefined
    choose_to_participate: undefined
  $scope.batchUpdateOptions =
    live: $routeParams.live || false
    frozen: $routeParams.frozen || false
  $scope.investmentId = $routeParams.investment_id

  $scope.init = ->
    WhiteLabelPartnerService.current().then (response) ->
      $scope.whiteLabelPartner = response.white_label_partner
    InvestorProfileService.show(
      $scope.invProfId
    ).$promise.then (res) ->
      $scope.investorProfile = res.investor_profile
      if $scope.investorProfile
        if $scope.investorProfile.new_issues_profile
          $scope.participation = $scope.investorProfile.new_issues_profile.choose_to_participate
          $scope.newIssuesProfile = NewIssuesProfile.get({id:$scope.investorProfile.new_issues_profile.id})
        else
          $scope.newIssuesProfile = new NewIssuesProfile($scope.nip)
        $scope.qpcContact = res.investor_profile.contact_card
        if $scope.qpcContact.name == ""
          $scope.qpcContact.name = $scope.qpcContact.email
      else
        $scope.investorProfile = {}

    if IcnIdentity.isExternalApprover()
      InvestmentService.show($scope.investmentId).then (res) ->
        $scope.status = res.status

  $scope.reloadProfile = ->
    $scope.init()

  $scope.setParticipation = ($event) ->
    if $event.currentTarget.name == 'noParticipation' && $event.currentTarget.checked == true
      $scope.newIssuesProfile.choose_to_participate = false
      $scope.participation = false
    else if $event.currentTarget.name == 'participation' && $event.currentTarget.checked == true
      $scope.newIssuesProfile.choose_to_participate = true
      $scope.participation = true
    else
      $scope.newIssuesProfile.choose_to_participate = null
    $scope.clearChoices($event)

  $scope.clearChoices = ($event) ->
    $scope.clearQuestion1Choices($event, false)
    $scope.clearQuestion2Choices($event, false)

  $scope.clearQuestion1Choices = ($event, check_none_applies = true) ->
    if $event.target.checked
      #angular.element("input.question1:checkbox").prop('checked', false)
      for k,_ of $scope.newIssuesProfile.data.question1
        $scope.newIssuesProfile.data.question1[k]= null
      $scope.newIssuesProfile.data.question1.none_applies = check_none_applies
      $scope.question1Pass = check_none_applies
    else
      $scope.question1Pass = false
    return

  $scope.clearQuestion2Choices = ($event, check_none_applies = true) ->
    if $event.target.checked
      for k,_ of $scope.newIssuesProfile.data.question2
        $scope.newIssuesProfile.data.question2[k]= null
      $scope.newIssuesProfile.data.question2.none_applies = check_none_applies
      $scope.question2Pass = false
    else
      $scope.question2Pass = true
    return

  $scope.anyFailingQuestion1Selected = ->
    return false if $scope.isHiddenQuestion('question1')
    if $scope.newIssuesProfile && $scope.newIssuesProfile.data
      _.some $scope.newIssuesProfile.data.question1, (v,k, obj) ->
        v == true && k != "none_applies"

  $scope.save = ->
    if !$scope.newIssuesProfile.id
      $scope.newIssuesProfile.investor_profile_id = $scope.investorProfile.id
      $scope.newIssuesProfile.$save()
    else
      $scope.newIssuesProfile.batch_update = $scope.batchUpdateOptions
      NewIssuesProfile.update({id: $scope.newIssuesProfile.id}, $scope.newIssuesProfile).$promise

  $scope.question1Passed = ->
    $scope.newIssuesProfile &&
      ($scope.newIssuesProfile.choose_to_participate == true) &&
      ($scope.newIssuesProfile.data.question1.none_applies == true) &&
      !$scope.anyFailingQuestion1Selected()

  $scope.question2Passed = ->
    if $scope.newIssuesProfile && $scope.newIssuesProfile.data
      _.some $scope.newIssuesProfile.data.question2, (v,k, obj) ->
        v == true && k != "none_applies"

  $scope.anyFailingQuestion2Selected = ->
    return false if $scope.isHiddenQuestion('question2')
    $scope.newIssuesProfile && $scope.newIssuesProfile.data && ($scope.newIssuesProfile.data.question2.none_applies == true)

  $scope.investorIsIndividual = ->
    $scope.investorProfile && $scope.investorProfile.signer_type == "0"

  $scope.investorIsEntity = ->
    $scope.investorProfile &&  $scope.investorProfile.signer_type == "1"

  $scope.showQuestionOne = ->
    !$scope.isHiddenQuestion('question1')

  $scope.showQuestionTwo = ->
    ($scope.anyFailingQuestion1Selected() || $scope.isHiddenQuestion('question1')) &&
    $scope.investorIsEntity() && !$scope.isHiddenQuestion('question2')

  $scope.clearQuestion1Eligibility = ($event) ->
    if $event.currentTarget.checked == true
      $scope.newIssuesProfile.data.question1.none_applies = false

  $scope.clearQuestion2Eligibility = ($event) ->
    if $event.currentTarget.checked == true
      $scope.newIssuesProfile.data.question2.none_applies = false

  $scope.submitDisabled = ->
    return false unless InvestorProfileService.userMustCompleteProfile($scope.user)

    if $scope.newIssuesProfile && $scope.newIssuesProfile.data
      if $scope.newIssuesProfile.choose_to_participate == null || $scope.newIssuesProfile.choose_to_participate == undefined
        true
      else if $scope.newIssuesProfile.choose_to_participate == false
        false
      else if $scope.investorIsIndividual() && $scope.isHiddenQuestion('question1')
        false
      else if $scope.investorIsIndividual() && !($scope.anyFailingQuestion1Selected() || $scope.newIssuesProfile.data.question1.none_applies)
        true
      else if $scope.investorIsEntity() && $scope.isHiddenQuestion('question1') && $scope.isHiddenQuestion('question2')
        false
      else if $scope.investorIsEntity() && ($scope.anyFailingQuestion1Selected() || $scope.isHiddenQuestion('question1')) &&
        !($scope.anyFailingQuestion2Selected() || $scope.question2Passed())
          true
    else
      true

  $scope.toggleQuestion1B = ($event) ->
    if $event.currentTarget.checked == false
      $scope.newIssuesProfile.data.question1.has_broker_dealer_personnel_family_financial_support = false
      $scope.newIssuesProfile.data.question1.has_broker_dealer_personnel_family_broker_dealer_member = false
      $scope.newIssuesProfile.data.question1.has_broker_dealer_personnel_family_can_allocate_new_issues = false
    $scope.clearQuestion1Eligibility($event)

  $scope.toggleQuestion1E = ($event) ->
    if $event.currentTarget.checked == false
      $scope.newIssuesProfile.data.question1.owns_broker_dealer_listed = false
      $scope.newIssuesProfile.data.question1.owns_broker_dealer_owns_ten_percent_or_more = false
      $scope.newIssuesProfile.data.question1.owns_broker_dealer_owns_twenty_five_percent_or_more = false
      $scope.newIssuesProfile.data.question1.owns_broker_dealer_family_financial_support = false
    $scope.clearQuestion1Eligibility($event)

  $scope.toggleQuestion1EChildren = ($event) ->
    if ($scope.newIssuesProfile.data.question1.owns_broker_dealer_listed ||
          $scope.newIssuesProfile.data.question1.owns_broker_dealer_owns_ten_percent_or_more ||
          $scope.newIssuesProfile.data.question1.owns_broker_dealer_owns_twenty_five_percent_or_more ||
          $scope.newIssuesProfile.data.question1.owns_broker_dealer_family_financial_support)
      $scope.newIssuesProfile.data.question1.owns_broker_dealer = true
      $scope.newIssuesProfile.data.question1.none_applies = false
    else
      $scope.newIssuesProfile.data.question1.owns_broker_dealer = false

  $scope.toggleQuestion1BChildren = ($event) ->
    if ($scope.newIssuesProfile.data.question1.has_broker_dealer_personnel_family_financial_support ||
        $scope.newIssuesProfile.data.question1.has_broker_dealer_personnel_family_broker_dealer_member ||
        $scope.newIssuesProfile.data.question1.has_broker_dealer_personnel_family_can_allocate_new_issues)
      $scope.newIssuesProfile.data.question1.is_broker_dealer_personnel = true
      $scope.newIssuesProfile.data.question1.none_applies = false
    else
      $scope.newIssuesProfile.data.question1.is_broker_dealer_personnel = false

  $scope.canManageInvestors = ->
    if IcnIdentity.isRia()
      IcnIdentity.canManageInvestors()
    else
      true

  $scope.isHiddenQuestion = (question) ->
    profileVisibilityValue(question) == 'hidden'

  $scope.isReadOnlyQuestion = (question) ->
    profileVisibilityValue(question) == 'read_only'

  $scope.disableQuestion = (question) ->
    !$scope.canManageInvestors() || $scope.isReadOnlyQuestion(question)

  profileVisibilityValue = (question) ->
    # see 'areas/wlp_admin/components/new_issues_profile_visibility_form.coffee'
    # for information on possible values
    visibility = 'default'
    if hasProfileVisibilityQuestion(question)
      visibility = $scope.whiteLabelPartner.new_issues_profile_visibility[question]

    if ($scope.isReadOnlyRole() && !IcnIdentity.canEditInvestorProfiles() && visibility == 'default') ||
       (IcnIdentity.isExternalApprover() && $scope.statusPassedSuitabilityReview())

      visibility = 'read_only' if visibility == 'default'

    visibility

  hasProfileVisibilityQuestion = (question) ->
    !!(_.isObject($scope.whiteLabelPartner) and
      $scope.whiteLabelPartner.hasOwnProperty('new_issues_profile_visibility') and
        $scope.whiteLabelPartner.new_issues_profile_visibility.hasOwnProperty(question))

  $scope.isReadOnlyRole = ->
    IcnIdentity.isRia() || IcnIdentity.isAdminOrIr() || IcnIdentity.isSalesManager() || IcnIdentity.isHomeOffice()

  $scope.statusPassedSuitabilityReview = ->
    $scope.status == 'IR Review' || $scope.status == 'Compliance Review' || $scope.status == 'Approved' ||
    $scope.status == 'Accepted'

  $scope.init()
