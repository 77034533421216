angular.module('directives').directive "investorProfileAdvisorInformation", ->
  restrict: "E"
  templateUrl: "areas/no_tab/investor_profile/components/advisor_information/advisor_information.html"
  scope:
    investorProfile: "="
    isReadOnlyField: "="
    isHiddenField: "="
    submitted: "="
    formErrorMsgs: "="
    mustComplete: "="

  link: (scope) ->

    scope.formErrorMsgs ||= {}

    scope.checkFieldValidity = (model, error_key, message) ->
      valid = !!model
      scope.formErrorMsgs[error_key] = message if !valid && scope.mustComplete
      delete scope.formErrorMsgs[error_key] if valid
      return valid || !scope.mustComplete
