(function() {
    angular.module('directives').directive("userMenu", function(MenuAnimation) {
        return {
            restrict: "AE",
            replace: true,
            transclude: true,
            templateUrl: "templates/directives/user_menu.html"
        };
    });
}).call(this);
