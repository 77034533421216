angular.module('directives').directive 'shareClassesTable', ->
  templateUrl: 'areas/paf_admin/tabs/common/funds/pages/funds/components/share_classes/share_classes_table.html'
  restrict: 'E'
  scope:
    fund: '='
  controller: ($scope, $modal, ShareClassSvc, IcnIdentity) ->
    newShareClass = ->
      { private_access_fund_id: $scope.fund.id }

    $scope.defaultText = (sc) ->
      if sc.is_default then 'Default Class' else '-'

    $scope.deletable = (sc) ->
      $scope.canEditShareClasses() && !sc.has_investments

    $scope.readOnlyShareClassModal = (shareClass) ->
      opts =
        templateUrl: "dialogs/share_class_dialog/share_class_dialog.html"
        controller: "shareClassDialogCtrl"
        windowClass: "large-modal"
        resolve:
          shareClass: -> shareClass
          options: -> {
            readOnly: true
            header: 'Share Class Details'
          }
      $modal.open(opts)

    $scope.writeShareClassModal = (shareClass) ->
      if shareClass
        header = "Edit Share Class '#{shareClass.name}'"
      else
        header = "Create Share Class"

      sc = shareClass || newShareClass()
      opts =
        templateUrl: "dialogs/share_class_dialog/share_class_dialog.html"
        controller: "shareClassDialogCtrl"
        windowClass: "large-modal"
        resolve:
          shareClass: -> sc
          options: -> {
            callback: $scope.updateShareClasses
            header: header
          }
      $modal.open(opts)

    $scope.setAsPafDefault = (sc) ->
      return if sc.default_class
      ShareClassSvc.setAsPafDefault(sc.id).$promise.then (response) ->
        $scope.fund.share_classes = response.share_classes

    $scope.updateShareClasses = (shareClass) ->
      replaced = false
      newShareClasses = _.map $scope.fund.share_classes, (sc) ->
        sc.is_default = false if shareClass.is_default
        if !replaced && shareClass.id == sc.id
          replaced = true
          shareClass
        else
          sc
      newShareClasses.push shareClass unless replaced
      $scope.fund.share_classes = newShareClasses

    $scope.removalWarning = (sc) ->
      msg = "Are you sure you want to permanently delete Share Class '#{sc.name}?'"
      opts =
        templateUrl: "dialogs/simple_alert/simple_alert_dialog.html"
        controller: "simpleAlertDialogCtrl"
        windowClass: "small-modal"
        resolve:
          acceptCallback: -> $scope.callRemoveShareClassApi
          options: -> {
            header: 'Delete Share Class',
            message: msg,
            acceptText: 'DELETE'
            modelId: sc.id
          }
      $modal.open(opts)

    $scope.callRemoveShareClassApi = (scid) ->
      ShareClassSvc.remove(scid).$promise.then (response) ->
        $scope.removeShareClass(response.share_class.id)

    $scope.removeShareClass = (scid) ->
      nscs = _.reject $scope.fund.share_classes, (sc) ->
        scid == sc.id
      $scope.fund.share_classes = nscs

    $scope.writeBulkUploadModal = ->
      opts =
        templateUrl: "dialogs/share_class_dialog/bulk_upload_dialog.html"
        controller: "BulkUploadDialogCtrl"
        windowClass: "small-modal"

      $modal.open(opts)

    $scope.canEditShareClasses = ->
      IcnIdentity.canEditShareClass()
