angular.module('controllers').controller "BulkUploadDialogCtrl",
  ($scope, $modalInstance, ShareClassSvc, $window, ModalDialogInstancesObserver, DocumentExportsService) ->
    $scope.uploadStatus = 'none'

    $scope.closeModal = ->
      $modalInstance.close()

    $scope.uploadShareClasses = (files) ->
      if(files.length > 0)
        $scope.uploadStatus = 'uploading'
        ShareClassSvc.bulkUpload(files).then((response) ->
          $scope.uploadStatus = 'success'
          $modalInstance.close()
        ).catch((resp) ->
          $scope.uploadStatus = 'error'
          $modalInstance.close()
        )

    $scope.downloadTemplate = ->
      ShareClassSvc.bulkUploadTemplate().$promise.then((response) ->
        url = URL.createObjectURL(response.file)
        tempLink = document.createElement("a")
        tempLink.href = url
        tempLink.target =  "_blank"
        tempLink.download = response.name
        tempLink.style.display = "none"
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      )

    dragleaveEventCallBackFunction = (e) ->
      e = e or event
      e.preventDefault()

    $window.addEventListener 'dragleave', dragleaveEventCallBackFunction


    dragoverEventCallBackFunction = (e) ->
      e = e or event
      e.preventDefault()

    $window.addEventListener 'dragover', dragoverEventCallBackFunction


    dropEventCallBackFunction = (e) ->
      e = e or event
      files = e.dataTransfer.files
      e.preventDefault()
      if e.target.id == ('bulk-upload') && $scope.uploadStatus == 'none'
        $scope.uploadShareClasses(files)

    $window.addEventListener 'drop', dropEventCallBackFunction

    $scope.$on("$destroy", ->
      $window.removeEventListener('drop', dropEventCallBackFunction)
    )

    $scope.picker = ->
      el = document.getElementById('bulk-upload')
      if el
        el.click()

    currentModalInstance = $modalInstance
    currentModalInstance.events = {}

    # dismiss modal dialog box when navigate to other page
    ModalDialogInstancesObserver.setModalDialogInstance(currentModalInstance)

    loadBulkUploadResults = ->
      DocumentExportsService.fetchForShareClassBulkUploadResults().then (data) ->
        $scope.bulkUploadResults = data

    init = ->
      loadBulkUploadResults()

    init()
