angular.module('services').factory 'RolesForUserManagement', (IcnIdentity) ->
  self = @

  self.rolesForManagement = ->
    [
      'ai_default',
      'qp_default',
      'qpcl_default',
      'ria_default',
      'sfo_default',
      'aicl_default',
      'interested_party',
      'delegate',
    ]

  return @
