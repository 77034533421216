angular.module('controllers').controller "AccreditationCtrl",
  ($scope, $routeParams, $http, $rootScope, $location, UserAccreditation, $window, $validator, FormDataService, Menu,
   IcnIdentity, ReferralCode, $q, $sce) ->
    setOptions = (userId) ->
      minFirmYear = 1900
      maxFirmYear = new Date().getFullYear()
      $scope.firmYearSelectOptions = _.range(minFirmYear, maxFirmYear)
      UserAccreditation.options().then (d) ->
        $scope.countryList = d.countries
        $scope.nonUSCountryList = $scope.nonUSCountries(d.countries)
        $scope.titleOptions = d.title_options
        $scope.employmentOptions = d.employment_options
        $scope.incomeOptions = d.income_options
        $scope.netWorthOptions = d.net_worth_options
        $scope.investmentValueOptions = d.investment_value_options
        $scope.firmInvestmentValueOptions = d.firm_investment_value_options
        if $scope.accreditation_type != 'ria' && typeof $scope.user_accreditation.country is "undefined"
          $scope.setDefaultCountry()
        return
      return

    showThankYou = (referral_code_id) ->
      ReferralCode.$get({id: referral_code_id}, (data) ->
        if data.referral_code.thank_you_text
          $scope.thankYouText = $sce.trustAsHtml(data.referral_code.thank_you_text)
          $("html,body").scrollTop 0
          $scope.show_thank_you = true
        else
          $scope.accreditationComplete(true)
      ,
        (e) ->
          $scope.msg = 'error occured'
      )

    setAddressOptions = (addresses) ->
      $scope.addresses = addresses
      $scope.setAddress addresses[0]  if typeof addresses isnt "undefined" and addresses.length > 0
      return

    $scope.requireRiaCrd = ->
      return false unless $scope.user_accreditation.is_ria
      $scope.user_accreditation.advisor_confirmed

    $scope.$watch 'user_accreditation.advisor_confirmed', (newValue, _) ->
      if newValue
        $scope.user_accreditation.is_ria = true
      else
        $scope.user_accreditation.is_ria = false
        if $scope.accreditation_type == "qp"
          $scope.user_accreditation.firm_name = ''

    $scope.$watch 'user_accreditation.is_ria', (newValue, _) ->
      if newValue
        $scope.crdNumberType = "Individual Advisor's CRD Number*"
      else
        $scope.crdNumberType = "Individual's CRD Number"

    $scope.employmentValidator = "[requiredBlur]"

    $scope.setValidator = ->
      if $scope.isUnemployed()
        $scope.employmentValidator = "[]"
        delete $scope.accreditation_form.$error['user_accreditation.employer']
        delete $scope.accreditation_form.$error['user_accreditation.type_of_business']
        delete $scope.accreditation_form.$error['user_accreditation.role']
      else
        $scope.employmentValidator = "[requiredBlur]"

    $scope.isUnemployed = ->
      $scope.user_accreditation.employment_status == 'Unemployed'

    $scope.showPhoneField = ->
      IcnIdentity.unaccreditedInvestor() || IcnIdentity.unaccreditedRia()

    validRiaChosen = ->
      _.any($scope.rias, (k) -> k.name == $scope.user_accreditation.firm_name) &&
        $scope.user_accreditation.ria_record_name == $scope.user_accreditation.firm_name

    $scope.hideAutocompleteError = ->
      # Hide the error if the input is filled in with a choice from the given RIAs
      noFirmBlur = $scope.formBlur.firm_blur isnt true
      noFirmBlur or validRiaChosen()

    $scope.accreditationInvalid = ->
      $scope.accreditation_type == 'ria' && !validRiaChosen()

    $scope.selectRecord = ($item) ->
      $scope.user_accreditation.ria_record_id = $item.id
      $scope.user_accreditation.ria_record_name = $item.name
      return

    $scope.riaAutocomplete = (query) ->
      UserAccreditation.autocomplete(
        $scope.user.id, query
      ).then (rias) -> $scope.rias = rias

    $scope.setAddress = (address) ->
      $scope.user_accreditation.address1 = address.address1
      $scope.user_accreditation.address2 = address.address2
      $scope.user_accreditation.city = address.city
      $scope.user_accreditation.state = address.state
      $scope.user_accreditation.country = address.country
      $scope.user_accreditation.zip = address.zip
      return

    $scope.setDefaultCountry = ->
      $scope.user_accreditation.country = "United States of America"

    $scope.nonUSCountries = (countries) ->
      _.reject(countries, (country) -> country.name == 'United States of America')

    $scope.formTemplates =
      individual_contact: "areas/no_tab/accreditation/pages/accreditation/templates/accreditation_sections/individual_contact_information.html"
      firm_contact: "areas/no_tab/accreditation/pages/accreditation/templates/accreditation_sections/firm_contact_information.html"
      annual_income: "areas/no_tab/accreditation/pages/accreditation/templates/accreditation_sections/annual_income.html"
      net_worth: "areas/no_tab/accreditation/pages/accreditation/templates/accreditation_sections/net_worth.html"
      employment: "areas/no_tab/accreditation/pages/accreditation/templates/accreditation_sections/employment.html"
      signature: "areas/no_tab/accreditation/pages/accreditation/templates/accreditation_sections/signature.html"
      firm_assets: "areas/no_tab/accreditation/pages/accreditation/templates/accreditation_sections/firm_assets.html"
      qpc_questionnaire: "areas/no_tab/accreditation/pages/accreditation/templates/accreditation_sections/qpc_questionnaire.html"
      ria_questionnaire: "areas/no_tab/accreditation/pages/accreditation/templates/accreditation_sections/ria_questionnaire.html"
      aicl_questionnaire: "areas/no_tab/accreditation/pages/accreditation/templates/accreditation_sections/aicl_questionnaire.html"
      wait_for_contact: "areas/no_tab/accreditation/pages/accreditation/templates/accreditation_sections/wait_for_contact.html"
      section_seperator: "areas/no_tab/accreditation/pages/accreditation/templates/accreditation_sections/section_seperator.html"
      thank_you: "areas/no_tab/accreditation/templates/thank_you.html"

    $scope.date = new Date()
    $scope.years =
      current_year: $scope.date.getFullYear()
      one_year_ago: $scope.date.getFullYear() - 1
      two_years_ago: $scope.date.getFullYear() - 2

    $scope.accreditationFields =
      qp: [
        "individual_contact"
        "annual_income"
        "net_worth"
        "employment"
      ]
      sfo: [
        "firm_contact"
        "firm_assets"
      ]
      qpc: [
        "qpc_questionnaire"
      ]
      ria: [
        "ria_questionnaire"
      ]
      aicl: [
        "aicl_questionnaire"
      ]

    $scope.accreditationComplete = (valid) ->
      if (!valid && $scope.user.isInvestor)
        $window.location.href = '/accreditation/qp'
      else
        $window.location.href = IcnIdentity.defaultPage()

    $scope.usStates = FormDataService.usStates

    $scope.fullNameErrors = ->
      $scope.accreditation_form.first_name.$error.required || $scope.accreditation_form.last_name.$error.required

    $scope.addressErrors = ->
      $scope.accreditation_form.address1.$error.required || $scope.accreditation_form.city.$error.required ||
        $scope.accreditation_form.state.$error.required || $scope.accreditation_form.zip.$error.required ||
        $scope.accreditation_form.country.$error.required

    $scope.submitAccreditations = (user) ->
      $scope.submitted = true
      return if !$scope.accreditation_form.$valid
      promises = []
      if !$scope.user_accreditation.income_calculation_type && $scope.accreditation_type == "qp"
        $scope.user_accreditation.income_calculation_type = "individual"

      promises.push(UserAccreditation.save($scope.user.id, $scope.user_accreditation))

      $q.all(promises).then (results) ->
        response = results[0]
        if response.thank_you_redirect > 0
          showThankYou(response.thank_you_redirect)
        else
          $scope.accreditationComplete(response.valid)
      , ->
        $("html,body").scrollTop 0
        $scope.wait_for_contact = true

    $scope.signatureTitle = ->
      if $scope.accreditation_type == 'ria'
        "Investment Advisor"
      else
        "Individual Investor"

    $scope.accreditation_type = $routeParams.accreditation_type
    $scope.user = IcnIdentity.data.user
    $scope.usStates = FormDataService.usStates
    $scope.wait_for_contact = false
    $scope.show_thank_you = false
    $scope.wlp = IcnIdentity.whiteLabelPartner()
    $scope.user_accreditation = {}
    $scope.formBlur = {}
    $scope.instructionTemplates =
      aicl: "areas/no_tab/accreditation/pages/accreditation/templates/accreditation_instructions/aicl_instructions.html"
      qpc: "areas/no_tab/accreditation/pages/accreditation/templates/accreditation_instructions/qpc_instructions.html"
      qp: "areas/no_tab/accreditation/pages/accreditation/templates/accreditation_instructions/qp_instructions.html"
      ria: "areas/no_tab/accreditation/pages/accreditation/templates/accreditation_instructions/ria_instructions.html"
      sfo: "areas/no_tab/accreditation/pages/accreditation/templates/accreditation_instructions/sfo_instructions.html"

    data = IcnIdentity.data

    UserAccreditation.all(data.user.id).then (d) ->
      setAddressOptions d.addresses
      $scope.wait_for_contact = not d.valid
      delete d.addresses

      $scope.user_accreditation = _.defaults($scope.user_accreditation, d)
      setOptions data.user.id
      return
