angular.module('services').factory 'RedemptionTermsService', ->
  svc = {
    Na: 'N/A'

    SemiAnnually: 'semi_annually'
    Annually: 'annually'
    Quarterly: 'quarterly'
    Monthly: 'monthly'
    Weekly: 'weekly'

    isClassTerms: null
    redemptionTerms: null

    terms: (redemptionTerms) ->
      svc.redemptionTerms = redemptionTerms
      svc.isClassTerms = svc.redemptionTerms.is_class_terms
      return svc

    title: ->
      if svc.isClassTerms then 'Class Terms' else 'Fund Terms'

    lockUpPeriodTitle: ->
      if svc.isClassTerms then 'Lock-up Period' else 'Lock Up'

    gateTitle: ->
      if svc.isClassTerms then 'Gate' else 'Fund Gate'

    lockUpPenaltyTitle: ->
      if svc.isClassTerms then 'Early Penalty' else 'Lock Up Penalty'

    gatePenaltyTitle: ->
      if svc.isClassTerms then 'Gate Penalty' else 'Fund Gate Penalty'

    redemptionFrequency: ->
      frequency = svc.redemptionTerms.redemption_frequency
      frequency = "semi-annually" if frequency == svc.SemiAnnually
      frequency || svc.Na

    noticePeriod: ->
      return svc.Na if !svc.redemptionTerms.notice_period

      "#{svc.redemptionTerms.notice_period} #{svc.noticeRequirementType()}s"

    noticeRequirementType: ->
      if svc.redemptionTerms.redemption_notice_requirement_type == 'business_day'
        'business day'
      else
        'calendar day'

    auditHoldbackValue: ->
      svc.redemptionTerms.audit_holdback

    auditHoldback: ->
      svc.auditHoldbackValue() || 0

    investorGate: ->
      svc.redemptionTerms.investor_gate

    investorGatePenalty: ->
      svc.redemptionTerms.investor_gate_penalty

    lockUpPeriod: ->
      return svc.Na if !svc.redemptionTerms.lock_up_period

      if svc.isClassTerms
        return "#{svc.redemptionTerms.lock_up_period} months"
      else
        return "#{svc.redemptionTerms.lock_up_period} years"

    lockUpPenalty: ->
      svc.redemptionTerms.lock_up_penalty

    gate: ->
      svc.redemptionTerms.gate

    minRedemptionAmount: ->
      return 0.0 if !svc.redemptionTerms.minimum_redemption_amount

      svc.redemptionTerms.minimum_redemption_amount

    gatePenalty: ->
      svc.redemptionTerms.gate_penalty

    nextRedemptionDate: ->
      moment(svc.redemptionTerms.next_redemption_date)

    fundRedemptionAnnually: ->
      !svc.isClassTerms && svc.redemptionTerms.redemption_frequency == svc.Annually

    fundRedemptionQuarterly: ->
      !svc.isClassTerms && svc.redemptionTerms.redemption_frequency == svc.Quarterly

    classRedemptionAnnually: ->
      svc.isClassTerms && svc.redemptionTerms.redemption_frequency == svc.Annually

    classRedemptionQuarterly: ->
      svc.isClassTerms && svc.redemptionTerms.redemption_frequency == svc.Quarterly

    classRedemptionWeekly: ->
      svc.isClassTerms && svc.redemptionTerms.redemption_frequency == svc.Weekly

    fundRedemptionDate: ->
      svc.redemptionTerms.redemption_date unless svc.isClassTerms

    classRedemptionDate: ->
      svc.redemptionTerms.redemption_date if svc.isClassTerms
  }

  return svc
