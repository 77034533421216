angular.module('directives').directive "dropdownToggle",
  ($document, $location) ->
    restrict: "CA"
    link: (scope, element, attrs) ->
      openElement = null
      closeMenu = angular.noop

      scope.$watch "$location.path", ->
        closeMenu()

      element.parent().bind "click", ->
        closeMenu()

      element.bind "click", (event) ->
        elementWasOpen = (element is openElement)
        event.preventDefault()
        event.stopPropagation()
        closeMenu()  if openElement
        unless elementWasOpen or element.hasClass("disabled") or element.prop("disabled")
          $(".drop-down.open").removeClass "open" # close currently open menus
          element.parent().addClass "open"
          openElement = element
          closeMenu = (event) ->
            # When editing Fund Profile, don't close the dropdown while checking/unchecking asset classes, etc.
            if ((!event && openElement.closest(".dropdown-form").length == 0) ||
                $(event.target).closest('.dropdown-form').length == 0)
              if event
                event.preventDefault()
                event.stopPropagation()
              $document.unbind "click", closeMenu
              element.parent().removeClass "open"
              closeMenu = angular.noop
              openElement = null
          $document.bind "click", closeMenu
