angular.module('directives').directive 'fundSelector', ->
  templateUrl: 'areas/common/fund_selector_template.html'
  restrict: 'E'
  scope:
    funds: '='
    fund: '='
    filterBy: '='
    showAllOption: '='
    displayInline: '@'

  controller: ($scope, PafAdminNavSvc) ->

    init = ->
      $scope.showAllFundsOption = showAllFundsOption()

    setDisplayedFunds = ->
      $scope.displayedFunds = $scope.funds.filter (fund) -> fund.openEnded == ($scope.filterBy == 'hedgeFund')
      $scope.showAllFundsOption = showAllFundsOption()

    filterFunds = ->
      setDisplayedFunds()

      if $scope.filterBy == 'hedgeFund'
        $scope.fund = $scope.displayedFunds[0]
      else if $scope.filterBy != 'hedgeFund'
        $scope.fund = null

    changeFunds = ->
      setDisplayedFunds()

    showAllFundsOption = ->
      (!$scope.showAllOption || $scope.showAllOption == 'true') && $scope.filterBy != 'hedgeFund'

    $scope.setSelectedFund = (fund) ->
      if fund?.id
        PafAdminNavSvc.selectedFundId = fund.id
        $scope.fund = fund
      else
        PafAdminNavSvc.selectedFundId = null
        $scope.fund = null

    $scope.$watch "filterBy", (oldValue, newValue) ->
      if $scope.filterBy && $scope.funds && (oldValue != newValue)
        filterFunds()

    $scope.$watch "funds", (newFunds, oldFunds) ->
      if newFunds?
        if $scope.filterBy
          changeFunds()
        else
          if $scope.showAllFundsOption
            $scope.displayedFunds = [{name: 'All Funds', id: null}].concat newFunds
          else
            $scope.displayedFunds = newFunds


    init()
