angular.module('controllers').controller "AddOrViewNoteCtrl",
  ($scope, $modal, $modalInstance, selectedPaf, mode, NoteService, IcnIdentity) ->
    $scope.selectedPaf = selectedPaf
    $scope.mode = mode
    $scope.addMode = -> $scope.mode == 'add'
    $scope.viewMode = -> $scope.mode == 'view'
    newNote = {
      noteable_id: $scope.selectedPaf.id,
      noteable_type: 'PrivateAccessFund',
      subject: '',
      body: ''
    }
    $scope.modalHeader = ->
      if $scope.addMode() then 'Add Note' else 'Note'

    $scope.noteForm = {
      note: $scope.selectedPaf.note || newNote
    }

    $scope.disableSaveButton = ->
      !$scope.noteForm.note.subject || !$scope.noteForm.note.body

    $scope.canAddSubReviewNotes = ->
      IcnIdentity.canAddSubReviewNotes()

    $scope.switchToAddMode = ->
      $scope.mode = 'add'

    $scope.createNote = ->
      params = { note: $scope.noteForm.note }
      NoteService.save(params).$promise.then (res) ->
        $modalInstance.close(res.note)

    $scope.updateNote = ->
      params = { id: $scope.selectedPaf.note.id, note: $scope.noteForm.note }
      NoteService.update($scope.selectedPaf.note.id, params).$promise.then (res) ->
        $modalInstance.close(res.note)

    $scope.openDeleteNoteModal = (selectedPaf, note) ->
      $modal.open(
        templateUrl: "areas/aml_approval/dialogs/notes/delete.html"
        controller: "DeleteNoteCtrl"
        windowClass: 'small-modal',
        resolve:
          selectedPaf: ->
            selectedPaf
          note: ->
            note
      ).result.then ->
        $modalInstance.close()

    $scope.dismissModal = ->
      $modalInstance.dismiss()
