angular.module('services').factory "AccountTypesService", ($resource, $http, $q) ->
  service =
    loadAccountTypes: (wlp_id) ->
      $http({
        url: "/api/v1/registration_account_types",
        method: "GET",
        params: { wlp_id: wlp_id, visibility_at: "registration_flow" }
      })

  return service
