angular.module('controllers').controller 'InvestorRelationsSearchDetailCtrl',
  ($scope, $routeParams, $location, $modal, InvestmentService, PrivateAccessFunds, IcnIdentity, urlBuilder) ->

    $scope.investments = []

    $scope.confirmRiaDeletion = (riaEmail, investmentId) ->
      opts =
        templateUrl: "dialogs/custom_confirm_dialog/custom_confirm.html"
        controller: "customConfirmDialogCtrl"
        windowClass: "small-modal"
        resolve:
          header: ->
            "Delete RIA User Email"
          msg: ->
            "Are you sure you want to delete \"#{riaEmail}\""
          confirm_label: ->
            "Ok"
          cancel_label: ->
            "Cancel"
      $modal.open(opts).result.then ->
        deleteRiaRelation(investmentId)

    $scope.showRiaUserModal = (isEditing, investment) ->
      opts =
        templateUrl: "components/investor_relations/dialogs/ria_user_email_dialog/ria_user_email_dialog.html"
        controller: "riaUserEmailDialogCtrl"
        windowClass: "small-modal"
        resolve:
          investmentId: ->
            investment.id
          isEditing: ->
            isEditing
          riaEmail: ->
            if isEditing == true then investment.ria_email else ""

      $modal.open(opts).result.then ->
        loadSelectedInvestment()

    deleteRiaRelation = (investmentId) ->
      InvestmentService.removeRia(investmentId).then ->
        loadSelectedInvestment()

    $scope.goToReqDocs = (investment) ->
      urlBuilder.reactUrl(
        "/required_documents?investment_id=#{investment.id}&investor_profile_id=#{investment.investor_profile_id}",
        true
      )

    $scope.openInvestorProfile = (investment) ->
      invProfId = investment.investor_profile_id
      selectedPaf = $scope.selectedPaf
      contactCard = $scope.contactCard
      queryString = "&irPath=#{selectedPaf.id}_#{contactCard.id}" +
        "&from=investor_relations_search_detail" +
        "&private_access_fund_id=#{selectedPaf.id}" +
        "&paf_override=#{selectedPaf.id}" +
        "&investment_id=#{investment.id}"
      url = urlBuilder.investorProfileUrl(invProfId, "1", queryString)

      $location.url url if url

    loadSelectedInvestment = ->
      InvestmentService.forContactFund($routeParams.contactCardId, $routeParams.fundId).then (response) ->
        $scope.investorName = response.investments[0].investor_name
        $scope.fundName = response.investments[0].fund_name
        $scope.contactCard = response.investments[0].contact_card
        $scope.selectedPaf = response.investments[0].paf
        $scope.investments = response.investments
        $scope.pafId = $scope.selectedPaf.id
        loadPafs()
        setWatches()
        return

    setWatches = ->
      $scope.$watch "selectedPaf", (newPaf, oldPaf) ->
        if newPaf != undefined && newPaf != null && newPaf != oldPaf
          changeFund()

    loadPafs = ->
      PrivateAccessFunds.getPafsForContactCard($routeParams.contactCardId).then (pafs) ->
        $scope.pafs = pafs
        if $scope.pafId != undefined && !isNaN(parseInt($scope.pafId))
          $scope.selectedPaf = _.findWhere $scope.pafs, {id: $scope.pafId}

    changeFund = ->
      $location.path "/investor_relations/investor_search/detail/#{$routeParams.contactCardId}/#{$scope.selectedPaf.id}"

    init = ->
      loadSelectedInvestment()

    init()
