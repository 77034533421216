angular.module "icn.paf_admin"
  .controller "RedemptionsCtrl",
    ($scope, $location, $routeParams, $route, Redemptions, PafAdminSvcApi, $modal, IcnIdentity, $window,
    $q, Notification, RequiredDocument) ->
      init = ->
        $scope.allSelected = false
        $scope.notification = null
        $scope.orderByField = "display_status"
        $scope.reverseSort = false
        $scope.redemptions = $scope.selectedRedemptions = []
        $scope.selectedPafId = $routeParams.private_access_fund_id
        $scope.selectedGroupId = $location.search().group || $routeParams.group
        $scope.enableRedemptions = IcnIdentity.enableRedemptions()
        $scope.fundType = $routeParams.fund_type
        $scope.minDate = $location.search().min_date || $routeParams.minDate
        $scope.maxDate = $location.search().max_date || $routeParams.maxDate
        $scope.minDateFormat = {
          dateFormat: "mm-dd-yy"
        }
        $scope.maxDateFormat = {
          dateFormat: "mm-dd-yy"
        }
        $scope.hideFundGroups = (IcnIdentity.isHomeOffice() || IcnIdentity.isPafAdmin()) && IcnIdentity.isWhiteLabel()
        pafAdminUse = IcnIdentity.isPafAdmin() && !$scope.selectedPafId && !!$scope.selectedGroupId
        normalUse = $scope.selectedPafId

        if $scope.enableRedemptions && IcnIdentity.canLoadRedemptions() && (pafAdminUse || normalUse)
          Redemptions.advanced_dashboard($scope.selectedGroupId, $scope.selectedPafId, $scope.fundType,
            $scope.minDate, $scope.maxDate).then (response) ->
              $scope.redemptions = _.sortBy(response, "value_date")
              calculateTotal()
              populateSortingColumns()
        if $scope.fundType == "hf"
          $scope.reportableAmountType = "NAV"
        else
          $scope.reportableAmountType = "Capital Balance"

        refreshFundGroups() if $scope.fundType == "hf" && !$scope.hideFundGroups
        refreshFunds()

      refreshFundGroups = ->
        PafAdminSvcApi.getFundGroups(true).then((response) ->
          $scope.fundGroups = [{ id: 0, name: "All", slug: "all" }].concat(response)
          $scope.selectedGroup = _.findWhere($scope.fundGroups, { id: parseInt($scope.selectedGroupId) || 0 })
        )

      refreshFunds = ->
        if $scope.fundType == "pe"
          PafAdminSvcApi.getPrivateEquityFunds(true).then((response) -> prepareFunds(response))
        else if $scope.fundType == "hf"
          PafAdminSvcApi.hedgeFunds($scope.selectedGroupId, true).then((response) -> prepareFunds(response))

      prepareFunds = (response) ->
        $scope.funds = _.sortBy(response, "name")
        if IcnIdentity.isPafAdmin() && !IcnIdentity.isWhiteLabel() && $scope.fundType == "hf"
          $scope.funds.unshift({ id: -1, name: "All" })

        $scope.selectedFund = _.findWhere($scope.funds, { id: parseInt($routeParams.private_access_fund_id) || -1 })
        $scope.fundsReady = true if $routeParams.private_access_fund_id

      $scope.canUpdatePafRedemptions = ->
        !!$scope.redemptions[0]?.can_manage_redemption

      $scope.updateMinDate = ->
        $route.updateParams(minDate: $scope.minDate)

      $scope.updateMaxDate = ->
        $route.updateParams(maxDate: $scope.maxDate)

      $scope.selectGroup = (group_id) ->
        return if "#{group_id}" == $routeParams.group || ($routeParams.group == undefined && group_id == 0)

        group_id = null if group_id == 0
        $route.updateParams({ group: group_id, private_access_fund_id: undefined })

      $scope.selectId = (fund_id) ->
        selectedFundId = $routeParams.private_access_fund_id
        return if "#{fund_id}" == selectedFundId || (selectedFundId == undefined && fund_id == -1)

        fund_id = undefined if fund_id == -1
        $route.updateParams({ group: $scope.selectedGroupId, private_access_fund_id: fund_id })

      populateSortingColumns = ->
        _.each($scope.redemptions, (redemption) ->
          redemption.investor_custodian_name = redemption.investor.custodian_name
        )

      calculateTotal = ->
        $scope.totals = {
          nav: total('nav'),
          withdrawals: total('redemption_request'),
          netWired: total('net_wired')
        }

      total = (attr) ->
        _.reduce($scope.redemptions, (sum, row) ->
          sum + row[attr]
        , 0
        )

      $scope.selectAll = ->
        _.each($scope.redemptions, (redemption) ->
          redemption.selected = $scope.allSelected
        )

      $scope.toRedemptionDashboard = ->
        $location.search ''
        $location.path("/paf_admin/#{$scope.fundType}/redemptions_dashboard/"
        ).search(minDate: $routeParams.minDate, maxDate: $routeParams.maxDate, group: $routeParams.group, private_access_fund_id: $routeParams.private_access_fund_id)

      $scope.refresh = ->
        month = $scope.selectedMonth
        year = $scope.selectedYear
        $location.path(
          "/paf_admin/#{$scope.fundType}/redemptions/#{$scope.selectedPafId}"
        ).search({month: month, year: year})

      $scope.orderBy = (field) ->
        $scope.reverseSort = if field == $scope.orderByField then !$scope.reverseSort else false
        $scope.orderByField = field

      openModal = (params) ->
        $modal.open(params).result.then( ->
          init()
        , (error) ->
          $scope.notification = error if error?.text
        )

      dialogPath = (relativePart) ->
        "areas/paf_admin/tabs/common/redemptions/pages/redemptions/dialogs/" + relativePart

      $scope.setValueDate = (redemption) ->
        openModal(
          templateUrl: dialogPath("set_value_date/set_value_date.html")
          controller: "SetValueDateCtrl"
          windowClass: "small-modal"
          resolve:
            redemption: -> redemption
            pafId: -> redemption.paf_id
        )

      $scope.setWireDate = (redemption) ->
        openModal(
          templateUrl: dialogPath("set_wire_date/set_wire_date.html")
          controller: "SetWireDateCtrl"
          windowClass: "small-modal"
          resolve:
            redemption: -> redemption
            pafId: -> redemption.paf_id
        )

      $scope.setHoldBackWireDate = (redemption) ->
        openModal(
          templateUrl: dialogPath("set_hold_back_wire_date/set_hold_back_wire_date.html")
          controller: "SetHoldBackWireDateCtrl"
          windowClass: "small-modal"
          resolve:
            redemption: -> redemption
            pafId: -> redemption.paf_id
        )

      $scope.setSelectedValueDate = (redemption) ->
        selectedRedemptions = getSelectedRedemptions()
        openModal(
          templateUrl: dialogPath("set_value_date/set_value_date.html")
          controller: "SetAllValueDateCtrl"
          windowClass: "small-modal"
          resolve:
            redemptions: -> selectedRedemptions
            pafId: -> selectedRedemptions[0].paf_id
        )

      $scope.setSelectedWireDate = (redemption) ->
        selectedRedemptions = getSelectedRedemptions()
        openModal(
          templateUrl: dialogPath("set_wire_date/set_wire_date.html")
          controller: "SetAllWireDateCtrl"
          windowClass: "small-modal"
          resolve:
            redemptions: -> selectedRedemptions
            pafId: -> selectedRedemptions[0].paf_id
        )

      $scope.setSelectedHoldBackWireDate = (redemption) ->
        selectedRedemptions = getSelectedRedemptions()
        openModal(
          templateUrl: dialogPath("set_hold_back_wire_date/set_hold_back_wire_date.html")
          controller: "SetAllHoldBackWireDateCtrl"
          windowClass: "small-modal"
          resolve:
            redemptions: -> selectedRedemptions
            pafId: -> selectedRedemptions[0].paf_id
        )

      getSelectedRedemptions = ->
        _.where($scope.redemptions, {selected: true})

      $scope.selectedRedemptionsMenuAvailable = ->
        selectedRedemptions = getSelectedRedemptions()
        selectedRedemptions.length > 0 && !_.some(selectedRedemptions, {status: 'paid'})

      $scope.allRedemptionsPending = ->
        _.every(getSelectedRedemptions(), {status: 'pending_signature'})

      $scope.canSubmitApproval = (redemption) ->
        redemption.status == 'pending_approval' && $scope.canUpdatePafRedemptions()

      $scope.canSubmitBulkApprovals = ->
        return false if !$scope.canUpdatePafRedemptions()

        selectedRedemptions = getSelectedRedemptions()
        if selectedRedemptions.length > 0
          _.every(selectedRedemptions, {status: 'pending_approval'})

      $scope.submitApproval = (redemption) ->
        Redemptions.approve(redemption.paf_id, redemption)
        .then(->
          init()
        , (response) ->
          $scope.notification = response.data)

      $scope.submitBulkApprovals = ->
        selectedRedemptions = getSelectedRedemptions()
        dfd = $q.defer()
        Redemptions.bulkApprove(
          selectedRedemptions[0].paf_id, selectedRedemptions
        ).then (res) ->
          dfd.resolve res
        , (err) ->
          Notification.error err.data.message
          dfd.reject(err)
        dfd.promise

      $scope.canCancelRedemption = (redemption) ->
        return false unless $scope.canUpdatePafRedemptions()
        return true if redemption.status in ['draft','pending_signature', 'pending_approval']

        redemption.status == 'approved' && Date.parse(redemption.wire_date) > Date.now()

      $scope.cancelRedemption = (redemption) ->
        openModal(
          templateUrl: dialogPath("cancel_redemption/cancel_redemption.html")
          controller: "CancelRedemptionCtrl"
          windowClass: "small-modal"
          resolve:
            redemption: -> redemption
            pafId: -> redemption.paf_id
        )

      $scope.canSetHoldBackWireDate = (redemption) ->
        redemption.full && $scope.canUpdatePafRedemptions() &&
          !(redemption.hold_back == 0 || redemption.hold_back == undefined)

      $scope.canSetSelectedHoldBackWireDate = ->
        return false if !$scope.canUpdatePafRedemptions()

        selectedRedemptions = getSelectedRedemptions()
        if selectedRedemptions.length > 0
          _.every(selectedRedemptions, {full: true})

      $scope.canSetSelectedValueDateAndWireDate = ->
        return false if !$scope.canUpdatePafRedemptions()

        selectedRedemptions = getSelectedRedemptions()
        selectedRedemptions.length > 0

      $scope.downloadAgreement = (redemption) ->
        RequiredDocument.view(
          id: redemption.redemption_document?.id
        ).$promise.then (res) ->
          $window.open res.url
          return true

      $scope.investorProfile = (redemption) ->
        $window.open("/investor_profile/#{redemption.investor_id}/")

      init()
