angular.module('controllers').controller "QualificationDialogCtrl",
  ($scope, $modalInstance, $window, $location, QualificationQuestionsService) ->
    $scope.header = "Qualification"

    getInvestorQualificationQuestions = ->
      QualificationQuestionsService.getQualificationQuestions().then((res) ->
        if !JSON.parse(res.data).length
          $modalInstance.dismiss()
        else
          $scope.qualificationQuestions = JSON.parse(res.data)
      ).catch((error) ->
        $location.path('/logout')
      )

    $scope.setQualificationQuestion = ($event, question) ->
      $scope.showError = false
      question.checked = $event.target.checked

    $scope.hasTitle = (question) ->
      return !_.isEmpty(question.title)

    $scope.isAnyQuestionChecked = ->
      return $scope.qualificationQuestions && $scope.qualificationQuestions.filter((q) -> q.checked).length > 0

    $scope.saveQualifications = ->
      if !$scope.isAnyQuestionChecked()
        return
      questionIds = $scope.qualificationQuestions.filter((question) -> question.checked).map((q) -> q.id)
      QualificationQuestionsService.saveQualifications(questionIds).then( ->
        $window.location.reload()
      ).catch( ->
        $scope.showError = true
      )

    init = ->
      getInvestorQualificationQuestions()

    init()
