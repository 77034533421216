angular.module('services').factory "LibraryDocumentService",
  ($resource) ->
    @resource = $resource("/api/v1/vendor_templates/:libraryDocumentId", {libraryDocumentId: "@libraryDocumentId"},
      refreshTemplates:
        method: "GET"
        url: "/api/v1/vendor_templates/refresh_templates"
        isArray: false
      forPaf:
        method: 'GET'
        url: "/api/v1/subscription_document_definitions/for_paf"
        isArray: true
      forInvestment:
        method: 'GET'
        url: "/api/v1/subscription_document_definitions/for_investment"
        isArray: true
    )

    @resource.all = ->
      @resource.query()

    return @resource
