angular.module('services').factory "InvestmentStatusLabels", ($http) ->
  service =
    wlpPafLabels: (params) ->
      $http({
        url: "/api/v1/investment_status_labels",
        method: "GET",
        params: params
      })

  return service
