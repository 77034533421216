angular.module('services').factory "Funds",
  ($resource, $q) ->
    profileFields = [
      "about"
      "competitive_advantages"
      "count"
      "created_at"
      "currency"
      "custom_tab_label"
      "custom_tab_content"
      "custom_promo"
      "disclaimer_html"
      "disclaimer"
      "display_name"
      "fields"
      "firm_id"
      "firm_logo_url"
      "firm_medium_logo_url"
      "full_width_summary"
      "fund_document_ids"
      "has_video"
      "has_uploaded_files"
      "id"
      "market"
      "name"
      "performance_chart_title"
      "profile_subtitle"
      "private_access_fund"
      "brightcove_video_id"
      "roadshow"
      "roadshow_override"
      "set_full_width_summary"
      "strategy"
      "updated_at"
      "vintage"
    ]

    resource = $resource("/api/v1/funds/:id/:resource/:attr/:attr2",
      id: "@id"
      action: "@action"
      attr: "@attr"
      attr2: "@attr2"
      resource: "@resource"
      "fields[]": profileFields
    ,
      update:
        method: "PATCH"

      getWithoutButter:
        method: "GET"
        headers:
          "Butter-Bar": false

      queryWithoutButter:
        method: "GET"
        isArray: true
        headers:
          "Butter-Bar": false

    )

    acceptFundDisclaimer = (id) ->
      resource.save(
        id: id
        resource: "accept_fund_disclaimer"
      ).$promise

    prepareDocument = (fundId, docId) ->
      resource.get(
        id: fundId
        resource: "fund_documents",
        attr: docId
        attr2: 'prepare_document'
      ).$promise

    find = (fundId, hitKey = null) ->
      dfd = $q.defer()
      params = if hitKey? then {id: fundId, hit_key: hitKey} else {id: fundId}
      query = if butterBar then resource.get(params) else resource.getWithoutButter(params)
      butterBar = true
      query.$promise

    fundDocuments = (id, attr, attr2, key, groupBy) ->
      resource.query(
        id: id
        resource: "fund_documents"
        attr: attr
        attr2: attr2
        key: key
        group_by: groupBy
      ).$promise

    hits = (id, attr, key, groupBy) ->
      resource.query(
        id: id
        resource: "hits"
        attr: attr
        key: key
        group_by: groupBy
      ).$promise

    logPrint = (id) ->
      resource.save(
        id: id
        resource: "log_print"
      ).$promise

    fundOfferings = ->
      fo = $resource("/api/v1/funds/fund_offerings")
      fo.query(
        resource: "fund_offerings"
        cache: true
      ).$promise

    butterBar = true
    withoutButter = ->
      butterBar = false
      return service

    service = {
      acceptFundDisclaimer: acceptFundDisclaimer
      find: find
      fundDocuments: fundDocuments
      hits: hits
      logPrint: logPrint
      fundOfferings: fundOfferings
      withoutButter: withoutButter
      prepareDocument: prepareDocument
    }
