angular.module 'icn.components.fund_selector'
  .factory "FundSelectorFilterer", ->
    self = this

    @namedFilters = [
      {
        name: 'with-capital-call-schedules-only'
        filterFn: (fund) ->
          fund.subscription_processing_only == false
      }
      ,
      {
        name: 'with-capital-call-schedules-only-and-not-blacklisted'
        filterFn: (fund) ->
          fund.subscription_processing_only == false && fund.blacklisted == false
      }
      ,
      {
        name: 'not-blacklisted'
        filterFn: (fund) ->
          fund.blacklisted == false
      },
      {
        name: 'with-redemptions'
        filterFn: (fund) ->
          fund.has_redemptions == true
      }
    ]

    @getFilteredFunds = (unfilteredFunds) ->
      if @filterName
        f = _.find @namedFilters, (namedFilter) ->
          namedFilter.name == self.filterName
        @filter = f?.filterFn
      else
        @filter = null

      if @filter
        unfilteredFunds.filter @filter
      else
        unfilteredFunds

    return this
