angular.module('directives').directive "icnFundDocuments", ->
  restrict: "E"
  scope:
    title: "="
    documents: "="
    hideFundDocuments: "="
    onDocumentView: "=?"
  controller: ($scope, $window, TrackingService) ->
    if _.isUndefined($scope.onDocumentView)
      $scope.onDocumentView = (document) -> return

    $scope.limitDocuments = 3

    $scope.viewDocument = (document) ->
      url = "/api/v1/funds/#{document.fund.id}/fund_documents/#{document.id}/view_document"
      $window.open url, "_blank"
      $scope.onDocumentView(document)
      TrackingService.fundAction(document.fund, 'Click on View: ' + document.file_name)
      return

  templateUrl: "templates/directives/fund_documents.html"
