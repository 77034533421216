angular.module('controllers').controller "placementFeeDialogCtrl",
  ($scope, $modal, $modalInstance, InvestmentService, investment,
  resetNetCommitment, NetFeeAgreementService, currency, PlacementFeeRangeSelector, $filter) ->
    $scope.investment = investment
    $scope.placementFeeRange =
      PlacementFeeRangeSelector.forAmount($scope.investment, $scope.investment.amount)
    $scope.allowedPlacementFees =
      if $scope.placementFeeRange.allowed_placement_fees?
        $scope.placementFeeRange.allowed_placement_fees.sort()
      else []
    $scope.withAllowedPlacementFees = $scope.allowedPlacementFees.length > 0
    $scope.currency = currency
    $scope.commitmentText =
      if $scope.investment.paf_type == 'Hedge Fund'
        'Subscription'
      else 'Commitment'
    $scope.grossAmountText = "Gross #{$scope.commitmentText} Amount"
    $scope.netAmountText = "Net #{$scope.commitmentText} Amount"

    $scope.isNetPlacementFee = NetFeeAgreementService.isNetFeeAgreement($scope.investment)

    $scope.dialogHeader =
      if $scope.isNetPlacementFee
        "Net Placement Fee"
      else
        "Placement Fee"

    $scope.placementFeeDialogText =
      if $scope.isNetPlacementFee
        "Your firm has allowed for the collection of an upfront, one-time placement fee \
        for this investment as part of the subscription process."
      else
        "Your firm has allowed for the collection of an upfront, one-time placement fee \
        for this investment as part of the subscription process. If you are intending \
        to collect such placement fee for the investor, enter the % rate in the box below."

    determinePlacementFee = ->
      return $scope.allowedPlacementFees[0] if $scope.allowedPlacementFees.length > 0
      return $scope.investment.placement_fee if $scope.investment.placement_fee
      if $scope.placementFeeRange.max_placement_fee &&
          $scope.placementFeeRange.max_placement_fee == $scope.placementFeeRange.min_placement_fee
        $scope.placementFeeRange.max_placement_fee
      else
        0.0

    $scope.placementForm = {
      placement_fee: determinePlacementFee()
    }

    validWithBoundsOrAllowedPlacementFees = ->
      (placementFeeInsideBounds() &&
      !$scope.mandatoryPlacementFeeChanged()) ||
      $scope.withAllowedPlacementFees

    $scope.validPlacementFee = ->
      $scope.placementForm.placement_fee != null &&
      validWithBoundsOrAllowedPlacementFees()

    placementFeeInsideBounds = ->
      $scope.placementForm.placement_fee >= 0 &&
        !$scope.placementFeeOverMaxValue() && !$scope.placementFeeUnderMinValue()

    $scope.placementFeeOverMaxValue = ->
      $scope.placementFeeRange.max_placement_fee &&
      $scope.placementForm.placement_fee > $scope.placementFeeRange.max_placement_fee

    $scope.placementFeeUnderMinValue = ->
      $scope.placementFeeRange.min_placement_fee &&
        $scope.placementForm.placement_fee < $scope.placementFeeRange.min_placement_fee

    $scope.mandatoryPlacementFeeChanged = ->
      $scope.placementFeeRange.max_placement_fee &&
        $scope.placementFeeRange.max_placement_fee == $scope.placementFeeRange.min_placement_fee &&
        $scope.placementForm.placement_fee != $scope.placementFeeRange.min_placement_fee

    $scope.cancelDialog = ->
      $modalInstance.dismiss()

    $scope.netCommitmentAmount = ->
      NetFeeAgreementService.applyNetFee(
        $scope.investment.amount,
        $scope.placementForm.placement_fee,
        $scope.investment.placement_fee,
        resetNetCommitment
      )

    $scope.netPlacementFee = -> $scope.placementForm.placement_fee || ""

    $scope.grossCommitment =
      if $scope.investment.placement_fee && $scope.investment.amount == $scope.investment.commitment
        NetFeeAgreementService.revertNetFee($scope.investment.amount, $scope.investment.placement_fee)
      else
        $scope.investment.amount

    $scope.netFeeAmount = ->
      if $scope.placementForm.placement_fee
        $filter('currency')(-$scope.grossCommitment + $scope.netCommitmentAmount(false), "", 0)

    $scope.updatePlacementFee = ->
      if $scope.isNetPlacementFee
        updatedCommitment = NetFeeAgreementService.applyNetFee(
          $scope.investment.amount,
          $scope.placementForm.placement_fee,
          $scope.investment.placement_fee,
          resetNetCommitment
        )
        $scope.investment.amount = updatedCommitment
        if updatedCommitment
          $scope.investment.has_net_commitment = true

      $modalInstance.close(
        {
          investment: $scope.investment,
          placementFee: $scope.placementForm.placement_fee,
          subAdvisoryFee: null
        }
      )
