angular.module('services').factory "Firms",
  ($resource) ->
    fields = [
      "id"
      "name"
      "background"
      "multiple"
      "vintage"
      "private_access_fund"
      "updated_at"
    ]
    addressesFields = [
      "id"
      "addressable_id"
      "addressable_type"
      "address1"
      "address2"
      "city"
      "state"
      "zip"
      "country"
      "telephone"
      "main_address"
      "created_at"
      "updated_at"
      "preqin_id"
    ]

    resource = $resource("/api/v1/firms/:id/:resource",
      id: "@id"
      resource: "@resource"
      query: "@query"
      "fields[]": fields
    ,
      getWithoutButter:
        method: "GET"
        isArray: false
        headers:
          "Butter-Bar": false

      queryWithoutButter:
        method: "GET"
        isArray: true
        headers:
          "Butter-Bar": false

      update:
        method: "PATCH"

      firmAutocomplete:
        url: "/api/v1/firms/autocomplete"
        method: "GET"
        isArray: true
        headers:
          "Butter-Bar": false

      firmsForRegistration:
        url: "/api/v1/firms/firms_for_registration"
        method: "GET"
        headers:
          "Butter-Bar": false

      fa_feature:
        url: "/api/v1/firms/:id/fa_feature"
        method: "GET"
        isArray: false
        headers:
          "Butter-Bar": false

      financial_advisor_settings:
        url: "/api/v1/firms/:id/financial_advisor_settings"
        method: "GET"
        isArray: false
        headers:
          "Butter-Bar": false
    )

    resource.find = (firm_id) ->
      resource.get(
        id: firm_id
      ).$promise

    resource.getAddresses = (firm_id) ->
      resource.query(
        id: firm_id
        resource: "addresses"
        "fields[]": addressesFields
      ).$promise

    resource.autocomplete = (query) ->
      resource.firmAutocomplete(
        query: query
      ).$promise

    resource.firmsForRegistrationAutocomplete = (query) ->
      resource.firmsForRegistration(
        query: query
        "fields[]": null
      ).$promise

    resource.faFeature = (firm_id) ->
      resource.fa_feature(
        id: firm_id
      ).$promise

    resource.financialAdvisorSettings = (firm_id) ->
      resource.financial_advisor_settings(
        id: firm_id
      ).$promise

    resource
