angular.module('directives').directive "investorProfileTopNav", ->
  restrict: "E"
  templateUrl: "areas/no_tab/components/investor_profile_top_nav/_top_nav.html"
  scope:
    user: "="
    invProfId: "="
    investorProfile: "="
    hasNewIssuesStep: "="

  controller: ($scope, $routeParams, IcnIdentity) ->
    $scope.currentStep = parseInt($routeParams.id)
    $scope.showContactInfo = true
    $scope.newIssues = $routeParams.newIssues == 'true'
    $scope.showNewIssues = undefined

    if IcnIdentity.isWhiteLabel()
      if !IcnIdentity.whiteLabelPartner().paf_phone_number || !IcnIdentity.whiteLabelPartner().paf_email_address
        $scope.showContactInfo = false
      else
        $scope.phoneNumber = IcnIdentity.whiteLabelPartner().paf_phone_number
        $scope.emailAddress = IcnIdentity.whiteLabelPartner().paf_email_address
    else
      $scope.phoneNumber = "212 994 7333"
      $scope.emailAddress = "ir@icapitalnetwork.com"

    $scope.$watch "investorProfile", (newValue) ->
      if newValue
        $scope.showCompleteCircle = $scope.investorProfile.required_documents_provided
        $scope.showNewIssues = ($scope.investorProfile && $scope.investorProfile.new_issues_profile) || $scope.newIssues
