angular.module('services').factory "ModalResponsabilityChainService",
  (TermsOfUseModal, InvestorAcknowledgementModal, QualificationModal, OneTimePopupModal, IcnIdentity) ->

    initialized = false

    reInitialize = ->
      initialized = false

    # Check for needs to show modals
    # Terms of Use -> Investor Acknowledgement -> One Time Popup
    call = ->
      return if !IcnIdentity.user || initialized

      initialized = true

      TermsOfUseModal.show().then( ->
        InvestorAcknowledgementModal.show().then( ->
          QualificationModal.show().then( ->
            OneTimePopupModal.show().then( ->
              reInitialize() # Everything ok
            ).catch( (response) ->
              reInitialize() # "Error calling OneTimePopupModal
            )
          ).catch( (response) ->
            reInitialize() # Error calling QualificationModal
          )
        ).catch( (response) ->
          reInitialize() # Error calling InvestorAcknowledgementModal
        )
      ).catch( (response) ->
        reInitialize() # Error calling TermsOfUseModal
      )

    { call: call }
