angular.module('directives').directive "sameAs", ->
  require: "ngModel"
  link: (scope, elm, attrs, ctrl) ->
    ctrl.$parsers.unshift (viewValue) ->
      if viewValue is scope[attrs.sameAs]
        ctrl.$setValidity "sameAs", true
        viewValue
      else
        ctrl.$setValidity "sameAs", false
        undefined
