angular.module('controllers').controller 'InvestorSelectorCtrl', ($scope) ->

angular.module('directives').directive 'investorSelector', ->
  return {
    templateUrl: 'areas/common/investor_selector.html'
    restrict: 'E'
    scope:
      investors: '='
      investor: '='
      displayInline: '@'
  }
