angular.module('directives').directive 'multiValidate', ($parse, $rootScope) ->
  restrict: 'A'
  require: 'ngModel'
  link: (scope, elem, attrs, ngModelCtrl) ->
    validate = $parse(attrs.multiValidate)(scope)
    ngModelCtrl.$viewChangeListeners.push ->
      $rootScope.$broadcast 'multiValidate:valueChanged'
    deregisterListener = scope.$on('multiValidate:valueChanged', (event) ->
      ngModelCtrl.$setValidity 'multiValidate', validate()
    )
    scope.$on '$destroy', deregisterListener
