angular.module('controllers').controller "ManualUploadGPDialog",
  ($scope, $modalInstance, ModalDialogInstancesObserver, InvestmentService, investment, header, $timeout, $window) ->
    $scope.investment = investment
    $scope.header = header
    $scope.uploadStatus = 'none'
    $scope.preview = null
    $scope.fade = true
    $scope.fadeDelay = 0

    $scope.uploadDoc = (files) ->
      if !files
        files = angular.element("input[id='subdoc-file" + $scope.investment.id + "']")[0].files

      $timeout (-> $scope.loading(files)), $scope.fadeDelay
      $scope.fadeDelay = 0

    $scope.loading = (files) ->
      $scope.uploadStatus = 'loading'
      $scope.fade = true

      InvestmentService.uploadGPSignature($scope.investment, files).then(
        (response) ->
          $scope.preview = response.general_partner_signature.thumb_url

          $scope.uploadStatus = 'complete'
          $modalInstance.close(response)

          $timeout (-> $scope.success()), 2000

        (error) ->
          $scope.uploadStatus = 'error'
          $scope.fadeIn()
      )

    $scope.success = (fadeIn = true) ->
      $scope.uploadStatus = 'success'
      $scope.fadeIn()

    $scope.cancelDialog = ->
      $modalInstance.dismiss()

    $scope.closeErrorMessage = ->
      $scope.uploadError = false

    $scope.fadeFileSelect = ->
      angular.element("button[id='upload-select-btn']").addClass('upload-fade')

    $scope.stopFade = ->
      angular.element("button[id='upload-select-btn']").removeClass('upload-fade')

    $scope.fullFade = ->
      $scope.fadeDelay = 1000
      angular.element("button[id='upload-select-btn']").addClass('full-fade')

    $scope.fadeIn = ->
      $timeout (-> angular.element("button[id='upload-select-btn']").removeClass("transparent") ), 500

    $scope.picker = ->
      el = document.getElementById('subdoc-file' + $scope.investment.id)
      if el
        el.click()

    if investment.general_partner_signature.status == 'signed'
      $scope.preview = $scope.investment.general_partner_signature.thumb_url
      $scope.fade = false
      $scope.success()


    dragleaveEventCallBackFunction = (e) ->
      e = e or event
      e.preventDefault()
      if e.target.id == ('subdoc-file' + $scope.investment.id) or e.target.id == 'upload-select-btn'
        $scope.stopFade()

    $window.addEventListener 'dragleave', dragleaveEventCallBackFunction


    dragoverEventCallBackFunction = (e) ->
      e = e or event
      e.preventDefault()
      if e.target.id == ('subdoc-file' + $scope.investment.id) or e.target.id == 'upload-select-btn'
        $scope.fadeFileSelect()

    $window.addEventListener 'dragover', dragoverEventCallBackFunction


    dropEventCallBackFunction = (e) ->
      e = e or event
      files = e.dataTransfer.files
      e.preventDefault()
      if e.target.id == ('subdoc-file' + $scope.investment.id) or e.target.id == 'upload-select-btn'
        $scope.fullFade()
        $scope.uploadDoc(files)

    $window.addEventListener 'drop', dropEventCallBackFunction


    clickEventCallBackFunction = (e) ->
      if e.target.id == ('subdoc-file' + $scope.investment.id) or e.target.id == 'upload-select-btn'
        e = e or event
        unless $scope.pickerInProgress
          $scope.pickerInProgress = true
        else
          e.preventDefault()
          $scope.pickerInProgress = false

    $window.addEventListener 'click', clickEventCallBackFunction


    currentModalInstance = $modalInstance
    currentModalInstance.events = {}

    currentModalInstance.events.dragleave = [$window ,dragleaveEventCallBackFunction]
    currentModalInstance.events.dragover = [$window ,dragoverEventCallBackFunction]
    currentModalInstance.events.drop = [$window ,dropEventCallBackFunction]
    currentModalInstance.events.click = [$window ,clickEventCallBackFunction]

    # dismiss modal dialog box when navigate to other page
    ModalDialogInstancesObserver.setModalDialogInstance(currentModalInstance)
