angular.module('directives').directive "uiSelectChoiceSubmenu", ->
  restrict: "A",
  scope:
    submenuText: '=?'
    submenuAction: '=?'
    submenuActionArg: '=?'

  compile: (tElem, attrs) ->
    angular.element(window).mousemove (event) ->
      subMenu = angular.element('#drop-submenu')

      if subMenu.length != 0
        position = subMenu.offset()
        height = subMenu.outerHeight()
        width = subMenu.outerWidth()

        left = subMenu.attr('hover-start')
        right = position.left + width
        top = position.top
        bottom = position.top + height

        unless event.pageX > left && event.pageX < right && event.pageY >= top && event.pageY < bottom
          subMenu.remove()

    return (scope, element, attrs) ->
      angular.element('.ui-select-choices').on('scroll', -> angular.element('#drop-submenu').remove())

      element.parent().parent().bind 'mouseover', (e) ->
        angular.element('#drop-submenu').remove()
        rowContainer = angular.element(e.currentTarget).find('.ui-select-choices-row-inner')
        topPosition = Math.round(rowContainer.offset().top)
        rightPosition = rowContainer.offset().left + rowContainer.outerWidth()
        height = rowContainer.outerHeight() + 'px'

        link = '<a href="#">' + scope.submenuText + '</a>'
        angular.element('body').append('<div id="drop-submenu">' + link + '</div>')
        subMenu = angular.element('#drop-submenu')
        subMenu.css('top', topPosition)
        subMenu.css('left', rightPosition)
        subMenu.css('height', height)
        subMenu.css('line-height', height)
        subMenu.attr('hover-start', angular.element(e.currentTarget).offset().left)

        subMenu.click (e) ->
          angular.element('#drop-submenu').remove()
          scope.submenuAction(scope.submenuActionArg)

