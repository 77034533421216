angular.module('services').factory '$exceptionHandler', ($log, $window, IcnUtility) ->
  (exception, cause) ->
    $log.error.apply($log, arguments) # Original behavior of $exceptionHandlerProvider

    disableThirdPartyJs = ->
      IcnUtility.hasPartnerProxyAPIPrefix()

    # Prevent IcnIdentity injection errors during Jasmine testing
    if $window.icnBootstrap == undefined
      user_id = ""
    else
      # Don't inject IcnIdentity unless the data exists on the page
      user_id = $window.icnBootstrap?.user?.id

    # Send all errors caught by Angular to HoneyBadger
    if !disableThirdPartyJs()
      return unless $window.Honeybadger

      $window.Honeybadger.notify(exception,
        context:
          user_id: user_id
          path: window.location.href
          cause: cause
      )
