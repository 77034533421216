angular.module('services').factory "DiligenceDocumentService", ($resource, ngDialog, $q, $upload) ->
  service = $resource "/api/v1/diligence_documents/:id",

  remove = (document) ->
    service.delete({id: document.id}).$promise

  uploadDiligenceDocument = (investment, files) ->
    dfd = $q.defer()
    params  =
      url: '/api/v1/diligence_documents'
      method: 'POST'
      file: files[0]
      data : { investment_id: investment.id}

    $upload.upload(params).success (res) ->
      dfd.resolve(res)
    dfd.promise

  uploadDiligenceDocument: uploadDiligenceDocument
  remove: remove
