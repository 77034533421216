angular.module('controllers').controller "hfFundsCapitalReportCtrl",
  ($scope, $modalInstance, header, confirmLabel, cancelLabel, pafs, onSelectedItem, FundsCapitalReportsService,
  PafAdminSvcApi) ->

    $scope.header = header
    $scope.confirmLabel = confirmLabel
    $scope.cancelLabel = cancelLabel
    $scope.pafs = pafs
    $scope.onSelectedItem = onSelectedItem
    $scope.fields =  {
      pafIds: [],
      closedDate: '',
      filename: '',
      transactionType: '',
      custodianIds: [],
      firmIds: [],
      pafGroupsIds: [],
      wlpIds: [],
      fileType: 'csv',
      fromDate: ''
      toDate: ''
    }

    $scope.toMinDate = ''

    $scope.onFromDateChange = ->
      if validDate($scope.fields.fromDate)
        $scope.toMinDate = new Date($scope.fields.fromDate.getTime() + (24 * 60 * 60 * 1000))
        if validDate($scope.fields.toDate) && $scope.fields.fromDate.getDate() >= $scope.fields.toDate.getDate()
          $scope.fields.toDate = ''


    $scope.transactionTypes = [
      {name: 'Subscriptions', id: 'Subscriptions'},
      {name: 'Redemptions', id: 'Redemptions'},
      {name: 'Transfers', id: 'Transfers'},
      {name: 'ThirdParty', id: 'ThirdParty'},
    ]

    validDate = (date) ->
      return date instanceof Date && !isNaN(date)

    $scope.cancel = ->
      $modalInstance.dismiss "no"

    $scope.submit = (fields) ->
      $modalInstance.close(Object.assign({}, fields))

    $scope.selectAllPafs = ->
      $scope.fields.pafIds = $scope.pafs.map((fc) -> fc.id)

    $scope.deselectAllPafs = ->
      $scope.fields.pafIds = []

    $scope.selectAllCustodians = ->
      $scope.fields.custodianIds = $scope.custodians.map((record) -> record.id)

    $scope.deselectAllCustodians = ->
      $scope.fields.custodianIds = []

    $scope.selectAllWlp = ->
      $scope.fields.wlpIds = $scope.wlps.map((record) -> record.id)

    $scope.deselectAllWlp = ->
      $scope.fields.wlpIds = []

    $scope.selectAllGroups = ->
      $scope.fields.pafGroupsIds = $scope.pafGroups.map((record) -> record.id)

    $scope.deselectAllGroups = ->
      $scope.fields.pafGroupsIds = []

    $scope.selectAllFirms = ->
      $scope.fields.firmIds = $scope.firms.map((record) -> record.id)

    $scope.deselectAllFirms = ->
      $scope.fields.firmIds = []

    $scope.showDatePicker = ->
      return $scope.fields.transactionType && $scope.fields.transactionType != 'ThirdParty'

    $scope.canShowFunds = ->
      return $scope.fields.pafGroupsIds.length == 0

    $scope.canShowFundGroups = ->
      return $scope.fields.pafIds.length == 0

    init = ->
      pafs_ids =  $scope.pafs.map((fc) -> fc.id)
      FundsCapitalReportsService.filtersByPafs(pafs_ids).then (response) ->
        $scope.custodians = response['custodians']
        $scope.wlps = response['wlps']
        $scope.pafGroups = response['pafGroups']
        $scope.firms = response['firms']

    init()
