angular.module('directives').directive 'internalNotesList', ->
  templateUrl: 'areas/aml_approval/components/internal_notes/internal_notes_list.html'
  restrict: 'E'
  scope:
    investment: '='
    whiteLabelPartnerId: '=?'
    mode: '=?'
    investorProfileInternalNotes: '='
    investmentInternalNotes: '='
    selectedTab: '='
    updateCounter: '&?'

  controller: ($scope, $location, $routeParams, $modal, IcnIdentity, NoteService) ->
    $scope.currentUser = IcnIdentity.user
    $scope.isWhiteLabel = IcnIdentity.isWhiteLabel()
    $scope.confirmDelete = (id) ->
      opts =
        templateUrl: "dialogs/custom_confirm_dialog/custom_confirm.html"
        controller: "customConfirmDialogCtrl"
        windowClass: "small-modal"
        resolve:
          header: -> "Note"
          msg: -> "Are you sure you want to delete it?"
          confirm_label: -> "Ok"
          cancel_label: -> "Cancel"
      $modal.open(opts).result.then ->
        deleteInternalNote(id)

    $scope.addNewNote = (noteable_type) ->
      $scope.mode = 'edit'
      $scope.internalNote = emptyInternalNote()
      $scope.internalNote.noteable_type = noteable_type

    $scope.selectedNoteClass = (index, level) ->
      if $scope.mode == 'view' && $scope.note.indexSelected == index && $scope.note.levelSelected == level
        'selected-note'

    $scope.editModeClass = ->
      if $scope.mode == 'edit' then 'edit-mode' else 'view-mode'

    $scope.editInternalNote = (index, level) ->
      if (level == 'InvestorProfile')
        $scope.internalNote = $scope.investorProfileInternalNotes[index]
      else
        $scope.internalNote = $scope.investmentInternalNotes[index]
      $scope.internalNoteBackup = _.clone($scope.internalNote)
      $scope.note.indexSelected = index
      $scope.mode = 'edit'

    $scope.openInternalNote = (index, level) ->
      $scope.clearErrorMsg()
      if $scope.mode == 'view'
        $scope.note.levelSelected = level
        $scope.note.indexSelected = index
      if $scope.mode == 'edit'
        if $scope.note.indexSelected == index && $scope.note.levelSelected == level
          return
        else
          opts =
            templateUrl: "dialogs/custom_confirm_dialog/custom_confirm.html"
            controller: "customConfirmDialogCtrl"
            windowClass: "small-modal"
            resolve:
              header: -> "Unsaved Notes"
              msg: -> "You are about to leave this note without saving your changes. Would you like to continue?"
              confirm_label: -> "Yes"
              cancel_label: -> "Cancel"
          $modal.open(opts).result.then ->
            $scope.note.levelSelected = level
            $scope.note.indexSelected = index
            $scope.mode = 'view'

    $scope.showInternalNote = (index, level) ->
      $scope.mode == 'view' && $scope.note.indexSelected == index && $scope.note.levelSelected == level

    $scope.updateNote = ->
      if !$scope.internalNote.editable_subject
        $scope.errorMsg = 'Note subject cannot be empty'
        return

      if !$scope.internalNote.editable_body
        $scope.errorMsg = 'Note body cannot be empty'
        return

      setNoteable()
      $scope.internalNote.subject = $scope.internalNote.editable_subject
      $scope.internalNote.body = $scope.internalNote.editable_body
      params = { id: $scope.internalNote.id, note: $scope.internalNote }
      NoteService.update(params.id, params).$promise.then (res) ->
        $scope.note.levelSelected = $scope.internalNote.noteable_type
        if ($scope.note.levelSelected == 'InvestorProfile')
          $scope.investorProfileInternalNotes[$scope.note.indexSelected] = res.note
        else
          $scope.investmentInternalNotes[$scope.note.indexSelected] = res.note
        $scope.investment.with_internal_notes = true
        clearFormAndChangeToViewMode()
      , (error) ->
        index = $scope.note.indexSelected
        if ($scope.note.levelSelected == 'InvestorProfile')
          $scope.investorProfileInternalNotes[index] = _.clone($scope.internalNoteBackup)
        else
          $scope.investmentInternalNotes[index] = _.clone($scope.internalNoteBackup)
        $scope.internalNote = _.clone($scope.internalNoteBackup)
        $scope.errorMsg = error.data.message

    $scope.createNote = ->
      if !$scope.internalNote.editable_subject
        $scope.errorMsg = 'Note subject cannot be empty'
        return
      
      if !$scope.internalNote.editable_body
        $scope.errorMsg = 'Note body cannot be empty'
        return

      setNoteable()
      $scope.internalNote.subject = $scope.internalNote.editable_subject
      $scope.internalNote.body = $scope.internalNote.editable_body
      $scope.internalNote.context = if currentUserCannotAddExternalNotes() then "internal" else "external"
      params = { note: $scope.internalNote, investment_id: $scope.investment.id, wlp_id: $scope.whiteLabelPartnerId }
      NoteService.save(params).$promise.then (res) ->
        $scope.note.levelSelected = $scope.internalNote.noteable_type
        if ($scope.note.levelSelected == 'InvestorProfile')
          notes = $scope.investorProfileInternalNotes
          $scope.investorProfileInternalNotes = [res.note].concat notes
        else
          notes = $scope.investmentInternalNotes
          $scope.investmentInternalNotes = [res.note].concat notes
        $scope.note.indexSelected = 0
        $scope.investment.with_internal_notes = true
        clearFormAndChangeToViewMode()
        $scope.updateCounter({ value: 1 })
      , (error) ->
        $scope.errorMsg = error.data.message

    deleteInternalNote = (id) ->
      params = { id: id, investment_id: $scope.investment.id }
      NoteService.delete(params).$promise.then ->
        if ($scope.note.levelSelected == 'InvestorProfile')
          $scope.investorProfileInternalNotes.splice($scope.note.indexSelected, 1)
        else
          $scope.investmentInternalNotes.splice($scope.note.indexSelected, 1)
        initialNoteState()
        
        $scope.updateCounter({ value: -1 })

    emptyInternalNote = ->
      { id: '', subject: '', body: '', noteable_id: '', noteable_type: 'Investment', context: '' }

    setNoteable = ->
      if ($scope.internalNote.noteable_type == 'InvestorProfile')
        $scope.internalNote.noteable_id = $scope.investment.investor_profile_id
      else
        $scope.internalNote.noteable_id = $scope.investment.id

    clearFormAndChangeToViewMode = ->
      $scope.clearErrorMsg()
      $scope.internalNote = emptyInternalNote()
      $scope.mode = 'view'

    initialNoteState = ->
      $scope.mode = 'view'
      $scope.note = { indexSelected: 0, levelSelected: 'Investment' }

    countInternalNotes = ->
      $scope.investmentInternalNotes.length + $scope.investorProfileInternalNotes.length

    $scope.noteViewOnlyMode = (internal_note) ->
      $scope.currentUser.id != internal_note.created_by.id || !IcnIdentity.canEditSubReviewNotes()

    currentUserCannotAddExternalNotes = ->
      !IcnIdentity.canAddExternalNotes() ||
      $scope.selectedTab != 'iCapital'

    isWlpInvestmentAndNotWlpUser = ->
      $scope.whiteLabelPartnerId &&
      !$scope.isWhiteLabel

    $scope.$watchGroup ['investment', 'selectedTab'], ->
      $scope.viewOnlyMode =
          if $scope.selectedTab == 'iCapital'
            $scope.investment && currentUserCannotAddExternalNotes()
          else
            $scope.investment && isWlpInvestmentAndNotWlpUser()

    $scope.$watch 'mode', ->
      $scope.internalNote.editable_subject = $scope.internalNote.subject
      $scope.internalNote.editable_body = $scope.internalNote.body

    $scope.newNotePlaceholder = ->
      if $scope.internalNote.required
        "Comments are required for approving investments with Validation Failures. Please provide the
         rationale for approving this investment."
      else
        "Add New Note..."

    noteTabsDescription = ->
      return "" if !$scope.hasNoteTabsDescription
      if $scope.selectedTab == 'iCapital - Internal Only'
        return "This section is for internal notes only. Notes posted here will not be visible to external partners."
      if $scope.selectedTab == 'iCapital'
        return "Notes posted here will be visible to external partners."

    $scope.$watch 'selectedTab', ->
      $scope.hasNoteTabsDescription = $scope.selectedTab == 'iCapital - Internal Only' ||
       ($scope.selectedTab == 'iCapital' && IcnIdentity.canAddExternalNotes())
      $scope.noteTabsDescription = noteTabsDescription()

    init = ->
      $scope.errorMsg = ""
      $scope.clearErrorMsg = -> $scope.errorMsg = ""
      $scope.investment = {}
      $scope.whiteLabelPartnerId = null
      $scope.internalNote = emptyInternalNote()
      initialNoteState()

    init()
