angular.module('controllers').controller "CancelRedemptionCtrl",
  ($scope, $modalInstance, Redemptions, redemption, pafId) ->
    $scope.cancel = -> $modalInstance.dismiss()

    $scope.ok = ->
      Redemptions.cancel(pafId, redemption).then( ->
        redemption.status = 'canceled'
        redemption.display_status = 'Canceled'

        $modalInstance.close()
      , (response) ->
        $modalInstance.dismiss(response.data)
      )
