angular.module('directives').directive "commonModal", ->
  restrict: "E"
  replace: true
  transclude: true
  templateUrl: 'components/common_modal/common_modal.html'
  scope:
    modalHeader: '@'
    customized: '@'
  controller:
    ($scope, TopModalService, $rootScope, $element) ->
      $scope.topModal = {show: true}
      $scope.scopeObject = { scope: $scope, name: $scope.modalHeader }

      TopModalService.registerModal($scope.scopeObject, ->
        $scope.check()
      )

      $scope.check = ->
        $scope.topModal = {show: TopModalService.topModal($scope.scopeObject)}
        if $scope.topModal.show
          $element.parent().show()
        else
          $element.parent().hide()
        $scope.safeApply()

      $scope.safeApply = ->
        phase = $scope.$root.$$phase
        $scope.$apply() if phase != '$apply' && phase != '$digest'
