angular.module('services').factory "OneTimePopups", ($resource, $q) ->
  resource = $resource("/api/v1/one_time_popups/",
    id: '@id'
  ,
    mostRecent:
      url: "/api/v1/one_time_popups/most_recent"
      method: "GET"

    markAsRead:
      url: "/api/v1/one_time_popups/:id/mark_as_read"
      method: "PATCH"
  )


  mostRecent = ->
    resource.mostRecent().$promise

  markAsRead = (popup) ->
    resource.markAsRead({ id: popup.id }).$promise

  mostRecent: mostRecent
  markAsRead: markAsRead
