angular.module('directives').directive "slidable", ->
  scope:
    items: "="
    itemsCount: '=?'
    totalAmount: '=?'
    closed: "=?"
    filtered: "=?"
    toggle: "&?"
    fromSubReview: "=?"
  bindToController: true
  controllerAs: "slidable"
  controller: ($scope) ->
    self = this

    if self.closed == undefined || self.toggle == undefined
      self.closed = false

    self.toggleClosed = ->
      return if self.countItems() <= 0
      if self.toggle
        self.toggle()
      else
        self.closed = !self.closed

    $scope.$watch ->
      self.items
    , (newVal, oldVal) ->
      if newVal?.length != oldVal?.length
        return if self.filtered
        self.closed = self.closed

    self.countItems = ->
      itemTotalLength = self.items?.length || 0
      return itemTotalLength if !self.itemsCount
      if self.itemsCount < itemTotalLength
        itemTotalLength
      else
        self.itemsCount
