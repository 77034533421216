angular.module('controllers').controller "InvestorProfileCtrl",
($scope, $q, $routeParams, $rootScope, $location, IcnIdentity, Users, ContactCard, InvestorProfileService,
 WhiteLabelPartnerService, InvestmentService) ->
  $scope.currentStep = parseInt($routeParams.id) or 1
  $scope.invProfId = $routeParams.invProfId
  $scope.pafId = $routeParams.private_access_fund_id
  $scope.pafOverride = $routeParams.paf_override
  $scope.investmentId = $routeParams.investment_id
  $scope.investorProfile = undefined
  $scope.batchUpdateOptions =
    live: null
    frozen: null

  $scope.isReadOnlyField = (field) ->
    profileVisibilityValue(field) == 'read_only'

  $scope.isHiddenField = (field) ->
    profileVisibilityValue(field) == 'hidden'

  $scope.init = ->
    $scope.isReady = false

    wlpPromise = WhiteLabelPartnerService.current().then (response) ->
      $scope.whiteLabelPartner = response.white_label_partner
      $scope.isReady = true

    invProPromise = InvestorProfileService.show(
      $scope.invProfId
    ).$promise.then (res) ->
      $scope.investorProfile = res.investor_profile
      if $scope.investorProfile
        $scope.qpcContact = res.investor_profile.contact_card

        if $scope.currentStep > 1
          $scope.batchUpdateOptions =
            live: $routeParams.live || false
            frozen: $routeParams.frozen || false

        if $scope.qpcContact.name == ""
          $scope.qpcContact.name = $scope.qpcContact.email
      else
        $scope.investorProfile = IcnIdentity.user.profile

    if IcnIdentity.isExternalApprover()
      return $scope.status = $routeParams.investment_status if ($routeParams.from == 'client_management' ||
                                                                $routeParams.from == 'investment_management') &&
                                                               $routeParams.investment_status
      InvestmentService.show($scope.investmentId).then (res) ->
        $scope.status = res.status

    $q.all([wlpPromise, invProPromise]).then ->
      wlp = $scope.whiteLabelPartner
      inv_pro = $scope.investorProfile
      if wlp && wlp.suppress_investor_communications_default
        unless inv_pro.section_b_ria_access
          inv_pro.section_b_ria_access = '0'
        if !inv_pro.section_b_email_only_ria && inv_pro.section_b_ria_access != '1'
          inv_pro.section_b_email_only_ria = '1'


  $scope.reloadProfile = ->
    $scope.init()

  $scope.init()

  $scope.isReadOnlyRole = ->
    IcnIdentity.isRia() || IcnIdentity.isAdminOrIr() || IcnIdentity.isSalesManager() || IcnIdentity.isHomeOffice() ||
    IcnIdentity.isBranchManager()

  $scope.statusPassedSuitabilityReview = ->
    $scope.status == 'IR Review' || $scope.status == 'Compliance Review' || $scope.status == 'Approved' ||
    $scope.status == 'Accepted'

  profileVisibilityValue = (field) ->
    # see 'areas/wlp_admin/components/profile_visibility_form.coffee'
    # for information on possible values
    visibility = 'default'
    visibility = $scope.whiteLabelPartner.profile_visibility[field] if hasProfileVisibilityField(field)

    if ($scope.isReadOnlyRole() && !IcnIdentity.canEditInvestorProfiles() && visibility == 'default') ||
       (IcnIdentity.isExternalApprover() && $scope.statusPassedSuitabilityReview()) ||
       (IcnIdentity.isPafAdmin() && !IcnIdentity.canEditInvestorProfiles())

      visibility = 'read_only' if visibility == 'default'

    # currently will check the permission for Individual investor only
    if IcnIdentity.isIndividualInvestor() &&
       IcnIdentity.canViewInvestorProfiles() &&
       !IcnIdentity.hasEditInvestorProfilesPermission()
      visibility = 'read_only'

    visibility

  hasProfileVisibilityField = (field) ->
    !!(_.isObject($scope.whiteLabelPartner) and
        $scope.whiteLabelPartner.hasOwnProperty('profile_visibility') and
          $scope.whiteLabelPartner.profile_visibility.hasOwnProperty(field))
