angular.module('filters').filter 'truncateCenter', ->
  (text, maxLength, separator) ->
    text = text.replace(/^\s+|\s+$/g, '')
    maxLength ?= 10
    separator ?= '...'

    return text if (maxLength > text.length)

    separatorLength = separator.length
    maxLength = maxLength - separatorLength
    truncationStart = Math.ceil(maxLength / 2)
    truncationLength = text.length - maxLength

    text.substr(0, truncationStart) + separator + text.substr(truncationStart + truncationLength)
