angular.module('directives').directive "investorProfileInterestedParties", (IcnIdentity) ->
  restrict: "E"
  templateUrl: "areas/no_tab/investor_profile/components/interested_parties/interested_parties.html"
  scope:
    investorProfile: "="
    isReadOnlyField: "="
    isHiddenField: "="
    submitted: "="
    formErrorMsgs: "="

  link: (scope, element, attrs) ->
    scope.max_num_parties = 5
    scope.interested_party_template = {
      address: '',
      city: '',
      name: '',
      relationship_to_investor: '',
      state: '',
      zip: ''
    }

    scope.$watch 'investorProfile', (newVal, oldVal) ->
      if newVal
        if scope.investorProfile.section_c_copy_interested_parties == '1'
          scope.showInterestedParties = true
        else
          scope.showInterestedParties = false

    scope.addErrorMessage = (valid, error_key, message) ->
      scope.formErrorMsgs[error_key] = message if !valid
      delete scope.formErrorMsgs[error_key] if valid

    scope.validInterestedParties = ->
      return false unless scope.investorProfile
      return true if IcnIdentity.isAdminOrIr()
      valid = !_.isEmpty(scope.investorProfile.section_c_copy_interested_parties)
      scope.addErrorMessage(valid, "interested_parties", "Interested Parties section is required.")
      return !!valid

    scope.deleteInterestedParty = (interestedPartyNum) ->
      scope.investorProfile.interested_parties.splice(interestedPartyNum - 1, 1)

    scope.deleteAllInterestedParties = ->
      scope.showInterestedParties = false
      scope.investorProfile.interested_parties = []

    scope.initInterestedParties = ->
      scope.showInterestedParties = true
      scope.investorProfile.interested_parties = []
      scope.addInterestedParty()

    scope.addInterestedParty = ->
      if scope.investorProfile.interested_parties.length < scope.max_num_parties
        scope.investorProfile.interested_parties.push(_.clone(scope.interested_party_template))

    scope.showAddAnother = (interestedPartyNum) ->
      if interestedPartyNum == scope.max_num_parties
        false
      else if interestedPartyNum == scope.investorProfile.interested_parties.length
        true
      else
        false
