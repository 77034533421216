angular.module('directives').directive 'internalNotes', ->
  templateUrl: 'areas/aml_approval/components/internal_notes/internal_notes.html'
  restrict: 'E'
  scope:
    forceReload: '='
    investment: '='
    notesCounter: '&?'
    mode: '=?'

  controller: ($scope, IcnIdentity, NoteService) ->
    $scope.isExternalApprover = IcnIdentity.isExternalApprover()
    $scope.isWhiteLabel = IcnIdentity.isWhiteLabel()
    $scope.canAddExternalNotes = IcnIdentity.canAddExternalNotes()

    $scope.updateCounter = (value) ->
      $scope.totalNotesCount = $scope.totalNotesCount + value

    populateNoteTabs = ->
      $scope.tabs = []
      if $scope.isWhiteLabel
        $scope.tabs.push({ name: $scope.investment.white_label; active: true })
        $scope.tabs.push({ name: "iCapital"; active: false }) if hasExternalNotes()
        $scope.selectedTab = $scope.tabs[0].name
      else
        $scope.tabs.push({ name: "iCapital - Internal Only"; active: true }) if mainUserCanSeeInternalTab()
        $scope.tabs.push({ name: $scope.investment.white_label; active: false }) if mainUserCanSeeWhiteLabelTab()
        $scope.tabs.push({ name: "iCapital"; active: false }) if mainUserCanSeeWhiteLabelExternalTab()
        $scope.selectedTab = $scope.tabs[0].name

    mainUserCanSeeInternalTab = ->
      !$scope.isExternalApprover

    mainUserCanSeeWhiteLabelTab = ->
      $scope.investment.white_label && !$scope.isExternalApprover

    hasExternalNotes = ->
      externalNotesWlpCount() > 0

    mainUserCanSeeWhiteLabelExternalTab = ->
      mainUserCanSeeWhiteLabelTab() && (hasExternalNotes() || $scope.canAddExternalNotes)

    $scope.selectTab = (index) ->
      _.map($scope.tabs, (tab) ->
        tab.active = false
      )
      $scope.tabs[index].active = true
      $scope.selectedTab = $scope.tabs[index].name

    $scope.$watch 'investment', ->
      loadNotesForInvestment()

    $scope.$watch 'forceReload', ->
      if $scope.forceReload
        loadNotesForInvestment()
        $scope.forceReload = false

    loadNotesForInvestment = ->
      if $scope.investment && $scope.investment.id
        $scope.whiteLabelPartnerId = $scope.investment.white_label_partner_id
        $scope.investment_internal_notes = []
        $scope.investment_internal_notes_for_wlp = []
        $scope.investment_external_notes_for_wlp = []
        $scope.investor_profile_internal_notes = []
        $scope.investor_profile_internal_notes_for_wlp = []
        $scope.investor_profile_external_notes_for_wlp = []
        
        populateNoteTabs()
        params = {
          investment_id: $scope.investment.id,
          investor_profile_id: $scope.investment.investor_profile_id,
          wlp_id: $scope.whiteLabelPartnerId
        }
        
        NoteService.query(params).$promise.then (res) ->
          res.filter (n) ->
            n.editable_subject = n.subject
            n.editable_body = n.body
            if n.noteable_type == 'Investment'
              populateInvestmentNotes(n)
            if n.noteable_type == 'InvestorProfile'
              populateInvestorProfileNotes(n)
          if $scope.selectedTab
            $scope.internalNotesCount = internalNotesCount()
          else
            $scope.internalNotesCount = 0
          $scope.wlpNotesCount = notesWlpCount()
          $scope.totalNotesCount = $scope.wlpNotesCount + $scope.internalNotesCount
          $scope.notesCounter({ notesCount: $scope.totalNotesCount })
          # populate again after loading the notes, so we can show the external tabs when the user doesn't have
          # the permission to see the external tab but has external notes.
          populateNoteTabs()

    $scope.$watch 'totalNotesCount', ->
      if $scope.totalNotesCount >= 0
        $scope.notesCounter({ notesCount: $scope.totalNotesCount })

    $scope.$watch 'selectedTab', ->
      $scope.mode = 'view' if $scope.selectedTab

    internalNotesCount = ->
      $scope.investment_internal_notes.length +
      $scope.investor_profile_internal_notes.length

    internalNotesWlpCount = ->
      $scope.investment_internal_notes_for_wlp.length +
      $scope.investor_profile_internal_notes_for_wlp.length

    externalNotesWlpCount = ->
      $scope.investment_external_notes_for_wlp.length +
      $scope.investor_profile_external_notes_for_wlp.length

    notesWlpCount = ->
      internalNotesWlpCount() + externalNotesWlpCount()

    totalNotes = ->
      notesWlpCount() + internalNotesCount()

    populateInvestmentNotes = (note) ->
      if isInternalNote(note)
        $scope.investment_internal_notes.push(note)
      if isWlpExternalNote(note)
        $scope.investment_external_notes_for_wlp.push(note)
      if isWlpNote(note)
        $scope.investment_internal_notes_for_wlp.push(note)

    populateInvestorProfileNotes = (note) ->
      if isInternalNote(note)
        $scope.investor_profile_internal_notes.push(note)
      if isWlpExternalNote(note)
        $scope.investor_profile_external_notes_for_wlp.push(note)
      if isWlpNote(note)
        $scope.investor_profile_internal_notes_for_wlp.push(note)
    
    isInternalNote = (note) ->
      note.context == 'internal' && !isNoteFromCurrentOrAnotherWlp(note)

    isNoteFromCurrentOrAnotherWlp = (note) ->
      note.white_label_partner_id?

    isWlpNote = (note) ->
      isNoteFromCurrentOrAnotherWlp(note) &&
        note.context == 'internal'
    
    isWlpExternalNote = (note) ->
      isNoteFromCurrentOrAnotherWlp(note) &&
        note.context == 'external'

    init = ->
      $scope.internalNotesCount = 0
      $scope.wlpNotesCount = 0
      $scope.totalNotesCount = 0
      $scope.mode = 'view'

    init()
