angular.module('services').factory "Register",
  ($resource, IcnIdentity, $modal) ->
    resource = $resource("/api/v1/registration")

    userTypes = [
      {
        type: "qp"
        name: "Individual Investor"
      }
      {
        type: "ria"
        name: "Financial Advisor or Team Member"
      }
      {
        type: "sfo"
        name: "Single Family Office"
      }
      {
        type: "interested_party"
        name: "Interested Party"
      }
    ]

    getAvailableRegistrationRoles = (hideInvited, role) ->
      if ["investor", "delegation_rep"].includes(role)
        userTypes.push({
          type: role
          name: "Investor Representative"
        })

      if hideInvited
        userTypes = _.filter(userTypes, (userType) ->
          userType.type != 'interested_party'
        )

      if IcnIdentity.isWhiteLabel()
        toBlock = IcnIdentity.whiteLabelPartner().blocked_registration_roles
        _.filter(userTypes, (userType) -> !_.contains(toBlock, userType.type))
      else
        if hideInvited
          toHide = ['qp', 'investor', 'sfo']
          _.filter(userTypes, (userType) ->
            !_.contains(toHide, userType.type)
          )
        else
          userTypes

    openInvalidDomainDialog = (user) ->
      $modal.open({
        templateUrl: 'services/register/dialogs/invalid_domain/invalid_domain_dialog.html',
        resolve: {
          email: ->
            user.email
          firmType: ->
            _.findWhere(userTypes, {type: user.role}).name
        },
        windowClass: 'large-modal',
        controller: 'InvalidDomainCtrl'
      })

    save = (user) ->
      params =
        user:
          email: user.email
          password: user.password
          password_confirmation: user.password_confirmation
          role: user.role
          referral: user.referral
          default_fund: user.default_fund
          white_label_partner_id: IcnIdentity.data.white_label_partner.id
          agree: user.agree
          wlp_agree: user.wlp_agree
          main_terms_id: user.main_terms_id
          wlp_terms_id: user.wlp_terms_id
          country: user.country
          tmi_token: user.tmi_token
          token: user.token
        qps_paf: user.qps_paf
      resource.save(params).$promise

    {
      userTypes: userTypes
      getAvailableRegistrationRoles: getAvailableRegistrationRoles
      openInvalidDomainDialog: openInvalidDomainDialog
      save: save
    }
