angular.module('controllers').controller "ExportDocsDialog",
  ($scope, $location, $modalInstance, $modal, $window, $http, selectedPaf, header,
  SubscriptionReviewExportDocsService, investmentsCount, closes) ->

    $scope.selectedPaf = selectedPaf

    $scope.header = (if (angular.isDefined(header)) then header else "")

    $scope.close = { startDate: '', endDate: '' }

    $scope.investmentsCount = investmentsCount

    $scope.closesInvestmentsCount = closes

    $scope.investmentsSelectedCount = 0

    init = ->
      $scope.closeStartDateOptions = _.map($scope.closesInvestmentsCount, (_, closeDate) -> return closeDate)
      $scope.closeStartDateOptions = _.sortBy($scope.closeStartDateOptions, ((el) -> moment(el)))
      _($scope.closeStartDateOptions).reverse()
      $scope.originalCloseStartDateOptions = $scope.closeStartDateOptions
      $scope.closeEndDateOptions = $scope.closeStartDateOptions

      $scope.statusOptions.splice(0, 1) unless $scope.selectedPaf.allow_reversion
      removeEmptyStatusOptions()
      $scope.acceptedInvestmentsCount = 0
      $scope.totalAcceptedInvestmentsCount =  _.find($scope.investmentsCount, (i) -> i.status == "accepted").count
      SubscriptionReviewExportDocsService.index($scope.selectedPaf.id).then (res) ->
        $scope.generatedZips = res.fund_exported_files

    removeEmptyStatusOptions = ->
      $scope.statusOptions = _.filter($scope.statusOptions,
        (s) -> (_.find $scope.investmentsCount, (option) -> option.status == s.id).count != 0)

    $scope.docTypes = [
      {
        id: 'all_documents'
        label: 'All'
        selected: false
      }
      {
        id: 'entity_formation'
        label: 'Entity Formation'
        selected: false
      }
      {
        id: 'fatca'
        label: 'FATCA'
        selected: false
      }
      {
        id: 'identity'
        label: 'Identity'
        selected: false
      }
      {
        id: 'subscription'
        label: 'Subscription'
        selected: false
      }
      {
        id: 'tax'
        label: 'Tax'
        selected: false
      }
    ]

    $scope.statusOptions = [
      {
        id: 'reverted'
        label: 'Attention Required'
        selected: false
        mappingLabels: ['reverted', 'investment_auto_reverted']
      }
      {
        id: 'preApproval'
        label: 'Pre-Approval'
        selected: false
        mappingLabels: ['pre_approval']
      }
      {
        id: 'preApprovalSecondary'
        label: 'Pre-Approval 2'
        selected: false
        mappingLabels: ['pre_approval_secondary']
      }
      {
        id: 'pendingReview'
        label: 'In Process'
        selected: false
        mappingLabels: ['pending_review']
      }
      {
        id: 'externalReview'
        label: 'External Review'
        selected: false
        mappingLabels: ['external_review']
      }
      {
        id: 'externalSecondaryReview'
        label: 'External Review 2'
        selected: false
        mappingLabels: ['external_secondary_review']
      }
      {
        id: 'irReview'
        label: 'Document Review'
        selected: false
        mappingLabels: ['ir_review']
      }
      {
        id: 'complianceReview'
        label: 'Legal Review'
        selected: false
        mappingLabels: ['compliance_review']
      }
      {
        id: 'approved'
        label: 'Approved'
        selected: false
        mappingLabels: ['approved']
      }
      {
        id: 'accepted'
        label: 'Accepted'
        selected: false
        mappingLabels: ['accepted']
      }
    ]

    $scope.investmentStatusLabel = (status, label) ->
      statusCount = (_.find $scope.investmentsCount, (option) -> option.status == status).count
      "#{label} (#{statusCount})"

    $scope.acceptedInvestmentsSelected = ->
      $scope.statusOptions.some (option) -> option.id == 'accepted' && option.selected

    checkSelectedOptions = (options) ->
      options.some (option) -> option.selected

    selectedStatusOptions = (options) ->
      selected = []
      _.each(options, (option) ->
        selected = selected.concat(option.mappingLabels) if option.selected
      )
      selected

    selectedDocTypes = ->
      selected = []
      return selected if (_.find $scope.docTypes, (option) -> option.id == 'all_documents').selected
      _.each($scope.docTypes, (option) ->
        selected = selected.concat(option.id) if option.selected
      )
      selected

    nonAcceptedInvestmentsCount = ->
      statusSelected = []
      _.each($scope.statusOptions, (el) ->
        statusSelected.push(el.id) if el.selected && el.id != 'accepted'
      )
      selectedInvestments = $scope.investmentsCount.filter (el) -> _.include(statusSelected, el.status)
      $scope.investmentsSelectedCount = selectedInvestments.reduce ((total, el) ->
        total + el['count']
      ), 0

    $scope.$watch "statusOptions", ((newVal, oldVal) ->
      $scope.investmentsSelectedCount = nonAcceptedInvestmentsCount()
      if !$scope.acceptedInvestmentsSelected()
        $scope.close.startDate = ''
        $scope.close.endDate = ''
      else if $scope.acceptedInvestmentsSelected() && $scope.close.startDate == ''
        $scope.investmentsSelectedCount += $scope.totalAcceptedInvestmentsCount
      else if $scope.acceptedInvestmentsSelected() && $scope.close.startDate != ''
        $scope.investmentsSelectedCount += $scope.acceptedInvestmentsCount
    ), true

    $scope.$watch "close.startDate", ((newVal, oldVal) ->
      $scope.close.endDate = '' if newVal == ''
      return if !$scope.acceptedInvestmentsSelected() || newVal == ''
      return if newVal == oldVal
      $scope.closeEndDateOptions = _.filter($scope.originalCloseStartDateOptions, (closeEndDate) ->
        return !moment(closeEndDate).isBefore(newVal)
      )
      return investmentsInCloseDateRange() if $scope.close.endDate != ''
      if newVal != ''
        $scope.investmentsSelectedCount += $scope.closesInvestmentsCount[newVal]
        $scope.acceptedInvestmentsCount = $scope.closesInvestmentsCount[newVal]
        if oldVal == ''
          $scope.investmentsSelectedCount -= $scope.totalAcceptedInvestmentsCount
        else
          $scope.investmentsSelectedCount -= $scope.closesInvestmentsCount[oldVal]
    ), true

    $scope.$watch "close.endDate", ((newVal, oldVal) ->
      if newVal == ''
        $scope.closeStartDateOptions = $scope.originalCloseStartDateOptions
        return
      $scope.closeStartDateOptions = _.filter($scope.originalCloseStartDateOptions, (closeDate) ->
        return !moment(closeDate).isAfter(newVal)
      )
      investmentsInCloseDateRange()
    ), true

    investmentsInCloseDateRange = ->
      startDate = moment($scope.close.startDate, "DD-MMM-YYYY")
      endDate = moment($scope.close.endDate, "DD-MMM-YYYY")
      investmentsInRange = 0
      _.each($scope.closesInvestmentsCount, (value, key) ->
        compareDate = moment(key, "DD-MMM-YYYY")
        investmentsInRange += value if !(compareDate.isBefore(startDate) || compareDate.isAfter(endDate))
      )
      $scope.investmentsSelectedCount -= $scope.acceptedInvestmentsCount
      $scope.investmentsSelectedCount += investmentsInRange
      $scope.acceptedInvestmentsCount = investmentsInRange

    $scope.generateSelectedAMLDocuments = ->
      return if $scope.showNoInvestmentsWarning()

      SubscriptionReviewExportDocsService.create(
        $scope.selectedPaf.id,
        selectedDocTypes($scope.docTypes),
        { investments_status: selectedStatusOptions($scope.statusOptions),
        close_start_date: $scope.close.startDate,
        close_end_date: $scope.close.endDate }
      ).then ->
        $modal.open(
          templateUrl: "dialogs/custom_notify_dialog/custom_notify_dialog.html"
          controller: "customNotifyDialogCtrl"
          windowClass: "small-modal"
          resolve:
            header: ->
              "Your zipfile is being generated..."

            msg: ->
              "You will receive an email when your zipfile is ready. Alternatively, you can refresh this page."

            btn_label: -> "Close"
        )

    $scope.disableGenerateButton = ->
      !checkSelectedOptions($scope.docTypes) ||
      !checkSelectedOptions($scope.statusOptions) ||
      $scope.showNoInvestmentsWarning()

    $scope.showNoInvestmentsWarning = ->
      $scope.statusOptions.length == 0

    $scope.cancelDialog = ->
      $modalInstance.dismiss()

    init()
