angular.module('directives').directive "diligenceDocumentList", ->
  restrict: "E"
  scope:
    documents: "="
  templateUrl: "components/diligence_document_list/diligence_document_list.html"
  controller:
    ($scope, $window, DiligenceDocumentService, Session) ->
      currentUser = () ->
        Session.requestCurrentUser()

      $scope.viewDocument = (document) ->
        url = "/api/v1/diligence_document_files/#{document.id}"
        $window.open url
        return

      $scope.removeDocument = (document) ->
        currentUser().then (user) ->
          DiligenceDocumentService.remove(document).then (resp) ->
            $scope.documents.splice($scope.documents.indexOf(document), 1)
