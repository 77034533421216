// Generated by CoffeeScript 2.4.1
(function() {
  angular.module('directives').directive("addAml", function() {
    return {
      restrict: "E",
      scope: {
        closeAdd: "=",
        investmentId: "=?",
        addDoc: "=",
        investorProfileId: "="
      },
      templateUrl: "components/investor_documents/components/required_document_upload/add_aml.html",
      controller: function($scope, RequiredDocument, InvestmentResource) {
        var approvedInvestment;
        $scope.types = [];
        $scope.model = {};
        $scope.model.selectedType = null;
        RequiredDocument.getUserCreateTypes({
          investment_id: $scope.investmentId
        }).$promise.then(function(response) {
          return $scope.types = response;
        }, function(error) {
          return $scope.types = [];
        });
        if ($scope.investmentId) {
          InvestmentResource.get({
            id: $scope.investmentId
          }).$promise.then(function(investment) {
            return $scope.investmentStatus = investment.status;
          });
        }
        $scope.showNext = function () {
          return !!$scope.model.selectedType
        };
        $scope.addRequiredDoc = function() {
          $scope.loading = true;
          return $scope.requiredDocCreate($scope.model.selectedType).$promise.then(function(response) {
            $scope.loading = false;
            $scope.error = false;
            $scope.closeAdd();
            return $scope.addDoc(response);
          }, function(error) {
            $scope.loading = false;
            return $scope.error = true;
          });
        };
        $scope.requiredDocCreate = function(id) {
          if ($scope.investmentId) {
            return RequiredDocument.createForInvestment({
              'investment_id': $scope.investmentId,
              'required_document_type_id': id,
              'add_approval': true,
              'doc_not_accepted': approvedInvestment()
            });
          } else {
            return RequiredDocument.createForInvestorProfile({
              'investor_profile_id': $scope.investorProfileId,
              'required_document_type_id': id
            });
          }
        };
        return approvedInvestment = function() {
          return $scope.investmentStatus === 'Approved';
        };
      }
    };
  });

}).call(this);
