angular.module('controllers').controller "distributorExportCtrl",
  ($scope, $modalInstance, header, confirmLabel, cancelLabel, DistributorFilesService, PafAdminSvcApi) ->

    $scope.header = header
    $scope.confirmLabel = confirmLabel
    $scope.cancelLabel = cancelLabel

    $scope.availableMonths = []
    $scope.availableYears = []
    $scope.dateCombination = []
    $scope.selectableMonths = []
    $scope.selectableYears = []
    $scope.whiteLabelPartners = []
    $scope.fundGroups = []

    $scope.selectedFields =  {
      month: '',
      year: '',
      wlp: '',
      filename: '',
      fundGroupId: '',
      reportSlug: '',
    }

    getAvailableWLPs = (fundGroupId) ->
      DistributorFilesService.getAvailableWLPs(fundGroupId).then (response) ->
        $scope.whiteLabelPartners = response.white_label_partners

    refreshFundGroups = ->
      PafAdminSvcApi.getFundGroups().then (response) ->
        $scope.fundGroups = _.filter response, (group) ->
          group.slug == 'hedge_focus' || group.slug == 'breit_o'

    getReportDates = (wlpId) ->
      DistributorFilesService.getValidDates(wlpId).then (response) ->
        for period in response.dates
          pushYearToArray(period.year)
          pushMonthToArray(period.month)
          pushDateCombination(period.year, period.month)
      $scope.selectableMonths = $scope.availableMonths
      $scope.selectableYears = $scope.availableYears

    pushYearToArray = (year) ->
      if $scope.availableYears.length == 0
        $scope.availableYears.push({name: year, id: year})
        return
      unless year in $scope.availableYears.map((e) -> e.name)
        $scope.availableYears.push({name: year, id: year})

    pushMonthToArray = (month) ->
      month_name = moment().month(month - 1).format('MMMM')
      if $scope.availableMonths.length == 0
        $scope.availableMonths.push({name: month_name, id: month})
        return
      unless month_name in $scope.availableMonths.map((e) -> e.name)
        $scope.availableMonths.push({name: month_name, id: month})

    pushDateCombination = (year, month) ->
      $scope.dateCombination.push({year: year, month: month})

    $scope.onWlpChange = ->
      find = (i for i in $scope.whiteLabelPartners when i.id is $scope.selectedFields.wlp.id &&
                i.report_slug is $scope.selectedFields.wlp.report_slug)[0]
      if !!find
        $scope.selectedFields.reportSlug = find.report_slug
      getReportDates($scope.selectedFields.wlp.id)

    $scope.onGroupChange = ->
      getAvailableWLPs($scope.selectedFields.fundGroupId)

    $scope.onYearChange = ->
      months = $scope.dateCombination.filter((e) -> e.year == $scope.selectedFields.year).map((e) -> e.month)
      $scope.selectableMonths = $scope.availableMonths.filter((e) -> e.id in months)

    $scope.onMonthChange = ->
      years = $scope.dateCombination.filter((e) -> e.month == $scope.selectedFields.month).map((e) -> e.year)
      $scope.selectableYears = $scope.availableYears.filter((e) -> e.id in years)

    $scope.validSelection = ->
      return !!$scope.selectedFields.wlp.id && !!$scope.selectedFields.year && !!$scope.selectedFields.month

    $scope.cancel = ->
      $modalInstance.dismiss "no"

    $scope.submit = (selectedFields) ->
      year = $scope.selectedFields.year
      month = $scope.selectedFields.month
      day = new Date(year, month, 0).getDate()
      $scope.selectedFields.closeDate = moment("#{year}-#{month}-#{day}").format("MM/DD/YYYY")
      $modalInstance.close(selectedFields)

    init = ->
      refreshFundGroups()

    init()
