angular.module('services').factory 'WlpSpecificAttestationService', ($resource, $http, $log) ->
  resource = $resource '/api/v1/wlp_specific_attestations', null,

    existsForPaf:
      method: 'GET'
      url: '/api/v1/wlp_specific_attestations/exists_for_paf'
      isArray: false

    forInvestment:
      method: 'GET'
      url: '/api/v1/wlp_specific_attestations/for_investment'
      isArray: false

  existsForPaf: (paf_id) ->
    resource.existsForPaf(paf_id: paf_id).$promise

  forInvestment: (investment_id) ->
    resource.forInvestment(investment_id: investment_id).$promise
