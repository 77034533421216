angular.module('controllers').controller "sendSubscriptionAttestationDialogCtrl",
  ($scope, $modal, $modalInstance, attestation, IcnIdentity) ->
    $scope.headerText = "Send Subscription Agreement"

    $scope.confirm = ->
      $modalInstance.close(true)

    $scope.cancelDialog = ->
      $modalInstance.dismiss()

    $scope.attestationId = attestation.id
    $scope.attestationMsg = attestation.message
    $scope.attestationChecked = false

    $scope.showOptionalAttestation = ->
      !!$scope.attestationId &&
      (IcnIdentity.isRia() || IcnIdentity.isBranchManager() || IcnIdentity.isIndividualInvestor())

    $scope.toggleAttestation = ->
      $scope.attestationChecked = !$scope.attestationChecked

    $scope.allowSubmit = ->
      ($scope.attestationChecked || !$scope.attestationId)
