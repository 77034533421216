angular.module('services').factory "Partners", ($resource) ->
  service = $resource "api/v1/partners/:id",
    id: "@id"

  find = (partner_id) ->
    service.get(
      id: partner_id
    ).$promise

  {
    find: find
  }
