angular.module('directives').directive 'icnRedemptionList', (IcnIdentity) ->
  restrict: 'E'
  templateUrl: 'components/redemption_list/redemption_list.html'
  scope:
    privateAccessFund: '='
    ria: '='
    closed: '=?'
    toggle: '='
    holdbackRedemptions: '='
    holdbackRedemptionsSize: '='
    fetchHoldbackRedemptions: '&'
    completedRedemptions: '='
    completedRedemptionsSize: '='
    fetchCompletedRedemptions: '&'
    activeRedemptions: '='
    activeRedemptionsSize: '='
    fetchActiveRedemptions: '&'
  controller: ($scope, Redemptions, $window, CurrencySelector) ->
    init = ->
      $scope.isPafAdmin = IcnIdentity.isPafAdmin()
      $scope.orderByField = 'status'
      $scope.reverseSort = false
      if $scope.privateAccessFund.open_ended
        $scope.reportableAmountType = 'NAV'
      else
        $scope.reportableAmountType = 'Capital Balance'

    $scope.currency = CurrencySelector.fromPaf($scope.privateAccessFund)

    $scope.isUnitizedFund = $scope.privateAccessFund.unitized

    $scope.orderBy = (field) ->
      $scope.reverseSort = if field == $scope.orderByField then !$scope.reverseSort else false
      $scope.orderByField = field

    $scope.redemptionsSize = ->
      ($scope.activeRedemptions.length || $scope.activeRedemptionsSize) +
      ($scope.holdbackRedemptions.length || $scope.holdbackRedemptionsSize) +
      ($scope.completedRedemptions.length || $scope.completedRedemptionsSize)

    init()
