angular.module('controllers').controller "keepFileModalController",
  ($scope, $modalInstance, $routeParams, RequiredDocument, ApprovalNotesService, approval, header, body, status) ->
    $scope.header = header
    $scope.body = body
    $scope.document = approval.required_document
    $scope.approval = approval
    $scope.status = status
    $scope.errorMsg = ''
    $scope.newComment = { subject: 'Original file kept', body: '', noteable_type: 'RequiredDocumentApproval' }

    $scope.saveCommentReason = ->
      $scope.errorMsg = ''
      return if $scope.newComment.body.length < 1
      $scope.newComment.noteable_id = $scope.approval.id
      params = { required_document_approval_id: $scope.approval.id, note: $scope.newComment }
      ApprovalNotesService.save(params).$promise.then (res) ->
        updateRequiredDocumentStatus()
      , (error) ->
        $scope.errorMsg = error.data.message

    $scope.dismissModal = ->
      $modalInstance.dismiss()

    updateRequiredDocumentStatus = ->
      RequiredDocument.update({ id: $scope.document.id }, {
        status: $scope.status,
        investment_id: $routeParams.investment_id,
        origin: 'keep_file'
      }).$promise.then (res) ->
        $modalInstance.close()
      , (error) ->
        $scope.errorMsg = error.data.message
