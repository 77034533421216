angular.module('controllers').controller 'IR-InvestorProfileContactInformation-Investments-Ctrl',
  ($scope, $location,InvestmentService, $route,
    $routeParams, $modal, urlBuilder, IcnIdentity) ->

    init = ->
      $scope.query = $routeParams.query
      $scope.fund = {
        id: parseInt($routeParams.pafId)
      }

      activate()

    $scope.confirmRiaDeletion = (riaEmail, investmentId) ->
      opts =
        templateUrl: "dialogs/custom_confirm_dialog/custom_confirm.html"
        controller: "customConfirmDialogCtrl"
        windowClass: "small-modal"
        resolve:
          header: ->
            "Delete RIA User Email"
          msg: ->
            "Are you sure you want to delete \"#{riaEmail}\""
          confirm_label: ->
            "Ok"
          cancel_label: ->
            "Cancel"
      $modal.open(opts).result.then ->
        deleteRiaRelation(investmentId)

    $scope.showRiaUserModal = (isEditing, investment) ->
      opts =
        templateUrl: "components/investor_relations/dialogs/ria_user_email_dialog/ria_user_email_dialog.html"
        controller: "riaUserEmailDialogCtrl"
        windowClass: "small-modal"
        resolve:
          investmentId: ->
            investment.id
          isEditing: ->
            isEditing
          riaEmail: ->
            if isEditing == true then investment.ria_email else ""

      $modal.open(opts).result.then ->
        activate()

    $scope.editProfile = (investment, fund, contactCard) ->
      queryString = "&irPath=#{fund.id}_#{contactCard.id}&" +
        "from=investor_relations_investment_contact_details&" +
        "private_access_fund_id=#{fund.id}&paf_override=#{fund.id}" +
        "&investment_id=#{investment.id}"
      url = urlBuilder.investorProfileUrl(
        investment.investor_profile.id, "1",
        queryString
      )

      $location.url url if url

    deleteRiaRelation = (investmentId) ->
      InvestmentService.removeRia(investmentId).then ->
        activate()

    $scope.goToReqDocs = (investment) ->
      urlBuilder.reactUrl(
        "/required_documents?investment_id=#{investment.id}&investor_profile_id=#{investment.investor_profile_id}",
        true
      )

    activate = ->
      InvestmentService.forInvestorRelations($routeParams.pafId, $scope.query).then (allInvestments) ->
        $scope.investments =  _.filter allInvestments, (investment) ->
          investment.contact_card.id == parseInt($routeParams.contactCardId) && !investment.canceled

        allContactCards = _.map allInvestments, (investment) ->
          id: investment.contact_card.id
          name: investment.contact_card.display_name

        $scope.contactCards = _.uniq allContactCards, 'id'

        $scope.contactCard = _.findWhere $scope.contactCards, { id: parseInt( $routeParams.contactCardId) }

        $scope.$watch 'contactCard', (newContactCard) ->
          $route.updateParams({ pafId: $routeParams.pafId, contactCardId: newContactCard?.id })


    init()
