angular.module('services').factory "WlpAttestationRecordService",
  ($resource) ->
    WlpAttestationRecordService = ->
      @service = $resource("/api/v1/wlp_attestation_records")
      return

    WlpAttestationRecordService::create = (investmentId, wlpAttestationId) ->
      record =
        investment_id: investmentId
        wlp_attestation_id: wlpAttestationId
        from_angular: true

      @service.save record: record

    return new WlpAttestationRecordService
