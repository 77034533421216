angular.module('directives').directive "investmentTabs", ->
  restrict: "E"
  scope:
    investments: "="
    selectedInvestment: '='
  templateUrl: "templates/investment_tabs.html"
  controller: ($scope) ->
    $scope.setInvestment = (investment) ->
      $scope.selectedInvestment = investment
    $scope.selected = (investment) ->
      $scope.selectedInvestment == investment

    $scope.investorDisplayName = (investment) ->
      if investment.entity_name.length > 0
        investment.entity_name
      else
        investment.contact_card.display_name
