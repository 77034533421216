angular.module('directives').directive "slidableHeader", ->
  scope:
    title: "@"
    loading: "=?"
  templateUrl: "components/slidable/templates/slidable_header.html"
  transclude: true
  require: "^slidable"
  link: (scope, el, attr, slidableCtrl) ->
    scope.slidable = slidableCtrl
    scope.toggleClosed = slidableCtrl.toggleClosed
