angular.module('controllers').controller "SetAllWireDateCtrl",
  ($scope, $modalInstance, Redemptions, redemptions, pafId) ->
    $scope.redemptionIds = _.pluck(redemptions, 'id')
    $scope.redemption = {}

    $scope.cancelDialog = -> $modalInstance.dismiss()

    $scope.dateOptions = {
      dateFormat: "mm/dd/yy"
    }

    $scope.save = ->
      Redemptions.bulkUpdate(pafId, $scope.redemptionIds, $scope.redemption).then( ->
        $modalInstance.close()
      , (response) ->
        $modalInstance.dismiss(response.data)
      )
