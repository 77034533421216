angular.module('services').factory 'DocumentExportsService', ($q, $resource) ->
  service = $resource('/api/v1/document_exports/:id', id: "@id")

  get = (pafId, documentTypes) ->
    service.query({paf_id: pafId, 'document_types[]': documentTypes}).$promise

  fetchForHFReporting = ->
    documentTypes = [
      'FundsSubscriptionsReportsHfExportCsv',
      'FundsSubscriptionsReportsHfExportXlsx',
      'FundsTransfersReportsHfExportCsv',
      'FundsTransfersReportsHfExportXlsx',
      'FundsRedemptionsReportsHfExportCsv',
      'FundsRedemptionsReportsHfExportXlsx',
      'FundsThirdPartyReportsHfExportCsv',
      'FundsThirdPartyReportsHfExportXlsx',
    ]
    service.query({'document_types[]': documentTypes}).$promise

  fetchForDistributionReporting = ->
    documentTypes = [
      'DistributorReportsExportXlsx',
    ]
    service.query({'document_types[]': documentTypes}).$promise

  fetchForShareClassBulkUploadResults = ->
    documentTypes = [
      'ShareClassBulkUploadResultsCsv',
    ]
    service.query({'document_types[]': documentTypes}).$promise

  fetchByDocTypeAndQueryParams = (documentTypes, queryParams) ->
    query = {'document_types[]': documentTypes}
    if queryParams['sinceDate']
      query.since_date = queryParams['sinceDate']
    if queryParams['page'] && queryParams['perPage']
      query.page = queryParams['page']
      query.per_page = queryParams['perPage']
      service.get(query).$promise
    else
      service.query(query).$promise

  return {
    get: get,
    fetchForHFReporting: fetchForHFReporting,
    fetchForDistributionReporting: fetchForDistributionReporting,
    fetchForShareClassBulkUploadResults: fetchForShareClassBulkUploadResults,
    fetchByDocTypeAndQueryParams: fetchByDocTypeAndQueryParams
  }
