angular.module('controllers').controller "SubscriptionReviewExportsDialogCtrl",
  ($scope, $location, $modalInstance, $modal, $window, $http, header, selectedPaf, selectedClose, approvalStatus,
  detailMessage, type, SubscriptionReviewExportInvestmentsService) ->

    $scope.header = (if (angular.isDefined(header)) then header else "")

    $scope.selectedPaf = selectedPaf
    $scope.selectedClose = selectedClose
    $scope.detailMessage = detailMessage

    $scope.approvalStatus = approvalStatus
    $scope.type = type

    processStatus = ->
      switch $scope.approvalStatus
        when 'Reverted' then 'reverted'
        when 'Pre-Approval' then 'pre_approval'
        when 'Pre-Approval 2' then 'pre_approval_secondary'
        when 'Pending Documents' then 'pending_review'
        when 'Suitability Review' then 'external_review'
        when 'Suitability Review 2' then 'external_secondary_review'
        when 'IR Review' then 'ir_review'
        when 'Compliance Review' then 'compliance_review'
        when 'Approved Subscriptions' then 'approved'
        when 'Accepted Subscriptions' then 'accepted'


    init = ->
      closeId = $scope.selectedClose && $scope.selectedClose.id
      pafId = $scope.selectedPaf.id
      SubscriptionReviewExportInvestmentsService.index(pafId, $scope.type, closeId, processStatus()).then (res) ->
        $scope.generatedExports = res.subscription_exported_files
        if manageClosePage()
          $scope.generatedExports = $scope.generatedExports.filter((generatedExport) -> hasClose(generatedExport))

    inProcessExportModal = ->
      $modal.open(
        templateUrl: "dialogs/custom_notify_dialog/custom_notify_dialog.html"
        controller: "customNotifyDialogCtrl"
        windowClass: "small-modal"
        resolve:
          header: ->
            "Your export is being generated..."

          msg: ->
            "Your export is being processed. You will receive an email with download instructions once the "+
              "export is complete. Alternatively, you can refresh this page."

          btn_label: -> ""
      )

    hasClose = (generatedExport) ->
      generatedExport.close_date != '' && generatedExport.close_date?

    manageClosePage = ->
      $location.path() == "/manage_close_page/#{$scope.selectedPaf.id}"

    $scope.disableExportButton = ->
      manageClosePage() && $scope.selectedClose == ""

    $scope.showCloseDate = ->
      $scope.generatedExports && $scope.generatedExports.every((generatedExport) -> hasClose(generatedExport))

    $scope.exportSubscriptionReviews = ->
      closeId = $scope.selectedClose && $scope.selectedClose.id
      pafId = $scope.selectedPaf.id
      SubscriptionReviewExportInvestmentsService.create(pafId, $scope.type, closeId, processStatus()).then ->
        inProcessExportModal()

    init()

    $scope.cancelDialog = -> $modalInstance.dismiss()
