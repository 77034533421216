angular.module 'icn.models'
.factory 'Custodian', ($resource) ->
  $resource '/api/v1/custodians/:id', {id: '@id'},
    index:
      method:'GET',
      isArray: true

    for_dtcc_reportable:
      url: '/api/v1/custodians/for_dtcc_reportable'
      method: 'GET',
      isArray: true
