angular.module('services').factory 'ClientManagementService', ->
  _riaId = undefined
  _pafId = undefined
  _teamCodeId = undefined

  parseRias = (rias) ->
    _.each rias, (ria) ->
      if ria.first_name == "" || ria.last_name == ""
        ria.display_name = ria.email
      else
        ria.display_name = ria.first_name + " " + ria.last_name

    rias

  restorePath = (managementType) ->
    if _riaId == undefined  && _pafId == undefined
      return ""
    else if _pafId != undefined
      if _riaId != undefined
        return "/#{managementType}/#{_pafId}/#{_riaId}"
      else if _teamCodeId != undefined
        return "/#{managementType}/#{_pafId}/team_code/#{_teamCodeId}"
      else
        return "/#{managementType}/#{_pafId}"

  getRiaId = ->
    _riaId

  setRiaId = (id) ->
    _riaId = id

  getPafId = ->
    _pafId

  setPafId = (id) ->
    _pafId = id

  getTeamCode = ->
    _teamCodeId

  setTeamCodeId = (teamCode) ->
    _teamCodeId = teamCode

  setBoth = (pafId, riaId) ->
    _pafId = pafId
    _riaId = riaId

  setBothWithTeamCode = (pafId, teamCode) ->
    _pafId = pafId
    _teamCodeId = teamCode

  return {
    getRiaId: getRiaId
    setRiaId: setRiaId
    getPafId: getPafId
    setPafId: setPafId
    getTeamCode: getTeamCode
    setTeamCodeId: setTeamCodeId
    setBoth: setBoth
    setBothWithTeamCode: setBothWithTeamCode
    restorePath: restorePath
    parseRias: parseRias
  }
