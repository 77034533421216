angular.module('services').factory 'PreApprovalQuestionnaireModalService', ->
  mapFormDataFromQS = (routeParams, formSubBook, userKey) ->
    if userKey == 'Account_Add_New'
      {
        firstName: routeParams.first_name
        lastName: routeParams.last_name
        email: routeParams.email
        externalId: if routeParams.external_id != 'undefined' then routeParams.external_id else undefined
        subBook: formSubBook
        accountNumber: routeParams.user_number
        accountAttestation: setParamToBoolean(routeParams.confirmation)
        subDocCode: routeParams.sub_doc_code
        investmentShareClass: { id: parseInt(routeParams.investment_share_class_id) || undefined }
        investmentShareClassCode: routeParams.investment_share_class_code
        useExceptionForNewUser: setParamToBoolean(routeParams.use_exception)
        selectedReferralCode: routeParams.referral_code
      }
    else if userKey == 'Account_Choose_Existing'
      {
        referralCode: routeParams.referral_code
        subDocCode: routeParams.sub_doc_code
        investmentShareClass: { id: parseInt(routeParams.investment_share_class_id) || undefined }
        investmentShareClassCode: routeParams.investment_share_class_code
        useException: setParamToBoolean(routeParams.use_exception)
        investorProfile: { id: parseInt(routeParams.investor_profile_id) }
        investorProfileName: parseInt(routeParams.investor_profile_id)
        investor: { id: parseInt(routeParams.investor_id) || undefined }
        subBook: { id: parseInt(routeParams.sub_book_id) || undefined }
      }
    else
      {
        firstName: routeParams.first_name || ''
        lastName: routeParams.last_name || ''
        email: routeParams.email || ''
        prospectInvestorProfile: parseInt(routeParams.prospect_profile_id) || undefined
      }

  populateFormData = (formData, userKey) ->
    if userKey == 'Account_Add_New'
      Object.assign(
        {},
        formData,
        { investmentShareClass: { id: formData.investmentShareClass?.id } }
      )
    else if userKey == 'Account_Choose_Existing'
      {
        referralCode: formData.referralCode
        subDocCode: formData.subDocCode
        investmentShareClass: { id: formData.investmentShareClass?.id }
        investmentShareClassCode: formData.investmentShareClassCode
        useException: formData.useException
        investorProfile: { id: formData.investorProfile?.id }
        investorProfileName: formData.investorProfile?.id
        investor: { id: formData.investor?.id }
        subBook: { id: formData.subBook?.id }
      }
    else
      {
        firstName: formData.firstName
        lastName: formData.lastName
        email: formData.email
        prospectInvestorProfile: formData.prospectInvestorProfile
      }

  checkValueAccount = (formData, routeParams, userKey) ->
    if userKey == 'Account_Choose_Existing' && routeParams.external_id != 'undefined'
      formData.externalId == routeParams.external_id
    else if userKey == 'Account_Add_New' && routeParams.sub_book_id != 'undefined'
      formData.subBook?.id == parseInt(routeParams.sub_book_id)
    else
      true

  emptyFormData = (isNewInvestment) ->
    if isNewInvestment
      {
        investorProfile: {}
        investorProfileName: null
        investorProfiles: []
        investorProfileNames: []
        newInvestorProfile: undefined
        referralCode: ''
        subBook: {}
        subDocCode: ''
        investmentShareClass: null
        investmentShareClassCode: ''
        useException: false
      }
    else
      {
        firstName: ''
        lastName: ''
        email: ''
        externalId: ''
        selectedReferralCode: undefined
        subBook: {}
        investmentShareClass: null
        investmentShareClassCode: ''
        accountNumber: undefined
        accountAttestation: false
        useExceptionForNewUser: false
        useException: false
      }

  generateQueryParamsForFormData = (formData, existingAccountData, userKey) ->
    switch userKey
      when 'Account_Add_New'
        "&first_name=#{encodeURIComponent(formData.firstName)}" +
        "&last_name=#{encodeURIComponent(formData.lastName)}" +
        "&email=#{encodeURIComponent(formData.email)}" +
        "&sub_book_id=#{formData.subBook?.id}" +
        "&external_id=#{formData.externalId}" +
        "&sub_doc_code=#{encodeURIComponent(formData.subDocCode)}" +
        "&investment_share_class_id=#{formData.investmentShareClass?.id}" +
        "&investment_share_class_code=#{formData.investmentShareClassCode}" +
        "&use_exception=#{formData.useExceptionForNewUser}" +
        "&referral_code=#{encodeURIComponent(formData.selectedReferralCode)}"
      when 'Account_Choose_Existing'
        "&investor_id=#{existingAccountData.investor?.id}" +
        "&investor_profile_id=#{existingAccountData.investorProfile?.id}" +
        "&sub_book_id=#{existingAccountData.subBook?.id}" +
        "&external_id=#{formData.externalId}" +
        "&sub_doc_code=#{encodeURIComponent(existingAccountData.subDocCode)}" +
        "&investment_share_class_id=#{existingAccountData.investmentShareClass?.id}" +
        "&investment_share_class_code=#{existingAccountData.investmentShareClassCode}" +
        "&use_exception=#{existingAccountData.useException}" +
        "&referral_code=#{encodeURIComponent(existingAccountData.referralCode)}"
      when 'Prospect_Add_New'
        "&first_name=#{encodeURIComponent(formData.firstName)}" +
        "&last_name=#{encodeURIComponent(formData.lastName)}" +
        "&email=#{encodeURIComponent(formData.email)}"
      when 'Prospect_Choose_Existing'
        "&prospect_profile_id=#{formData.prospectInvestorProfile}"

  setParamToBoolean = (param) ->
    param == "true"

  return {
    mapFormDataFromQS: mapFormDataFromQS
    populateFormData: populateFormData
    checkValueAccount: checkValueAccount
    emptyFormData: emptyFormData
    generateQueryParamsForFormData: generateQueryParamsForFormData
  }
