angular.module('services').factory "NonDisclosureAgreement", ($resource) ->
  resource = $resource "/api/v1/non_disclosure_agreements/:id",
    id: '@id'
  ,

    index:
      method: "GET"
      isArray: true
    
    create:
      method: "POST"


  get = (pafId) ->
    resource.index(paf_id: pafId).$promise
  
  create = (pafId) ->
    resource.create(paf_id: pafId).$promise

  {
    get: get
    create: create
  }
