angular.module('directives').directive "video", ->
  restrict: "A"
  link: (scope, element, attrs) ->
    attrs.type = attrs.type or "video/mp4"
    attrs.id = attrs.id or "videojs" + Math.floor(Math.random() * 100)
    attrs.setup = attrs.setup or {}
    setup = {}
    setup = angular.extend(setup, attrs.setup)
    element.attr "id", attrs.id
    player = videojs(attrs.id, setup, ->
      @src
        type: attrs.type
        src: attrs.src
    )
