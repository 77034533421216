angular.module('controllers').controller "SelectNextClose",
  ($scope, $modalInstance, $routeParams, IcnIdentity, investment, closeDates, PafAdminClose, openFund) ->

    $scope.closeDates = _.map(closeDates, (closeDate) -> closeDate = moment(closeDate).format("DD-MMM-YYYY"))
    $scope.investment = investment
    $scope.investmentCloseDate = if $scope.investment.close_date then moment($scope.investment.close_date).format("DD-MMM-YYYY") else ''
    $scope.isOpenFund = openFund

    $scope.dismissModal = ->
      $modalInstance.dismiss()

    $scope.isAllowedToSetCloseDates = ->
      IcnIdentity.isPafAdmin()

    $scope.updateCloseDate = (investment_id, close_date) ->
      PafAdminClose.toggleInNextCloseDate({id: investment_id, fund_id: $routeParams.pafId, close_date: close_date})
      $modalInstance.close(close_date)
