import Pusher from 'pusher-js';

(function() {
  angular.module('services').service("PushNotificationsService", function(IcnIdentity, $http, Notification, $timeout) {
    const disableThirdPartyJs = function() {
      var prefix;
      prefix = window.partnerProxyAPIPrefix;
      return _.isString(prefix) && prefix !== '';
    };

    const pusherDefs = IcnIdentity.data.pusher;
    const environmentLabel = IcnIdentity.data.env;

    if ((environmentLabel == 'development' && !pusherDefs.server_running) || disableThirdPartyJs()) {
      console.info("Disabling pusher");
      return;
    }

    if (!IcnIdentity.user) {
      return;
    }

    const PUSH_EVENTS = {
      EXPORT_STARTED: 'EXPORT_STARTED',
      EXPORT_FINISHED: 'EXPORT_FINISHED',
      JOB_COMPLETE: 'JOB_COMPLETE',
      JOB_FAILED: 'JOB_FAILED',
      IMPORT_FINISHED: 'IMPORT_FINISHED',
      IMPORT_FAILED: 'IMPORT_FAILED',
      EXPORT_DOWNLOAD: 'EXPORT_DOWNLOAD',
      WATERMARK_DOCUMENT_READY: 'WATERMARK_DOCUMENT_READY',
      WATERMARK_DOCUMENT_ERROR: 'WATERMARK_DOCUMENT_ERROR',
      REFRESH_STARTED: 'REFRESH_STARTED',
      REFRESH_ONGOING: 'REFRESH_ONGOING',
      REFRESH_FINISHED: 'REFRESH_FINISHED',
      EMAIL_STATUS_PROCESSING: 'EMAIL_STATUS_PROCESSING',
      EMAIL_STATUS_FINISHED: 'EMAIL_STATUS_FINISHED'
    };
    window.events = PUSH_EVENTS;

    const pusherConfig = {
      authorizer: function(channel, options) {
        return {
          authorize: function(socketId, callback) {
            var params;
            params = {
              channel_name: channel.name,
              socket_id: socketId
            };
            return $http.post('/api/v1/pusher/auth', params).then(function(response) {
              return callback(false, response.data);
            });
          }
        };
      },
      cluster: pusherDefs.pusher_cluster,
      forceTLS: true
    };

    const hostConfig = function() {
      if (environmentLabel != 'development' && environmentLabel != 'test') {
        return {};
      }
      if (!pusherDefs.pusher_ws_host) {
        return {};
      }
      return {
        wsHost: pusherDefs.pusher_ws_host,
        wsPort: pusherDefs.pusher_ws_port,
        httpHost: pusherDefs.pusher_http_host,
        httpPort: pusherDefs.pusher_http_port,
        forceTLS: false,
        disableStats: true
      };
    };

    const initializePusher = function() {
      var config;
      config = Object.assign({}, pusherConfig, hostConfig());
      return new Pusher(pusherDefs.pusher_api_key, config);
    };
    window.pusher = initializePusher();

    const channel = window.pusher.subscribe("private-" + IcnIdentity.user.id);
    window.userChannel = channel;

    const exportStarted = "Your export may take a few moments.<br/>" + "We will notify you when it is ready.";
    const exportEnd = function(url, isDownload) {
      var download;
      download = isDownload ? " download target=\"_blank\" rel=\"noopener noreferrer\" " : " ";
      return "Your export is ready for " + "<a" + download + ("class=\"download-link\" href=\"" + url + "\">download</a>.");
    };
    const importFail = "Your import failled.";
    const importEnd = function(url) {
      return "Your import result is ready for " + ("<a class=\"download-link\" href=\"" + url + "\">download</a>.");
    };
    const watermarkDocumentReady = function(url) {
      return "Your document is ready to " + ("<a target=\"_blank\" rel=\"noopener noreferrer\" class=\"download-link\" href=\"" + url + "\">preview</a>") + ".";
    };
    const jobComplete = function(data) {
      return (data.message + " ") + ("<a target=\"_self\" class=\"download-link\" href=\"" + data.url + "\">reload</a>");
    };

    channel.bind_global(function(event, data) {
      switch (event) {
      case PUSH_EVENTS.EXPORT_STARTED:
        return Notification(exportStarted);
      case PUSH_EVENTS.EXPORT_FINISHED:
        return $timeout(function() {
          return Notification(exportEnd(data.url, data.isDownload));
        }, 5000);
      case PUSH_EVENTS.IMPORT_FINISHED:
        return $timeout(function() {
          return Notification(importEnd(data.url));
        }, 5000);
      case PUSH_EVENTS.IMPORT_FAILED:
        return $timeout(function() {
          return Notification(importFail);
        }, 5000);
      case PUSH_EVENTS.JOB_COMPLETE:
        return $timeout(function() {
          return Notification(jobComplete(data));
        }, 1000);
      case PUSH_EVENTS.JOB_FAILED:
        return $timeout(function() {
          return Notification(data.message);
        }, 1000);
      case PUSH_EVENTS.EXPORT_DOWNLOAD:
        return $timeout(function() {
          Notification(data.message);
          return document.location.href = data.url;
        }, 1000);
      case PUSH_EVENTS.WATERMARK_DOCUMENT_READY:
        return $timeout(function() {
          return Notification(watermarkDocumentReady(data.url));
        }, 1000);
      case PUSH_EVENTS.WATERMARK_DOCUMENT_ERROR:
        return $timeout(function() {
          return Notification(data.message);
        }, 1000);
      case PUSH_EVENTS.EMAIL_STATUS_PROCESSING:
        return $timeout(function() {
          return Notification(data.message);
        }, 1000);
      case PUSH_EVENTS.EMAIL_STATUS_FINISHED:
        return $timeout(function() {
          return Notification(exportEnd(data.url, data.isDownload));
        }, 5000);
      case PUSH_EVENTS.REFRESH_STARTED:
      case PUSH_EVENTS.REFRESH_ONGOING:
      case PUSH_EVENTS.REFRESH_FINISHED:
        return $timeout(function() {
          return Notification(data.message);
        }, 1000);
      }
    });
  });
}).call(this);
