angular.module 'icn.components.fund_selector'
  .factory "FundSelectorService",
  ($q, PrivateAccessFund, PafAdminSvcApi, $routeParams, $rootScope, FundSelectorFilterer, PrivateAccessFunds) ->
    self = this

    @loadFunds = (fundType, filterName = null, nonAdmin = false) ->
      if @fundType == fundType
        if FundSelectorFilterer.filterName != filterName
          FundSelectorFilterer.filterName = filterName
          @reapplyFilter()
        @setSelectedFundFromRoute()
      else
        @fundType = fundType
        @nonAdmin = nonAdmin
        FundSelectorFilterer.filterName = filterName
        @refreshFunds()

    @refreshFunds = ->
      if @fundType == 'pe'
        if @nonAdmin == 'true'
          PrivateAccessFunds.getOpenAndVintagePrivateAccessFunds(private_equity: true).then @processUnfilteredFunds
        else
          PrivateAccessFund.fetchAllPrivateEquities().then @processUnfilteredFunds
      else if @fundType == 'hf'
        PafAdminSvcApi.hedgeFunds().then @processUnfilteredFunds

    @processUnfilteredFunds = (unfilteredFunds) ->
      self.unfilteredFunds = unfilteredFunds
      self.reapplyFilter()
      self.setSelectedFundFromRoute()

    @setSelectedFundFromRoute = ->
      if $routeParams.private_access_fund_id
        self.setSelectedFundId $routeParams.private_access_fund_id
      else
        return $q.defer().promise

    @setSelectedFundId = (id) ->
      if id
        PrivateAccessFund.fetchOne(id).then (fund) ->
          self.selectedFund = new PrivateAccessFund(fund)
      else
        self.selectedFund = null
        return $q.defer().promise

    @reapplyFilter = ->
      @funds = FundSelectorFilterer.getFilteredFunds(@unfilteredFunds)

    return this
