// Generated by CoffeeScript 2.4.1
(function() {
  angular.module('controllers').controller("DownloadCountersignDialog", function($scope, $modalInstance, $window, $modal, Close, close, privateAccessFund) {
    var alert, downloadTemplate;
    $scope.data = {
      sign_method: 'manual'
    };
    $scope.paf = privateAccessFund;
    $scope.close = close;
    $scope.investorCount = close.investment_count;
    $scope.totalCommitment = close.investments_commitment_total;
    $scope.onCancel = function() {
      return $modalInstance.dismiss();
    };
    downloadTemplate = function(result) {
      var data, params;
      data = $scope.data;
      params = {
        id: $scope.close.id,
        sign_method: data.sign_method,
        gp_signatory_name: data.name,
        gp_signatory_title: data.title
      };
      return Close.sendCounterSignature(params).$promise.then(function(result) {
        $window.open(result.countersignature_document.file_url, '_blank');
        return $modalInstance.close(result);
      });
    };
    alert = function() {
      var opts;
      opts = {
        templateUrl: "dialogs/custom_confirm_dialog/custom_confirm.html",
        controller: "customConfirmDialogCtrl",
        windowClass: "small-modal",
        resolve: {
          header: function() {
            return "Warning";
          },
          msg: function() {
            return "Downloading the template for manual signature will remove the existing countersigned document.  Do you wish you continue?";
          },
          confirm_label: function() {
            return "Yes";
          },
          cancel_label: function() {
            return "No";
          }
        }
      };
      return $modal.open(opts).result.then(downloadTemplate);
    };
    return $scope.onSubmit = function(form) {
      if (form.$valid) {
        if (close.countersignature_document.status === 'signed' || close.countersignature_document.status === 'pending_esign') {
          return alert();
        } else {
          return downloadTemplate();
        }
      }
    };
  });

}).call(this);
