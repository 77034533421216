angular.module('services').factory 'ReferralCode', ($resource, $sce) ->
  ReferralCode = $resource('/api/v1/referral_codes/:id',
    id: "@id"
  ,
    makePrimary:
      url: '/api/v1/referral_codes/:id/make_primary'
      method: 'PATCH'

    assignReferralCode:
      url: '/api/v1/referral_codes/assign_referral_code'
      method: 'PATCH'

    unassignReferralCode:
      url: '/api/v1/referral_codes/:id/unassign_referral_code'
      method: 'PATCH'
  )

  ReferralCode::thankYouHtml = ->
    $sce.trustAsHtml(this.thank_you_text)

  ReferralCode::unassignReferralCode = (id, userId) ->
    ReferralCode.unassignReferralCode(id: id, user_id: userId)

  ReferralCode::makePrimary = (id, userId, currentPrimaryId) ->
    ReferralCode.makePrimary(id: id, user_id: userId, current_primary_id: currentPrimaryId)

  ReferralCode::assignReferralCode = (code, primary, currentPrimaryId, userId) ->
    ReferralCode.assignReferralCode(code: code, primary: primary, current_primary_id: currentPrimaryId, user_id: userId)

  new ReferralCode
