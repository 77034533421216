angular.module('services').factory "Months", ->
  monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ]

  getLastMonths = (n) ->
    date = new Date()
    months = []
    for i in [1..n]
      months.push({
        name: "#{monthNames[date.getMonth()]} #{date.getFullYear()}"
        year: date.getFullYear()
        month: date.getMonth() + 1
      })
      date.setMonth(date.getMonth() - 1)
    return months

  return {
    getLastMonths: getLastMonths
  }
