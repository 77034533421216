angular.module('controllers').controller 'InvestorRelationsSearchCtrl',
  ($scope, $location, ContactCardsAutocomplete, $routeParams,
    InvestorSearchService, InvestmentService, $sce, $timeout) ->

    $scope.select = {}
    $scope.cardId = InvestorSearchService.getCardId()

    contactCardsHeader = (contacts) ->
      for contact in contacts
        contact["investor_email_header"] = "INVESTOR EMAIL"
      return contacts

    approvedInv = (investment) ->
      return investment.status == 'Approved' || investment.status == 'Accepted'

    pendingInv = (investment) ->
      return investment.status != 'Approved' && investment.status != 'Accepted'

    loadSelectedCard = ->
      if $scope.cardId != null
        InvestmentService.forContactCard($scope.cardId).then (response) ->
          if response.investments.length > 0
            $scope.select.selectedCard = response.investments[0].contact_card
            $scope.approvedInvestments = response.investments.filter (inv) -> approvedInv(inv)
            $scope.pendingInvestments = response.investments.filter (inv) -> pendingInv(inv)
          return

    delayedContactCardsSearch = (query) ->
      ContactCardsAutocomplete.autocomplete(query).then (contactCards) ->
        $scope.contactCards = contactCardsHeader(contactCards)

    init = ->
      if $routeParams.cardId != null
        $scope.cardId = $routeParams.cardId
      if $scope.select.selectedCard != null || $scope.cardId != null
        loadSelectedCard()

    init()

    $scope.contactCardsAsync = (query) ->
      if $scope.contactsTimer
        $timeout.cancel($scope.contactsTimer)
        $scope.contactsTimer = null
      if query && query.trim().length > 2
        $scope.contactsTimer = $timeout((-> delayedContactCardsSearch(query)), 1000)

    $scope.setCardId = ($item, $model) ->
      if $item == null
        InvestorSearchService.setCardId(null)
        $location.path InvestorSearchService.restorePath()
      else
        $scope.cardId = $item.id
        InvestorSearchService.setCardId($item.id)
        $location.path InvestorSearchService.restorePath()
