angular.module('directives').directive "privateAccessFundSeries",
  (IcnUtility, ToolTips) ->
    restrict: "E"
    scope:
      privateAccessFund: "="
      investment: "="
      data: "="
      onPage: "="
    templateUrl: "components/private_access_fund_series/private_access_fund_series.html"

    link: (scope, element, attrs) ->
      init = ->
        scope.toolTips = ToolTips
        scope.showSeries = false
        scope.activeSeries = []

        if scope.privateAccessFund.series
          scope.activeSeries = _.sortBy(_.where(scope.privateAccessFund.series, {disabled: false}), (s) -> s.name)
          scope.showSeries = scope.activeSeries.length > 1
          scope.data.hasSeries = scope.showSeries

        if scope.showSeries
          scope.calculateAmounts()

      allocationAmount = (allocation) ->
        percent = parseFloat(allocation.allocation_percentage)
        amt = parseInt(IcnUtility.unformatCurrency(String(scope.investment.amount)))
        amt * percent

      scope.calculateAmounts = ->
        return unless scope.privateAccessFund

        series = _.find(scope.privateAccessFund.series, id: parseInt(scope.data.selectedSeries))
        return unless series

        i = 0
        for k, v of series.allocations
          scope.data.amounts[i] = allocationAmount(v)
          i++

      scope.seriesRowClass = (series) ->
        if parseInt(scope.data.selectedSeries) == series.id
          'content-default'
        else
          'content-default__light'

      scope.formatAllocation = (allocation) ->
        (parseFloat(allocation.allocation_percentage) * 100).toFixed(0) + "%"

      scope.$watch "investment.amount", (newVal, oldVal) ->
        scope.calculateAmounts()

      scope.$watch "data.selectedSeries", (newVal, oldVal) ->
        scope.calculateAmounts()

      scope.$watch "privateAccessFund", (newVal, oldVal) ->
        if newVal != undefined
          init()
