angular.module('services').factory "SubscriptionReviewExportDocsService", ($resource) ->
  resource = $resource "/api/v1/fund_exported_files/:private_access_fund_id", {private_access_fund_id: '@id'},
    index:
      url: '/api/v1/fund_exported_files',
      method: 'GET'
    create:
      url: '/api/v1/fund_exported_files',
      method: 'POST'

    create = (pafId, amlDocTypes, options = null) ->
      resource.create(
        private_access_fund_id: pafId,
        aml_doc_types: amlDocTypes,
        investment_id: options['investment_id']
        options: options
      ).$promise

    index = (pafId, investmentId = null) ->
      resource.index(
        private_access_fund_id: pafId,
        investment_id: investmentId
      ).$promise

  return {
    create: create
    index: index
  }
