angular.module('directives').directive "investorProfileInterestedParty", (IcnIdentity) ->
  restrict: "E"
  templateUrl: "areas/no_tab/investor_profile/components/interested_parties/interested_party.html"
  scope:
    party: "="
    interestedPartyNum: "="
    investorProfile: "="
    isReadOnlyField: "="
    isHiddenField: "="
    submitted: "="

  link: (scope, element, attrs) ->
    scope.isInvesteorRelations = IcnIdentity.isInvestorRelations()

    scope.addInterestedParty = ->
      scope.$parent.addInterestedParty()

    scope.deleteInterestedParty = ->
      scope.$parent.deleteInterestedParty(scope.interestedPartyNum)

    scope.showAddAnother = ->
      scope.$parent.showAddAnother(scope.interestedPartyNum)

    scope.showDelete = ->
      scope.interestedPartyNum != 1

    scope.fieldName = (field) ->
      "interested_party_#{scope.interestedPartyNum}_#{field}"
