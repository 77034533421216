angular.module('directives').directive "optionsDisabled", ($parse) ->
  disableOptions = (scope, attr, element, data, fnDisableIfTrue) ->
    
    # refresh the disabled options in the select element.
    angular.forEach element.find("option"), (value, index) ->
      elem = angular.element(value)
      unless elem.val() is ""
        locals = {}
        locals[attr] = data[index]
        elem.attr "disabled", fnDisableIfTrue(scope, locals)
      return

    return

  priority: 0
  require: "ngModel"
  link: (scope, iElement, iAttrs, ctrl) ->
    if iAttrs.showDisabled == "true"
      # parse expression and build array of disabled options
      expElements = iAttrs.optionsDisabled.match(/^\s*(.+)\s+for\s+(.+)\s+in\s+(.+)?\s*/)
      attrToWatch = expElements[3]
      fnDisableIfTrue = $parse(expElements[1])
      scope.$watch attrToWatch, ((newValue, oldValue) ->
        disableOptions scope, expElements[2], iElement, newValue, fnDisableIfTrue  if newValue
        return
      ), true

      # handle model updates properly
      scope.$watch iAttrs.ngModel, (newValue, oldValue) ->
        disOptions = $parse(attrToWatch)(scope)
        disableOptions scope, expElements[2], iElement, disOptions, fnDisableIfTrue  if newValue
        return
    return

