angular.module("icn.paf_admin")
.factory "NasdaqFilesService", ($resource, $http, $q) ->
  NasdaqFilesService =
    $resource("/api/v1/nasdaq_files/:id", {id: '@id'},
      exportNasdaqFiles:
        url: "/api/v1/nasdaq_files/:id/export_nasdaq_files"
        method: "GET"
    )

  NasdaqFilesService::exportNasdaqFiles = (pafId, effectiveDate, filename, fileType) ->
    NasdaqFilesService.exportNasdaqFiles({
      id: pafId
      effective_date: effectiveDate
      filename: filename
      file_type: fileType
    }).$promise

  return new NasdaqFilesService()
