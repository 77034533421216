angular.module('controllers').controller "PEUploadDialogCtrl",
  ($scope, $upload, $modalInstance, $window, IcnDocumentSvc,
    FundSelectorService, ModalDialogInstancesObserver, DocumentExportsService) ->
    $scope.uploadStatus = "none"
    $scope.fundSelector = FundSelectorService

    $scope.closeModal = ->
      $modalInstance.close()

    $scope.uploadInvestorsReport = (files) ->
      $upload.upload
        url: "/api/v1/private_equity/pcap_uploader"
        data:
          file: files[0]
      .then (res) ->
        $modalInstance.close(res)
      .catch (res) ->
        $modalInstance.close(res)

    $scope.downloadTemplate = ->
      IcnDocumentSvc.uploadTemplateXLS("capital_balance_upload_template").$promise.then((response) ->
        url = new URL(response.file_path)
        tempLink = document.createElement("a")
        tempLink.href = url
        tempLink.target = "_blank"
        tempLink.download = "capital_balance_upload_template.xlsx"
        tempLink.style.display = "none"
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      )

    dropEventCallBackFunction = (e) ->
      e = e or event
      files = e.dataTransfer.files
      e.preventDefault()
      if e.target.id == ("bulk-upload") && $scope.uploadStatus == "none"
        $scope.uploadInvestorsReport(files)

    $window.addEventListener "drop", dropEventCallBackFunction

    $scope.$on("$destroy", ->
      $window.removeEventListener("drop", dropEventCallBackFunction)
    )

    $scope.picker = ->
      el = document.getElementById("bulk-upload")
      if el
        el.click()

    currentModalInstance = $modalInstance
    currentModalInstance.events = {}

    # dismiss modal dialog box when navigate to other page
    ModalDialogInstancesObserver.setModalDialogInstance(currentModalInstance)
