angular.module('controllers').controller 'UserManagementAccessFundsCtrl', ($scope, $routeParams, $location, $modal, Users, PrivateAccessFundResource) ->

  $scope.getUser = ->
    Users.find($routeParams.user_id).then (response) ->
      $scope.user = response.user
      $scope.userName = response.user.user_name
      $scope.userAccessFunds = response.user.access_funds

  $scope.removeFund = (fund) ->
    PrivateAccessFundResource.removeAccessForUser({ id: fund.id, user_id: $scope.user.id }).$promise.then (response) ->
      $scope.userAccessFunds = _.reject($scope.userAccessFunds, (accessFund) -> accessFund.id == fund.id)
      $scope.message = {msg: "Fund access removed from user.", cssClass: 'alert-success'}

  $scope.setLimited = (fund) ->
    PrivateAccessFundResource.setLimitedForUser({ id: fund.id, user_id: $scope.user.id, limited: fund.limited }).$promise.then (response) ->
      $scope.message = { msg: "Private access fund limitation updated for user.", cssClass: 'alert-success' }

  $scope.openAddPrivateAccessFundAccessDialog = ->
    $modal.open(
          templateUrl: "areas/user_management/dialogs/add_private_access_fund_access_dialog.html"
          controller: "addPrivateAccessFundAccessDialogCtrl"
          windowClass: "small-modal"
          resolve:
            user: -> $scope.user
            userAccessFunds: -> $scope.userAccessFunds
        ).result.then (fund) ->
          $scope.userAccessFunds.push(fund)
          $scope.message = {msg: "Fund access added to user.", cssClass: 'alert-success'}

  $scope.returnToListing = ->
    $location.path('/user_management')

  init = ->
    $scope.getUser()
    $scope.message = {}

  init()
