angular.module 'icn.models'
.factory "RequiredDocument",
  ($resource, $upload, $q) ->
    requiredDocument = $resource "/api/v1/required_documents/:id", {id: '@id'},

      forInvestorProfile:
        method: 'GET',
        url: '/api/v1/required_documents/for_investor_profile'
        params:
          investor_profile_id: '@investor_profile_id'

      forInvestment:
        method: 'GET',
        url: '/api/v1/required_documents/for_investment'

      forProfileAndInvestment:
        method: 'GET',
        url: '/api/v1/required_documents/combined_for_investment'

      requiredDocumentTypes:
        url: "/api/v1/required_document_types"
        method: "GET"

      requiredDocumentTypesAddDocumentModal:
        url: "/api/v1/required_document_types/from_add_document_modal"
        method: "GET"

      overrideTypesForInvestment:
        url: "/api/v1/required_document_types/overrides_for_investment"
        method: "GET"

      investmentOverridenTypesForInvestment:
        url: "/api/v1/required_document_types/investment_overriden"
        method: "GET"

      createForInvestment:
        url: "/api/v1/required_documents/create_for_investment"
        method: "POST"

      createForInvestorProfile:
        url: "/api/v1/required_documents/create_for_investor_profile"
        method: "POST"

      getUserCreateTypes:
        url: "/api/v1/required_documents/user_create_types"
        method: "GET"
        isArray: true

      requestEsigns:
        url: "/api/v1/required_documents/request_esigns"
        method: "POST"
        params:
          investor_profile_id: @investor_profile_id
          signer_ids: @signer_ids
          primary_doc_id: @selected_document_id
          email: @email

      update:
        url: '/api/v1/required_documents/:id',
        method: 'PATCH'

      destroy_file:
        url: '/api/v1/required_documents/:id/destroy_file',
        method: 'PATCH'

      forSendFundAdminDocs:
        url: '/api/v1/required_documents/for_send_fund_admin_docs'
        method: 'GET'
        isArray: true

      retrieveSignatureProcessPendingParticipant:
        url: '/api/v1/required_documents/:id/retrieve_signature_process_pending_participant'
        method: 'GET'
        isArray: false

    requiredDocument.upload = (id, files, investmentId) ->
      $upload.upload(
        method: 'PATCH',
        url: "/api/v1/required_documents/#{id}/upload?investment_id=#{investmentId}"
        file: files[0]
      )

    requiredDocument.view = (document) ->
      deferred = $q.defer()
      deferred.resolve({ url: "api/v1/required_documents/view?id=#{document.id}" })
      { $promise: deferred.promise }

    requiredDocument.combinedForInvestment = (investmentId) ->
      requiredDocument.forProfileAndInvestment({'investment_id': investmentId}).$promise.then (data) ->
        data.required_documents

    requiredDocument
