angular.module('directives').directive "hedgeFundHistoricalPerformance", ->
  restrict: "E"
  transclude: true
  templateUrl: "templates/directives/hedge_fund_historical_performance.html"
  scope:
    fund: "="
  controller:
    ($scope, $routeParams, $route, $templateCache, Funds, IcnIdentity, IcnUtility) ->
      headerCellTmpl = $templateCache.get("templates/directives/historical_performance/header.html")
      percentageCellTmpl = $templateCache.get("templates/directives/hedge_fund_historical_performance/percentage.html")
      ytdCellTmpl = $templateCache.get("templates/directives/hedge_fund_historical_performance/ytd.html")
      yearCellTmpl = $templateCache.get("templates/directives/hedge_fund_historical_performance/year.html")

      $scope.hedgeFundHistoricPerformance =
        data: "fund.hedge_fund_historical_performance"
        headerRowHeight: 35
        rowHeight: 35
        enableRowSelection: false
        plugins: [new IcnUtility.ngGridFlexibleHeightPlugin()]
        enableSorting: false
        sortInfo:
          fields: ["year"]
          directions: ["desc"]
        columnDefs: "historicalPerformanceColumnDefs"

      $scope.months = ['jan','feb','mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']

      $scope.parseFloat = (i) ->
        parseFloat(i)

      $scope.abs = (i) ->
        Math.abs(i)

      monthColumnWidth = 68
      $scope.historicalPerformanceColumnDefs = [
        {
          field: "year"
          displayName: "YEAR"
          headerClass: "Year"
          headerCellTemplate: headerCellTmpl
          cellTemplate: yearCellTmpl
          width: 80
        }
        {
          field: "jan"
          displayName: "JAN"
          headerClass: "January"
          headerCellTemplate: headerCellTmpl
          cellTemplate: percentageCellTmpl
          width: monthColumnWidth
        }
        {
          field: "feb"
          displayName: "FEB"
          headerClass: "Feburary"
          headerCellTemplate: headerCellTmpl
          cellTemplate: percentageCellTmpl
          width: monthColumnWidth
        }
        {
          field: "mar"
          displayName: "MAR"
          headerClass: "March"
          headerCellTemplate: headerCellTmpl
          cellTemplate: percentageCellTmpl
          width: monthColumnWidth
        }
        {
          field: "apr"
          displayName: "APR"
          headerClass: "April"
          headerCellTemplate: headerCellTmpl
          cellTemplate: percentageCellTmpl
          width: monthColumnWidth
        }
        {
          field: "may"
          displayName: "MAY"
          headerClass: "May"
          headerCellTemplate: headerCellTmpl
          cellTemplate: percentageCellTmpl
          width: monthColumnWidth
        }
        {
          field: "jun"
          displayName: "JUN"
          headerClass: "June"
          headerCellTemplate: headerCellTmpl
          cellTemplate: percentageCellTmpl
          width: monthColumnWidth
        }
        {
          field: "jul"
          displayName: "JUL"
          headerClass: "July"
          headerCellTemplate: headerCellTmpl
          cellTemplate: percentageCellTmpl
          width: monthColumnWidth
        }
        {
          field: "aug"
          displayName: "AUG"
          headerClass: "August"
          headerCellTemplate: headerCellTmpl
          cellTemplate: percentageCellTmpl
          width: monthColumnWidth
        }
        {
          field: "sep"
          displayName: "SEP"
          headerClass: "September"
          headerCellTemplate: headerCellTmpl
          cellTemplate: percentageCellTmpl
          width: monthColumnWidth
        }
        {
          field: "oct"
          displayName: "OCT"
          headerClass: "October"
          headerCellTemplate: headerCellTmpl
          cellTemplate: percentageCellTmpl
          width: monthColumnWidth
        }
        {
          field: "nov"
          displayName: "NOV"
          headerClass: "November"
          headerCellTemplate: headerCellTmpl
          cellTemplate: percentageCellTmpl
          width: monthColumnWidth
        }
        {
          field: "dec"
          displayName: "DEC"
          headerClass: "December"
          headerCellTemplate: headerCellTmpl
          cellTemplate: percentageCellTmpl
          width: monthColumnWidth
        }
        {
          field: "ytd"
          displayName: "YTD"
          headerClass: "Year to Date"
          headerCellTemplate: headerCellTmpl
          cellTemplate: ytdCellTmpl
          width: 80
        }
      ]
