angular.module('services').factory "PartnerFunds", ($resource, Firms, $q) ->
  fields = [
    "id"
    "name"
    "about"
    "firm_logo_url"
    "firm_id"
    "contacts"
    "brightcove_video_id"
    "roadshow"
  ]
  service = $resource "api/v1/partners/:partner_id/funds",
    partner_id: "@partner_id"
    "fields[]": fields

  get_funds = (partner_id) ->
    service.query(
      partner_id: partner_id
    ).$promise
  {
    funds: get_funds
  }
