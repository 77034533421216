angular.module('controllers').controller "EditCloseSchedule",
  ($scope, $location, $modalInstance, $modal, $routeParams, IcnIdentity, Close, PrivateAccessFundResource,
   loadNextCloseOn, loadAvailableCloseDates, populateInvestmentsForUser, closedFund,
   refreshCloses, preCloseRequired, wiresDueRequired) ->

    $scope.fundId = $routeParams.pafId
    $scope.isOpenFund = !closedFund
    $scope.preCloseRequired = preCloseRequired
    $scope.wiresDueRequired = wiresDueRequired

    setFormDefaults = ->
      $scope.formData = {
        closeDate: ''
        docDueDate: ''
        wiresDueDate: ''
        fundingWindowStart: ''
        fundingWindowEnd: ''
        fundingCallPercentage: ''
        edit: {
          closeDate: ''
          docDueDate: ''
          wiresDueDate: ''
          fundingWindowStart: ''
          fundingWindowEnd: ''
          fundingCallPercentage: ''
        }
      }

    getClosesForFund = ->
      Close.closesForFund({
        fund_id: $routeParams.pafId,
        status: 'next_close'
      }).$promise.then (response) ->
        if refreshCloses
          $scope.plannedCloses = refreshCloses(response)
        else
          $scope.plannedCloses = response

    init = ->
      setMaxDueDate($scope.formData.closeDate)

      $scope.newCloseDate = {
        dateFormat: "dd-M-yy"
      }
      $scope.editCloseDate = {
        dateFormat: "dd-M-yy"
      }

      if $scope.wiresDueRequired
        setMaxWiresDueDate($scope.formData.closeDate)

      if $scope.preCloseRequired
        setMinFundingWindowEndDate($scope.formData.fundingWindowStart)

        $scope.newFundingWindowStartDate = {
          dateFormat: "dd-M-yy"
        }
        $scope.editFundingWindowStartDate = {
          dateFormat: "dd-M-yy"
        }

      getClosesForFund()

    reloadCloses = ->
      loadAvailableCloseDates()
      loadNextCloseOn()
      getClosesForFund()

    setMaxDueDate = (date) ->
      $scope.newDocDueDate = setMaxDate(date)

    setMaxWiresDueDate = (date) ->
      $scope.newWiresDueDate = setMaxDate(date)

    setMaxFundingWindowStartDate = (date) ->
      $scope.newFundingWindowStartDate = setMaxDate(date)

    setMaxFundingWindowEndDate = (date) ->
      min = $scope.newFundingWindowEndDate?.minDate
      $scope.newFundingWindowEndDate = setMaxDate(date)
      $scope.newFundingWindowEndDate.minDate = min

    setMinFundingWindowEndDate = (date) ->
      max = $scope.newFundingWindowEndDate?.maxDate
      $scope.newFundingWindowEndDate = setMinDate(date)
      $scope.newFundingWindowEndDate.maxDate = max

    setMaxEditDocDueDate = (date) ->
      $scope.editDocDueDate = setMaxDate(date)

    setMaxEditWiresDueDate = (date) ->
      $scope.editWiresDueDate = setMaxDate(date)

    setMaxEditFundingWindowStartDate = (date) ->
      $scope.editFundingWindowStartDate = setMaxDate(date)

    setMinEditFundingWindowEndDate = (date) ->
      max = $scope.editFundingWindowEndDate?.max
      $scope.editFundingWindowEndDate = setMinDate(date)
      $scope.editFundingWindowEndDate.max = max

    setMaxEditFundingWindowEndDate = (date) ->
      min = $scope.editFundingWindowEndDate?.min
      $scope.editFundingWindowEndDate = setMaxDate(date)
      $scope.editFundingWindowEndDate.min = min

    setMaxDate = (date) ->
      {
        maxDate: new Date(date),
        dateFormat: "dd-M-yy"
      }

    setMinDate = (date) ->
      {
        minDate: new Date(date),
        dateFormat: "dd-M-yy"
      }

    $scope.$watch "formData.closeDate", ->
      docDueDate = moment($scope.formData.docDueDate)
      closeDate = moment($scope.formData.closeDate)
      wiresDueDate = moment($scope.formData.wiresDueDate)
      fundingWindowStart = moment($scope.formData.fundingWindowStart)
      fundingWindowEnd = moment($scope.formData.fundingWindowEnd)

      if docDueDate != 'Invalid date'
        if closeDate != 'Invalid date'
          $scope.formData.docDueDate = $scope.formData.closeDate if docDueDate > closeDate
          setMaxDueDate($scope.formData.closeDate)
          setMaxWiresDueDate($scope.formData.closeDate)

        if fundingWindowStart != 'Invalid date'
          $scope.formData.fundingWindowStart = $scope.formData.closeDate if fundingWindowStart > closeDate
          setMaxFundingWindowStartDate($scope.formData.closeDate)

        if fundingWindowEnd != 'Invalid date'
          $scope.formData.fundingWindowEnd = $scope.formData.closeDate if fundingWindowEnd > closeDate
          setMaxFundingWindowEndDate($scope.formData.closeDate)

    $scope.$watch "formData.fundingWindowStart", ->
      fundingWindowStart = moment($scope.formData.fundingWindowStart)
      fundingWindowEnd = moment($scope.formData.fundingWindowEnd)

      if fundingWindowStart != 'Invalid date' && fundingWindowEnd != 'Invalid date'
        $scope.formData.fundingWindowEnd = $scope.formData.fundingWindowStart if fundingWindowStart > fundingWindowEnd
        setMinFundingWindowEndDate($scope.formData.fundingWindowStart)

    $scope.$watch "formData.edit.closeDate", ->
      setMaxEditDocDueDate($scope.formData.edit.closeDate)
      setMaxEditWiresDueDate($scope.formData.edit.closeDate)
      setMaxEditFundingWindowStartDate($scope.formData.edit.closeDate)
      setMaxEditFundingWindowEndDate($scope.formData.edit.closeDate)


    $scope.$watch "formData.edit.fundingWindowStart", ->
      setMinEditFundingWindowEndDate($scope.formData.edit.fundingWindowStart)

    $scope.cannotSubmit = ->
      common_fields = [$scope.formData.closeDate, $scope.formData.docDueDate]
      funding_fields = [
        $scope.formData.fundingWindowStart,
        $scope.formData.fundingWindowEnd,
        $scope.formData.fundingCallPercentage
      ]
      common_fields.some((el) -> [undefined, ''].includes(el)) ||
        ($scope.preCloseRequired && funding_fields.some((el) -> [undefined, ''].includes(el)) ) ||
        ($scope.wiresDueRequired && [undefined, ''].includes($scope.formData.wiresDueDate))

    $scope.removeClose = (close) ->
      close_date = moment(close.close_date).format("DD-MMM-YYYY")
      opts =
        templateUrl: "dialogs/custom_confirm_dialog/custom_confirm.html"
        controller: "customConfirmDialogCtrl"
        windowClass: "small-modal"
        resolve:
          header: -> "Edit Close Schedule"
          msg: -> "You are about to remove #{close_date} from the close schedule. Every investment currently
                   scheduled for this close will lose this close date. Do you wish to continue?"
          confirm_label: -> "OK"
          cancel_label: -> "Cancel"
      $modal.open(opts).result.then ->
        $scope.removedCloseId = close.id
        Close.delete({
          id: close.id,
          private_access_fund_id: $scope.fundId
        }).$promise.then  ->
          $scope.plannedCloses = $scope.plannedCloses.filter (close) -> close.id != $scope.removedCloseId
          reloadCloses()
          populateInvestmentsForUser()

    $scope.createClose = ->
      Close.createCloseForFund({
        status: 'next_close',
        private_access_fund_id: $scope.fundId,
        doc_due_date: $scope.formData.docDueDate,
        close_date: $scope.formData.closeDate,
        wires_due_date: $scope.formData.wiresDueDate,
        funding_window_start: $scope.formData.fundingWindowStart,
        funding_window_end: $scope.formData.fundingWindowEnd,
        funding_call_percentage: $scope.formData.fundingCallPercentage
      }).$promise.then (response) ->
        reloadCloses()
        setFormDefaults()

    $scope.editClose = (close) ->
      $scope.editMode = true
      close.edit_mode = true

      setMaxEditDocDueDate(close.close_date)
      $scope.formData.edit.closeDate = moment(close.close_date).format("YYYY-MMM-DD")
      $scope.formData.edit.docDueDate = moment(close.doc_due_date).format("YYYY-MMM-DD")

      if $scope.wiresDueRequired
        setMaxEditWiresDueDate(close.close_date)
        $scope.formData.edit.wiresDueDate = moment(close.wires_due_date).format("YYYY-MMM-DD")

      if $scope.preCloseRequired
        setMinEditFundingWindowEndDate(close.funding_window_start)
        $scope.formData.edit.fundingWindowStart = moment(close.funding_window_start).format("YYYY-MMM-DD")
        $scope.formData.edit.fundingWindowEnd = moment(close.funding_window_end).format("YYYY-MMM-DD")

        $scope.formData.edit.fundingCallPercentage = close.funding_call_percentage

    alert = (close) ->
      opts =
        templateUrl: "dialogs/custom_confirm_dialog/custom_confirm.html"
        controller: "customConfirmDialogCtrl"
        windowClass: "small-modal"
        resolve:
          header: ->
            "Warning"
          msg: ->
            "A countersignature already exists for all investments pending #{moment(close.close_date).format('DD-MMM-YYYY')} close. A new countersignature document must be signed or uploaded upon a change to close date. Do you wish to continue?"
          confirm_label: ->
            "Ok"
          cancel_label: ->
            "Cancel"
      $modal.open(opts).result.then( ->
        updateClose(close)
      )

    updateClose = (close) ->
      Close.update({
        id: close.id,
        close_date: $scope.formData.edit.closeDate,
        doc_due_date: $scope.formData.edit.docDueDate,
        wires_due_date: $scope.formData.edit.wiresDueDate,
        funding_window_start: $scope.formData.edit.fundingWindowStart,
        funding_window_end: $scope.formData.edit.fundingWindowEnd,
        funding_call_percentage: $scope.formData.edit.fundingCallPercentage,
        private_access_fund_id: $scope.fundId
      }).$promise.then (response) ->
        reloadCloses()
        populateInvestmentsForUser()
        close.edit_mode = undefined
        $scope.editMode = false
        $scope.formData.edit.closeDate = ''
        $scope.formData.edit.docDueDate = ''
        $scope.formData.edit.wiresDueDate = ''
        $scope.formData.edit.fundingWindowStart = ''
        $scope.formData.edit.fundingWindowEnd = ''
        $scope.formData.edit.fundingCallPercentage = ''

    $scope.updateClose = (close) ->
      if (['signed', 'pending_esign', 'uploaded'].includes(close?.countersignature_document?.status))
        alert(close)
      else
        updateClose(close)

    $scope.dismissModal = ->
      $modalInstance.dismiss()

    setFormDefaults()

    init()
