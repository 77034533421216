angular.module('services').factory "CKEditorService", ->
  config = {}

  # Filebrowser routes
  # The location of an external file browser, that should be launched when "Browse Server" button is pressed.
  config.filebrowserBrowseUrl = "/ckeditor/attachment_files"

  # The location of an external file browser, that should be launched when "Browse Server" button is pressed in the Flash dialog.
  config.filebrowserFlashBrowseUrl = "/ckeditor/attachment_files"

  # The location of a script that handles file uploads in the Flash dialog.
  config.filebrowserFlashUploadUrl = "/ckeditor/attachment_files"

  # The location of an external file browser, that should be launched when "Browse Server" button is pressed in the Link tab of Image dialog.
  config.filebrowserImageBrowseLinkUrl = "/ckeditor/pictures"

  # The location of an external file browser, that should be launched when "Browse Server" button is pressed in the Image dialog.
  config.filebrowserImageBrowseUrl = "/ckeditor/pictures"

  # The location of a script that handles file uploads in the Image dialog.
  config.filebrowserImageUploadUrl = "/ckeditor/pictures"

  # The location of a script that handles file uploads.
  config.filebrowserUploadUrl = "/ckeditor/attachment_files"

  config.allowedContent = true

  config.templates_files = ['/images/ckeditor/templates.js']

  config.stylesSet = 'default'

  config.startupOutlineBlocks = true

  config.templates_replaceContent = false

  config.extraPlugins = 'htmlbuttons,historicalperformancetable'

  config.allowedContent = {
    $1: {
      elements: CKEDITOR.dtd,
      attributes: true,
      styles: true,
      classes: true
    }
  }

  config.disallowedContent = "script; *[on*]"
  # Toolbar groups configuration. http://docs.ckeditor.com/#!/guide/dev_toolbar
  config.toolbar = [
    {name: 'document', items: ['Source', '-', 'Templates']},
    {name: 'tools', items: ['Maximize', 'ShowBlocks']},
    {name: 'clipboard', items: ['PasteText', 'PasteFromWord', '-', 'Undo', 'Redo']},
    {name: 'editing', items: ['Find', 'Replace']},
    {
      name: 'paragraph',
      items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'CreateDiv', '-', 'JustifyLeft',
              'JustifyCenter', 'JustifyRight', 'JustifyBlock']
    },
    {name: 'links', items: ['Link', 'Unlink']},
    {name: 'insert', items: ['Image', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar']},
    '/',
    {name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize']},
    {name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Subscript', 'Superscript', '-', 'RemoveFormat']},
    {name: 'colors', items: ['TextColor', 'BGColor']},
    {name: 'funds', items: ['HistoricalPerformanceTable']}
  ]

  return config
