angular.module('services').factory "PrivateAccessFundResource", ($resource) ->
  reseource = $resource "/api/v1/private_access_funds/:id",
    id: "@id"
  ,
    setLimitedForUser:
      url: "/api/v1/private_access_funds/:id/set_limited_for_user"
      method: "PATCH"

    removeAccessForUser:
      url: "/api/v1/private_access_funds/:id/remove_access_for_user"
      method: "PATCH"

    addAccessForUser:
      url: "/api/v1/private_access_funds/:id/add_access_for_user"
      method: "PATCH"

    reverseInquiryFunds:
      url: "/api/v1/private_access_funds/reverse_inquiry_funds"
      method: "GET"

    subsequentClose:
      url: "/api/v1/private_access_funds/:id/subsequent_close"
      method: "PATCH"
