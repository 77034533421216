angular.module('controllers').controller "dtccPeActivityFileCtrl",
  ($scope, $modalInstance, header, msg, confirmLabel, cancelLabel, pafs, Custodian, PrivateAccessFund, onSelectedItem,
  PafAdminSvcApi) ->

    fetchCapitalEvents = (pafId) ->
      privateAccessFund = new PrivateAccessFund id: pafId
      PafAdminSvcApi.getCapitalEvents(privateAccessFund).then (results) ->
        $scope.privateAccessFund = privateAccessFund
        $scope.capitalCalls = []
        $scope.distributions = []
        for event in results
          if event.wire_amount >= 0
            $scope.capitalCalls.push(event)
            $scope.selectedOption.capitalCalls[event.id] = false
          else
            $scope.distributions.push(event)
            $scope.selectedOption.distributions[event.id] = false
    
    fetchCustodians = (pafId) ->
      Custodian.for_dtcc_reportable({ private_access_fund_id: pafId }).$promise.then (response) ->
        $scope.custodians = response
        $scope.selectedOption.custodianIds = _.filter($scope.custodians, (i) ->
          i.dtcc_reporting_default
        ).map((fc) -> fc.id)

    $scope.header = header
    $scope.msg = msg
    $scope.confirmLabel = confirmLabel
    $scope.cancelLabel = cancelLabel
    $scope.pafs = pafs
    $scope.onSelectedItem = onSelectedItem
    $scope.selectedOption = {
      activity: "capitalCalls",
      capitalCalls: {},
      distributions: {},
      custodianIds: [],
      filename: '',
      pafId: '',
      fileType: 'csv'
    }

    $scope.validSelection = ->
      if $scope.selectedOption.activity == "capitalCalls"
        if _.find($scope.selectedOption.capitalCalls, (cc) -> !!cc)
          true
        else
          false
      else if $scope.selectedOption.activity == "distributions"
        if _.find($scope.selectedOption.distributions, (di) -> !!di)
          true
        else
          false
      else
        false

    $scope.selectAllCustodians = ->
      $scope.selectedOption.custodianIds = $scope.custodians.map((fc) -> fc.id)

    $scope.deselectAllCustodians = ->
      $scope.selectedOption.custodianIds = []

    $scope.cancel = ->
      $modalInstance.dismiss "no"

    $scope.onSelectedFundChange = ->
      pafId = $scope.selectedOption.pafId
      if !!pafId
        fetchCapitalEvents(pafId)
        fetchCustodians(pafId)

    $scope.submit = (selectedOption) ->
      $modalInstance.close({
        activityType: selectedOption.activity,
        capitalCalls: (_.filter _.keys(selectedOption.capitalCalls), (key) -> selectedOption.capitalCalls[key]),
        distributions: (_.filter _.keys(selectedOption.distributions), (key) -> selectedOption.distributions[key]),
        custodianIds: selectedOption.custodianIds,
        pafId: selectedOption.pafId,
        filename: selectedOption.filename,
        fileType: selectedOption.fileType
      })
