angular.module('services').factory 'Close', ($resource, $upload) ->
  resource = $resource '/api/v1/closes/:id',
    id: '@id'
  ,
    update:
      method: "PATCH"

    investmentsInClose:
      method: 'GET'
      url: '/api/v1/closes/:id/investments_in_close'
      isArray: true

    merge_countersignatures:
      method: 'PATCH'
      url: '/api/v1/closes/:id/merge_countersignatures'

    sendCounterSignature:
      method: "PATCH"
      url: '/api/v1/closes/:id/send_countersignature_document'

    closesForFund:
      method: 'GET'
      url: '/api/v1/closes/closes_for_fund'
      isArray: true

    bulkReviewByClose:
      method: 'GET'
      url: '/api/v1/closes/bulk_review_by_close'
      isArray: true

    bulkReviewByInvestment:
      method: 'GET'
      url: '/api/v1/closes/bulk_review_by_investment'
      isArray: false

    createCloseForFund:
      method: 'POST'
      url: '/api/v1/closes'

    executedCloseDates:
      method: 'GET'
      url: '/api/v1/closes/executed_close_dates_for_fund'
      isArray: true

    getCloseByDate:
      method: 'GET'
      url: '/api/v1/closes/get_close_by_date'

  resource.uploadCounterSignature = (id, files) ->
    $upload.upload(
      method: 'PATCH',
      url: "/api/v1/closes/#{id}/upload_countersignature_document"
      file: files[0]
    )
  resource
