angular.module('directives').directive "scroll", ->
  restrict: "A"
  link: (scope, element, attrs) ->
    element.click (e) ->
      e.preventDefault()
      $target = $(e.target)
      offset = ((if $target.data("offset") then $target.data("offset") else 214))
      scrollTo = $("#" + $target.data("target")).offset().top - offset
      return  if $target.data("noScrollDown") and ($(document).scrollTop() < scrollTo)
      $("html, body").animate
        scrollTop: scrollTo
      , 500
