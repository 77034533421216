angular.module('filters').filter 'truncateByWord', ->
  (text, length) ->
    length = 10 if (isNaN(length))
    return '' if angular.isUndefined(text)
    if (text.length <= length)
      text
    else
      words = String(text).split(' ')
      newText = words.shift()
      while words && (newText.length + words[0].length) < length
        newText = "#{newText} #{words.shift()}"
      newText
