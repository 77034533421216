angular.module('services').factory 'WhiteLabelPartnerService', ($q, $resource) ->
  _current = undefined

  resource = $resource('/api/v1/white_label_partners/:id',
    { id: '@id' }
    current:
      url: '/api/v1/white_label_partners/current'
      method: 'GET'
      params:
        approval: '@approval'
    query:
      url: '/api/v1/white_label_partners'
      method: 'GET'
      isArray: true
    update:
      method: 'PATCH'
  )

  current = (approval) ->
    if _current
      return _current

    deferred = $q.defer()
    resource.current(
      approval: approval
    ).$promise.then (response) ->
      deferred.resolve response

    _current = deferred.promise
    _current

  query = ->
    deferred = $q.defer()
    resource.query().$promise

  updateProfileVisibility = (id, value) ->
    resource.update(
      { id: id }
      white_label_partner:
        profile_visibility: value
    ).$promise

  updateProfileFieldOverwrite = (id, value) ->
    resource.update(
      { id: id }
      white_label_partner:
        profile_field_overwrite: JSON.stringify(value)
    ).$promise

  updateContactCardAttributeOverwrite = (id, value) ->
    resource.update(
      { id: id }
      white_label_partner:
        contact_card_attribute_overwrite: JSON.stringify(value)
    ).$promise

  updateSubscriptionDetailsVisibility = (id, value) ->
    resource.update(
      { id: id }
      white_label_partner:
        subscription_details_visibility: JSON.stringify(value)
    ).$promise

  updateNewIssuesProfileVisibility = (id, value) ->
    resource.update(
      { id: id }
      white_label_partner:
        new_issues_profile_visibility: JSON.stringify(value)
    ).$promise

  return {
    current: current
    query: query
    updateProfileVisibility: updateProfileVisibility
    updateProfileFieldOverwrite: updateProfileFieldOverwrite
    updateContactCardAttributeOverwrite: updateContactCardAttributeOverwrite
    updateSubscriptionDetailsVisibility: updateSubscriptionDetailsVisibility
    updateNewIssuesProfileVisibility: updateNewIssuesProfileVisibility
  }
