angular.module "icn.paf_admin"
  .controller "RedemptionsDashboardCtrl", ($scope, PrivateAccessFund, PafAdminSvcApi, $routeParams, $route, IcnIdentity) ->
    $scope.fundType = $routeParams.fund_type
    $scope.minDate = $routeParams.minDate
    $scope.maxDate = $routeParams.maxDate
    $scope.selectedGroupId = $routeParams.group

    $scope.minDateFormat = {
      dateFormat: "mm-dd-yy"
    }
    $scope.maxDateFormat = {
      dateFormat: "mm-dd-yy"
    }
    $scope.hideFundGroups = (IcnIdentity.isHomeOffice() || IcnIdentity.isPafAdmin()) && IcnIdentity.isWhiteLabel()

    refreshFundGroups = ->
      $scope.fundsReady = false
      PafAdminSvcApi.getFundGroups(true).then((response) ->
        $scope.fundGroups = [{ id: 0, name: "All", slug: "all" }].concat(response)
        $scope.selectedGroup = _.findWhere($scope.fundGroups, { id: parseInt($scope.selectedGroupId) || 0 })
        $scope.fundsReady = true if $scope.selectedGroup
      )

    refreshFunds = ->
      $scope.fundsReady = false
      if $scope.fundType == "pe"
        PafAdminSvcApi.getPrivateEquityFunds(true).then((response) -> prepareFunds(response))
      else if $scope.fundType == "hf"
        PafAdminSvcApi.hedgeFunds($scope.selectedGroupId, true).then((response) -> prepareFunds(response))

    prepareFunds = (response) ->
      $scope.funds = _.sortBy(response, "name")
      if IcnIdentity.isPafAdmin() && !IcnIdentity.isWhiteLabel() && $scope.fundType == "hf"
        $scope.funds.unshift({ id: -1, name: "All" })

      $scope.selectedFund = _.findWhere($scope.funds, { id: parseInt($routeParams.private_access_fund_id) || -1 })
      $scope.fundsReady = true if $routeParams.private_access_fund_id

    $scope.updateMinDate = ->
      $route.updateParams({ minDate: $scope.minDate })

    $scope.updateMaxDate = ->
      $route.updateParams({ maxDate: $scope.maxDate })

    $scope.selectGroup = (group_id) ->
      return if "#{group_id}" == $routeParams.group || ($routeParams.group == undefined && group_id == 0)

      group_id = null if group_id == 0
      $route.updateParams({ group: group_id, private_access_fund_id: undefined })

    $scope.selectId = (fund_id) ->
      selectedFundId = $routeParams.private_access_fund_id
      return if "#{fund_id}" == selectedFundId || (selectedFundId == undefined && fund_id == -1)

      fund_id = undefined if fund_id == -1
      $route.updateParams({ group: $scope.selectedGroupId, private_access_fund_id: fund_id })

    init = ->
      refreshFundGroups() if $scope.fundType == "hf" && !$scope.hideFundGroups
      refreshFunds()

    init()
