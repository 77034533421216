angular.module('services').factory 'PafAdminClose', ($resource) ->
  resource = $resource '/api/v1/investments/:id',
    id: '@id'
  ,
    toggleInNextClose:
      method: 'PATCH'
      url: '/api/v1/investments/:id/toggle_in_next_close'
    toggleInNextCloseDate:
      method: 'PATCH'
      url: '/api/v1/investments/:id/toggle_in_next_close_date'
