angular.module('filters').filter 'ordinal', ->
  (number) ->
    if isNaN(number) or number < 1
      return "--"
    else
      lastDigit = number % 10
      if lastDigit == 1
        return number + 'st'
      else if lastDigit == 2
        return number + 'nd'
      else if lastDigit == 3
        return number + 'rd'
      else if lastDigit > 3
        return number + 'th'
    return
