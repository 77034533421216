angular.module('directives').directive "butterbar",
  ($rootScope) ->
    restrict: 'AE'
    templateUrl: 'templates/directives/butterbar.html'
    link: (scope, element, attrs) ->
      $rootScope.butterbarConfig = { force: false, message: '' }

      updateVisibility = ->
        if $rootScope.butterbarConfig.force
          scope.show = true
        else
          scope.show = not scope.xhr_done

      $rootScope.$watch "xhr.done", (present, past) ->
        scope.xhr_done = present
        updateVisibility()

      $rootScope.$watch("butterbarConfig", updateVisibility, true)
