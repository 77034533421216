angular.module('services').factory 'CustomRedemptionConfigurationService', (RedemptionAmountService) ->
  svc = {
    init: (privateAccessFund, redemption = undefined) ->
      svc.privateAccessFund = privateAccessFund
      svc.ReportableAmountType = if svc.privateAccessFund.open_ended then 'NAV' else 'Capital Balance'
      svc.customRedemptionsEnabled = (investment) ->
        investment.redemption_settings?.enable_custom_redemptions && svc.privateAccessFund.open_ended
      svc.redemption = redemption

    # client management initiate redemption request functions

    showFullRedemptionWarning: (value, type, redemption) ->
      return false unless svc.customRedemptionsEnabled(redemption.investment)

      switch type
        when RedemptionAmountService.Amount
          return value >= svc.redemption.totals.amount
        when RedemptionAmountService.Units
          return value > svc.redemption.totals.units
        else
          return false

    # client management redemption sub list functions

    subListNavAmount: (redemption) ->
      return if svc.hideNavAmount(redemption)

      if !!redemption.nav then redemption.nav.toFixed(0) else 0

    subListAmountPayable: (redemption) ->
      return if svc.hideAmountPayable(redemption)

      if !!redemption.total_distribution then redemption.total_distribution.toFixed(0) else 0

    subListAmountPayablePercentage: (redemption) ->
      if redemption.full
        value = (1.0 - redemption.hold_back)
      else
        value = redemption.redemption_percent

      "#{value.toFixed(2) * 100}%"

    subListHoldBackAmount: (redemption) ->
      return if svc.hideHoldBackAmount(redemption)

      if !!redemption.hold_back_amount then redemption.hold_back_amount.toFixed(0) else 0

    subListHoldBackPercentage: (redemption) ->
      "#{redemption.hold_back.toFixed(2) * 100}%"

    subListUnits: (redemption) ->
      return if svc.hideUnits(redemption)

      if !!redemption.units then redemption.units.toFixed(0) else 0

    hideNavAmount: (redemption) ->
      svc.customRedemptionsEnabled(redemption.investment) &&
        (!redemption.nav || redemption.redemption_request > redemption.nav)

    hideAmountPayable: (redemption) ->
      svc.customRedemptionsEnabled(redemption.investment) && (
        !redemption.nav && redemption.calculation_type == RedemptionAmountService.Percent ||
        !redemption.total_distribution
      )

    hideHoldBackAmount: (redemption) ->
      svc.customRedemptionsEnabled(redemption.investment) && (
        (!redemption.nav && redemption.calculation_type == RedemptionAmountService.Percent) ||
        !redemption.hold_back_amount
      )

    hideUnits: (redemption) ->
      svc.customRedemptionsEnabled(redemption.investment) && (!redemption.units || !svc.privateAccessFund.unitized)
  }

  return svc
