angular.module('controllers').controller "nasdaqExportCtrl",
  ($scope, $modalInstance, header, pafs, confirmLabel, cancelLabel, PrivateAccessFund, PafAdminSvcApi) ->

    fetchAvailableQuarters = (pafId) ->
      $scope.fields.effectiveQuarter = ''
      $scope.availableQuarters = []
      privateAccessFund = new PrivateAccessFund id: pafId

      PafAdminSvcApi.getReportingQuarters(privateAccessFund).then (results) ->
        $scope.availableQuarters = _.filter results, (quarter) ->
          return quarter.count > 0

        $scope.availableQuarters =  _.map $scope.availableQuarters, (quarter) ->
          return {id: quarter.id, end_date: quarter.end_date}

        if !$scope.availableQuarters.length
          $scope.placeholder = "--- No Quarters Available ---"

    $scope.placeholder = "--- Available Quarters ---"
    $scope.header = header
    $scope.confirmLabel = confirmLabel
    $scope.cancelLabel = cancelLabel
    $scope.pafs = pafs
    $scope.fields = {
      effectiveQuarter: '',
      filename: '',
      pafId: '',
      fileType: 'csv'
    }

    $scope.isEmpty = ->
      return $scope.fields.pafId && !$scope.availableQuarters.length

    $scope.validSelection = ->
      return !!$scope.fields.effectiveQuarter

    $scope.onSelectedFundChange = ->
      pafId = $scope.fields.pafId
      if !!pafId
        fetchAvailableQuarters(pafId)

    $scope.cancel = ->
      $modalInstance.dismiss "no"

    $scope.submit = (selectedOption) ->
      $modalInstance.close({
        effectiveQuarter: selectedOption.effectiveQuarter,
        filename: selectedOption.filename,
        pafId: selectedOption.pafId,
        fileType: selectedOption.fileType,
      })
