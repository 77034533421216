angular.module('services').factory "CustodianAccountName", ->
  decorated = (custodian, accountType, accountName) ->
    if accountName
      if prefix(custodian, accountType)
        accountName = prefix(custodian, accountType) + ' ' + accountName
      if suffix(custodian, accountType)
        accountName = accountName + ' ' + suffix(custodian, accountType)

    accountName

  suffix = (custodian, accountType) ->
    return "" unless custodian && accountType

    if custodian == 'Pershing'
      if accountType == 'Retirement: IRA (Traditional, SEP, Rollover, Roth, etc.)' || accountType == 'Retirement: Other'
        'Pershing LLC as Custodian'
      else
        'FBO Pershing LLC'

  prefix = (custodian, accountType) ->
    return "" unless custodian && accountType

    switch custodian
      when 'Fidelity RIAs / Family Office', 'NFS Broker-Dealers'
        if accountType == 'Retirement: IRA (Traditional, SEP, Rollover, Roth, etc.)'
          'NFS/FMTC FBO'
        else
          'NFS FBO'
      when 'Charles Schwab'
        if accountType == 'Retirement: IRA (Traditional, SEP, Rollover, Roth, etc.)'
          'Charles Schwab & Co., Inc. as Custodian for'
        else
          'Charles Schwab & Co., Inc. FBO'
      when 'Millennium Trust'
        'Millennium Trust Co. LLC Custodian FBO'
      when 'TD Ameritrade'
        if accountType == 'Taxable'
          'TD Ameritrade Clearing Inc. FBO'
        else
          'TD Ameritrade Clearing Inc. Cust. FBO'
      when 'Community National Bank'
        'Community National Bank CUST FBO'

  return {
    decorated: decorated
    suffix: suffix
    prefix: prefix
  }
