angular.module('services').factory "UserPafExceptionService", ($resource, $q) ->
  UserPafExceptionService = ->
    @service = $resource("/api/v1/user_paf_exceptions",
      null
    ,

      checkExceptionApplied:
        method: 'GET',
        url: '/api/v1/user_paf_exceptions/check_exception_applied/:inv_id',
        inv_id: @inv_id

      deleteUnusedException:
        method: 'DELETE',
        url: '/api/v1/user_paf_exceptions/:exception_id',
        exception_id: @exception_id

      getPafUserUnusedExceptions:
        method: 'GET',
        url: '/api/v1/user_paf_exceptions/user_paf_unused_exceptions',
        params:
          paf_id: @paf_id
        isArray: true

      getAllUnusedReverseSolicitationForPaf:
        method: 'GET',
        url: '/api/v1/user_paf_exceptions/unused_exceptions_for_paf',
        params:
          paf_id: @paf_id
        isArray: true
    )
    return

  UserPafExceptionService::addNewException = (fund_id, user_email, reason) ->
    @service.save(fund_id: fund_id, user_email: user_email, reason: reason)

  UserPafExceptionService::deleteUnusedException = (exception_id) ->
    @service.deleteUnusedException(exception_id: exception_id)

  UserPafExceptionService::checkExceptionApplied = (inv_id) ->
    @service.checkExceptionApplied(inv_id: inv_id)

  UserPafExceptionService::getPafUserUnusedExceptions = (paf_id) ->
    @service.getPafUserUnusedExceptions(paf_id: paf_id)

  UserPafExceptionService::getAllUnusedReverseSolicitationForPaf = (paf_id) ->
    @service.getAllUnusedReverseSolicitationForPaf(paf_id: paf_id)

  return new UserPafExceptionService()
