angular.module('directives').directive "numbersOnly", ->
  require: "?ngModel"
  link: (scope, el, attrs, ngModelCtrl) ->
    fromUser = (text) ->
      if text
        transformedInput = text.replace(/[^0-9]/g, '')

        if transformedInput != text
          ngModelCtrl.$setViewValue(transformedInput)
          ngModelCtrl.$render()

        return transformedInput
      return undefined

    ngModelCtrl.$parsers.push(fromUser)
