angular.module('services').factory "PreApprovalQuestionnaireForm", ($resource, $http, $q) ->
  service =
    questionnaireExistForInvestor: (params) ->
      $http({
        url: "/api/v1/required_pre_approval_questionnaires/for_investor",
        method: "GET",
        params: params,
        cache: true
      })
    questionnaireExistForInvestorProfile: (params) ->
      $http({
        url: "/api/v1/required_pre_approval_questionnaires/for_investor_profile",
        method: "GET",
        params: params,
        cache: true
      })

  return service
