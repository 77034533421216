# Takes a sum of an array. Also takes an optional attribute name to sum an array of objects.
angular.module('filters').filter 'sum', ->
  (arr, attr=null) ->
    intArray = if attr? then _.pluck(arr, attr).map((x) -> parseFloat(x)) else arr
    intArray = intArray.map (x) ->
      parsed = parseFloat(x)
      if isNaN(parsed) then 0 else parsed
    intArray.reduce(
      (x,y) -> x + y
    , 0).toFixed(2)
