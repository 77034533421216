angular.module('controllers').controller "TransfersTableCtrl", ($scope, $q, $modal, CurrencySelector) ->
  $scope.hasSupplementalForm = ->
    if $scope.privateAccessFund
      $scope.privateAccessFund.has_investment_supplemental_form
    else if $scope.selectedPaf
      $scope.selectedPaf.has_investment_supplemental_form

  $scope.showUnits = ->
    if $scope.privateAccessFund
      $scope.privateAccessFund.open_ended && $scope.privateAccessFund.unitized
    else if $scope.selectedPaf
      $scope.selectedPaf.open_ended && $scope.selectedPaf.unitized

  $scope.colspanForTotal = ->
    initCalspan = 4
    if $scope.hasSupplementalForm()
      initCalspan = 5
    if $scope.showUnits()
      ++initCalspan
    initCalspan

  $scope.profileText = (investment) ->
    return 'Not Assigned' unless investment
    investment.investor_profile_completed && "Complete" || "Incomplete"

  $scope.chevron = {
    down: "/images/chevron-down.svg",
    right: "/images/chevron-right.svg"
  }

  $scope.sectionChevronIcon = (subList) ->
    if subList.expanded
      $scope.chevron.down
    else
      $scope.chevron.right

  $scope.transferChevronIcon = (transfer) ->
    if transfer.expanded
      $scope.chevron.down
    else
      $scope.chevron.right

  $scope.expandTransfers = (subList) ->
    loadTransfer(subList).then ->
      subList.expanded = !subList.expanded
      if !subList.expanded
        subList.transfers.forEach (transfer) -> transfer.expanded = false

  $scope.expandTransfer = (transfer) ->
    $scope.loadTransferInvestments(transfer).then ->
      transfer.expanded = !transfer.expanded

  $scope.sendExecuteTransfer = (transfer, subList) ->
    $scope.executeTransfer(transfer).then ->
      if subList.total == 0
        subList.expanded = false

  $scope.orderBy = (field) ->
    $scope.orderByField = field
    $scope.reverseSort = !$scope.reverseSort

  $scope.disabledLink = (transfer) ->
    $scope.subscription_review || !transfer.available

  $scope.updateEffectiveDate = (transfer, subList) ->
    $modal.open(
      templateUrl: "dialogs/transfer_dialog/update_effective_date_dialog.html"
      controller: "initiateTransferDialogCtrl"
      windowClass: "small-modal"
      resolve:
        fund: -> $scope.selectedPaf
        investment: -> transfer
        addOnInvestments: -> null
    )

  loadTransfer = (subList) ->
    dfd = $q.defer()

    if (!subList.loaded)
      subList.loaded = true
      $scope.populateTransfers(subList: subList).then ->
        dfd.resolve()
    else
      dfd.resolve()
    dfd.promise
