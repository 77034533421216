angular.module 'icn.components.fund_selector'
  .directive 'icnFundSelector', ->
    templateUrl: 'directives/fund_selector/fund_selector.html'
    restrict: 'E'
    scope:
      fundType: '@'
      filterName: '@'
      showDisabled: '@'
      displayInline: '@'
      nonAdmin: '@'
      onSelectChanged: '&'

    controller: ($scope, FundSelectorService, $route) ->
      $scope.fundSelectorService = FundSelectorService
      onSelectChanged = $scope.onSelectChanged()

      init = ->
        $scope.fundSelectorService.loadFunds($scope.fundType, $scope.filterName).then ->
          watchFundSelectorService()

      watchFundSelectorService = ->
        $scope.$watch 'fundSelectorService.selectedFund', (fund) ->
          $scope.selectorFund = _.findWhere($scope.fundSelectorService.funds, {id: parseInt(fund.id)}) if fund
          onSelectChanged(fund) if onSelectChanged

      $scope.selectFund = (item) ->
        $route.updateParams({private_access_fund_id: item?.id})

      $scope.$watch('fundType', (newVal, oldVal) ->
        if oldVal != newVal
          init()
      )

      init()
