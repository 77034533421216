angular.module('controllers').controller "peFundsCapitalReportCtrl",
  ($scope, $modalInstance, header, confirmLabel, cancelLabel, pafs,FundsCapitalReportsService, onSelectedItem,
  PafAdminSvcApi) ->

    $scope.header = header
    $scope.confirmLabel = confirmLabel
    $scope.cancelLabel = cancelLabel
    $scope.pafs = pafs
    $scope.onSelectedItem = onSelectedItem
    $scope.fields =  {
      pafIds: [],
      closedDate: '',
      filename: '',
      transactionType: '',
      custodianIds: [],
      firmIds: [],
      wlpIds: [],
      fileType: 'csv',
      isRounded: 'false',
      showNavPerShare: 'false',
      timeQTD: true,
      timeYTD: true,
      timeITD: true
    }
    $scope.selectedFields =  {
      month: ''
      year: ''
    }

    $scope.transactionTypes = [
      { name: 'Position', id: 'Subscriptions' },
      { name: 'Third Party', id: 'ThirdParty' },
      { name: 'Transfers', id: 'Transfers' },
    ]

    $scope.availableMonths = []
    $scope.availableYears = []
    $scope.dateCombination = []
    $scope.selectableMonths = []
    $scope.selectableYears = []

    $scope.cancel = ->
      $modalInstance.dismiss "no"

    $scope.submit = (fields) ->
      year = $scope.selectedFields.year
      month = $scope.selectedFields.month
      day = new Date(year, month, 0).getDate()
      $scope.fields.closedDate = moment("#{year}-#{month}-#{day}").format("MM/DD/YYYY")
      $modalInstance.close(fields)

    $scope.selectAllPafs = ->
      $scope.fields.pafIds = $scope.pafs.map((fc) -> fc.id)
      $scope.onPafChange()

    $scope.deselectAllPafs = ->
      $scope.fields.pafIds = []
      $scope.onPafChange()

    $scope.selectAllCustodians = ->
      $scope.fields.custodianIds = $scope.custodians.map((record) -> record.id)

    $scope.deselectAllCustodians = ->
      $scope.fields.custodianIds = []

    $scope.selectAllWlp = ->
      $scope.fields.wlpIds = $scope.wlps.map((record) -> record.id)

    $scope.deselectAllWlp = ->
      $scope.fields.wlpIds = []

    $scope.selectAllFirms = ->
      $scope.fields.firmIds = $scope.firms.map((record) -> record.id)

    $scope.deselectAllFirms = ->
      $scope.fields.firmIds = []

    $scope.onPafChange = ->
      $scope.selectedFields.month = ''
      $scope.selectedFields.year = ''
      $scope.availableMonths = []
      $scope.availableYears = []
      $scope.fields.custodianIds = []
      grabCustodiansForPafs($scope.fields.pafIds)
      grabPcapReportDateForPafs($scope.fields.pafIds)

    $scope.onYearChange = ->
      months = $scope.dateCombination.filter((e) -> e.year == $scope.selectedFields.year).map((e) -> e.month)
      $scope.selectableMonths = $scope.availableMonths.filter((e) -> e.id in months)

    $scope.onMonthChange = ->
      years = $scope.dateCombination.filter((e) -> e.month == $scope.selectedFields.month).map((e) -> e.year)
      $scope.selectableYears = $scope.availableYears.filter((e) -> e.id in years)

    $scope.showDatePicker = ->
      return $scope.fields.transactionType && $scope.fields.transactionType != 'ThirdParty'

    $scope.showTimeInterval = ->
      return $scope.fields.transactionType && $scope.fields.transactionType == 'Subscriptions'

    $scope.showIncludeNavPerShare = ->
      return $scope.fields.transactionType && ($scope.fields.transactionType == 'ThirdParty' || $scope.fields.transactionType == 'Subscriptions')

    grabPcapReportDateForPafs = (pafs) ->
      PafAdminSvcApi.getReportingQuartersForPafs('paf_ids[]': pafs).then (response) ->
        for period in response
          pushYearToArray(period.year)
          pushMonthToArray(period.month)
          pushDateCombination(period.year, period.month)
      $scope.selectableMonths = $scope.availableMonths
      $scope.selectableYears = $scope.availableYears

    pushYearToArray = (year) ->
      if $scope.availableYears.length == 0
        $scope.availableYears.push({name: year, id: year})
        return
      unless year in $scope.availableYears.map((e) -> e.name)
        $scope.availableYears.push({name: year, id: year})

    pushMonthToArray = (month) ->
      month_name = moment().month(month - 1).format('MMMM')
      if $scope.availableMonths.length == 0
        $scope.availableMonths.push({name: month_name, id: month})
        return
      unless month_name in $scope.availableMonths.map((e) -> e.name)
        $scope.availableMonths.push({name: month_name, id: month})

    pushDateCombination = (year, month) ->
      $scope.dateCombination.push({year: year, month: month})

    grabCustodiansForPafs = (pafs) ->
      FundsCapitalReportsService.custodianByPaf(pafs).then (response) ->
        $scope.custodians = response['custodians']

    init = ->
      pafs_ids =  $scope.pafs.map((fc) -> fc.id)
      FundsCapitalReportsService.filtersByPafs(pafs_ids).then (response) ->
        $scope.custodians = []
        $scope.wlps = response['wlps']
        $scope.firms = response['firms']
      grabPcapReportDateForPafs($scope.fields.pafIds)

    init()

