angular.module('controllers').controller "UbsSoftValidationsDialogCtrl",
  ($scope, $modal, $modalInstance, InvestmentService, accountId, validations) ->
    $scope.headerText = "Validation Warnings"

    $scope.accountId = accountId
    $scope.validations = validations

    $scope.allowTaxExempt = $scope.validations.allow_tax_exempt
    $scope.allowErisaAccount = $scope.validations.allow_erisa_account
    $scope.validAccount = $scope.validations.valid_account
    $scope.validAccountAddresses = $scope.validations.valid_account_addresses
    $scope.validNetWorth = $scope.validations.valid_net_worth
    $scope.validCountry = $scope.validations.valid_country
    $scope.validSuitability = $scope.validations.valid_suitability

    $scope.reject = ->
      $modalInstance.close(false)

    $scope.accept = ->
      $modalInstance.close(true)
