angular.module('services').factory "Countersignature", ($resource) ->
  resource = $resource "/api/v1/countersignature/investments",
  {},
      index:
        method: 'GET'
        isArray: true

      index = ({ pafId = null }) ->
        resource.index(
          paf_id: pafId
        ).$promise

  return {
    index: index
  }
