angular.module('controllers').controller "ReminderDialogController",
  ($scope, $modalInstance, ModalDialogInstancesObserver, SignatureProcessService, RequiredDocument, documentWithSignatureProcess) ->
    $scope.documentId = documentWithSignatureProcess.id
    $scope.errors = null

    $scope.ok = ->
      $scope.errors = null
      SignatureProcessService.sendReminder($scope.signatureProcessId).then ->
        $modalInstance.close()
      , (response) ->
        $scope.errors = "Problem sending reminder for agreement. Check that the respective email is valid."

    $scope.cancel = ->
      $modalInstance.dismiss()

    init = ->
      RequiredDocument.retrieveSignatureProcessPendingParticipant(id: $scope.documentId).$promise.then (response) ->
        $scope.name = response.waiting_on.name
        $scope.email = response.waiting_on.email
        $scope.signatureProcessId = response.id
      , (response) ->
        $scope.unableToFindAgreement = true
        $scope.errors = "Problem finding agreement"

    init()

    # dismiss modal dialog box when navigate to other page
    ModalDialogInstancesObserver.setModalDialogInstance($modalInstance)
