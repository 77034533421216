angular.module("icn.paf_admin")
.factory "CashOverCommittedService", ($resource, $http, $q) ->
  CashOverCommittedService =
    $resource("/api/v1/fund_admin_reporting", {},
      exportCashReport:
        url: "/api/v1/fund_admin_reporting/export_cash_report"
        method: "POST"
      exportOverCommitmentReport:
        url: "/api/v1/fund_admin_reporting/export_overcommitment_report"
        method: "POST"
      getValidDates:
        url: "/api/v1/fund_admin_reporting/valid_reporting_periods_for_pe"
        method: "GET"
        isArray: false
      getValidPartners:
        url: "/api/v1/fund_admin_reporting/valid_reporting_partners_for_pe"
        method: "GET"
        isArray: false
    )

  CashOverCommittedService::export = (closeDate, filename, partners, reportType) ->
    if reportType == 'Cash'
      CashOverCommittedService.exportCashReport({
        close_date: closeDate
        filename: filename
        partners: partners
      }).$promise
    else if reportType == 'Overcommitted'
      CashOverCommittedService.exportOverCommitmentReport({
        close_date: closeDate
        filename: filename
        partners: partners
      }).$promise

  CashOverCommittedService::getValidDates = ->
    CashOverCommittedService.getValidDates().$promise

  CashOverCommittedService::getValidPartners = ->
    CashOverCommittedService.getValidPartners().$promise

  return new CashOverCommittedService()
