angular.module('controllers').controller "OverrideApprovalStatus", ($scope, $modalInstance, InvestmentService,
privateAccessFundId, investment, listStatus) ->

  $scope.privateAccessFundId = privateAccessFundId
  $scope.investment = investment
  $scope.listStatus = listStatus
  $scope.overrideStatusSelected = { value: null }

  $scope.dismissModal = ->
    $modalInstance.dismiss()

  $scope.overrideApprovalStatus = ->
    InvestmentService.overrideApprovalStatus($scope.investment.id, $scope.overrideStatusSelected.value).then((result) ->
      $modalInstance.close(result)
    )

  options = [
    {
      label: 'External Review'
      id: 'external_review'
    }
    {
      label: 'External Review 2'
      id: 'external_secondary_review'
    }
    {
      label: 'Document Review'
      id: 'ir_review'
    }
    {
      label: 'Legal Review'
      id: 'compliance_review'
    }
  ]

  $scope.disableStatusOverride = ->
    $scope.overrideStatusSelected.value == null || $scope.statusOptions.length <= 0

  init = ->
    $scope.statusOptions = do ->
      options = _.filter options, (option) -> $scope.investment.statuses.includes(option.id)
      switch $scope.listStatus
        when'Approved Subscriptions'
          options
        when 'Compliance Review'
          options.filter (obj) ->
            obj.id != 'compliance_review'
        when 'IR Review'
          options.filter (obj) ->
            obj.id == 'external_review' || obj.id == 'external_secondary_review'

  init()
