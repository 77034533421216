angular.module('services').factory "Dialog", ($modal) ->
  return {
    openNotifyDialog: (header, msg, showCancel = true, confirmLabel = 'Ok') ->
      opts =
        templateUrl: "dialogs/custom_confirm_dialog/custom_confirm.html"
        controller: "customConfirmDialogCtrl"
        windowClass: "small-modal"
        resolve:
          header: ->
            header
          msg: ->
            msg
          confirm_label: ->
            confirmLabel
          cancel_label: ->
            if showCancel
              "Cancel"
            else
              ""
      $modal.open(opts)
  }
