angular.module('controllers').controller "RecallInvestment", ($scope, $modalInstance, InvestmentService,
privateAccessFundId, investmentId, recallInvestmentRequested) ->

  $scope.privateAccessFundId = privateAccessFundId
  $scope.investmentId = investmentId
  $scope.recallInvestmentRequested = recallInvestmentRequested
  $scope.header = if $scope.recallInvestmentRequested then 'Cancel Recall' else 'Recall Investment'

  $scope.dismissModal = ->
    $modalInstance.dismiss()

  $scope.msg = ->
    if $scope.recallInvestmentRequested
      "You are about to cancel the recall request for this investment. Do you wish to proceed?"
    else
      "You are about to request a return of the selected investment to External Review status. " +
      "Do you wish to proceed?"

  $scope.recallInvestment = ->
    InvestmentService.recallInvestment(privateAccessFundId, investmentId).then((result) ->
      $modalInstance.close(result)
    )
