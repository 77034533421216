angular.module('controllers').controller "SelectRequiredDocumentTypeCtrl",
  ($scope, $modalInstance, RequiredDocument, investment,
  isFromAddDocumentModal, $upload, ButterbarService, documents) ->
    $scope.investment = investment
    $scope.documents = documents
    $scope.createAmlDocumentWithAmlType = (chosenType) ->
      if $scope.allowAddRequiredDocAndUpload()
        addRequiredDocumentAndUploadFile(chosenType)
      else
        addRequiredDocument(chosenType)

    addRequiredDocument = (chosenType) ->
      RequiredDocument.createForInvestment(
        'investment_id': $scope.investment.id
        'required_document_type_id': chosenType.id
      ).$promise.then(
        (response) ->
          $modalInstance.close(response)
        (error) ->
          $scope.actionError = true
      )

    addRequiredDocumentAndUploadFile = (chosenType) ->
      file = angular.element("input[type='file']")[0].files[0]
      params =
        method: 'POST',
        url: "/api/v1/required_documents/create_for_investment",
        file: file,
        data:
          investment_id: $scope.investment.id,
          required_document_type_id: chosenType.id
          doc_already_accepted: true
          add_approval: true

      if file == undefined
        $scope.actionError = true
        return

      ButterbarService.force()
      $upload.upload(
        params
      ).success((response) ->
        ButterbarService.removeForce()
        $modalInstance.close(response)
      ).error (errors) ->
        $scope.actionError = true

    $scope.allowAddRequiredDocAndUpload = ->
      $scope.investment.approval_status == 'Accepted'

    $scope.cancelDialog = ->
      $modalInstance.dismiss()

    filterRequiredDocumentTypes = (resp) ->
      removedDocs = ["Suitability Document - Entity", "Suitability Document - Individual"]

      subscriptionDocumentTypes = $scope.documents.filter (el) ->
        el.document_type is 'Subscription Document'

      removedDocs.push 'Subscription Document' if subscriptionDocumentTypes.length > 0

      $scope.aml_types = resp.required_document_types.filter (el) ->
        # Remove documents that appear in removedDocs
        (removedDocs.indexOf(el.name) == -1)

    init = ->
      if isFromAddDocumentModal
        RequiredDocument.requiredDocumentTypesAddDocumentModal().$promise.then (resp) ->
          filterRequiredDocumentTypes(resp)
      else
        RequiredDocument.requiredDocumentTypes().$promise.then (resp) ->
          filterRequiredDocumentTypes(resp)
    init()
