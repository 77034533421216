angular.module('controllers').controller "AddReverseSolicitationDialogCtrl",
  ($scope, $rootScope, $modal, $modalInstance, ModalDialogInstancesObserver,
   PrivateAccessFunds, Users, UserPafExceptionService) ->
    $scope.reverseSolicitationForm = {}
    $scope.reverseSolicitation = {}
    $scope.availableUserList = []
    $scope.pafNotOnList = false
    $scope.errorMessage = ''
    $scope.errorAddingException = false

    $scope.cancelDialog = ->
      $modalInstance.dismiss()

    $scope.addNewException = ->
      params = {
        fund_id: $scope.reverseSolicitation.private_access_fund.id,
        user_email: $scope.reverseSolicitation.user,
        reason: $scope.reverseSolicitation.reason
      }
      UserPafExceptionService.addNewException(params.fund_id,
                                              params.user_email,
                                              params.reason).$promise.then (res) ->
        $modalInstance.close('success')
      .catch (error) ->
        $scope.errorAddingException = true
        if error.data.message == 'Couldn\'t find ContactCard'
          $scope.errorMessage = 'User not found'
        else
          $scope.errorMessage = error.data.message

    $scope.searchUsers = ->
      regex = /^$|^[A-Za-z0-9._%+\-]+@[A-Za-z0-9.\-]+\.[A-Za-z]{2,}$/
      if $scope.reverseSolicitation.user && $scope.reverseSolicitation.user.match(regex)
        getAvailableUserList($scope.reverseSolicitation.user)

    getAvailableUserList = (email) ->
      Users.usersListForPafException(email).then (userLists) ->
        $scope.availableUserList = userLists.map (userObject) ->
          userObject.full_name = userObject.last_name
                                           .concat(', ')
                                           .concat(userObject.first_name)
                                           .concat(' - ')
                                           .concat(userObject.wlp_name_from_login)
          userObject
        $scope.reverseSolicitation.user = $scope.availableUserList[0].login if $scope.availableUserList.length == 1

    checkIfPafOnList = (val) ->
      if typeof val == 'string'
        $scope.pafNotOnList = true
      else
        $scope.pafNotOnList = false

    $scope.fieldOnBlurCheck = (field) ->
      return if typeof $scope.reverseSolicitation.private_access_fund == "object"
      if field == 'paf'
        matchList = _.filter($scope.openFunds, (object) ->
          object.fund_name == $scope.reverseSolicitation.private_access_fund
        )
        if matchList.length > 0
          $scope.reverseSolicitation.private_access_fund = matchList[0]

    $scope.$watch "reverseSolicitation.private_access_fund", ((newAssets, oldAssets) ->
      unless _.isUndefined(newAssets)
        checkIfPafOnList(newAssets)
    ), true

    $scope.$watch "reverseSolicitation.user", ((newAssets, oldAssets) ->
      $scope.reverseSolicitation.user = newAssets.login if typeof $scope.reverseSolicitation.user == "object"
      $scope.availableUserList = []
    ), true

    init = ->
      PrivateAccessFunds.getAllPafForReverseSolicitation().$promise.then (funds) ->
        $scope.openFunds = funds

    init()
