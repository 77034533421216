angular.module('controllers').controller "dtccExportCtrl",
  ($scope, $modalInstance, header, confirmLabel, cancelLabel, pafs, onSelectedItem, Custodian, reportClosingShares) ->

    $scope.header = header
    $scope.confirmLabel = confirmLabel
    $scope.cancelLabel = cancelLabel
    $scope.pafs = pafs
    $scope.onSelectedItem = onSelectedItem
    $scope.reportClosingShares = reportClosingShares
    $scope.fields =  {
      pafIds: [],
      effectiveDate: '',
      filename: '',
      custodianIds: [],
      fileType: 'csv',
      reportClosingShares: false
    }

    $scope.cancel = ->
      $modalInstance.dismiss "no"

    $scope.submit = (fields) ->
      $modalInstance.close(fields)

    $scope.selectAllPafs = ->
      $scope.fields.pafIds = $scope.pafs.map((fc) -> fc.id)

    $scope.deselectAllPafs = ->
      $scope.fields.pafIds = []

    $scope.selectAllCustodians = ->
      $scope.fields.custodianIds = $scope.custodians.map((fc) -> fc.id)

    $scope.deselectAllCustodians = ->
      $scope.fields.custodianIds = []

    init = ->
      Custodian.for_dtcc_reportable().$promise.then (response) ->
        $scope.custodians = response
        $scope.fields.custodianIds = _.filter($scope.custodians, (i) ->
          i.dtcc_reporting_default
        ).map((fc) -> fc.id)

    init()
