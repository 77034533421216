angular.module('services').factory "UserAcceptedTerms", ($resource, $q) ->
  resource = $resource("/api/v1/user_accepted_terms",
    null
  ,
    acceptTerms:
      url: "/api/v1/user_accepted_terms/accept_terms"
      method: "POST"
      terms_id: @terms_id
  )

  acceptTerms = (terms_id) ->
    resource.acceptTerms(terms_id: terms_id).$promise

  acceptTerms: acceptTerms
