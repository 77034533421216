angular.module('controllers').controller 'addPrivateAccessFundAccessDialogCtrl', ($scope, $modal, $modalInstance, user, userAccessFunds, PrivateAccessFundResource) ->
  $scope.fund =
    id: undefined
    name: undefined
    limited: false
  $scope.headerText = "Add Private Access Fund Access"
  PrivateAccessFundResource.reverseInquiryFunds().$promise.then (response) ->
    fundsWithAccessIds = _.map(userAccessFunds, (fund) -> fund.id)
    $scope.accessFunds = _.filter(response.private_access_funds, (fund) -> !_.contains(fundsWithAccessIds, fund.id))

  $scope.addAccess = ->
    PrivateAccessFundResource.addAccessForUser({id: $scope.fund.id, user_id: user.id, limited: $scope.fund.limited}).$promise.then (response) ->
      fund = _.find($scope.accessFunds, (fund) -> fund.id == $scope.fund.id)
      fund.limited = $scope.fund.limited
      $modalInstance.close(fund)

  $scope.cancelDialog = ->
      $modalInstance.dismiss()

  $scope.invalidForm = ->
    !$scope.fund.id
