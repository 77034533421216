angular.module 'icn.paf_admin.funds'
.directive 'privateAccessFundAdminUploadDocument', (AdminFundDocument) ->
  restrict: 'E'
  templateUrl: 'areas/paf_admin/tabs/hf/funds/pages/funds/components/admin_fund_documents/admin_fund_documents.html'
  scope:
    fund: '='

  link: (scope) ->
    scope.fundDocument = {}
    scope.fundDocument.dateErrors = null
    scope.fundDocument.documentType = ''
    scope.sendUploadNotifications = false

    scope.uploadDocument = (file, type) ->
      AdminFundDocument.upload(scope.fund, file, scope.fundDocument.documentType, scope.fundDocument.reportDate, scope.sendUploadNotifications).then(
        (response) ->
          scope.uploadSuccess = true
          scope.uploadError = false
          scope.fundDocument.documentType = ''
          scope.fundDocument.reportDate = undefined
          scope.sendUploadNotifications = false
        (error) ->
          scope.uploadError = true
      )

    scope.selectType = ->
      scope.uploadSuccess = false
      scope.uploadError = false
      scope.fundDocument.reportDate = undefined
      scope.fundDocument.dateErrors = null

    scope.isOpenEndedFund = ->
      if scope.fund
        scope.fund.open_ended

    scope.showDatePicker = ->
      scope.fundDocument.documentType == 'MonthlyReport'

    scope.disableUploadButton = ->
      scope.fundDocument.documentType == '' || (scope.fundDocument.documentType == 'MonthlyReport' && (scope.fundDocument.reportDate == undefined || scope.fundDocument.dateErrors.hasErrors()))
