angular.module('services').factory "UserAccreditation",
  ($resource, $q, IcnIdentity) ->
    resource = $resource("/api/v1/users/:id/user_accreditations",
      id: "@id"
    ,
      query:
        method: "GET"
        isArray: false
        headers:
          "Butter-Bar": false

      autocomplete:
        method: "GET"
        url: "/api/v1/users/:id/user_accreditations/ria_autocomplete"
        q: "@query"
        isArray: true
        headers:
          "Butter-Bar": false

      options:
        method: "GET"
        url: "/api/v1/user_accreditations/options"
        isArray: false
        headers:
          "Butter-Bar": false
    )

    all = (id) ->
      resource.query(
        id: id
      ).$promise

    options = ->
      resource.options(
      ).$promise

    autocomplete = (id, query) ->
      resource.autocomplete(
        id: id
        query: query
      ).$promise

    save = (id, accreditation) ->
      dfd = $q.defer()
      resource.save(
        id: id
        user_accreditation: accreditation
      ).$promise.then (response) ->
# In case the User role changes after accreditation, check for a new default page
        if response.default_page
          IcnIdentity.user.default_page = response.default_page
        dfd.resolve response
      , (response) ->
        dfd.reject response
      dfd.promise

    {
      all: all
      options: options
      autocomplete: autocomplete
      save: save
    }
