angular.module('controllers').controller 'InvestorRelationsMassEmailsCtrl', (
$scope, $http, Dialog, PrivateAccessFunds, CKEditorService, InvestorRelationsMassEmail, $route, Notification) ->
  $scope.pafs = []
  $scope.ckeditorOptions = CKEditorService
  $scope.data = {}
  $scope.displayAllFundsButton = true
  $scope.totalFunds = 0
  $scope.fileButtonText = "Add file..."
  $scope.formDataIndex = 0
  $scope.indexObject = {}
  $scope.hasDraft = undefined
  $scope.sendGroup = undefined
  $scope.showDraftAttachments = false

  $scope.getTheFiles = ($files) ->
    angular.forEach($files, (value, key) ->
      if value.type != 'application/pdf'
        Notification.error 'File is not a PDF!'
        return

      if value.size > 2000000
        Notification.error 'PDF too large!'
        return

      Notification.success 'PDF attached'
      $scope.formData.append($scope.formDataIndex, value)
      $scope.indexObject[$scope.formDataIndex] = value
      $scope.formDataIndex++
    )

    $('#file').val('')
    return

  $scope.remove = (key) ->
    $scope.formData.delete(key)
    delete $scope.indexObject[key]

  $scope.restart = ($event) ->
    $route.reload()

  $scope.availableGroupOptions =
    [
      {id: 'all', name: 'All (Investors, IPs and RIAs)'},
      {id: 'investors_only', name: 'Only Investors'},
      {id: 'rias_investors', name: "RIAs, IPs and those Investors who don't have RIAs or IPs"}
    ]
  $scope.data.group = $scope.availableGroupOptions[0].id

  $scope.allFundsClick = ->
    $scope.selectedPafs.private_access_funds = $scope.pafs
    $scope.displayAllFundsButton = false

  $scope.clearFundsClick = ->
    $scope.selectedPafs.private_access_funds = []
    $scope.displayAllFundsButton = true

  $scope.fileUpload = ->
    document.querySelector('#file').click()

  $scope.saveDraft = ($event) ->
    if $scope.hasDraft
      Dialog.openNotifyDialog(
        "Save Draft",
        "You are about to save over an existing Draft. <br><br>Would you like to proceed?"
      ).result.then (data) ->
        $scope.submit($event, true)
    else
      $scope.submit($event, true)

  $scope.sendDialog = ($event) ->
    Dialog.openNotifyDialog('Send Mail', 'Are you sure you want to send the send the mail?')
    .result.then (data) ->
      $scope.submit($event, false)

  $scope.submit = ($event, draft) ->
    $scope.newMassEmail.private_access_fund_ids = []
    $scope.newMassEmail.draft = draft
    $scope.newMassEmail.notification_group = $scope.data.group

    angular.forEach($scope.selectedPafs.private_access_funds, (i) ->
      $scope.newMassEmail.private_access_fund_ids.push(i.id)
    )

    lastSaved = angular.copy($scope.newMassEmail)
    $scope.newMassEmail.$save().then (obj) ->
      if filesAttached()
        $scope.sendFile(obj.id)
      else
        $scope.notifySuccess()
    ,(e) ->
      $scope.success = ""
      $scope.error = "Email Sending Failed"

  filesAttached = ->
    $scope.formData.get 0

  $scope.notifySuccess = ->
    if $scope.newMassEmail.draft
      $scope.onDraftSuccess()
    else
      $scope.onSaveSuccess()

  $scope.sendFile = (irmeId) ->
    params =
      method: 'POST'
      url: "/api/v1/investor_relations_mass_emails/#{irmeId}/upload"
      data: $scope.formData
      headers:
        'Content-Type': undefined
    $http(params).success((res) ->
      $scope.notifySuccess()
    ).error (err) ->
      $scope.success = ""
      Notification "File upload failed"
      $scope.newMassEmail = lastSaved

  $scope.notify = ->
    InvestorRelationsMassEmail.notify($scope.newMassEmail, {notificationGroup: $scope.data.group})

  $scope.onSaveSuccess = ->
    $scope.notify() unless filesAttached()
    $scope.resetForm()
    $scope.error = ""
    $scope.success = "Emails Sent"

  $scope.onDraftSuccess = ->
    $scope.hasDraft = true
    $scope.draftMessage = "Draft saved"
    $scope.newMassEmail.id = undefined
    $scope.formData = new FormData()
    $('#file').val('')

  $scope.resetForm = ->
    $scope.newMassEmail = new InvestorRelationsMassEmail({})
    $scope.selectedPafs = {private_access_funds: []}
    $scope.formData = new FormData()
    $("#file").val('')

  $scope.loadDraft = ->
    $scope.indexObject = {}
    $scope.checkDraft()
    $scope.populateFields()

  $scope.populateFields = ->
    $scope.selectedPafs.private_access_funds = $scope.draftPafs
    $scope.newMassEmail.subject = $scope.draftSubject
    $scope.newMassEmail.body = $scope.draftBody
    $scope.showDraftAttachments = true
    $scope.data.group = $scope.sendGroup

  PrivateAccessFunds.getPrivateAccessFundsForInvestorRelations().then((pafs) ->
    $scope.pafs = pafs
    $scope.totalFunds = pafs.length
    $scope.checkDraft()
  )

  filterPafs = (pafList) -> _.filter $scope.pafs, (elem) -> _.contains pafList.pafs, elem.id.toString()

  $scope.checkDraft = ->
    InvestorRelationsMassEmail.checkDraft().$promise.then (response) ->
      pafs = filterPafs(response)
      $scope.hasDraft = response.draft
      if response.draft
        $scope.draftBody = response.body
        $scope.draftPafs = pafs
        $scope.draftSubject = response.subject
        $scope.attachments = response.attachments
        $scope.investorRelationsMassEmailId = response.id
        $scope.sendGroup = response.notification_group

  $scope.removeSavedAttachment = ($event, attachment) ->
    InvestorRelationsMassEmail
    .deleteDraftAttachment('', {generic_file_id: attachment.id, id: $scope.investorRelationsMassEmailId})
    .$promise.then ->
      angular.element($event.currentTarget).parent().remove()

  $scope.resetForm()
