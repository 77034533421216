angular.module('services').factory 'ParamsService', ($location) ->

  splitQpsPafParam = () ->
    params = $location.search()
    qps_paf = params.qps_paf

    if params.qps_paf isnt undefined
      limited = params.qps_paf[0].toLowerCase() == 'l'
      qps_paf =
        paf_id: if limited then params.qps_paf.substring(1, params.qps_paf.length) else params.qps_paf
        limited: limited

    qps_paf

  return {
    splitQpsPafParam: splitQpsPafParam
  }
