angular.module('directives').directive 'compileTemplate', ($compile, $parse) ->
  link: (scope, element, attr) ->
    parsed = $parse(attr.ngBindHtml)
    #Recompile if the template changes

    getStringValue = ->
      (parsed(scope) or '').toString()

    scope.$watch getStringValue, ->
      $compile(element, null, -9999) scope
      #The -9999 makes it skip directives so that we do not recompile ourselves
      return
    return
