angular.module('services').factory "ShareClassSvc", ($resource, $upload) ->
  resource = $resource "/api/v1/share_classes/:id", { id: "@id" },
    create:
      url: '/api/v1/share_classes'
      method: 'POST'

    update:
      url: '/api/v1/share_classes/:id'
      method: 'PATCH'

    remove:
      url: '/api/v1/share_classes/:id'
      method: 'DELETE'

    forPaf:
      url: "api/v1/share_classes/for_paf"
      methos: 'GET'

    show:
      url: '/api/v1/share_classes/:id'
      method: 'GET'

    templateFile:
      url: '/api/v1/share_classes/bulk_upload_template'
      method: 'GET'
      transformResponse: (data, headers) ->
        name = headers()['file-name']
        if data
          file = new Blob([data],
            type: 'text/csv'
          )
          return file: file, name: name

  create = (params) ->
    resource.create(params)

  update = (params) ->
    resource.update(params)

  remove = (id) ->
    resource.remove(id: id)

  forPaf = (params) ->
    resource.forPaf(params)

  show = (id) ->
    resource.show(id: id)

  bulkUploadTemplate = ->
    resource.templateFile()

  bulkUpload = (files) ->
    params = {
      url: '/api/v1/share_classes/bulk_upload'
      method: 'POST',
      file: files[0]
    }
    $upload.upload(params)

  {
    create: create
    update: update
    remove: remove
    forPaf: forPaf
    show: show
    bulkUploadTemplate: bulkUploadTemplate
    bulkUpload: bulkUpload
  }
