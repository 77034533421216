angular.module('services').factory "RiaImports", ($resource, $upload, $q) ->
  RiaImports =
    $resource("api/v1/ria_imports/:id",
      {},
      query:
        method: "GET"
        isArray: false)

  RiaImports::getAll = () ->
    RiaImports.query()

  RiaImports::save = (files) ->
    $upload.upload(
      method: 'POST',
      url: "/api/v1/ria_imports",
      file: files[0]
    ).error (errors) ->
      $q.reject(errors)

  return new RiaImports
