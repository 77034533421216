angular.module('directives').directive "icnInput", ($interval, IcnUtility) ->
  restrict: "E"
  require: "^form"
  scope:
    name: "@"
    id: "@"
    type: "@"
    label: "@"
    inputclass: "@"
    matchPattern: "@"
    blur: "&"
    disableAutofill: "@"
    directives: "@"
    checkbox: "@"
    model: "="

  templateUrl: "templates/directives/icn_input.html"
  compile: (el, attrs) ->
    if attrs.directives
      inputField = el[0].querySelector("input")
      directives = attrs.directives.split(" ")
      directives.forEach (dir) ->
        inputField.setAttribute(dir, "")

    post: (scope, el, attrs, formCtrl) ->
      scope.name = scope.name || scope.type
      scope.customId = scope.id || scope.name
      label = scope.label || scope.name
      scope.label = label.charAt(0).toUpperCase() + label.slice(1)
      scope.focused = false
      scope.inputEmpty = true
      scope.required = attrs.hasOwnProperty("required")
      scope.disabled = attrs.fieldDisabled == "true"
      scope.matchPattern = new RegExp(scope.matchPattern)
      scope.changeFocus = (focused) ->
        scope.focused = focused
        scope.blur() unless focused || !scope.blur

      scope.updateInput = (event) ->
        scope.inputEmpty = event.currentTarget.value.length <= 0

      if IcnUtility.isChrome()
        clearAutofilledContent = (el) ->
          $el = $(el)
          $el.val(" ")
          $el.val("")
          $el.change()
          scope.focused = false
          scope.inputEmpty = true
          scope.model = ""

        cancelInterval = ->
          $interval.cancel(checkAutofill)

        fixAutofill = (el) ->
          if scope.disableAutofill == "true"
            clearAutofilledContent(el)
          else
            $(el).focus()

          formCtrl.$setUntouched()

        checkAutofill = $interval ->
          if scope.focused
            cancelInterval()
          else
            autofilledEl = el[0].querySelector("input:-webkit-autofill")

            if autofilledEl != null
              fixAutofill(autofilledEl)
        , 100
