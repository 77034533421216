angular.module('services').factory "InvestmentShareClassService",
  ($resource) ->
    InvestmentShareClassService = ->
      @service = $resource("/api/v1/investment_share_classes", null,
        available:
          method: "GET"
          url: "/api/v1/investment_share_classes/available"
          isArray: true
        availableForLimitCode:
          method: "GET"
          url: "/api/v1/investment_share_classes/for_limit_code"
          isArray: true
      )
      return

    InvestmentShareClassService::available = (privateAccessFundId, whiteLabelPartnerId, firmId) ->
      @service.available(
        private_access_fund_id: privateAccessFundId,
        white_label_partner_id: whiteLabelPartnerId,
        firm_id: firmId
      ).$promise

    InvestmentShareClassService::availableForLimitCode =
      (privateAccessFundId, whiteLabelPartnerId, firmId, limitCode) ->
        @service.availableForLimitCode(
          private_access_fund_id: privateAccessFundId,
          white_label_partner_id: whiteLabelPartnerId,
          firm_id: firmId,
          limit_code: limitCode
        ).$promise

    return new InvestmentShareClassService()
