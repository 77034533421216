angular.module('services').factory "FirmAttestationOverrideService", ($resource, $q) ->
  resource = $resource("/api/v1/firm_attestation_overrides",
  {},
  attestationRequired:
    method: 'GET',
    url: '/api/v1/firm_attestation_overrides/attestation_required',
  )

  attestationRequired = (params) ->
    resource.attestationRequired(params).$promise

  attestationRequired: attestationRequired
