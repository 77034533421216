angular.module('controllers').controller "DownloadAcceptedSubdocsDialogCtrl",
  ($scope, $modalInstance, PafAdminSvcApi, privateAccessFund, investments) ->

    $scope.cancelDialog = ->
      $modalInstance.dismiss()

    $scope.downloadAcceptedSubdocs = ->
      $scope.formData.valueDateYear = null if $scope.formData.allAcceptedSubdocs
      $scope.formData.valueDateMonth = null if $scope.formData.allAcceptedSubdocs
      PafAdminSvcApi.getAcceptedInvestmentsSubdocs(privateAccessFund.id, $scope.formData.valueDateYear, $scope.formData.valueDateMonth)
      $modalInstance.close()

    valueDateYears = ->
      accepted = _.filter(investments, (investment) -> investment.accepted == true)
      _.uniq(_.map(accepted, (investment) -> investment.value_date_for_client_management_display.slice(0, 4)).sort(), true)

    init = ->
      $scope.formData = {}
      $scope.valueDateYearsAvailable = valueDateYears()

    init()
