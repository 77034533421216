angular.module('controllers').controller "InvestorProfileSaveDialogCtrl",
  ($scope, $modal, $modalInstance) ->
    $scope.disabled = false
    $scope.profileType = 'current'
    $scope.batchUpdateOptions =
      live: false
      frozen: false

    $scope.isDisabled = (profileType) ->
      if profileType == 'current'
        false
      else if profileType == 'other' && ($scope.batchUpdateOptions.live || $scope.batchUpdateOptions.frozen)
        false
      else
        true

    $scope.clearBatchUpdateOptions = ->
      $scope.batchUpdateOptions =
        live: false
        frozen: false

    $scope.sendUpdateOptions = ->
      $modalInstance.close($scope.batchUpdateOptions)
