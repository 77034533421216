// Generated by CoffeeScript 2.5.1
(() => {
  angular.module('directives').directive('comments', () => {
    return {
      templateUrl: 'areas/aml_approval/components/comments/comments.html',
      restrict: 'E',
      scope: {
        approval: '='
      },
      controller: ($scope, $location, $routeParams, $modal, IcnIdentity, ApprovalNotesService) => {
        var deleteComment, errorMessage, getComments, hideAcknowledgeBox, init;
        $scope.confirmDelete = (id) => {
          const opts = {
            templateUrl: "dialogs/custom_confirm_dialog/custom_confirm.html",
            controller: "customConfirmDialogCtrl",
            windowClass: "small-modal",
            resolve: {
              header: () => 'Comments',
              msg: () => 'Are you sure you want to delete it?',
              confirm_label: () => 'Ok',
              cancel_label: () => 'Cancel',
            }
          };
          return $modal.open(opts).result.then(() => {
            deleteComment(id);
          });
        };
        $scope.doNotScroll = false;
        $scope.canAcknowledgeComment = IcnIdentity.isPafAdmin() || IcnIdentity.isDocumentReviewer() || IcnIdentity.isExternalApprover() || IcnIdentity.isHomeOffice() || IcnIdentity.isBranchManager();
        errorMessage = () => {
          $scope.$parent.errorMessage.message = 'Note body cannot be empty';
          $scope.$parent.errorMessage.approval = $scope.approval;
        };
        $scope.acknowledgeComment = (comment) => {
          comment.acknowledged = true;
          const params = {
            id: comment.id,
            note: comment
          };
          return ApprovalNotesService.update(params.id, params).$promise.then((res) => {
            comment.acknowledged = res.approval_note.acknowledged;
            comment.new_remediation_comment = res.approval_note.new_remediation_comment;
          });
        };
        hideAcknowledgeBox = (comment) => (
          comment.created_by.id === IcnIdentity.user.id || (!IcnIdentity.isWhiteLabel() && comment.created_by.white_label_partner_id === 0 && comment.created_by.paf_admin)
        );
        $scope.newRemediationComment = (comment) => {
          return !hideAcknowledgeBox(comment) && comment.new_remediation_comment && $scope.canAcknowledgeComment;
        };
        $scope.resetNewComment = () => {
          $scope.newComment = {
            new: false,
            body: '',
            noteable_type: 'RequiredDocumentApproval'
          };
        };

        $scope.fileKeptReplaced = (comment) => (
          comment && comment.subject === 'Original file kept was replaced'
        );

        $scope.showCommentSubject = (comment) => (
          comment && (
            comment.subject === 'Original file kept' ||
            comment.subject === 'Original Investor Profile kept' ||
            comment.subject === 'Original Supplemental Form kept' ||
            comment.subject === 'Original Commitment Amount kept' ||
            comment.subject === 'Original Subscription Amount kept'
          )
        );

        $scope.canEditComment = (comment) => (
          !comment.editMode && $scope.isAuthorLastComment(comment) &&
          !$scope.fileKeptReplaced(comment) && IcnIdentity.canEditSubReviewNotes()
        )

        $scope.scrollToLatestComment = () => {
          let objDiv = document.getElementById(`approval-comments-${$scope.approval.id}`);
          if ($scope.doNotScroll || !objDiv) {
            return;
          }
          objDiv.scrollTop = objDiv.scrollHeight;
        };
        $scope.isAuthorLastComment = (comment) => {
          return $scope.approval.comments[$scope.approval.comments.length - 1].id === comment.id && comment.created_by.id === IcnIdentity.user.id && comment.author_last_comment;
        };
        getComments = () => {
          const params = {
            required_document_approval_id: $scope.approval.id
          };
          return ApprovalNotesService.query(params).$promise.then((res) => {
            $scope.approval.comments = res.filter((n) => {
              return n.noteable_type === 'RequiredDocumentApproval';
            });
            return _.map($scope.approval.comments, (comment) => {
              if (comment.id === $scope.approval.comments[$scope.approval.comments.length - 1].id) {
                return;
              }
              return comment.new_remediation_comment = false;
            });
          });
        };
        deleteComment = (id) => {
          const params = {
            id: id
          };
          return ApprovalNotesService.delete(params).$promise.then(() => {
            $scope.approval.comments = $scope.approval.comments.filter((n) => {
              return n.id !== id;
            });
            return $scope.approval.comments[$scope.approval.comments.length - 1].author_last_comment = true;
          });
        };
        $scope.showCommentUpdatedAt = (comment) => {
          const updated_at = new Date(comment.updated_at).setMinutes(0, 0, 0);
          const created_at = new Date(comment.created_at).setMinutes(0, 0, 0);
          return comment.updated_at && (updated_at !== created_at);
        };
        $scope.toggleEditMode = (comment) => {
          $scope.doNotScroll = true;
          comment.editMode = !comment.editMode;
        };
        $scope.addNewComment = () => {
          $scope.doNotScroll = false;
          $scope.newComment.new = true;
        };
        $scope.createComment = () => {
          if (!$scope.newComment.body) {
            return errorMessage();
          }
          $scope.newComment.subject = $scope.approval.document_type;
          $scope.newComment.noteable_id = $scope.approval.id;
          const params = {
            required_document_approval_id: $scope.approval.id,
            note: $scope.newComment
          };
          return ApprovalNotesService.save(params).$promise.then((res) => {
            return getComments().then(() => {
              $scope.resetNewComment();
              return $scope.scrollToLatestComment();
            });
          }, (error) => {
            return $scope.errorMsg = error.data.message;
          });
        };
        $scope.updateComment = (comment) => {
          if (!comment.body) {
            return errorMessage();
          }
          const params = {
            id: comment.id,
            note: comment
          };
          return ApprovalNotesService.update(params.id, params).$promise.then((res) => {
            comment.updated_at = res.approval_note.updated_at;
            comment.updated_by = res.approval_note.updated_by;
            return comment.editMode = false;
          }, (error) => {
            return $scope.errorMsg = error.data.message;
          });
        };
        $scope.$watch('approval', () => {
          return getComments();
        });
        init = () => {
          $scope.resetNewComment();
          return getComments();
        };
        return init();
      }
    };
  });

}).call(this);
