angular.module('services').factory 'NetFeeAgreementService', (IcnUtility) ->
  formatToNumber = (commitment) ->
    return commitment if _.isNumber(commitment)
    commitment = IcnUtility.unformatCurrency(commitment)
    if commitment == '' then 0 else parseInt(commitment)

  revertNetFee = (commitment, placementFee) ->
    commitment / (1 - (placementFee / 100))

  isNetFeeAgreement: (investment) ->
    return false if investment.sub_doc_type_name == 'advisory_individual'
    investment.firm_fee_agreement && investment.firm_fee_agreement.placement_fee_type == 'net'

  applyNetFee: (commitment, placementFee, oldPlacementFee, resetNetCommitment = false) ->
    if commitment
      grossCommitment = formatToNumber(commitment)
      if oldPlacementFee && !resetNetCommitment
        grossCommitment = revertNetFee(commitment, oldPlacementFee) # commitment / (1 - (oldPlacementFee / 100))
      return Math.round(grossCommitment * (1 - (placementFee / 100)))
    commitment

  revertNetFee: revertNetFee
