angular.module 'icn.paf_admin'
  .controller 'RedemptionsApprovalsCtrl',
    ($scope, $location, $routeParams, PafAdminSvcApi, Redemptions, $modal, IcnIdentity) ->
      init = ->
        $scope.notification = null
        $scope.orderByField = 'value_date'
        $scope.reverseSort = true
        $scope.redemptions = $scope.selectedRedemptions = []
        $scope.fundType = $routeParams.fund_type
        if $scope.fundType == 'hf'
          $scope.getHedgeFunds()
        else
          $scope.getEquityFunds()
        $scope.selectedPafId = $routeParams.private_access_fund_id
        $scope.enableRedemptions = IcnIdentity.enableRedemptions()
        $scope.getRedemptions() if $scope.selectedPafId && $scope.enableRedemptions

      $scope.getRedemptions = ->
        Redemptions.getAllForApprovals(pafId: $scope.selectedPafId).then (res) ->
          $scope.redemptions = res.redemptions

      $scope.getHedgeFunds = ->
        PafAdminSvcApi.hedgeFunds()
        .then (funds) ->
          $scope.funds = funds
          $scope.fund = _.find(funds, (fund) ->
            parseInt(fund.id) == parseInt($routeParams.private_access_fund_id)
          )
        return

      $scope.getEquityFunds = ->
        PafAdminSvcApi.getPrivateEquityFunds()
        .then (funds) ->
          $scope.funds = funds
          $scope.fund = _.find(funds, (fund) ->
            parseInt(fund.id) == parseInt($routeParams.private_access_fund_id)
          )
        return

      $scope.refreshList = ->
        $location.path("/paf_approver/#{$scope.fundType}/redemptions/#{$scope.fund.id}")

      $scope.orderBy = (field) ->
        $scope.reverseSort = if field == $scope.orderByField then !$scope.reverseSort else true
        $scope.orderByField = field

      $scope.approve = (redemption) ->
        Redemptions.approve($scope.selectedPafId, redemption)
        .then(->
          init()
        , (response) ->
          $scope.notification = response.data)

      $scope.reject = (redemption) ->
        $modal.open(
          templateUrl: dialogPath("set_rejection_reason/set_rejection_reason.html")
          controller: "SetRejectionReasonCtrl"
          windowClass: "small-modal"
          resolve:
            redemption: -> redemption
            pafId: -> $scope.selectedPafId
        ).result.then(->
          init()
        , (error) ->
          $scope.notification = error if error?.text
        )

      dialogPath = (relativePart) ->
        "areas/paf_admin/tabs/common/redemptions/pages/approvals/dialogs/" + relativePart

      init()
