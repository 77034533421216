angular.module("icn.paf_admin")
.factory "TaxReportService", ($resource, $http, $q) ->
  TaxReportService =
    $resource("/api/v1/fund_admin_reporting", {},
      exportTaxReport:
        url: "/api/v1/fund_admin_reporting/export_tax_report"
        method: "POST"
    )

  TaxReportService::export = (year, openEnded, filename, reportType) ->
    TaxReportService.exportTaxReport({
      year: year
      open_ended: openEnded
      filename: filename
      report_type: if reportType == 'Transfers' then 'transfersTaxReport' else 'positionTaxReport'
    }).$promise

  return new TaxReportService()
