angular.module('services').factory "Menu", ($resource) ->
  resource = $resource("/api/v1/menu",{},
    cache: true

    fund_administration_menu:
      url: "/api/v1/menu/fund_administration_menu"
      method: "GET"
      isArray: true
  )

  fund_administration_menu = (viewType) ->
    resource.fund_administration_menu(view_type: viewType).$promise

  return {
    fund_administration_menu: fund_administration_menu
  }
