angular.module('controllers').controller "PickSignersDialogCtrl",
  (
    $scope, $rootScope, $modal, $modalInstance, ModalDialogInstancesObserver, investment,
    configs, IcnIdentity
  ) ->
    $scope.headerText = "Pick and Sequence Signers"
    $scope.hasSubtitle = !!configs?.subtitle
    $scope.subtitle = configs?.subtitle
    $scope.hasNote1 = !!configs?.note1
    $scope.note1 = configs?.note1
    $scope.hasNote2 = !!configs?.note2
    $scope.note2 = configs?.note2
    $scope.hasNext = configs?.next

    $scope.investment = investment
    $scope.investorProfile = $scope.investment.investor_profile
    $scope.availableSigners = $scope.investment.available_signers

    getSignerByRecipient = (recipient) -> 
      $scope.availableSigners.find (x) -> x.contact_card_id is recipient?.id

    $scope.firstSigner = getSignerByRecipient($scope.investment.first_recipient)

    if $scope.investment.existing_signers_record
      $scope.secondSigner = getSignerByRecipient($scope.investment.extra_recipient)
      $scope.thirdSigner = getSignerByRecipient($scope.investment.extra_third_recipient)
    else
      $scope.secondSigner = undefined
      $scope.thirdSigner = undefined
  
    isTypeOfAccountToBlock =
      $scope.investorProfile.section_a_individual_type &&
      ['IRA', 'SEP IRA', 'Individual'].includes($scope.investorProfile.section_a_individual_type)

    $scope.maxSigners = if isTypeOfAccountToBlock then 2 else 3

    $scope.showSecondSigner = $scope.availableSigners.length >= 2
    $scope.showThirdSigner = $scope.availableSigners.length >= 3

    # dismiss modal dialog box when navigate to other page
    ModalDialogInstancesObserver.setModalDialogInstance($modalInstance)

    $scope.cancelDialog = -> $modalInstance.dismiss()

    $scope.updateSigner = (signer, numberSigner) ->
      switch numberSigner
        when 'first'
          $scope.firstSigner = signer
        when 'second'
          $scope.secondSigner = signer
        when 'third'
          $scope.thirdSigner = signer

    $scope.clearSigners = ->
      $scope.firstSigner = undefined
      $scope.secondSigner = undefined
      $scope.thirdSigner = undefined

    $scope.allowSecondSigner = ->
      !$scope.showSecondSigner && $scope.availableSigners.length >= 2

    $scope.allowThirdSigner = ->
      $scope.showSecondSigner && $scope.maxSigners == 3 &&
      !$scope.showThirdSigner && $scope.availableSigners.length >= 3

    $scope.blockDeleteSigners = ->
      $scope.showThirdSigner || isJointAccount()

    $scope.showClearSigners = ->
      $scope.showSecondSigner && !$scope.showThirdSigner

    isJointAccount = ->
      $scope.investorProfile.individual_joint_account

    selectedSignersLength = ->
      _.countBy(
        [
          $scope.firstSigner,
          $scope.secondSigner,
          $scope.thirdSigner
        ], (signer) -> !!signer
      )['true']

    validateJointAccountSelectedSigners = (callback) ->
      return callback(true) unless $scope.investorProfile.ubs_wlp

      if isJointAccount() && selectedSignersLength() < 2
        return openJointAccountWarningDialog().finally -> callback(false)

      callback(true)

    openJointAccountWarningDialog = ->
      $modal.open(
        templateUrl: 'dialogs/custom_notify_dialog/custom_notify_dialog.html'
        controller: 'customNotifyDialogCtrl'
        windowClass: 'small-modal'
        resolve:
          header: -> 'Joint Account Warning'
          msg: -> 'You must select two signers for a joint account.'
          btn_label: -> 'Close'
      ).result

    $scope.addSigner = ->
      if !$scope.showSecondSigner
        $scope.showSecondSigner = true
      else if !$scope.showThirdSigner
        $scope.showThirdSigner = true

    $scope.deleteSigner = ->
      if $scope.showThirdSigner
        $scope.showThirdSigner = false
        $scope.thirdSigner = null
      else if $scope.showSecondSigner
        $scope.showSecondSigner = false
        $scope.secondSigner = null

    $scope.allowSubmit = ->
      return true unless $scope.showSecondSigner

      twoSigners = $scope.firstSigner && $scope.secondSigner
      if $scope.showThirdSigner then twoSigners && $scope.thirdSigner else twoSigners

    $scope.saveSigners = ->
      validateJointAccountSelectedSigners(
        (valid) ->
          return unless valid

          firstSignerId = $scope.firstSigner.id
          secondSignerId = $scope.secondSigner?.id
          thirdSignerId = $scope.thirdSigner?.id
          $modalInstance.close([firstSignerId, secondSignerId, thirdSignerId])
      )
