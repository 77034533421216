angular.module('controllers').controller "InlineExportDocsDialog",
  ($scope, $location, $modalInstance, $modal, $window, $http, selectedPaf, header,
  SubscriptionReviewExportDocsService, investment) ->

    $scope.selectedPaf = selectedPaf

    $scope.header = (if (angular.isDefined(header)) then header else "")

    $scope.investment = investment

    init = ->
      SubscriptionReviewExportDocsService.index($scope.selectedPaf.id, $scope.investment.id).then (res) ->
        $scope.generatedZips = res.fund_exported_files

    $scope.docTypes = [
      {
        id: 'all_documents'
        label: 'All'
        selected: true
      }
      {
        id: 'entity_formation'
        label: 'Entity Formation'
        selected: false
      }
      {
        id: 'fatca'
        label: 'FATCA'
        selected: false
      }
      {
        id: 'identity'
        label: 'Identity'
        selected: false
      }
      {
        id: 'subscription'
        label: 'Subscription'
        selected: false
      }
      {
        id: 'tax'
        label: 'Tax'
        selected: false
      }
    ]

    checkSelectedOptions = (options) ->
      options.some (option) -> option.selected

    selectedOptions = (options) ->
      selected = []
      return selected if (_.find options, (option) -> option.id == 'all_documents').selected

      _.each(options, (option) ->
        selected = selected.concat(option.id) if option.selected
      )
      selected

    $scope.generateSelectedAMLDocuments = ->
      SubscriptionReviewExportDocsService.create(
        $scope.selectedPaf.id,
        selectedOptions($scope.docTypes),
        investment_id: $scope.investment.id
      ).then ->
        $modal.open(
          templateUrl: "dialogs/custom_notify_dialog/custom_notify_dialog.html"
          controller: "customNotifyDialogCtrl"
          windowClass: "small-modal"
          resolve:
            header: ->
              "Your zipfile is being generated..."

            msg: ->
              "You will receive an email when your zipfile is ready. Alternatively, you can refresh this page."

            btn_label: -> "Close"
        )

    $scope.disableGenerateButton = ->
      !checkSelectedOptions($scope.docTypes)

    $scope.cancelDialog = ->
      $modalInstance.dismiss()

    init()
