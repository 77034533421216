angular.module('directives').directive "backToTop", ->
  restrict: "AE"
  replace: true
  transclude: true
  template: '<div id="icn-back-to-top">
                <div class="to-top-img-wrapper">
                  <img class="to-top-img" src="images/back_to_top.svg"/>
                </div>
             </div>'
  link: (scope, elem, attrs) ->
    elem.find('.to-top-img').click ->
      $('html, body').animate { scrollTop: 0 }, 1000

    $(window).scroll ->
      if $(this).scrollTop() > 800
        $('#icn-back-to-top').fadeIn()
      else
        $('#icn-back-to-top').fadeOut()

    ua = (navigator.userAgent || navigator.vendor || window.opera)
    ismob = (/Android|iPhone|iPad|iPod/i).test(ua) && !(/Firefox/i).test(ua)

    backToTopResizeFunction = (e) ->
      adjustBackToTopBtn = ->
        feedbackBtnHeight = $('#us_report_button').height()

        if (90 == Math.abs(window.orientation))
          zoomRatio = window.screen.height  / window.innerWidth
          widthAndHeight = 50
        else
          zoomRatio = window.screen.width / window.innerWidth
          widthAndHeight = 33

#        if $('.to-top-img-wrapper').attr('class').includes('container')
#          $('.to-top-img-wrapper').removeClass('container')

        iconZoomRatio = (1.387 / zoomRatio)
        $('.to-top-img').css( 'width', iconZoomRatio * widthAndHeight )
                        .css( 'height', iconZoomRatio * widthAndHeight)
                        .css( 'right', iconZoomRatio * 5)
        $('#icn-back-to-top').css('bottom', feedbackBtnHeight + iconZoomRatio * 8 + iconZoomRatio * widthAndHeight)

      setTimeout(adjustBackToTopBtn, 10)

    if ismob
      backToTopResizeFunction()
      $(window).off('scroll', backToTopResizeFunction)
      $(window).on('scroll', backToTopResizeFunction)

