angular.module('controllers').controller 'ResetPasswordCtrl', ($scope, Session, $routeParams, $window, AccountUtilities) ->
  $scope.lessthanIE9 = -> $window.is_ie_lt9

  $scope.user = {}
  $scope.serverError = {}
  $scope.confirmPasswordFieldHasError = false
  $scope.user.isPasswordInvalid = false
  $scope.resetPassword = ->
    $scope.oldPassword = $scope.user.password
    Session.resetPassword($scope.user.password,
                          $scope.user.password_confirmation,
                          $routeParams.reset_password_token).then((response) ->
      $routeParams.reset_password_token = null
      $window.location.href = "/login"
    , (error) ->
      if error.data.validations && _.keys(error.data.validations).length > 0
        $scope.serverError = error.data.validations
      else if AccountUtilities.invalidPasswordBreach(error.data.message) || AccountUtilities.invalidPasswordWords(error.data.message)
        $scope.user.isPasswordInvalid = true
      else
        $scope.serverError.unknownError = true
        $scope.unknownErrorMessage = error.data.message
    )

  $scope.passwordHasError = (password) ->
    if password != undefined && $scope.oldPassword != password.$viewValue && $scope.user.isPasswordInvalid
      $scope.user.isPasswordInvalid = false
    AccountUtilities.passwordHasError(password) || $scope.user.isPasswordInvalid

  $scope.confirmedPasswordHasError = (password, password_confirmation) ->
    $scope.confirmPasswordFieldHasError = AccountUtilities.confirmedPasswordHasError(password, password_confirmation)

  $scope.allCompleted = ->
    AccountUtilities.passwordRequirementsAllCompleted($scope.user.password, $scope.user.password_confirmation) && !$scope.user.isPasswordInvalid
