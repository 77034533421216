angular.module('services').factory "InvestorQualificationService", ($http) ->
  getInvestorQualificationQuestions = ->
    $http({
      url: "/api/v1/user_investor_qualification_standard_questions",
      method: "GET",
      transformResponse: (response) ->
        return response
    })

  { getInvestorQualificationQuestions: getInvestorQualificationQuestions }
