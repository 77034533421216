angular.module('services').factory "PagingService", [
  () ->
    class Pager
      _config = {}
      _movePage = (dir, pagerName) ->
        _config[pagerName].currentPage = _config[pagerName].currentPage + dir

      constructor: ( config = {} ) ->
        pagerName = config.pagerName
        config.currentPage = 0 unless config.currentPage
        config.pageSize = 10 unless config.pageSize
        config.itemCount = 0 unless config.itemCount
        _config[pagerName] = config

      reset: (pagerName) ->
        _config[pagerName].currentPage = 0
        _config[pagerName].itemCount = 0

      currentPage: (n, pagerName) ->
        _config[pagerName].currentPage = n

      getCurrentPage: (pagerName) ->
        _config[pagerName].currentPage

      pageSize: (pagerName) ->
        _config[pagerName].pageSize

      nextPage: (pagerName) ->
        _movePage 1, pagerName

      previousPage: (pagerName) ->
        _movePage -1, pagerName

      pageCount : (pagerName) ->
        Math.ceil(_config[pagerName].itemCount / _config[pagerName].pageSize)

      itemCount : (n, pagerName) ->
        _config[pagerName].itemCount = n

      isFirstPage : (pagerName) ->
        _config[pagerName].currentPage == 0

      isLastPage : (pagerName) ->
        _config[pagerName].currentPage == @pageCount(pagerName) - 1

      startingIndexOnCurrentPage: (pagerName) ->
        _config[pagerName].currentPage * _config[pagerName].pageSize + 1

      endingIndexOnCurrentPage: (pagerName) ->
        endIndex = (_config[pagerName].currentPage + 1) * _config[pagerName].pageSize
        return _config[pagerName].itemCount if endIndex > _config[pagerName].itemCount
        endIndex

    create = (settings) ->
      new Pager(settings || {})

    return (
      create: create
    )

]
