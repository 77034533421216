angular.module('controllers').controller("customNotifyDialogCtrl",
  ($scope, $modalInstance, header, msg, btn_label, custom_body_class) ->

    $scope.header = (if (angular.isDefined(header)) then header else "Notification")
    $scope.msg = (if (angular.isDefined(msg)) then msg else "Unknown application notification.")
    $scope.btn_label = (if (angular.isDefined(btn_label)) then btn_label else "Close")
    $scope.custom_body_class = custom_body_class || ''

    $scope.close = ->
      $modalInstance.close()
      $scope.$destroy()
  ).value('custom_body_class', null)
