angular.module('services').factory "IcnGeoCountriesService", ($resource, $q) ->
  IcnGeoCountriesService = ->
    @service = $resource("/api/v1/icn_geo_countries",
      null
    ,
      forInvestorProfile:
        method: "GET"
        url: "/api/v1/icn_geo_countries/for_investor_profile"
        isArray: true

      forPrivateAccessFund:
        method: "GET"
        url: "/api/v1/icn_geo_countries/for_private_access_fund"
    )
    return

  IcnGeoCountriesService::getAllCountries = ->
    @service.query()

  IcnGeoCountriesService::getViewableCountries = ->
    @service.query(for_user: true)

  IcnGeoCountriesService::getNotRestrictedViewableCountries = ->
    @service.query(not_restricted: true)

  IcnGeoCountriesService::forInvestorProfile = (ip_id, batchupdate) ->
    @service.forInvestorProfile(investor_profile_id: ip_id, apply_to_live: batchupdate)

  IcnGeoCountriesService::forPrivateAccessFund = (paf_id, for_individual) ->
    @service.forPrivateAccessFund(private_access_fund_id: paf_id, for_individual: for_individual)

  return new IcnGeoCountriesService()
