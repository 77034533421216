angular.module('directives').directive "icnRoadshowChapterDetail", ->
  restrict: "E"
  templateUrl: "templates/roadshows/roadshow_chapter_detail.html"
  scope:
    chapter: "="
    fund: "="
  require: "^icnRoadshow"
  link: (scope, element, attrs, icnRoadshowController) ->
    scope.close = ->
      icnRoadshowController.showChapterList collapsed: false
