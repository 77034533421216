angular.module('directives').directive 'maskMoney', ->
  restrict: 'A'
  scope:
    ngModel: "="
    precision: '@'
    prefix: '@'
  link: (scope, elem, attrs) ->
    scope.precision ?= 0
    scope.prefix ?= ''
    elem.maskMoney({precision: parseInt(scope.precision), prefix: scope.prefix})
    scope.$watch "ngModel", (oldValue, newValue) ->
      elem.maskMoney("mask")
