angular.module('controllers').controller "InvestmentEventsDialogCtrl",
  ($scope, $modal, $modalInstance, $filter, ModalDialogInstancesObserver, InvestmentStatusLabels,
  InvestmentReviewStatusService, IcnIdentity, events, type, CurrencySelector, selectedPaf) ->
    $scope.whiteLabelPartner = IcnIdentity.whiteLabelPartner()
    $scope.events = events
    $scope.type = type
    $scope.selectedPaf = selectedPaf
    $scope.currency = CurrencySelector.fromPaf($scope.selectedPaf)
    $scope.cancelDialog = -> $modalInstance.dismiss()
    redemptionStatusLabel = (status) -> status.charAt(0).toUpperCase() + status.slice(1)

    $scope.hasEvents = -> $scope.events.length > 0

    InvestmentStatusLabels.wlpPafLabels({
      paf_id: $scope.selectedPaf.id,
      wlp_id: $scope.whiteLabelPartner.id
    }).then (labels) ->
      $scope.wlpPafLabels = labels.data

    $scope.$watch "wlpPafLabels", ->
      $scope.statusLabel = InvestmentReviewStatusService.statusLabel(IcnIdentity, $scope, "selectedPaf")

    $scope.getEventType = (event) ->
      event_type = switch event.type_slug.trim()
        when 'investment_created'
          'Offering Materials Sent to Signers'
        when 'commitment_amount_updated'
          "Commitment Amount updated " +
          "from #{$scope.currency}  #{$filter('number')(event.metadata.commitment_before, 0)} " +
          "to #{$scope.currency} #{$filter('number')(event.metadata.commitment_after, 0)}"
        when 'investment_moved'
          "Investment Moved to #{$scope.statusLabel(event.metadata.approval_status)}"
        when 'redemption_status_updated'
          "Redemption was #{redemptionStatusLabel(event.metadata.status)}"
        else event.type
      event_type

    # dismiss modal dialog box when navigate to other page
    ModalDialogInstancesObserver.setModalDialogInstance($modalInstance)
