angular.module('controllers').controller "initiateTransferDialogCtrl",
  ($scope, $modal, $modalInstance, investment, fund, addOnInvestments, TransferService, IcnIdentity,
    TransferAmountService, TransferDateService, CurrencySelector) ->
    $scope.currentStep = 1
    $scope.fund = fund
    $scope.investment = investment
    $scope.addOnInvestments = addOnInvestments
    $scope.totalCommitment = _.reduce(addOnInvestments, (sum, add_on) ->
      return sum + (add_on.available_amount || 0.0)
    , (investment.available_amount || 0.0))
    $scope.totalUnits = _.reduce(addOnInvestments, (sum, add_on) ->
      return sum + (add_on.available_units || 0.0)
    , (investment.available_units || 0.0))
    $scope.currency = CurrencySelector.fromPaf(fund)

    $scope.transfer = {
      percentage: TransferAmountService.FullTransferPercentage,
      units: $scope.totalUnits,
      calculation_type: '',
      transfer_type: 'Full',
      investment_amount: $scope.totalCommitment,
      investment_units: $scope.totalUnits,
      investment_id: investment.id,
      transfer_amount: $scope.totalCommitment,
      request: {},
      open_ended: fund.open_ended,
      is_end_of_quarter: false,
      unitized: ($scope.fund.open_ended && $scope.totalUnits > 0)
      transferees: [{
        percentage: TransferAmountService.FullTransferPercentage,
        amount: $scope.totalCommitment,
        units: $scope.totalUnits,
        before: {
          percentage: TransferAmountService.FullTransferPercentage,
          amount: $scope.totalCommitment,
          units: $scope.totalUnits,
          transfer_type: 'Full',
          calculation_type: null
        }
      }],
      totals: {
        amount: $scope.totalCommitment,
        units: $scope.totalUnits,
        percentage: TransferAmountService.FullTransferPercentage
      },
      before: {
        calculation_type: '',
        percentage: TransferAmountService.FullTransferPercentage,
        transfer_amount: TransferAmountService.ZeroValue,
        units: $scope.totalUnits
      }
    }

    $scope.disableBaseValueField = true
    $scope.validTransferTotal = false
    TransferAmountService.init($scope.totalCommitment, $scope.totalUnits)

    $scope.isPafAdmin = IcnIdentity.isPafAdmin()
    $scope.isInvestorRelations = IcnIdentity.isInvestorRelations()

    $scope.options = { split: false }

    $scope.current_month = TransferDateService.CurrentMonth
    $scope.current_year = TransferDateService.CurrentYear

    $scope.isPe = $scope.investment.commitment_based
    $scope.isHf = !$scope.isPe

    $scope.$watch 'transfer.transfer_type', (type) ->
      if type == 'Full'
        $scope.disableBaseValueField = true
        TransferAmountService.resetToFullTransfer($scope.transfer)
        $scope.calculateAmount($scope.transfer)
      else
        $scope.disableBaseValueField = false

    $scope.$watch 'transfer.totals.amount', (newVal, oldVal) ->
      $scope.validTransferTotal = validateTransferTotal($scope.transfer)

    validateTransferTotal = (transfer) ->
      return false unless transfer.totals.amount && transfer.transfer_amount && amountsLargerThanZero()
      parseFloat(transfer.totals.amount.toFixed(TransferAmountService.RoundAmount), 10) ==
        parseFloat(transfer.transfer_amount.toFixed(TransferAmountService.RoundAmount), 10)

    amountsLargerThanZero = ->
      if $scope.isAmount()
        $scope.transfer.transfer_amount > TransferAmountService.ZeroValue
      else if $scope.isPercent()
        $scope.transfer.percentage > TransferAmountService.ZeroValue
      else if $scope.isUnits()
        $scope.transfer.units > TransferAmountService.ZeroValue
      else
        false

    $scope.isAmount = ->
      $scope.transfer.calculation_type == TransferAmountService.Amount

    $scope.isPercent = ->
      $scope.transfer.calculation_type == TransferAmountService.Percent

    $scope.isUnits = ->
      $scope.transfer.calculation_type == TransferAmountService.Units

    $scope.amountType = ->
      if $scope.isHf
        'NAV'
      else
        'Commitment'

    $scope.$watch 'transfer.is_end_of_quarter', (newVal, oldVal) ->
      $scope.setMonthYear()

    TransferService.getReasons().then (result) ->
      $scope.transferReasons = result

    TransferService.getCalculationTypes($scope.fund.id).then (result) ->
      $scope.calculationTypes = result.all

    $scope.showUnits = ->
      $scope.transfer.unitized && $scope.transfer.units > 0

    $scope.hideReadonlyAmountField = ->
      $scope.transfer.calculation_type == TransferAmountService.Amount ||
        ($scope.isHf && $scope.transfer.calculation_type == TransferAmountService.Units &&
          !($scope.transfer.units > TransferAmountService.ZeroValue))

    $scope.showTransferInput = (calcType) ->
      if calcType == TransferAmountService.Percent
        $scope.transfer.calculation_type == TransferAmountService.Percent || $scope.transfer.calculation_type == ''
      else if calcType == TransferAmountService.Units
        $scope.transfer.calculation_type == TransferAmountService.Units
      else if calcType == TransferAmountService.Amount
        $scope.transfer.calculation_type == TransferAmountService.Amount
      else
        false

    $scope.saveCurrentTransferState = (transfer) ->
      transfer.before.calculation_type = transfer.calculation_type
      transfer.before.transfer_type = transfer.transfer_type
      $scope.transfer.before.percentage = $scope.transfer.percentage
      $scope.transfer.before.transfer_amount = $scope.transfer.transfer_amount
      $scope.transfer.before.units = $scope.transfer.units

    $scope.saveCurrentTransfereeState = (transferee) ->
      transferee.before.percentage = transferee.percentage
      transferee.before.amount = transferee.amount
      transferee.before.units = transferee.units

    transferStateChanged = (transfer) ->
      !(transfer.before.calculation_type == transfer.calculation_type &&
        transfer.before.transfer_type == transfer.transfer_type &&
        transfer.before.percentage == transfer.percentage &&
        transfer.before.transfer_amount == transfer.transfer_amount &&
        transfer.before.units == transfer.units)

    transfereeStateChanged = (transferee) ->
      !(transferee.before.percentage == transferee.percentage &&
        transferee.before.amount == transferee.amount &&
        transferee.before.units == transferee.units)

    $scope.calculateAmount = (transfer) ->
      return unless transfer.calculation_type && transferStateChanged(transfer)
      TransferAmountService.calculateAmount(transfer.calculation_type, transfer)

    $scope.addTransferee = ->
      $scope.transfer.transferees.push({
        percentage: TransferAmountService.ZeroValue,
        amount: TransferAmountService.ZeroValue,
        units: TransferAmountService.ZeroValue
        before: {
          percentage: TransferAmountService.ZeroValue,
          amount: TransferAmountService.ZeroValue,
          units: TransferAmountService.ZeroValue
        }
      })
      $scope.splitAmount()
      TransferAmountService.updateTotals($scope.transfer)
      $scope.validTransferTotal = validateTransferTotal($scope.transfer)

    $scope.removeTransferee = (index) ->
      $scope.transfer.transferees.splice(index, 1)
      $scope.splitAmount()
      TransferAmountService.updateTotals($scope.transfer)
      $scope.validTransferTotal = validateTransferTotal($scope.transfer)

    $scope.showSplitAmount = ->
      $scope.transfer.transferees.length > 1

    $scope.splitAmount = ->
      return unless $scope.options.split
      TransferAmountService.splitAmount($scope.transfer)

    $scope.updateTransfereeUnits = (transferee) ->
      return unless transfereeStateChanged(transferee)
      TransferAmountService.calculateTransfereeUnits($scope.transfer, transferee)
      $scope.options.split = false

    $scope.updateTransfereePercentage = (transferee) ->
      return unless transfereeStateChanged(transferee)
      TransferAmountService.calculateTransfereePercentage($scope.transfer, transferee)
      $scope.options.split = false

    $scope.updateTransfereeAmount = (transferee) ->
      return unless transfereeStateChanged(transferee)
      TransferAmountService.calculateTransfereeAmount($scope.transfer, transferee)
      $scope.options.split = false

    validTransferPercentage = ->
      (!!$scope.transfer.percentage) &&
        ($scope.transfer.percentage <= TransferAmountService.FullTransferPercentage || !$scope.transfer.unitized)

    validTransferAmount = ->
      !!$scope.transfer.transfer_amount

    validCalculationType = ->
      !!$scope.transfer.calculation_type

    $scope.disableContinueButton = ->
      !validCalculationType() || !validTransferPercentage() || !validTransferAmount() || !amountsLargerThanZero()

    $scope.validRequestForm = ->
      $scope.transfer.request.reason &&
      $scope.transfer.request.accredited &&
      $scope.transfer.request.ira

    $scope.moveToStep = (step) ->
      if step == 3
        $scope.validTransferTotal = validateTransferTotal($scope.transfer)
      $scope.currentStep = step

    checkRequestAnswers = ->
      if $scope.transfer.request.reason == 'name_change' ||
        $scope.transfer.request.reason == 'custodian_change'
          $scope.contactIrText = "This is considered a re-registration and not processed as a transfer."
          return false
      else if $scope.transfer.request.reason == 'other' ||
        $scope.transfer.request.accredited == '0' ||
        $scope.transfer.request.ira == '1'
          return false
      return true

    $scope.submitRequest = ->
      if $scope.isInvestorRelations || checkRequestAnswers()
        $scope.moveToStep(2)
      else
        $scope.moveToStep(0)

    $scope.cancel = ->
      $modalInstance.dismiss "no"

    $scope.submit = ->
      transferees = $scope.transfer.transferees.filter (transferee) -> transferee.percentage != 0
      TransferService.save($scope.transfer, transferees).then (transfer) ->
        $modalInstance.close transfer

    $scope.invalidMonth = (month) ->
      if $scope.isPafAdmin
        firstYear = $scope.current_year - 1
      else
        firstYear = $scope.current_year
      parseInt($scope.transfer.effective_date_year) == firstYear && month.id < $scope.current_month

    $scope.filterMonth = (month) ->
      $scope.isHf ||
      (month.end_of_quarter && $scope.transfer.is_end_of_quarter) ||
      (month.begin_of_quarter && !$scope.transfer.is_end_of_quarter)

    $scope.canShowEndOfQuarterCheckbox = ->
      !$scope.isHf && $scope.isPafAdmin

    $scope.setMonthYear = ->
      select_month = _.find $scope.months, (month) ->
        $scope.filterMonth(month) && month.id >= $scope.current_month
      if select_month
        $scope.transfer.effective_date_month = select_month.id
      else
        new_year_month = if !$scope.isHf && $scope.is_end_of_quarter then 3 else 1
        $scope.transfer.effective_date_month = new_year_month
        $scope.transfer.effective_date_year = $scope.current_year + 1

    $scope.updateEffectiveDate = ->
      # Note, here the "$scope.investment" is actually a transfer for some reason.
      TransferService.update(
        $scope.investment.id,
        {
          is_end_of_quarter: $scope.transfer.is_end_of_quarter,
          effective_date_year: $scope.transfer.effective_date_year,
          effective_date_month: $scope.transfer.effective_date_month
        }
      ).then (transfer) ->
        $scope.investment.effective_date = transfer.effective_date
        $modalInstance.close()
      , (response) ->
        $modalInstance.dismiss(response.data)
        $modal.open
          templateUrl: "dialogs/custom_notify_dialog/custom_notify_dialog.html"
          controller: "customNotifyDialogCtrl"
          windowClass: "small-modal"
          resolve:
            header: ->
              "Something went wrong"
            msg: ->
              "Something went wrong during this transfer update. " +
              "Please refresh the page to see the current state of the transfer."
            btn_label: -> ""

    initDateSelectors = ->
      $scope.months = TransferDateService.initMonths()
      $scope.years = TransferDateService.initYears($scope.isPafAdmin)
      $scope.transfer.effective_date_year = $scope.current_year
      $scope.setMonthYear()

    initDateSelectors()
