angular.module('services').factory "SignatureProcessService",
  ($resource, $q) ->
    SignatureProcessService = ->
      @service = $resource("/api/v1/signature_processes", {},
        sendReminder:
          url: "/api/v1/signature_processes/send_reminder/:id"
          method: 'POST'
          isArray: false
      )
      return

    SignatureProcessService::sendReminder = (id) ->
      @service.sendReminder(id: id).$promise

    return new SignatureProcessService
