(function() {
    angular.module('directives').directive("icnFooter", function() {
        return {
            restrict: "AE",
            replace: true,
            transclude: true,
            templateUrl: "templates/directives/footer.html"
        };
    });
}).call(this);
