angular.module('controllers').controller "customConfirmDialogCtrl",
  ($scope, $modalInstance, ModalDialogInstancesObserver, header, msg, confirm_label, cancel_label) ->

    $scope.header = (if (angular.isDefined(header)) then header else "Confirmation")
    $scope.msg = (if (angular.isDefined(msg)) then msg else "Confirmation required.")
    $scope.confirm_label = (if (angular.isDefined(confirm_label)) then confirm_label else "Yes")
    $scope.cancel_label = (if (angular.isDefined(cancel_label)) then cancel_label else "No")

    $scope.no = ->
      $modalInstance.dismiss "no"
    $scope.yes = ->
      $modalInstance.close "yes"

    # dismiss modal dialog box when navigate to other page
    ModalDialogInstancesObserver.setModalDialogInstance($modalInstance)
