angular.module('filters').filter "bigNumberCurrency", ($filter) ->
  (number) ->
    number_str = ""
    suffix = ""
    space = ""
    number = parseFloat(number, 10)  if _.isString(number)
    if number is undefined or number is null or _.isNaN(number)
      return ""
    else if Math.abs(number) > 1000000000
      number_str = (number / 1000000000)
      suffix = "B"
      space = " "
    else
      number_str = number
      

    ($filter('currency')(number_str) + space + suffix).trim()
