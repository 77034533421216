angular.module('controllers').controller 'ForgotPasswordCtrl', ($scope, Session, $window) ->
  $scope.lessthanIE9 = -> $window.is_ie_lt9
  $scope.resetPasswordSent = false
  $scope.resetPasswordError = false
  $scope.user = {}
  regexp = new RegExp("single sign on")

  $scope.forgotPassword = ->
    Session.forgotPassword($scope.user.email).then(
      (response) ->
        $scope.message = response.data
        $scope.canResetPassword = !regexp.test($scope.message)
        $scope.showSentMessage = true
    )
