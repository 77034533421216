angular.module('services').factory "UserManagementService",
  ($resource, $q, IcnIdentity) ->
    resource = $resource("/api/v1/user_management/:id",
      id: "@id"
    ,
      query:
        method: "GET"
        isArray: false
        headers:
          "Butter-Bar": false

      userExport:
        method: "POST"
        url: "/api/v1/user_management/user_export"
        searchParams: "@searchParams"
        isArray: false
        headers:
          "Butter-Bar": false
    )

    all = (id) ->
      resource.query(
        id: id
      ).$promise

    userExport = (searchParams) ->
      resource.userExport(
        searchParams: searchParams
      ).$promise

    {
      all: all
      userExport: userExport
    }
