angular.module('directives').directive "requiredDocumentsInstructions", ->
  restrict: "E"
  scope:{}
  template: "<custom-instructions key='required_documents' default-instructional-text='defaultInstructionalText' />"
  controller: ($scope) ->
    $scope.defaultInstructionalText =
      '''
            In order to complete our diligence for your acceptance into the Private Access fund, please upload the
            following documents.
      '''
