angular.module('services', ['ngResource']).config ($httpProvider) ->

  $httpProvider.defaults
    .headers.common['X-CSRF-Token'] = $('meta[name=csrf-token]').attr('content')

  $httpProvider.interceptors.push ($q, $rootScope, $window, $cookieStore, $injector) ->
    $rootScope.xhr = { done: true }
    xhr = 0
    skipAnimationEndpoints = [
      'upload_document',
      'upload_transfer_document',
      /required_documents\/\d+\/upload/,
      /required_documents\/for_investment/,
      /required_documents\/for_investor_profile/,
      /required_documents\/create_for_investment/,
      /required_documents\/create_for_investor_profile/,
      /exports\/count/,
      /pusher\/auth/,
      'team_management/referral_codes/validate',
    ]

    skipAnimation = (url) ->
      _.detect skipAnimationEndpoints, (endpoint) ->
        !!url.match(endpoint)

    setXHRtoDone = (response) ->
      if !(skipAnimation(response.config.url))
        xhr--

        if xhr == 0
          $rootScope.xhr = {done: true}

    modifyArrayResponse = (response) ->
      if _.isArray(response.data)
        response.data = _.map response.data, (obj) ->
          first_key = _.keys(obj)[0]

          if (_.keys(obj).length == 1 && _.isObject(obj[first_key]))
            for attr of obj
              obj = obj[attr]
              obj.$icn_namespace = attr
              return obj

    modifyObjectResponse = (response) ->
      condition = (response.data && (response.data.fund || response.data.funds ||
        response.data.fund_search || response.data.firm || response.data.siblings ||
        response.data.addresses || response.data.settings || response.data.event_log ||
        response.data.current_user || response.data.notification || response.data.messages_count ||
        response.data.message || response.data.messages || response.data.fund_investment ||
        response.data.count || response.data.objectcount || response.data.workspaces ||
        response.data.folders || response.data.documents || response.data.notes || response.data.user_accreditation ||
        response.data.library_documents || response.data.images || response.data.agreements ||
        response.data.investments) && !response.data.icn_response_header)

      if condition
        for attr of response.data
          if (response.data[attr])
            response.data = response.data[attr]
            if (typeof response.data == 'object')
              response.data.$icn_namespace = attr


    IcnUtility = $injector.get('IcnUtility')
    hasPartnerProxyAPIPrefix = IcnUtility.hasPartnerProxyAPIPrefix
    prependPartnerProxyAPIPrefix = IcnUtility.prependPartnerProxyAPIPrefix
    isTemplateRequest = IcnUtility.isTemplateRequest

    return {
      request: (config) ->
        if !(skipAnimation(config.url))
          xhr++
          is_done = (config.headers['Butter-Bar'] == false ? true : false)
          $rootScope.xhr = { done: is_done }
        if (config.data && config.data.$icn_namespace)
          obj = {}
          obj[config.data.$icn_namespace] = config.data
          config.data = obj
        if (hasPartnerProxyAPIPrefix() && !isTemplateRequest(config))
          config.url = prependPartnerProxyAPIPrefix(config.url)

        return config || $q.when(config)
      ,
      response: (response) ->
        setXHRtoDone(response)
        # If you'd like your array/object data to stay intact, add the url to this list
        # We need to move all array/object returning endpoints to a non root-element style
        urlsToSaveFromRuination = [
          '/api/v1/countersignature/investments',
          '/api/v1/users/bootstrap_identity',
          '/api/v1/investment_report_document_sets/valid_reporting_periods',
          '/api/v1/investment_report_document_sets/valid_reporting_periods_for_pafs',
          '/api/v1/investment_report_document_sets',
          '/api/v1/hedge_fund_accounting_reports',
          '/api/v1/custodian_accounts',
          '/api/v1/custodians',
          '/api/v1/flash_reports',
          '/api/v1/groups',
          '/api/v1/permissions',
          '/api/v1/admin_fund_documents',
          '/api/v1/funds/fund_offerings',
          '/api/v1/fund_dashboard',
          '/api/v1/fund_sales',
          'fund_raising_activity',
          '/api/v1/closes',
          '/api/v1/private_access_funds/private_equity_funds',
          '/api/v1/private_access_funds/open_funds',
          '/api/v1/subscription_document_definitions/for_paf',
          '/api/v1/subscription_document_definitions/for_investment',
          '/api/v1/firm_fee_agreements',
          '/api/v1/private_access_funds/with_pending_capital_call_approvals',
          '/api/v1/investment_share_classes',
          '/api/v1/investments',
          '/api/v1/check_draft_api_v1_investor_relations_mass_email',
          '/api/v1/aml_approval_document_status_dashboard',
          '/api/v1/reversion/document_approvals',
          '/api/v1/reversion/approval_notes',
          '/api/v1/subscription_review/aml_approval_documents',
          '/api/v1/hedge_fund_admin/subscriptions/\\d+/closes',
          '/api/v1/document_exports',
          '/api/v1/investor_reports/private_access_funds',
          '/api/v1/investor_reports/investments',
          '/api/v1/investor_profiles/fields',
          '/api/v1/required_documents/user_create_types',
          '/api/v1/sub_doc_types',
          '/api/v1/subscription_document_definitions',
          '/api/v1/country_disclosures',
          '/api/v1/country_disclosure_agreements',
          '/api/v1/users/ir_by_login',
          '/api/v1/private_access_funds/\\d+/form_pf_classifications',
          '/api/v1/client_management/investments',
          '/api/v1/user_paf_exceptions',
          '/api/v1/users/users_list_for_paf_exception',
          '/api/v1/one_time_popups/most_recent',
          '/api/v1/financial_info',
          '/api/v1/transfers/reasons',
          '/api/v1/transfers/for_subscription_review',
          '/api/v1/transfers/\\d+/transfer_investments',
          '/api/v1/notes',
          '/api/v1/private_access_funds/funds_with_unused_exceptions',
          '/api/v1/redemptions/dashboard',
          '/api/v1/redemptions/advanced_dashboard',
          '/api/v1/contact_cards/autocomplete',
          '/api/v1/firms/autocomplete',
          '/api/v1/funds/autocomplete',
          '/api/v1/funds/\\d+/fund_documents',
          '/api/v1/funds/\\d+/fund_documents/signers',
          '/api/v1/exports',
          '/api/v1/private_access_funds/\\d+/capital_events',
          '/api/v1/private_access_funds/\\d+/investments/initial_investment',
          '/api/v1/private_access_funds/\\d+/investments/initial_investment_present',
          '/api/v1/users/\\d+/referral_codes'
          '/api/v1/non_disclosure_agreements',
          '/api/v1/fund_admin_groups',
          '/api/v1/advisor_marketing_fields',
          '/api/v1/investor_representative_requests/investors',
          '/api/v1/investor_representative_requests/managers',
          '/api/v1/team_management/referral_codes',
          '/api/v1/incident_status_alerts/latest',
          '/api/v1/registration_account_types',
          '/api/v1/private_access_funds/admin_index',
          '/api/v1/private_access_funds/hedge_funds',
          '/api/v1/private_access_funds/bny_funds',
          '/api/v1/menu/fund_administration_menu',
        ]

        # This implements some kind of legacy array/object transform on all AJAX requests
        # we are trying to get away from this style
        isLegacyUrl = !_.any urlsToSaveFromRuination, (url_pattern) ->
          response.config.url.match(url_pattern)

        if(isLegacyUrl)
          modifyArrayResponse(response)
          modifyObjectResponse(response)

        return response || $q.when(response)
      ,
      responseError: (response) ->
        setXHRtoDone(response)
        IcnIdentity = $injector.get('IcnIdentity')

        if response.status == 401
          if $window.location.pathname != "/login"
            if IcnIdentity.loginDestination() != "/login"
              $cookieStore.remove('_icn_session')
              $window.location.href = IcnUtility.ensureRelative(IcnIdentity.loginDestination())
            else
              $cookieStore.remove('_icn_session')
              $cookieStore.put('_icn_auth_error', response.data.error || '')
              $window.location.href = "login"

        return $q.reject(response)
    }
