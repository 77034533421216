angular.module('directives').directive "signerConfigurationBox", (ContactCard) ->
  restrict: "E"
  templateUrl: "components/investment_list/dialogs/send_subscription_dialog/signer_configuration_box/signer_configuration_box.html"
  scope:
    signerConfiguration: "="
    phonesAllowed: "="

  link: (scope, element, attrs) ->
    scope.displayPhones = scope.phonesAllowed && !scope.signerConfiguration.skip_auth
    scope.contactOptions = scope.signerConfiguration.contact_options?.map((contact_option) -> contact_option.email)
    scope.emailInput = { email: "" }
    scope.phoneInput = { phone: "" }
    scope.phoneValid = true
    scope.phoneErrors = null

    scope.phoneOptions = scope.signerConfiguration.phone_nums?.map((phone_num) -> phone_num.phone_iso) || []

    if scope.phoneOptions?.length == 1
      scope.signerConfiguration.phone = scope.phoneOptions[0]
    else
      scope.signerConfiguration.phone = undefined


    scope.emailValidate = ->
      emailRegex = /^$|^[A-Za-z0-9]{1}(?!.*?\.\.)[^ @]*@[A-Za-z0-9\-.]+\.[A-Za-z]+$/

      if emailRegex.test(scope.emailInput.email)
        scope.signerConfiguration.email = scope.emailInput.email
        scope.emailError = false
      else
        scope.emailError = true
        scope.signerConfiguration.email = undefined

    scope.phoneValidate = ->
      ContactCard.check_phone_validity(phone_number: scope.phoneInput.phone).$promise.then (result) ->
        scope.phoneValid = result.valid
        if result.valid
          scope.signerConfiguration.phone = scope.phoneInput.phone
        else
          scope.signerConfiguration.phone = undefined

        scope.phoneErrors = if !scope.phoneValid then 'The phone number is invalid' else null

    scope.phoneDisabled = ->
      scope.phoneOptions?.length == 1
