angular.module('services').factory "AmlFilterStorageService", [
  "$localStorage"
  ($localStorage) ->

    $localStorage.amlFilter = {} if angular.isUndefined($localStorage.amlFilter)

    isFilterSet = (key) ->
      return !angular.isUndefined($localStorage.amlFilter[key])

    setFilter = (key, filter) ->
      return unless key
      $localStorage.amlFilter[key] = filter

    getFilter = (key) ->
      return $localStorage.amlFilter[key]

    clearFilter = (key) ->
      return unless key
      clone = angular.copy($localStorage.amlFilter)
      delete clone[key]
      $localStorage.amlFilter = clone

    return (
      setFilter: setFilter,
      getFilter: getFilter,
      isFilterSet: isFilterSet,
      clearFilter: clearFilter
    )
]

