angular.module('directives').directive "icnRoadshowChapterList", (TrackingService) ->
  restrict: "E"
  templateUrl: "templates/roadshows/roadshow_chapter_list.html"
  scope:
    chapters: "="
    isCollapsed: "="
    fund: "="
  require: "^icnRoadshow"

  link: (scope, element, attrs, icnRoadshowController) ->
    scope.isCollapsed = true  if _.isUndefined(scope.isCollapsed)

    scope.isChapterHidden = (index) ->
      scope.isCollapsed and index > 1

    scope.selectChapter = (chapter, $event) ->
      $event.preventDefault()
      icnRoadshowController.showChapterDetail chapter
      TrackingService.pushEvent('Click on video: ' +  chapter.title)
