angular.module('controllers').controller "SendToGPDialog", ($scope, $modalInstance, $window, investment, IcnIdentity, InvestmentService,
  privateAccessFund, openFund) ->

    $scope.data = {}

    $scope.paf = privateAccessFund
    $scope.investment = investment

    $scope.isOpenFund = openFund

    $scope.onCancel = ->
      $modalInstance.dismiss()

    $scope.onSubmit = (form) ->
      if form.$valid
        InvestmentService.sendGeneralPartnerCertificate(
          $scope.paf.gp_legal_entity_name,
          $scope.data.name,
          $scope.data.title,
          $scope.data.email,
          $scope.data.sign_method,
          $scope.paf.id,
          investment
        ).then((result) ->
          if $scope.data.sign_method == 'manual'
            $window.open(result.general_partner_signature.file_url, '_blank')

          $modalInstance.close(result)
        )
