class Node
  constructor: (previous, next, template) ->
    @previous = previous
    @next = next
    @template = "components/investment_list/dialogs/investment_blocks_dialog/templates/#{template}.html"

class HardNode extends Node
  constructor: (blocks) ->
    super(null, null, "hard_block_modal")
    @blocks = blocks

class SoftNode extends Node
  constructor: (blocks) ->
    super(null, null, "soft_block_modal")
    @blocks = blocks
    @isSoftNode = true

class PotencialIssueNode extends Node
  constructor: (block, index, size) ->
    super(null, null, "potencial_issues_modal")
    @block = block
    @index = index
    @size = size
    @accepted = false
    @isPotencialIssue = true

  accept: ->
    @accepted = !@accepted

  infoTemplate: ->
    return "#{@block.block_type}.html"

angular.module('controllers').controller "InvestmentBlocksDialogCtrl",
  ($scope,
  $window,
  $rootScope,
  $modal,
  $modalInstance,
  $location,
  ModalDialogInstancesObserver,
  blocks,
  urlBuilder,
  privateAccessFund,
  showAcceptanceSteps,
  queryParams,
  IcnIdentity,
  $sce) ->

    buildHardStates = (blocks) ->
      return new HardNode(blocks)

    buildSoftStates = (blocks) ->
      softNode = new SoftNode(blocks)
      if !showAcceptanceSteps
        return softNode

      blocks.reduce((lastNode, block, index, array) ->
        blockNode = new PotencialIssueNode(block, index + 1, array.length)
        lastNode.next = blockNode
        blockNode.previous = lastNode

        return blockNode
      , softNode)

      return softNode

    hasHardBlocks = -> blocks[0].constraint_type == "hard"

    queryParamsToString = (params) ->
      queryString = []
      for key in Object.keys(params)
        if Array.isArray(params[key])
          params[key].forEach (value) ->
            queryString.push("#{key}[]=#{value}")
        else
          queryString.push("#{key}=#{params[key]}")
      queryString.join("&")

    mergeQueryParams = (queryParams) ->
      queryParamsToString(Object.assign({}, $location.search(), queryParams))

    $scope.withEncodedParams = (url) ->
      return url + if !!queryParams then encodeURIComponent("?#{queryParams}") else ""

    $scope.currentState = if hasHardBlocks() then buildHardStates(blocks) else buildSoftStates(blocks)
    $scope.privateAccessFund = privateAccessFund

    $scope.needAssistanceOverride = $sce.trustAsHtml(IcnIdentity.needAssistanceOverride())

    $scope.cancelDialog = ->
      $modalInstance.dismiss()

    $scope.next = ->
      if $scope.currentState.next
        $scope.currentState = $scope.currentState.next
      else
        $modalInstance.close([])

    $scope.previous = ->
      $scope.currentState = $scope.currentState.previous

    $scope.submit = ->
      block_types = blocks.map((block) -> block.block_type)
      $modalInstance.close(block_types)

    $scope.callToAction = (data) ->
      switch data.page
        when "investor_profile"
          investor_profile_url = urlBuilder.investorProfileUrl(
            data.inv_profile_id,
            data.step,
            mergeQueryParams(data.query_string),
            true,
          )
          $window.location.href = $scope.withEncodedParams(investor_profile_url)
        when "education_dashboard"
          backUrl = encodeURIComponent($location.absUrl())
          $window.open(data.url.replace("return_url=", "return_url=#{backUrl}"))
