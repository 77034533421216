angular.module('directives').directive 'incidentStatusAlerts', ($http) ->
  restrict: 'E',
  templateUrl: 'components/incident_status_alerts/incident_status_alerts.html',
  controllerAs: '$ctrl',
  controller: ->
    $ctrl = this

    $http.get '/api/v1/incident_status_alerts/latest'
      .then ({ data: alerts }) ->
        $ctrl.activeAlerts = alerts && alerts.filter ({ status } = {}) -> status != 'resolved'
        $ctrl.resolvedAlerts = alerts && alerts.filter ({ status } = {}) -> status == 'resolved'
