angular.module('controllers').controller "sendSubscriptionDialogCtrl",
  ($scope, $modal, $modalInstance, Firms, ModalDialogInstancesObserver, InvestmentService, Users, privateAccessFund,
    IcnUtility, investment, resetSubscription, msg, fundSignatureVendor, InvestmentPhoneNumbersService,
    placementFee, subAdvisoryFee, IcnIdentity, TrackingService, ContactCard, $window,
    SubscriptionDocumentDefinitionService, attestation) ->

    $scope.currentStep = "sign_method"
    $scope.faFeature = {
      preferredSigner: "",
      faSelection: 'investor',
      email: investment.financial_advisor_info?.email,
      name: investment.financial_advisor_info?.name
      selectedSignatory: undefined,
      centralSignatories: [],
      sendOfferingDocs: true,
      phone: null,
      role_name: 'discretionary',
      display_name: 'Financial Advisor',
      phone_nums: investment.financial_advisor_info?.phone_nums,
    }

    $scope.privateAccessFund = privateAccessFund
    $scope.privateAccessFundId = privateAccessFund.id
    $scope.fundSignatureVendor = fundSignatureVendor
    $scope.investment = investment
    $scope.resetSubscription = resetSubscription
    $scope.msg = msg
    $scope.whiteLabelPartner = IcnIdentity.whiteLabelPartner()
    $scope.placementFee = placementFee
    $scope.subAdvisoryFee = subAdvisoryFee
    $scope.headerText = "Send Subscription Agreement"
    $scope.preText = ""
    $scope.paf_strict_signature_method = $scope.investment.paf_strict_signature_method
    $scope.submitButton = ""
    $scope.docError = false
    $scope.subscriptionDocumentDefinition = $scope.investment.subscription_document_definition
    $scope.faFeatureEnabled = $scope.subscriptionDocumentDefinition.enable_discretionary_signer &&
      !investment.custodian?.disable_discretionary_fa &&
      !IcnIdentity.isIndividualInvestor()

    if attestation
      $scope.attestationId = attestation.id
      $scope.attestationMsg = attestation.message
      $scope.attestationChecked = false

      if !$scope.resetSubscription
        $scope.currentStep = "fund_acknowledgement"

    $scope.moveToStep = (step) ->
      $scope.currentStep = step

    $scope.allowSubmit = ->
      unless $scope.attestationChecked || !$scope.attestationId || $scope.resetSubscription
        return false

      return true unless $scope.currentStep == 'sign_method'

      validateSigners()

    $scope.toggleAttestation = ->
      $scope.attestationChecked = !$scope.attestationChecked

    $scope.showOptionalAttestation = ->
      !!$scope.attestationId &&
      (IcnIdentity.isRia() || IcnIdentity.isBranchManager() || IcnIdentity.isIndividualInvestor()) &&
      !$scope.resetSubscription

    # dismiss modal dialog box when navigate to other page
    ModalDialogInstancesObserver.setModalDialogInstance($modalInstance)

    finishProcess = (investment) ->
      if investment.sign_method == "manual" && investment.signature_vendor == 'echosign' && !!investment.subscription_temp_file_url
        $window.open(investment.subscription_temp_file_url)

      investment.approval_status = "Pending Review"
      investment.used_preparer_workflow = $scope.preparerBeingUsed

      $modalInstance.close(investment)

    $scope.showInvestor = ->
      !$scope.faFeatureEnabled ||
        ($scope.faFeatureEnabled && $scope.faFeature.faSelection == 'investor')

    $scope.faSelected = ->
      $scope.faFeatureEnabled &&
        $scope.faFeature.faSelection == 'fa'

    $scope.financialAdvisorSelected = ->
      $scope.faSelected() &&
        $scope.faFeature.preferredSigner == 'Financial Advisor'

    validateSigners = ->
      if $scope.faSelected()
        validateDiscretionaryWorkflows()
      else
        validMainSigners()

    validateDiscretionaryWorkflows = ->
      return validateFinancialAdvisorWorkflow() if $scope.financialAdvisorSelected()
      validateCentralSignatoryWorkflow() if $scope.centralSignatorySelected()

    validateFinancialAdvisorWorkflow = ->
      $scope.financialAdvisorSelected() &&
        validFinancialAdvisorEmail() &&
        (valid($scope.faFeature) || !$scope.showDiscretionaryFa()) &&
        _.all($scope.processedPreviousSignerConfigurations(), (signerConfiguration) -> valid(signerConfiguration)) &&
        _.all($scope.processedNextSignerConfigurations(), (signerConfiguration) -> valid(signerConfiguration))

    $scope.showDiscretionaryFa = () ->
      previousSignerConfigurations = $scope.processedPreviousSignerConfigurations()
      previousSignerConfigurations?.length > 0 || !$scope.preparerBeingUsed || IcnIdentity.email() != $scope.faFeature.email

    validateCentralSignatoryWorkflow = ->
      selectedCentralSignatory() &&
        $scope.csPhoneNumberValid &&
        _.all($scope.previousSignerConfigurations(), (signerConfiguration) -> valid(signerConfiguration)) &&
        _.all($scope.nextSignerConfigurations(), (signerConfiguration) -> valid(signerConfiguration))

    validMainSigners = ->
      _.all($scope.signerConfigurations, (signerConfiguration) -> valid(signerConfiguration))

    valid = (signerConfiguration) ->
      complete = !!signerConfiguration.email

      complete && (!$scope.require2fa || (signerConfiguration.skip_auth || !!signerConfiguration.phone))

    validFinancialAdvisorEmail = ->
      return true unless $scope.financialAdvisorSelected()
      $scope.faFeature.email

    $scope.centralSignatorySelected = ->
      $scope.faSelected() &&
        $scope.faFeature.preferredSigner == 'Central Signatory'

    selectedCentralSignatory = ->
      return true unless $scope.centralSignatorySelected()
      $scope.faFeature.selectedSignatory

    setFormDefaults = ->
      $scope.faFeature.selectedSignatory = undefined

    $scope.showOfferingDocumentInfo = ->
      !$scope.investment.requires_pre_approval &&
        !$scope.investment.requires_pre_approval_secondary &&
        !$scope.resetSubscription &&
        faShowOfferingDocs()

    faShowOfferingDocs = ->
      $scope.faFeature.faSelection == 'investor' || $scope.faFeature.sendOfferingDocs

    $scope.hideManualSignature = ->
      (
        $scope.manualSignatureOnly() ||
        $scope.electronicSignatureOnly() ||
        !validFinancialAdvisorEmail() ||
        !selectedCentralSignatory()
      )

    init = ->
      getSignerConfigurations()

      if $scope.resetSubscription
        $scope.headerText = "Send Updated Subscription"

        type = if $scope.investment.paf_type == 'Hedge Fund' then 'Subscription Amount' else 'Commitment Amount'
        $scope.preText = "Use this feature to send a revised subscription agreement " +
                         "for signature.  Changes to the Account Profile or #{type} " +
                         "must be made online first to be reflected in the new agreement."

      if $scope.msg
        $scope.preText = $scope.msg

      InvestmentService.availableSignMethod($scope.investment.id).then((result) ->
        $scope.subscription_sign_method = result.sign_method

        if $scope.manualSignatureOnly()
          $scope.investment.sign_method = 'manual'
          $scope.submitButton = 'DOWNLOAD'

          if $scope.custodianManualSignatureOnly()
            $scope.signatureRequirementWarning = 'The custodian you have selected requires a manual' +
                                                ' signature for this subscription agreement.'
          else
            $scope.signatureRequirementWarning = 'The Fund requires this subscription agreement' +
                                                        ' to be manually signed.'
        else
          $scope.investment.sign_method = 'electronic'
          $scope.submitButton = 'SEND'
      )
      $scope.isDocusignFund = $scope.fundSignatureVendor == 'docusign'
      $scope.csPhoneNumberValid = !$scope.require2fa
      $scope.showMissingPhoneNumbersError = false

      getFaFeature()

    $scope.cancelDialog = ->
      $modalInstance.dismiss()

    $scope.custodianManualSignatureOnly = ->
      ($scope.investment.custodian &&
        $scope.investment.custodian.manual_signature &&
        !$scope.paf_strict_signature_method)

    $scope.manualSignatureOnly = ->
      $scope.subscription_sign_method == 'manual_only'

    $scope.electronicSignatureOnly = ->
      $scope.subscription_sign_method == 'electronic_only'

    $scope.previewReferenceDocs = ->
      $window.open('/investments/' + $scope.investment.id + '/preview_reference_documents_email.html')
      return true

    $scope.manualSubBook = ->
      $scope.investment.sign_method = 'manual'
      $scope.sendSubBook()

    $scope.sendSubBook = ->
      amount = IcnUtility.formatCurrency(investment.amount)

      if $scope.resetSubscription
        if angular.isDefined(placementFee)
          investment.placement_fee = placementFee
          resetSubscription(investment, amount)
        else if angular.isDefined(subAdvisoryFee)
          InvestmentService.setSubAdvisoryFee(investment, subAdvisoryFee).then ->
            resetSubscription(investment, amount)
        else
          resetSubscription(investment, amount)
      else
        if angular.isDefined(placementFee)
          investment.placement_fee = placementFee
          sendSubscription(investment)
        else if angular.isDefined(subAdvisoryFee)
          InvestmentService.setSubAdvisoryFee(investment, subAdvisoryFee).then ->
            sendSubscription(investment)
        else
          sendSubscription(investment)
          message = "Click in Send Subscription with " + investment.sign_method + " signing"
          TrackingService.investmentAction(investment, message)

    sendSubscription = (investment) ->
      InvestmentService.sendSubscription(
        $scope.privateAccessFundId,
        investment,
        $scope.attestationId || null,
        signerConfigurationData(),
        $scope.faFeature,
        investment.amount,
        false
      ).then (data) ->
        return $scope.docError = true if data.phone_errors
        handleAgreementCreation(investment, data)
      .catch (error) ->
        $scope.docError = true

    resetSubscription = (investment, amt) ->
      InvestmentService.sendSubscription(
        $scope.privateAccessFundId,
        investment,
        null,
        signerConfigurationData(),
        $scope.faFeature,
        amt,
        true
      ).then (data) ->
        return $scope.docError = true if data.phone_errors
        investment.approval_status = data.investment.approval_status
        handleAgreementCreation(investment, data)

    handleAgreementCreation = (investment, data) ->
      investment.agreement_id = data.investment.agreement_id
      investment.signature_vendor = data.investment.signature_vendor
      investment.commitment = data.investment.commitment
      investment.subscription_document = data.investment.subscription_document
      investment.subscription_temp_file_url = data.investment.subscription_temp_file_url

      finishProcess(investment)

    strip_icn_extension = (email) ->
      email.replace(/\b(\.icn[0-9]+)$\b/, '')

    getFaFeature = ->
      if $scope.faFeatureEnabled
        Firms.faFeature($scope.investment.firm_id).then((result) ->
          faAdvisorOnly = result.preferred_signer == 'Financial Advisor Advisory Only'
          if faAdvisorOnly && $scope.investment.sub_doc_type_name != 'advisory_individual'
            $scope.faFeatureEnabled = false
            return
          $scope.faFeature.centralSignatories = _.map(result.central_signatories, (cs) -> {
            id: cs.id,
            name: cs.name,
            display_name: "#{cs.name} (#{cs.email})",
            email: cs.email,
            country_code: cs.country_code,
            area_code: cs.area_code,
            phone: cs.phone,
            phone_iso: cs.phone_iso
          })
          if faAdvisorOnly
            $scope.faFeature.preferredSigner = 'Financial Advisor'
          else
            $scope.faFeature.preferredSigner = result.preferred_signer
          $scope.faFeature.sendOfferingDocs = result.send_fa_offering_docs
          $scope.faFeatureEnabled = $scope.subscriptionDocumentDefinition.enable_discretionary_signer &&
            !!result.preferred_signer &&
            result.preferred_signer != 'Default Signers'
        )

    $scope.selectSignatory = (cs) ->
      $scope.csPhoneNumberValid = !$scope.require2fa || !!cs.phone

    $scope.showCsError = ->
      selectedCentralSignatory() && !$scope.csPhoneNumberValid

    $scope.$watch 'faFeature.faSelection', (newVal) ->
      setFormDefaults() if newVal == 'investor'

    findSignerIndex = (roleName, signerConfigs) -> signerConfigs.findIndex((sc) => sc.role_name == roleName);

    getSignerConfigurations = ->
      return unless $scope.investment.subscription_document_definition?.id

      SubscriptionDocumentDefinitionService.retrieveSignerConfigurations(
        id: $scope.subscriptionDocumentDefinition.id, investment_id: $scope.investment.id,
      ).$promise.then (response) ->
        $scope.signerConfigurations = response.signer_configurations
        $scope.preparerBeingUsed = response.preparer_being_used
        $scope.require2fa = response.requires_2fa
        $scope.signer1Index = findSignerIndex('signer 1', $scope.signerConfigurations)
        $scope.signer2Index = findSignerIndex('signer 2', $scope.signerConfigurations)
        $scope.signer3Index = findSignerIndex('signer 3', $scope.signerConfigurations)

    $scope.previousSignerConfigurations = () ->
      $scope.signerConfigurations.slice(0, $scope.signer1Index)

    $scope.nextSignerConfigurations = () ->
      if ($scope.signer3Index >= 0)
        $scope.signerConfigurations.slice($scope.signer3Index + 1)
      else if ($scope.signer2Index >= 0)
        $scope.signerConfigurations.slice($scope.signer2Index + 1)
      else
        $scope.signerConfigurations.slice($scope.signer1Index + 1)

    $scope.processedPreviousSignerConfigurations = () ->
      previousSignerConfigurations = $scope.previousSignerConfigurations()
      lastSigner = previousSignerConfigurations[previousSignerConfigurations.length - 1]

      if (lastSigner?.role_name == 'financial advisor')
        return previousSignerConfigurations.slice(0, previousSignerConfigurations.length - 1)

      previousSignerConfigurations

    $scope.processedNextSignerConfigurations = () ->
      nextSignerConfigurations = $scope.nextSignerConfigurations()
      firstSigner = nextSignerConfigurations[0]

      if (firstSigner?.role_name == 'financial advisor')
        return nextSignerConfigurations.slice(1)

      nextSignerConfigurations

    signerConfigurationData = () ->
      signerData = {}

      _.each($scope.signerConfigurations, (signerConfiguration) ->
        signerData[signerConfiguration.role_name] = {
          phone: signerConfiguration.phone,
          email: signerConfiguration.email,
          skip_auth: signerConfiguration.skip_auth,
        }
      )

      signerData

    init()
