angular.module('services').factory "MenuAnimation", ($window, $timeout) ->
  submenus = ['invest', 'research', 'reporting', 'manage', 'admin', 'management-reporting']

  hideUserMenu = () ->
    $('.header__actions .dropdown-menu.fade').removeClass 'opaque'

  showUserMenu = () ->
    $('.header__actions .dropdown-menu.fade').addClass 'opaque'

  sumChildLinkWidths = (nodes) ->
    sum = 0
    nodes.each (i, el) ->
      sum += $(el).width()

    return sum

  lefts = {}
  submenuLeft = (submenu) ->
    # Calculate the left offset of the submenus - they should be centered under their respective menu item
    # Initially, they are aligned to the left of the submenu container; calculate and save the new left offset
    name = submenu.attr('name')
    unless (lefts[name])
      links = submenu.find(".header__submenu-links")

      # For IE < Edge 16, width: fit-content is not supported.
      # Need to sum the widths of the child elements, else IE will use the width of the full div.
      linksChildrenWidth = sumChildLinkWidths( links.children() );
      navItem = $("li[name='#{name}']")
      linksOffset = links.offset()
      navItemOffset = navItem.offset()
      if navItem != undefined && navItemOffset != undefined && linksOffset != undefined
        newLeft = navItemOffset.left + (navItem.width() / 2) - (linksChildrenWidth / 2) - linksOffset.left
        lefts[name] = if newLeft < 30 then 30 else newLeft # Don't go farther left than the site margin
    lefts[name]

  setMouseEvents = ->
    $('.header__nav-item, .header__submenu').mouseenter (event) ->
      selected = $(event.currentTarget)
      submenu = $(".header__submenu[name='#{selected.attr('name')}']")
      return

    $('.header__actions').mouseenter (event) ->
      showUserMenu()

    $('.header__actions').mouseleave (event) ->
      hideUserMenu()

    $('.dropdown-menu').click () ->
      hideUserMenu()

  setClickEvents = ->
    $('body').on 'touchstart', (event) ->
      selected = $('.selected-nav')
      clickedNavItem = $(event.target).closest('.header__nav-item')
      clickedUserMenu = $(event.target).closest('.header__actions')

      if clickedUserMenu.length
        if userMenu.hasClass('opaque')
          # If a user menu item is clicked, give a delay before closing user menu to allow for the iPad to register the click
          $timeout( ->
            $('#account-info').addClass 'no-hover'
            hideUserMenu()
          , 500)
        else
          $('#account-info').removeClass 'no-hover'
          showUserMenu()
      else if clickedNavItem.length
        submenu = $(".header__submenu[name='#{clickedNavItem.attr('name')}']")
        submenu.css('left', "#{submenuLeft(submenu)}px") # Sets custom centering logic

  isIPad = navigator.userAgent.match(/iPad/i) != null

  initiate = ->
    if isIPad
      setClickEvents()
    else
      setMouseEvents()

  {
    initiate: initiate
  }
