angular.module 'icn.paf_admin.funds'
.directive 'privateAccessFundFinancialDocument', (AdminFundDocument, $modal) ->
  restrict: 'E'
  templateUrl: 'areas/paf_admin/tabs/pe/funds/pages/funds/components/financial_document/financial_document.html'
  scope:
    fund: '='

  link: (scope, elem, attrs) ->
    scope.dateErrors = null
    scope.financialDocuments = []

    scope.reportDateChoices = ->
      curr = new Date()
      _.range(2015, curr.getFullYear() + 1)

    scope.updateFinancialDocuments = ->
      AdminFundDocument.query({private_access_fund_id: scope.fund.id, type: 'FinancialDocument'}).$promise.then (res) ->
        scope.financialDocuments = res

    scope.$watch 'fund', (fund) ->
      if fund
        scope.updateFinancialDocuments()

    resetForm = ->
      scope.financialDocument =
        reportDate: ""
        sendUploadNotifications: false

    scope.uploadDocument = (file) ->
      date = "1/1/" + scope.financialDocument.reportDate
      AdminFundDocument.upload(scope.fund, file, 'FinancialDocument', date, scope.financialDocument.sendUploadNotifications)
      .then (res) ->
        scope.updateFinancialDocuments()
        scope.msg =
          text: 'File uploaded successfully'
          type: 'info'

      , (err) ->
          scope.msg =
            type: 'danger'
            errors: [err.data.message]

    scope.deleteFinancialDocument = (doc) ->
      opts =
        templateUrl: "dialogs/custom_confirm_dialog/custom_confirm.html"
        controller: "customConfirmDialogCtrl"
        windowClass: "small-modal"
        resolve:
          header: ->
            "Delete Financial Statement"
          msg: ->
            "Are you sure you want to delete this financial statement?"
          confirm_label: ->
            "Confirm"
          cancel_label: ->
            "Cancel"
      $modal.open(opts).result.then ->
        doc.$delete().then (res) ->
          if(!res.errors)
            scope.updateFinancialDocuments()
            scope.msg =
              text: 'File deleted successfully'
              type: 'info'
          else
            scope.msg =
              type: 'danger'
              errors: res.errors

    resetForm()
