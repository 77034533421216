angular.module('services').service "FullWidth", ->
  # Not using classList.toggle because of IE support
  removeFullWidthScreen = ->
    document.getElementById('content-container').classList.remove 'container-fluid'
    document.getElementById('content-container').classList.add 'container'
    document.getElementById('content-container').classList.add 'fixed-container'

  useFullWidthScreen = ->
    document.getElementById('content-container').classList.add 'container-fluid'
    document.getElementById('content-container').classList.remove 'container'
    document.getElementById('content-container').classList.remove 'fixed-container'

  {
    removeFullWidthScreen: removeFullWidthScreen,
    useFullWidthScreen: useFullWidthScreen
  }
