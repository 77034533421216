angular.module('directives').directive "icnDropdownWrapper", ->
  restrict: "E"
  scope: {}
  transclude: true
  templateUrl: "components/dropdowns/icn_dropdown.html"
  link: (scope, el, attrs, ctrl, transclude) ->
    transclude(scope.$parent, (clone) ->
      dropdownEl = el.find(".icn-dropdown")
      dropdownEl.append(clone)
      selectEl = el.find("select")
      selectEl.addClass("icn-select")
    )
