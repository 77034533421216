angular.module('controllers').controller 'PrivateAccessFundListCtrl',
    ($scope, $timeout, $route, $location, PrivateAccessFunds, CurrentUser, IcnIdentity, FlashReport, ToolTips, $modal, $sce) ->
      $scope.onShore = "true"
      $scope.showContact = true
      $scope.contactInfo = {}
      $scope.toolTips = ToolTips
      $scope.needAssistanceOverride = false

      $scope.filterOnOffShore = (fund) ->
        $scope.hideShoreDropdown || $scope.onShore == fund.on_shore?.toString()

      $scope.limitedForUser = (id) ->
        IcnIdentity.isLimitedForPaf(id)

      setHeaderElements = ->
        $scope.contactInfo.phoneNumber = '212 994 7333'
        $scope.contactInfo.emailAddress = 'ir@icapitalnetwork.com'
        if IcnIdentity.isWhiteLabel()
          $scope.wlp = IcnIdentity.data.white_label_partner
          $scope.needAssistanceOverride = $sce.trustAsHtml($scope.wlp.need_assistance_override)
          $scope.hideShoreDropdown = $scope.wlp.hide_shore_dropdown
          $scope.wlpShowFlashReports = $scope.wlp.show_flash_reports

      checkForRedirect = (funds) ->
        if ($scope.feature_flags.redirect_paf_list_to_profile)
          if (funds.length == 1)
            $location.path("/funds/#{funds[0].fund_id}")
        funds

      checkForInvestmentOpportunities = (funds) ->
        if $scope.fundType == 'pe'
          $location.path("/investment_opportunities/private_equity_funds")
        else if $scope.fundType == 'hedge'
          $location.path("/investment_opportunities/hedge_funds")
        else if $scope.fundType == 'direct_deal'
          $location.path("/investment_opportunities/")
        else if $scope.fundType == 'ai'
          $location.path("/investment_opportunities/ai_funds")
        funds

      setDisclaimers = ->
        $scope.disclaimers = []
        _.each($scope.flashReports, (report) ->
          if report.disclaimer && report.disclaimer.length > 0
            report.paf_name = report.paf_name + "<sup>#{$scope.disclaimers.length + 1}</sup>"
            $scope.disclaimers.push $sce.trustAsHtml("#{$scope.disclaimers.length + 1}. #{report.disclaimer}")

          report.fund_name_html = $sce.trustAsHtml(report.fund_name)
        )

      flashReports = ->
        FlashReport.query().$promise.then (data) ->
          $scope.flashReports = data
          showFlashReports =
            $scope.fundType == 'hedge' &&
            $scope.flashReports.length > 0 &&
            $scope.private_access_funds.length > 0
          if IcnIdentity.isWhiteLabel()
            $scope.showFlashReports = showFlashReports && $scope.wlpShowFlashReports
          else
            $scope.showFlashReports = showFlashReports
          setDisclaimers()

      menu = IcnIdentity.data
      $scope.feature_flags = menu.feature_flags
      $scope.fundType = $route.current.fundType

      setHeaderElements()

      PrivateAccessFunds.getOpenAndVintagePrivateAccessFunds({fund_type: $scope.fundType, branch_manager_as_investor: true})
        .then(checkForInvestmentOpportunities)
        .then(checkForRedirect)
        .then(flashReports)
