angular.module('services').factory "TermsOfUseService", ($resource, $q) ->
  TermsOfUseService = ->
    @service = $resource("/api/v1/terms_of_uses",
      null
    ,
      get_main_site_active:
        url: "/api/v1/terms_of_uses/active_main_site_terms"
        method: 'GET'

      get_wlp_active:
        url: "/api/v1/terms_of_uses/active_wlp_terms"
        method: 'GET'
        wlp_id: @wlp_id
    )
    return

  TermsOfUseService::get_main_site_active = ->
    @service.get_main_site_active().$promise

  TermsOfUseService::get_wlp_active = ->
    @service.get_wlp_active().$promise

  return new TermsOfUseService()
