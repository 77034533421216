angular.module('directives').directive "icnDisclaimer", ($rootScope, DisclaimerService, IcnIdentity, RoutesUtilService) ->
  restrict: "E"
  templateUrl: "areas/layout/components/disclaimer/icn_disclaimer.html"
  scope: {}

  link: (scope, element, attrs) ->

    if(angular.isDefined(attrs.disableSupress))
      scope.suppressDisclaimer = ->
        false
    else
      scope.suppressDisclaimer = ->
        not RoutesUtilService.onAnyFundPage()

    scope.showDisclaimer = !scope.suppressDisclaimer()

    scope.currentDisclaimer = DisclaimerService.currentDisclaimer
    scope.standardDisclaimer = IcnIdentity.data.white_label_partner.disclaimer

    scope.customDisclaimer = ->
      disclaimer_obj = scope.currentDisclaimer.disclaimer
      if (disclaimer_obj != null && disclaimer_obj != undefined)
        return disclaimer_obj.toString().length > 0
      else
        return false

    $rootScope.$on '$locationChangeStart', (event, newUrl, oldUrl) ->
      DisclaimerService.currentDisclaimer.disclaimer = ""

      if scope.suppressDisclaimer()
        DisclaimerService.hideDisclaimer()
      else
        DisclaimerService.showDisclaimer()

      scope.showDisclaimer = DisclaimerService.disclaimerVisible()
