angular.module('services').factory 'LibraryDocumentCache', (LibraryDocumentService) ->
  _promises = {}
  _investment_promises = {}

  @fetchDocs = (pafId, wlpId = null) ->
    if wlpId
      LibraryDocumentService.forPaf(private_access_fund_id: pafId, wlp_id: wlpId).$promise
    else if _promises[pafId]
      _promises[pafId]
    else
      _promises[pafId] = LibraryDocumentService.forPaf(private_access_fund_id: pafId).$promise
      _promises[pafId]

  @fetchInvestmentDocs = (investmentId) ->
    return _investment_promises[investmentId] if _investment_promises[investmentId]

    _investment_promises[investmentId] = LibraryDocumentService.forInvestment(investment_id: investmentId).$promise
    _investment_promises[investmentId]

  return @
