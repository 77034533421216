angular.module 'icn.models'
.factory 'InvestorRelationsMassEmail', ($resource) ->
  investorRelationsMassEmail =
    $resource '/api/v1/investor_relations_mass_emails/:id', {id: '@id'},
      notify:
        method: 'POST',
        url: "/api/v1/investor_relations_mass_emails/:id/notify"
        params:
          notificationGroup: '@notificationGroup'
      checkDraft:
        method: 'GET'
        url: '/api/v1/check_draft_api_v1_investor_relations_mass_email'
        transformResponse: (response) ->
          data = {}
          if response == "null"
            data.draft = false
          else
            data = JSON.parse(response)
          data
      deleteDraftAttachment:
        method: 'POST'
        url: '/api/v1/delete_draft_attachment_api_v1_investor_relations_mass_email'
        params:
          generic_file_id: '@genericFileId'
