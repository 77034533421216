angular.module('controllers').controller "InvestorAcknowledgementCtrl",
  ($scope, $window, $modal, $routeParams,
  Session, IcnIdentity, InvestorQualificationService, UserAcknowledgementService) ->
    $scope.header = 'Investor Acknowledgement'
    $scope.qualificationQuestions = []
    $scope.destination_url = $routeParams.destination_url
    user =
      isSFO: IcnIdentity.user.isSfo

    $scope.$on('$routeChangeStart', (event, next, current) ->
      unless next.originalPath == '/logout'
        event.preventDefault()
    )

    $scope.selected = {}

    $scope.disabledAgreeButton = ->
      $scope.qualificationQuestions.filter((question) -> question.checked).length == 0

    $scope.loadQualificationQuestions = ->
      InvestorQualificationService.getInvestorQualificationQuestions().then (response) ->
        $scope.qualificationQuestions = JSON.parse(response.data)
        if !$scope.qualificationQuestions.length
          Session.logout("/login")

    $scope.setQualificationQuestion = ($event, question) ->
      question.checked = $event.target.checked

    $scope.hasTitle = (question) ->
      return !_.isEmpty(question.title)

    $scope.accept = ->
      questionIds = $scope.qualificationQuestions.filter((question) -> question.checked).map((q) -> q.id)

      # Create user_acknowledgement and update investor qualification standards
      UserAcknowledgementService.create(
        questionIds
      ).then((response) ->
        if response.data.disabled
          qualificationChangedModal()
        else if !!$scope.destination_url
          $window.location.href = "#{$scope.destination_url}"
        else if !!IcnIdentity.user.default_page
          $window.location.href = "#{IcnIdentity.user.default_page}"
        else
          $window.location.reload()
      )

    $scope.$on('modal.closing', (event, reason) ->
      event.preventDefault() unless reason == 'lock_account'
    )

    init = ->
      $scope.loadQualificationQuestions()

    $scope.acknowledgementInfo = ->
      info = 'By clicking "I Agree" you are accepting and ' +
             'warranting to iCapital and the Fund that '

      if user.isSFO
        info += 'the Single-Family Office is '
      else
        info += 'you are '

      info += 'all that apply below. Please take this time to review the information ' +
              'you provide to iCapital and ensure it is current and complete.'

      info

    qualificationChangedModal = ->
      opts =
        templateUrl: "dialogs/custom_notify_dialog/custom_notify_dialog.html"
        controller: "customNotifyDialogCtrl"
        windowClass: "small-modal"
        resolve:
          header: ->
            ""
          msg: ->
            "Your qualification has changed. Please contact support at support@icapitalnetwork.com"
          btn_label: -> ""
      $modal.open(opts).result.then ->
        Session.logout('/login')

    init()
