angular.module('services').factory "InvestmentBlocksService", ($modal, InvestmentService, IcnIdentity) ->
  queryParams = ""

  openInvestmentBlocksModal = (blocks, showAcceptanceSteps, privateAccessFund, callback) ->
    $modal.open(
      templateUrl: "components/investment_list/dialogs/investment_blocks_dialog/investment_blocks_dialog.html"
      controller: "InvestmentBlocksDialogCtrl"
      windowClass: "small-modal"
      resolve:
        blocks: -> blocks
        showAcceptanceSteps: -> showAcceptanceSteps
        privateAccessFund: -> privateAccessFund
        queryParams: -> queryParams
    ).result.then (blocks) ->
      callback(blocks)

  showBlocksModalIfNecessary = (request, showAcceptanceSteps, privateAccessFund, callback) ->
    request.then((data) ->
      isUbs = IcnIdentity.whiteLabelPartner()?.slug == 'ubs'
      ubsBlocks = ["minimum_add_on_amount", "minimum_investment_amount"]
      blocks = data.blocks
      if isUbs
        blocks = blocks.filter((block) ->
          ubsBlocks.includes(block.block_type)
        )

      if blocks.length > 0
        openInvestmentBlocksModal(blocks, showAcceptanceSteps, privateAccessFund, callback)
      else
        callback([])
    )

  showBlocksForInvestmentModalIfNecessary = (investment, paf, callback) ->
    return callback([]) if !!investment.transfer_investment_id

    params =
      private_access_fund_id: paf.id
      wlp_id: investment.white_label_partner_id || IcnIdentity.data.white_label_partner?.id
      investment_id: investment.id
      ria_id: investment.ria.id
      investor_profile_id: investment.investor_profile_id
      commitment: investment.amount

    showBlocksModalIfNecessary(InvestmentService.fetchBlocksForInvestment(params), true, paf, (block_types) ->
      InvestmentService.acceptSoftBlocksForInvestment(investment.id, block_types).then(callback)
    )

  showBlocksPreInvestmentModalIfNecessary = (investment_params, paf, callback) ->
    return callback([]) if !!investment_params.transfer_investment_id

    params =
      private_access_fund_id: paf.id
      wlp_id: investment_params.wlp_id  || IcnIdentity.data.white_label_partner?.id
      ria_id: investment_params.ria_id
      investor_profile_id: investment_params.investor_profile_id
      sub_book_id: investment_params.sub_book_id
      attestation: investment_params.attestation
      commitment: investment_params.amount
      user_paf_exception_id: investment_params.user_paf_exception_id

    showBlocksModalIfNecessary(InvestmentService.fetchBlocksForInvestment(params), false, paf, callback)

  showBlocksForAddOnInvestmentModalIfNecessary = (investment, paf, amount, callback) ->
    params =
      private_access_fund_id: paf.id
      wlp_id: investment.white_label_partner_id  || IcnIdentity.data.white_label_partner?.id
      ria_id: investment.ria.id
      investor_profile_id: investment.investor_profile_id
      commitment: parseInt(amount || 0)
      initial_investment_id: investment.id

    showBlocksModalIfNecessary(InvestmentService.fetchBlocksForInvestment(params), true, paf, callback)

  setQueryParams = (_queryParams) -> queryParams = _queryParams

  return {
    showBlocksForInvestmentModalIfNecessary: showBlocksForInvestmentModalIfNecessary
    showBlocksPreInvestmentModalIfNecessary: showBlocksPreInvestmentModalIfNecessary
    showBlocksForAddOnInvestmentModalIfNecessary: showBlocksForAddOnInvestmentModalIfNecessary
    setQueryParams: setQueryParams
  }
