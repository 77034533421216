angular.module('directives').directive "icnProgressBar", ($timeout) ->
  restrict: "E"
  scope:
    percent: "@"
    overflow: "@"
    target: "@"
  templateUrl: "templates/directives/progress-bar.html"
  link: (scope, elem, attrs) ->
    el = elem
    scope.$watch "percent", (newValue, oldValue) ->
      return  if _(newValue).isEmpty()
      targetMeter = el.find(".target_meter")
      targetAmount = el.find(".target_amount")
      line1 = el.find(".line_1")
      totalWidth = elem.parent().width()
      filled100 = totalWidth * 0.666 # leave 30% for overflow
      fillArea = filled100 * scope.percent
      text = el.find(".percent_complete")
      targetMeter.css "left", Math.round(filled100) + "px"
      targetAmount.css "left", Math.round(filled100 + 6) + "px"
      fillArea = filled100 * 1.5  if scope.percent > 1.5
      $timeout (->
        line1.animate
          width: fillArea + "px"
        ,
          duration: 1000
          complete: ->
            str = Math.round(scope.percent * 100) + "%"
            text.hide().html(str).fadeIn "slow"
      ), 1500
