angular.module "icn.paf_admin.components"
  .directive 'investorCell', ->
    restrict: 'E'
    templateUrl: 'areas/paf_admin/components/investor_cell/investor_cell.html'
    scope:
      investment: '='
      privateAccessFund: '=?'
    controller: ($scope) ->
      if $scope.investment
        $scope.investmentId = $scope.investment.id
        $scope.seriesName = $scope.investment.series_name
        $scope.firstName = $scope.investment.contact_card.first_name
        $scope.lastName = $scope.investment.contact_card.last_name
      $scope.allocations = []

      $scope.fundAllocationsBySeries = ->
        investment_series_id = $scope.investment.private_access_fund_series_id
        fund_series = $scope.privateAccessFund.series
        investment_series = _.find fund_series, (series) ->
          series.id == investment_series_id
        underlying_fund_allocations = investment_series?.allocations
        _.each underlying_fund_allocations, (allocation) ->
          fund_allocation = {}
          fund_allocation.fund_name = allocation.manager
          fund_allocation.amount = allocation.allocation_percentage * $scope.investment.commitment
          $scope.allocations.push(fund_allocation)

      init = ->
        $scope.fundAllocationsBySeries() if $scope.privateAccessFund && $scope.privateAccessFund.series.length > 0

      init()
