(() => {
  angular.module('directives').directive('icnMultiselect', ($modal) => ({
    restrict: 'E',
    templateUrl: 'directives/icn_multiselect/icn_multiselect.html',

    scope: {
      label: '@',
      action: '=',
      options: '=',
      optionKey: '@',
      optionLabel: '@',
      optionDisabledLabel: '@',
      hasDisabled: '@',
      width: '@',
      dropdownClass: '@',
      selected: '=',
      selectedModel: '@',
      selectorStatusSuffix: '@',
      selectedText: '@',
    },

    controller($scope, $element) {
      $scope.action = $scope.action || {};
      $scope.optionKey = $scope.optionKey || 'key';
      $scope.optionLabel = $scope.optionLabel || 'label';
      $scope.selected = $scope.selected || [];
      $scope.selectedModel = $scope.selectedModel || {};

      $scope.hasAction =
        $scope.action && $scope.action.label && $scope.action.callback;

      $scope.optionDisabledLabel = $scope.optionDisabledLabel || 'disabled';
      $scope.hasDisabled = $scope.hasDisabled == 'true' || false;

      const selectorStatus = {
        noneSelected: 0,
        allSelected: 1,
        someSelected: 2,
      };

      const initialize = () => {
        $scope.$watch('selected', onSelectedChange, true);

        $scope.selected.forEach((key) => {
          $scope.selectedModel[key] = true;
        });

        $(document).click(function (event) {
          const isClickedElementChildOfDropdown =
            $element.find(event.target).length > 0;

          if (!isClickedElementChildOfDropdown) {
            $scope.$apply(() => ($scope.dropdownClass = ''));
          }
        });
      };

      $scope.handleToggleDropdown = ($event) =>
        ($scope.dropdownClass = $scope.dropdownClass === 'open' ? '' : 'open');

      $scope.handleItemSelection = (item) => {
        const itemKey = item[$scope.optionKey];

        if (keySelected(itemKey)) {
          const index = $scope.selected.indexOf(itemKey);
          $scope.selected.splice(index, 1);
        } else {
          $scope.selected.push(itemKey);
        }
      };

      $scope.handleSelectorStatusClick = ($event) => {
        if (calculateSelectorStatus() != selectorStatus.allSelected) {
          let unselectedKeys = _.difference(
            $scope.options.map((option) => option[$scope.optionKey]),
            $scope.selected
          );

          if ($scope.hasDisabled) {
            let unselectedKeysWithoutDisabled = [];
            unselectedKeys.forEach((unselectedKey) => {
              const item = $scope.options.find(
                (option) => option[$scope.optionKey] == unselectedKey
              );
              if (!item[$scope.optionDisabledLabel]) {
                unselectedKeysWithoutDisabled.push(item[$scope.optionKey]);
              }
            });
            unselectedKeys = unselectedKeysWithoutDisabled;
          }

          $scope.selected.push.apply($scope.selected, unselectedKeys);
          unselectedKeys.forEach((key) => ($scope.selectedModel[key] = true));
        } else {
          let selectedDisabledKeys = [];
          if ($scope.hasDisabled) {
            selectedDisabledKeys = $scope.options
              .filter(
                (option) =>
                  option[$scope.optionDisabledLabel] &&
                  $scope.selected.includes(option[$scope.optionKey])
              )
              .map((item) => item[$scope.optionKey]);
          }

          $scope.selected.splice(
            0,
            $scope.selected.length,
            ...selectedDisabledKeys
          );
          _.keys($scope.selectedModel).forEach(
            (key) => delete $scope.selectedModel[key]
          );
          selectedDisabledKeys.forEach((key) => {
            $scope.selectedModel[key] = true;
          });
        }
      };

      $scope.isItemDisabled = (item) => {
        if ($scope.hasDisabled) {
          return item[$scope.optionDisabledLabel];
        }
        return false;
      };

      const onSelectedChange = () => {
        $scope.selectorStatusSuffix = fetchSelectorStatusSuffix();
      };

      const keySelected = (key) => $scope.selected.includes(key);

      const fetchSelectorStatusSuffix = () => {
        switch (calculateSelectorStatus()) {
          case selectorStatus.allSelected:
            return 'all-selected';
            break;
          case selectorStatus.someSelected:
            return 'some-selected';
            break;
          default:
            return 'none-selected';
            break;
        }
      };

      const calculateSelectorStatus = () => {
        const optionsLength = $scope.options.length;
        const selectedLength = $scope.selected.length;

        if (selectedLength == 0) {
          return selectorStatus.noneSelected;
        }

        if (optionsLength == selectedLength) {
          return selectorStatus.allSelected;
        }

        return selectorStatus.someSelected;
      };

      initialize();
    },
  }));
}).call(this);
