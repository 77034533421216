angular.module('directives').directive "hoverTooltip", ->
  template: "<ng-transclude ng-show='active'></ng-transclude>" +
    "<img class='tooltip-icon' ng-src='{{ active ? activeIcon : regularIcon }}' " +
    "ng-mouseenter='active = true' " +
    "ng-mouseleave='active = false' class='tooltip-icon'>"
  transclude: true
  controller: ($scope) ->
    $scope.active = false
    $scope.regularIcon = "/images/tooltip_icon_white.svg"
    $scope.activeIcon = "/images/tooltip_icon_white_active.svg"
