angular.module("icn.paf_admin").factory "Investment", ($q, PafAdminSvcApi, IcnBase, InvestmentService, IcnUtility) ->
  class Investment
    constructor: (config) ->
      IcnBase.setup this, config

    toCsv: ->
      "#{@contact_card.first_name} #{@contact_card.last_name},#{@contact_card.email},#{@investor_name},#{@private_access_fund_id}," +
        "#{@contact_card.id},#{@id},#{@commitment}"

    valuesCloseEnough = (v1, v2) ->
      Math.floor(v1 * 100) == Math.floor(v2 * 100)

    isReconciled: ->
      cc = @capitalCall

      @thirdPartyCapitalCall && cc &&
      valuesCloseEnough(@thirdPartyCapitalCall.total_commitment, @commitment) &&
      valuesCloseEnough(@thirdPartyCapitalCall.current_capital_call, cc.amount) &&
      valuesCloseEnough(@thirdPartyCapitalCall.contributions_after_call, cc.amountInvestedAfterCall) &&
      valuesCloseEnough(@thirdPartyCapitalCall.contributed_after_call, cc.percentageInvestedAfterCall) &&
      valuesCloseEnough(@thirdPartyCapitalCall.remaining_commitment_after_call, cc.remainingCommitmentAfterCall)

    isInAttentionRequired: ->
      this.approval_status in ['Reverted', 'Auto-Reverted Investment']

    isInPreApproval: ->
      this.approval_status ==  'Pre-Approval'

    isInPreApprovalSecondary: ->
      this.approval_status ==  'Pre-Approval 2'

    isInInProcess: ->
      this.approval_status ==  'Pending Review'

    isInSuitabilityReview: ->
      this.approval_status ==  'External Review'

    isInSuitabilitySecondaryReview: ->
      this.approval_status ==  'External Review 2'

    isInDocumentReview: ->
      this.approval_status == 'IR Review'

    isInLegalReview: ->
      this.approval_status ==  'Compliance Review'

    isInApproved: ->
      this.approval_status == 'Approved'

    isInAccepted: ->
      this.approval_status == 'Accepted'

  Investment.fetch = (privateAccessFund) ->
    dfd = $q.defer()
    PafAdminSvcApi.getInvestmentsDetail(privateAccessFund).then (investments) ->
      dfd.resolve _.map investments, (investment) -> new Investment(investment)

    dfd.promise


  Investment
