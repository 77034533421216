angular.module('services').factory "Redirects", ($rootScope, $location, IcnIdentity, RoutesUtilService) ->
  savedPath = ""

  getSavedPath = ->
    savedPath

  setSavedPath = (path) ->
    savedPath = path

  redirectPath = ->
    if RoutesUtilService.registrationRefreshEnabled() && $location.path() == '/register'
      return '/login'

    if loggedIn()
      if RoutesUtilService.onLoggedOutOnlyPage() || RoutesUtilService.impersonationBlocked()
        return IcnIdentity.defaultPage()
      else if IcnIdentity.pendingMfaRegistration()
        unless RoutesUtilService.onMfaRegistrationPage() || RoutesUtilService.onPublicPage()
          return if IcnIdentity.user.phone then "/mfa_verify" else "/mfa_register"

    unless loggedIn()
      if $location.search().reset_password_token?
        return "/reset_password"
      else unless RoutesUtilService.onPublicPage()
        if IcnIdentity.loginDestination() != "/login"
          return IcnIdentity.loginDestination()
        else
          return "/login"
      else
        return false

    if $location.path() == "/"
      return IcnIdentity.defaultPage()

    false

  loggedIn = ->
    IcnIdentity.authenticated()

  redirectIfNecessary = ->
    path = redirectPath()
    return unless path
    return if path == $location.path()
    if (IcnIdentity.loginDestination() != "/login" && path == IcnIdentity.loginDestination()) ||
    $location.path() == "/api/v1/confirmation" || path.includes('/icn_react/static/register')
      window.location.href = path
      return
    currentPath = $location.url()
    referral = currentPath unless currentPath == "/" || currentPath == "/dashboard";

    $location
      .path(path)
      .search("referral", referral)
      .replace()

  redirect = (defaultPath) ->
    path = ensureRelative(redirectPath())
    if path is false
      $location.href ensureRelative(defaultPath)
    else
      $location.href path
    return

  return {
    redirectIfNecessary: redirectIfNecessary
    redirectPath: redirectPath
    redirect: redirect
    setSavedPath: setSavedPath
    getSavedPath: getSavedPath
  }
