angular.module('services').factory "TeamManagementService", ($resource, $http, $q) ->
  service =
    getAssignableUsers: (params) ->
      $http({
        url: "/api/v1/team_management/referral_codes/assignable_users",
        method: "GET",
        params: params
      })

    validateCreateTeam: (data) ->
      $http({
        url: "/api/v1/team_management/referral_codes/validate",
        method: "POST",
        data: data
      })
    
    createTeam: (data) ->
      $http({
        url: "/api/v1/team_management/referral_codes",
        method: "POST",
        data: data
      })

  return service
