angular.module('controllers').controller "InternalNotesModalCtrl",
  ($scope, $modalInstance, $routeParams, investment, header) ->
    $scope.header = header

    $scope.$watch "investment", ->
      if (investment && investment.id)
        $scope.investment = investment

    $scope.dismissModal = ->
      $modalInstance.dismiss()
