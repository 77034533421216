angular.module('services').factory "InvestorRepresentativeService", ($resource, $http, $q) ->
  service =
    getInvestors: (user_id) ->
      $http({
        url: "/api/v1/investor_representative_requests/investors",
        method: "GET",
        params: { investor_representative_id: user_id }
      });

    getRepresentatives: (user_id) ->
      $http({
        url: "/api/v1/investor_representative_requests/managers",
        method: "GET",
        params: { investor_id: user_id, all_requests: true }
      });

  return service
