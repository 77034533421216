angular.module('directives').directive "amlDocumentList", ->
  restrict: "E"
  scope:
    investment: "="
  templateUrl: "components/aml_document_list/aml_document_list.html"
  controller:
    ($scope, Session, $window, RequiredDocument, ngDialog, $modal, $dialogs) ->

      init = ->
        RequiredDocument.combinedForInvestment($scope.investment.id).then (docs) ->
          $scope.documents = docs

      currentUser = () ->
        Session.requestCurrentUser()

      $scope.canView = (document) ->
        document.has_file

      $scope.viewDocument = (document) ->
        return unless $scope.canView(document)
        RequiredDocument.view(
          id: document.id
        ).$promise.then (res) ->
          $window.open res.url
        return

      $scope.selectAmlDocumentType = ->
        $modal.open(
          templateUrl: "components/aml_document_list/dialogs/select_required_document_type/select_required_document_type.html"
          controller: "SelectRequiredDocumentTypeCtrl"
          windowClass: "small-modal"
          resolve:
            investment: -> $scope.investment
            isFromAddDocumentModal: -> false
        ).result.then(
          (aml_document) ->
            $scope.requestFailure = ""
            $scope.documents.push aml_document
        )

      # Dialog Methods

      $scope.cancelRefuseDialog = ->
        $scope.dialog_message = null
        ngDialog.close()

      init()
