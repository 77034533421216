angular.module('services').factory "AgreementEmbeddedExperience",
  ($resource, $q, $upload, urlBuilder) ->
    handlePreview = (privateAccessFundId, investmentId, agreementId, currentPageName) ->
      addedArguments = "agreement_id=#{agreementId}&investment_id=#{investmentId}&preview=true"
      signingUrl(privateAccessFundId, currentPageName, addedArguments)

    handlePreparation = (privateAccessFundId, requiredDocumentId, signMethod, currentPageName) ->
      addedArguments = "preparation=true&manual_agreement=#{signMethod == "manual"}&" +
        "required_document_id=#{requiredDocumentId}"
      signingUrl(privateAccessFundId, currentPageName, addedArguments)

    handleManualGeneration = (privateAccessFundId, requiredDocumentId, currentPageName) ->
      addedArguments = "manual_agreement=true&required_document_id=#{requiredDocumentId}"
      signingUrl(privateAccessFundId, currentPageName, addedArguments)

    handleSigningUrl = (privateAccessFundId, requiredDocumentId, currentPageName) ->
      addedArguments = "required_document_id=#{requiredDocumentId}"
      signingUrl(privateAccessFundId, currentPageName, addedArguments)

    signingUrl = (privateAccessFundId, currentPageName, addedArguments = null) ->
      queryParams = "paf_id=#{privateAccessFundId}&from=#{currentPageName}"
      queryParams = "#{queryParams}&#{addedArguments}" if addedArguments

      urlBuilder.reactUrl("/agreement_signing?#{queryParams}")

    return (
      handlePreview: handlePreview
      handlePreparation: handlePreparation
      handleSigningUrl: handleSigningUrl
      handleManualGeneration: handleManualGeneration
    )
