angular.module('controllers').controller 'UserManagementReverseSolicitationCtrl',
  ($scope, $http, $modal, FullWidth) ->
    $scope.orderByField = 'fund_name'

    loadExceptionList = (page) ->
      $http.get('/api/v1/user_paf_exceptions')
      .then (result) ->
        response = result.data
        angular.forEach(response, (value, index) ->
          created_by_name_and_email = value.created_by.split('$%#^@&')
          response[index].creator_name = created_by_name_and_email[0]
          response[index].creator_email = created_by_name_and_email[1]
        )
        $scope.whiteListExceptions = result.data

    loadExceptionList()

    $scope.addNewException = ->
      opts =
        templateUrl: "dialogs/add_reverse_solicitation_dialog/add_reverse_solicitation_dialog.html"
        controller: "AddReverseSolicitationDialogCtrl"
        windowClass: 'add_reverse_solicitation_dialog'

      $modal.open(opts).result.then(
        (result) ->
          if result == 'success'
            loadExceptionList()
      )

    $scope.deleteUnusedExceptions = ->
      opts =
        templateUrl: "dialogs/delete_unused_reverse_solicitation_dialog/delete_unused_reverse_solicitation_dialog.html"
        controller: "DeleteUnusedReverseSolicitationDialogCtrl"
        windowClass: 'delete_unused_reverse_solicitation_dialog'

      $modal.open(opts).result.then(
        (result) ->
          if result == 'success'
            loadExceptionList()
      )

    # table order
    $scope.orderBy = (field) ->
      $scope.orderByField = field
      $scope.reverseSort = !$scope.reverseSort

    FullWidth.useFullWidthScreen()

    $scope.$on '$destroy', ->
      FullWidth.removeFullWidthScreen()
