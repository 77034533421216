angular.module('services').factory 'TrackingService', ($http, $cookies) ->
  clickLink = (page, link) ->
    if window.Munchkin
      Munchkin.munchkinFunction 'clickLink', 'href': window.location.pathname + '/' + link

  investmentAction = (investment, action) ->
    if investment
      if window.Munchkin
        Munchkin.munchkinFunction 'clickLink', 'href': window.location.pathname + '/' + investment.id + '/' + action

  fundAction = (fund, action) ->
    if fund
      if window.Munchkin
        Munchkin.munchkinFunction 'clickLink', 'href': window.location.pathname + '/' + action

  pushEvent = (eventName) ->
    if window.Munchkin
      Munchkin.munchkinFunction 'clickLink', 'href': eventName

  clickLink: clickLink
  investmentAction: investmentAction
  pushEvent: pushEvent
  fundAction: fundAction
