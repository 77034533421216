angular.module('filters').filter "noFractionCurrency",
  ($filter, $locale) ->
    currencyFilter = $filter("currency")
    formats = $locale.NUMBER_FORMATS
    return (amount, currencySymbol) ->
      return if !amount && amount != 0
      cents = Math.abs((amount%1).toFixed(2))
      if cents > 0.49
        if amount > 0
          amount = amount + 1
        else
          amount = amount - 1
      value = currencyFilter(amount, currencySymbol)
      sep = value.indexOf(formats.DECIMAL_SEP)
      return value.substring(0, sep)  if amount >= 0
      value.substring(0, sep) + ")"
