angular.module('controllers').controller 'NotConfirmedCtrl', ($scope, $routeParams, $http, $modal) ->
  $scope.email = $routeParams.email

  $scope.resendConfirmationEmail = ->
    $http.get("/api/v1/registration/resend_confirmation/",
      params:
        email: $scope.email
    )
    confirmPopup()
    return

  confirmPopup = ->
    opts =
      templateUrl: "dialogs/custom_notify_dialog/custom_notify_dialog.html"
      controller: "customNotifyDialogCtrl"
      windowClass: "small-modal"
      resolve:
        header: ->
          ""
        msg: ->
          "Your verification email has been resent"
        btn_label: -> ""
    $modal.open(opts)
