// Generated by CoffeeScript 2.4.1
(function() {
  angular.module('directives').directive("requiredDocumentUpload", function(
    AmlDocumentService,
    RequiredDocument,
    Close,
    IcnIdentity,
    $window,
    $modal,
    $routeParams,
    $sce,
    $location,
    ToolTips,
    InvestmentService,
    PlacementFeeRangeSelector,
    InvestmentBlocksService,
    Dialog,
    $q,
    urlBuilder,
    PrivateAccessFunds
  ) {
    return {
      restrict: "E",
      scope: {
        document: "=",
        userId: "=",
        currency: "=",
        removeRequiredDoc: "=",
        customDeletable: "=?",
        investorProfile: "=",
        reloadProfile: "=",
        loadDocuments: "=?",
        refresh: "=?",
        approval: "=?",
        close: "=?",
        paf: "=?",
        disableSubdocActions: "=?",
        investment: "=?",
        sequenceSignersRequired: "=?",
      },
      templateUrl: "components/investor_documents/components/required_document_upload/required_document_upload.html",
      link: function(scope) {
        var allowSignature;
        scope.fileType = function(doc) {
          return AmlDocumentService.getFileType(doc.file_name);
        };
        scope.action = function(document) {
          var action;
          action = 'upload';
          if (document.document_action === 'signature' && allowSignature(document.status)) {
            action = 'signature';
          }
          return action;
        };
        scope.openDocumentInstructions = function() {
          return $modal.open({
            templateUrl: 'components/investor_documents/components/required_document_upload/dialogs/document_instructions_dialog/document_instructions_dialog.html',
            controller: function($scope, $modalInstance, $sce, document) {
              $scope.document = document;
              $scope.instructions = $sce.trustAsHtml(document.required_document_type.instructions);
              return $scope.cancelDialog = function() {
                return $modalInstance.dismiss();
              };
            },
            windowClass: "small-modal",
            resolve: {
              document: function() {
                return scope.document;
              }
            }
          });
        };
        return allowSignature = function(status) {
          return status === 'signature_needed' || 'pending_esign' || 'required';
        };
      },
      controller: function($scope, $timeout, TrackingService) {
        var loadInvestment, acceptedFileUploadType, countersignatureIsFilled, docAvailable, manuallySigned, fileExists, init, openRequiredDocument, requiredDocumentIsFilled, returnFromDragLeave, returnFromDragOver, showOverwriteWarningDialog, toggleClickOrDnD;
        $scope.isSubscriptionDocument = function () {
          return $scope.document.required_document_type.name === 'Subscription Document';
        };
        $scope.isTaxDocument = function () {
          return $scope.document.required_document_type.name == 'Tax Document (W-9 or W-8)';
        };
        $scope.isTransferDocument = function () {
          return $scope.document.required_document_type.name == 'Transfer Document';
        };

        loadInvestment = function() {
          if(!$scope.investment && !$scope.isSubscriptionDocument()) {
            InvestmentService.show($scope.investmentId).then(function(res) {
              return $scope.investment = res;
            });
          }
        };
        init = function() {
          var ref;
          $scope.titleLimit = 32;
          $scope.toolTips = ToolTips;

          $scope.uploadStatus = $scope.isSubscriptionDocument() ? $scope.approval.sub_doc_status : $scope.document.status;
          $scope.errorMessage = $scope.document.message_for_user || 'Your document has been rejected. Please try again.';
          $scope.idString = ($scope.document.id || 'new').toString();
          if ((ref = $scope.document.required_document_type) != null ? ref.description : void 0) {
            $scope.docDescription = $sce.trustAsHtml($scope.document.required_document_type.description);
          }

          $scope.investmentId = $routeParams.investment_id;
          loadInvestment();
          return;
        };
        $scope.$watch("document", function() {
          return init();
        });
        $scope.showDestroyFile = function() {
          var ref, ref1;
          return IcnIdentity.check('canDestroyFile') && (((ref = $scope.investment) != null ? ref.add_on : void 0) === false) && ((ref1 = $scope.uploadStatus) === 'approved' || ref1 === 'uploaded' || ref1 === 'signed');
        };
        $scope.showDownloadLink = function() {
          return (manuallySigned() || docAvailable()) && (requiredDocumentIsFilled() || countersignatureIsFilled());
        };
        $scope.showCustomRemoveButton = function (status = $scope.uploadStatus) {
          return $scope.removeRequiredDoc !== void 0 && $scope.customDeletable !== void 0 && $scope.customDeletable(status, $scope.document);
        };
        docAvailable = function(status = $scope.uploadStatus) {
          return status === 'approved' || status === 'uploaded' || status === 'signed' || (status === 'reverted' && requiredDocumentIsFilled());
        };
        manuallySigned = function(status = $scope.uploadStatus) {
          return status === 'manual_signed';
        };
        $scope.documentSigned = function(status = $scope.uploadStatus) {
          return status === 'signed' || status === 'manual_signed';
        };
        requiredDocumentIsFilled = function(doc = $scope.document) {
          return doc.has_file;
        };
        countersignatureIsFilled = function(doc = $scope.document) {
          return doc.required_document_type.name === 'Counter Signature Document' && doc.status === 'signed' || doc.status === 'uploaded';
        };
        $scope.showKeepFileLink = function() {
          var investment = $scope.approval.investment || $scope.investment;

          if (!investment) return false;

          var investmentStatus = investment.approval_status || investment.approval_status_override;
          return (
            $scope.uploadStatus === 'reverted' &&
            $scope.approval != null &&
            requiredDocumentIsFilled() &&
            !['Auto-Reverted Investment', 'investment_auto_reverted'].includes(investmentStatus) &&
            (!$scope.approval.commitment_amount_has_change_request || !$scope.approval.commitment_amount_require_changes)
          );
        };
        $scope.resendable = function() {
          return $scope.uploadStatus === 'pending_esign' || $scope.uploadStatus === 'signed';
        };
        $scope.pendingSignature = function() {
          return $scope.uploadStatus === 'pending_esign' || ($scope.isSubscriptionDocument() && $scope.uploadStatus === 'sent');
        };
        $scope.esignable = function() {
          return $scope.document.esignable;
        };
        $scope.canViewInvestorProfiles = function() {
          return IcnIdentity.check('canViewInvestorProfiles');
        };
        $scope.showDescription = function() {
          return $scope.uploadStatus === 'signature_needed' || $scope.uploadStatus === 'required' || $scope.uploadStatus === 'pending_upload';
        };
        $scope.isCounterSignatureDocument = function() {
          return $scope.document.required_document_type.name === 'Counter Signature Document';
        };
        $scope.showManualCountersignDialog = function() {
          if (!($scope.close && $scope.paf)) {
            return;
          }
          return $modal.open({
            templateUrl: "components/investor_documents/components/required_document_upload/dialogs/download_countersign_document/download_countersign_document_dialog.html",
            controller: "DownloadCountersignDialog",
            windowClass: 'large-modal',
            resolve: {
              close: function() {
                return $scope.close;
              },
              privateAccessFund: function() {
                return $scope.paf;
              }
            }
          }).result.then(function(result) {
            if (result) {
              $scope.document = result.countersignature_document;
              $scope.uploadStatus = result.countersignature_document.status;
              return $scope.idString = $scope.document.id.toString();
            } else {
              $scope.errorMessage = "Agreement generation request failed";
              return $scope.esignFailure = true;
            }
          });
        };
        $scope.disableSendSubDoc = function(){
          if (IcnIdentity.isRia() || IcnIdentity.isQp() || IcnIdentity.isAi() || IcnIdentity.isBranchManager() || IcnIdentity.isSfo()) {
            return false;
          }
          return true;
        };

        const modalSize2FA = (investment) => (
          investment.require_2fa ? 'large-modal' : 'middle-modal'
        );
      $scope.generatingAgreement = function () {
        return $scope.document.status === 'generating_agreement';
      };

        const agreementSigningUrl = (investment, addedArguments) => {
          const baseUrl = "/agreement_signing"
          let queryParams = `paf_id=${investment.private_access_fund_id}&from=remediation_comments&investment_id=${investment.id}`

          if (investment.subscription_signature_process_id) {
            queryParams = `${queryParams}&id=${investment.subscription_signature_process_id}`
          } else {
            queryParams = `${queryParams}&agreement_id=${investment.echosign_agreement_id}&vendor=${investment.signature_vendor}`
          }

          if (addedArguments) {
            queryParams = `${queryParams}&${addedArguments}`
          }

          return $location.url (`${baseUrl}?${queryParams}`)
        }

        const handlePreparation = (investment) => {
          const addedArguments = `preparation=true&manual_agreement=${investment.sign_method == "manual"}`;

          return agreementSigningUrl(investment, addedArguments);
        }

        const handleManualAgreement = (investment) => {
          return agreementSigningUrl(investment, "manual_agreement=true");
        }

        const showSendSubscriptionDialog = (investment, resetSubscription, msg, placementFee, subAdvisoryFee) => {
          InvestmentBlocksService.showBlocksForInvestmentModalIfNecessary(investment, investment.private_access_fund, () => {
            return $modal.open({
              templateUrl: "components/investment_list/dialogs/send_subscription_dialog/send_subscription_dialog.html",
              controller: "sendSubscriptionDialogCtrl",
              windowClass: modalSize2FA(investment),
              resolve: {
                privateAccessFund: function () {
                  return investment.private_access_fund;
                },
                fundSignatureVendor: function () {
                  return investment.private_access_fund.signature_vendor;
                },
                attestation: function () {
                  return investment.private_access_fund.attestation;
                },
                investment: function () {
                  return investment;
                },
                placementFee: function () {
                  return placementFee;
                },
                subAdvisoryFee: function () {
                  return subAdvisoryFee;
                },
                resetSubscription: function () {
                  return resetSubscription;
                },
                msg: function () {
                  return msg;
                }
              }
            }).result.then(function (investment) {
              if (investment.used_preparer_workflow) {
                return handlePreparation(investment);
              } else if (investment.sign_method == 'manual' && investment.signature_vendor == 'docusign') {
                return handleManualAgreement(investment);
              } else {
                return $scope.refresh();
              }
            });
          });
        };

        const openPickSignersModal = () => {
          return $modal.open({
            templateUrl: "components/investment_list/dialogs/pick_signers_dialog/pick_signers_dialog.html",
            controller: "PickSignersDialogCtrl",
            windowClass: "small-modal",
            resolve: {
              investment: () => $scope.investment,
              configs: function() {
                return { next: false };
              }
            }
          });
        };

        $scope.openSendSubscriptionDialog = function(resetSubscription, msg = undefined) {
          if ($scope.disableSendSubDoc() || $scope.disableSubdocActions) {
            return;
          }

          return checkIfNeedsNewAgreementResponses().then(() => {
            if (IcnIdentity.user.canSendSubscription
              && $scope.sequenceSignersRequired
              && $scope.investment.available_signers.length > 1
            ) {
              return openPickSignersModal().result.then((signerIds) => {
                if (signerIds) {
                  InvestmentService.changeSigners($scope.investment.id, signerIds).then((updatedInvestment) => {
                    $scope.investment.first_recipient = updatedInvestment.first_recipient
                    $scope.investment.extra_recipient = updatedInvestment.extra_recipient
                    $scope.investment.extra_third_recipient = updatedInvestment.extra_third_recipient
                  });
                }
                return $scope.canSeeFeeDialogAndShowSendSubscriptionDialog(resetSubscription, msg)
              });
            } else {
              return $scope.canSeeFeeDialogAndShowSendSubscriptionDialog(resetSubscription, msg)
            }
          });
        };

        const isInvestorProfileIncomplete = () => {
          return !$scope.investment.investor_profile_completed;
        }

        const isSuplementalQuestionsIncomplete = () => {
          return $scope.investment.has_investment_supplemental_form &&
            !$scope.investment.investment_supplemental_responses_completed;
        }

        const editInvestorProfile = () => {
          let queryString = "&userId=" + $scope.investment.contact_card.user_id +
            `&private_access_fund_id=${$scope.investment.private_access_fund_id}` +
            `&paf_override=${$scope.investment.private_access_fund_id}` +
            `&investment_status=${$scope.investment.approval_status}` +
            `&investment_id=${$scope.investment.id}`
          if ($routeParams.from) {
            queryString += "&ffrom=" + $routeParams.from;
          }
          if ($scope.approval.commitment_amount_change_request_done) {
            urlBuilder.setCommitmentAmountParam($scope.investment.commitment);
            queryString += "&commitmentAmount=" + $scope.investment.commitment;
          }
          const url = urlBuilder.investorProfileUrl(
            $scope.investment.investor_profile_id,
            "1",
            queryString
          )

          $location.url(url)
        };

        const editSupplementalForm = () => {
          let queryString = "";
          if ($routeParams.from) {
            queryString += "&ffrom=" + $routeParams.from;
          }
          if ($scope.approval.commitment_amount_change_request_done) {
            urlBuilder.setCommitmentAmountParam($scope.investment.commitment);
            queryString += "&commitmentAmount=" + $scope.investment.commitment;
          }

          const url = urlBuilder.supplementalFormUrl(
            $scope.investment.id,
            $scope.investment.private_access_fund_id,
            queryString
          );

          $location.url(url)
        };

        const checkIfNeedsNewAgreementResponses = () => {
          let notify_text = "";
          if (isInvestorProfileIncomplete()) {
            const notice_text = "The Investor Profile must be completed before you can send " +
              "a subscription agreement.<br><br>" +
              "Click OK to proceed to Investor Profile.";
            return Dialog.openNotifyDialog("Investor Profile Incomplete", notice_text, true).result.then(() => {
              editInvestorProfile();
            });
          } else if (isSuplementalQuestionsIncomplete()) {
            notify_text = "The Supplemental Form must be completed before you can send a subscription agreement.<br><br>" +
              "Click OK to proceed to Supplemental Form.";
            return Dialog.openNotifyDialog("Supplemental Form Incomplete", notify_text, true).result.then(() => {
              editSupplementalForm();
            });
          } else {
            const dfd = $q.defer();
            InvestmentService.needsNewAgreementResponses($scope.investment.id).then(function (response) {
              if (response.new_investor_profile_version || response.new_supp_form_version) {
                let notify_title;
                if (response.new_investor_profile_version && response.new_supp_form_version) {
                  notify_text = "The Investor Profile Questionnaire and Supplemental Form have been updated. " +
                    "You must complete and resubmit them before you can send the subscription agreement.<br><br>" +
                    "Click OK to proceed to the Investor Profile.";
                  notify_title = "Investor Profile and Supplemental Form Update";
                } else if (response.new_investor_profile_version) {
                  notify_text = "The Investor Profile Questionnaire has been updated. " +
                    "You must complete it before you can send the subscription agreement.<br><br>" +
                    "Click OK to proceed to the Investor Profile.";
                  notify_title = "Investor Profile Update";
                } else {
                  notify_text = "The Supplemental Form has been updated. " +
                    "You must resubmit before you can send the subscription agreement.<br><br>" +
                    "Click OK to proceed to the Supplemental Form.";
                  notify_title = "Supplemental Form Update";
                }

                Dialog.openNotifyDialog(notify_title, notify_text, true).result.then(() => (
                  InvestmentService.cancelSubscription($scope.investment.id).then(() => {
                    if (response.new_investor_profile_version) {
                      editInvestorProfile();
                    } else {
                      editSupplementalForm();
                    }
                  })
                ));

                return dfd.reject();
              } else {
                return dfd.resolve();
              }
            });
            return dfd.promise;
          }
        };

        $scope.canSeeFeeDialogAndShowSendSubscriptionDialog = function(resetSubscription, msg) {
          if ($scope.canSeeFeeDialog()) {
            $scope.openFeeDialog($scope.investment).result.then(function({investment, placementFee, subAdvisoryFee}) {
              return showSendSubscriptionDialog(investment, resetSubscription, msg, placementFee, subAdvisoryFee)
            });
          }
          else return showSendSubscriptionDialog($scope.investment, resetSubscription, msg);
        };

        $scope.canSeeFeeDialog = function() {
          const firm_fee_agreement = $scope.investment.firm_fee_agreement
          if (firm_fee_agreement && firm_fee_agreement.firm_id == null && IcnIdentity.isIndividualInvestor()) {
            return false
          }
          return ($scope.hasPlacementFee() && $scope.investment.sub_doc_type_name == 'brokerage') ||
            $scope.hasSubAdvisoryFee()
        };

        $scope.hasPlacementFee = function() {
          return PlacementFeeRangeSelector.hasPlacementFee($scope.investment)
        };

        $scope.hasSubAdvisoryFee = function() {
          var firmFeeAgreement = $scope.investment.firm_fee_agreement;
          return firmFeeAgreement && (firmFeeAgreement.max_sub_advisory_fee || firmFeeAgreement.min_sub_advisory_fee)
        };

        $scope.openFeeDialog = function() {
          var firmFeeAgreement = $scope.investment.firm_fee_agreement;
          if (firmFeeAgreement && (firmFeeAgreement.max_sub_advisory_fee || firmFeeAgreement.min_sub_advisory_fee))
            return $scope.openSubAdvisoryFeeDialog()
          else
            return $scope.openPlacementFeeDialog()
        };

        $scope.openPlacementFeeDialog = function() {
          return $modal.open({
            templateUrl: "components/investment_list/dialogs/placement_fee_dialog/placement_fee_dialog.html",
            controller: "placementFeeDialogCtrl",
            windowClass: "small-modal",
            resolve: {
              investment: function () {
                return $scope.investment;
              },
              resetNetCommitment: function ()  {
                return false;
              },
              currency: function () {
                return $scope.currency;
              }
            }
          })
        };

        $scope.openSubAdvisoryFeeDialog = function() {
          return $modal.open({
            templateUrl: "components/investment_list/dialogs/sub_advisory_fee_dialog/sub_advisory_fee_dialog.html",
            controller: "subAdvisoryFeeDialogCtrl",
            windowClass: "small-modal",
            resolve: {
              investment: function () {
                return $scope.investment;
              },
            }
          })
        };

        $scope.clickUploadDoc = function() {
          if($scope.disableSubdocActions) {
            return;
          }
          return $modal.open({
            templateUrl: "dialogs/upload_doc_dialog/upload_doc_dialog.html",
            controller: "UploadDocDialogCtrl",
            windowClass: "small-modal",
            resolve: {
              header: function() {
                return "Upload Signed Subscription Agreement";
              },
              privateAccessFundId: function() {
                return $scope.investment.private_access_fund.id;
              },
              investment: function() {
                return $scope.investment;
              }
            }
          }).result.catch(function(cancel) {
            return !cancel && $scope.refresh();
          });
        };
        $scope.clickUploadTransferDoc = function() {
          return $modal.open({
            templateUrl: "dialogs/upload_transfer_doc_dialog/upload_transfer_doc_dialog.html",
            controller: "UploadTransferDocDialogCtrl",
            windowClass: "small-modal",
            resolve: {
              header: function() {
                return "Upload Signed Transfer Document";
              },
              privateAccessFundId: function() {
                return $scope.investment.private_access_fund_id;
              },
              transferInvestment: function() {
                return null; // at present there is no usage of transferInvestment in UploadTransferDocDialogCtrl
              },
              transfereeInvestment: function() {
                return $scope.investment;
              }
            }
          }).result.catch(function(cancel) {
            return !cancel && $scope.refresh();
          });
        };
        $scope.showCountersignDialog = function() {
          if (!($scope.close && $scope.paf)) {
            return;
          }
          return $modal.open({
            templateUrl: "components/investor_documents/components/required_document_upload/dialogs/send_countersign_document/send_countersign_document_dialog.html",
            controller: "SendCountersignDialog",
            windowClass: 'large-modal',
            resolve: {
              close: function() {
                return $scope.close;
              },
              privateAccessFund: function() {
                return $scope.paf;
              }
            }
          }).result.then(function(result) {
            if (result) {
              $scope.document = result.countersignature_document;
              $scope.uploadStatus = result.countersignature_document.status;
              return $scope.idString = $scope.document.id.toString();
            } else {
              $scope.errorMessage = "Agreement generation request failed";
              return $scope.esignFailure = true;
            }
          });
        };
        $scope.reSendForESignature = function() {
          return $scope.echosignClick(false);
        };
        $scope.echosignClick = function(initialRequest = true) {
          $scope.errorMessage = null;
          $scope.esignFailure = false;
          return $modal.open({
            templateUrl: "components/investor_documents/components/echosign/esign_modal.html",
            controller: "esignModalCtrl",
            windowClass: "small-modal",
            resolve: {
              investorProfile: function() {
                return $scope.investorProfile;
              },
              docId: function() {
                return $scope.idString;
              },
              initialRequest: function() {
                return initialRequest;
              },
              deletable: function() {
                return $scope.deletable();
              },
              manuallyAddedEmail: function() {
                return $scope.document.manually_added_signer_email;
              }
            }
          }).result.then(function(data) {
            if (data.investor_profile) {
              if ($scope.refresh != null) {
                return $scope.refresh();
              } else {
                return $scope.reloadProfile(data.investor_profile);
              }
            } else {
              $scope.errorMessage = "Agreement generation request failed";
              return $scope.esignFailure = true;
            }
          });
        };
        $scope.deletable = function() {
          if ($scope.uploadStatus === 'reverted' || $scope.removeRequiredDoc === void 0 || $scope.isCounterSignatureDocument()) {
            return false;
          }
          return $scope.document.status !== 'approved' && $scope.document.status !== 'accepted' && IcnIdentity.data.user.id === $scope.document.creator_id || IcnIdentity.isInvestorRelations() || IcnIdentity.isPafAdmin();
        };
        $scope.uploadDoc = function(files) {
          return $timeout((function() {
            return $scope.loading(files);
          }), 1000);
        };
        $scope.uploadCountersignatureDoc = function(files) {
          if (!$scope.close) {
            return;
          }
          if (files[0].type !== 'application/pdf') {
            $scope.errorMessage = "File must be a PDF";
            return;
          }
          $scope.uploadStatus = 'loading';
          return Close.uploadCounterSignature($scope.close.id, files).then(function(response) {
            $scope.document = response.data.countersignature_document;
            $scope.uploadStatus = 'complete';
            if ($scope.refresh != null) {
              $scope.refresh();
            }
            angular.element("div[id='upload-container-" + $scope.idString + "']").removeClass('upload-fade');
            $timeout((function() {
              return $scope.success();
            }), 2000);
            return function(error) {
              $scope.errorMessage = 'Your upload was unsuccessful. Please try again, or contact Investor Relations at 212-994-7333 for further assistance.';
              $scope.uploadStatus = 'rejected';
              angular.element("div[id='upload-container-" + $scope.idString + "']").removeClass('upload-fade');
              return $scope.fadeIn();
            };
          });
        };
        acceptedFileUploadType = function(fileType) {
          return $scope.document.required_document_type.supported_mime_types.indexOf(fileType) > -1;
        };
        $scope.acceptedFileExtensions = function() {
          return  $scope.document.required_document_type.supported_file_extensions;
        };
        fileExists = function(files) {
          return files !== void 0 && files[0] !== void 0;
        };
        $scope.uploadRequiredDoc = function(files) {
          if (fileExists(files) && !acceptedFileUploadType(files[0].type)) {
            $scope.errorMessage = "There was an error uploading your document. Please upload a file in one of the following formats: " + $scope.document.required_document_type.supported_file_extensions;
            $scope.uploadStatus = 'rejected';
            angular.element("div[id='upload-container-" + $scope.idString + "']").removeClass('upload-fade');
            $scope.fadeIn();
            return;
          }
          $scope.uploadStatus = 'loading';
          RequiredDocument.upload($scope.document.id, files, $scope.investmentId).then(function(response) {
            $scope.document.thumb_vertical_url = response.data.thumb_vertical_url;
            $scope.uploadStatus = 'complete';
            if ($scope.refresh != null) {
              $scope.refresh();
            }
            angular.element("div[id='upload-container-" + $scope.idString + "']").removeClass('upload-fade');
            return $timeout((function() {
              return $scope.success();
            }), 2000);
          }, function(error) {
            $scope.errorMessage = 'Your upload was unsuccessful. Please try again, or contact Investor Relations at 212-994-7333 for further assistance.';
            $scope.uploadStatus = 'rejected';
            angular.element("div[id='upload-container-" + $scope.idString + "']").removeClass('upload-fade');
            return $scope.fadeIn();
          });
          let document_type = $scope.document.required_document_type.name;
          if (/drivers license/i.test(document_type) || /identification document/i.test(document_type)) {
            TrackingService.fundAction($scope.paf, 'Click to upload Identification Document');
          }
          if (/w-9/i.test(document_type)) {
            return TrackingService.fundAction($scope.paf, 'Click to upload W-9');
          }
        };
        $scope.loading = function(files) {
          if(files.length === 0) {
            return;
          }

          if ($scope.isCounterSignatureDocument()) {
            return $scope.uploadCountersignatureDoc(files);
          }
          else if($scope.isSubscriptionDocument()) {
            return $scope.uploadSubscriptionDocument(files);
          }
          else {
            return $scope.uploadRequiredDoc(files);
          }
        };
        $scope.uploadSubscriptionDocument = function(files) {
          if (fileExists(files) && !acceptedFileUploadType(files[0].type)) {
            $scope.errorMessage = "Your upload was unsuccessful. " + "Please upload your document using one of the following file types: pdf, png, jpg, or jpeg.";
            $scope.uploadStatus = 'rejected';
            angular.element("div[id='upload-container-" + $scope.idString + "']").removeClass('upload-fade');
            $scope.fadeIn();
            return;
          }
          else {
            $scope.uploadStatus = 'loading';
            return InvestmentService.uploadUserDoc($scope.investment, files).then((response) => {
              $scope.uploadStatus = 'complete';

              if ($scope.refresh != null) {
                $scope.refresh();
              }
              angular.element("div[id='upload-container-" + $scope.idString + "']").removeClass('upload-fade');
              return $timeout((function() {
                return $scope.success();
              }), 2000);
            }, (error) => {
              $scope.errorMessage = 'Your upload was unsuccessful. Please try again, or contact Investor Relations at 212-994-7333 for further assistance.';
              $scope.uploadStatus = 'rejected';
              angular.element("div[id='upload-container-" + $scope.idString + "']").removeClass('upload-fade');
              return $scope.fadeIn();
            });
          }
        };
        $scope.viewDocument = function(document) {
            openRequiredDocument(document);
        };
        openRequiredDocument = function(document) {
          return RequiredDocument.view({
            id: document.id
          }).$promise.then(function(res) {
            return $window.open(res.url);
          });
        };
        $scope.keepRevertedDocument = function() {
          if($scope.isSubscriptionDocument() && $scope.disableSubdocActions) {
            return;
          }
          let status = $scope.document.esignable ? 'signed' : 'uploaded';
          let opts = {
            templateUrl: "areas/aml_approval/dialogs/keep_file_modal/keep_file_modal.html",
            controller: "keepFileModalController",
            windowClass: "small-modal",
            resolve: {
              header: function() {
                return "Comments";
              },
              body: function() {
                return "Please provide reason for keeping the existing file:";
              },
              approval: function() {
                return $scope.approval;
              },
              status: function() {
                return status;
              }
            }
          };
          return $modal.open(opts).result.then(function(res) {
            return $scope.refresh();
          });
        };
        $scope.success = function() {
          $scope.uploadStatus = 'uploaded';
          return $scope.fadeIn;
        };
        $scope.fadeFileSelect = function() {
          angular.element("div[id='" + $scope.idString + "']").addClass('upload-fade');
          angular.element("div[id='upload-container-" + $scope.idString + "']").addClass('upload-fade');
          return angular.element("span[id='check-" + $scope.idString + "']").hide();
        };
        $scope.stopFade = function() {
          angular.element("div[id='" + $scope.idString + "']").removeClass('upload-fade');
          angular.element("div[id='upload-container-" + $scope.idString + "']").removeClass('upload-fade');
          return angular.element("span[id='check-" + $scope.idString + "']").show();
        };
        $scope.fullFade = function() {
          return angular.element("div[id='" + $scope.idString + "']").addClass('full-fade');
        };
        $scope.fadeIn = function(seconds = 0.5) {
          angular.element("div[id='" + $scope.idString + "']").addClass("transparent");
          return $timeout((function() {
            return angular.element("div[id='" + $scope.idString + "']").removeClass("transparent");
          }), seconds * 100);
        };
        $scope.canUpload = function() {
          if($scope.isSubscriptionDocument()) {
            return !$scope.disableSubdocActions;
          }
          else if($scope.isCounterSignatureDocument()) {
            return !$scope.close.merged_countersignature;
          }
          else {
            return true;
          }
        };
        $scope.picker = function() {
          if (!$scope.canUpload()) {
            return;
          }
          let el = document.getElementById($scope.idString);
          el = angular.element(el);
          if (el) {
            return showOverwriteWarningDialog(function() {
              return _.defer(function() {
                return el.click();
              });
            });
          }
        };
        showOverwriteWarningDialog = function(cb_confirm, cb_cancel = void 0) {
          if (!docAvailable()) {
            return cb_confirm();
          }
          let opts = {
            templateUrl: "dialogs/custom_confirm_dialog/custom_confirm.html",
            controller: "customConfirmDialogCtrl",
            windowClass: "small-modal",
            resolve: {
              header: function() {
                return "Overwrite Existing Document";
              },
              msg: function() {
                return "This action will overwrite the existing uploaded document. " + "Please confirm that you would like to proceed.";
              },
              confirm_label: function() {
                return "Confirm";
              },
              cancel_label: function() {
                return "Cancel";
              }
            }
          };
          return $modal.open(opts).result.then(function() {
            return cb_confirm();
          }).catch(function() {
            if (cb_cancel) {
              return cb_cancel();
            }
          });
        };
        $scope.showOverwriteWarningDialog = function() {
          let opts = {
            templateUrl: "dialogs/custom_confirm_dialog/custom_confirm.html",
            controller: "customConfirmDialogCtrl",
            windowClass: "small-modal",
            resolve: {
              header: function() {
                return "Overwrite Existing Document";
              },
              msg: function() {
                return "This action will overwrite the existing uploaded document. " + "Please confirm that you would like to proceed.";
              },
              confirm_label: function() {
                return "Confirm";
              },
              cancel_label: function() {
                return "Cancel";
              }
            }
          };
          return $modal.open(opts).result.then(function() {
            return $scope.picker();
          });
        };
        $scope.removeFile = function(doc) {
          let opts = {
            templateUrl: "dialogs/custom_confirm_dialog/custom_confirm.html",
            controller: "customConfirmDialogCtrl",
            windowClass: "small-modal",
            resolve: {
              header: function() {
                return "Delete Document";
              },
              msg: function() {
                return `Are you sure you would like to delete the ${doc.required_document_type.name}?`;
              },
              confirm_label: function() {
                return "Yes";
              },
              cancel_label: function() {
                return "Cancel";
              }
            }
          };
          return $modal.open(opts).result.then(function() {
            return RequiredDocument.destroy_file({
              id: doc.id,
              investment_id: $scope.investmentId
            }).$promise.then(function() {
              return $scope.loadDocuments();
            });
          });
        };
        $scope.removeRequirementFile = function(doc) {
          let opts = {
            templateUrl: "dialogs/custom_confirm_dialog/custom_confirm.html",
            controller: "customConfirmDialogCtrl",
            windowClass: "small-modal",
            resolve: {
              header: function() {
                return "Delete Document Requirement";
              },
              msg: function() {
                return $scope.showCustomRemoveButton() ? 'Are you sure you want to delete this document?' : `Are you sure you would like to delete the ${doc.required_document_type.name} requirement and the corresponding document approval?`;
              },
              confirm_label: function() {
                return "Yes";
              },
              cancel_label: function() {
                return "Cancel";
              }
            }
          };
          return $modal.open(opts).result.then(function() {
            return $scope.removeRequiredDoc(doc);
          });
        };
        // hack to make the document thumbnail and links both clickable and drag-and-dropable
        toggleClickOrDnD = function(allowClick) {
          if (!$scope.canUpload()) {
            return;
          }
          let zIdx = allowClick ? '1' : 'auto';
          let pos = allowClick ? 'relative' : 'static';
          let uploadBox = document.querySelector("#upload-container-" + $scope.idString);
          // hide/show thumbnail using z-index (cannot use positioning without messing up layout)
          let thumb = uploadBox.querySelector('.clickable.thumbnail');
          if (thumb) {
            thumb.style.zIndex = zIdx;
          }
          // hide/show links via positioning (cannot use z-index without links visually disapearing)
          let lis = uploadBox.getElementsByTagName('li');
          if (lis.length > 0) {
            let results = [];
            for (let i = 0, len = lis.length; i < len; i++) {
              let li = lis[i];
              results.push(li.style.position = pos);
            }
            return results;
          }
        };
        returnFromDragLeave = function() {
          $scope.stopFade();
          return toggleClickOrDnD(true);
        };
        returnFromDragOver = function() {
          $scope.fadeFileSelect();
          return toggleClickOrDnD(false);
        };
        $window.addEventListener('dragleave', function(e) {
          if (!$scope.canUpload()) {
            return;
          }
          e = e || event;
          if (e.target.id === "subdoc-file-" + $scope.idString || e.target.id === $scope.idString) {
            e.preventDefault();
            return returnFromDragLeave();
          }
        });
        $window.addEventListener('dragover', function(e) {
          if (!$scope.canUpload()) {
            return;
          }
          e = e || event;
          if (e.target.id === "subdoc-file-" + $scope.idString || e.target.id === $scope.idString) {
            e.preventDefault();
            return returnFromDragOver();
          }
        });
        return $window.addEventListener('drop', function(e) {
          if (!$scope.canUpload()) {
            return;
          }
          e = e || event;
          let files = e.dataTransfer.files;
          if (e.target.id === "subdoc-file-" + $scope.idString || e.target.id === $scope.idString) {
            e.preventDefault();
            $scope.fullFade();
            if (docAvailable()) {
              return showOverwriteWarningDialog(function() {
                return $scope.uploadDoc(files);
              }, returnFromDragLeave);
            } else {
              return $scope.uploadDoc(files);
            }
          }
        });
      }
    };
  });
}).call(this);
