// Generated by CoffeeScript 2.4.1
(function() {
  angular.module('directives').directive("signers", function() {
    return {
      restrict: "E",
      templateUrl: "components/investor_documents/components/echosign/signers.html",
      scope: {
        accountType: "@",
        signers: "=",
        setSigner: "=",
        hideSelectors: "="
      }
    };
  });

}).call(this);
