angular.module('directives').directive 'countryListDropdown', ($parse, $compile, IcnIdentity, InvestorProfileService) ->
  restrict: 'E'
  templateUrl: 'templates/directives/country_list_dropdown.html'
  priority: 100000,
  scope:
    fieldName: '@'
    investorProfile: '='
    viewableCountriesArray: '='
    submitted: '='
    errorText: '@'
    classNames: '@'
    defaultOption: '@'
    locked: '=?'
  link: (scope, elem) ->
    scope.$watch('viewableCountriesArray', (newValue, oldValue) ->
      updateSelected() if newValue
    )

    init = ->
      scope.isPafAdmin = IcnIdentity.isPafAdmin()
      scope.modelName = 'investorProfile.'.concat(scope.fieldName)
      updateSelected()

    updateSelected = ->
      scope.selected = _.find(scope.viewableCountriesArray, (c) ->
        c.name == scope.investorProfile[scope.fieldName]
      )

    scope.updateInvestorProfile = ->
      scope.investorProfile[scope.fieldName] = scope.selected.name

    scope.isUsEntityCountry = (fieldKey) ->
      scope.investorProfile[fieldKey] == 'United States of America'

    scope.mustComplete = InvestorProfileService.userMustCompleteProfile(IcnIdentity.user)

    scope.checkIfRequired = ->
      scope.mustComplete && !scope.$parent.checkFieldValidity(scope.modelName, scope.fieldName, scope.errorText)

    scope.isReadOnlyField = ->
      scope.$parent.isReadOnlyField(scope.fieldName) || scope.locked

    init()
