angular.module('controllers').controller 'assignReferralCodeCtrl', ($scope, $modal, $modalInstance, user, currentPrimaryId, ReferralCode) ->
  $scope.referralCode = {}
  $scope.headerText = "Assign Referral Code"

  $scope.assignReferralCode = ->
    ReferralCode.assignReferralCode($scope.referralCode.code, $scope.referralCode.primary, currentPrimaryId, user.id).$promise.then (response) ->
      $modalInstance.close(response)
    ,
      (response) ->
        $modalInstance.close(response)

  $scope.cancelDialog = ->
      $modalInstance.dismiss()

  $scope.invalidForm = ->
    !$scope.referralCode.code
