angular.module('directives').directive "fundAdministrationLegend", ->
  restrict: "E"
  templateUrl: "areas/common/fund_administration_legend.html"
  scope:
    legendData: '='
  controller:
    ($scope, $element, $attrs) ->
      $scope.keys = (obj) ->
        (if obj then Object.keys(obj) else [])

      $scope.isTextNote = (key) ->
        /^\(.*\)$/.test(key)
