angular.module('services').factory 'InvestmentPhoneNumbersService', ->
  class InvestmentPhoneNumbersService
    constructor: (investment, signersStruct, faFeature) ->
      this.investment = investment
      this.signersStruct = signersStruct
      this.signers = {
        first: investment.first_recipient,
        second: investment.extra_recipient,
        third: investment.extra_third_recipient
      }
      this.faFeature = faFeature
      this.require2fa = this.investment.require_2fa
      this.phoneNumberOptions = {}

      this.getSignersInfo() if this.require2fa

    getSignersInfo: ->
      this.getSignersPhoneNumberOptions(this.signers.first, 'firstSigner')
      this.getSignersPhoneNumberOptions(this.signers.second, 'secondSigner')
      this.getSignersPhoneNumberOptions(this.signers.third, 'thirdSigner')
      this.getSignersPhoneNumberOptions(this.investment.financial_advisor_info, 'financialAdvisor')

    selectCentralSignatory: (centralSignatory) ->
      this.selectSignatory(centralSignatory, 'centralSignatory')

    selectCustodianSignatory: (custodianSignatory) ->
      this.selectSignatory(custodianSignatory, 'custodianSignatory')

    selectSignatory: (signatory, signerKey) ->
      if signatory?.phone
        for_api = this.parseForApi(signatory.phone_iso)
        for_display = this.parseForDisplay(for_api, signatory)
        phone = { for_display: for_display, for_api: for_api }

        this.signersStruct[signerKey].phone = phone
        this.signersStruct[signerKey].phoneNumberOptions = [phone]
      else
        this.signersStruct[signerKey].phone = null
        this.signersStruct[signerKey].phoneNumberOptions = []

    changePhoneNumber: (signerKey, phone_data) ->
      for_api = this.parseForApi(phone_data.phone_iso)
      for_display = this.parseForDisplay(for_api, phone_data)
      phone = { for_display: for_display, for_api: for_api }
      this.signersStruct[signerKey].phone = phone

    signersPhoneNums: ->
      {
        primary_recipient_phone: this.phoneNumber(this.signersStruct.firstSigner.phone),
        extra_recipient_phone: this.phoneNumber(this.signersStruct.secondSigner.phone),
        extra_third_recipient_phone: this.phoneNumber(this.signersStruct.thirdSigner.phone),
        financial_advisor_phone: this.phoneNumber(this.signersStruct.financialAdvisor?.phone),
        central_signatory_phone: this.phoneNumber(this.signersStruct.centralSignatory?.phone),
        custodian_signatory_phone: this.phoneNumber(this.signersStruct.custodianSignatory?.phone)
      }

    phoneNumber: (signer_phone) ->
      if !!signer_phone then signer_phone.for_api else ''

    signerPhoneNumbersMissing: ->
      (this.signers.first && this.missingPhoneNumber('firstSigner')) ||
        (this.signers.second && this.missingPhoneNumber('secondSigner')) ||
        (this.signers.third && this.missingPhoneNumber('thirdSigner'))

    missingPhoneNumber: (signerKey) ->
      this.signersStruct[signerKey].phoneNumberOptions?.length == 0 &&
        !this.signersStruct[signerKey].phone

    showPhoneElement: (signerKey) ->
      this.require2fa && !this.signersStruct[signerKey].skipAuthentication

    signersPhoneNumberOptions: (signerKey) ->
      this.signersStruct[signerKey].phoneNumberOptions

    signerPhoneDisabled: (signerKey) ->
      options = this.signersStruct[signerKey].phoneNumberOptions
      !options || options.length <= 1

    signerPhoneShow: (signerKey) ->
      options = this.signersStruct[signerKey].phoneNumberOptions

      this.require2fa && !!options && options.length > 0

    getSignersPhoneNumberOptions: (recipientInfo, signerKey) ->
      return unless recipientInfo?.phone_nums?.length && !!this.signersStruct[signerKey]
      tempPhoneNums = []
      recipientInfo.phone_nums.forEach ((phoneNumberObject) ->
        for_api = this.parseForApi(phoneNumberObject.phone_iso)
        for_display = this.parseForDisplay(for_api, phoneNumberObject)

        temp = { for_display: for_display, for_api: for_api }
        tempPhoneNums.push(temp)
        if phoneNumberObject.primary
          this.signersStruct[signerKey].phone = temp
      ).bind(this)
      if !this.signersStruct[signerKey].phone && tempPhoneNums.length > 0
        this.signersStruct[signerKey].phone = tempPhoneNums[0]
      this.signersStruct[signerKey].phoneNumberOptions = tempPhoneNums

    parseForApi: (isoPhoneNumber) ->
      if isoPhoneNumber.charAt(0) == '+'
        isoPhoneNumber.slice(1)
      else
        isoPhoneNumber

    parseForDisplay: (isoPhoneNumber, phoneNumberObject) ->
      for_display = isoPhoneNumber
      if phoneNumberObject.area_code
        for_display = phoneNumberForDisplay(for_display, phoneNumberObject.area_code)
      if phoneNumberObject.label && phoneNumberObject.label.length > 0
        for_display = for_display + ' (' + phoneNumberObject.label + ')'
      for_display

    phoneNumberForDisplay = (isoPhoneNumber, areaCode) ->
      isoNumArray = isoPhoneNumber.split(areaCode)
      if isoNumArray.length >= 2
        prifixNum = isoNumArray.shift() + '-' + areaCode + '-'
        followingNum = isoNumArray.join(areaCode)
        return prifixNum + followingNum.slice(0, 3) + '-' + followingNum.slice(3)
      else
        isoPhoneNumber

  init = (investment, signersStruct, faFeature) ->
    new InvestmentPhoneNumbersService(investment, signersStruct, faFeature)

  return (
    init: init
  )
