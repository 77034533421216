angular.module('controllers').controller "TextInputDialogCtrl",
  ($scope, $modalInstance, prompt) ->
    $scope.prompt = prompt
    $scope.inputs = {}

    $scope.submit = ->
      $modalInstance.close($scope.inputs.text)

    $scope.cancel = ->
      $modalInstance.dismiss()
