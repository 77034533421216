angular.module('services').factory "IcnDocumentSvc", ($resource) ->
  resource = $resource "/api/v1/icn_documents/file_path/?:doctype", { doctype: "@doctype" },
    templateFile:
      url: '/api/v1/icn_documents/file_path/?doctype=:doctype'
      method: 'GET'
      transformResponse: (data) ->
        if data
          return file_path: JSON.parse(data).file.url

  uploadTemplate = (doctype) ->
    resource.templateFile(doctype: doctype)

  {
    uploadTemplateXLS: uploadTemplate
  }
