angular.module('directives').directive 'filterLock', ->
  templateUrl: 'areas/aml_approval/components/filter_lock/filter_lock.html'
  restrict: 'E'
  scope:
    'click': '&onClick'
    'filterLockKey': '='

  controller: ($scope, AmlFilterStorageService) ->

    $scope.isLocked = ->
      AmlFilterStorageService.isFilterSet($scope.filterLockKey) || false

    $scope.clicked = ->
      locked = !$scope.isLocked()
      AmlFilterStorageService.clearFilter($scope.filterLockKey) unless locked
      $scope.click({locked: locked})
