angular.module('directives').directive 'icnRegistration', (CustomPageTemplateService, AccountUtilities, TrackingService, TermsOfUseService) ->
  restrict: 'E'
  scope:
    user: '='
    onComplete: '&'
    customTemplate: '=?'
  templateUrl: (elem, attr) ->
    CustomPageTemplateService.getPageTemplateFromAttribute(
      'areas/register/register/components/icn_registration/templates/',
      attr.customTemplate,
      true
    )

  controller: ($scope, $modal, $location, IcnIdentity, $window, Register, ToolTips) ->
    $scope.serverValidations = {}
    $scope.showUserTypeDropDown = !$scope.user.qps_paf
    $scope.confirmPasswordFieldHasError = false
    $scope.toolTips = ToolTips
    $scope.showRegistrationSteps = !!$location.search().showSteps
    $scope.user.isPasswordInvalid = false
    $scope.enable_custom_wlp_page = false

    init = ->
      role = $scope.user.role
      $scope.hasRole = !!role
      $scope.hasEmail = !!$scope.user.email
      $scope.userTypes = Register.getAvailableRegistrationRoles(!$scope.hasRole, $scope.user.role)

      $scope.hasWlpTerms = false
      TermsOfUseService.get_main_site_active().then((response) ->
        $scope.user.main_terms_id = response.terms_of_use.id
      )

      if IcnIdentity.isWhiteLabel()
        TermsOfUseService.get_wlp_active().then((response) ->
          if response.terms_of_use
            $scope.hasWlpTerms = true
            $scope.user.wlp_terms_id = response.terms_of_use.id

            $scope.wlpName = IcnIdentity.whiteLabelPartner().name
            $scope.enable_custom_wlp_page = response.terms_of_use.enable_custom_wlp_page_and_popup
        )

    $scope.allCompleted = ->
      AccountUtilities.passwordRequirementsAllCompleted($scope.user.password, $scope.user.password_confirmation) && !$scope.user.isPasswordInvalid

    $scope.submitRegistration = ->
      $scope.oldPassword = $scope.user.password
      Register.save($scope.user).then((response) ->
        $scope.onComplete(user: response.user)
        TrackingService.clickLink('Registration Page', 'Click on Sign Up')
      , (error) ->
        if error.data.validations && _.keys(error.data.validations).length > 0
          $scope.serverValidations = error.data.validations
        else if AccountUtilities.invalidPasswordBreach(error.data.message) || AccountUtilities.invalidPasswordWords(error.data.message)
          $scope.serverValidations.passwordError = true
          $scope.serverValidations.invalidPasswordError = true
          $scope.user.isPasswordInvalid = true
        else if invalidEmailError(error.data.message)
          $scope.serverValidations.passwordError = true
          $scope.serverValidations.unknownError = true
        else
          $scope.serverValidations.unknownError = true
      )

    $scope.openQuestionnaireExample = (event) ->
      event.preventDefault()  unless typeof event is "undefined"
      $modal.open
        templateUrl: "areas/register/register/dialogs/questionnaire_example/questionnaire_example.html"
        windowClass: "large-modal"
        controller: "QuestionnaireExampleDialog"
      return

    invalidEmailError = (msg) ->
      return false if msg == null || msg == undefined
      msg.match(/Registration failed: Password must be/)

    $scope.passwordHasError = (password) ->
      if password != undefined && $scope.oldPassword != password.$viewValue && $scope.user.isPasswordInvalid
        $scope.user.isPasswordInvalid = false
      AccountUtilities.passwordHasError(password) || $scope.user.isPasswordInvalid

    $scope.confirmedPasswordHasError = (password, password_confirmation) ->
      $scope.confirmPasswordFieldHasError = AccountUtilities.confirmedPasswordHasError(password, password_confirmation)

    $scope.validateEmail = (email) ->
      AccountUtilities.validateEmail(email)

    $scope.mailFilled = (email) ->
      email.$viewValue && email.$viewValue != ''

    init()
