angular.module('services').factory(
  'ExternalAddOnPrompter', (InvestmentService, $modal) ->
    new: ({
      privateAccessFund = {},
      allowExternalAddOns = false
    }) ->
      dialog = (callback) ->
        externalAddOn = false

        $modal.open(
          templateUrl: 'dialogs/custom_confirm_dialog/custom_confirm.html'
          controller: 'customConfirmDialogCtrl'
          windowClass: 'small-modal'
          resolve:
            header: -> 'Additional Subscription'
            msg: ->
              """
                Please select "Yes" if the selected account has a previous
                investment in this fund. If "Yes" is selected, this investment
                will be treated as an Additional Subscription.
              """
            confirm_label: -> 'Yes'
            cancel_label: -> 'No'
        )
        .result
        .then -> externalAddOn = true
        .finally -> callback(externalAddOn)

      prompt = (callback, initialInvestmentParams = null, forProspect = null) ->
        return callback(false) unless allowExternalAddOns && !forProspect

        if initialInvestmentParams?
          InvestmentService.initialInvestmentPresent(
            privateAccessFund.id,
              investor_profile_id: initialInvestmentParams.investor_profile_id
              is_account: initialInvestmentParams.is_account
              sub_book_id: initialInvestmentParams.sub_book_id
              sub_doc_code: initialInvestmentParams.sub_doc_code
              ubs_object: initialInvestmentParams.ubs_object
              wlp_id: initialInvestmentParams.wlp_id
          ).then (response) ->
            return callback(false) if response.initial_investment_present

            dialog(callback)
        else
          dialog(callback)

      prompt: prompt,
      dialog: dialog
)
