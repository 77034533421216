angular.module('services').factory 'TopModalService', ($rootScope) ->
  _stack = []

  topModal = (obj) ->
    last = _stack[_stack.length - 1]
    last && last.name == obj.name && last.scope == obj.scope

  registerModal = (obj, callback) ->
    _stack.push obj
    $rootScope.$emit('modal-opening')

    $rootScope.$on('modal-closing', callback)
    $rootScope.$on('modal-opening', callback)
    
    obj.scope.$on('$destroy', ->
      remove(obj)
    )

  remove = (obj) ->
    _stack.splice(_.findIndex(_stack, obj), 1)
    $rootScope.$emit('modal-closing')

  {
    topModal: topModal
    registerModal: registerModal
  }
