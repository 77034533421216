angular.module('services').factory 'RiaClients', (Users, IcnIdentity) ->
  self = @
  _promises = {
    load: {},
    loadForWlp: {}
  }
  _users = undefined

  @load = (pafId, with_canceled = false, riaId = IcnIdentity.user.id) ->
    return _promises.load[riaId] if _promises.load[riaId]

    _promises.load[riaId] = Users.riaClients(riaId, pafId, with_canceled)

    _promises.load[riaId].then (resp) ->
      _users = resp

    return _promises.load[riaId]

  @loadForWlp = (pafId, with_canceled = false, wlpId, riaId = IcnIdentity.user.id) ->
    return _promises.loadForWlp[riaId] if _promises.loadForWlp[riaId]

    _promises.loadForWlp[riaId] = Users.riaClients(riaId, pafId, with_canceled, wlpId)

    _promises.loadForWlp[riaId].then (resp) ->
      _users = resp

    return _promises.loadForWlp[riaId]

  @users = ->
    return _users

  @flush = ->
    _promises = {
      load: {},
      loadForWlp: {}
    }
    _users = undefined

  return @
