angular.module('services').factory 'DisclaimerService', ($sce) ->

  _showDisclaimer = true
  _currentDisclaimer = {
    disclaimer: ""
  }

  showDisclaimer = () ->
    _showDisclaimer = true

  hideDisclaimer = () ->
    _showDisclaimer = false

  disclaimerVisible = () ->
    _showDisclaimer

  setDisclaimer = (htmlText) ->
    _currentDisclaimer.disclaimer = $sce.trustAsHtml(htmlText)

  return {
    showDisclaimer: showDisclaimer
    hideDisclaimer: hideDisclaimer
    disclaimerVisible: disclaimerVisible
    currentDisclaimer: _currentDisclaimer
    setDisclaimer: setDisclaimer
  }
