(() => {
  angular.module('controllers').controller('ApprovalHistoryExportDialogCtrl', (
    $scope,
    $modalInstance,
    $modal,
    ExportsService,
    header,
    selectedPaf
  ) => {
    $scope.header = header || '';
    $scope.selectedPaf = selectedPaf;
    $scope.exports = [];

    $scope.inProcessNotifier = () => (
      $modal.open({
        templateUrl: 'dialogs/custom_notify_dialog/custom_notify_dialog.html',
        controller: 'customNotifyDialogCtrl',
        windowClass: 'small-modal',
        resolve: {
          header: () => 'Your export is being generated...',
          msg: () => (
            'Your export is being processed. You will receive an email with download instructions ' +
            'once the export is complete. Alternatively, you can refresh this page.'
          ),
          btn_label: () => '',
        }
      })
    );

    $scope.export = () => (
      ExportsService.exportApprovalHistory($scope.selectedPaf.id).then(
        () => $scope.inProcessNotifier()
      )
    );

    $scope.cancel = () => $modalInstance.dismiss();

    return ExportsService.all($scope.selectedPaf.id).then(
      (response) => $scope.exports = response.exports
    );
  });
}).call(this);
