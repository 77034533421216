angular.module('controllers').controller "InvestmentsTableCtrl", ($scope) ->
  orderByNoDefaultSort = (field) ->
    $scope.orderByField = field
    $scope.reverseSort = !$scope.reverseSort

  orderByDefaultSort = (field) ->
    if field != $scope.orderByField
      $scope.orderByField = field
      $scope.reverseSort = false
    else
      $scope.reverseSort = !$scope.reverseSort
      $scope.orderByField = $scope.defaultOrderByField unless $scope.reverseSort

  $scope.orderBy = (field) ->
    orderByDefaultSort(field) if $scope.defaultOrderByField
    orderByNoDefaultSort(field) unless $scope.defaultOrderByField
