angular.module('directives').directive "investmentDetailPopover", ->
  restrict: "E"
  transclude: true
  scope:
    investment: "="
    paf: "="
    disabled: "="
  templateUrl: "templates/directives/investment_detail_popover.html"
  controller:
    ($scope, $element, $window) ->
