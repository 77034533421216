angular.module('controllers').controller 'TermsOfUseCtrl', ($scope, $routeParams, $location, IcnIdentity, TermsOfUseService, $sce) ->

  $scope.termsContent = '<h3>Loading Terms Content...</h3>'

  if $routeParams.id != undefined
    TermsOfUseService.get_wlp_active().then((val) -> setTermsOfUse(val))
  else
    TermsOfUseService.get_main_site_active().then((val) -> setTermsOfUse(val))

  setTermsOfUse = (val) ->
    if val.terms_of_use == undefined
      $location.path('/logout')
    else
      $scope.termsTitle = $sce.trustAsHtml(val.terms_of_use.terms_title ? 'iCapital Terms of Use')
      $scope.termsContent = $sce.trustAsHtml(val.terms_of_use.terms_content)
      $scope.updatedTime = moment(val.terms_of_use.date).format("MMM/Do/YYYY").split('/')
