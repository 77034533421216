angular.module('directives').directive "icnDatePicker", ->
  restrict: "E"
  templateUrl: "components/date_picker/icn_date_picker.html"
  require: "ngModel"
  scope:
    inputName: "="
    errors: "="
    validate: "&"
    ngModel: "="

  link: (scope, elem, attr, model) ->
    buildErrors = () ->
      scope.errors = {
        hasErrors: () ->
          @empty || @format || @validation
      }

    buildErrors()

    input = elem.find("input")

    $(input).keyup (ev) ->
      return true if ev.keyCode == 37 || ev.keyCode == 39
      if ev.keyCode == 27
        model.$render()
      buildErrors()
      regex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/
      match = @value.match(regex)
      if match
        date = new Date(parseInt(match[3]), parseInt(match[1]) - 1, parseInt(match[2]))
        model.$setViewValue date.toString()
        scope.errors.validation = true  if angular.isDefined(scope.validate()) and not scope.validate(date: date)
      else if @value is ""
        scope.errors.empty = true
      else
        scope.errors.format = true

      if scope.errors.hasErrors()
        $(input).parent().addClass "has-error"
        $(input).parent().removeClass "has-success"
      else
        $(input).parent().addClass "has-success"
        $(input).parent().removeClass "has-error"

      scope.$apply()
      return

    scope.inputFormName = ->
      if scope.inputName?
        scope.inputName
      else
        'my_date'

    scope.$watch 'ngModel', (wireDate) ->
      if wireDate
        regex = /\d{1,2} \d{4} \d{2}:\d{2}:\d{2} GMT/
        match = wireDate.toString().match(regex)
        if match
          model.$setViewValue wireDate.getMonth() + 1 + '/' + wireDate.getDate() + '/' + wireDate.getFullYear()
    return
