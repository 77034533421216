angular.module('directives').directive 'dateValidator', ->
  require: 'ngModel'
  link: ($scope, element, attrs, ngModel) ->
    ngModel.$validators.date = (value) ->
      date = ngModel.$viewValue
      moment_date = moment(date, attrs.datePattern)

      if ( _.isString(date)  && date.length > 0 && !moment_date.isValid())
        return false

      return true
