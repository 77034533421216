angular.module('services').factory "FundRoadshowService", ($resource) ->
  FundRoadshowService = ->
    @service = $resource("/api/v1/funds/:fundId/roadshows",
      fundId: "@fundId"
    )
    return

  FundRoadshowService::show = (fundId) ->
    @service.get fundId: fundId

  FundRoadshowService::local = ->
    id: 1
    title: "Test Roadshow (visible)"
    hidden: true
    chapters: [
      {
        id: 1
        video_id: "2117277076001"
        thumbnail_url: "http://brightcove.vo.llnwd.net/e1/pd/1405556719001/1405556719001_2117303166001_ScottNuttal4-3.jpg?pubId=1405556719001"
        title: "Firm Background"
        position: 1
      }
      {
        id: 2
        video_id: "2117277076001"
        thumbnail_url: "http://brightcove.vo.llnwd.net/e1/pd/1405556719001/1405556719001_2117303166001_ScottNuttal4-3.jpg?pubId=1405556719001"
        title: "Investment Sourcing"
        position: 2
      }
      {
        id: 3
        video_id: "2117277076001"
        thumbnail_url: "http://brightcove.vo.llnwd.net/e1/pd/1405556719001/1405556719001_2117303166001_ScottNuttal4-3.jpg?pubId=1405556719001"
        title: "Post Investment Value Creation"
        position: 3
      }
      {
        id: 4
        video_id: "2117277076001"
        thumbnail_url: "http://brightcove.vo.llnwd.net/e1/pd/1405556719001/1405556719001_2117303166001_ScottNuttal4-3.jpg?pubId=1405556719001"
        title: "Overall Investment Performance"
        position: 4
      }
      {
        id: 5
        video_id: "2117277076001"
        thumbnail_url: "http://brightcove.vo.llnwd.net/e1/pd/1405556719001/1405556719001_2117303166001_ScottNuttal4-3.jpg?pubId=1405556719001"
        title: "York Advantage"
        position: 5
      }
      {
        id: 6
        video_id: "2117277076001"
        thumbnail_url: "http://brightcove.vo.llnwd.net/e1/pd/1405556719001/1405556719001_2117303166001_ScottNuttal4-3.jpg?pubId=1405556719001"
        title: "Competition"
        position: 6
      }
    ]

  new FundRoadshowService
