angular.module('controllers').controller "setShareClassDialogCtrl",
 ($scope, $modalInstance, investment, InvestmentService, ShareClassSvc, options, initial, addOns) ->
   $scope.shareClasses = []
   $scope.investment = investment
   $scope.headerText = options.header
   $scope.selectedShareClassId = options.currentShareClass.id
   $scope.selectedShareClass = options.currentShareClass
   $scope.addOns = addOns
   $scope.initial = initial
   $scope.showSetShareClass = true

   loadShareClasses = ->
     ShareClassSvc.forPaf(paf_id: investment.private_access_fund_id).$promise.then((response) ->
       $scope.shareClasses = response.share_classes
       $scope.shareClasses.push { name: 'none' }
     )

   $scope.setSelectedShareClass = (scId) ->
     $scope.selectedShareClass = $scope.shareClasses.find (sc) ->
       sc.id == scId

   $scope.setShareClass = (scId) ->
     InvestmentService.setShareClass(investment.id, scId).then((response) ->
       $scope.changed_sc = 1 + $scope.addOns.length
       $scope.settedShareClass = response
     ).then( ->
       $scope.showSetShareClass = false
     ).catch((error) ->
       $scope.errorMessage =  error.data.message
     )

   $scope.closeModal = ->
     $modalInstance.close($scope.settedShareClass)

   $scope.totalCommitment = ->
     commitment = 0
     if $scope.initial
       commitment = $scope.initial.commitment

     totalAddOns = _.reduce $scope.addOns, (sum, addOn) ->
       sum + addOn.commitment
     , 0
     commitment + totalAddOns



   loadShareClasses()
