angular.module('controllers').controller "DashboardCtrl",
  ($scope, $filter, $modal, $sce, $routeParams, $http, $templateCache, CurrentUser, Firms, Funds,
  IcnIdentity, SiteContentService, ClientManagementService, AdvisorMarketingFields, ModalResponsabilityChainService) ->

    $scope.enableRegistrationMarketplace = IcnIdentity.featureFlagEnabled('enable_registration_marketplace')

    lpTemplate = "areas/dashboard/templates/dashboards/lp.html"
    qpTemplate = "areas/dashboard/templates/dashboards/lp.html"
    riaTemplate = "areas/dashboard/templates/dashboards/lp.html"
    aiTemplate = "areas/dashboard/templates/dashboards/lp.html"
    gpTemplate = "areas/dashboard/templates/dashboards/gp.html"
    qpclTemplate = "areas/dashboard/templates/dashboards/qpcl.html"
    adminTemplate = "areas/dashboard/templates/dashboards/admin.html"
    emptyTemplate = "areas/dashboard/templates/dashboards/empty.html"
    docMgrTemplate = "areas/dashboard/templates/dashboards/doc_mgr.html"

    $scope.onMktBannerClick = ->
      $http.post('/api/v1/hits', {
        key: 'view',
        hitable: 'AdvisorMarketingField',
        hitable_id: $scope.mktFields.id,
        note: "#{$scope.mktFields.banner_subject} - #{$scope.mktFields.banner_text}",
      })

    $scope.investmentOpportunitiesUrls = {
      privateEquityFunds: '/icn_react/static/investment_opportunities/params?category=Private%20Credit&category=Private%20Equity&category=Real%20Assets&sort_type=default&view_type=tiles',
      aiFunds: '/icn_react/static/investment_opportunities/params?investor_qualification=Accredited%20Investor&sort_type=default&view_type=tiles',
      hedgeFunds: '/icn_react/static/investment_opportunities/params?category=Hedge%20Fund&sort_type=default&view_type=tiles',
    }

    filterBlankOrPE = (firm_type) ->
      firm_type == null || firm_type == "" || firm_type.lastIndexOf('Private Equity', 0) == 0

    dashboardTemplateForUser = (user) ->
      ClientManagementService.setPafId(undefined)
      if user.isFullInvestor or IcnIdentity.isPortalApprover()
        lpTemplate
      else if user.isAdmin
        adminTemplate
      else if user.isRia or user.isBranchManager
        riaTemplate
      else if user.isAi
        aiTemplate
      else if user.isLimitedRole
        qpclTemplate
      else if user.isQp
        qpTemplate
      else if user.isDocumentManager
        docMgrTemplate
      else
        emptyTemplate

    d = IcnIdentity.data
    $scope.current_user = IcnIdentity.user
    $scope.user = $scope.current_user
    $scope.dashboardTemplate = dashboardTemplateForUser($scope.user)
    $scope.is_white_label = d.white_label_partner.id != 0
    $scope.white_label_all_funds = (d.white_label_partner.include_all_funds == true)
    $scope.is_ria = $scope.user.isRia
    $scope.isRia = $scope.is_ria
    $scope.isAi = $scope.user.isAi
    $scope.dashboardContentHTML = undefined

    $scope.openNewFund = (fund) ->
      $modal.open(
        templateUrl: "dialogs/custom_notify_dialog/custom_notify_dialog.html"
        controller: "customNotifyDialogCtrl"
        windowClass: "small-modal"
        resolve:
          header: ->
            "Open a new fund"

          msg: ->
            "To open a new fund for raising capital, please contact your Account Manager to set up your "+
              "initial fund profile."
          btn_label: -> ""
      )
      return

    # Check if needs to show any modal
    ModalResponsabilityChainService.call()

    if $scope.dashboardTemplate is gpTemplate
      $scope.funds = Firms.query(
        id: $scope.user.firm_id
        resource: "funds"
        type: "open"
      )

      $scope.funds.$promise.then (d) ->
        $scope.funds = _.sortBy($scope.funds, (f) ->
          f.name.toLowerCase()
        )

        $scope.selectedFund = (if d.length > 0 then d[0] else [])
        $scope.updateSelectedFund d[0]  if d.length > 0
        return

      $scope.closedFundsList = Firms.query(
        id: $scope.user.firm_id
        resource: "funds"
        type: "closed"
      )

      $scope.closedFunds =
        data: "closedFundsList"
        headerRowHeight: 25
        width: 400
        enableRowSelection: false
        plugins: [new ngGridFlexibleHeightPlugin()]
        sortInfo:
          fields: ["vintage"]
          directions: ["desc"]

        onRowMouseEnter: (row) ->
          row.elm.find(".row-action").removeClass "hide"
          return

        onRowMouseLeave: (row) ->
          row.elm.find(".row-action").addClass "hide"
          return

        columnDefs: [
          {
            field: "id"
            displayName: "ID"
            visible: false
          }
          {
            field: "name"
            displayName: "FUND"
            width: 370
            cellTemplate: $templateCache.get("areas/dashboard/templates/cells/name_cell.html")
          }
          {
            field: "vintage"
            displayName: "VINTAGE"
            headerClass: "historical"
          }
          {
            field: "updated_at"
            displayName: "LAST UPDATE"
            headerClass: "historical"
            cellTemplate: "<div class=\"ngCellText\" ng-class=\"col.colIndex()\">"+
              "<span ng-cell-text>{{row.getProperty(col.field)|date:'short'}}</span></div>"
          }
          {
            field: "actions"
            displayName: "ACTION"
            headerClass: "historical"
            visible: false
          }
        ]

    $scope.onClick = (e) ->
      $("*[popover]").each ->
        if(not ($(this).is(e.target) or $(this).has(e.target).length > 0) and
            $(this).siblings(".popover").length isnt 0 and
            $(this).siblings(".popover").has(e.target).length is 0
        )
          $(this).siblings(".popover").remove()
          angular.element(this).scope().tt_isOpen = false
        return

      return

    $scope.updateSelectedFund = (fund) ->
      Funds.hits(fund.id, "count", "search", "month").then (searchHits) ->
        $scope.searchViews = null

        if searchHits.length
          searchViewsData = _.map(searchHits, (searchHit) ->
            [
              $filter("date")(searchHit.fields.date, "MMM")
              searchHit.count
            ]
          )
          colors = [
            "#7FC777"
            "#009C8F"
            "#61C8BF"
          ]
          i = 0
          _.each colors, (color) ->
            searchViewsData[i].push color
            i = i + 1
            return

          $scope.searchViews =
            data: [[
              "Month"
              "Views"
              {
                role: "style"
              }
            ]].concat(searchViewsData)
            options:
              width: 320
              height: 180
              chartArea:
                width: "75%"
                height: "75%"

              legend:
                position: "none"
        return

      Funds.fundDocuments(fund.id, "hits", "count", "view", "month").then (searchHits) ->
        $scope.documentViews = null

        if searchHits.length
          documentViewData = _.map(searchHits, (searchHit) ->
            [
              $filter("date")(searchHit.fields.date, "MMM")
              searchHit.count
            ]
          )
          documentViewColors = [
            "#0074B7"
            "#00B9FF"
            "#77DCFF"
          ]
          i = 0
          _.each documentViewColors, (color) ->
            documentViewData[i].push color
            i = i + 1
            return

          $scope.documentViews =
            data: [[
              "Month"
              "Views"
              {
                role: "style"
              }
            ]].concat(documentViewData)
            options:
              width: 320
              height: 180
              colors: documentViewColors
              chartArea:
                width: "75%"
                height: "75%"

              legend:
                position: "none"
        return

      Funds.hits(fund.id, "count", JSON.stringify([
          "view"
          "list_view"
      ]), "firm_type").then((searchHits) ->
        searchHits = _.reject(searchHits, (searchHit) ->
          filterBlankOrPE(searchHit.fields.firm_type)
        )

        $scope.profileViews = null

        if searchHits.length
          profileViewsData = _.map(searchHits, (searchHit) ->
            [
              searchHit.fields.firm_type
              searchHit.count
            ]
          )
          profileColors = [
            "#009C8F"
            "#61C8BF"
            "#0074B7"
            "#00B9FF"
            "#77DCFF"
            "#444444"
            "#AAAAAA"
            "#FFAC00"
            "#CC6E00"
            "#7FC777"
          ]
          $scope.profileViews =
            data: [].concat(profileViewsData)
            options:
              width: 330
              height: 170
              pieSliceText: "value"
              colors: profileColors
              pieHole: 0.5
              chartArea:
                width: "90%"
                height: "90%"

              backgroundColor: "#ffffff"
              legend:
                position: "right"
                textStyle:
                  fontSize: 12
        return
      )

    $scope.loadingUpdate = false
    if $scope.dashboardTemplate is lpTemplate or
       $scope.dashboardTemplate is qpclTemplate or
       $scope.dashboardTemplate is docMgrTemplate
      updates = {}

      SiteContentService.current($routeParams.approval)
        .then (response) -> $scope.siteContent = response.site_content

      CurrentUser.getFundBucketLabels().$promise.then (response) ->
        $scope.fundBucketLabels = response

        advisorMarketingField = ->
          return AdvisorMarketingFields.get().$promise

        if ($scope.user.isRia or $scope.user.isQp)
          advisorMarketingField().then (response) ->
            $scope.hasMktBanner = response.banner_text and response.banner_link
            $scope.hasMktBannerSubject = response.banner_subject
            $scope.hasMktSurvey = response.survey_text and response.survey_link
            $scope.mktFields = response

          $scope.mktBanner = 'areas/dashboard/templates/dashboards/mkt_banner.html'

        if $scope.user.isRia and !$scope.is_white_label
          $scope.roleHTML = 'areas/dashboard/templates/dashboards/three_banner.html'
        else if $scope.user.isAi and !$scope.is_white_label
          $scope.roleHTML = 'areas/dashboard/templates/dashboards/ai.html'
          $scope.featuredFundId = $scope.siteContent.featured_fund_id
        else if !$scope.is_white_label
          $scope.roleHTML = 'areas/dashboard/templates/dashboards/two_banner.html'
        else
          $scope.dashboardContentHTML = $sce.trustAsHtml($scope.siteContent.dashboard_content)

      CurrentUser.get
        resource: "dashboard"
      , (d) ->
        data = d
        options = [
          {
            first: "1st"
          }
          {
            second: "2nd"
          }
          {
            third: "3rd"
          }
          {
            forth: "4th"
          }
          {
            fifth: "5th"
          }
          {
            sixth: "6th"
          }
        ]

        pafFunds = _.map(d.user.paf_funds, (pafFund) ->
          temp = pafFund.paf_fund
          temp
        )

        data.paf_funds = pafFunds
        $scope.dashboard = data
        $scope.pafFunds = pafFunds

        return

      $scope.sortGridValues = (a, b) ->
        a = -1  if a is "empty"
        b = -1  if b is "empty"
        a - b

      $scope.canHaveInvestments = ->
        $scope.user.isQp || $scope.user.isAi

      $scope.selectedData = []

    # TODO -- port ng-grid code to slick grid. IE9 seems fine with external templates used by slick grid
    dateCellTmpl = $templateCache.get("areas/dashboard/templates/cells/date_cell.html")
