angular.module('controllers').controller "shareClassDialogCtrl",
  ($q, $scope, $modalInstance, $location, shareClass, ShareClassSvc, ExternalInternalMappingsService, options) ->
    $scope.shareClass = _.clone(shareClass)
    $scope.shareClass.distribution_reinvestment = shareClass.distribution_reinvestment || 'reinvestment'
    $scope.shareClass.lock_up_type = shareClass.lock_up_type || ''
    $scope.shareClass.short_name = shareClass.short_name || ''
    $scope.shareClass.retro_rate = shareClass.retro_rate || 0
    $scope.shareClass.gate = shareClass.gate || 0
    $scope.shareClass.investor_gate = shareClass.investor_gate || 0
    $scope.shareClass.investor_gate_penalty = shareClass.investor_gate_penalty || 0
    $scope.shareClass.gate_penalty = shareClass.gate_penalty || 0
    $scope.shareClass.early_penalty = shareClass.early_penalty || 0
    $scope.shareClass.management_fee = shareClass.management_fee || 0
    $scope.shareClass.service_fee = shareClass.service_fee || 0
    $scope.shareClass.inception_date = shareClass.inception_date || ''
    $scope.shareClass.minimum_redemption_amount = shareClass.minimum_redemption_amount || 0

    $scope.exposeLimitCode =
      !!$location.url().match("/paf_admin/hf/funds") || !!$location.url().match("/paf_admin/pe/funds")
    $scope.readOnly = options.readOnly
    $scope.header = options.header
    $scope.errors = {}

    $scope.inceptionDateOptions = {
      dateFormat: "mm/dd/yy"
    }

    $scope.toPercentage = (num) ->
      (num * 100).toFixed(2) + ' %'

    prepForSubmit = ->
      $scope.shareClass.lock_up_type = $scope.shareClass.lock_up_type || null
      $scope.shareClass.retro_rate = $scope.shareClass.retro_rate || null
      $scope.shareClass.gate = $scope.shareClass.gate || null
      $scope.shareClass.investor_gate = $scope.shareClass.investor_gate || null
      $scope.shareClass.investor_gate_penalty = $scope.shareClass.investor_gate_penalty || null
      $scope.shareClass.gate_penalty = $scope.shareClass.gate_penalty || null
      $scope.shareClass.early_penalty = $scope.shareClass.early_penalty || null
      $scope.shareClass.management_fee = $scope.shareClass.management_fee || null
      $scope.shareClass.service_fee = $scope.shareClass.service_fee || null
      $scope.shareClass.minimum_redemption_amount = $scope.shareClass.minimum_redemption_amount || null

    $scope.formValid = ->
      for _, val of $scope.errors
        if val
          $scope.errorMsg = "Please correct the highlighted fields"
          return false
      true

    externalInternalMappings = (newShareClass) ->
      parameters = { id: $scope.shareClass.bny_external_mapping_id, record_type: "ShareClass", record_id: newShareClass.id, external_id: $scope.shareClass.bny_external_share_class_id, context: "BNY Accounting API" }
      if $scope.shareClass.bny_external_mapping_id && $scope.shareClass.bny_external_share_class_id
        mapping_action = ExternalInternalMappingsService.update(parameters)
      else if $scope.shareClass.bny_external_mapping_id && !$scope.shareClass.bny_external_share_class_id
        mapping_action = ExternalInternalMappingsService.delete($scope.shareClass.bny_external_mapping_id)
      else
        mapping_action = ExternalInternalMappingsService.create(parameters)
      mapping_action.$promise.then((resp) ->
        newShareClass.bny_external_share_class_id = resp.external_id
        newShareClass.bny_external_mapping_id = resp.id
        options.callback(newShareClass)
        $modalInstance.close()
      ).catch((resp) ->
        $scope.errorMsg = resp.data.errors[0]
      )

    responseShareClassSaving = (newShareClass) ->
      if $scope.shareClass.bny_external_share_class_id || $scope.shareClass.bny_external_mapping_id
        externalInternalMappings(newShareClass)
      else
        options.callback(newShareClass)
        $modalInstance.close()

    saveAfterVerification = ->
      $scope.clearErrors()
      prepForSubmit()
      if ($scope.shareClass.id)
        ShareClassSvc.update($scope.shareClass).$promise.then((resp) ->
          responseShareClassSaving(resp.share_class)
        ).catch((resp) ->
          $scope.errorMsg = resp.data.errors[0]
        )
      else
        ShareClassSvc.create($scope.shareClass).$promise.then((resp) ->
          responseShareClassSaving(resp.share_class)
        ).catch((resp) ->
          $scope.errorMsg = resp.data.errors[0]
        )

    bnyExternalMappingConditionValid = ->
      ExternalInternalMappingsService.mapping_by_external_id({
        external_id: $scope.shareClass.bny_external_share_class_id,
        context: "BNY Accounting API"
      }).$promise.then((resp) ->
        existence = false
        if resp.mapping != null && $scope.shareClass.bny_external_mapping_id != resp.mapping.id
          existence = true

        if existence
          $scope.errors.bny_external_mapping_id = true
          $scope.formValid()
        else
          saveAfterVerification()

      ).catch((resp) ->
        $scope.errors.bny_external_mapping_id = true
        $scope.formValid()
      )

    $scope.saveShareClass = ->
      $scope.findErrors()
      return unless $scope.formValid()
      if !!$scope.shareClass.bny_external_share_class_id == false && !!$scope.shareClass.bny_external_mapping_id == false
        saveAfterVerification()
      else
        bnyExternalMappingConditionValid()


    $scope.findErrors = ->
      $scope.errors.name = true unless $scope.nameValid()
      $scope.errors.frequency = true unless $scope.redemptionValid()
      $scope.errors.aum = true unless $scope.aumValid()

    $scope.nameValid = ->
      !!$scope.shareClass.name

    $scope.redemptionValid = ->
      $scope.shareClass?.redemption_frequency != "annually" || $scope.shareClass.redemption_date?

    $scope.aumValid = ->
      if $scope.shareClass.aum_max && $scope.shareClass.aum_min
        parseInt($scope.shareClass.aum_max) >= parseInt($scope.shareClass.aum_min)
      else
        true

    $scope.clearErrors = (field) ->
      if field
        $scope.errors[field] = false
      else
        $scope.errors = {}
      $scope.errorMsg = null

    $scope.closeModal = ->
      $modalInstance.close()

    $scope.cloneShareClass = ->
      $scope.header = 'Create Share Class'
      $scope.shareClass['id'] = null
      $scope.shareClass['name'] = null
      $scope.shareClass['short_name'] = null
      $scope.shareClass['bny_external_mapping_id'] = null
      $scope.shareClass['bny_external_share_class_id'] = null

    $scope.cloneable = ->
      !!shareClass.id && !$scope.readOnly


