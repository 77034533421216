angular.module('services').factory 'CustomPageTemplateService', (IcnIdentity) ->
  class CustomPageTemplateService
    getPageTemplate: (templateRoot = '', templateId) ->
      if !_.isEmpty(templateId) &&
      IcnIdentity.isWhiteLabel() &&
      IcnIdentity.whiteLabelPartner().custom_page_templates[templateId]?
        return templateRoot + IcnIdentity.whiteLabelPartner().custom_page_templates[templateId]
      else
        return templateRoot + 'default.html'

    getPageTemplateFromAttribute: (templateRoot = '', attributeValue, wlp_default = false) ->
      if attributeValue?
        return templateRoot + attributeValue + '.html'
      else if IcnIdentity.isWhiteLabel() && wlp_default
        return templateRoot + 'wlp_default.html'
      else
        return templateRoot + 'default.html'

  return new CustomPageTemplateService()
