angular.module "icn.paf_admin.components"
  .directive 'pafAdministrationNotification', ->
    return {
      scope:
        message: '='
      restrict: 'E'
      templateUrl: 'areas/paf_admin/components/notification/notification.html'
      controller: ($scope) ->
        $scope.dismissError = () ->
          $scope.message = null

    }
