CKEDITOR.stylesSet.add('default',
    [
        {name: 'Large Green Title', element: 'span', attributes: {'class' : 'custom__green-title'}},
        {name: 'Large Green Title w Margin', element: 'span', attributes: {'class' : 'custom__green-title-with-margin'}},
        {name: 'Large Black Title', element: 'span', attributes: {'class' : 'custom__black-title'}},
        {name: 'Large Black Title w Margin', element: 'span', attributes: {'class' : 'custom__black-title-with-margin'}},
        {name: 'Source', element: 'span', attributes: {'class' : 'custom__source'}},
        {name: 'Small Green Title', element: 'span', attributes: {'class' : 'custom__small-title'}},
        {name: 'Small Green Text Header', element: 'span', attributes: {'class' : 'custom__green-text-header'}},
        {name: 'Small Black Title', element: 'span', attributes: {'class' : 'custom__subtitle'}},
        {name: 'Small Black Text Header', element: 'span', attributes: {'class' : 'custom__text-header'}},
        {name: 'Text', element: 'p', attributes: {'class' : 'custom__text'}},
        {name: 'Disclaimer', element: 'p', attributes: {'class' : 'custom__disclaimer'}},
        {name: 'Gray Subtitle', element: 'span', attributes: {'class' : 'custom__profile-subtitle'}},
        {name: 'Gray Subtext', element: 'p', attributes: {'class' : 'custom__profile-subtitle-text'}},
        {name: 'Green Header for One Time Popup', element: 'p', attributes: {'class' : 'custom__one-time-popup-green-header'}},
        {name: 'Text for One Time Popup', element: 'p', attributes: {'class' : 'custom__one-time-popup-text'}},
        {name: 'List Items for One Time Popup', element: 'li', attributes: {'class' : 'custom__one-time-popup-list-item'}},
        {name: 'Title for One Time Popup', element: 'h4', attributes: {'class' : 'custom__one-time-popup-title'}}
    ]);
