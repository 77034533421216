angular.module("icn.paf_admin").factory 'PafAdminSvcApi', ($q, $http, $upload, PrivateAccessFunds, IcnUtility) ->
  refreshBnyFunds = (pafIds, reportDate, publishType) ->
    dfd = $q.defer()
    $http(
      method: 'POST',
      url: '/api/v1/hedge_fund_accounting_reports/refresh_bny_funds'
      params: { 'paf_ids[]': pafIds, report_date: reportDate, publish_type: publishType }
    ).then (res) ->
      dfd.resolve {}
    , (err) ->
      dfd.reject err
    dfd.promise

  getPrivateAccessFund = (id) ->
    PrivateAccessFunds.getPrivateAccessFundForPafAdmin(id)

  getPrivateAccessFunds = ->
    PrivateAccessFunds.getPrivateAccessFundsForPafAdmin()

  getPrivateEquityFunds = (forRedemptions) ->
    PrivateAccessFunds.getPrivateEquityFundsForPafAdmin(forRedemptions)

  getInvestments = (privateAccessFund) ->
    dfd = $q.defer()
    $http(
      method: 'GET',
      url: "/api/v1/private_access_funds/#{privateAccessFund.id}/fund_investments"
    ).then (res) ->
      dfd.resolve res.data.fund_investments
    , (err) ->
      dfd.reject err
    dfd.promise

  updatePaf = (fund) ->
    $http(
      method: 'PATCH'
      url: "/api/v1/private_access_funds/#{fund.id}/paf_admin_update"
      data: {private_access_fund: fund}
    )

  closeFund = (id) ->
    $http(
      method: 'GET'
      url: "/api/v1/private_access_funds/#{id}/close"
    )

  getQuarterlyReports = (privateAccessFundId) ->
    dfd = $q.defer()
    $http.get("/api/v1/private_access_funds/#{privateAccessFundId}/quarterly_reports").then (res) ->
      dfd.resolve res.data.quarterly_reports
    dfd.promise

  createQuarterlyReport = (privateAccessFundId, files, date) ->
    dfd = $q.defer()
    params =
      method: 'POST',
      url: "/api/v1/private_access_funds/#{privateAccessFundId}/quarterly_reports"
      data: {report_file: files[0], report_date: IcnUtility.parseDate(date)}
    $upload.upload(params).success(
      (res) -> dfd.resolve(res)
    ).error (errors) ->
      dfd.reject(errors)
    dfd.promise

  deleteQuarterlyReport = (quarterlyReport) ->
    $http(
      method: 'DELETE'
      url: "/api/v1/quarterly_reports/#{quarterlyReport.id}"
    )

  hedgeFunds = (adminGroupId, forRedemptions) ->
    dfd = $q.defer()
    $http(
      method: 'get'
      url: "/api/v1/private_access_funds/hedge_funds",
      params: { fund_admin_group: adminGroupId, filter_with_redemptions: forRedemptions },
    ).success((res) ->
      dfd.resolve res
    ).error (res) ->
      dfd.reject(res)
    dfd.promise

  getFundGroups = (filterByUser) ->
    dfd = $q.defer()
    $http(
      method: 'get'
      url: "/api/v1/fund_admin_groups",
      params: { filter_by_paf_access: filterByUser }
    ).success((res) ->
      dfd.resolve res
    ).error (res) ->
      dfd.reject(res)
    dfd.promise

  saveEmail = (contactCard, email) ->
    dfd = $q.defer()
    $http(
      method: 'PATCH'
      data: {email: email}
      url: "/api/v1/contact_cards/#{contactCard.id}/update_email"
    ).success((res) ->
      dfd.resolve res
    ).error (res) -> dfd.reject(res)
    dfd.promise

  getFundsWithPendingCapitalCallApprovals = ->
    dfd = $q.defer()
    $http(
      method: 'get'
      url: "/api/v1/private_access_funds/with_pending_capital_call_approvals"
    ).success((res) ->
      dfd.resolve res
    )

  getFinancialInfo = ->
    dfd = $q.defer()
    $http(
      method: 'GET'
      url: '/api/v1/financial_info'
    ).success((res) ->
      dfd.resolve res
    )

  getCapitalEvents = (privateAccessFund) ->
    dfd = $q.defer()
    $http(
      method: 'get'
      url: "/api/v1/private_access_funds/#{privateAccessFund.id}/capital_events"
    ).success((res) ->
      dfd.resolve res
    )
    dfd.promise

  getReportingQuarters = (privateAccessFund) ->
    dfd = $q.defer()

    $http(
      method: 'get'
      url: "/api/v1/investment_report_document_sets/valid_reporting_periods",
      params: {
        private_access_fund_id: privateAccessFund.id
      }
    ).success((res) ->
      dfd.resolve res
    )
    dfd.promise

  getReportingQuartersForPafs = (params) ->
    dfd = $q.defer()

    $http(
      method: 'get'
      url: "/api/v1/investment_report_document_sets/valid_reporting_periods_for_pafs",
      params: params
    ).success((res) ->
      dfd.resolve res
    )
    dfd.promise

  return {
    getInvestments: getInvestments
    getPrivateAccessFund: getPrivateAccessFund
    getPrivateAccessFunds: getPrivateAccessFunds
    getPrivateEquityFunds: getPrivateEquityFunds
    refreshBnyFunds: refreshBnyFunds
    updatePaf: updatePaf
    closeFund: closeFund
    getQuarterlyReports: getQuarterlyReports
    createQuarterlyReport: createQuarterlyReport
    deleteQuarterlyReport: deleteQuarterlyReport
    saveEmail: saveEmail
    hedgeFunds: hedgeFunds
    getFundsWithPendingCapitalCallApprovals: getFundsWithPendingCapitalCallApprovals
    getFinancialInfo: getFinancialInfo
    getCapitalEvents: getCapitalEvents
    getFundGroups: getFundGroups,
    getReportingQuarters: getReportingQuarters,
    getReportingQuartersForPafs: getReportingQuartersForPafs
  }
