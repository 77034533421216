angular.module("icn.paf_admin").factory 'FundManagementFee', (IcnBase) ->
  class FundManagementFee
    constructor: (config) ->
      IcnBase.setup this, config
      @_destroy = false
      @initializeRules()

    initializeRules: ->
      _.each @rules, (rule) -> rule._destroy = false

  return FundManagementFee
