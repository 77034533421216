angular.module('directives').directive 'fundSelect', ->
  templateUrl: 'areas/common/fund_select.html'
  restrict: 'E'
  scope:
    funds: '='
    fund: '='
    filterBy: '='
    showAllOption: '='
    displayInline: '@'

  controller: ($scope, PafAdminNavSvc) ->

    init = ->
      $scope.showAllFundsOption = showAllFundsOption()

    setDisplayedFunds = ->
      $scope.displayedFunds = $scope.funds.filter (fund) -> fund.openEnded == ($scope.filterBy == 'hedgeFund')
      $scope.showAllFundsOption = showAllFundsOption()

    filterFunds = ->
      setDisplayedFunds()

      if $scope.filterBy == 'hedgeFund'
        $scope.fund = $scope.displayedFunds[0]
      else if $scope.filterBy != 'hedgeFund'
        $scope.fund = null

    changeFunds = ->
      setDisplayedFunds()

    showAllFundsOption = ->
      (!$scope.showAllOption || $scope.showAllOption == 'true') && $scope.filterBy != 'hedgeFund'

    setSelectedFund = (fund) ->
      if fund
        PafAdminNavSvc.selectedFundId = fund.id
      else
        PafAdminNavSvc.selectedFundId = null

    $scope.$watch "fund", (f) ->
      setSelectedFund(f)

    $scope.$watch "filterBy", (oldValue, newValue) ->
      if $scope.filterBy && $scope.funds && (oldValue != newValue)
        filterFunds()

    $scope.$watch "funds", (newFunds, oldFunds) ->
      if newFunds?
        if $scope.filterBy
          changeFunds()
        else
          $scope.displayedFunds = newFunds

    init()
