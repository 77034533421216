angular.module('services').factory "ContactCardsAutocomplete", ($resource) ->
  resource = $resource("/api/v1/contact_cards/:id",
    id: "@id"
    resource: "@resource"
    query: "@query"
  ,
    contactCardAutocomplete:
      url: "/api/v1/contact_cards/autocomplete"
      method: "GET"
      isArray: true
  )

  resource.autocomplete = (query) ->
    resource.contactCardAutocomplete(
      query: query
    ).$promise

  resource
