angular.module 'icn.models'
.factory "FirmFeeAgreementResource", ($resource) ->
  firmFeeAgreementResource = $resource "/api/v1/firm_fee_agreements/:id",
    id: "@id"
  ,
    forPafAndFirm:
      url: "/api/v1/firm_fee_agreements/for_paf_and_firm"
      method: "GET"
      isArray: true

    pafDefault:
      url: "/api/v1/firm_fee_agreements/paf_default"
      method: "GET"
