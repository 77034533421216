angular.module("icn.paf_admin.reporting")
  .controller 'ReportTransactionsCtrl', ($scope) ->
    $scope.showTransactions = () ->
      $scope.report.reportTransactions?.show

    $scope.selectedTransactions = () ->
      return if !$scope.report.reportTransactions
      $scope.report.reportTransactions[$scope.transactionType]

  .directive 'reportTransactions', ->
    return {
      templateUrl: 'areas/paf_admin/tabs/pe/reporting/pages/report/components/transactions/transactions.html'
      restrict: 'E'
      scope:
        report: '='
        transactionType: '@'
      controller: 'ReportTransactionsCtrl'
    }
