angular.module('services').factory 'RedemptionAmountService', (Redemptions) ->
  svc = {
    ZeroValue: 0.0

    totals: null

    FullRedemptionPercentage: 1.0

    RoundUnits: 4
    RoundPercentage: 2
    RoundAmount: 2

    Amount: 'amount'
    Percent: 'percent'
    Units: 'units'

    init: (customRedemptionsEnabled) ->
      svc.customRedemptionsEnabled = customRedemptionsEnabled

    totalCommitment: (investment) ->
      _.reduce(investment.add_on_investments, (sum, add_on) ->
        return sum + add_on.amount
      , investment.amount)

    totalReportableAmount: (investment) ->
      _.reduce(investment.add_on_investments, (sum, add_on) ->
        return sum + add_on.reportable_amount
      , investment.reportable_amount)

    totalUnits: (investment) ->
      _.reduce(investment.add_on_investments, (sum, add_on) ->
        return sum + (add_on.units || svc.ZeroValue)
      , (investment.units || svc.ZeroValue))

    calculateAmount: (redemption) ->
      return unless svc.redemptionStateChanged(redemption)
      params = svc.params(redemption)
      return unless params

      Redemptions.calculateAmount(params).then (results) ->
        svc.updateRedemptionFromResults(redemption, results)
      .catch (data, status) ->
        console.error("Redemptions.calculateAmount", status, data)

    params: (redemption) ->
      params = svc.amountParams(redemption)
      return unless params

      params.value_date = redemption.valueDate
      params.private_access_fund_id = redemption.pafId
      params.investment_id = redemption.investment.id
      params.full = redemption.full
      params

    amountParams: (redemption) ->
      amount = svc.amountByCalculationType(redemption)
      return unless amount
      {
        amount: amount,
        calculation_type: redemption.calculationType.code,
        total_redemption_amount: if redemption.totals.amount > 0 then redemption.totals.amount else redemption.amount,
        total_units: if redemption.totals.units > 0 then redemption.totals.units else redemption.units
      }

    redemptionStateChanged: (redemption) ->
      !(redemption.before.calculationType.code == redemption.calculationType.code &&
        redemption.before.full == redemption.full &&
        redemption.before.percentage == redemption.percentage &&
        redemption.before.amount == redemption.amount &&
        redemption.before.units == redemption.units)

    amountByCalculationType: (redemption) ->
      calculationType = redemption.calculationType.code
      if calculationType == svc.Amount
        return unless svc.customRedemptionsEnabled || svc.canCalculateByAmount(redemption)
        amount = redemption.amount
      else if calculationType == svc.Percent
        return unless svc.customRedemptionsEnabled || svc.canCalculateByPercent(redemption)
        amount = redemption.percentage
      else if calculationType == svc.Units
        return unless svc.customRedemptionsEnabled || svc.canCalculateByUnits(redemption)
        amount = redemption.units

    updateRedemptionFromResults: (redemption, results) ->
      redemption.units = results.units
      redemption.amount = results.amount
      redemption.percentage = results.redemption_percent
      redemption.full = svc.isFullRedemption(redemption)
      redemption.totalDistribution = results.total_distribution
      redemption.distributionPercentage = results.distribution_percentage

    canCalculateByAmount: (redemption) ->
      if redemption.amount == svc.ZeroValue && !svc.customRedemptionsEnabled
        svc.resetToZeroRedemption(redemption)
        return false
      return true

    canCalculateByPercent: (redemption) ->
      if (redemption.percentage == undefined || redemption.percentage == svc.ZeroValue) && !svc.customRedemptionsEnabled
        svc.resetToZeroRedemption(redemption)
        return false
      else
        return true

    canCalculateByUnits: (redemption) ->
      if (redemption.totals.units == svc.ZeroValue || redemption.units == undefined || redemption.units == svc.ZeroValue) && !svc.customRedemptionsEnabled
        svc.resetToZeroRedemption(redemption)
        return false
      else
        return true

    resetToZeroRedemption: (redemption) ->
      redemption.percentage = svc.ZeroValue
      redemption.amount = svc.ZeroValue
      redemption.units = svc.ZeroValue
      redemption.full = false
      svc.nullifyCalculatedFields(redemption)

    resetToFullRedemption: (redemption) ->
      redemption.percentage = redemption.totals.percentage
      redemption.amount = redemption.totals.amount
      redemption.units = redemption.totals.units
      redemption.full = true
      svc.nullifyCalculatedFields(redemption)
      svc.calculateAmount(redemption)

    nullifyCalculatedFields: (redemption) ->
      redemption.totalDistribution = null
      redemption.distributionPercentage = null

    isFullRedemption: (redemption) ->
      redemption.percentage == svc.FullRedemptionPercentage ||
        (redemption.calculationType.code == svc.Amount && redemption.amount >= redemption.totals.amount ||
            redemption.calculationType.code == svc.Units && redemption.units >= redemption.totals.units)
  }

  return svc
