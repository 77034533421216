angular.module('directives').directive "documentIcon", ->
  restrict: "AE"
  scope:
    filename: "="

  templateUrl: "areas/fund_documents/documents/components/document_icon/document_icon.html"
  link: (scope, element, attrs) ->
    scope.getClass = ->
      ext = scope.fileType(scope.filename)
      return "doc"  if _.contains([ "doc", "docx" ], ext)
      return "xls"  if _.contains([ "xls", "xlsx" ], ext)
      return "ppt"  if _.contains([ "ppt", "pptx" ], ext)
      return "pdf"  if _.contains([ "pdf" ], ext)
      "other"

    scope.fileType = (url) ->
      if not url? or url is undefined
        "n/a"
      else
        fileName = _.last(url.toLowerCase().split('/'))
        extension = _.last(fileName.split('.'))
        if (extension != undefined)
          extension.substring(0, 3)
        else
          ""
