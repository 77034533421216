angular.module('controllers').controller "CustomizeViewDialog",
  ($scope, $route, $modalInstance, $q, selectedPaf, UserSubscriptionReviewViews, IcnIdentity, investmentsCount,
  savedCustomView, allowedStatuses, InvestmentReviewStatusService, InvestmentStatusLabels) ->
    $scope.selectedPaf = selectedPaf
    $scope.investmentsCount = investmentsCount
    $scope.savedCustomView = savedCustomView
    $scope.clearAllOptions = true
    $scope.allowedStatuses = allowedStatuses
    $scope.whiteLabelPartner = IcnIdentity.whiteLabelPartner()
    $scope.checkboxes = [
      {
        id: 'all'
        selected: false
      }
    ]
    setCheckboxes = ->
      _.map($scope.allowedStatuses, (status) ->
        if !$scope.savedCustomView || _.includes($scope.savedCustomView.statuses, status)
          selectedValue = true
        else
          selectedValue = false
        el = {
          id: status,
          selected: selectedValue
        }
        $scope.checkboxes.push(el)
      )
      $scope.checkboxesLength = $scope.checkboxes.length

    init = ->
      setCheckboxes()

    $scope.saveCustomView = ->
      selectedStatuses = _.pluck(_.where($scope.checkboxes, {selected: true}), 'id')
      selectedStatuses = _.reject(selectedStatuses, (status) -> status == 'all')
      if $scope.savedCustomView
        UserSubscriptionReviewViews.update(
          id: $scope.savedCustomView.id,
          {
            data: {
              statuses: selectedStatuses,
              private_access_fund_id: $scope.selectedPaf.id
            },
            private_access_fund_id: $scope.selectedPaf.id
          }
        ).then (response) ->
          $modalInstance.close($route.reload())
      else
        UserSubscriptionReviewViews.create(
          {
            private_access_fund_id: $scope.selectedPaf.id,
            data: { statuses: selectedStatuses }
          },
        ).then (response) ->
          $modalInstance.close($route.reload())

    $scope.dismissModal = ->
      $modalInstance.close()

    InvestmentStatusLabels.wlpPafLabels({
      paf_id: $scope.selectedPaf.id,
      wlp_id: $scope.whiteLabelPartner.id
    }).then (labels) ->
      $scope.wlpPafLabels = labels.data

    $scope.$watch "wlpPafLabels", ->
      $scope.statusLabel = InvestmentReviewStatusService.statusLabel(IcnIdentity, $scope, "selectedPaf")

    $scope.labelInvestmentsCount = (status) ->
      "(#{$scope.investmentsCount[status]?.toLocaleString()})"

    $scope.disableSaveViewBtn = ->
      selected = _.pluck(selectedOptions($scope.checkboxes), 'id')
      return true if selected?.length <= 0 || alreadySavedView(selected)
      false

    alreadySavedView = (selected) ->
      return false unless $scope.savedCustomView
      savedViewLength = $scope.savedCustomView.statuses.length
      selected.length == savedViewLength &&
        _.intersection($scope.savedCustomView?.statuses, selected).length == savedViewLength

    $scope.forFirstColumn = (index) ->
      index < $scope.checkboxesLength / 2

    $scope.forSecondColumn = (index) ->
      index >= $scope.checkboxesLength / 2

    selectAll = (selected) ->
      $scope.clearAllOptions = true
      _.each($scope.checkboxes, (checkbox) ->
        if checkbox.id != 'all'
          checkbox.selected = selected
      )

    selectedOptions = (options) ->
      _.filter(options, (el) -> el.id != 'all' && el.selected)

    allOptionsChecked = (options) ->
      !options[0].selected && selectedOptions(options)?.length == $scope.checkboxesLength - 1

    allOptionsUnchecked = (options) ->
      options[0].selected && selectedOptions(options)?.length < $scope.checkboxesLength - 1

    $scope.$watch "checkboxes", ((newVal, oldVal) ->
      if newVal[0].selected && !oldVal[0].selected
        return selectAll(true) if $scope.clearAllOptions
        $scope.clearAllOptions = true
      else if oldVal[0].selected && !newVal[0].selected
        return selectAll(false) if $scope.clearAllOptions
        $scope.clearAllOptions = true
      else if allOptionsChecked(newVal)
        $scope.clearAllOptions = false
        return $scope.checkboxes[0].selected = true
      else if allOptionsUnchecked(newVal)
        $scope.clearAllOptions = false
        return $scope.checkboxes[0].selected = false
    ), true

    init()
