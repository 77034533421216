angular.module('services').factory "ContactCard", ($resource, $q, IcnIdentity) ->
  fields = [
    "name"
    "job_title"
    "phone"
    "address"
  ]
  resource = $resource("/api/v1/contact_cards/:id",
    id: "@id"
    "fields[]": fields
    isArray: false
  ,
    update:
      method: "PATCH"

    investor_profiles:
      url: "/api/v1/contact_cards/:id/investor_profiles"
      id: "@id"
      method: "GET"
      isArray: true

    check_email_unique:
      url: "/api/v1/contact_cards/check_email_unique"
      method: "GET"
      isArray: false
      email: "@email"
      wlp_id: "@wlpId"

    check_phone_validity:
      url: "/api/v1/contact_cards/check_phone_validity"
      method: "GET"
      isArray: false
      phone_number: "@phoneNumber"

    overwrite_attributes:
      url: "/api/v1/contact_cards/overwrite_attributes"
      method: "GET"

    qualified_purchasers:
      url: '/api/v1/private_access_funds/:private_access_fund_id/contact_cards/qualified_purchasers'
      method: 'GET'
      isArray: true
  )

  resource.investorProfiles = (id, pafId) ->
    deferred = $q.defer()
    resource.investor_profiles(
      id: id, private_access_fund_id: pafId
    ).$promise.then (profiles) ->
      if IcnIdentity.canCreateNewProfile()

        profiles.unshift(
          id: 0
          name: "+ New Account Profile"
          valid_attestation: false
          qualified_purchaser: false
          accredited_investor: false
        )

      deferred.resolve profiles

    deferred.promise

  resource.qualifiedPurchasers = (pafId, wlpId) ->
    resource.qualified_purchasers(private_access_fund_id: pafId, wlp_id: wlpId).$promise

  resource
