angular.module('directives').directive 'maskMoneyKeyeventRewrite', ->
  restrict: 'A',
  link: (scope, elem, attrs) ->
    # extend the keydown event of "jquery.maskMoney.js".
    # This won't affact other elements that not using this directive and were using "jquery.maskMoney.js"
    keydownCallBack = (event) ->
      # backspace key event
      if event.which == 8
        #trigger the input value change event. So that the ngModel value changed
        setTimeout (->
          elem.change()
          return
        ), 100
      # 0-9 number key event
      else if (event.which >= 48 && event.which <= 57) || (event.which >= 96 && event.which <= 105)
        setTimeout (->
          elem.change()
          return
        ), 100
      # enter key event
      else if elem.val() != "0" && (event.which == 13 || event.which == 176)
        event.preventDefault();
        setTimeout (->
          scope.saveAmount();
          return
        ), 100
    elem.keydown(keydownCallBack)
