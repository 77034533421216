angular.module('controllers').controller "ReactRedirectCtrl",
  (IcnIdentity, $location, urlBuilder, ModalResponsabilityChainService) ->

    setUrl = ->
      $location.url(urlBuilder.reactUrl($location.url()))

    if IcnIdentity.isImposter
      setUrl()
    else
      # Check if needs to show any modal
      ModalResponsabilityChainService.call().then( -> setUrl())
