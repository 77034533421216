angular.module('services').factory 'UbsValidationDialogs', ($modal) ->
  soft = (accountId, validations, callback) ->
    $modal.open(
      templateUrl:
        'components/investment_list/dialogs/ubs_soft_validations_dialog/ubs_soft_validations_dialog.html'
      controller: 'UbsSoftValidationsDialogCtrl'
      windowClass: 'small-modal'
      resolve:
        accountId: -> accountId
        validations: -> validations
    ).result.then (accepted) ->
      callback(accepted) if callback

  hard = (accountId, validations, callback) ->
    $modal.open(
      templateUrl:
        'components/investment_list/dialogs/ubs_hard_validations_dialog/ubs_hard_validations_dialog.html'
      controller: 'UbsHardValidationsDialogCtrl'
      windowClass: 'small-modal'
      resolve:
        accountId: -> accountId
        validations: -> validations
    ).result.then (accepted) ->
      callback(accepted) if callback

  soft: soft,
  hard: hard
