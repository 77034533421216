angular.module('directives').directive 'icnLogin', ($validator, CustomPageTemplateService) ->
  restrict: 'E'
  templateUrl: (elem, attr) ->
    CustomPageTemplateService.getPageTemplateFromAttribute(
      'areas/login/login/components/icn_login/templates/',
      attr.customTemplate
    )
  scope:
    user: '='
    wlpName: '='
    ssoLoginEnabled: '='
    ssoLoginUrl: '='
    customTemplate: '=?'

  controller: ($scope, $window, $sce, $modal, $route, Session, IcnIdentity, IcnUtility, $cookieStore) ->
    $scope.serverError = false
    $scope.unprocessableEntity = false
    $scope.devUsers = []
    $scope.dev = user: null
    $scope.allowRegistrationRefresh = IcnIdentity.featureFlagEnabled('enable_registration_refresh')
    $scope.loginHelp = IcnIdentity.whiteLabelPartner().login_help

    # Remove registration data from session storage
    sessionStorage.removeItem('registration')

    sso_error = $cookieStore.get("_sso_auth_error")
    if sso_error
      document.cookie = "_sso_auth_error=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=#{sso_error.domain}"
      $scope.serverError = atob(sso_error.msg)

    goToLandingPage = (response) ->
      landingUrl = ""
      if isValidURL(response.landingURL)
        landingUrl = IcnUtility.ensureRelative(response.landingURL)

      $window.location.href = landingUrl

    isValidURL = (url) ->
      try
        pageUrl = new URL(url)
      catch error
        if !url.includes(window.location.origin) && isValidURL(window.location.origin + IcnUtility.ensureStartingSlash(url))
          return true
        return false

      return (Boolean(pageUrl) && pageUrl.host == window.location.host)

    $scope.loginDev = ->
      Session.login("", "", $scope.dev.user.id, "").then goToLandingPage

    $scope.refreshPage = ->
      $route.reload()

    $scope.login = ->
      v = $validator.validate $scope, 'user'
      v.success ->
        pw = $scope.user.password
        $scope.user.password = ''
        Session.login($scope.user.email.toLowerCase(),
                      pw, null, $scope.user.remember_me, {qps_paf: $scope.user.qps_paf}).then(
          goToLandingPage
        , (response) ->
          if response.needs_password_reset
            $window.location.href = '/reset_password?reset_password_token=' + response.reset_token
          else if response.status == 422
            $scope.unprocessableEntity = true
          else
            $scope.serverError = response.message
        )
      v.error ->
        $scope.serverError = "User and/or password missing or invalid."

    $scope.goToLink = ->
      $window.location.href = $scope.ssoLoginUrl

    $scope.goToRegistration = ->
      if $scope.allowRegistrationRefresh
        $window.location.href = '/icn_react/static/register'
      else
        $window.location.href = '/register'

    Session.devUsers().then (devUsers) -> $scope.devUsers = devUsers
