angular.module('controllers').controller "ViewDelegates", ($scope, $modalInstance, selectedUser, Delegation) ->

  $scope.selectedUser = selectedUser

  loadDelegators = ->
    Delegation.getDelegates(selectedUser.id).then((response) ->
      $scope.delegates = response.delegates
    )

  $scope.closeDialog = ->
    $modalInstance.close()

  loadDelegators()
