angular.module('directives').directive "contentOverflow", ->
  scope:
    contentOverflow: '='

  #contentOverflow has:          enable: true/false
  #                              delimiter: the charator you want to wrap the content
  #                              customChar: the custom charator you want to wrap after the delimiter
  #                              data: current scope of the content that you want to apply
  #                              uniqId: uniq id for the span inside the container
  link: (scope, element) ->
    if scope.enable == false
      return
    setTimeout ->
      currentElement = angular.element(element[0])
      textElement = currentElement.find('#' + scope.contentOverflow.uniqId)

      currentElementWidth = parseInt(currentElement.css('width'), 10)
      textElementWidth = parseInt(textElement.css('width'), 10)

      if currentElementWidth >= textElementWidth
        return

      content = scope.contentOverflow.data[scope.contentOverflow.whichFieldInData]
      delimiterLength = scope.contentOverflow.delimiter.length
      delimiterAfterEscape = scope.contentOverflow.delimiter.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
      regex = RegExp(delimiterAfterEscape,'gi')
      check = regex.exec(content)
      if _.isNull(check)
        return
      else
        index = check.index
        customChar = if scope.contentOverflow.customChar then scope.contentOverflow.customChar else ''
        content = content.slice(0, index + 1) + customChar + content.slice(index + 1)
        textElement.text(content)
        currentElement.removeClass('signer-nowrap')
    , 0
    return
