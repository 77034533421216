angular.module('services').factory "InvestorAcknowledgement", ($resource, $q) ->
  resource = $resource("/api/v1/investor_acknowledgements",
    {},
    acknowledge:
      method: "POST"
  )

  acknowledge = (questions) ->
    resource.acknowledge(questions).$promise

  acknowledge: acknowledge
