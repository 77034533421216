angular.module('directives').directive "icnRoadshow", ($rootScope) ->
  restrict: "E"
  templateUrl: "templates/roadshows/roadshow.html"
  scope:
    roadshow: "="
    fund: "="
    changeTabFn: "&?"
  link: (scope) ->
    scope.toggleCollapse = ($event) ->
      $event.preventDefault()
      scope.isChapterListCollapsed = not scope.isChapterListCollapsed
      $rootScope.$emit "roadshow:toggle"

  controller: ($scope, $window, $location, $modal) ->
    @showChapterDetail = (chapter) ->
      $modal.open(
        templateUrl: "dialogs/roadshow_chapter_dialog/roadshow_chapter_dialog.html"
        controller: "RoadshowChapterDialogCtrl"
        windowClass: 'roadshow-dialog'
        resolve:
          chapter: -> chapter
          fund: -> $scope.fund
      )

    $scope.isChapterListCollapsed = true

    chapter_id = $location.search().chapter_id
    if chapter_id
      chapters = $scope.roadshow.chapters.filter (i) ->
        i.id == parseInt(chapter_id, 10)
      @showChapterDetail chapters[0]
