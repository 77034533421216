angular.module('controllers').controller "RegisterCtrl",
  ($scope, Session, $http, $location, $modal, ParamsService, $window, IcnIdentity, CustomPageTemplateService, $sce) ->
    $scope.lessthanIE9 = -> $window.is_ie_lt9
    params = $location.search()

    if IcnIdentity.whiteLabelPartner().no_register &&
        params.role != 'interested_party' &&
        params.role != 'qp' &&
        !params.referral_code? &&
        !params.tmi_token?
      $location.path("/login")
    $scope.user =
      agree: false
      wlp_agree: false
      main_terms_id: null
      wlp_terms_id: null
      password: null
      password_confirmation: null

    $scope.user.partner = params.partner if params.partner?
    $scope.user.referral = params.referral_code if params.referral_code?
    $scope.user.role = params.role if params.role?
    $scope.user.email = params.email if params.email?
    $scope.user.default_fund = params.default_fund if params.default_fund?
    $scope.user.qps_paf = ParamsService.splitQpsPafParam()
    $scope.user.tmi_token = params.tmi_token if params.tmi_token?
    $scope.user.token = params.token if params.token?
    $scope.showSecondary = true
    $scope.isWlp = IcnIdentity.isWhiteLabel()

    $scope.pageContent = CustomPageTemplateService.getPageTemplate('areas/register/register/templates/', 'registration')
    if IcnIdentity.isWhiteLabel()
      $scope.wlpDisclaimerHtml = $sce.trustAsHtml(IcnIdentity.whiteLabelPartner().disclaimer)

    $scope.onComplete = (user) ->
      if user?.confirmed
        Session.login($scope.user.email, $scope.user.password, null, false).then (response) ->
          $window.location.href = response.landingURL
      else
        $location.path "/not_confirmed/" + $scope.user.email
