angular.module('controllers').controller "acceptTermsDialogCtrl",
  ($scope, $modalInstance, $q, $sce, ModalDialogInstancesObserver,
    UserAcceptedTerms, Session, TermsOfUseService, IcnIdentity) ->

    DEFAULT_TERMS_TITLE = 'Terms and Conditions Acknowledgement'
    DEFAULT_TERMS_ACKNOWLEDGE_TEXT = 'I acknowledge that I have read and agree to the above Terms and Conditions agreement'
    DEFAULT_ERROR_MESSAGE = 'There was an unexpected error, you are not able to accept the terms at this time. Please email support@icapitalnetwork.com or call 212-994-7333 for assistance.'

    $scope.termsTitle = ""
    $scope.termsContent = ""
    $scope.termsAckText = ""

    # dismiss modal dialog box when navigate to other page
    ModalDialogInstancesObserver.setModalDialogInstance($modalInstance)
    $scope.userSignInCount = Number(IcnIdentity.user.sign_in_count)

    $scope.wlp_id_term = 0
    $scope.termsContent = "Loading Terms Content..."
    $scope.termsAccepted = IcnIdentity.user.active_main_site_terms_accepted
    $scope.wlpTermsAccepted = IcnIdentity.user.active_wlp_terms_accepted
    $scope.terms = []
    $scope.termsErrorMessage = ""

    $scope.flipValue = ->
      $scope.termsErrorMessage = ""
      if $scope.wlp_id_term == 0
        $scope.termsAccepted = !$scope.termsAccepted
      else
        $scope.wlpTermsAccepted = !$scope.wlpTermsAccepted

    $scope.needToAcceptMainSiteTerms = ->
      !$scope.termsAccepted && (!IcnIdentity.isWhiteLabel() || !IcnIdentity.user.enable_custom_wlp_page)

    $scope.needToAcceptWlpSiteTerms = ->
      !$scope.wlpTermsAccepted && IcnIdentity.isWhiteLabel()

    # Show main site terms of use
    if $scope.needToAcceptMainSiteTerms()
      TermsOfUseService.get_main_site_active().then (val) ->
        setTerms(val)

    # Show wlp terms of use
    if $scope.needToAcceptWlpSiteTerms()
      TermsOfUseService.get_wlp_active().then (val) ->
        setTerms(val, IcnIdentity.whiteLabelPartner().id)

    setTerms = (val, id = 0) ->
      $scope.terms.push({
        id: id
        term_id: val.terms_of_use.id
        title: $sce.trustAsHtml(val.terms_of_use.terms_title ? DEFAULT_TERMS_TITLE)
        content: $sce.trustAsHtml(val.terms_of_use.terms_content)
        ack_text: $sce.trustAsHtml(val.terms_of_use.terms_ack_text ? DEFAULT_TERMS_ACKNOWLEDGE_TEXT)
      })

      $scope.showTerms(id)

    acceptFor = (terms_id) ->
      defer = $q.defer()
      UserAcceptedTerms.acceptTerms(terms_id).then (res) ->
        defer.resolve(res)
      , (error) ->
        defer.reject(error)

      defer.promise

    acceptTerms = ->
      $scope.termsErrorMessage = ''
      acceptancePromises = []
      acceptancePromises.push(acceptFor($scope.terms[0].term_id)) if $scope.termsAccepted
      acceptancePromises.push(acceptFor($scope.activeTermsId)) if $scope.wlpTermsAccepted

      $q.all(acceptancePromises).then( (res) ->
        IcnIdentity.user.active_main_site_terms_accepted = true if $scope.termsAccepted
        IcnIdentity.user.active_wlp_terms_accepted = true if $scope.wlpTermsAccepted
        $modalInstance.close()
      ).catch (error) ->
        $scope.termsErrorMessage = DEFAULT_ERROR_MESSAGE

    showNextTermsAndConditions = ->
      $scope.terms.length > 1 && $scope.termsAccepted && !$scope.wlpTermsAccepted

    $scope.showTerms = (id = 0) ->
      $scope.termsErrorMessage = ''
      [termOption] = $scope.terms.filter (t) -> t.id == id

      $scope.wlp_id_term = id
      $scope.activeTermsId = termOption.term_id
      $scope.termsTitle = termOption.title
      $scope.termsContent = termOption.content
      $scope.termsAckText = termOption.ack_text

    $scope.accept = ->
      # Show next terms and conditions
      if showNextTermsAndConditions()
        [termOption] = $scope.terms.filter (t) -> t.id != 0
        $scope.showTerms(termOption.id)
      # Accept iCapital and wlp terms
      else
        acceptTerms()

    $scope.cancel = ->
      $modalInstance.dismiss('cancel')
      Session.logout('/login')
