angular.module('services').factory 'IcnBase', ->

  setup =  (obj, config) ->
    obj.config = (config) ->
      for key of config
        obj[key] = config[key]

    obj.config config

  return {
    setup: setup
  }
