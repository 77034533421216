angular.module('controllers').controller "DeleteUnusedReverseSolicitationDialogCtrl",
  ($scope, $rootScope, $modal, $modalInstance, ModalDialogInstancesObserver, PrivateAccessFunds, UserPafExceptionService) ->
    $scope.reverseSolicitationForm = {}
    $scope.reverseSolicitation = {}
    $scope.availableUserList = []
    $scope.pafNotOnList = false
    $scope.errorMessage = ''
    $scope.errorDeletingException = false
    $scope.reverseSolicitation.unusedExceptionList = []

    $scope.cancelDialog = ->
      $modalInstance.dismiss()

    $scope.deleteUnusedException = ->
      params = {
        exception_id: $scope.reverseSolicitation.selectedException,
      }
      UserPafExceptionService.deleteUnusedException(params.exception_id).$promise.then (res) ->
        $modalInstance.close('success')
      .catch (error) ->
        $scope.errorDeletingException = true
        if error.data.message == 'selected exception is being used'
          $scope.errorMessage = 'selected exception is being used'
        else
          $scope.errorMessage = error.data.message

    getUnusedExceptionList = (paf_id) ->
      UserPafExceptionService.getAllUnusedReverseSolicitationForPaf(paf_id).$promise.then (unused_excetion_list) ->
        $scope.reverseSolicitation.unusedExceptionList = unused_excetion_list.map (exceptionObject) ->
          exceptionObject.full_info = 'For: '.concat(exceptionObject.user_name)
            .concat(' - ')
            .concat(exceptionObject.user_email)
            .concat('  |  Reason: ')
            .concat(exceptionObject.reason)
          exceptionObject
        console.log($scope.reverseSolicitation.unusedExceptionList)
        if $scope.reverseSolicitation.unusedExceptionList.length == 1
          $scope.reverseSolicitation.selectedException = $scope.reverseSolicitation.unusedExceptionList[0].login

    checkIfPafOnList = (val) ->
      if typeof val == 'string'
        $scope.pafNotOnList = true
      else
        $scope.pafNotOnList = false

    $scope.fieldOnBlurCheck = (field) ->
      return if typeof $scope.reverseSolicitation.private_access_fund == "object"
      if field == 'paf'
        matchList = _.filter($scope.openFunds, (object) ->
          object.fund_name == $scope.reverseSolicitation.private_access_fund
        )
        if matchList.length > 0
          $scope.reverseSolicitation.private_access_fund = matchList[0]

    $scope.$watch "reverseSolicitation.private_access_fund", ((newAssets, oldAssets) ->
      unless _.isUndefined(newAssets)
        checkIfPafOnList(newAssets)
      return if typeof newAssets != "object"
      getUnusedExceptionList(newAssets.id)
    ), true

    init = ->
      PrivateAccessFunds.getAllPafsHaveUnusedExceptions().$promise.then (funds) ->
        console.log(funds)
        $scope.openFunds = funds

    init()
