angular.module('services').factory 'InvestmentReviewStatusService', ->

  findStatusLabel = (icnIdentity, scope, paf) ->
    wlpLabels = icnIdentity.whiteLabelPartner().investment_status_labels
    wlpPafLabels = scope.wlpPafLabels
    mergeLabels(wlpPafLabels, wlpLabels, scope[paf]?.investment_status_labels)

  # label priority: wlp and paf labels > wlp labels > paf labels
  # extend function works in reverse order
  mergeLabels = (wlpPafLabels, wlpLabels, pafLabels) ->
    labels = $.extend({}, pafLabels, wlpLabels)
    labels = $.extend({}, labels, wlpPafLabels)
    labels

  statusLabel = (icnIdentity, scope, paf) ->
    (status) ->
      labels = findStatusLabel(icnIdentity, scope, paf)

      switch status
        when 'Reverted', 'reverted'
          labels?.reverted || 'Attention Required'
        when 'Pre-Approval', 'pre_approval'
          labels?.pre_approval || 'Pre-Approval'
        when 'Pre-Approval 2', 'pre_approval_secondary'
          labels?.pre_approval_secondary || 'Pre-Approval 2'
        when 'Pending Documents', 'pending_review'
          labels?.pending_review || 'In-Process'
        when 'IR Review', 'ir_review'
          labels?.ir_review || 'Document Review'
        when 'Suitability Review', 'external_review'
          reviewType = if scope.white_label then "#{scope.white_label} Review" else "External Review"
          labels?.external_review || reviewType
        when 'Suitability Review 2', 'external_secondary_review'
          reviewType = if scope.white_label then "#{scope.white_label} Review 2" else "External Review 2"
          labels?.external_secondary_review || reviewType
        when 'Compliance Review', 'compliance_review'
          labels?.compliance_review || 'Legal Review'
        when 'Approved', 'approved'
          labels?.approved || 'Approved'
        when 'Accepted', 'accepted'
          labels?.accepted || 'Accepted'
        when 'Approved Subscriptions'
          labels?.approved || 'Approved'
        when 'Accepted Subscriptions'
          labels?.accepted || 'Accepted'
        when 'all'
          'All'
        else
          status

  return {
    statusLabel: statusLabel
  }
