angular.module('controllers').controller "subAdvisoryFeeDialogCtrl",
  ($scope, $modal, $modalInstance, InvestmentService, investment) ->
    $scope.investment = investment
    determineSubAdvisoryFee = ->
      if $scope.investment.sub_advisory_fee then $scope.investment.sub_advisory_fee else 0.0

    $scope.subAdvisoryForm = {
      sub_advisory_fee: determineSubAdvisoryFee()
    }

    $scope.validSubAdvisoryFee = ->
      $scope.subAdvisoryForm.sub_advisory_fee != null && subAdvisoryFeeInsideBounds()

    subAdvisoryFeeInsideBounds = ->
      $scope.subAdvisoryForm.sub_advisory_fee >= 0 &&
        !$scope.subAdvisoryFeeOverMaxValue() && !$scope.subAdvisoryFeeUnderMinValue()

    $scope.subAdvisoryFeeOverMaxValue = ->
      max_sub_advisory_fee = $scope.investment.firm_fee_agreement?.max_sub_advisory_fee
      max_sub_advisory_fee && $scope.subAdvisoryForm.sub_advisory_fee > max_sub_advisory_fee

    $scope.subAdvisoryFeeUnderMinValue = ->
      min_sub_advisory_fee = $scope.investment.firm_fee_agreement?.min_sub_advisory_fee
      min_sub_advisory_fee && $scope.subAdvisoryForm.sub_advisory_fee < min_sub_advisory_fee

    $scope.cancelDialog = ->
      $modalInstance.dismiss()

    $scope.updateSubAdvisoryFee = ->
      $modalInstance.close({
        placementFee: undefined,
        subAdvisoryFee: $scope.subAdvisoryForm.sub_advisory_fee,
        investment: $scope.investment
      })
