angular.module('services').factory 'AccountUtilities', ->

  completeRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\~!@#$%^&*_\-\+=\`:;\"\'<>,.?\/\[\]{}()])[A-Za-z\d\~!@#$%^&*_\-\+=\`:;\"\'<>,.?\/\[\]{}()]{10,}$/

  emailRegex = /^$|^[A-Za-z0-9]{1}(?!.*?\.\.)[^ @]*@[A-Za-z0-9\-.]+\.[A-Za-z]+$/

  passwordHasError = (password) ->
    password &&
    password.$invalid &&
    password.$touched &&
    password.$dirty &&
    password.$viewValue != ''

  confirmedPasswordHasError = (password, password_confirmation) ->
    password_confirmation &&
    password &&
    password.$viewValue != '' &&
    password_confirmation.$viewValue != '' &&
    password_confirmation.$touched &&
    password_confirmation.$dirty &&
    password.$touched &&
    password.$viewValue != password_confirmation.$viewValue

  passwordRequirementsAllCompleted = (password, password_confirmation) ->
    return password &&
           completeRegex.test(password) &&
           password == password_confirmation

  validatePasswordRegex = (password) ->
    return password &&
           completeRegex.test(password)

  validateEmail = (email) ->
    return email && emailRegex.test(email.$viewValue)

  cleanupEmail = (email) ->
    email.replace(/\b(\.icn[0-9]+)$\b/, '')

  invalidPasswordWords = (msg) ->
    return false if msg == null || msg == undefined
    msg.includes("Password contains common or forbidden words")

  invalidPasswordBreach = (msg) ->
    return false if msg == null || msg == undefined
    msg.includes("Password has previously appeared in a data breach and should not be used")

  passwordHasError: passwordHasError
  confirmedPasswordHasError: confirmedPasswordHasError
  passwordRequirementsAllCompleted: passwordRequirementsAllCompleted
  validatePasswordRegex: validatePasswordRegex
  validateEmail: validateEmail
  cleanupEmail: cleanupEmail
  invalidPasswordWords: invalidPasswordWords
  invalidPasswordBreach: invalidPasswordBreach
