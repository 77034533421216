angular.module('directives').directive "subscriptionSignerBox", (ContactCard) ->
  restrict: "E"
  templateUrl: "components/investment_list/dialogs/send_subscription_dialog/signer_box/subscription_signer_box.html"
  scope:
    signerStruct: "="
    signerKey: "@"
    phoneService: "="
    isDocusignFund: "="

  link: (scope, element, attrs) ->
    scope.phoneNumberOptions = scope.phoneService.signersPhoneNumberOptions(scope.signerKey)

    scope.phoneValidate = ->
      ContactCard.check_phone_validity(phone_number: scope.signerStruct.phoneInput).$promise.then (result) ->
        scope.phoneService.changePhoneNumber(scope.signerKey, result.phone_data) if result.valid
        scope.phoneNumberValid = result.valid && scope.signerStruct.phone?.length
        scope.phoneNumberError = !result.valid
        scope.signerStruct.phoneValid = result.valid
        scope.signerStruct.phoneErrors = if scope.phoneNumberError then 'The phone number is invalid' else null

