angular.module('directives').directive 'redemptionSubList', ->
  restrict: 'A'
  replace: true
  templateUrl: 'components/redemption_list/redemption_sub_list/redemption_sub_list.html'
  scope:
    privateAccessFund: '='
    ria: '='
    redemptions: '='
    header: '@'
    open: '='
    order: '='
    reverse: '='
    count: '='
    fetchRedemptions: '&'
  controller: ($scope, IcnIdentity, $modal, $q, Redemptions, $window, $location, WhiteLabelPartnerService,
               InvestmentService, CustomRedemptionConfigurationService, urlBuilder, Dialog, RequiredDocument,
               AgreementEmbeddedExperience) ->
    init = ->
      $scope.isPafAdmin = IcnIdentity.isPafAdmin()
      $scope.isUnitizedFund = $scope.privateAccessFund.unitized
      $scope.opened = $scope.open
      WhiteLabelPartnerService.current().then (response) ->
        $scope.sequenceSignersRequired = response.white_label_partner?.sequence_signers_required

    $scope.chevron = {
      down: "/images/chevron-down.svg",
      right: "/images/chevron-right.svg"
    }

    $scope.sectionChevronIcon = ->
      if $scope.opened
        $scope.chevron.down
      else
        $scope.chevron.right

    $scope.$watchCollection 'redemptions', (newVal) ->
      if newVal?
        calculateTotals()

    $scope.current_date = new Date(Date.now()).getTime()

    $scope.convert_to_date = (dateString) ->
      if dateString
        new Date(dateString)

    CustomRedemptionConfigurationService.init($scope.privateAccessFund)

    $scope.calculate_nav_amount = (redemption) ->
      CustomRedemptionConfigurationService.subListNavAmount(redemption)

    $scope.calculate_amount_payable = (redemption) ->
      CustomRedemptionConfigurationService.subListAmountPayable(redemption)

    $scope.calculate_amount_payable_percentage = (redemption) ->
      CustomRedemptionConfigurationService.subListAmountPayablePercentage(redemption)

    $scope.calculate_hold_back_amount = (redemption) ->
      CustomRedemptionConfigurationService.subListHoldBackAmount(redemption)

    $scope.calculate_hold_back_percentage = (redemption) ->
      CustomRedemptionConfigurationService.subListHoldBackPercentage(redemption)

    $scope.calculate_units = (redemption) ->
      CustomRedemptionConfigurationService.subListUnits(redemption)

    $scope.formatHoldBackWireDate = (redemption) ->
      status   = redemption.status
      holdBack = redemption.hold_back
      return "NA" if (status == "approved" && (holdBack == 0 || holdBack == undefined))

      return  $scope.convert_to_date(redemption.hold_back_wire_date)

    $scope.showAsterisk = (redemption, column) ->
      return !redemption.wire_date unless redemption.investment.redemption_settings.enable_custom_redemptions

      isPositive = false

      if column == 'amount_payable'
        isPositive = !!parseFloat($scope.calculate_amount_payable(redemption))
      else if column == 'hold_back_amount'
        isPositive = !!parseFloat($scope.calculate_hold_back_amount(redemption))

      !redemption.wire_date && isPositive

    $scope.canAccessRedemptions = ->
      IcnIdentity.canInitiateRedemption()

    $scope.holdBackNotSent = (redemption) ->
      !redemption.hold_back_wire_date ||
        new Date(redemption.hold_back_wire_date).getTime() > $scope.current_date

    $scope.expandRedemptions = ->
      if ($scope.count != 0 && $scope.redemptions.length == 0)
        $scope.fetchRedemptions().then  ->
          $scope.opened = !$scope.opened
      else
        $scope.opened = !$scope.opened

    currentPageName = ->
      regex_investment_management = new RegExp('investment_management')
      if regex_investment_management.test($location.path())
        "investment_management"
      else
        "client_management"

    $scope.requiresSignature = (redemption) ->
      redemption.status == 'draft' ||
        redemption.redemption_document?.status == 'pending_upload' ||
        redemption.redemption_document?.status == 'pending_completion'

    $scope.handleSignature = (redemption) ->
      if !redemption.has_supplemental_form || redemption.supplemental_form_completed
        if redemption.redemption_document?.status == 'pending_upload'
          $scope.uploadRedemptionDoc(redemption)
        else if redemption.redemption_document?.status == 'pending_completion'
          AgreementEmbeddedExperience.handlePreparation(
            $scope.privateAccessFund.id,
            redemption.redemption_document.id,
            redemption.sign_method,
            currentPageName(),
          )
        else if allowSequenceSigners(redemption)
          configs = { next: true }
          $scope.openPickSignersModal(redemption.investment, configs).result.then (signerIds) ->
            return unless signerIds

            InvestmentService.changeSigners(redemption.investment_id, signerIds).then (updatedInvestment) ->
              redemption.investment = updatedInvestment
              $scope.showSendSignatureDialog(redemption)
            , -> scope.msg = "Not able to change signers"
        else
          $scope.showSendSignatureDialog(redemption)

        if (document.activeElement != document.body)
          document.activeElement.blur()
      else
        notify_text = "The Supplemental Form must be completed before you can upload a redemption document.<br><br>" +
                      "Click OK to proceed to Supplemental Form."
        Dialog.openNotifyDialog("Supplemental Form Incomplete", notify_text, true).result.then ->
          $scope.openSupplementalForm(redemption)

    allowSequenceSigners = (redemption) ->
      $scope.sequenceSignersRequired && redemption.investment.available_signers.length > 1

    $scope.openPickSignersModal = (investment, configs) ->
      $modal.open(
        templateUrl: "components/investment_list/dialogs/pick_signers_dialog/pick_signers_dialog.html"
        controller: "PickSignersDialogCtrl"
        windowClass: "small-modal"
        resolve:
          investment: -> investment
          configs: -> configs
      )

    $scope.agreementText = (redemption) ->
      return '' unless redemption

      if redemption.status == 'draft' && !redemption.sign_method
        return 'Send For Signature'

      if redemption.redemption_document
        switch redemption.redemption_document.status
          when 'required'
            return 'Send For Signature'
          when 'pending_upload'
            return 'upload signature'
          when 'pending_completion'
            return 'pending completion'
          else
            return redemption.display_status

      return redemption.display_status

    $scope.canDownloadAgreement = (redemption) ->
      return redemption?.redemption_document?.can_download

    $scope.downloadAgreement = (redemption) ->
      RequiredDocument.view(
        id: redemption.redemption_document.id
      ).$promise.then (res) ->
        $window.open res.url
        return true

    $scope.showSendRedemptionReminder = (redemption) ->
      redemption.sign_method == 'electronic' &&
      redemption.status == 'pending_signature' &&
      (IcnIdentity.isRia() || IcnIdentity.isQp() ||  IcnIdentity.isQpcl() || IcnIdentity.isPafAdmin())

    $scope.sendRedemptionReminder = (redemption, $event) ->
      $scope.openReminderDialog(redemption)
      $event.preventDefault() if $event

    $scope.openReminderDialog = (redemption) ->
      opts =
        templateUrl: "areas/private_equity_funds/private_access_funds/components/reminder_dialog/_reminder_dialog.html"
        controller: "ReminderDialogController"
        windowClass: "small-modal"
        resolve:
          documentWithSignatureProcess: -> redemption.redemption_document
      $modal.open opts

    $scope.canUploadDocument = (redemption) ->
      redemption.status == 'pending_signature' && $scope.canAccessRedemptions()

    $scope.uploadRedemptionDoc = (redemption) ->
      $modal.open(
        templateUrl: "dialogs/upload_redemption_doc_dialog/upload_redemption_doc_dialog.html"
        controller: "UploadRedemptionDocDialogCtrl"
        windowClass: "small-modal"
        resolve:
          header: ->
            "Upload Signed Redemption Document"
          privateAccessFund: -> $scope.privateAccessFund
          redemption: -> redemption
      )

    $scope.showSendSignatureDialog = (redemption) ->
      redemption = angular.copy(redemption)
      $modal.open(
        templateUrl: "components/redemption_list/dialogs/send_redemption_dialog.html"
        controller: "sendRedemptionDialogCtrl"
        windowClass: "small-modal"
        resolve:
          redemption: -> redemption
          privateAccessFundId: -> $scope.privateAccessFund.id
      ).result.then (redemption) ->
        $.extend(_.findWhere($scope.redemptions, {id: redemption.id}), redemption)

    $scope.canCancelRedemption = (redemption) ->
      redemption.status in ['draft', 'generating_agreement', 'pending_signature'] && $scope.canAccessRedemptions()

    $scope.cancelRedemption = (redemption) ->
      Redemptions.cancel($scope.privateAccessFund.id, redemption).then (response) ->
        redemption.status = 'canceled'
        redemption.display_status = 'canceled'
        redemption.redemption_document.status = 'canceled'

    $scope.canViewEventsHistory = -> IcnIdentity.canViewEventsHistory()

    $scope.viewEvents = (redemption) ->
      Redemptions.redemptionEvents( $scope.privateAccessFund.id, redemption.id).then (response) ->
        modalSize = if response?.redemption_events?.length > 0 then 'large-modal' else 'small-modal'
        $modal.open(
          templateUrl: "components/investment_list/dialogs/investment_events_dialog/investment_events_dialog.html"
          controller: "InvestmentEventsDialogCtrl"
          windowClass: modalSize
          resolve:
            events: -> response.redemption_events,
            type: -> 'redemption',
            selectedPaf: -> $scope.privateAccessFund
        )

    $scope.openSupplementalForm = (redemption, $event) ->
      localStorage.setItem('agreement_exists_' + redemption.id, !!redemption.redemption_document?.can_download)

      url = $scope.supplementalQuestionsUrl(redemption)
      window.location.assign(url)
      $event.preventDefault() if $event

    $scope.supplementalQuestionsUrl = (redemption) ->
      urlBuilder.redemptionSupplementalFormUrl(
        redemption.id,
        $scope.privateAccessFund.id,
        '',
      )

    $scope.redemptionSupplementalResponseStatusString = (redemption = {}) ->
      if redemption.supplemental_form_completed
        'Complete'
      else
        'Incomplete'

    $scope.disabledLink = (redemption) ->
      $scope.subscription_review || !redemption.available

    calculateTotals = ->
      $scope.totalValueDateNav = _.reduce($scope.redemptions, (t, i) ->
        t + parseFloat($scope.calculate_nav_amount(i))
      , 0)

      $scope.totalAmountPayable = _.reduce($scope.redemptions, (t, i) ->
        t + parseFloat($scope.calculate_amount_payable(i))
      , 0)

      $scope.totalHoldBack = _.reduce($scope.redemptions, (t, i) ->
        t + parseFloat($scope.calculate_hold_back_amount(i))
      , 0)

    init()
