angular.module('directives').directive "historicalPerformance", ->
  restrict: "E"
  transclude: true
  templateUrl: "templates/directives/historical_performance.html"
  scope:
    siblings: "="
    fund: "="
    firmOwner: '='
    lockedForApproval: '='
    approval: '='
    saveSection: '&'
  controller:
    ($scope, $routeParams, $route, $templateCache, Funds, IcnIdentity, IcnUtility) ->
      headerCellTmpl = $templateCache.get("templates/directives/historical_performance/header.html")
      percentageCellTmpl = $templateCache.get("templates/directives/historical_performance/percentage.html")
      numberCellTmpl = $templateCache.get("templates/directives/historical_performance/number.html")

      $scope.currency = '$'

      sortVintage = (a, b) ->
        vintageA = parseInt(a)
        vintageB = parseInt(b)
        if vintageA == vintageB
          0
        else if vintageA > vintageB
          1
        else -1 if vintageA < vintageB

      $scope.addSibling = ->
        $scope.siblings.unshift(
          new: true
          name: ""
          vintage: ""
        )

      $scope.$watch('editable', (editable, previousValue) ->
        unless editable == previousValue
          # Toggle the visibility of the called % and RVPI columns
          $scope.historicPerformance.$gridScope.columns[8].toggleVisible()
          $scope.historicPerformance.$gridScope.columns[9].toggleVisible()
          if editable.editing
            # Toggle the width of the name column
            $scope.historicPerformance.$gridScope.columns[0].width = 100
            $scope.historicPerformance.columnDefs[0].width = 100
          else
            # Reset original column defs
            $scope.historicPerformance.ngGrid.buildColumns()
      , true)

      $scope.historicPerformance =
        data: "siblings"
        headerRowHeight: 35
        rowHeight: 35
        enableRowSelection: false
        plugins: [new IcnUtility.ngGridFlexibleHeightPlugin()]
        enableSorting: false
        sortInfo:
          fields: ["vintage"]
          directions: ["desc"]
        columnDefs: "historicalPerformanceColumnDefs"

      isIPad = navigator.userAgent.match(/iPad/i) != null
      $scope.historicalPerformanceColumnDefs = [
        {
          field: "name"
          displayName: "FUND"
          cellTemplate: $templateCache.get("templates/directives/historical_performance/name.html")
          width: if isIPad then 298 else 328
        }
        {
          field: "vintage"
          displayName: "VINTAGE"
          headerClass: "Year the specific fund made its first investment"
          width: 72
          sortfn: sortVintage
          headerCellTemplate: headerCellTmpl
          cellTemplate: $templateCache.get("templates/directives/historical_performance/vintage.html")
        }
        {
          field: "dpi"
          displayName: "DPI"
          headerClass: "DPI = Distributions / Capital Invested"
          headerCellTemplate: headerCellTmpl
          cellTemplate: percentageCellTmpl
          width: 72
        }
        {
          field: "net_irr"
          displayName: "NET IRR"
          headerClass: "Internal Rate of Return (dollar weighted performance metric)"
          width: 72
          headerCellTemplate: headerCellTmpl
          cellTemplate: percentageCellTmpl
        }
        {
          field: "multiple"
          displayName: "NET MOIC"
          headerClass: "Multiple of Invested Capital = Total Value / Invested Capital"
          width: 72
          headerCellTemplate: headerCellTmpl
          cellTemplate: multiplierCellTmpl
        }
        {
          field: "called"
          displayName: "CALLED %"
          cellTemplate: percentageCellTmpl
          width: 72
        }
        {
          field: "rvpi"
          displayName: "RVPI"
          cellTemplate: numberCellTmpl
          width: 72
        }
        {
          field: "quartile"
          displayName: "QUART"
          headerClass: "Funds with similar characteristics ranked from top 25% to bottom 25% based on key metrics"
          headerCellTemplate: headerCellTmpl
          cellTemplate: quartileCellTmpl
          width: 72
        }
      ]
