angular.module('services').factory 'InvestorSearchService', ->
  _cardId = undefined

  restorePath = ->
    if _cardId != undefined
      return "/investor_relations/investor_search/#{_cardId}"
    else
      return "/investor_relations/investor_search"

  getCardId = ->
    _cardId

  setCardId = (id) ->
    _cardId = id

  return {
    getCardId: getCardId
    setCardId: setCardId
    restorePath: restorePath
  }
