angular.module('services').factory 'urlBuilder', ($location, IcnUtility) ->
  reactUrl = (url, returnValue) ->
    endpoint = if url.startsWith('/') then url else "/#{url}"
    reactPath = "/icn_react/static#{endpoint}"
    if IcnUtility.hasPartnerProxyAPIPrefix()
      reactPath =  IcnUtility.getPartnerProxyAPIPrefix() + reactPath
    protocol = ''

    if !returnValue
      window.location.assign("#{reactPath}")
    else
      return "#{reactPath}"

  supplementalFormPreInvestmentUrl = (queryStringParams, forNewTab = false) ->
    url = reactUrl("paf_supplemental_questions_pre_investment", true)
    url = "#{url}?#{queryStringParams}"
    if !forNewTab
      window.location.assign(url)
      return null

    return url

  supplementalFormUrl = (investmentId, pafId, queryStringParams, forNewTab = false) ->
    url = reactUrl("investment/#{investmentId}/paf_supplemental_questions", true)
    queryString = "&paf_id=#{pafId}&returnUrl=#{window.location.pathname}"
    queryString = "#{queryString}#{queryStringParams}" if queryStringParams
    url = "#{url}?#{queryString}"
    if !forNewTab
      window.location.assign(url)
      return null

    return url

  redemptionSupplementalFormUrl = (investmentId, pafId, queryStringParams) ->
    url = "redemption/#{investmentId}/redemption_supplemental_questions"
    url = reactUrl(url, true)
    queryString = "paf_id=#{pafId}&returnUrl=#{window.location.pathname}#{queryStringParams}"
    url = "#{url}?#{queryString}"

    return url

  preApprovalQuestionnaireUrl = (queryParams, forNewTab = false) ->
    url = reactUrl("pre_approval_questionnaire", true)
    returnUrl =
      if getQueryParamValue(queryParams, "source_modal") == "createInvestmentQP"
        "/private_access_funds/#{getQueryParamValue(queryParams, "paf_id")}/legal_docs"
      else
        window.location.pathname

    url = "#{url}?#{queryParams}&return_url=#{returnUrl}"
    if !forNewTab
      window.location.assign(url)
      return null

    return url

  setCommitmentAmountParam = (commitment)  ->
    url = new URL(window.location.href)
    url.searchParams.set 'commitmentAmount', commitment
    window.history.replaceState null, null, url

  getQueryParamValue = (queryParam, key) ->
    regex = new RegExp("#{key}=(.*?)(\&|$)")
    queryParam.match(regex)[1]

  investorProfileUrl = (invProfId, step, queryString = "", forNewTab = false) ->
    return joinQueryString(reactUrl("/investor_profile/#{invProfId}/step/#{step}", true), queryString, forNewTab, true)

  educationDashboardUrl = (queryString = "", forNewTab = false) ->
    return joinQueryString(reactUrl("/education_dashboard", true), queryString, forNewTab, false)

  joinQueryString = (url, queryString = "", forNewTab = false, returnUrl = false) ->
    paramsUrl = queryString
    if returnUrl
      returnUrlParam = "returnUrl=#{window.location.pathname}"
      paramsUrl = if paramsUrl.length > 0 then "#{paramsUrl}&#{returnUrlParam}" else returnUrlParam

    finalUrl = if paramsUrl.length > 0 then "#{url}?#{paramsUrl}" else url

    if !forNewTab
      window.location.assign(finalUrl)
      return null

    return finalUrl

  reactUrl: reactUrl
  investorProfileUrl: investorProfileUrl
  educationDashboardUrl: educationDashboardUrl
  supplementalFormUrl: supplementalFormUrl
  preApprovalQuestionnaireUrl: preApprovalQuestionnaireUrl
  supplementalFormPreInvestmentUrl: supplementalFormPreInvestmentUrl
  redemptionSupplementalFormUrl: redemptionSupplementalFormUrl
  setCommitmentAmountParam: setCommitmentAmountParam
