"use strict";

// file automatically generated by angular-template-cache

angular
    .module("icn")
    .run(["$templateCache", function($templateCache) {
        $templateCache.put("areas/aml_approval/components/comments/comments.html", "<div class=\"approval-comments\">\n" +
            "  <div class=\"row no-padding\">\n" +
            "    <div class=\"col-md-12 no-padding\">\n" +
            "      <ul>\n" +
            "        <li class=\"comment\" ng-repeat=\"comment in approval.comments\">\n" +
            "          <div class=\"author\">\n" +
            "            Comment entered by {{comment.created_by.display_name}} on {{comment.created_at | date: \"dd-MMM-yy h:mm a\"}}\n" +
            "            <span ng-if=\"canEditComment(comment)\" ng-click=\"toggleEditMode(comment)\">\n" +
            "              <i class=\"fa fa-pencil\" aria-hidden=\"true\"></i>\n" +
            "            </span>\n" +
            "            <span ng-if=\"comment.editMode\" ng-click=\"toggleEditMode(comment)\" class=\"close-edit\"><i class=\"fa fa-times\" aria-hidden=\"true\"></i></span>\n" +
            "            <div ng-show=\"comment.updated_by\">\n" +
            "              <span class=\"note-update\" ng-if=\"showCommentUpdatedAt(comment)\">\n" +
            "                Edited by {{comment.updated_by.display_name}} on {{comment.updated_at | date: \"dd-MMM-yy h:mm a\"}}\n" +
            "              </span>\n" +
            "            </div>\n" +
            "          </div>\n" +
            "          <div ng-if=\"!comment.editMode\">\n" +
            "            <div class=\"content\"><span ng-if=\"showCommentSubject(comment)\">{{comment.subject}}: </span>{{comment.body}}</div>\n" +
            "            <div ng-if=\"newRemediationComment(comment)\" class=\"mt-2\">\n" +
            "              <icn-checkbox label=\"Comment Acknowledged\" model=\"comment.acknowledged\" ng-click=\"acknowledgeComment(comment)\">\n" +
            "                Comment Acknowledged\n" +
            "              </icn-checkbox>\n" +
            "            </div>\n" +
            "          </div>\n" +
            "          <div ng-if=\"comment.editMode\">\n" +
            "            <textarea ng-model=\"comment.body\" rows=\"4\" placeholder=\"Enter comment here...\"></textarea>\n" +
            "            <div class=\"row\">\n" +
            "              <button type=\"submit\" ng-click=\"updateComment(comment)\">SAVE</button>\n" +
            "              <button type=\"submit\" class=\"button__decline\" ng-click=\"confirmDelete(comment.id)\">DELETE</button>\n" +
            "            </div>\n" +
            "          </div>\n" +
            "        </li>\n" +
            "        <div ng-if=\"newComment.new\">\n" +
            "          <textarea ng-model=\"newComment.body\" rows=\"4\" placeholder=\"Enter comment here...\"></textarea>\n" +
            "          <div class=\"row\">\n" +
            "            <button type=\"submit\" ng-click=\"createComment(newComment)\">SAVE</button>\n" +
            "            <button type=\"submit\" class=\"button__decline\" ng-click=\"resetNewComment()\">CANCEL</button>\n" +
            "          </div>\n" +
            "          {{scrollToLatestComment()}}\n" +
            "        </div>\n" +
            "        {{scrollToLatestComment()}}\n" +
            "      </ul>\n" +
            "      <a href=\"#\" class=\"add-comment\" ng-if=\"!newComment.new\" ng-click=\"addNewComment()\"><i class=\"fa fa-plus\" aria-hidden=\"true\"></i> Add Comment</a>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/aml_approval/components/filter_lock/filter_lock.html", "<div class=\"lock\" ng-class=\"{ locked: isLocked() }\">\n" +
            "  <div class=\"on-shore-tooltip-container\">\n" +
            "    <span class=\"ml-5\"\n" +
            "      tooltip-class=\"on-shore-tooltip\"\n" +
            "      tooltip-html=\"isLocked() ? 'Filters locked' : 'Filters unlocked'\"\n" +
            "      tooltip-placement=\"bottom\"\n" +
            "      tooltip-append-to-body=\"false\"\n" +
            "      tooltip-trigger=\"mouseenter\">\n" +
            "      <i class=\"fa\" ng-class=\"{'fa-unlock-alt': !isLocked(), 'fa-lock': isLocked()}\" ng-click=\"clicked()\"></i>\n" +
            "    </span>\n" +
            "    </div>\n" +
            "</div>");

        $templateCache.put("areas/aml_approval/components/internal_notes/internal_note_preview.html", "<div class=\"note-subject\">{{internal_note.subject}}</div>\n" +
            "<div class=\"note-authors\">\n" +
            "  <span class=\"note-timestamp\">{{internal_note.created_at | date:\"dd-MMM-yyyy 'at' h:mma\"}}</span>\n" +
            "  <span class=\"note-creator\">created by {{internal_note.created_by.display_name}}</span>\n" +
            "</div>\n" +
            "<div ng-show=\"internal_note.updated_by\">\n" +
            "  <span class=\"note-timestamp\">{{internal_note.updated_at | date:\"dd-MMM-yyyy 'at' h:mma\"}}</span>\n" +
            "  <span class=\"note-creator\">updated by {{internal_note.updated_by.display_name}}</span>\n" +
            "</div>\n" +
            "<p class=\"note-body\">{{internal_note.body}}</p>");

        $templateCache.put("areas/aml_approval/components/internal_notes/internal_notes_list.html", "<div class=\"internal-notes\">\n" +
            "  <p ng-show=\"errorMsg\" class=\"alert alert-danger\">{{errorMsg}} <i ng-click=\"clearErrorMsg()\" class=\"fa fa-times\" aria-hidden=\"true\"></i></p>\n" +
            "  <p ng-show=\"hasNoteTabsDescription\">{{noteTabsDescription}}</p>\n" +
            "  <div class=\"row notes no-padding\">\n" +
            "    <div class=\"col-md-4 notes-index no-padding\">\n" +
            "      <ul>\n" +
            "        <li class=\"level-label\">Investment Level <a class=\"new-note\" ng-hide=\"viewOnlyMode\" ng-click=\"addNewNote('Investment')\">+ New Note</a></li>\n" +
            "        <li ng-repeat=\"internal_note in investmentInternalNotes\"\n" +
            "            class=\"{{selectedNoteClass($index, 'Investment')}}\"\n" +
            "            ng-click=\"openInternalNote($index, 'Investment')\">\n" +
            "          <span class=\"note-required\" ng-if=\"internal_note.required\">(Required)</span>\n" +
            "          {{internal_note.subject}}\n" +
            "          <i ng-hide=\"viewOnlyMode || noteViewOnlyMode(internal_note) || internal_note.required\" ng-click=\"confirmDelete(internal_note.id)\" class=\"fa fa-times\" aria-hidden=\"true\"></i>\n" +
            "        </li>\n" +
            "        <li class=\"level-label\">Account Profile Level<a class=\"new-note\" ng-hide=\"viewOnlyMode\" ng-click=\"addNewNote('InvestorProfile')\">+ New Note</a></li>\n" +
            "        <li ng-repeat=\"internal_note in investorProfileInternalNotes\"\n" +
            "            class=\"{{selectedNoteClass($index, 'InvestorProfile')}}\"\n" +
            "            ng-click=\"openInternalNote($index, 'InvestorProfile')\">\n" +
            "          {{internal_note.subject}}\n" +
            "          <i ng-hide=\"viewOnlyMode || noteViewOnlyMode(internal_note) || internal_note.required\" ng-click=\"confirmDelete(internal_note.id)\" class=\"fa fa-times\" aria-hidden=\"true\"></i>\n" +
            "        </li>\n" +
            "        <li class=\"bottom-placeholder\"></li>\n" +
            "      </ul>\n" +
            "    </div>\n" +
            "    <div class=\"col-md-8 notes-area {{editModeClass()}}\">\n" +
            "      <!-- View mode -->\n" +
            "      <div ng-repeat=\"internal_note in investmentInternalNotes\" ng-show=\"showInternalNote($index, 'Investment')\">\n" +
            "        <div ng-include=\"'areas/aml_approval/components/internal_notes/internal_note_preview.html'\"></div>\n" +
            "        <span class=\"edit-note-icon\"><i ng-hide=\"viewOnlyMode || noteViewOnlyMode(internal_note)\" ng-click=\"editInternalNote($index, 'Investment')\" class=\"fa fa-pencil\" aria-hidden=\"true\"></i></span>\n" +
            "      </div>\n" +
            "      <div ng-repeat=\"internal_note in investorProfileInternalNotes\" ng-show=\"showInternalNote($index, 'InvestorProfile')\">\n" +
            "        <div ng-include=\"'areas/aml_approval/components/internal_notes/internal_note_preview.html'\"></div>\n" +
            "        <span class=\"edit-note-icon\"><i ng-hide=\"viewOnlyMode || noteViewOnlyMode(internal_note)\" ng-click=\"editInternalNote($index, 'InvestorProfile')\" class=\"fa fa-pencil\" aria-hidden=\"true\"></i></span>\n" +
            "      </div>\n" +
            "\n" +
            "      <!-- Edit mode -->\n" +
            "      <div ng-show=\"mode == 'edit'\" class=\"new-note-form\">\n" +
            "        <label>Subject: </label>\n" +
            "        <input type=\"text\" ng-model=\"internalNote.editable_subject\" class=\"note-subject-edit\" ng-if=\"!internalNote.required\">\n" +
            "        <span ng-if=\"internalNote.required\">{{internalNote.editable_subject}}</span>\n" +
            "        <span class=\"note-required\" ng-if=\"internalNote.required\">(Required)</span>\n" +
            "        <textarea class=\"note-body-edit\" name=\"note-body-edit\" ng-model=\"internalNote.editable_body\" rows=\"4\" placeholder=\"{{newNotePlaceholder()}}\"></textarea>\n" +
            "        <div class=\"button-wrapper submit-note\">\n" +
            "          <button ng-show=\"internalNote.id != ''\" ng-click=\"updateNote()\">Save</button>\n" +
            "          <button ng-show=\"internalNote.id == ''\" ng-click=\"createNote()\">Submit</button>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "\n" +
            "    </div>\n" +
            "  </div>\n" +
            "\n" +
            "  <a class=\"export-notes-link\" target=\"_blank\" href=\"/api/v1/notes.csv?investment_id={{investment.id}}&investor_profile_id={{investment.investor_profile_id}}\">\n" +
            "    <i class=\"fa fa-download\" aria-hidden=\"true\"></i>\n" +
            "    <span>Export notes</span>\n" +
            "  </a>\n" +
            "</div>");

        $templateCache.put("areas/aml_approval/components/internal_notes/internal_notes.html", "<ul class=\"nav nav-tabs\" role=\"tablist\" ng-hide=\"tabs.length <= 1\" data-test-id=\"notes-nav-tabs\" >\n" +
            "  <li ng-repeat=\"item in tabs track by $index\" ng-class=\"{active: item.active}\">\n" +
            "    <a href=\"\" ng-click=\"selectTab($index)\">{{item.name}}</a>\n" +
            "  </li>\n" +
            "</ul>\n" +
            "<div ng-hide=\"isWhiteLabel\" data-test-id=\"notes-tabs\">\n" +
            "  <div ng-show=\"selectedTab == 'iCapital - Internal Only'\" data-test-id=\"internal-notes\">\n" +
            "    <internal-notes-list \n" +
            "      investment='investment' \n" +
            "      mode='mode' \n" +
            "      update-counter=\"updateCounter(value)\"\n" +
            "      investment-internal-notes='investment_internal_notes'\n" +
            "      investor-profile-internal-notes='investor_profile_internal_notes' \n" +
            "      selected-tab='selectedTab'>\n" +
            "    </internal-notes-list>\n" +
            "  </div>\n" +
            "  <div ng-show=\"selectedTab == tabs[1].name\" data-test-id=\"wlp-internal-notes\">\n" +
            "    <internal-notes-list \n" +
            "      investment='investment' \n" +
            "      mode='mode' \n" +
            "      white-label-partner-id='whiteLabelPartnerId' \n" +
            "      update-counter=\"updateCounter(value)\"\n" +
            "      investment-internal-notes='investment_internal_notes_for_wlp' \n" +
            "      investor-profile-internal-notes='investor_profile_internal_notes_for_wlp'\n" +
            "      selected-tab='selectedTab'>\n" +
            "    </internal-notes-list>\n" +
            "  </div>\n" +
            "  <div ng-show=\"selectedTab == 'iCapital'\" data-test-id=\"wlp-external-notes\">\n" +
            "    <internal-notes-list \n" +
            "      investment='investment'\n" +
            "      mode='mode' \n" +
            "      white-label-partner-id='whiteLabelPartnerId' \n" +
            "      update-counter=\"updateCounter(value)\"\n" +
            "      investment-internal-notes='investment_external_notes_for_wlp' \n" +
            "      investor-profile-internal-notes='investor_profile_external_notes_for_wlp' \n" +
            "      selected-tab='selectedTab'>\n" +
            "    </internal-notes-list>\n" +
            "  </div>\n" +
            "</div>\n" +
            "<div ng-show=\"isWhiteLabel\" data-test-id=\"wlp-notes-tabs\" >\n" +
            "  <div ng-show=\"selectedTab == tabs[0].name\" data-test-id=\"wlp-internal-notes\">\n" +
            "    <internal-notes-list \n" +
            "      investment='investment' \n" +
            "      mode='mode' \n" +
            "      white-label-partner-id='whiteLabelPartnerId' \n" +
            "      update-counter=\"updateCounter(value)\"\n" +
            "      investment-internal-notes='investment_internal_notes_for_wlp' \n" +
            "      investor-profile-internal-notes='investor_profile_internal_notes_for_wlp' \n" +
            "      selected-tab='selectedTab'>\n" +
            "    </internal-notes-list>\n" +
            "  </div>\n" +
            "  <div ng-show=\"selectedTab == 'iCapital'\" data-test-id=\"wlp-external-notes\">\n" +
            "    <internal-notes-list \n" +
            "      investment='investment' \n" +
            "      mode='mode' \n" +
            "      white-label-partner-id='whiteLabelPartnerId' \n" +
            "      update-counter=\"updateCounter(value)\"\n" +
            "      investment-internal-notes='investment_external_notes_for_wlp' \n" +
            "      investor-profile-internal-notes='investor_profile_external_notes_for_wlp' \n" +
            "      selected-tab='selectedTab'>\n" +
            "    </internal-notes-list>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/aml_approval/dialogs/bulk_approval_dialog.html", "<common-modal class=\"bulk-approval\" modal-header=\"{{header}}\">\n" +
            "  <div class=\"bulk-approval-container\" ng-show=\"closes\">\n" +
            "    <div class=\"row\" ng-if=\"content.name == 'close-date-list' || content.name == 'investment-id-input'\">\n" +
            "      <label class=\"radio-button-label-space\" for=\"close-date-list\">\n" +
            "        <input type=\"radio\" id=\"close-date-list\" name=\"content\" ng-model=\"content.name\" value=\"close-date-list\" ng-change=\"resetInvestmentListContent()\" >\n" +
            "        <span>&nbsp;By Close Date</span>\n" +
            "      </label>\n" +
            "      <label class=\"radio-button-label-space\" for=\"investment-id-input\">\n" +
            "        <input type=\"radio\" id=\"investment-id-input\" name=\"content\" ng-model=\"content.name\" value=\"investment-id-input\">\n" +
            "        <span>&nbsp;By Investment ID</span>\n" +
            "      </label>\n" +
            "    </div>\n" +
            "    <div ng-if=\"content.name == 'close-date-list'\">\n" +
            "      <div class=\"row filter-border\">\n" +
            "        <div ng-if=\"showWlpFilter()\" style=\"padding: 0; margin-top: 15px; margin-bottom: 15px; padding-right: 15px;\" ng-dropdown-multiselect=\"\" options=\"wlpData\"\n" +
            "          checkboxes=\"true\" class=\"col-md-7 icn-select\" selected-model=\"selectedWlp\" extra-settings=\"settings\"\n" +
            "          translation-texts=\"wlpTranslation\" events=\"events\">\n" +
            "        </div>\n" +
            "        <div style=\"padding: 0; margin-top: 15px; margin-bottom: 15px\" ng-dropdown-multiselect=\"\" options=\"signMethods\"\n" +
            "          checkboxes=\"true\" class=\"col-md-5 icn-select\" selected-model=\"selectedSignMethods\" extra-settings=\"settings\"\n" +
            "          translation-texts=\"signMethodTranslation\" events=\"events\">\n" +
            "        </div>\n" +
            "      </div>\n" +
            "      <table class=\"table\">\n" +
            "        <thead>\n" +
            "          <th class=\"col-md-1\">\n" +
            "            <icn-checkbox custom-id=\"select-all\" ng-click=\"selectAll('close')\"\n" +
            "                          model=\"formData.selectedAll\"></icn-checkbox>\n" +
            "          </th>\n" +
            "          <th class=\"col-md-2\" cli-header order=\"close_date\">\n" +
            "            Pending Close\n" +
            "          </th>\n" +
            "          <th class=\"col-md-3\" cli-header order=\"wlp_name\">\n" +
            "            White Label Partner\n" +
            "          </th>\n" +
            "          <th class=\"col-md-2\" cli-header order=\"capitalized_sign_method\">\n" +
            "            Upload Method\n" +
            "          </th>\n" +
            "          <th class=\"col-md-1 text-align-right\" cli-header order=\"count\">\n" +
            "            # of Investors\n" +
            "          </th>\n" +
            "          <th class=\"col-md-3 text-align-right\" cli-header order=\"total_commitment\">\n" +
            "            Total Amount\n" +
            "          </th>\n" +
            "        </thead>\n" +
            "        <tbody class=\"custom-scrollbar\">\n" +
            "          <tr ng-repeat=\"close in closes | orderBy:orderByField:reverseSort track by $index\">\n" +
            "            <td class=\"col-md-1\">\n" +
            "              <icn-checkbox custom-id=\"{{$index}}\" model=\"close.checked\"\n" +
            "                            ng-click=\"resolveSelectAll(close, 'close')\"></icn-checkbox>\n" +
            "            </td>\n" +
            "            <td class=\"col-md-2\">\n" +
            "              {{close.close_date}}\n" +
            "            </td>\n" +
            "            <td class=\"col-md-3\">\n" +
            "              {{close.wlp_name}}\n" +
            "            </td>\n" +
            "            <td class=\"col-md-2\">\n" +
            "              {{close.capitalized_sign_method}}\n" +
            "            </td>\n" +
            "            <td class=\"col-md-1 text-align-right\">\n" +
            "              {{close.count}}\n" +
            "            </td>\n" +
            "            <td class=\"col-md-3 dollar-amount text-align-right\">\n" +
            "              ${{close.total_commitment | number:0}}\n" +
            "            </td>\n" +
            "          </tr>\n" +
            "        </tbody>\n" +
            "        <tfoot>\n" +
            "          <tr>\n" +
            "            <td colspan=\"3\" class=\"col-md-7\"></td>\n" +
            "            <td class=\"col-md-2 text-align-right\">\n" +
            "              {{totalOfInvestor()}}\n" +
            "            </td>\n" +
            "            <td class=\"col-md-3 text-align-right\">\n" +
            "              ${{totalAmount('close') | number:0}}\n" +
            "            </td>\n" +
            "          </tr>\n" +
            "        </tfoot>\n" +
            "      </table>\n" +
            "      <div class=\"row modal-close-actions\">\n" +
            "        <button type=\"submit\"\n" +
            "                class=\"execute-button\"\n" +
            "                ng-click=\"approve('close')\"\n" +
            "                ng-disabled=\"buttonStatus.approveDisabled\">\n" +
            "          APPROVE\n" +
            "        </button>\n" +
            "        <button type=\"button\" class=\"btn-unfilled-blue btn-hover-filled-blue\" ng-click=\"cancel()\">\n" +
            "          CANCEL\n" +
            "        </button>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "    <div class=\"investments\" ng-if=\"content.name == 'investment-id-input' && investment_list_content == false\">\n" +
            "      <textarea rows=\"3\" class=\"form-control\" id=\"investment_ids_input\" ng-model=\"investment_ids\"\n" +
            "                oninput=\"angular.element(this).scope().setSubmitButtonStatus()\"\n" +
            "                placeholder=\"Investment IDs may be entered as a comma delimited list, or copied and pasted from a spreadsheet (tip: use the ID column from the Investment Export to collect your IDs). A max of 500 IDs may be entered.\">\n" +
            "      </textarea>\n" +
            "      <div class=\"investment-id-limit\" ng-if=\"investment_meta.exceed_limit\">\n" +
            "        Investment count cannot exceed 500.\n" +
            "      </div>\n" +
            "      <div class=\"row modal-close-actions\">\n" +
            "        <button type=\"submit\" class=\"execute-button\" ng-click=\"submit(investment_ids)\" ng-disabled=\"buttonStatus.submitDisabled\">\n" +
            "          SUBMIT\n" +
            "        </button>\n" +
            "        <button type=\"button\" class=\"btn-unfilled-blue btn-hover-filled-blue\" ng-click=\"cancel()\">\n" +
            "          CANCEL\n" +
            "        </button>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "    <div class=\"investments\" ng-if=\"investment_list_content == true\">\n" +
            "      <table class=\"table\">\n" +
            "        <thead>\n" +
            "        <th class=\"col-md-1\">\n" +
            "          <icn-checkbox custom-id=\"select-all\" ng-click=\"selectAll('investment')\"\n" +
            "                        model=\"formData.selectedAll\"></icn-checkbox>\n" +
            "        </th>\n" +
            "        <th class=\"col-md-1\" cli-header order=\"id\">\n" +
            "          ID\n" +
            "        </th>\n" +
            "        <th class=\"col-md-1\" cli-header order=\"type\">\n" +
            "          Type\n" +
            "        </th>\n" +
            "        <th class=\"col-md-3\" cli-header order=\"investor_profile.name\">\n" +
            "          Account Name\n" +
            "        </th>\n" +
            "        <th class=\"col-md-3\" cli-header order=\"ria_firm\">\n" +
            "          Firm\n" +
            "        </th>\n" +
            "        <th class=\"col-md-1 text-align-right\" cli-header order=\"commitment\">\n" +
            "          Amount\n" +
            "        </th>\n" +
            "        <th class=\"col-md-2 text-align-right\" cli-header order=\"close_date\">\n" +
            "          Next Close\n" +
            "        </th>\n" +
            "        </thead>\n" +
            "        <tbody class=\"custom-scrollbar\">\n" +
            "        <tr ng-repeat=\"investment in investments | orderBy:orderByField:reverseSort track by $index\">\n" +
            "          <td class=\"col-md-1\">\n" +
            "            <icn-checkbox custom-id=\"{{$index}}\" model=\"investment.checked\"\n" +
            "                          ng-click=\"resolveSelectAll(investment, 'investment')\"></icn-checkbox>\n" +
            "          </td>\n" +
            "          <td class=\"col-md-1\">\n" +
            "            {{investment.id}}\n" +
            "          </td>\n" +
            "          <td class=\"col-md-1\">\n" +
            "            {{investment.type}}\n" +
            "          </td>\n" +
            "          <td class=\"col-md-3\">\n" +
            "            {{investment.investor_profile.name}}\n" +
            "          </td>\n" +
            "          <td class=\"col-md-3\">\n" +
            "            {{investment.ria_firm}}\n" +
            "          </td>\n" +
            "          <td class=\"col-md-1 dollar-amount text-align-right\">\n" +
            "            ${{investment.commitment | number:0}}\n" +
            "          </td>\n" +
            "          <td class=\"col-md-2 text-align-right\">\n" +
            "            {{investment.close_date}}\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        </tbody>\n" +
            "        <tfoot>\n" +
            "        <tr>\n" +
            "          <td colspan=\"3\" class=\"col-md-9 investment-result-info\">\n" +
            "            {{investment_meta.found_investments}} of {{investment_meta.total_investments}} investments found.\n" +
            "          </td>\n" +
            "          <td class=\"col-md-1 text-align-right\">\n" +
            "            ${{totalAmount('investment') | number:0}}\n" +
            "          </td>\n" +
            "          <td colspan=\"3\" class=\"col-md-2\"></td>\n" +
            "        </tr>\n" +
            "        </tfoot>\n" +
            "      </table>\n" +
            "      <div class=\"row modal-close-actions\">\n" +
            "        <button type=\"submit\"\n" +
            "                class=\"execute-button\"\n" +
            "                ng-click=\"approve('investment')\"\n" +
            "                ng-disabled=\"buttonStatus.approveDisabled\">\n" +
            "          APPROVE\n" +
            "        </button>\n" +
            "        <button type=\"button\" class=\"btn-unfilled-blue btn-hover-filled-blue\" ng-click=\"cancel()\">\n" +
            "          CANCEL\n" +
            "        </button>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "    <div ng-if=\"content.name == 'confirmation'\">\n" +
            "      <div class=\"investments-approval-statement\">\n" +
            "        You are about to approve {{approval_number}} investment(s). Would you like to continue?\n" +
            "      </div>\n" +
            "      <div class=\"row modal-close-actions\">\n" +
            "        <button type=\"submit\" class=\"execute-button\" ng-click=\"confirmed()\">\n" +
            "          YES\n" +
            "        </button>\n" +
            "        <button type=\"button\" class=\"btn-unfilled-blue btn-hover-filled-blue\" ng-click=\"cancel()\">\n" +
            "          NO\n" +
            "        </button>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/aml_approval/dialogs/customize_view_dialog.html", "<common-modal modal-header=\"Customize View\">\n" +
            "  <div class=\"modal-header-combined__content sub-review-customize-view\">\n" +
            "    <div class=\"investments-statuses\">Show Subscription Review Statuses for:</div>\n" +
            "    <div class=\"row checkboxes-container\">\n" +
            "      <div class=\"col-md-6 checkboxes\">\n" +
            "        <div ng-repeat=\"option in checkboxes\" ng-if=\"forFirstColumn($index)\">\n" +
            "          <div class=\"options\">\n" +
            "            <icn-checkbox\n" +
            "              custom-id=\"{{option.id}}\"\n" +
            "              label=\"{{statusLabel(option.id)}}\"\n" +
            "              model=\"option.selected\">\n" +
            "            </icn-checkbox>\n" +
            "            <span class=\"total-label\">{{labelInvestmentsCount(option.id)}}</span>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "      <div class=\"col-md-6 checkboxes\">\n" +
            "        <div ng-repeat=\"option in checkboxes\" ng-if=\"forSecondColumn($index)\">\n" +
            "          <div class=\"options\">\n" +
            "            <icn-checkbox\n" +
            "              custom-id=\"{{option.id}}\"\n" +
            "              label=\"{{statusLabel(option.id)}}\"\n" +
            "              model=\"option.selected\">\n" +
            "            </icn-checkbox>\n" +
            "            <span class=\"total-label\">{{labelInvestmentsCount(option.id)}}</span>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"row modal-actions action-buttons\">\n" +
            "      <button type=\"submit\" class=\"right-aligned-btn save-button save-btn\" ng-click=\"saveCustomView()\" ng-disabled=\"disableSaveViewBtn()\">\n" +
            "        SAVE\n" +
            "      </button>\n" +
            "      <button type=\"submit\" class=\"right-aligned-btn button__decline cancel-btn\" ng-click=\"dismissModal()\">\n" +
            "        CANCEL\n" +
            "      </button>\n" +
            "    </div>\n" +
            "\n" +
            "</common-modal>");

        $templateCache.put("areas/aml_approval/dialogs/edit_close_schedule.html", "<common-modal class=\"planned-closes-modal-override\" modal-header=\"Edit Close Schedule\">\n" +
            "  <div class=\"modal-header-combined__content investmentSubscription planned-closes-modal aml-approval-modal manage\">\n" +
            "    <div ng-if=\"isOpenFund\">\n" +
            "      <div>\n" +
            "        <form id=\"plannedClosesForm\" class=\"css-form\" novalidate>\n" +
            "          <div class=\"row add-new-close-form\">\n" +
            "            <div class=\"col-xs-4 add-new-close-dates\">\n" +
            "              <div class=\"heading-6\">Close Date</div>\n" +
            "              <i id=\"calendar-close\" class=\"fa fa-calendar\" aria-hidden=\"true\"></i>\n" +
            "              <input\n" +
            "                name=\"closeDate\"\n" +
            "                autocomplete=\"off\"\n" +
            "                type=\"text\"\n" +
            "                ui-date=\"newCloseDate\"\n" +
            "                ui-date-format=\"yy-M-dd\"\n" +
            "                class=\"form-control\"\n" +
            "                ng-model=\"formData.closeDate\"\n" +
            "                placeholder=\"Close Date\"\n" +
            "              />\n" +
            "            </div>\n" +
            "            <div class=\"col-xs-4 add-new-close-dates\">\n" +
            "              <div class=\"heading-6\">Due Date</div>\n" +
            "              <i id=\"calendar-close\" class=\"fa fa-calendar\" aria-hidden=\"true\"></i>\n" +
            "              <input\n" +
            "                name=\"docDueDate\"\n" +
            "                autocomplete=\"off\"\n" +
            "                type=\"text\"\n" +
            "                ui-date=\"newDocDueDate\"\n" +
            "                ui-date-format=\"yy-M-dd\"\n" +
            "                class=\"form-control\"\n" +
            "                ng-model=\"formData.docDueDate\"\n" +
            "                placeholder=\"Due Date\" />\n" +
            "            </div>\n" +
            "            <div ng-if=\"wiresDueRequired\" class=\"col-xs-4 add-new-close-dates\">\n" +
            "              <div class=\"heading-6\">Wires Due</div>\n" +
            "              <i id=\"calendar-close\" class=\"fa fa-calendar\" aria-hidden=\"true\"></i>\n" +
            "              <input\n" +
            "                name=\"wiresDueDate\"\n" +
            "                autocomplete=\"off\"\n" +
            "                type=\"text\"\n" +
            "                ui-date=\"newWiresDueDate\"\n" +
            "                ui-date-format=\"yy-M-dd\"\n" +
            "                class=\"form-control\"\n" +
            "                ng-model=\"formData.wiresDueDate\"\n" +
            "                placeholder=\"Wires Due Date\" />\n" +
            "            </div>\n" +
            "            <div ng-if=\"preCloseRequired\" class=\"col-xs-5 add-new-close-dates\">\n" +
            "              <div class=\"heading-6\">Funding Start</div>\n" +
            "              <i id=\"calendar-close\" class=\"fa fa-calendar\" aria-hidden=\"true\"></i>\n" +
            "              <input\n" +
            "                name=\"fundingWindowStart\"\n" +
            "                autocomplete=\"off\"\n" +
            "                type=\"text\"\n" +
            "                ui-date=\"newFundingWindowStartDate\"\n" +
            "                ui-date-format=\"yy-M-dd\"\n" +
            "                class=\"form-control\"\n" +
            "                ng-model=\"formData.fundingWindowStart\"\n" +
            "                placeholder=\"Funding Start\"\n" +
            "              />\n" +
            "            </div>\n" +
            "            <div ng-if=\"preCloseRequired\" class=\"col-xs-5 add-new-close-dates\">\n" +
            "              <div class=\"heading-6\">Funding End</div>\n" +
            "              <i id=\"calendar-close\" class=\"fa fa-calendar\" aria-hidden=\"true\"></i>\n" +
            "              <input\n" +
            "                name=\"fundingWindowEnd\"\n" +
            "                autocomplete=\"off\"\n" +
            "                type=\"text\"\n" +
            "                ui-date=\"newFundingWindowEndDate\"\n" +
            "                ui-date-format=\"yy-M-dd\"\n" +
            "                class=\"form-control\"\n" +
            "                ng-model=\"formData.fundingWindowEnd\"\n" +
            "                placeholder=\"Funding End\"\n" +
            "              />\n" +
            "            </div>\n" +
            "            <div ng-if=\"preCloseRequired\" class=\"col-xs-5 add-new-close-dates\">\n" +
            "              <div class=\"heading-6\">Initial Call Percentage</div>\n" +
            "              <input\n" +
            "                name=\"fundingCallPercentage\"\n" +
            "                autocomplete=\"off\"\n" +
            "                type=\"text\"\n" +
            "                ui-percentage-mask\n" +
            "                class=\"form-control non-calendar-box\"\n" +
            "                ng-model=\"formData.fundingCallPercentage\"\n" +
            "                placeholder=\"Initial Call Percent\"\n" +
            "              />\n" +
            "            </div>\n" +
            "            <div class=\"col-xs-5 add-new-close\">\n" +
            "              <button class=\"align-button btn-unfilled-blue\" ng-disabled=\"cannotSubmit()\" type=\"submit\" ng-click=\"createClose()\">\n" +
            "                ADD NEW\n" +
            "              </button>\n" +
            "            </div>\n" +
            "          </div>\n" +
            "        </form>\n" +
            "      </div>\n" +
            "\n" +
            "      <div class=\"row planned-closes-container closes-table\">\n" +
            "        <table class=\"table\">\n" +
            "          <thead>\n" +
            "            <tr>\n" +
            "              <th class=\"document-type\">Close Date</th>\n" +
            "              <th class=\"document-type\">Commitments</th>\n" +
            "              <th class=\"document-type\">Due Date</th>\n" +
            "              <th ng-if=\"wiresDueRequired\" class=\"document-type\">Wires Due Date</th>\n" +
            "              <th ng-if=\"preCloseRequired\" class=\"document-type\">Funding Start</th>\n" +
            "              <th ng-if=\"preCloseRequired\" class=\"document-type\">Funding End</th>\n" +
            "              <th ng-if=\"preCloseRequired\" class=\"document-type\">Initial Call Percentage</th>\n" +
            "              <th class=\"document-type\"></th>\n" +
            "            </tr>\n" +
            "          </thead>\n" +
            "          <tbody>\n" +
            "            <tr ng-repeat=\"plannedClose in plannedCloses\" ng-class=\"{'highlight' : plannedClose.edit_mode}\">\n" +
            "              <!-- Close date -->\n" +
            "              <td>\n" +
            "                <div ng-if=\"!plannedClose.edit_mode\">\n" +
            "                  {{plannedClose.close_date | date:'dd-MMM-yyyy'}}\n" +
            "                </div>\n" +
            "                <div class=\"date-input\" ng-if=\"plannedClose.edit_mode\">\n" +
            "                  <i id=\"calendar-close\" class=\"fa fa-calendar\" aria-hidden=\"true\"></i>\n" +
            "                  <input\n" +
            "                    name=\"editCloseDate\"\n" +
            "                    type=\"text\"\n" +
            "                    ui-date=\"editCloseDate\"\n" +
            "                    ui-date-format=\"yy-M-dd\"\n" +
            "                    class=\"form-control\"\n" +
            "                    ng-model=\"formData.edit.closeDate\"\n" +
            "                  />\n" +
            "                </div>\n" +
            "              </td>\n" +
            "              <td class=\"dollar-amount\" ng-class=\"{'highlight-column' : plannedClose.edit_mode}\">\n" +
            "                ${{plannedClose.investments_commitment_total | number:0}}\n" +
            "              </td>\n" +
            "              <!-- Doc due date -->\n" +
            "              <td>\n" +
            "                <div ng-if=\"!plannedClose.edit_mode\">\n" +
            "                  {{plannedClose.doc_due_date | date:'dd-MMM-yyyy'}}\n" +
            "                </div>\n" +
            "                <div class=\"date-input\" ng-if=\"plannedClose.edit_mode\">\n" +
            "                  <i id=\"calendar-close\" class=\"fa fa-calendar\" aria-hidden=\"true\"></i>\n" +
            "                  <input\n" +
            "                    name=\"editDocDueDate\"\n" +
            "                    type=\"text\"\n" +
            "                    ui-date=\"editDocDueDate\"\n" +
            "                    ui-date-format=\"yy-M-dd\"\n" +
            "                    class=\"form-control\"\n" +
            "                    ng-model=\"formData.edit.docDueDate\"\n" +
            "                  />\n" +
            "                </div>\n" +
            "              </td>\n" +
            "              <!-- Wires due date -->\n" +
            "              <td ng-if=\"wiresDueRequired\">\n" +
            "                <div ng-if=\"!plannedClose.edit_mode\">\n" +
            "                  {{plannedClose.wires_due_date | date:'dd-MMM-yyyy'}}\n" +
            "                </div>\n" +
            "                <div class=\"date-input\" ng-if=\"plannedClose.edit_mode\">\n" +
            "                  <i id=\"calendar-close\" class=\"fa fa-calendar\" aria-hidden=\"true\"></i>\n" +
            "                  <input\n" +
            "                    name=\"editWiresDueDate\"\n" +
            "                    type=\"text\"\n" +
            "                    ui-date=\"editWiresDueDate\"\n" +
            "                    ui-date-format=\"yy-M-dd\"\n" +
            "                    class=\"form-control\"\n" +
            "                    ng-model=\"formData.edit.wiresDueDate\" />\n" +
            "                </div>\n" +
            "              </td>\n" +
            "              <!-- Funding Window Start date -->\n" +
            "              <td ng-if=\"preCloseRequired\">\n" +
            "                <div ng-if=\"!plannedClose.edit_mode\">\n" +
            "                  {{plannedClose.funding_window_start | date:'dd-MMM-yyyy'}}\n" +
            "                </div>\n" +
            "                <div class=\"date-input\" ng-if=\"plannedClose.edit_mode\">\n" +
            "                  <i id=\"calendar-close\" class=\"fa fa-calendar\" aria-hidden=\"true\"></i>\n" +
            "                  <input\n" +
            "                    name=\"editFundingWindowStartDate\"\n" +
            "                    type=\"text\"\n" +
            "                    ui-date=\"editFundingWindowStartDate\"\n" +
            "                    ui-date-format=\"yy-M-dd\"\n" +
            "                    class=\"form-control\"\n" +
            "                    ng-model=\"formData.edit.fundingWindowStart\"\n" +
            "                  />\n" +
            "                </div>\n" +
            "              </td>\n" +
            "              <!-- Funding Window End date -->\n" +
            "              <td ng-if=\"preCloseRequired\">\n" +
            "                <div ng-if=\"!plannedClose.edit_mode\">\n" +
            "                  {{plannedClose.funding_window_end | date:'dd-MMM-yyyy'}}\n" +
            "                </div>\n" +
            "                <div class=\"date-input\" ng-if=\"plannedClose.edit_mode\">\n" +
            "                  <i id=\"calendar-close\" class=\"fa fa-calendar\" aria-hidden=\"true\"></i>\n" +
            "                  <input\n" +
            "                    name=\"editFundingWindowEndDate\"\n" +
            "                    type=\"text\"\n" +
            "                    ui-date=\"editFundingWindowEndDate\"\n" +
            "                    ui-date-format=\"yy-M-dd\"\n" +
            "                    class=\"form-control\"\n" +
            "                    ng-model=\"formData.edit.fundingWindowEnd\"\n" +
            "                  />\n" +
            "                </div>\n" +
            "              </td>\n" +
            "              <!-- Funding Call Percentage -->\n" +
            "              <td ng-if=\"preCloseRequired\">\n" +
            "                <div ng-if=\"!plannedClose.edit_mode\">\n" +
            "                  {{plannedClose.funding_call_percentage | percentage}}\n" +
            "                </div>\n" +
            "                <div class=\"date-input\" ng-if=\"plannedClose.edit_mode\">\n" +
            "                  <input\n" +
            "                    name=\"editFundingCallPercentage\"\n" +
            "                    type=\"text\"\n" +
            "                    ui-percentage-mask\n" +
            "                    class=\"form-control non-calendar-box\"\n" +
            "                    ng-model=\"formData.edit.fundingCallPercentage\"\n" +
            "                  />\n" +
            "                </div>\n" +
            "              </td>\n" +
            "              <!-- Edit close -->\n" +
            "              <td ng-class=\"{'highlight-column' : plannedClose.edit_mode}\">\n" +
            "                <i\n" +
            "                  ng-if=\"!plannedClose.edit_mode\"\n" +
            "                  ng-click=\"editClose(plannedClose)\"\n" +
            "                  class=\"fa fa-pencil edit-close\"\n" +
            "                  tooltip-placement=\"bottom\"\n" +
            "                  tooltip-class=\"on-shore-tooltip\"\n" +
            "                  tooltip-html=\"'Edit'\"\n" +
            "                >\n" +
            "                </i>\n" +
            "                <i\n" +
            "                  ng-if=\"plannedClose.edit_mode\"\n" +
            "                  ng-click=\"updateClose(plannedClose)\"\n" +
            "                  class=\"fa fa-check save-close\"\n" +
            "                  tooltip-placement=\"bottom\"\n" +
            "                  tooltip-class=\"on-shore-tooltip\"\n" +
            "                  tooltip-html=\"'Save'\"\n" +
            "                  >\n" +
            "                </i>\n" +
            "              </td>\n" +
            "              <!-- Delete close -->\n" +
            "              <td ng-class=\"{'highlight-column' : plannedClose.edit_mode}\">\n" +
            "                <i\n" +
            "                  class=\"fa fa-times-circle-o fa-lg delete-close\"\n" +
            "                  ng-click=\"removeClose(plannedClose)\"\n" +
            "                  aria-hidden=\"true\"\n" +
            "                  tooltip-placement=\"bottom\"\n" +
            "                  tooltip-class=\"on-shore-tooltip\"\n" +
            "                  tooltip-html=\"'Delete'\"\n" +
            "                >\n" +
            "                </i>\n" +
            "              </td>\n" +
            "            </tr>\n" +
            "          </tbody>\n" +
            "        </table>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"row modal-actions confirm-button\">\n" +
            "      <button type=\"submit\" class=\"right-aligned-btn button__decline\" ng-click=\"dismissModal()\">\n" +
            "        OK\n" +
            "      </button>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/aml_approval/dialogs/edit_investment.html", "<common-modal modal-header=\"Edit Investment\">\n" +
            "  <div class=\"aml-approval-modal mt-1\">\n" +
            "    <div class=\"row\" ng-if=\"pafSeries.length > 0\">\n" +
            "      <label class=\"m-0 col-md-6\">Class of Interests:</label>\n" +
            "      <icn-dropdown-wrapper class=\"select-container icn-dropdown col-md-6 edit-investment\">\n" +
            "        <select ng-model=\"seriesNameUpdated\"\n" +
            "                ng-options=\"serie.name for serie in pafSeries | orderBy:'name' track by serie.name\"\n" +
            "                class=\"form-control icn-select\"\n" +
            "                ng-change=\"seriesNameUpdate(seriesNameUpdated)\">\n" +
            "          <option value=\"\"></option>\n" +
            "        </select>\n" +
            "      </icn-dropdown-wrapper>\n" +
            "    </div>\n" +
            "    <div class=\"row\" ng-if=\"subBooks.length > 1\">\n" +
            "      <label class=\"m-0 col-md-6\">Sub Doc Type:</label>\n" +
            "      <icn-dropdown-wrapper class=\"select-container icn-dropdown col-md-6 edit-investment\">\n" +
            "        <select ng-model=\"subDocTypeUpdated\"\n" +
            "                ng-options=\"subBook.icn_name for subBook in subBooks | orderBy:'icn_name' track by subBook.icn_name\"\n" +
            "                class=\"form-control icn-select\"\n" +
            "                ng-change=\"subDocTypeUpdate(subDocTypeUpdated)\">\n" +
            "          <option value=\"\"></option>\n" +
            "        </select>\n" +
            "      </icn-dropdown-wrapper>\n" +
            "    </div>\n" +
            "    <div ng-if=\"canEditPlacementFee()\">\n" +
            "      <div class=\"placement-fee_box\" ng-if=\"!withAllowedPlacementFees\">\n" +
            "        <label class=\"placement-fee_label\">Placement Fee:</label>\n" +
            "        <input ui-percentage-mask ui-percentage-value type=\"text\" name=\"placement_fee\" class=\"placement-fee_input\"\n" +
            "          ng-model=\"placementForm.placement_fee\" ng-class=\"{invalid: !validPlacementFee()}\">\n" +
            "        <span class=\"placement-fee_error error\" ng-if=\"placementFeeOverMaxValue() && !mandatoryPlacementFeeChanged()\">\n" +
            "          Maximum placement fee: {{placementFeeRange.max_placement_fee}}%\n" +
            "        </span>\n" +
            "        <span class=\"placement-fee_error error\" ng-if=\"placementFeeUnderMinValue() && !mandatoryPlacementFeeChanged()\">\n" +
            "          Minimum placement fee: {{placementFeeRange.min_placement_fee}}%\n" +
            "        </span>\n" +
            "        <span class=\"placement-fee_error error\" ng-if=\"mandatoryPlacementFeeChanged()\">\n" +
            "          Mandatory placement fee: {{placementFeeRange.min_placement_fee}}%\n" +
            "        </span>\n" +
            "      </div>\n" +
            "      <div class=\"placement-fee_box\" ng-if=\"withAllowedPlacementFees\">\n" +
            "        <label class=\"m-0 col-md-6\">Placement Fee:</label>\n" +
            "        <icn-dropdown-wrapper class=\"select-container icn-dropdown col-md-6 edit-investment\">\n" +
            "          <select\n" +
            "            ng-model=\"placementForm.placement_fee\"\n" +
            "            ng-options=\"option for option in allowedPlacementFees\" class=\"form-control icn-select\">\n" +
            "          </select>\n" +
            "        </icn-dropdown-wrapper>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "    <div ng-if=\"canEditSubAdvisoryFee()\">\n" +
            "      <div class=\"placement-fee_box\">\n" +
            "        <label class=\"placement-fee_label\">Sub-Advisory Fee:</label>\n" +
            "        <input ui-percentage-mask ui-percentage-value type=\"text\" name=\"sub_advisory_fee\" class=\"placement-fee_input\"\n" +
            "          ng-model=\"placementForm.sub_advisory_fee\" ng-class=\"{invalid: !validSubAdvisoryFee()}\">\n" +
            "        <span class=\"placement-fee_error error\" ng-if=\"subAdvisoryFeeOverMaxValue()\">\n" +
            "          Maximum fee: {{investment.firm_fee_agreement.max_sub_advisory_fee}}%\n" +
            "        </span>\n" +
            "        <span class=\"placement-fee_error error\" ng-if=\"subAdvisoryFeeUnderMinValue()\">\n" +
            "          Minimum fee: {{investment.min_sub_advisory_fee}}%\n" +
            "        </span>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "    <br />\n" +
            "    <div class=\"row modal-actions\">\n" +
            "      <button type=\"submit\" class=\"right-aligned-btn save-button\" ng-disabled=\"disableUpdateInvestmentButton()\"\n" +
            "        ng-click=\"updateInvestment()\">\n" +
            "        SAVE\n" +
            "      </button>\n" +
            "      <button type=\"submit\" class=\"right-aligned-btn button__decline\" ng-click=\"dismissModal()\">\n" +
            "        CANCEL\n" +
            "      </button>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/aml_approval/dialogs/internal_notes_modal/internal_notes_modal.html", "<common-modal class=\"investmentSubscription internal-notes-modal\" modal-header=\"{{header}}\">\n" +
            "\n" +
            "  <internal-notes investment=\"investment\"></internal-notes>\n" +
            "\n" +
            "  <div class=\"row\">\n" +
            "    <button type=\"submit\" class=\"right-aligned-btn button__decline\" ng-click=\"dismissModal()\">\n" +
            "      CANCEL\n" +
            "    </button>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/aml_approval/dialogs/keep_file_modal/keep_file_modal.html", "<common-modal modal-header=\"{{header}}\">\n" +
            "  <div class=\"aml-approval-modal\">\n" +
            "    <div style=\"color: red\" ng-show=\"errorMsg\">\n" +
            "      {{ errorMsg }}\n" +
            "    </div>\n" +
            "    <div>\n" +
            "      {{ body }}\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"approval-comments\">\n" +
            "      <textarea ng-change=\"errorMsg = ''\" ng-model=\"newComment.body\" rows=\"4\" placeholder=\"Enter comment here...\"></textarea>\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"row modal-actions\">\n" +
            "      <button type=\"submit\" class=\"right-aligned-btn save-button\"\n" +
            "        ng-disabled=\"newComment.body.length < 1\"\n" +
            "        ng-click=\"saveCommentReason()\">\n" +
            "        SAVE\n" +
            "      </button>\n" +
            "      <button type=\"submit\" class=\"right-aligned-btn button__decline\" ng-click=\"dismissModal()\">\n" +
            "        CANCEL\n" +
            "      </button>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/aml_approval/dialogs/manual_upload_gp_dialog.html", "<common-modal modal-header=\"{{header}}\">\n" +
            "  <div ng-switch=\"uploadStatus\" class=\"upload-status\">\n" +
            "    <div class=\"no-extra-margin\">\n" +
            "      <p>Upload a General Partnet Signature by <strong>{{investment.investor_name}}</strong> for <strong>{{investment.fund_name}}</strong>.</p>\n" +
            "      <div class=\"callout callout-warning\">\n" +
            "        <p><strong>NOTE:</strong> This action will <strong>overwrite</strong> any existing Countersignature for this investment.</p>\n" +
            "      </div>\n" +
            "      <div class=\"upload-container\">\n" +
            "\n" +
            "        <button ng-click=\"picker()\" ng-switch-when=\"none\" id=\"upload-select-btn\" class=\"file-upload upload-border\">\n" +
            "          <input onclick=\"event.stopPropagation()\" class=\"file-input\" type=\"file\" id=\"subdoc-file{{investment.id}}\" name='subdoc-file{{investment.id}}' onchange=\"angular.element(this).scope().uploadDoc(this.files)\" />\n" +
            "          <img src=\"images/drag_drop.svg\" height=\"30\" width=\"48\">\n" +
            "          <div class=\"sm-mrg\">Drop file here or <br><a>browse files</a></div>\n" +
            "        </button>\n" +
            "\n" +
            "        <div ng-switch-when=\"loading\" class=\"loader-container\"><div class=\"loader\"></div></div>\n" +
            "\n" +
            "        <div ng-switch-when=\"complete\" class=\"vert-box\">\n" +
            "          <div style=\"display:inline-block;\">\n" +
            "            <img class=\"check-splash\" src=\"images/checkmark.svg\">\n" +
            "            <div class=\"check-splash-text\">Upload Successful</div>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "\n" +
            "        <button ng-click=\"picker()\" ng-switch-when=\"success\"  id=\"upload-select-btn\" class=\"file-upload upload-border\" ng-class=\"{transparent : fade}\">\n" +
            "          <input onclick=\"event.stopPropagation()\" class=\"file-input\" type=\"file\" id=\"subdoc-file{{investment.id}}\" name='subdoc-file{{investment.id}}' onchange=\"angular.element(this).scope().uploadDoc(this.files)\" />\n" +
            "          <div class=\"vert-box left-box\">\n" +
            "            <div class=\"vert-box bkgrnd\">\n" +
            "              <img ng-if=\"preview\" ng-src=\"{{preview}}\">\n" +
            "              <img ng-if=\"!preview\" ng-src=\"images/sub_thumb.png\">\n" +
            "            </div>\n" +
            "          </div>\n" +
            "          <div class=\"right-box\">\n" +
            "            <span>Uploaded</span>\n" +
            "            <div></div>\n" +
            "            <a>Upload new file</a>\n" +
            "          </div>\n" +
            "        </button>\n" +
            "\n" +
            "        <button ng-click=\"picker()\" ng-switch-when=\"error\" id=\"upload-select-btn\" class=\"file-upload upload-border transparent\">\n" +
            "          <input onclick=\"event.stopPropagation()\" class=\"file-input\" type=\"file\" id=\"subdoc-file{{investment.id}}\" name='subdoc-file{{investment.id}}' onchange=\"angular.element(this).scope().uploadDoc(this.files)\" />\n" +
            "          <div class=\"upload-error\">There was an error uploading your document. Please try again.</div>\n" +
            "          <div class=\"sm-mrg\">Drop file here or <a>browse files</a></div>\n" +
            "        </button>\n" +
            "\n" +
            "      </div>\n" +
            "    </div>\n" +
            "    <div class=\"clearfix\">\n" +
            "      <button class=\"right-aligned-btn\" ng-click=\"cancelDialog()\">Done</button>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/aml_approval/dialogs/notes/add_or_view_note.html", "<common-modal modal-header=\"{{modalHeader()}}\">\n" +
            "  <div class=\"sub-review-notes mt-1\">\n" +
            "    <form id=\"sub_review_note_form\">\n" +
            "      <div class=\"row\">\n" +
            "        <h3 class=\"fund-name pb-11\">\n" +
            "          {{selectedPaf.name}}\n" +
            "        </h3>\n" +
            "      </div>\n" +
            "      <div class=\"row pb-15\">\n" +
            "        <input\n" +
            "          name=\"noteSubject\"\n" +
            "          ng-if=\"addMode()\"\n" +
            "          type=\"text\"\n" +
            "          ng-model=\"noteForm.note.subject\"\n" +
            "          placeholder=\"Subject\"\n" +
            "          class=\"note-field note-title\"\n" +
            "        />\n" +
            "        <div ng-if=\"viewMode()\" class=\"note-field note-title\">\n" +
            "          {{selectedPaf.note.subject}}\n" +
            "        </div>\n" +
            "      </div>\n" +
            "      <div class=\"row pb-15\">\n" +
            "        <textarea\n" +
            "          name=\"noteBody\"\n" +
            "          ng-if=\"addMode()\"\n" +
            "          ng-model=\"noteForm.note.body\"\n" +
            "          rows=\"9\"\n" +
            "          placeholder=\"Add New Note...\"\n" +
            "          class=\"note-field note-body\">\n" +
            "        </textarea>\n" +
            "        <div ng-if=\"viewMode()\" class=\"scrollable-span note-field note-body\">\n" +
            "          {{selectedPaf.note.body}}\n" +
            "        </div>\n" +
            "      </div>\n" +
            "      <div class=\"row button-wrapper mt-10\" ng-if=\"viewMode()\">\n" +
            "        <div class=\"col-md-6 pl-0\">\n" +
            "          <div ng-if=\"canAddSubReviewNotes()\">\n" +
            "            <a href=\"\" class=\"mr-7\" ng-click=\"switchToAddMode()\">\n" +
            "              Edit\n" +
            "            </a>\n" +
            "            |\n" +
            "            <a href=\"\" class=\"ml-7\" ng-click=\"openDeleteNoteModal(selectedPaf, selectedPaf.note)\">\n" +
            "              Delete\n" +
            "            </a>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "        <div class=\"col-md-6\">\n" +
            "          <button type=\"submit\" class=\"save-button save-btn\" ng-click=\"dismissModal()\">\n" +
            "            OK\n" +
            "          </button>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "      <div class=\"row button-wrapper\" ng-if=\"addMode()\">\n" +
            "        <button\n" +
            "          ng-if=\"!selectedPaf.note\"\n" +
            "          type=\"submit\"\n" +
            "          class=\"save-button save-btn ml-10\"\n" +
            "          ng-click=\"createNote()\"\n" +
            "          ng-disabled=\"disableSaveButton()\">\n" +
            "          SAVE\n" +
            "        </button>\n" +
            "        <button\n" +
            "          ng-if=\"selectedPaf.note\"\n" +
            "          type=\"submit\"\n" +
            "          class=\"save-button save-btn ml-10\"\n" +
            "          ng-click=\"updateNote()\"\n" +
            "          ng-disabled=\"disableSaveButton()\">\n" +
            "          UPDATE\n" +
            "        </button>\n" +
            "        <button type=\"submit\" class=\"button__decline cancel-btn\" ng-click=\"dismissModal()\">\n" +
            "          CANCEL\n" +
            "        </button>\n" +
            "      </div>\n" +
            "    </form>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/aml_approval/dialogs/notes/delete.html", "<common-modal modal-header=\"Delete Note\">\n" +
            "  <div class=\"sub-review-notes mt-1\">\n" +
            "    <div class=\"row\">\n" +
            "      <h3 class=\"fund-name\">\n" +
            "        {{selectedPaf.name}}\n" +
            "      </h3>\n" +
            "    </div>\n" +
            "    <div class=\"row note-field note-title pb-30\">\n" +
            "      {{selectedPaf.note.subject}}\n" +
            "    </div>\n" +
            "    <div class=\"row note-field note-body\">\n" +
            "      Are you sure you want to delete this note?\n" +
            "    </div>\n" +
            "    <div class=\"row delete-modal-ruler\"></div>\n" +
            "    <div class=\"row button-wrapper mt-20\">\n" +
            "      <button type=\"submit\" class=\"save-button save-btn ml-10\" ng-click=\"deleteNote()\">\n" +
            "        YES\n" +
            "      </button>\n" +
            "      <button type=\"submit\" class=\"button__decline cancel-btn\" ng-click=\"dismissModal()\">\n" +
            "        NO\n" +
            "      </button>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/aml_approval/dialogs/override_approval_status_dialog.html", "<common-modal modal-header=\"Override Status\">\n" +
            "  <div class=\"override-approval-status-modal\">\n" +
            "    <br></br>\n" +
            "\n" +
            "    <div class=\"status-options\">\n" +
            "      <div>Override investment status to:</div>\n" +
            "      <label ng-repeat=\"choice in statusOptions\">\n" +
            "        <input type=\"radio\" name=\"choice.id\" ng-model=\"overrideStatusSelected.value\" ng-value=\"choice.label\" />\n" +
            "        {{choice.label}}\n" +
            "      </label>\n" +
            "    </div>\n" +
            "    <div class=\"row\">\n" +
            "      <button type=\"submit\" class=\"right-aligned-btn save-button\"\n" +
            "        ng-disabled=\"disableStatusOverride()\"\n" +
            "        ng-click=\"overrideApprovalStatus()\">\n" +
            "        OK\n" +
            "      </button>\n" +
            "      <button type=\"submit\" class=\"right-aligned-btn button__decline\" ng-click=\"dismissModal()\">\n" +
            "        CANCEL\n" +
            "      </button>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/aml_approval/dialogs/recall_investment_dialog.html", "<common-modal modal-header=\"{{header}}\">\n" +
            "  <div class=\"aml-approval-modal\">\n" +
            "    <h5>{{msg()}}</h5>\n" +
            "    <div class=\"row modal-actions\">\n" +
            "      <button type=\"submit\" class=\"right-aligned-btn save-button\"\n" +
            "        ng-click=\"recallInvestment()\">\n" +
            "        OK\n" +
            "      </button>\n" +
            "      <button type=\"submit\" class=\"right-aligned-btn button__decline\" ng-click=\"dismissModal()\">\n" +
            "        CANCEL\n" +
            "      </button>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/aml_approval/dialogs/select_next_close.html", "<common-modal modal-header=\"Set Close Date\">\n" +
            "  <div class=\"aml-approval-modal\" ng-if=\"isOpenFund\">\n" +
            "    <div class=\"row\">\n" +
            "      <div class=\"col-md-12 no-padding\">\n" +
            "        <icn-dropdown-wrapper class=\"client-management-dropdown\">\n" +
            "          <select ng-model=\"investmentCloseDate\"\n" +
            "                  ng-options=\"closeDate for closeDate in closeDates track by closeDate\"\n" +
            "                  class=\"form-control\">\n" +
            "            <option value=\"\"></option>\n" +
            "          </select>\n" +
            "        </icn-dropdown-wrapper>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "\n" +
            "    <br />\n" +
            "    <div class=\"row modal-actions\">\n" +
            "      <button type=\"submit\" class=\"right-aligned-btn save-button\"\n" +
            "        ng-disabled=\"investmentCloseDate == ''\"\n" +
            "        ng-click=\"updateCloseDate(investment.id, investmentCloseDate)\">\n" +
            "        SAVE\n" +
            "      </button>\n" +
            "      <button type=\"submit\" class=\"right-aligned-btn button__decline\" ng-click=\"dismissModal()\">\n" +
            "        CANCEL\n" +
            "      </button>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/aml_approval/dialogs/send_to_gp_dialog.html", "<common-modal class=\"gp-signature-dialog \" modal-header=\"Countersignature\">\n" +
            "  <div>\n" +
            "    <form name=\"generalPartnerForm\" ng-submit=\"onSubmit(generalPartnerForm)\" novalidate>\n" +
            "\n" +
            "      <div class=\"row\">\n" +
            "        <div class=\"col-md-12\">\n" +
            "          <b>Fund Name:</b>{{paf.name}}\n" +
            "        </div>\n" +
            "        <div class=\"col-md-12\">\n" +
            "          <b>Investor Name:</b>{{investment.investor_name}}\n" +
            "        </div>\n" +
            "        <div class=\"col-md-12\">\n" +
            "          <b>Investment Id:</b>{{investment.id}}\n" +
            "        </div>\n" +
            "        <div class=\"col-md-12\">\n" +
            "          <b>Amount:</b>{{investment.commitment | currency:undefined:0 }}\n" +
            "        </div>\n" +
            "      </div>\n" +
            "      <hr>\n" +
            "      <div class=\"row\">\n" +
            "        <div class=\"col-md-12\">\n" +
            "          <label>\n" +
            "            <input type=\"radio\" ng-model=\"data.sign_method\" value=\"electronic\" required>\n" +
            "            <b>Electronic</b><br>\n" +
            "            The Countersignature document will be sent securely through Adobe Sign to the email address provided.\n" +
            "          </label>\n" +
            "          <label>\n" +
            "            <input type=\"radio\" ng-model=\"data.sign_method\" value=\"manual\" required>\n" +
            "            <b>Manual</b><br>\n" +
            "            The Countersignature document will be available to download and print for manual signature. To submit a signed Countersignature document, select Upload Countersignature document on Subscription Review.\n" +
            "          </label>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "      <hr>\n" +
            "      <div class=\"general-partner-info\">\n" +
            "        <div class=\"row\">\n" +
            "          <label class=\"col-md-6\">GP:</label>\n" +
            "          <span class=\"col-md-6\">{{paf.gp_legal_entity_name}}</span>\n" +
            "        </div>\n" +
            "        <div class=\"row\">\n" +
            "          <label class=\"col-md-6\">Name:</label>\n" +
            "          <input id=\"name\" ng-model=\"data.name\" class=\"col-md-6\" type=\"text\" required/>\n" +
            "        </div>\n" +
            "        <div class=\"row\">\n" +
            "          <label class=\"col-md-6\">Title of Signatory:</label>\n" +
            "          <input type=\"text\" class=\"col-md-6\"  ng-model=\"data.title\" required/>\n" +
            "        </div>\n" +
            "        <div class=\"row\" ng-hide=\"data.sign_method == 'manual'\">\n" +
            "          <label class=\"col-md-6\">Email Address:</label>\n" +
            "          <input id=\"email\" class=\"col-md-6\"\n" +
            "                type=\"email\" ng-model=\"data.email\" ng-required=\"data.sign_method == 'electronic'\"/>\n" +
            "          <span ng-show=\"form.email.email.$invalid\">\n" +
            "            Please enter a correct email address.\n" +
            "          </span>\n" +
            "        </div>\n" +
            "        <div class=\"row button-group\">\n" +
            "          <button class=\"link-button\" type=\"submit\">Send</button>\n" +
            "          <button class=\"link-button cancel-btn\" type=\"button\" ng-click=\"onCancel()\">Cancel</button>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "    </form>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/aml_approval/dialogs/sub_review_export_docs_modal/export_docs_dialog.html", "<common-modal modal-header=\"{{header}}\">\n" +
            "  <div class=\"modal-header-combined__content export-docs\">\n" +
            "      <br />\n" +
            "\n" +
            "      <div ng-if=\"showNoInvestmentsWarning()\" class=\"export-docs-no-investments-alert\">\n" +
            "        <i class=\"fa fa-2x fa-exclamation-circle\" aria-hidden=\"true\"></i>\n" +
            "        <div class=\"message\">\n" +
            "          No investments available to export.\n" +
            "        </div>\n" +
            "      </div>\n" +
            "\n" +
            "      <div class=\"export-docs-options export-docs-status-options\">\n" +
            "        <span class=\"types\">Include Documents for:</span>\n" +
            "        <table>\n" +
            "          <thead>\n" +
            "            <tr ng-repeat=\"option in statusOptions\">\n" +
            "              <td>\n" +
            "                <div class=\"aml-docs-export-checkboxes\">\n" +
            "                  <icn-checkbox\n" +
            "                    custom-id=\"{{option.id}}\"\n" +
            "                    label=\"{{investmentStatusLabel(option.id, option.label)}}\"\n" +
            "                    model=\"option.selected\">\n" +
            "                  </icn-checkbox>\n" +
            "                </div>\n" +
            "              </td>\n" +
            "            </tr>\n" +
            "          </thead>\n" +
            "        </table>\n" +
            "      </div>\n" +
            "      <div class=\"export-docs-options\">\n" +
            "        <span class=\"types\">Document Types:</span>\n" +
            "        <table>\n" +
            "          <thead>\n" +
            "            <tr ng-repeat=\"docType in docTypes\">\n" +
            "              <td>\n" +
            "                <div class=\"aml-docs-export-checkboxes\">\n" +
            "                  <icn-checkbox\n" +
            "                    custom-id=\"{{docType.id}}\"\n" +
            "                    label=\"{{docType.label}}\"\n" +
            "                    model=\"docType.selected\">\n" +
            "                  </icn-checkbox>\n" +
            "                </div>\n" +
            "              </td>\n" +
            "            </tr>\n" +
            "          </thead>\n" +
            "        </table>\n" +
            "      </div>\n" +
            "      <div class=\"close-date\" ng-show=\"acceptedInvestmentsSelected()\">\n" +
            "        <div class=\"col-xs-6 close-date-select\">\n" +
            "            <icn-select label=\"Close Start Date\" ng-model=\"close.startDate\" options=\"closeStartDateOptions\">\n" +
            "            </icn-select>\n" +
            "        </div>\n" +
            "        <div class=\"col-xs-6\">\n" +
            "            <icn-select label=\"Close End Date\" ng-model=\"close.endDate\" options=\"closeEndDateOptions\" ng-disabled=\"!close.startDate\">\n" +
            "            </icn-select>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "\n" +
            "      <div class=\"row aml-docs-export-close-actions\">\n" +
            "        <button class=\"save-button\" ng-disabled=\"disableGenerateButton()\" type=\"submit\" ng-click=\"generateSelectedAMLDocuments()\">\n" +
            "          GENERATE ZIP\n" +
            "        </button>\n" +
            "        <button type=\"submit\" class=\"button__decline\" ng-click=\"cancelDialog()\">\n" +
            "          CANCEL\n" +
            "        </button>\n" +
            "      </div>\n" +
            "      <br><br>\n" +
            "      <table class=\"icn-table\"\n" +
            "             name=\"amlDocsTable\"\n" +
            "             id=\"amlDocsTable\"\n" +
            "             ng-show=\"generatedZips.length > 0\">\n" +
            "        <thead>\n" +
            "          <th>ID</th>\n" +
            "          <th>Generated At</th>\n" +
            "          <th>Options</th>\n" +
            "        </thead>\n" +
            "        <tbody>\n" +
            "          <tr ng-repeat=\"zip in generatedZips | orderBy: '-created_at'\">\n" +
            "            <td>{{zip.id}}</td>\n" +
            "            <td>{{zip.created_at | date:'yyyy-MM-dd HH:mm:ss'}}</td>\n" +
            "            <td><a target=\"_self\" href=\"{{zip.generic_file.url}}\" download>Download</a></td>\n" +
            "          </tr>\n" +
            "        </tbody>\n" +
            "      </table>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/aml_approval/dialogs/sub_review_export_docs_modal/inline_export_docs_dialog.html", "<common-modal modal-header=\"{{header}}\">\n" +
            "  <div class=\"modal-header-combined__content\">\n" +
            "      <br></br>\n" +
            "\n" +
            "      <div class=\"inline-export-docs\">\n" +
            "        <span class=\"types\">Document Types:</span>\n" +
            "        <table>\n" +
            "          <thead>\n" +
            "            <tr ng-repeat=\"docType in docTypes\">\n" +
            "              <td>\n" +
            "                <div class=\"aml-docs-export-checkboxes\">\n" +
            "                  <icn-checkbox\n" +
            "                    custom-id=\"{{docType.id}}\"\n" +
            "                    label=\"{{docType.label}}\"\n" +
            "                    model=\"docType.selected\">\n" +
            "                  </icn-checkbox>\n" +
            "                </div>\n" +
            "              </td>\n" +
            "            </tr>\n" +
            "          </thead>\n" +
            "        </table>\n" +
            "      </div>\n" +
            "      <div class=\"row aml-docs-export-close-actions\">\n" +
            "        <button class=\"save-button\" ng-disabled=\"disableGenerateButton()\" type=\"submit\" ng-click=\"generateSelectedAMLDocuments()\">\n" +
            "          GENERATE ZIP\n" +
            "        </button>\n" +
            "        <button type=\"submit\" class=\"button__decline\" ng-click=\"cancelDialog()\">\n" +
            "          CANCEL\n" +
            "        </button>\n" +
            "      </div>\n" +
            "      <br><br>\n" +
            "      <table class=\"icn-table\"\n" +
            "             name=\"amlDocsTable\"\n" +
            "             id=\"amlDocsTable\"\n" +
            "             ng-show=\"generatedZips.length > 0\">\n" +
            "        <thead>\n" +
            "          <th>ID</th>\n" +
            "          <th>Generated At</th>\n" +
            "          <th>Options</th>\n" +
            "        </thead>\n" +
            "        <tbody>\n" +
            "          <tr ng-repeat=\"zip in generatedZips | orderBy: '-created_at'\">\n" +
            "            <td>{{zip.id}}</td>\n" +
            "            <td>{{zip.created_at | date:'yyyy-MM-dd HH:mm:ss'}}</td>\n" +
            "            <td><a target=\"_self\" href=\"{{zip.generic_file.url}}\" download>Download</a></td>\n" +
            "          </tr>\n" +
            "        </tbody>\n" +
            "      </table>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/common/fund_administration_legend.html", "<div class=\"fundAdministrationLegend\">\n" +
            "  <div ng-repeat=\"key in keys(legendData)\">\n" +
            "    <div ng-if=\"!isTextNote(key)\" class=\"fundAdministrationLegend-color {{key}}\"></div>\n" +
            "    <div ng-if=\"isTextNote(key)\" class=\"fundAdministrationLegend-text\">{{key}}</div>\n" +
            "    <div class=\"fundAdministrationLegend-description\">{{legendData[key]}}</div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/common/fund_select.html", "<div class=\"vertical-margin\" ng-class=\"{'row': !displayInline}\">\n" +
            "    <div class=\"col-md-5 no-left-padding select-container icn-dropdown\">\n" +
            "      <span class=\"pointer\"></span>\n" +
            "      <select class=\"form-control icn-select\" name=\"fund-selector\" ng-model=\"fund\"\n" +
            "        ng-options=\"fund.name group by fund.category for fund in displayedFunds | orderBy: ['-category', 'name']\"\n" +
            "        show-disabled=\"{{showDisabled}}\"\n" +
            "        options-disabled=\"fund.disabled for fund in funds\">\n" +
            "        <option value=\"\" ng-if=\"showAllFundsOption\">All Funds</option>\n" +
            "      </select>\n" +
            "    </div>\n" +
            "</div>");

        $templateCache.put("areas/common/fund_selector_template.html", "<div class=\"vertical-margin\" ng-class=\"{'row': !displayInline}\">\n" +
            "    <div class=\"col-md-1\">\n" +
            "      <label>Fund: </label>\n" +
            "    </div>\n" +
            "    <div class=\"col-md-5 no-left-padding\">\n" +
            "      <ui-select\n" +
            "        ng-model=\"fund\"\n" +
            "        on-select=\"setSelectedFund($item)\">\n" +
            "        <ui-select-match placeholder=\"--- All Funds ---\">{{$select.selected.name}}</ui-select-match>\n" +
            "        <ui-select-choices group-by=\"'category'\" repeat=\"fund in displayedFunds | orderBy: ['-category', 'name'] | propsFilter: {name: $select.search}\">\n" +
            "          <div ng-bind-html=\"fund.name | highlight: $select.search\" ui-disable-choice=\"fund.disabled\" ng-show=\"!fund.disabled || showDisabled\"></div>\n" +
            "        </ui-select-choices>\n" +
            "      </ui-select>\n" +
            "    </div>\n" +
            "</div>");

        $templateCache.put("areas/common/investor_selector.html", "<div class=\"vertical-margin\" ng-class=\"{'row': !displayInline}\">\n" +
            "  <div class=\"col-md-3\">\n" +
            "    <select name=\"investor-selector\" class=\"form-control\" ng-model=\"investor\" ng-options=\"investor.full_name for investor in investors | orderBy: 'full_name'\">\n" +
            "      <option value=\"\"> --- all investors --- </option>\n" +
            "    </select>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/dashboard/dashboard_index.html", "<div id=\"dashboard\" class=\"row\">\n" +
            "	<div ng-include=\"dashboardTemplate\"></div>\n" +
            "</div>");

        $templateCache.put("areas/dashboard/templates/cells/date_cell.html", "<div ng-hide='editable' class='ngCellText' ng-class=\"col.colIndex()\">\n" +
            "    <span ng-cell-text>\n" +
            "        <span ng-switch=\"(row.getProperty(col.field) == 'empty' || row.getProperty(col.field) == 0)\">\n" +
            "            <span ng-switch-when='false' class='pull-left'>\n" +
            "                {{row.getProperty(col.field)|date}}\n" +
            "            </span>\n" +
            "            <span ng-switch-when='true' class='pull-left'>\n" +
            "                --\n" +
            "            </span>\n" +
            "        </span>\n" +
            "    </span>\n" +
            "</div>\n" +
            "<input ng-show='editable' ng-class=\"'colt' + col.index\" ng-input='COL_FIELD' ng-model='row.entity[col.field]'  />");

        $templateCache.put("areas/dashboard/templates/cells/name_cell.html", "<div style='width:370px;' class='ngCellText ng-scope' ng-class='col.colIndex()'>\n" +
            "    <span ng-cell-text class='ng-binding'>\n" +
            "        <a href='/funds/{{row.getProperty(\"id\")}}'>{{row.getProperty(col.field) | truncateCenter:62}}</a>\n" +
            "    </span>\n" +
            "</div>");

        $templateCache.put("areas/dashboard/templates/cells/paf_name_cell.html", "<div class='ngCellText ng-scope' ng-class='col.colIndex()'>\n" +
            "    <span ng-cell-text class='ng-binding'>\n" +
            "        <a ng-if=\"canHaveInvestments() && row.getProperty('open')\" href='/private_access_funds/{{row.getProperty(\"id\")}}/investment_status'>\n" +
            "            {{row.getProperty(col.field) | truncateCenter:65}}\n" +
            "        </a>\n" +
            "        <a ng-if=\"canHaveInvestments() && !row.getProperty('open')\" href='/reporting/portfolio'>\n" +
            "            {{row.getProperty(col.field) | truncateCenter:65}}\n" +
            "        </a>\n" +
            "        <a ng-if=\"user.isRia\" href='/private_access_funds/{{row.getProperty(\"id\")}}/client_management'>\n" +
            "            {{row.getProperty(col.field) | truncateCenter:65}}\n" +
            "        </a>\n" +
            "    </span>\n" +
            "</div>");

        $templateCache.put("areas/dashboard/templates/dashboards/admin.html", "<div class=\"row\" style=\"padding-top: 20px;\">\n" +
            "  <div class=\"center lead\"><h2>Admin Dashboard</h2></div>\n" +
            "</div>");

        $templateCache.put("areas/dashboard/templates/dashboards/ai.html", "<div class=\"clearfix ai-dashboard\">\n" +
            "    <div class=\"clearfix row\">\n" +
            "        <div class=\"col-md-8\">\n" +
            "            <a class=\"dashboard-link\" href=\"/ai_funds\">\n" +
            "                <!-- background-image is left inline because the asset pipeline is changing the\n" +
            "                     url property relative to the machine, instead of using the absolute url -->\n" +
            "                <div class=\"background\" style=\"background-image:url(https://s3.amazonaws.com/icn-web/images/icn-dashboard/PE-Background-642x275%402x.png);\">\n" +
            "                    <p>{{fundBucketLabels['pe'].label || 'Private Equity'}}</p>\n" +
            "                    <p>\n" +
            "                        Single-manager and multi-manager private equity and private credit funds at low investment minimums\n" +
            "                    </p>\n" +
            "                </div>\n" +
            "            </a>\n" +
            "        </div>\n" +
            "\n" +
            "        <div class=\"col-md-4 featured-fund\">\n" +
            "            <a href=\"/funds/{{featuredFundId}}\">\n" +
            "                <div class=\"background\" style=\"background-image:url(https://s3.amazonaws.com/icn-web/images/icn-dashboard/OwlRock-306x275%402x.png);\">\n" +
            "                </div>\n" +
            "            </a>\n" +
            "        </div>\n" +
            "    </div>\n" +
            "\n" +
            "    <ng-include src=\"'areas/dashboard/templates/dashboards/info_row.html'\" />\n" +
            "</div>");

        $templateCache.put("areas/dashboard/templates/dashboards/doc_mgr.html", "<div ng-show=\"dashboardContentHTML\" style=\"margin-top:3px;\">\n" +
            "    <span ng-bind-html=\"dashboardContentHTML\" class=\"span-padding\"></span>\n" +
            "</div>");

        $templateCache.put("areas/dashboard/templates/dashboards/empty.html", "");

        $templateCache.put("areas/dashboard/templates/dashboards/gp.html", "<div class=\"col-md-12\">\n" +
            "    <div class=\"white-curved\">\n" +
            "        <h3 id=\"open_funds\">Open Fund Activity Metrics</h3>\n" +
            "\n" +
            "        <div ng-show=\"funds.length == 0\" style=\"height: 30px;\">\n" +
            "            <div class=\"not-raising-block\">\n" +
            "                <div class=\"funds-not-raising\"> You are currently not raising any funds</div>\n" +
            "                <button class=\"funds-open-new-button\" href=\"javascript:void(0);\" ng-click=\"openNewFund()\">\n" +
            "                    Open a new fund\n" +
            "                </button>\n" +
            "            </div>\n" +
            "        </div>\n" +
            "        <div ng-show=\"funds.length > 0\" style=\"height: 25px;\">\n" +
            "            <div class=\"pull-left\">\n" +
            "                <select id=\"selectedFund\" ng-model=\"selectedFund\" ng-options=\"fund.name for fund in funds | orderBy: 'name'\"\n" +
            "                        ng-change=\"updateSelectedFund(selectedFund)\"></select>\n" +
            "            </div>\n" +
            "            <div class=\"pull-left left-room\">\n" +
            "                <a href=\"/funds/{{selectedFund.id}}\" style=\"margin-right:15px;\">View / Edit <i\n" +
            "                        class=\"fa fa-angle-double-right\"></i></a>\n" +
            "                <a href=\"javascript:void(0);\" ng-click=\"openNewFund()\">Open a new fund <i\n" +
            "                        class=\"fa fa-angle-double-right\"></i></a>\n" +
            "            </div>\n" +
            "        </div>\n" +
            "        <div class=\"clearfix\"></div>\n" +
            "        <ul class=\"list-inline more-head-room widgets gp-dashboards-widgets\">\n" +
            "            <li class=\"widget-curved odd\">\n" +
            "                <div class=\"secondary-header icn-title\">Search Views<i class=\"fa fa-cog pull-right\"\n" +
            "                                                                       style=\"display:none; margin-top:5px;\"></i></div>\n" +
            "\n" +
            "                <div ng-show=\"!searchViews\">\n" +
            "                    <img src=\"/images/gp_search_no_data.jpg\" class=\"centered\" height=\"180\" align=\"center\"/>\n" +
            "                </div>\n" +
            "                <div ng-show=\"searchViews\">\n" +
            "                    <chart:column chart-data=\"searchViews\" class=\"centered\"/>\n" +
            "                </div>\n" +
            "            </li>\n" +
            "            <li class=\"widget-curved even\">\n" +
            "                <div class=\"secondary-header icn-title\">Profile Views<i class=\"fa fa-cog pull-right\"\n" +
            "                                                                        style=\"display:none; margin-top:5px;\"></i></div>\n" +
            "                <div ng-show=\"!profileViews\">\n" +
            "                    <img src=\"/images/gp_profile_no_data.jpg\" class=\"centered\" height=\"180\"/>\n" +
            "                </div>\n" +
            "                <div ng-show=\"profileViews\">\n" +
            "                    <chart:donut chart-data=\"profileViews\" class=\"centered\"/>\n" +
            "                    <div style=\"position: absolute;bottom: 10px;right: 19px;font-weight: 500; font-size:13px;\">Last 3\n" +
            "                        Months\n" +
            "                    </div>\n" +
            "                </div>\n" +
            "            </li>\n" +
            "            <li class=\"widget-curved even\">\n" +
            "                <div class=\"secondary-header icn-title\">Document Views<i class=\"fa fa-cog pull-right\"\n" +
            "                                                                         style=\"display:none; margin-top:5px;\"></i>\n" +
            "                </div>\n" +
            "                <div ng-show=\"!documentViews\">\n" +
            "\n" +
            "                    <img src=\"/images/gp_document_no_data.jpg\" class=\"centered\" height=\"180\"/>\n" +
            "                </div>\n" +
            "                <div ng-show=\"documentViews\">\n" +
            "                    <chart:column chart-data=\"documentViews\" class=\"centered\"/>\n" +
            "                </div>\n" +
            "            </li>\n" +
            "        </ul>\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"white-curved\" ng-show=\"closedFunds.ngGrid.data.length\">\n" +
            "        <h3 id=\"closed_funds\">Closed Funds</h3>\n" +
            "\n" +
            "        <div class=\"gridStyle\" id=\"icn-grid\" ng-grid=\"closedFunds\"></div>\n" +
            "    </div>\n" +
            "</div>");

        $templateCache.put("areas/dashboard/templates/dashboards/info_row.html", "<div class=\"row info-row\">\n" +
            "    <div class=\"col-md-4 left-tile\">\n" +
            "        <div>\n" +
            "            <h4>Investor Resources Center</h4>\n" +
            "            <p class=\"info-desc\">\n" +
            "                New to iCapital?\n" +
            "                Learn how to navigate the platform, review investment opportunities, and complete the subscription process with these\n" +
            "                short demo videos.\n" +
            "            </p>\n" +
            "            <a href=\"https://learn.icapitalnetwork.com/icapital-investor-resources\" target=\"_blank\">\n" +
            "                Visit the Site\n" +
            "            </a>\n" +
            "        </div>\n" +
            "    </div>\n" +
            "    <div class=\"col-md-4 center-tile\">\n" +
            "        <div>\n" +
            "            <h4>Insights & Education</h4>\n" +
            "            <p class=\"info-desc\">\n" +
            "                Access market insights and comprehensive asset class education.\n" +
            "            </p>\n" +
            "            <a href=\"https://www.icapitalnetwork.com/insights\" target=\"_blank\">\n" +
            "                Visit the Site\n" +
            "            </a>\n" +
            "            <h4>Subscribe to Actionable Insights</h4>\n" +
            "            <p class=\"info-desc\">\n" +
            "                Get our latest market commentary delivered to your inbox.\n" +
            "            </p>\n" +
            "            <a href=\"https://www.icapitalnetwork.com/blog-registration/\" target=\"_blank\">\n" +
            "                Sign Up for our Blog\n" +
            "            </a>\n" +
            "        </div>\n" +
            "    </div>\n" +
            "    <div class=\"col-md-4 assistance-container\">\n" +
            "        <div>\n" +
            "            <img alt=\"\" src=\"https://s3.amazonaws.com/icn-web/images/icn-dashboard/support_icon.png\" />\n" +
            "            <h4>Need Assistance?</h4>\n" +
            "            <div ng-switch on='!!needAssistanceOverride'>\n" +
            "                <div ng-switch-when='true' ng-bind-html=\"needAssistanceOverride\"></div>\n" +
            "                <div ng-switch-default>\n" +
            "                    <p>\n" +
            "                        <a href=\"mailto:ir@icapitalnetwork.com\">Email Investor Relations</a>, or call us at\n" +
            "                        <br> 212 994 7333 to speak with a representative.\n" +
            "                    </p>\n" +
            "                </div>\n" +
            "            </div>\n" +
            "\n" +
            "        </div>\n" +
            "        <a ng-if=\"hasMktSurvey\" href=\"{{mktFields.survey_link}}\" target=\"_blank\" class=\"advisor-survey\">\n" +
            "            <span class=\"survey-text\">{{mktFields.survey_text}}</span>\n" +
            "            <img src=\"/images/carat-icon.svg\" class=\"carat-icon\" />\n" +
            "        </a>\n" +
            "    </div>\n" +
            "</div>");

        $templateCache.put("areas/dashboard/templates/dashboards/lp.html", "<div ng-show=\"mktBanner\">\n" +
            "  <ng-include src=\"mktBanner\">\n" +
            "</div>\n" +
            "<br ng-if=\"mktBanner\">\n" +
            "<div ng-show=\"dashboardContentHTML\" style=\"margin-top:3px;\">\n" +
            "    <span ng-bind-html=\"dashboardContentHTML\" class=\"span-padding\"></span>\n" +
            "</div>\n" +
            "<div ng-show=\"roleHTML\" style=\"margin-top:3px;\">\n" +
            "    <ng-include src=\"roleHTML\" class=\"pull-right\">\n" +
            "</div>\n" +
            "<br>\n" +
            "<div class=\"dashboard__container\">\n" +
            "  <div ng-if=\"is_ria || (canHaveInvestments() && pafFunds.length > 0)\" ng-include=\"'areas/dashboard/templates/dashboards/tables/investments.html'\"></div>\n" +
            "</div>");

        $templateCache.put("areas/dashboard/templates/dashboards/mkt_banner.html", "<div ng-if=\"hasMktBanner\" class=\"clearfix mkt-banner\">\n" +
            "\n" +
            "  <div class=\"mkt-banner__text\">\n" +
            "    <img src=\"/images/megaphone-icon.svg\" ng-if=\"hasMktBannerSubject\" class=\"megaphone-icon\" />\n" +
            "    <p ng-if=\"hasMktBannerSubject\">{{mktFields.banner_subject}}</p>\n" +
            "    <div ng-if=\"hasMktBannerSubject\" class=\"separator\"></div>\n" +
            "    <a ng-href=\"{{mktFields.banner_link}}\" target=\"_blank\" ng-click=\"onMktBannerClick()\">\n" +
            "      <span class=\"mkt-text\">{{mktFields.banner_text}}</span>\n" +
            "      <img src=\"/images/carat-icon.svg\" class=\"carat-icon\" />\n" +
            "    </a>\n" +
            "  </div>\n" +
            "\n" +
            "</div>");

        $templateCache.put("areas/dashboard/templates/dashboards/qpcl.html", "<div ng-show=\"dashboardContentHTML\" style=\"margin-top:3px;\">\n" +
            "    <span ng-bind-html=\"dashboardContentHTML\" class=\"span-padding\"></span>\n" +
            "</div>\n" +
            "<div ng-show=\"roleHTML\" >\n" +
            "  <span ng-include=\"roleHTML\" class=\"span-padding\"></span>\n" +
            "</div>\n" +
            "<br/>\n" +
            "<div class=\"dashboard__container\">\n" +
            "  <div ng-include=\"'areas/dashboard/templates/dashboards/tables/investments.html'\"></div>\n" +
            "</div>");

        $templateCache.put("areas/dashboard/templates/dashboards/tables/investments.html", "<div ng-controller=\"DashboardTableCtrl\">\n" +
            "  <div class=\"table-title\" id=\"qp-investments\">\n" +
            "      {{tableTitle}}\n" +
            "    </div>\n" +
            "  <div ng-show=\"pafFunds.length > 0\" class=\"investmentList\" >\n" +
            "    <table class=\"investment-table table table-hover\">\n" +
            "      <thead>\n" +
            "        <tr class=\"investment-table-header-row\">\n" +
            "          <th cli-header order=\"name\" class=\"fund-name investment-table-text\">Fund Name</th>\n" +
            "          <th ng-if=\"isRia\" cli-header order=\"client_investors\">Client Investors</th>\n" +
            "          <th cli-header order=\"total_amount\">Total Commitment ($)</th>\n" +
            "          <th cli-header order=\"fund_close_date\" class=\"investment-table-date\">Target Close Date</th>\n" +
            "          <th cli-header order=\"latest_funding_date\" class=\"investment-table-date\">Latest Funding Date</th>\n" +
            "          <th cli-header order=\"as_of_date\" class=\"investment-table-date\">As Of Date</th>\n" +
            "        </tr>\n" +
            "      </thead>\n" +
            "      <tbody>\n" +
            "        <tr class=\"investment-table-body-row\" ng-repeat=\"fund in pafFunds | orderBy : orderByField : reverseSort\">\n" +
            "          <td class=\"investment-table-text\">\n" +
            "            <a href=\"#\" ng-click=\"goToClientManagement(fund.id, $event)\">{{ fund.name }}</a>\n" +
            "          </td>\n" +
            "          <td ng-if=\"isRia\">{{ fund.client_investors }}</td>\n" +
            "          <td>{{ fund.total_amount | currency : \"\" : 0 }}</td>\n" +
            "          <td>{{ fund.fund_close_date | date : \"mediumDate\" }}</td>\n" +
            "          <td>{{ fund.latest_funding_date | date : \"mediumDate\" }}</td>\n" +
            "          <td>{{ (fund.as_of_date | date : \"mediumDate\") }}</td>\n" +
            "        </tr>\n" +
            "      </tbody>\n" +
            "    </table>\n" +
            "  </div>\n" +
            "  <div class=\"dashboard-empty-list\" ng-show=\"pafFunds.length === 0\">\n" +
            "      You have no investments to manage, <a href=\"/private_access_funds\" class=\"alert-link\">Click Here</a> to view\n" +
            "      Private Access Funds\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/dashboard/templates/dashboards/three_banner.html", "<div class=\"clearfix three-banner\">\n" +
            "  <div class=\"clearfix row\">\n" +
            "\n" +
            "    <div class=\"col-md-4 left-banner\">\n" +
            "      <a class=\"dashboard-link\" href=\"{{investmentOpportunitiesUrls['privateEquityFunds'] || '/private_equity_funds'}}\" target=\"_self\">\n" +
            "        <!-- background-image is left inline because the asset pipeline is changing the\n" +
            "           url property relative to the machine, instead of using the absolute url -->\n" +
            "        <div class=\"background\" style=\"background-image:url(https://s3.amazonaws.com/icn-web/images/icn-dashboard/PE-Background-306x275%402x.png);\">\n" +
            "          <h1>{{fundBucketLabels['pe'] || 'Private Equity'}}</h1>\n" +
            "          <p>Single-manager and multi-manager private equity and private credit funds at low investment minimums</p>\n" +
            "        </div>\n" +
            "      </a>\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"col-md-4 middle-banner\">\n" +
            "      <a class=\"dashboard-link\" href=\"{{investmentOpportunitiesUrls['hedgeFunds'] ||'/hedge_funds'}}\" target=\"_self\">\n" +
            "        <div class=\"background\" style=\"background-image:url(https://s3.amazonaws.com/icn-web/images/icn-dashboard/HF-Background-306x275%402x.png);\">\n" +
            "          <h2>{{fundBucketLabels['hedge'] || 'Hedge Funds'}}</h2>\n" +
            "          <p>A curated selection of hedge funds across a broad range of investment strategies</p>\n" +
            "        </div>\n" +
            "      </a>\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"col-md-4 right-banner\">\n" +
            "      <a class=\"dashboard-link\" href=\"{{investmentOpportunitiesUrls['aiFunds'] || '/ai_funds'}}\" target=\"_self\">\n" +
            "        <div class=\"background\" style=\"background-image:url(https://s3.amazonaws.com/icn-web/images/icn-dashboard/AI-Background-306x275%402x.png);\">\n" +
            "          <h2>{{fundBucketLabels['ai'] || 'Accredited Investor Offerings'}}</h2>\n" +
            "          <p>Alternative product strategies structured for accredited investors</p>\n" +
            "        </div>\n" +
            "      </a>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "\n" +
            "  <ng-include src=\"'areas/dashboard/templates/dashboards/info_row.html'\"></ng-include>\n" +
            "</div>");

        $templateCache.put("areas/dashboard/templates/dashboards/two_banner.html", "<div class=\"clearfix two-banner\">\n" +
            "  <div class=\"clearfix row\">\n" +
            "\n" +
            "    <div class=\"col-md-6 left-banner\">\n" +
            "      <a class=\"dashboard-link\" href=\"{{investmentOpportunitiesUrls['privateEquityFunds'] || '/private_equity_funds'}}\" target=\"_self\">\n" +
            "        <!-- background-image is left inline because the asset pipeline is changing the\n" +
            "           url property relative to the machine, instead of using the absolute url -->\n" +
            "        <div class=\"background\" style=\"background-image:url(https://s3.amazonaws.com/icn-web/images/icn-dashboard/PE-Background-474x275%402x.png);\">\n" +
            "          <h1>{{fundBucketLabels['pe'] || 'Private Equity'}}</h1>\n" +
            "          <p>Single-manager and multi-manager private equity and private credit funds at low investment minimums</p>\n" +
            "        </div>\n" +
            "      </a>\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"col-md-6 right-banner\">\n" +
            "      <a class=\"dashboard-link\" href=\"{{investmentOpportunitiesUrls['hedgeFunds'] ||'/hedge_funds'}}\" target=\"_self\">\n" +
            "        <div class=\"background\" style=\"background-image:url(https://s3.amazonaws.com/icn-web/images/icn-dashboard/HF-Background-474x275%402x.png);\">\n" +
            "          <h2>{{fundBucketLabels['hedge'] || 'Hedge Funds'}}</h2>\n" +
            "          <p>A curated selection of hedge funds across a broad range of investment strategies</p>\n" +
            "        </div>\n" +
            "      </a>\n" +
            "    </div>\n" +
            "\n" +
            "  </div>\n" +
            "  <ng-include src=\"'areas/dashboard/templates/dashboards/info_row.html'\" />\n" +
            "</div>");

        $templateCache.put("areas/fund_documents/documents/components/document_icon/document_icon.html", "<span class=\"icon\" ng-class=\"'icon-file-'+getClass()\"></span>");

        $templateCache.put("areas/fund_profile/dialogs/download_document_dialog/download_document_dialog.html", "<common-modal modal-header=\"Download Document\">\n" +
            "  <div class=\"download-document-dialog\">\n" +
            "    <p>I am downloading a document for:</p>\n" +
            "    <div class=\"radio-group\">\n" +
            "      <icn-radio\n" +
            "        ng-repeat=\"mode in modes\"\n" +
            "        model=\"selected.mode\"\n" +
            "        key=\"{{mode.key}}\"\n" +
            "        label=\"{{mode.label}}\"\n" +
            "      >\n" +
            "      </icn-radio>\n" +
            "    </div>\n" +
            "    <div\n" +
            "      class=\"for-clients-group\"\n" +
            "      ng-if=\"selected.mode == modeKeys.forClient\"\n" +
            "    >\n" +
            "      <ng-include\n" +
            "        ng-if=\"::backendSearchWatermark\"\n" +
            "        src=\"'areas/fund_profile/dialogs/download_document_dialog/partials/backend_search.html'\"\n" +
            "      ></ng-include>\n" +
            "      <ng-include\n" +
            "        ng-if=\"::!backendSearchWatermark\"\n" +
            "        src=\"'areas/fund_profile/dialogs/download_document_dialog/partials/frontend_search.html'\"\n" +
            "      ></ng-include>\n" +
            "      <div class=\"eligibility-literature\">\n" +
            "        <div class=\"el-checkbox\">\n" +
            "          <icn-checkbox\n" +
            "            custom-id=\"clientEligibility\"\n" +
            "            model=\"selected.eligibility\"\n" +
            "          >\n" +
            "          </icn-checkbox>\n" +
            "        </div>\n" +
            "        <div class=\"el-text\">\n" +
            "          <p>\n" +
            "            I confirm that the Investor selected above meets the eligibility requirements\n" +
            "            for this fund and I will not distribute this document to any other party.\n" +
            "          </p>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "    <div class=\"buttons\">\n" +
            "      <button\n" +
            "        ng-click=\"download()\"\n" +
            "        ng-enabled=\"downloadable\"\n" +
            "        ng-class=\"{disabled: !downloadable}\"\n" +
            "      >\n" +
            "        Download\n" +
            "      </button>\n" +
            "      <button\n" +
            "        ng-click=\"cancel()\"\n" +
            "        class=\"button__reject\"\n" +
            "      >\n" +
            "        Cancel\n" +
            "      </button>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/fund_profile/dialogs/download_document_dialog/partials/backend_search.html", "<ui-select ng-model=\"selected.client\" class=\"from-backend\">\n" +
            "  <ui-select-match>\n" +
            "    <span\n" +
            "      ng-bind=\"placeholder\"\n" +
            "      ng-class=\"{placeholder: $select.selected.name == undefined}\"\n" +
            "    >\n" +
            "    </span>\n" +
            "  </ui-select-match>\n" +
            "  <ui-select-choices\n" +
            "    repeat=\"client in clientsWatermark track by client.contact_card_id\"\n" +
            "    refresh=\"search($select.search)\"\n" +
            "    refresh-delay=\"0\"\n" +
            "  >\n" +
            "    <span>{{ client.name }} ({{ client.email }})</span>\n" +
            "  </ui-select-choices>\n" +
            "</ui-select>");

        $templateCache.put("areas/fund_profile/dialogs/download_document_dialog/partials/frontend_search.html", "<ui-select ng-model=\"selected.client\">\n" +
            "  <ui-select-match>\n" +
            "    <span\n" +
            "      ng-bind=\"placeholder\"\n" +
            "      ng-class=\"{placeholder: $select.selected.name == undefined}\"\n" +
            "    >\n" +
            "    </span>\n" +
            "  </ui-select-match>\n" +
            "  <ui-select-choices\n" +
            "    repeat=\"client in (clientsWatermark | filter: $select.search) track by client.contact_card_id\"\n" +
            "  >\n" +
            "      <span>{{ client.name }} ({{ client.email }})</span>\n" +
            "  </ui-select-choices>\n" +
            "</ui-select>");

        $templateCache.put("areas/fund_profile/dialogs/fund_disclaimer_accept_dialog/fund_disclaimer_accept_dialog.html", "<common-modal modal-header=\"{{ currentDisclaimer.header }}\">\n" +
            "  <div class=\"disclaimerAcceptDialog-content\">\n" +
            "    <div class=\"disclaimerAcceptDialog-textarea\" ng-if=\"!currentDisclaimer.nonDisclosureAgreement\" ng-bind-html=\"currentDisclaimer.disclaimerText\"></div>\n" +
            "    <div class=\"disclaimerAcceptDialog-nonDisclosureAgreementArea\" ng-if=\"currentDisclaimer.nonDisclosureAgreement\">\n" +
            "      <ng-include src=\"'dialogs/non_disclosure_agreement_dialog/non_disclosure_agreement_dialog.html'\"></ng-include>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"disclaimerAcceptDialog-acceptance\">\n" +
            "    <div class=\"check-wrapper\">\n" +
            "      <div class=\"custom-check\" ng-class=\"{ 'checked' : currentDisclaimer.tempAccepted, 'accepted' : currentDisclaimer.accepted }\">\n" +
            "        <input type=\"checkbox\" value=\"None\" name=\"accepted\">\n" +
            "        <label for=\"accepted\" ng-click=\"toggleAcceptance()\"></label>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "    <span class=\"accept-statement\" ng-class=\"{ 'accepted' : currentDisclaimer.accepted }\">\n" +
            "      {{ currentDisclaimer.acknowledgeText }}\n" +
            "    </span>\n" +
            "  </div>\n" +
            "  <div ng-show=\"disclaimers.length > 1\" class=\"breadcrumb-container\">\n" +
            "    <span ng-repeat=\"disclaimer in disclaimers\"\n" +
            "          ng-click=\"clickable($index) && navigate($index)\"\n" +
            "          class=\"breadcrumb\"\n" +
            "          ng-class=\"{ 'active' : $index == currentIdx, 'clickable': clickable($index) }\"></span>\n" +
            "  </div>\n" +
            "  <div class=\"row\">\n" +
            "    <button class=\"pull-right subscription-btn\" ng-click=\"advance()\" ng-disabled=\"!currentDisclaimer.tempAccepted\">Ok</button>\n" +
            "    <button class=\"cancel-btn\" ng-click=\"cancel()\">Cancel</button>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/investor_relations/tabs/investment_contact_details/pages/edit_contact_details/edit_contact_details.html", "<icn-paf-admin-nav type=\"investor_relations\"></icn-paf-admin-nav>\n" +
            "\n" +
            "<div class=\"ir_edit_contact_details\">\n" +
            "  <div class=\"row vertical-margin more-head-room\">\n" +
            "    <div class=\"col-md-12\">\n" +
            "      <a href=\"/investor_relations/investor_search/detail/{{investment.investor_profile.primary_signer_contact_card.id}}/{{investment.private_access_fund_id}}\">\n" +
            "        <i class=\"fa fa-chevron-left\"></i> Back to investments in Investor Search\n" +
            "      </a>\n" +
            "\n" +
            "      <h2>Edit Investment Contact Details</h2>\n" +
            "\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"row\">\n" +
            "    <ng-include src=\"'areas/investor_relations/tabs/investment_contact_details/pages/edit_contact_details/templates/_edit_investor_profile.html'\"/>\n" +
            "  </div>\n" +
            "  <div class=\"row\">\n" +
            "    <ng-include src=\"'areas/investor_relations/tabs/investment_contact_details/pages/edit_contact_details/templates/_edit_investment.html'\"/>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/investor_relations/tabs/investment_contact_details/pages/edit_contact_details/templates/_edit_investment.html", "<form name=\"investmentForm\">\n" +
            "\n" +
            "  <div class=\"investorProfile-section-header\">\n" +
            "    Referral Code\n" +
            "  </div>\n" +
            "  <div class=\"row form-group\">\n" +
            "    <div class=\"col-md-3\">\n" +
            "      <label class=\"investorProfile-section-label\">\n" +
            "        Please enter the referral code:\n" +
            "      </label>\n" +
            "    </div>\n" +
            "    <div class=\"col-md-5 form-inline\">\n" +
            "      <input type=\"text\" ng-model=\"investment.investor_profile.referral_code\" class=\"form-control\" name=\"referral_code\"/>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"row text-center more-headroom\">\n" +
            "    <div class=\"alert alert-danger\" ng-show=\"errorMsgReferralCode\">\n" +
            "      <div ng-click=\"errorMsgReferralCode = undefined\">\n" +
            "        <a href=\"#\" class=\"close\" data-dismiss=\"alert\" aria-label=\"close\" >&times;</a>\n" +
            "        {{errorMsgReferralCode}}\n" +
            "      </div>\n" +
            "    </div>\n" +
            "\n" +
            "    <button ng-click=\"updateReferralCode()\" class=\"text-center\">\n" +
            "      Update Referral Code\n" +
            "    </button>\n" +
            "  </div>\n" +
            "</form>");

        $templateCache.put("areas/investor_relations/tabs/investment_contact_details/pages/edit_contact_details/templates/_edit_investor_profile.html", "<div class=\"row vertical-margin\">\n" +
            "  <div class=\"col-md-12\">\n" +
            "    <h4>\n" +
            "      Editing a\n" +
            "      <span class=\"label label-info\" ng-show=\"investment.investor_profile.original_id\">Frozen</span>\n" +
            "      <span class=\"label label-primary\" ng-show=\"!investment.investor_profile.original_id\">Live</span>\n" +
            "      investment profile for {{investment.investor_name}}</h4>\n" +
            "  </div>\n" +
            "</div>\n" +
            "\n" +
            "<div class=\"investorProfile-section-header\">\n" +
            "    Registered Investment Advisor Information\n" +
            "</div>\n" +
            "<investor-profile-advisor-information\n" +
            "  investor-profile=\"investment.investor_profile\"\n" +
            "  submitted=\"submitted\"\n" +
            "  form-error-msgs=\"form_error_msgs\"\n" +
            "  must-complete=\"mustComplete\">\n" +
            "</investor-profile-advisor-information>\n" +
            "\n" +
            "<div class=\"investorProfile-section-header\">\n" +
            "  Interested Parties\n" +
            "</div>\n" +
            "<investor-profile-interested-parties\n" +
            "  investor-profile=\"investment.investor_profile\"\n" +
            "  submitted=\"submitted\"\n" +
            "  form-error-msgs=\"form_error_msgs\">\n" +
            "</investor-profile-interested-parties>\n" +
            "\n" +
            "<div class=\"row text-center more-headroom\">\n" +
            "  <button ng-click=\"saveInvestorProfile()\" class=\"text-center\">\n" +
            "    Update Investor Profile\n" +
            "  </button>\n" +
            "</div>");

        $templateCache.put("areas/investor_relations/tabs/investment_contact_details/pages/investments/investments.html", "<icn-paf-admin-nav type=\"investor_relations\"></icn-paf-admin-nav>\n" +
            "\n" +
            "<div class=\"row vertical-margin\">\n" +
            "  <div class=\"col-md-12\">\n" +
            "      <a href=\"/investor_relations/investment_contact_details/contact_cards?pafId={{fund.id}}\">Back to Contact Cards</a>\n" +
            "  </div>\n" +
            "</div>\n" +
            "<div class=\"row\">\n" +
            "  <div class=\"col-md-12\">\n" +
            "    <h2>Investments for {{ contactCard.name }} in {{investments[0].fund_name}}</h2>\n" +
            "  </div>\n" +
            "</div>\n" +
            "<div class=\"row vertical-margin\">\n" +
            "  <div class='col-md-4'>\n" +
            "    <label for='contactCardChooser'>Choose Another Investor</label>\n" +
            "    <br />\n" +
            "    <select id='contactCardChooser' class='form-control' ng-options='contactCard as contactCard.name for contactCard in contactCards' ng-model='contactCard'></select>\n" +
            "  </div>\n" +
            "</div>\n" +
            "<div class=\"row\">\n" +
            "  <div class=\"col-md-12\">\n" +
            "    <table class=\"table icn-table\" id=\"investor-relations-cc-table\">\n" +
            "      <thead>\n" +
            "        <th>Id</th>\n" +
            "        <th>Commitment</th>\n" +
            "        <td>Profile</td>\n" +
            "        <th>Required Documents</th>\n" +
            "        <th class=\"ria-email-col\">RIA User Email</th>\n" +
            "        <th class=\"ria-email-col\">RIA Advisor Email</th>\n" +
            "        <th class=\"ria-email-col\">Interested Parties</th>\n" +
            "        <th class=\"referral-code-col\">Referral Code</th>\n" +
            "        <th class=\"referral-code-col\"></th>\n" +
            "      </thead>\n" +
            "      <tbody>\n" +
            "        <tr ng-repeat='investment in investments'>\n" +
            "          <td>\n" +
            "            {{ investment.id }}\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            {{ investment.amount | currency }}\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <a href=\"#\" ng-click=\"editProfile(investment, fund, contactCard)\">\n" +
            "              {{ investment.investor_profile.name }}\n" +
            "              <span class=\"label label-info\" ng-show=\"investment.investor_profile.original_id\">Frozen</span>\n" +
            "              <span class=\"label label-primary\" ng-show=\"!investment.investor_profile.original_id\">Live</span>\n" +
            "            </a>\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <a target=\"_self\" ng-href=\"{{::goToReqDocs(investment)}}\">\n" +
            "              Edit Required Documents\n" +
            "            </a>\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <span>{{ investment.ria_email }}</span>\n" +
            "            <div ng-if=\"investment.ria_email\" class=\"ria-email\">\n" +
            "              <a href=\"\" ng-click=\"showRiaUserModal(true, investment)\"><i class=\"fa fa-pencil\"></i> Edit</a>\n" +
            "              <a href=\"\" ng-click=\"confirmRiaDeletion(investment.ria_email, investment.id)\"><i class=\"fa fa-trash-o\"></i> Delete</a>\n" +
            "            </div>\n" +
            "            <div ng-hide=\"investment.ria_email\">\n" +
            "              <a href=\"\" ng-click=\"showRiaUserModal(false, investment)\"><i class=\"fa fa-plus\"></i> Add</a>\n" +
            "            </div>\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            {{ investment.investor_profile.section_b_ria_email }}\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <div class=\"row vertical-margin\" ng-repeat=\"party_email in investment.investor_profile.interested_parties_emails\">\n" +
            "              {{ party_email }}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            {{ investment.investor_profile.referral_code.code }}\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <a ng-href=\"/investor_relations/investment_contact_details/edit_contact_details?investmentId={{investment.id}}\">\n" +
            "              <i class=\"fa fa-pencil\"></i>\n" +
            "            </a>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "      </tbody>\n" +
            "    </table>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/investor_relations/tabs/investor_search/detail/detail.html", "<icn-paf-admin-nav type=\"investor_relations\"></icn-paf-admin-nav>\n" +
            "\n" +
            "<div class=\"row detail\">\n" +
            "  <div class=\"col-md-12\">\n" +
            "    <div class=\"back-to-search\">\n" +
            "      <a ng-href=\"/investor_relations/investor_search/{{contactCard.id}}\"><i class=\"fa fa-chevron-left\"></i>&nbsp;BACK TO SEARCH RESULTS</a>\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"main\">\n" +
            "      <div class=\"investments-for\">\n" +
            "        <span class=\"investor-name\">Investments for {{investorName}} in:</span>\n" +
            "        <fund-select funds=\"pafs\" fund=\"selectedPaf\" display-inline=\"true\" show-all-option=\"true\"></fund-select>\n" +
            "      </div>\n" +
            "\n" +
            "      <div>\n" +
            "        <table class=\"table table-hover open-ended-in-process-table\">\n" +
            "          <thead>\n" +
            "            <th>id</th>\n" +
            "            <th>commitment</th>\n" +
            "            <th>profile</th>\n" +
            "            <th>required documents</th>\n" +
            "            <th>ria user email</th>\n" +
            "            <th>ria advisor email</th>\n" +
            "            <th>interested parties</th>\n" +
            "            <th>referral code</th>\n" +
            "            <th></th>\n" +
            "          </thead>\n" +
            "          <tbody>\n" +
            "            <tr ng-repeat=\"investment in investments | orderBy: '-created_at'\">\n" +
            "              <td>\n" +
            "                {{ investment.id }}\n" +
            "              </td>\n" +
            "              <td class=\"with-border\">\n" +
            "                {{ investment.commitment | currency }}\n" +
            "              </td>\n" +
            "              <td class=\"with-border\">\n" +
            "                <a  href=\"\" ng-click=\"openInvestorProfile(investment)\">\n" +
            "                  {{ investment.investor_profile.name }}\n" +
            "                  <span class=\"label label-info\" ng-show=\"investment.investor_profile.original_id\">Frozen</span>\n" +
            "                  <span class=\"label label-primary\" ng-show=\"!investment.investor_profile.original_id\">Live</span>\n" +
            "                </a>\n" +
            "              </td>\n" +
            "              <td>\n" +
            "                <a target=\"_self\" ng-href=\"{{::goToReqDocs(investment)}}\">\n" +
            "                  Edit Required Documents\n" +
            "                </a>\n" +
            "              </td>\n" +
            "              <td class=\"with-border ria-email\">\n" +
            "                <span>{{ investment.ria_email }}</span>\n" +
            "                <div ng-if=\"investment.ria_email\">\n" +
            "                  <a href=\"\" ng-click=\"showRiaUserModal(true, investment)\"><i class=\"fa fa-pencil\"></i> Edit</a>\n" +
            "                  <a href=\"\" ng-click=\"confirmRiaDeletion(investment.ria_email, investment.id)\"><i class=\"fa fa-trash-o\"></i> Delete</a>\n" +
            "                </div>\n" +
            "\n" +
            "                <div ng-hide=\"investment.ria_email\">\n" +
            "                    <a href=\"\" ng-click=\"showRiaUserModal(false, investment)\"><i class=\"fa fa-plus\"></i> Add</a>\n" +
            "                  </div>\n" +
            "              </td>\n" +
            "              <td class=\"with-border\">\n" +
            "                {{ investment.investor_profile.section_b_ria_email }}\n" +
            "              </td>\n" +
            "              <td class=\"with-border\">\n" +
            "                <div class=\"row vertical-margin\" ng-repeat=\"interestedParty in investment.investor_profile.interested_parties\">\n" +
            "                  {{ interestedParty.email }}\n" +
            "                </div>\n" +
            "              </td>\n" +
            "              <td class=\"with-border\">{{ investment.investor_profile.referral_code }}</td>\n" +
            "              <td>\n" +
            "                <a ng-href=\"/investor_relations/investment_contact_details/edit_contact_details?investmentId={{investment.id}}\">\n" +
            "                  <i class=\"fa fa-pencil\"></i>\n" +
            "                </a>\n" +
            "              </td>\n" +
            "            </tr>\n" +
            "          </tbody>\n" +
            "        </table>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/investor_relations/tabs/investor_search/search/search.html", "<icn-paf-admin-nav type=\"investor_relations\"></icn-paf-admin-nav>\n" +
            "<div class=\"row investor-search\">\n" +
            "  <div class=\"row\">\n" +
            "    <div class=\"col-md-12\">\n" +
            "      <div class=\"form-group\">\n" +
            "        <label class=\"search-label col-sm-2\" for=\"contactCards\">Investor Search</label>\n" +
            "        <div class=\"col-sm-10\">\n" +
            "          <ui-select\n" +
            "            ng-model=\"select.selectedCard\"\n" +
            "            on-select=\"setCardId($item, $model)\"\n" +
            "            theme=\"bootstrap\"\n" +
            "            name=\"contactCards\">\n" +
            "            <ui-select-match placeholder=\"Search by investor name or email address\">\n" +
            "              {{$select.selected.email}}\n" +
            "            </ui-select-match>\n" +
            "            <ui-select-choices\n" +
            "              refresh=\"contactCardsAsync($select.search)\"\n" +
            "              refresh-delay=\"3\"\n" +
            "              group-by=\"'investor_email_header'\"\n" +
            "              repeat=\"contact_card.id as contact_card in contactCards | propsFilter: {name: $select.search, email: $select.search}\">\n" +
            "              <div class=\"row\">\n" +
            "                <div class=\"col-md-6 option-name\">\n" +
            "                  <span ng-bind-html=\"contact_card.name | highlight: $select.search\"></span>\n" +
            "                </div>\n" +
            "                <div class=\"col-md-6 option-email\">\n" +
            "                  <span ng-bind-html=\"contact_card.email | highlight: $select.search\"></span>\n" +
            "                </div>\n" +
            "              </div>\n" +
            "            </ui-select-choices>\n" +
            "          </ui-select>\n" +
            "          <div class=\"search-icon\">\n" +
            "            <i class=\"fa fa-search fa-lg\" aria-hidden=\"true\"></i>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"row more-head-room\" ng-if=\"approvedInvestments || pendingInvestments\">\n" +
            "    <div class=\"col-md-12\">\n" +
            "      <icn-profile-fund-list\n" +
            "        contact=\"select.selectedCard\"\n" +
            "        approved-investments=\"approvedInvestments\"\n" +
            "        pending-investments=\"pendingInvestments\">\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/investor_relations/tabs/mass_emails/mass_emails.html", "<icn-paf-admin-nav type=\"investor_relations\"></icn-paf-admin-nav>\n" +
            "\n" +
            "<div ng-show=\"success\"  class=\"alert alert-success\" role=\"alert\">{{success}}\n" +
            "  Send Another?\n" +
            "  <button ng-click=\"restart($event)\">Go!</button>\n" +
            "</div>\n" +
            "<div class=\"alert alert-danger\" role=\"alert\" ng-show=\"error\">{{error}}</div>\n" +
            "<div ng-hide=\"success\" class=\"panel panel-default\" style=\"margin:10px;\">\n" +
            "  <div class=\"panel-heading\">Investor Reporting Mass Email</div>\n" +
            "  <div class=\"panel-body\">\n" +
            "    <form name=\"massEmailForm\">\n" +
            "      <!-- All Funds -->\n" +
            "      <div class=\"row col-xs-12 mass-email\">\n" +
            "        <div class=\"alert alert-success\" ng-show=\"draftMessage\" ng-click=\"draftMessage = ''\" role=\"alert\" ng-focus=\"draftMessage\">{{draftMessage}}</div>\n" +
            "        <span ng-click=\"clearFundsClick()\" ng-class=\"displayAllFundsButton ? 'fund-disable' : 'fund-enable'\" class=\"pull-right\">\n" +
            "          Clear\n" +
            "        </span>\n" +
            "        <span class=\"pull-right\">&nbsp;|&nbsp;</span>\n" +
            "        <span ng-click=\"allFundsClick()\" ng-class=\"displayAllFundsButton ? 'fund-enable' : 'fund-disable'\" class=\"pull-right\">\n" +
            "          Add All Funds\n" +
            "        </span>\n" +
            "      </div>\n" +
            "      <!-- Select Funds -->\n" +
            "      <div class=\"row col-xs-12 mass-email\">\n" +
            "        <div class=\"col-xs-2\">\n" +
            "          <div class=\"row\">\n" +
            "            <label class=\"control-label pull-right\">Select Funds:</label>\n" +
            "          </div>\n" +
            "          <div class=\"row\">\n" +
            "            <span class=\"pull-right\">{{totalFunds}}</span>\n" +
            "            <span class=\"pull-right\">&nbsp;/&nbsp;</span>\n" +
            "            <span class=\"pull-right\">{{selectedPafs.private_access_funds.length}}</span>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "        <div class=\"col-xs-10\">\n" +
            "          <ui-select\n" +
            "            multiple\n" +
            "            ng-model=\"selectedPafs.private_access_funds\"\n" +
            "            theme=\"bootstrap\"\n" +
            "            sortable=\"true\"\n" +
            "            name=\"pafs\"\n" +
            "            close-on-select=\"true\">\n" +
            "            <ui-select-match placeholder=\"Select private access fund...\">{{$item.name}}</ui-select-match>\n" +
            "            <ui-select-choices repeat=\"paf in pafs | propsFilter: {name: $select.search}\">\n" +
            "              <div ng-bind-html=\"paf.name | highlight: $select.search\"></div>\n" +
            "              <small>\n" +
            "                id: {{paf.id}}\n" +
            "              </small>\n" +
            "            </ui-select-choices>\n" +
            "          </ui-select>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "      <!-- Group -->\n" +
            "      <div class=\"row col-xs-12 mass-email\">\n" +
            "        <div class=\"col-xs-2\">\n" +
            "          <label class=\"pull-right\">Send To:</label>\n" +
            "        </div>\n" +
            "        <div class=\"col-xs-5\">\n" +
            "          <select name=\"group\" id=\"groupSelect\" ng-model=\"data.group\" class=\"form-control\">\n" +
            "            <option ng-repeat=\"option in availableGroupOptions\" value=\"{{option.id}}\">{{option.name}}</option>\n" +
            "          </select>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "      <!-- Subject -->\n" +
            "      <div class=\"row col-xs-12 mass-email\">\n" +
            "        <div class=\"col-xs-2\">\n" +
            "          <label class=\"pull-right\">Subject:</label>\n" +
            "        </div>\n" +
            "        <div class=\"col-xs-7\">\n" +
            "          <input type=\"text\" ng-model=\"newMassEmail.subject\" name=\"subject\" class=\"form-control\" required>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "      <!-- Body -->\n" +
            "      <div class=\"row col-xs-12 mass-email\">\n" +
            "        <div class=\"col-xs-2\">\n" +
            "          <label class=\"pull-right\">Body:</label>\n" +
            "        </div>\n" +
            "        <div class=\"col-xs-10\">\n" +
            "          <textarea name=\"body\" ckeditor=\"ckeditorOptions\" ng-model=\"newMassEmail.body\" required></textarea>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "      <!-- Attachment -->\n" +
            "      <div class=\"col-xs-12 mass-email attachments\">\n" +
            "\n" +
            "        <div class=\"col-xs-2\">\n" +
            "          <label class=\"pull-right\">Attachments:</label>\n" +
            "        </div>\n" +
            "        <div class=\"col-xs-1\">\n" +
            "          <label for=\"file\" class=\"btn email-attachment\" id=\"attachment-label\">Add file...</label>\n" +
            "          <input\n" +
            "            multiple\n" +
            "            accept=\"application/pdf\"\n" +
            "            type=\"file\"\n" +
            "            id=\"file\"\n" +
            "            name=\"file\"\n" +
            "            ng-file-select=\"getTheFiles($files)\"\n" +
            "            valid-file\n" +
            "            ng-model=\"filename\"\n" +
            "            class=\"inputfile\">\n" +
            "        </div>\n" +
            "\n" +
            "        <div class=\"col-xs-3 attached-file\" ng-repeat=\"(key, data) in indexObject\">\n" +
            "          <i class=\"fa fa-paperclip\" aria-hidden=\"true\"></i><span class=\"name\">{{data.name  | truncate:24}}</span>\n" +
            "          <span class=\"btn del\" ng-click=\"remove(key)\">&times;</span>\n" +
            "        </div>\n" +
            "        <div ng-if=\"showDraftAttachments\" class=\"col-xs-3 attached-file\" ng-repeat=\"attachment in attachments\">\n" +
            "          <i class=\"fa fa-paperclip\" aria-hidden=\"true\"></i><span class=\"name\">{{attachment.file_name  | truncate:24}}</span>\n" +
            "          <span class=\"btn del\" ng-click=\"removeSavedAttachment($event, attachment)\">&times;</span>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "      <!-- Submit -->\n" +
            "      <div class=\"row col-xs-12 mass-email\">\n" +
            "        <div class=\"col-xs-2\"></div>\n" +
            "        <div class=\"col-xs-10\">\n" +
            "          <button type=\"button\" class=\"btn attachment-btn\" name=\"saveDraft\" ng-click=\"saveDraft($event)\">SAVE DRAFT</button>\n" +
            "          <button type=\"button\" class=\"btn attachment-btn\" ng-click=\"loadDraft()\" name=\"loadDraft\" ng-disabled=\"!hasDraft\">LOAD DRAFT</button>\n" +
            "          <button type=\"submit\" class=\"btn attachment-btn\" ng-click=\"sendDialog($event)\" ng-disabled=\"!massEmailForm.$valid\">SEND</button>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "    </form>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/layout/components/dialogs/contact_us.html", "<common-modal modal-header=\"\">\n" +
            "    <div class=\"modal-header-combined__content\">\n" +
            "        <h3 class=\"header-two__teal\">Need Assistance?</h3>\n" +
            "    </div>\n" +
            "    <div class=\"row\">\n" +
            "        <div ng-switch on='!!needAssistanceOverride'>\n" +
            "            <div ng-switch-when='true' ng-bind-html=\"needAssistanceOverride\"></div>\n" +
            "            <div ng-switch-default>\n" +
            "                <p ng-if='!isWhiteLabel'>\n" +
            "                    <strong>Private Fund & Hedge Fund Sales Support</strong>\n" +
            "                    <br>\n" +
            "                    <a href=\"mailto:sales@icapitalnetwork.com\">sales@icapitalnetwork.com</a>\n" +
            "                    <br>\n" +
            "                    212-994-7344\n" +
            "                </p>\n" +
            "                <p ng-if='!isWhiteLabel'>\n" +
            "                    <strong>Structured Investments Sales Support</strong>\n" +
            "                    <br>\n" +
            "                    <a href=\"mailto:sisales@icapitalnetwork.com\">sisales@icapitalnetwork.com</a>\n" +
            "                    <br>\n" +
            "                    877-346-7763\n" +
            "                </p>\n" +
            "                <p>\n" +
            "                    <strong>Investor Relations</strong>\n" +
            "                    <br>\n" +
            "                    <a href=\"mailto:ir@icapitalnetwork.com\">ir@icapitalnetwork.com</a>\n" +
            "                    <br>\n" +
            "                    212-994-7333\n" +
            "                </p>\n" +
            "                <br>\n" +
            "            </div>\n" +
            "        </div>\n" +
            "        <button type=\"button\" ng-click=\"cancelDialog()\">OK</button>\n" +
            "    </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/layout/components/disclaimer/icn_disclaimer.html", "<div class=\"footer-margin\" ng-show=\"showDisclaimer\" style=\"font-size:8pt\">\n" +
            "    <div class=\"custom-disclaimer\" ng-show=\"customDisclaimer()\">\n" +
            "        <span ng-bind-html=\"currentDisclaimer.disclaimer\"></span>\n" +
            "    </div>\n" +
            "    <div class=\"standard-disclaimer\">\n" +
            "        <span ng-bind-html=\"standardDisclaimer\"></span>\n" +
            "    </div>\n" +
            "</div>");

        $templateCache.put("areas/login/forgot_password/forgot_password.html", "<ng-include src=\"'areas/login/templates/unsupported_browser.html'\"></ng-include>\n" +
            "<div class=\"row col-centered password-reset-thank-you\" ng-show=\"showSentMessage\">\n" +
            "  <div class=\"password-reset-title\">\n" +
            "    <h3 ng-if=\"canResetPassword\">Thank You. Your password reset request has been submitted.</h3>\n" +
            "    <h3 ng-if=\"!canResetPassword\">Sorry.  We're unable to reset the password for <strong>{{user.email}}</strong>.</h3>\n" +
            "  </div>\n" +
            "  <div class=\"password-reset-body\" ng-bind-html=\"message\"><br/></div>\n" +
            "  <div class=\"password-reset-footer\"\n" +
            "       ng-if=\"canResetPassword\">\n" +
            "    Haven't received your reset instructions yet? Please check your spam folder or\n" +
            "    <a href=\"mailto:support@icapitalnetwork.com\">Contact Support</a>.<br/><br/>\n" +
            "    <a target=\"_self\" href=\"/login\">Click Here</a> to login.\n" +
            "  </div>\n" +
            "</div>\n" +
            "<div class=\"row\" ng-hide=\"showSentMessage\">\n" +
            "  <div class=\"back-login-link\" ng-if=\"!ssoLoginEnabled\">\n" +
            "    <a target=\"_self\" ng-href=\"/login\">\n" +
            "      <img src=\"/images/back_17x17.svg\">\n" +
            "      <span>back to login</span>\n" +
            "    </a>\n" +
            "  </div>\n" +
            "  <div class=\"col-md-5 col-centered forgot-box\" ng-hide=\"registrationComplete\">\n" +
            "    <div class=\"body\">\n" +
            "      <form id=\"forgot-form\"\n" +
            "            name=\"loginForm\"\n" +
            "            class=\"login-form auth-form\"\n" +
            "            ng-submit=\"forgotPassword()\"\n" +
            "            novalidate>\n" +
            "        <div class=\"login-form-header-container\">\n" +
            "          <h1 class=\"login-form-header\">\n" +
            "            Reset Your Password\n" +
            "          </h1>\n" +
            "          <div ng-hide=\"resetPasswordError\" class=\"forgot-password-message\">\n" +
            "            <p>Forgot your password? Please enter the email address associated with your account to reset it.</p>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "        <div class=\"login-error-container\">\n" +
            "          <div class=\"error login-error\" ng-if=\"serverError\">\n" +
            "            <div class=\"alert-icon-container\">\n" +
            "              <img src=\"images/alert-icon.svg\">\n" +
            "            </div>\n" +
            "            <div class=\"login-error-content\" ng-bind-html=\"serverError\"></div>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "        <icn-input name=\"email\" model=\"user.email\" required></icn-input>\n" +
            "        <div class=\"form-group login-button-container\">\n" +
            "          <button id=\"reset-password\" ng-disabled=\"loginForm.$invalid\" type=\"submit\" class=\"reset_password_button\">\n" +
            "            RESET PASSWORD\n" +
            "          </button>\n" +
            "        </div>\n" +
            "      </form>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/login/login/components/icn_login/templates/blackrock.html", "<div class=\"blackrock-custom-login-action-area\">\n" +
            "  <div class=\"left\">\n" +
            "    <div class=\"description\">\n" +
            "      If you are an advisor, log in here:\n" +
            "    </div>\n" +
            "    <button ng-click=\"goToLink()\">\n" +
            "      Login with BlackRock\n" +
            "    </button>\n" +
            "  </div>\n" +
            "  <div class=\"separator\">\n" +
            "    <div class=\"bar\"></div>\n" +
            "    <div class=\"text\">OR</div>\n" +
            "  </div>\n" +
            "  <div class=\"right\">\n" +
            "    <form name=\"loginForm\" ng-submit=\"login()\" no-validate>\n" +
            "      <div class=\"description\">\n" +
            "        If you are <strong>not</strong> an advisor, log in below or\n" +
            "        <br>\n" +
            "        <a ng-click=\"goToRegistration()\">register now.</a>\n" +
            "      </div>\n" +
            "\n" +
            "      <div class=\"form-group\">\n" +
            "        <input id=\"email\" class=\"email-field\" ng-model=\"user.email\" name=\"email\" type=\"email\" placeholder=\"Email Address\" validator=\"[requiredBlurNoMessage]\">\n" +
            "      </div>\n" +
            "\n" +
            "      <div class=\"form-group\">\n" +
            "        <input id=\"password\" class=\"password-field\" ng-model=\"user.password\" name=\"password\" type=\"password\" placeholder=\"Password\" validator=\"[requiredBlurNoMessage]\">\n" +
            "      </div>\n" +
            "\n" +
            "      <label class=\"remember-me\">\n" +
            "        <input type=\"checkbox\" ng-model=\"user.remember_me\" name=\"remember_me\">Remember Me\n" +
            "      </label>\n" +
            "\n" +
            "      <a ng-href=\"/forgot_password\" class=\"forgot-password\">\n" +
            "          Forgot password?\n" +
            "      </a>\n" +
            "\n" +
            "      <div class=\"relative-wrapper\">\n" +
            "        <div class=\"error-message\" ng-show=\"serverError\">The email or password you entered does <br>not match our records. Please try again.</div>\n" +
            "      </div>\n" +
            "\n" +
            "      <div>\n" +
            "        <button type=\"submit\" id=\"login-button\">\n" +
            "          Login\n" +
            "        </button>\n" +
            "      </div>\n" +
            "    </form>\n" +
            "  </div>\n" +
            "</div>\n" +
            "<div ng-include=\"'areas/login/login/components/icn_login/templates/dev_login.html'\" ng-if=\"(devUsers.length > 0)\"></div>");

        $templateCache.put("areas/login/login/components/icn_login/templates/default.html", "<img class=\"login-form-logo login-form-logo-icapital\" src=\"images/logo.svg\" alt=\"iCapital Network\">\n" +
            "<form id=\"login-form\"\n" +
            "      class=\"login-form\"\n" +
            "      name=\"loginForm\"\n" +
            "      ng-submit=\"login()\"\n" +
            "      ng-show=\"!ssoLoginEnabled\"\n" +
            "      novalidate>\n" +
            "  <div class=\"login-form-header-container\">\n" +
            "    <h1 class=\"login-form-header\">\n" +
            "      Login\n" +
            "    </h1>\n" +
            "  </div>\n" +
            "  <div class=\"login-error-container\">\n" +
            "    <div class=\"error login-error\" ng-if=\"serverError || unprocessableEntity\">\n" +
            "      <div class=\"alert-icon-container\">\n" +
            "        <img src=\"images/alert-icon.svg\">\n" +
            "      </div>\n" +
            "      <div class=\"login-error-content\" ng-show=\"serverError\">\n" +
            "        <div ng-bind-html=\"serverError\"></div>\n" +
            "      </div>\n" +
            "      <div class=\"login-error-content\" ng-show=\"unprocessableEntity\">\n" +
            "        Log in failed, please <a ng-click=\"refreshPage()\">refresh</a> and try again.\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div ng-if=\"loginHelp\" class=\"login-help\">{{loginHelp}}</div>\n" +
            "  <icn-input name=\"email\" model=\"user.email\" required></icn-input>\n" +
            "  <icn-input name=\"password\" model=\"user.password\" disable-autofill=\"true\" required></icn-input>\n" +
            "  <div class=\"login-links\" ng-if=\"!ssoLoginEnabled\">\n" +
            "      <a ng-href=\"/forgot_password\" class=\"login-link\">\n" +
            "          Forgot password?\n" +
            "      </a>\n" +
            "  </div>\n" +
            "  <div class=\"form-group login-button-container\">\n" +
            "    <button ng-disabled=\"loginForm.$invalid\" type=\"submit\" class=\"login-button\" id=\"login-button\">\n" +
            "      Log In\n" +
            "    </button>\n" +
            "  </div>\n" +
            "  <div ng-include=\"'areas/login/login/components/icn_login/templates/dev_login.html'\" ng-if=\"(devUsers.length > 0)\"></div>\n" +
            "</form>");

        $templateCache.put("areas/login/login/components/icn_login/templates/dev_login.html", "<div class=\"form-group login-form-dev\" ng-if=\"(devUsers.length > 0)\">\n" +
            "  <ui-select ng-model=\"dev.user\" theme=\"bootstrap\">\n" +
            "    <ui-select-match placeholder=\"Search users\">{{$select.selected.email}}</ui-select-match>\n" +
            "    <ui-select-choices repeat=\"user in devUsers | filter: $select.search | limitTo:20\">\n" +
            "      <span ng-bind-html=\"user.email | highlight: $select.search\"></span>\n" +
            "    </ui-select-choices>\n" +
            "  </ui-select>\n" +
            "  <br>\n" +
            "  <button type=\"button\" ng-click=\"loginDev()\" class=\"login-button button__bold\" ng-disabled=\"!dev.user\">\n" +
            "    Log in Dev\n" +
            "  </button>\n" +
            "</div>");

        $templateCache.put("areas/login/login/components/icn_login/templates/ieq.html", "<div class=\"ieq-custom-login-action-area\">\n" +
            "  <form id=\"login-form\" name=\"loginForm\" class=\"login-form\" ng-submit=\"login()\" no-validate>\n" +
            "    <div class=\"login-form-header-container\">\n" +
            "      <h1 class=\"login-form-header\">\n" +
            "        Login\n" +
            "      </h1>\n" +
            "    </div>\n" +
            "    <div class=\"login-error-container\">\n" +
            "      <div class=\"error login-error\" ng-if=\"serverError || unprocessableEntity\">\n" +
            "        <div class=\"alert-icon-container\">\n" +
            "          <img src=\"images/alert-icon.svg\">\n" +
            "        </div>\n" +
            "        <div class=\"login-error-content\" ng-show=\"serverError\">\n" +
            "          <div ng-bind-html=\"serverError\"></div>\n" +
            "        </div>\n" +
            "        <div class=\"login-error-content\" ng-show=\"unprocessableEntity\">\n" +
            "          Login failed, please <a ng-click=\"refreshPage()\">refresh</a> and try again.\n" +
            "        </div>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "    <icn-input id=\"email\" name=\"email\" model=\"user.email\" required></icn-input>\n" +
            "    <icn-input id=\"password\" name=\"password\" model=\"user.password\" disable-autofill=\"true\" required></icn-input>\n" +
            "    <div class=\"login-links\" ng-if=\"!ssoLoginEnabled\">\n" +
            "      <a ng-href=\"/forgot_password\" class=\"login-link\">\n" +
            "          Forgot password?\n" +
            "      </a>\n" +
            "    </div>\n" +
            "    <div class=\"ieq-custom-disclaimer-divider\"></div>\n" +
            "    <div class=\"form-group login-button-container\">\n" +
            "      <button ng-disabled=\"loginForm.$invalid\" class=\"login-button\" type=\"submit\" id=\"login-button\">\n" +
            "        Log In\n" +
            "      </button>\n" +
            "    </div>\n" +
            "  </form>\n" +
            "  <img src=\"images/IEQ-icon-access.png\" width=\"122\" height=\"122\" alt=\"\" class=\"icon-access\">\n" +
            "</div>");

        $templateCache.put("areas/login/login/login_index.html", "<ng-include src=\"'areas/login/templates/unsupported_browser.html'\"></ng-include>\n" +
            "<div><div class=\"row\" ng-include=\"pageContent\" ng-if=\"!shouldLoadNewLoginPage()\"></div></div>");

        $templateCache.put("areas/login/login/templates/blackrock.html", "<div class=\"blackrock-custom\">\n" +
            "  <div class=\"blackrock-custom-top-divider\"></div>\n" +
            "  <div class=\"col-md-12 blackrock-custom-subtitle\">\n" +
            "    Welcome to BlackRock Alternative Investments Portal\n" +
            "  </div>\n" +
            "  <div class=\"col-md-12 blackrock-custom-description p-login\">\n" +
            "    The BlackRock Alternative Investments Portal, powered by iCapital Network,<br>\n" +
            "    offers you and your clients a streamlined experience across investment diligence,<br>\n" +
            "    subscription processing, and ongoing investment monitoring and reporting.\n" +
            "  </div>\n" +
            "  <div class=\"centering-wrapper\">\n" +
            "    <icn-login user=\"user\" wlp-name=\"wlpName\"\n" +
            "               sso-login-enabled=\"ssoLoginEnabled\" sso-login-url=\"ssoLoginUrl\" custom-template=\"blackrock\"></icn-login>\n" +
            "  </div>\n" +
            "  <div class=\"centering-wrapper\">\n" +
            "    <div class=\"blackrock-custom-disclaimer-divider\"></div>\n" +
            "  </div>\n" +
            "  <div class=\"centering-wrapper\">\n" +
            "    <div class=\"wlp-disclaimer-box\">\n" +
            "        <div class=\"messageCenter-message-body\" ng-bind-html=\"wlpDisclaimerHtml\" ng-show=\"wlpDisclaimerHtml\"></div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/login/login/templates/default.html", "<div class=\"row\">\n" +
            "    <div class=\"col-md-5 col-centered login-box\">\n" +
            "        <div class=\"body login-container\">\n" +
            "            <icn-login user=\"user\"\n" +
            "                       wlp-name=\"wlpName\"\n" +
            "                       sso-login-url=\"ssoLoginUrl\">\n" +
            "            </icn-login>\n" +
            "            <div class=\"btn-login-wrapper center-block\" ng-show=ssoLoginEnabled>\n" +
            "                <div ng-if=\"ssoLoginHelp\" class=\"sso-login-help\">{{ssoLoginHelp}}</div>\n" +
            "                <div class=\"sso-links\">\n" +
            "                    <a ng-if=\"useCustomSSOLink\" class=\"login-link custom-sso-link\" target=\"_self\" href=\"{{ssoLoginUrl}}\">Log in with {{ssoLoginName}}</a>\n" +
            "                </div>\n" +
            "            </div>\n" +
            "            <div ng-show=\"showRegister()\" class=\"login-form-register\">\n" +
            "                <span>Not a member yet?</span> \n" +
            "                <br/>\n" +
            "                <a ng-click=\"redirectLogin()\" class=\"login-link\">Register Here</a>\n" +
            "            </div>\n" +
            "        </div>\n" +
            "    </div>\n" +
            "</div>");

        $templateCache.put("areas/login/login/templates/ieq.html", "<div class=\"ieq-custom\">\n" +
            "  <div class=\"row ieq-custom-header\">\n" +
            "    <div class=\"item\">\n" +
            "      <div class=\"col-md-11 p-0\">\n" +
            "        <span class=\"header-text\">IEQ Document Vault</span>\n" +
            "      </div>\n" +
            "      <div class=\"col-md-1 p-0\">\n" +
            "        <img src=\"images/IEQ-logo.png\" width=\"80\" height=\"80\" class=\"f-right\">\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"row\">\n" +
            "    <div class=\"col-md-5 col-centered login-box\">\n" +
            "      <div class=\"body\">\n" +
            "        <icn-login\n" +
            "          user=\"user\"\n" +
            "          wlp-name=\"wlpName\"\n" +
            "          sso-login-enabled=\"ssoLoginEnabled\"\n" +
            "          sso-login-url=\"ssoLoginUrl\"\n" +
            "          custom-template=\"ieq\">\n" +
            "        </icn-login>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/login/reset_password/reset_password.html", "<ng-include src=\"'areas/login/templates/unsupported_browser.html'\"></ng-include>\n" +
            "<div class=\"row\">\n" +
            "  <div class=\"reset-back-arrow col-centered\">\n" +
            "    <a ng-if=\"!ssoLoginEnabled\" class=\"back-link\" href=\"/login\" target=\"_self\">\n" +
            "      <span>Back to login</span>\n" +
            "    </a>\n" +
            "  </div>\n" +
            "  <div class=\"col-md-5 col-centered reset-container\">\n" +
            "    <div class=\"body\">\n" +
            "      <form id=\"reset-form\"\n" +
            "            name=\"loginForm\"\n" +
            "            class=\"login-form registration-form\"\n" +
            "            ng-submit=\"resetPassword()\"\n" +
            "            novalidate>\n" +
            "        <div class=\"reset-form-header-container\">\n" +
            "          <h1 class=\"login-form-header\">\n" +
            "            Reset Your Password\n" +
            "          </h1>\n" +
            "        </div>\n" +
            "        <div class=\"row reset-container-body\">\n" +
            "          <div class=\"col-md-6 col-left-reset\">\n" +
            "            <icn-input type=\"password\"\n" +
            "                      model=\"user.password\"\n" +
            "                      name=\"password\"\n" +
            "                      inputclass=\"registration-input\"\n" +
            "                      class=\"has-message\"\n" +
            "                      label=\"Create Password\"\n" +
            "                      ng-class=\"{ 'password-confirmation-error' : passwordHasError(loginForm.password) }\"\n" +
            "                      required>\n" +
            "            </icn-input>\n" +
            "            <div class=\"login-message-container\" ng-if=\"user.isPasswordInvalid\">\n" +
            "              <p class=\"error confirmation-error\">\n" +
            "                Password does not meet security requirements. Please refrain from using common words, variations of your name, email, or references to iCapital. \n" +
            "              </p>\n" +
            "            </div>\n" +
            "            <icn-input type=\"password\"\n" +
            "                      model=\"user.password_confirmation\"\n" +
            "                      name=\"password_confirmation\"\n" +
            "                      inputclass=\"registration-input\"\n" +
            "                      label=\"Confirm Password\"\n" +
            "                      class=\"has-message\"\n" +
            "                      ng-blur=\"confirmedPasswordHasError(registrationForm.password, registrationForm.password_confirmation)\"\n" +
            "                      ng-class=\"{ 'password-confirmation-error' : confirmPasswordFieldHasError }\"\n" +
            "                      required>\n" +
            "            </icn-input>\n" +
            "            <div class=\"login-message-container\">\n" +
            "              <p class=\"error confirmation-error\"\n" +
            "                ng-show=\"confirmedPasswordHasError(loginForm.password, loginForm.password_confirmation)\">\n" +
            "                Confirmed password does not match\n" +
            "              </p>\n" +
            "            </div>\n" +
            "          </div>\n" +
            "          <div class=\"col-md-5 col-right-register\">\n" +
            "            <icn-password-steps user=\"user\"></icn-password-steps>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "        <hr class=\"registration-line-large\" ng-class=\"{ 'line-error' : confirmedPasswordHasError(loginForm.password, loginForm.password_confirmation) }\">\n" +
            "        <div class=\"login-message-container\" ng-if=\"serverError.unknownError\">\n" +
            "          <p class=\"error confirmation-error\">\n" +
            "            {{ unknownErrorMessage }}\n" +
            "          </p>\n" +
            "        </div>\n" +
            "        <div class=\"form-group reset-button-container\">\n" +
            "          <button id=\"reset-password\"\n" +
            "                  class=\"reset_password_button login-button\"\n" +
            "                  type=\"submit\"\n" +
            "                  ng-disabled=\"loginForm.$invalid || !allCompleted()\">\n" +
            "            RESET PASSWORD\n" +
            "          </button>\n" +
            "        </div>\n" +
            "      </form>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/login/templates/unlocked.html", "<div class=\"accountUnlocked row\">\n" +
            "  <div class=\"accountUnlocked-title\">\n" +
            "    <h3>Your account has been unlocked</h3>\n" +
            "  </div>\n" +
            "  <div class=\"accountUnlocked-body\">\n" +
            "    Please <a href=\"/login\" target=\"_self\">click here</a> to sign in.\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/login/templates/unsupported_browser.html", "<div class=\"row col-centered\" ng-hide=\"lessthanIE9()==undefined\">\n" +
            "    <div class=\"login-outdated-browser\">\n" +
            "        <b>ATTENTION:</b> The browser we detected is unsupported and may result in unexpected\n" +
            "        behavior. This application is best viewed in an up to date version of Chrome,\n" +
            "        Internet Explorer, or Firefox. We recommend you update your browser or consider\n" +
            "        switching to one of our supported browsers before viewing the site.\n" +
            "        <br>Download an up to date version:\n" +
            "        <br>\n" +
            "        <a href=\"https://www.google.com/intl/en/chrome/browser/\">Chrome</a> |\n" +
            "        <a href=\"http://www.microsoft.com/en-us/download/internet-explorer.aspx\">Internet Explorer</a> |\n" +
            "        <a href=\"http://www.mozilla.org/en-US/firefox/new/\">Firefox</a>\n" +
            "    </div>\n" +
            "</div>");

        $templateCache.put("areas/no_tab/accreditation/pages/accreditation/accreditation.html", "<form id=\"accreditation-form\" ng-model=\"user_accreditation\" ng-model=\"user_accreditation\" name=\"accreditation_form\"\n" +
            "      ng-submit=\"submitAccreditations(data)\" class=\"form-horizontal\" role=\"form\" novalidate\n" +
            "      ng-hide=\"wait_for_contact || show_thank_you\">\n" +
            "  <div ng-include=\"instructionTemplates[accreditation_type]\"></div>\n" +
            "  <span class='accreditation-field' ng-repeat=\"field in accreditationFields[accreditation_type]\">\n" +
            "    <div ng-include='formTemplates[field]'></div>\n" +
            "    <div ng-include='formTemplates.section_seperator'></div>\n" +
            "  </span>\n" +
            "\n" +
            "  <div ng-include='formTemplates.signature'></div>\n" +
            "  <div ng-include='formTemplates.section_seperator'></div>\n" +
            "  <div class=\"row more-foot-room\">\n" +
            "    <div class=\"col-md-12\">\n" +
            "      <button>\n" +
            "        Verify and Create Account\n" +
            "      </button>\n" +
            "    </div>\n" +
            "    <div class=\"col-md-12 head-room\">\n" +
            "        <span class=\"error\" ng-show=\"!accreditation_form.$valid && submitted\">\n" +
            "            Missing {{ accreditation_form.$error.required.length }} required field(s).\n" +
            "            Please complete the highlighted items above.\n" +
            "        </span>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</form>\n" +
            "<div ng-show='wait_for_contact'>\n" +
            "  <div ng-include='formTemplates.wait_for_contact'></div>\n" +
            "</div>\n" +
            "<div ng-show='show_thank_you'>\n" +
            "  <div ng-include='formTemplates.thank_you'></div>\n" +
            "</div>");

        $templateCache.put("areas/no_tab/accreditation/pages/accreditation/templates/accreditation_fields/address.html", "<div class=\"row head-room\">\n" +
            "  <div class=\"col-md-3\">\n" +
            "    <label ng-class=\"{'error': (addressErrors() && submitted) }\">Permanent Address</label>\n" +
            "  </div>\n" +
            "  <div class='col-md-8 address-group'>\n" +
            "    <div class=\"col-md-7 form-inline\">\n" +
            "      <span class=\"form-group\" ng-class=\"{'has-error': (accreditation_form.country.$error.required && submitted) }\">\n" +
            "        <label for=\"country\" class=\"sr-only\"></label>\n" +
            "        <select class=\"input-m form-control wide-input\"\n" +
            "                name=\"country\"\n" +
            "                ng-model=\"user_accreditation.country\"\n" +
            "                ng-options=\"country.name as country.name for country in countryList\"\n" +
            "                validator=\"[requiredBlurNoMessage]\"\n" +
            "                required>\n" +
            "          <option value=\"\">Select Country</option>\n" +
            "       </select>\n" +
            "      </span>\n" +
            "    </div>\n" +
            "    <div class=\"col-md-7\" ng-show=\"addresses.length > 1\">\n" +
            "      <label for=\"addressselect\">\n" +
            "        <select name=\"address\"\n" +
            "                class=\"wide-input\"\n" +
            "                ng-model=\"address\"\n" +
            "                ng-options=\"(currOption.address1 + ', ' +currOption.city) for currOption in addresses\"\n" +
            "                ng-change=\"setAddress(address)\">\n" +
            "          <option value=\"\">Select Address</option>\n" +
            "        </select>\n" +
            "      </label>\n" +
            "    </div>\n" +
            "    <div class=\"col-md-7 form-inline\">\n" +
            "      <span class=\"form-group\" ng-class=\"{'has-error': (accreditation_form.address1.$error.required && submitted) }\">\n" +
            "        <label for=\"address1\" class=\"sr-only\"></label>\n" +
            "        <input type=\"text\"\n" +
            "               class=\"form-control wide-input\"\n" +
            "               placeholder=\"Street Address 1\"\n" +
            "               name=\"address1\"\n" +
            "               ng-model=\"user_accreditation.address1\"\n" +
            "               validator=\"[requiredBlurNoMessage]\"\n" +
            "               required>\n" +
            "      </span>\n" +
            "    </div>\n" +
            "    <div class=\"col-md-7 form-inline\">\n" +
            "      <input type=\"text\"\n" +
            "             placeholder=\"Street Address 2\"\n" +
            "             name=\"address2\"\n" +
            "             ng-model=\"user_accreditation.address2\"\n" +
            "             class=\"form-control wide-input\">\n" +
            "    </div>\n" +
            "    <div class=\"col-md-7 form-inline\">\n" +
            "      <span class=\"form-group\" ng-class=\"{'has-error': (accreditation_form.city.$error.required && submitted) }\">\n" +
            "        <label for=\"city\" class=\"sr-only\">\n" +
            "        </label>\n" +
            "        <input type=\"text\"\n" +
            "               name=\"city\"\n" +
            "               placeholder=\"City\"\n" +
            "               ng-model=\"user_accreditation.city\"\n" +
            "               class=\"short-input form-control\"\n" +
            "               validator=\"[requiredBlurNoMessage]\"\n" +
            "               required>\n" +
            "      </span>\n" +
            "      <span class=\"form-group form-inline\" ng-class=\"{'has-error': (accreditation_form.state.$error.required && submitted) }\">\n" +
            "        <label for=\"state\" class=\"sr-only\">\n" +
            "        </label>\n" +
            "        <select class=\"short-input input-m form-control\"\n" +
            "                name=\"state\"\n" +
            "                ng-model=\"user_accreditation.state\"\n" +
            "                ng-options=\"state.abbreviation as state.abbreviation for state in usStates\"\n" +
            "                validator=\"[requiredBlurNoMessage]\"\n" +
            "                ng-show=\"user_accreditation.country === 'United States of America'\"\n" +
            "                required>\n" +
            "          <option value=\"\">State</option>\n" +
            "        </select>\n" +
            "        <input type=\"text\"\n" +
            "               name=\"state\"\n" +
            "               placeholder=\"Province\"\n" +
            "               ng-model=\"user_accreditation.state\"\n" +
            "               class=\"short-input form-control\" validator=\"[requiredBlurNoMessage]\"\n" +
            "               ng-hide=\"user_accreditation.country === 'United States of America'\"\n" +
            "               required>\n" +
            "      </span>\n" +
            "    </div>\n" +
            "    <div class=\"col-md-8 form-inline\">\n" +
            "      <span class=\"form-group\"  ng-class=\"{'has-error': (accreditation_form.zip.$error.required && submitted) }\">\n" +
            "        <label for=\"zip\" class=\"sr-only\">\n" +
            "        </label>\n" +
            "        <input type=\"text\"\n" +
            "               name=\"zip\"\n" +
            "               placeholder=\"{{ user_accreditation.country === 'United States of America' ? 'Zip Code' : 'Postal Code' }}\"\n" +
            "               ng-model=\"user_accreditation.zip\"\n" +
            "               ng-attr-maxlength=\"{{ user_accreditation.country === 'United States of America' ? '5' : '' }}\"\n" +
            "               class=\"short-input form-control\"\n" +
            "               validator=\"[requiredBlurNoMessage]\"\n" +
            "               required>\n" +
            "      </span>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/no_tab/accreditation/pages/accreditation/templates/accreditation_fields/advisor.html", "<div class=\"row head-room\" ng-class=\"{'has-error': (accreditation_form.advisor_confirmed.$error.required && submitted) }\">\n" +
            "  <div class=\"col-md-3\">\n" +
            "      <label class=\"control-label\">Do you have a financial advisor? *</label>\n" +
            "  </div>\n" +
            "  <div class=\"col-md-8\">\n" +
            "    <span class=\"form-group\">\n" +
            "      <label style=\"font-weight: normal\">\n" +
            "        <input type=\"radio\"\n" +
            "               ng-model=\"user_accreditation.advisor_confirmed\"\n" +
            "               name=\"advisor_confirmed\"\n" +
            "               id=\"optionsRadios1\"\n" +
            "               validator=\"[requiredBlur]\"\n" +
            "               ng-value=\"true\"\n" +
            "               required>\n" +
            "        Yes\n" +
            "      </label>\n" +
            "    </span>\n" +
            "    <span class=\"form-group\">\n" +
            "      <label style=\"font-weight: normal\">\n" +
            "        <input type=\"radio\"\n" +
            "               ng-model=\"user_accreditation.advisor_confirmed\"\n" +
            "               name=\"advisor_confirmed\"\n" +
            "               id=\"optionsRadios2\"\n" +
            "               validator=\"[requiredBlur]\"\n" +
            "               ng-value=\"false\"\n" +
            "               required>\n" +
            "        No\n" +
            "      </label>\n" +
            "    </span>\n" +
            "  </div>\n" +
            "</div>\n" +
            "<div class=\"row head-room address-group\" ng-class=\"{'has-error': (accreditation_form.firm_name.$error.required && submitted) }\" ng-show=\"user_accreditation.advisor_confirmed\" >\n" +
            "  <div class=\"col-md-3\">\n" +
            "      <label class=\"control-label\">Name of Firm *</label>\n" +
            "  </div>\n" +
            "  <div class=\"col-md-7 form-inline\">\n" +
            "    <span class=\"form-group\">\n" +
            "      <label for=\"firm_name\" class=\"sr-only\"></label>\n" +
            "      <input type=\"text\"\n" +
            "             class=\"form-control wide-input\"\n" +
            "             placeholder=\"Firm Name\"\n" +
            "             name=\"firm_name\"\n" +
            "             ng-model=\"user_accreditation.firm_name\"\n" +
            "             ng-required=\"user_accreditation.advisor_confirmed\"\n" +
            "             validator=\"[requiredBlur]\">\n" +
            "    </span>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/no_tab/accreditation/pages/accreditation/templates/accreditation_fields/email.html", "<div class=\"row\">\n" +
            "  <div class=\"col-md-3\">\n" +
            "    <label class=\"accreditation-label\">Email</label>\n" +
            "  </div>\n" +
            "  <div class='col-md-8'>\n" +
            "    <label for=\"email\">\n" +
            "      <p class=\"form-control-static\">{{user.email}}</p>\n" +
            "    </label>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/no_tab/accreditation/pages/accreditation/templates/accreditation_fields/full_name.html", "<div class=\"row\">\n" +
            "  <div class=\"col-md-3\">\n" +
            "      <label class=\"accreditation-label\"\n" +
            "             ng-class=\"{'error': (fullNameErrors() && submitted) }\">Full Name: *</label>\n" +
            "  </div>\n" +
            "  <div class='col-md-8 form-inline'>\n" +
            "      <span class=\"form-group\" ng-class=\"{'has-error': (accreditation_form.first_name.$error.required && submitted) }\">\n" +
            "        <label for=\"full_name\" class=\"sr-only\"></label>\n" +
            "        <input type=\"text\" placeholder=\"First\" name=\"first_name\" ng-model=\"user_accreditation.first_name\" validator=\"[requiredBlurNoMessage]\"\n" +
            "        required class=\"form-control\" style=\"width:140px;\" />\n" +
            "      </span>\n" +
            "      <span class=\"form-group\">\n" +
            "        <label for=\"middle_initial\" class=\"sr-only\"></label>\n" +
            "        <input type=\"text\" placeholder=\"MI\" name=\"middle_initial\" ng-model=\"user_accreditation.middle_initial\" class=\"form-control\" style=\"width:40px;\"/>\n" +
            "      </span>\n" +
            "      <span class=\"form-group\" ng-class=\"{'has-error': (accreditation_form.last_name.$error.required && submitted) }\">\n" +
            "        <label for=\"last_name\" class=\"sr-only\"></label>\n" +
            "        <input type=\"text\" placeholder=\"Last\" name=\"last_name\" ng-model=\"user_accreditation.last_name\"  class=\"form-control\" validator=\"[requiredBlurNoMessage]\" style=\"width:140px;\"\n" +
            "        required/>\n" +
            "      </span>\n" +
            "\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/no_tab/accreditation/pages/accreditation/templates/accreditation_fields/organization_name.html", "<div class=\"row head-room\">\n" +
            "  <div class=\"col-md-3\" ng-class=\"{'error': (accreditation_form.firm_name.$error.required && submitted) }\">\n" +
            "      <label class=\"accreditation-label\">Organization Name: *</label>\n" +
            "  </div>\n" +
            "  <div class='col-md-8 form-group' ng-class=\"{'has-error': (accreditation_form.firm_name.$error.required && submitted) }\">\n" +
            "      <input type=\"text\" placeholder=\"Organization Name\" name=\"firm_name\" ng-model=\"user_accreditation.firm_name\" style=\"width:412px;\"\n" +
            "      required  validator=\"[requiredBlur]\" class=\"input-sm form-control\" />\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/no_tab/accreditation/pages/accreditation/templates/accreditation_fields/organization_role.html", "<div class=\"row\">\n" +
            "  <div class=\"col-md-3\" ng-class=\"{'error': (accreditation_form.role.$error.required && submitted) }\">\n" +
            "      <label class=\"accreditation-label\">Role at Organization: *</label>\n" +
            "  </div>\n" +
            "  <div class='col-md-4 form-group' ng-class=\"{'has-error': (accreditation_form.role.$error.required && submitted) }\">\n" +
            "      <input type=\"text\" placeholder=\"Role at Organization\" name=\"role\" ng-model=\"user_accreditation.role\"\n" +
            "             validator=\"[requiredBlur]\" class=\"input-sm form-control\" required />\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/no_tab/accreditation/pages/accreditation/templates/accreditation_fields/phone.html", "<div class=\"row\" ng-if=\"showPhoneField()\">\n" +
            "  <div class=\"col-md-3\">\n" +
            "    <label class=\"accreditation-label\">Phone Number (Optional):</label>\n" +
            "  </div>\n" +
            "  <div class='col-md-8'>\n" +
            "    <div class=\"col-md-8 form-inline\" style=\"margin-top:2px;padding-left:0px;\">\n" +
            "      <input type=\"text\"\n" +
            "             name=\"phone\" ng-model=\"user_accreditation.phone\"\n" +
            "             class=\"form-control\"\n" +
            "             style=\"width:130px;\"\n" +
            "             ui-mask=\"{{'(999) 999-9999'}}\"\n" +
            "          />\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/no_tab/accreditation/pages/accreditation/templates/accreditation_help/investments.html", "For these purposes, the term \"investments\" means any or all: (i) securities (as defined in the Securities Act), except for securities of issuers controlled by the Investor (\"Control Securities\"), unless (A) the issuer of the Control Securities is itself a registered or private investment company or is exempted from the definition of investment company by Rule 3a-6 or Rule 3a-7 under the Company Act, (B) the Control Securities represent securities of an issuer that files reports pursuant to Section 13 or 15(d) of the Securities Exchange Act of 1934, as amended, (C) the issuer of the Control Securities has a class of securities listed on a designated offshore securities market under Regulation S under the Securities Act, or (D) the issuer of the Control Securities is a private company with shareholders' equity not less than $50 million determined in accordance with generally accepted accounting principles, as reflected in the company's most recent financial statements (provided such financial statements were issued within 16 months of the date of Investor's purchase of an Interest); (ii) futures contracts or options thereon held for investment purposes; (iii) physical commodities held for investment purposes; (iv) swaps and other similar financial contracts entered into for investment purposes; (v) real estate held for investment purposes; and (vi) cash and cash equivalents held for investment purposes.--");

        $templateCache.put("areas/no_tab/accreditation/pages/accreditation/templates/accreditation_help/net_worth.html", "The term \"net worth\" means the excess of total assets at fair market value, including home furnishings and automobiles, over total liabilities; provided that, (i) the Investor's primary residence shall not be included as an asset, (ii) indebtedness that is secured by the Investor's primary residence, up to the estimated fair market value of the primary residence at the time of the sale of the Interests, shall not be included as a liability (except that if the amount of such indebtedness outstanding at the time of sale of the Interests exceeds the amount outstanding 60 days before such time, other than as a result of the acquisition of the primary residence, the amount of such excess shall be included as a liability), and (iii) indebtedness that is secured by the Investor's primary residence in excess of the estimated fair market value of the primary residence at the time of the sale of the Interests shall be included as a liability.");

        $templateCache.put("areas/no_tab/accreditation/pages/accreditation/templates/accreditation_instructions/aicl_instructions.html", "<div>\n" +
            "  <div>\n" +
            "    <h2>Individual Investor Client Questionnaire</h2>\n" +
            "    <div>Please complete the below questionnaire for access to the iCapital Network platform.</div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/no_tab/accreditation/pages/accreditation/templates/accreditation_instructions/qp_instructions.html", "<div class=\"row\">\n" +
            "  <div class=\"col-md-12\">\n" +
            "    <h2>Individual Investor Questionnaire</h2>\n" +
            "    <div>\n" +
            "        Please complete the below questionnaire for access to the iCapital Network platform.\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/no_tab/accreditation/pages/accreditation/templates/accreditation_instructions/qpc_instructions.html", "<div class=\"row\">\n" +
            "  <div class=\"col-md-12\">\n" +
            "    <h2>Individual Investor Client Questionnaire</h2>\n" +
            "    <div>Please complete the below questionnaire for access to the iCapital Network platform.</div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/no_tab/accreditation/pages/accreditation/templates/accreditation_instructions/ria_instructions.html", "<div class=\"row\">\n" +
            "  <div class=\"col-md-12\">\n" +
            "    <h2>Investment Advisor Onboarding Questionnaire</h2>\n" +
            "    <div>Please complete the below questionnaire for access to the iCapital Network platform.</div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/no_tab/accreditation/pages/accreditation/templates/accreditation_instructions/sfo_instructions.html", "<div class=\"row\">\n" +
            "  <div class=\"col-md-12\">\n" +
            "    <h2>Single Family Office Questionnaire</h2>\n" +
            "    <div>Please complete the below questionnaire for access to the iCapital Network platform.</div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/no_tab/accreditation/pages/accreditation/templates/accreditation_sections/aicl_questionnaire.html", "<div class=\"accreditation-container aicl-inset\">\n" +
            "  <div class=\"container-fluid\">\n" +
            "    <div class=\"row\">\n" +
            "      <span class=\"accreditation-section-with-logo\">\n" +
            "        <div class=\"accreditation-section row\">\n" +
            "          <div class=\"accreditation-section-confirmation col-md-1\" ng-class=\"{'has-error': (accreditation_form.advisor_confirmed.$error.required && submitted) }\">\n" +
            "            <input name=\"advisor_confirmed\" ng-model=\"user_accreditation.advisor_confirmed\" type=\"checkbox\" required/>\n" +
            "          </div>\n" +
            "          <div class=\"col-md-11\">\n" +
            "            <div>\n" +
            "              <span style=\"vertical-align: top;\" ng-class=\"{'error': (accreditation_form.advisor_confirmed.$error.required && submitted) }\">\n" +
            "                I confirm that I am a current client of\n" +
            "                  <span class=\"accreditation-advisor-name\" style=\"vertical-align: top;\">{{user_accreditation.advisor_name}}</span>. *\n" +
            "              </span>\n" +
            "            </div>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "      </span>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"accreditation-section row\" ng-class=\"{'has-error': (accreditation_form.aicl_confirmed.$error.required && submitted) }\">\n" +
            "    <div class=\"accreditation-section-confirmation col-md-1\"><input name=\"aicl_confirmed\" ng-model=\"user_accreditation.aicl_confirmed\" type=\"checkbox\" required/></div>\n" +
            "    <div class=\"col-md-10\" ng-class=\"{'error': (accreditation_form.aicl_confirmed.$error.required && submitted) }\">\n" +
            "      <div>I am an Accredited Investor (as defined under Regulation D promulgated under the Securities Act of 1933). *</div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/no_tab/accreditation/pages/accreditation/templates/accreditation_sections/annual_income.html", "<div class=\"row\">\n" +
            "      <div class=\"col-md-12\">\n" +
            "        <p class=\"lead\">Annual Income</p>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "    <div class=\"row form-group\" ng-class=\"{'has-error': (accreditation_form.income_two_years_ago.$error.required && submitted) }\">\n" +
            "      <div class=\"col-md-3\">\n" +
            "        <label class=\"control-label\"><span ng-bind=\"years.two_years_ago\"></span> Income *</label>\n" +
            "      </div>\n" +
            "      <div class=\"col-md-4\">\n" +
            "        <select class=\"form-control input-sm\" validator=\"[requiredBlur]\" ng-model=\"user_accreditation.income_two_years_ago\" class=\"form-control input-sm\" name=\"income_two_years_ago\" ng-options=\"currOption for currOption in incomeOptions\" required>\n" +
            "          <option value=\"\">Select</option>\n" +
            "        </select>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "    <div class=\"row form-group\" ng-class=\"{'has-error': (accreditation_form.income_one_year_ago.$error.required && submitted) }\">\n" +
            "      <div class=\"col-md-3\">\n" +
            "        <label class=\"control-label\"><span ng-bind=\"years.one_year_ago\"></span> Income *</label>\n" +
            "      </div>\n" +
            "      <div class=\"col-md-4\">\n" +
            "        <select class=\"form-control input-sm\" validator=\"[requiredBlur]\" ng-model=\"user_accreditation.income_one_year_ago\" name=\"income_one_year_ago\" ng-options=\"currOption for currOption in incomeOptions\" required>\n" +
            "          <option value=\"\">Select</option>\n" +
            "        </select>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "    <div class=\"row form-group\" ng-class=\"{'has-error': (accreditation_form.income_current_year.$error.required && submitted) }\">\n" +
            "      <div class=\"col-md-3\">\n" +
            "        <label class=\"control-label\"><span ng-bind=\"years.current_year\"></span> Income (Expected) *</label>\n" +
            "      </div>\n" +
            "      <div class=\"col-md-4\">\n" +
            "        <select class=\"form-control input-sm\" validator=\"[requiredBlur]\" ng-model=\"user_accreditation.income_current_year\" name=\"income_current_year\" ng-options=\"currOption for currOption in incomeOptions\" required>\n" +
            "          <option value=\"\">Select</option>\n" +
            "        </select>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "    <div class=\"row head-room\">\n" +
            "      <div class=\"col-md-3\">\n" +
            "        <label class=\"control-label\">How is this income calculated?</label>\n" +
            "      </div>\n" +
            "      <div class=\"col-md-8\">\n" +
            "          <span class=\"form-group\">\n" +
            "          <label style=\"font-weight: normal\">\n" +
            "            <input type=\"radio\" ng-model=\"user_accreditation.income_calculation_type\" name=\"income_calculation_type\" id=\"optionsRadios1\" value=\"individual\" ng-checked=\"true\">\n" +
            "            Individual\n" +
            "          </label>\n" +
            "          </span>\n" +
            "          <span class=\"form-group\">\n" +
            "          <label style=\"font-weight: normal\">\n" +
            "            <input type=\"radio\" ng-model=\"user_accreditation.income_calculation_type\" name=\"income_calculation_type\" id=\"optionsRadios2\" value=\"joint\">\n" +
            "            Joint with Spouse\n" +
            "          </label>\n" +
            "          </span>\n" +
            "      </div>\n" +
            "    </div>");

        $templateCache.put("areas/no_tab/accreditation/pages/accreditation/templates/accreditation_sections/employment.html", "<div class=\"row\">\n" +
            "  <div class=\"col-md-12\">\n" +
            "    <p class=\"lead\">Employment</p>\n" +
            "  </div>\n" +
            "</div>\n" +
            "\n" +
            "<div class=\"row form-group\" ng-class=\"{'has-error': (accreditation_form.employment_status.$error.required && submitted) }\">\n" +
            "  <div class=\"col-md-3\">\n" +
            "    <label class=\"control-label\">Employment Status *</label>\n" +
            "  </div>\n" +
            "  <div class=\"col-md-4\">\n" +
            "    <select class=\"form-control input-sm\" validator=\"[requiredBlur]\" ng-model=\"user_accreditation.employment_status\"\n" +
            "            ng-change=\"setValidator()\"\n" +
            "            name=\"employment_status\" ng-options=\"currOption for currOption in employmentOptions\" required>\n" +
            "      <option value=\"\"> Select</option>\n" +
            "    </select>\n" +
            "  </div>\n" +
            "</div>\n" +
            "<div class=\"row form-group\" ng-class=\"{'has-error': (accreditation_form.employer_name.$error.required && submitted) }\">\n" +
            "    <div class=\"col-md-3\">\n" +
            "        <label class=\"control-label\">Employer Name *</label>\n" +
            "    </div>\n" +
            "    <div class=\"col-md-4\">\n" +
            "        <input type=\"text\" value=\"\" class=\"form-control input-sm\" ng-model=\"user_accreditation.employer\"\n" +
            "               validator=\"{{employmentValidator}}\" name=\"employer_name\" ng-required=\"!isUnemployed()\" />\n" +
            "    </div>\n" +
            "</div>\n" +
            "<div class=\"row form-group\" ng-class=\"{'has-error': (accreditation_form.type_of_business.$error.required && submitted) }\">\n" +
            "  <div class=\"col-md-3\">\n" +
            "    <label class=\"control-label\">Type of Business *</label>\n" +
            "  </div>\n" +
            "  <div class=\"col-md-4\">\n" +
            "      <input type=\"text\" value=\"\" class=\"form-control input-sm\" validator=\"{{employmentValidator}}\"\n" +
            "             ng-model=\"user_accreditation.type_of_business\" name=\"type_of_business\" ng-required=\"!isUnemployed()\" />\n" +
            "  </div>\n" +
            "</div>\n" +
            "<div class=\"row form-group\" ng-class=\"{'has-error': (accreditation_form.job_title.$error.required && submitted) }\">\n" +
            "  <div class=\"col-md-3\">\n" +
            "    <label class=\"control-label\">Job title / Occupation *</label>\n" +
            "  </div>\n" +
            "  <div class=\"col-md-4\">\n" +
            "    <input type=\"text\" class=\"form-control input-sm\" validator=\"{{employmentValidator}}\"\n" +
            "           ng-model=\"user_accreditation.role\" name=\"job_title\" ng-required=\"!isUnemployed()\" />\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/no_tab/accreditation/pages/accreditation/templates/accreditation_sections/firm_assets.html", "<div class=\"row\">\n" +
            "  <div class=\"col-md-12\">\n" +
            "    <p class=\"lead\">Total Assets and Investments</p>\n" +
            "  </div>\n" +
            "</div>\n" +
            "<div class=\"row head-room form-inline form-group\">\n" +
            "    <div class=\"col-md-3\" ng-class=\"{'error': (accreditation_form.firm_assets.$error.required && submitted) }\">\n" +
            "      <label class=\"control-label\">Assets: *</label>\n" +
            "    </div>\n" +
            "    <div class=\"col-md-4\" ng-class=\"{'has-error': (accreditation_form.firm_assets.$error.required && submitted) }\">\n" +
            "      <select validator=\"[requiredBlur]\" class=\"input-sm form-control\" ng-model=\"user_accreditation.firm_assets\"\n" +
            "      name=\"firm_assets\" required ng-options=\"currOption for currOption in firmInvestmentValueOptions\">\n" +
            "        <option value=\"\">Select</option>\n" +
            "      </select>\n" +
            "    </div>\n" +
            "</div>\n" +
            "\n" +
            "<div class=\"row head-room form-inline form-group\">\n" +
            "    <div class=\"col-md-3\" ng-class=\"{'error': (accreditation_form.firm_investment_total_value.$error.required && submitted) }\">\n" +
            "      <label class=\"control-label\">Total Value of Investments: *</label>\n" +
            "    </div>\n" +
            "    <div class=\"col-md-4\" ng-class=\"{'has-error': (accreditation_form.firm_investment_total_value.$error.required && submitted) }\">\n" +
            "      <select validator=\"[requiredBlur]\" class=\"input-sm form-control\" ng-model=\"user_accreditation.firm_investment_total_value\"\n" +
            "              required name=\"firm_investment_total_value\" ng-options=\"currOption for currOption in firmInvestmentValueOptions\">\n" +
            "        <option value=\"\">Select</option>\n" +
            "      </select>\n" +
            "    </div>\n" +
            "</div>");

        $templateCache.put("areas/no_tab/accreditation/pages/accreditation/templates/accreditation_sections/firm_contact_information.html", "<div class=\"row more-head-room\">\n" +
            "    <div class=\"col-md-12\">\n" +
            "      <p class=\"lead\">Contact Information</p>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <span ng-include=\"'areas/no_tab/accreditation/pages/accreditation/templates/accreditation_fields/full_name.html'\"></span>\n" +
            "  <span ng-include=\"'areas/no_tab/accreditation/pages/accreditation/templates/accreditation_fields/phone.html'\"></span>\n" +
            "  <span ng-include=\"'areas/no_tab/accreditation/pages/accreditation/templates/accreditation_fields/organization_name.html'\"></span>\n" +
            "  <span ng-include=\"'areas/no_tab/accreditation/pages/accreditation/templates/accreditation_fields/organization_role.html'\"></span>\n" +
            "  <span ng-include=\"'areas/no_tab/accreditation/pages/accreditation/templates/accreditation_fields/email.html'\"></span>\n" +
            "  <span ng-include=\"'areas/no_tab/accreditation/pages/accreditation/templates/accreditation_fields/address.html'\"></span>");

        $templateCache.put("areas/no_tab/accreditation/pages/accreditation/templates/accreditation_sections/individual_contact_information.html", "<div class=\"row more-head-room\">\n" +
            "    <div class=\"col-md-12\">\n" +
            "      <p class=\"lead\">Contact Information</p>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <span ng-include=\"'areas/no_tab/accreditation/pages/accreditation/templates/accreditation_fields/full_name.html'\"></span>\n" +
            "  <span ng-include=\"'areas/no_tab/accreditation/pages/accreditation/templates/accreditation_fields/email.html'\"></span>\n" +
            "  <span ng-include=\"'areas/no_tab/accreditation/pages/accreditation/templates/accreditation_fields/phone.html'\"></span>\n" +
            "  <span ng-include=\"'areas/no_tab/accreditation/pages/accreditation/templates/accreditation_fields/address.html'\"></span>\n" +
            "  <span ng-include=\"'areas/no_tab/accreditation/pages/accreditation/templates/accreditation_fields/advisor.html'\"></span>");

        $templateCache.put("areas/no_tab/accreditation/pages/accreditation/templates/accreditation_sections/net_worth.html", "<div class=\"row\">\n" +
            "  <div class=\"col-md-12\">\n" +
            "    <p class=\"lead\">Net Worth & Investment Experience</p>\n" +
            "  </div>\n" +
            "</div>\n" +
            "<div class=\"row form-group\" ng-class=\"{'has-error': (accreditation_form.net_worth.$error.required && submitted) }\">\n" +
            "  <div class=\"col-md-3\">\n" +
            "    <div class=\"\" popover-info=\"\" popover-placement=\"bottom\" popover=\"accreditation_help/net_worth.html\"\n" +
            "    popover-trigger=\"mouseenter\" ng-mouseenter=\"\">\n" +
            "      <label class=\"control-label\">Net Worth *</label>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"col-md-4\">\n" +
            "    <select class=\"form-control input-sm\" validator=\"[requiredBlur]\" class=\"form-control input-sm\"\n" +
            "    validator=\"[requiredBlur]\" ng-model=\"user_accreditation.net_worth\" name=\"net_worth\"\n" +
            "    ng-options=\"currOption for currOption in netWorthOptions\" required>\n" +
            "      <option value=\"\">Select</option>\n" +
            "    </select>\n" +
            "  </div>\n" +
            "</div>\n" +
            "<div class=\"row form-group\" ng-class=\"{'has-error': (accreditation_form.net_worth_total_value.$error.required && submitted) }\">\n" +
            "  <div class=\"col-md-3\">\n" +
            "    <div popover-info=\"\" popover-placement=\"bottom\" popover=\"accreditation_help/investments.html\"\n" +
            "         popover-trigger=\"mouseenter\" ng-mouseenter=\"\">\n" +
            "      <label class=\"control-label\">Total Value of Investments *</label>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"col-md-4\">\n" +
            "    <select class=\"form-control input-sm\" validator=\"[requiredBlur]\" ng-model=\"user_accreditation.individual_investment_total_value\"\n" +
            "    name=\"net_worth_total_value\" ng-options=\"currOption for currOption in investmentValueOptions\" required>\n" +
            "      <option value=\"\">Select</option>\n" +
            "    </select>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/no_tab/accreditation/pages/accreditation/templates/accreditation_sections/qpc_questionnaire.html", "<div class=\"accreditation-container qpc-inset\">\n" +
            "  <div class=\"container-fluid\">\n" +
            "    <div class=\"row\">\n" +
            "      <span class=\"accreditation-section-with-logo\">\n" +
            "        <div class=\"accreditation-section row\">\n" +
            "          <div class=\"accreditation-section-confirmation col-md-1\" ng-class=\"{'has-error': (accreditation_form.advisor_confirmed.$error.required && submitted) }\">\n" +
            "            <input name=\"advisor_confirmed\" ng-model=\"user_accreditation.advisor_confirmed\" type=\"checkbox\" required/>\n" +
            "          </div>\n" +
            "          <div class=\"col-md-11\">\n" +
            "            <div>\n" +
            "              <span style=\"vertical-align: top;\" ng-class=\"{'error': (accreditation_form.advisor_confirmed.$error.required && submitted) }\">\n" +
            "                I confirm that I am a current client of\n" +
            "                  <span class=\"accreditation-advisor-name\" style=\"vertical-align: top;\">{{user_accreditation.advisor_name}}</span>. *\n" +
            "              </span>\n" +
            "            </div>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "        <div class=\"accreditation-section row\">\n" +
            "          <div class=\"col-md-1\"></div>\n" +
            "          <div class=\"col-md-11\">\n" +
            "            <div class=\"form-group\" ng-class=\"{'has-error': (accreditation_form.client_year.$error.required && submitted) }\">\n" +
            "              <span ng-class=\"{'error': (accreditation_form.client_year.$error.required && submitted) }\">I have been a client of this advisor since: *</span>\n" +
            "              <input class=\"accreditation-input-clientyear form-control\" name=\"client_year\" ng-model=\"user_accreditation.client_year\" validator=\"[requiredBlurNoMessage]\" type=\"text\" required/>\n" +
            "              <span ng-class=\"{'error': (accreditation_form.client_year.$error.required && submitted) }\"> *</span>\n" +
            "            </div>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "      </span>\n" +
            "      <span class=\"ria-firm-logo\">\n" +
            "        <img ng-src=\"{{user_accreditation.ria_logo}}\" />\n" +
            "      </span>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"accreditation-section row\" ng-class=\"{'has-error': (accreditation_form.qp_confirmed.$error.required && submitted) }\">\n" +
            "    <div class=\"accreditation-section-confirmation col-md-1\"><input name=\"qp_confirmed\" ng-model=\"user_accreditation.qp_confirmed\" type=\"checkbox\" required/></div>\n" +
            "    <div class=\"col-md-10\" ng-class=\"{'error': (accreditation_form.qp_confirmed.$error.required && submitted) }\">\n" +
            "      <div>I am a Qualified Purchaser (as defined under the Investment Company Act of 1940) and an Accredited Investor (as defined under Regulation D promulgated under the Securities Act of 1933). *</div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/no_tab/accreditation/pages/accreditation/templates/accreditation_sections/ria_questionnaire.html", "<div class=\"accreditation-container ria-inset\">\n" +
            "  <div class=\"accreditation-section row\">\n" +
            "    <div class=\"col-md-1\"></div>\n" +
            "    <div class=\"col-md-11\">\n" +
            "      <div class=\"form-group row\" ng-class=\"{'has-error': (accreditation_form.investor_first_name.$error.required && submitted) }\">\n" +
            "        <div class=\"col-md-3 accreditation-label\">\n" +
            "          <span ng-class=\"{'error': (accreditation_form.investor_first_name.$error.required && submitted) }\">First Name: *</span>\n" +
            "        </div>\n" +
            "        <div class=\"col-md-9\">\n" +
            "          <input  class=\"accreditation-input-advisorLegalName form-control\"\n" +
            "                  style=\"width:100%;\"\n" +
            "                  name=\"investor_first_name\"\n" +
            "                  type=\"text\"\n" +
            "                  ng-model=\"user_accreditation.investor_first_name\"\n" +
            "                  autocomplete='off'\n" +
            "                  required />\n" +
            "        </div>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"accreditation-section row\">\n" +
            "    <div class=\"col-md-1\"></div>\n" +
            "    <div class=\"col-md-11\">\n" +
            "      <div class=\"form-group row\" ng-class=\"{'has-error': (accreditation_form.investor_first_name.$error.required && submitted) }\">\n" +
            "        <div class=\"col-md-3 accreditation-label\">\n" +
            "          <span ng-class=\"{'error': (accreditation_form.investor_last_name.$error.required && submitted) }\">Last Name: *</span>\n" +
            "        </div>\n" +
            "        <div class=\"col-md-9\">\n" +
            "          <input  class=\"accreditation-input-advisorLegalName form-control\"\n" +
            "                  style=\"width:100%;\"\n" +
            "                  name=\"investor_last_name\"\n" +
            "                  type=\"text\"\n" +
            "                  ng-model=\"user_accreditation.investor_last_name\"\n" +
            "                  autocomplete='off'\n" +
            "                  required />\n" +
            "        </div>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"accreditation-section row\">\n" +
            "    <div class=\"col-md-1\"></div>\n" +
            "    <div class=\"col-md-11\">\n" +
            "      <div>\n" +
            "        <span ng-class=\"{'error': (accreditation_form.advisor_confirmed.$error.required && submitted) }\">\n" +
            "            Are you employed by a registered investment advisor with the Securities and Exchange Commission? *\n" +
            "        </span>\n" +
            "      </div>\n" +
            "      <span class=\"accreditation-section-confirmation accreditation-radio\"\n" +
            "         ng-class=\"{'error': (accreditation_form.advisor_confirmed.$error.required && submitted) }\">\n" +
            "        <input type=\"radio\" name=\"advisor_confirmed\" data-ng-model=\"user_accreditation.advisor_confirmed\" data-ng-value=\"true\" required>\n" +
            "          <span>Yes</span>\n" +
            "        </input>\n" +
            "        <input type=\"radio\" name=\"advisor_confirmed\" data-ng-model=\"user_accreditation.advisor_confirmed\" data-ng-value=\"false\" required>\n" +
            "          <span>No</span>\n" +
            "        </input>\n" +
            "      </span>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"accreditation-section row\">\n" +
            "    <div class=\"col-md-1\"></div>\n" +
            "    <div class=\"col-md-11\">\n" +
            "      <div class=\"form-group row\" ng-class=\"{'has-error': (accreditation_form.firm_name.$error.required && submitted) }\">\n" +
            "        <div class=\"col-md-3 accreditation-label\">\n" +
            "        <span ng-class=\"{'error': (accreditation_form.firm_name.$error.required && submitted) }\">Firm's Legal Name: *</span></div>\n" +
            "        <div class=\"col-md-9\" ng-hide=\"!user_accreditation.advisor_confirmed\">\n" +
            "          <input  class=\"accreditation-input-advisorLegalName form-control\"\n" +
            "                  ng-class=\"{'has-error': (!hideAutocompleteError())}\"\n" +
            "                  style=\"width:100%;\"\n" +
            "                  name=\"firm_name\"\n" +
            "                  validator=\"[requiredBlur]\"\n" +
            "                  type=\"text\"\n" +
            "                  ng-model=\"user_accreditation.firm_name\"\n" +
            "                  ng-blur=\"formBlur.firm_blur=true\"\n" +
            "                  autocomplete='off'\n" +
            "                  typeahead='firm.name for firm in riaAutocomplete($viewValue) | limitTo:5'\n" +
            "                  ng-model-options='{ debounce: 300 }'\n" +
            "                  typeahead-on-select='selectRecord($item)'\n" +
            "                  required />\n" +
            "          <div class=\"control-label error\" ng-hide=\"hideAutocompleteError()\">Please select from the available firm names.</div>\n" +
            "        </div>\n" +
            "        <div class=\"col-md-9\" ng-hide=\"user_accreditation.advisor_confirmed\">\n" +
            "          <input  class=\"accreditation-input-advisorLegalName form-control\"\n" +
            "                  style=\"width:100%;\"\n" +
            "                  name=\"firm_name\"\n" +
            "                  validator=\"[requiredBlur]\"\n" +
            "                  type=\"text\"\n" +
            "                  ng-model=\"user_accreditation.firm_name\"\n" +
            "                  required />\n" +
            "        </div>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"accreditation-section row\" ng-class=\"{'has-error': (accreditation_form.crd_number.$error.required && submitted) }\" ng-show=\"user_accreditation.advisor_confirmed\">\n" +
            "    <div class=\"col-md-1\"></div>\n" +
            "    <div class=\"col-md-11\">\n" +
            "      <div class=\"col-md-3 accreditation-label\">\n" +
            "        <span ng-class=\"{'error': (accreditation_form.crd_number.$error.required && submitted) }\">Firm's CRD Number*</span>\n" +
            "      </div>\n" +
            "      <div class=\"col-md-3\">\n" +
            "        <input  class=\"accreditation-input-crdnumber form-control\"\n" +
            "                name=\"crd_number\"\n" +
            "                ng-model=\"user_accreditation.crd_number\"\n" +
            "                validator=\"[requiredBlur]\"\n" +
            "                type=\"text\"\n" +
            "                ng-required=\"user_accreditation.advisor_confirmed\" />\n" +
            "      </div>\n" +
            "      <div class=\"col-md-4 accreditation-label\">\n" +
            "        <span class=\"small\">6 digit number provided by the SEC <br>\n" +
            "          <a href=\"http://brokercheck.finra.org/\" target=\"_blank\">FINRA BrokerCheck</a>\n" +
            "        </span>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"accreditation-section row\" ng-show=\"user_accreditation.advisor_confirmed\">\n" +
            "    <div class=\"col-md-1\"></div>\n" +
            "    <div class=\"col-md-11\">\n" +
            "      <div>\n" +
            "        <span>\n" +
            "            Are you a financial advisor?\n" +
            "        </span>\n" +
            "      </div>\n" +
            "      <span id=\"is-ria-option\" class=\"accreditation-section-confirmation accreditation-radio\">\n" +
            "        <input type=\"radio\" name=\"is_ria\" data-ng-model=\"user_accreditation.is_ria\" data-ng-value=\"true\">\n" +
            "          <span>Yes</span>\n" +
            "        </input>\n" +
            "        <input type=\"radio\" name=\"is_ria\" data-ng-model=\"user_accreditation.is_ria\" data-ng-value=\"false\">\n" +
            "          <span>No</span>\n" +
            "        </input>\n" +
            "      </span>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"accreditation-section row\" ng-class=\"{'has-error': (accreditation_form.advisor_crd_number.$error.required && submitted) }\" ng-show=\"user_accreditation.is_ria\">\n" +
            "    <div class=\"col-md-1\"></div>\n" +
            "    <div class=\"col-md-11\">\n" +
            "      <div class=\"col-md-3 accreditation-label\">\n" +
            "        <span ng-class=\"{'error': (accreditation_form.advisor_crd_number.$error.required && submitted) }\">{{ crdNumberType }}</span>\n" +
            "      </div>\n" +
            "      <div class=\"col-md-3\">\n" +
            "        <input class=\"accreditation-input-crdnumber form-control\"\n" +
            "               name=\"advisor_crd_number\"\n" +
            "               ng-model=\"user_accreditation.advisor_crd_number\"\n" +
            "               validator=\"[requiredBlur]\"\n" +
            "               type=\"text\"\n" +
            "               ng-required=\"requireRiaCrd()\" />\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"accreditation-section row\" ng-class=\"\">\n" +
            "    <div class=\"col-md-1\"></div>\n" +
            "    <div class=\"col-md-11\">\n" +
            "      <span ng-include=\"'areas/no_tab/accreditation/pages/accreditation/templates/accreditation_fields/phone.html'\"></span>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"accreditation-section row\">\n" +
            "    <div class=\"accreditation-section-confirmation col-md-1\"\n" +
            "         ng-class=\"{'error': (accreditation_form.firm_has_qp_clients.$error.required && submitted) }\">\n" +
            "      <input name=\"firm_has_qp_clients\" ng-model=\"user_accreditation.firm_has_qp_clients\" type=\"checkbox\" required />\n" +
            "    </div>\n" +
            "    <div class=\"col-md-11\">\n" +
            "      <span>\n" +
            "        <span>\n" +
            "            <span ng-class=\"{'error': (accreditation_form.firm_has_qp_clients.$error.required && submitted) }\">\n" +
            "                The firm has assets under management from clients who are both Qualified Purchasers (as defined under the\n" +
            "                Investment Company Act of 1940) and Accredited Investors (as defined under Regulation D promulgated under the\n" +
            "                Securities Act of 1933). *\n" +
            "            </span>\n" +
            "        </span>\n" +
            "      </span>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"accreditation-section row\" ng-hide=\"user_accreditation.advisor_confirmed\">\n" +
            "    <div class=\"col-md-1\"></div>\n" +
            "    <div class=\"col-md-11\">\n" +
            "      <div>\n" +
            "          <span ng-class=\"{'error': (accreditation_form.firm_confirm_us_location.$error.required && submitted) }\">\n" +
            "              Is the firm based in the USA? *\n" +
            "          </span>\n" +
            "      </div>\n" +
            "        <span class=\"accreditation-section-confirmation accreditation-radio\"\n" +
            "              ng-class=\"{'error': (accreditation_form.firm_confirm_us_location.$error.required && submitted) }\">\n" +
            "        <input  type=\"radio\"\n" +
            "                name=\"firm_confirm_us_location\"\n" +
            "                data-ng-model=\"user_accreditation.firm_confirm_us_location\"\n" +
            "                data-ng-value=\"true\"\n" +
            "                ng-required=\"!user_accreditation.advisor_confirmed\" >\n" +
            "          <span>Yes</span>\n" +
            "        </input>\n" +
            "        <input  type=\"radio\"\n" +
            "                name=\"firm_confirm_us_location\"\n" +
            "                data-ng-model=\"user_accreditation.firm_confirm_us_location\"\n" +
            "                data-ng-value=\"false\"\n" +
            "                ng-required=\"!user_accreditation.advisor_confirmed\">\n" +
            "          <span>No</span>\n" +
            "        </input>\n" +
            "      </span>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"accreditation-section row\" ng-show=\"user_accreditation.advisor_confirmed || user_accreditation.firm_confirm_us_location\">\n" +
            "    <div class=\"col-md-1\"></div>\n" +
            "    <div class=\"col-md-11\">\n" +
            "      <div class=\"col-md-3 accreditation-label\">\n" +
            "        <span ng-class=\"{'error': (accreditation_form.state.$error.required && submitted) }\">State of Business: *</span>\n" +
            "      </div>\n" +
            "      <div class=\"col-md-6\">\n" +
            "      <div class=\"form-group\" ng-class=\"{'has-error': (accreditation_form.state.$error.required && submitted) }\">\n" +
            "        <select class=\"form-control\"\n" +
            "                name=\"state\"\n" +
            "                ng-model=\"user_accreditation.state\"\n" +
            "                ng-options=\"state.abbreviation as state.abbreviation for state in usStates\"\n" +
            "                ng-change=\"setDefaultCountry()\"\n" +
            "                style=\"width:100px;\"\n" +
            "                ng-required=\"user_accreditation.advisor_confirmed || user_accreditation.firm_confirm_us_location\">\n" +
            "          <option value=\"\" disabled=\"disabled\">State</option>\n" +
            "        </select>\n" +
            "      </div></div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"accreditation-section row\" ng-hide=\"user_accreditation.advisor_confirmed || user_accreditation.firm_confirm_us_location\">\n" +
            "    <div class=\"col-md-1\"></div>\n" +
            "    <div class=\"col-md-11 form-inline\">\n" +
            "      <div class=\"col-md-3 accreditation-label\" ng-class=\"{'error': (accreditation_form.country.$error.required && submitted) }\">\n" +
            "        <span>Country: *</span>\n" +
            "      </div>\n" +
            "      <div class=\"form-group\" >\n" +
            "        <label for=\"country\" class=\"sr-only\"></label>\n" +
            "        <select class=\"input-m form-control wide-input\"\n" +
            "                name=\"country\"\n" +
            "                ng-model=\"user_accreditation.country\"\n" +
            "                ng-options=\"country.name as country.name for country in nonUSCountryList\"\n" +
            "                ng-required=\"!(user_accreditation.advisor_confirmed || user_accreditation.firm_confirm_us_location)\">\n" +
            "          <option value=\"\">Country</option>\n" +
            "       </select>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "\n" +
            "</div>");

        $templateCache.put("areas/no_tab/accreditation/pages/accreditation/templates/accreditation_sections/section_seperator.html", "<div class=\"row more-head-room more-foot-room\">\n" +
            "  <div class=\"col-md-12\">\n" +
            "    <hr style=\"width:100%\">\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/no_tab/accreditation/pages/accreditation/templates/accreditation_sections/signature.html", "<div class=\"row\">\n" +
            "  <div class=\"col-md-12\">\n" +
            "    <p class=\"lead\">Electronic Signature</p>\n" +
            "\n" +
            "    <div class=\"more-head-room\">\n" +
            "      I understand that iCapital Network will be relying on the veracity and completeness\n" +
            "      of my response to the foregoing questions and I represent, warrant, and covenant\n" +
            "      to iCapital Network as follows:\n" +
            "        <div class=\"accreditation-signature-inset\">\n" +
            "          <div ng-show=\"accreditation_type != 'ria'\">\n" +
            "            <p>(i) The answers to the previous questions are complete and correct and may\n" +
            "            be relied upon by iCapital Network; and</p>\n" +
            "            <p>(ii) I will notify iCapital Network immediately and in writing of any change\n" +
            "            in any statement made in the information set forth in this\n" +
            "              {{signatureTitle()}} Onboarding Questionnaire.</p>\n" +
            "          </div>\n" +
            "          <div ng-show=\"accreditation_type == 'ria'\">\n" +
            "            <p>(i) I confirm that I will only discuss a private fund with, or provide marketing materials, offering memorandum, participation agreements, etc., related to a Fund to, prospective investors who are Qualified Purchasers and Accredited Investors or current investors in the Fund;</p>\n" +
            "            <p>(ii) I confirm that I am not subject to an order issued by the United States or by the SEC under Section 203(f) of the Advisers Act, have not been convicted within the previous ten years of \n" +
            "                any felony or misdemeanor which involved conduct described in Section 203(e)(2)(A) through (D) of the Advisers Act, have not been found by the SEC to have engaged, or have been convicted of engaging, in any of the conduct specified \n" +
            "                in paragraphs (1), (5) or (6) of Section 203(e) of the Advisers Act and I am not subject to any order, judgment or decree described in Section 203(e)(5) of the Advisers Act.</p>\n" +
            "            <p>(iii) The answers to the previous questions are complete and correct and may be relied upon by iCapital Network;</p>\n" +
            "            <p>(iv) I will notify iCapital Network immediately and in writing of any change in any statement made in the information set forth in this Onboarding Questionnaire.</p>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</div>\n" +
            "<div class=\"row more-head-room more-foot-room\">\n" +
            "  <div class=\"col-md-8 form-group\" ng-class=\"{'error': (accreditation_form.signature.$error.required && submitted) }\">\n" +
            "    <b>Enter Your Full Legal Name: *</b>\n" +
            "      <input class=\"form-control\" required type=\"text\" placeholder=\"Your full legal name\" ng-model=\"user_accreditation.signature\"\n" +
            "      name=\"signature\" style=\"width:200px;\" required validator=\"[requiredBlur]\"/>\n" +
            "  </div>\n" +
            "  <div class=\"col-md-8 more-head-room\">\n" +
            "      <strong>Dated:</strong> {{date | date:'longDate'}}\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/no_tab/accreditation/pages/accreditation/templates/accreditation_sections/wait_for_contact.html", "<div class=\"questionaireCompleted row\">\n" +
            "  <div class=\"questionaireCompleted-title\">\n" +
            "	 <h3>Thank you for completing the questionnaire</h3>\n" +
            "  </div>\n" +
            "	<div class=\"questionaireCompleted-body\">\n" +
            "    An iCapital Representative will contact you shortly\n" +
            "  </div>  \n" +
            "</div>");

        $templateCache.put("areas/no_tab/accreditation/pages/wlp_accreditation/accreditation.html", "<form id=\"accreditation-form\" name=\"accreditation_form\" ng-submit=\"submitAccreditations()\" ng-hide=\"show_thank_you\">\n" +
            "  <span ng-bind-html=\"onboardingTerms\"></span>\n" +
            "  <div class=\"row more-head-room more-foot-room\">\n" +
            "    <div class=\"col-md-8 form-group\">\n" +
            "      <b>Enter Your Full Legal Name: *</b>\n" +
            "      <input class=\"form-control\" required type=\"text\" placeholder=\"Your full legal name\" ng-model=\"user_accreditation.signature\"\n" +
            "             name=\"signature\" style=\"width:200px;\" required validator=\"[requiredBlur]\"/>\n" +
            "    </div>\n" +
            "    <div class=\"col-md-8 more-head-room\">\n" +
            "      <strong>Dated:</strong> {{date | date:'longDate'}}\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"row more-foot-room\">\n" +
            "    <div class=\"col-md-4\">\n" +
            "      <button\n" +
            "          ng-disabled=\"!accreditation_form.$valid\">\n" +
            "        Verify and Create Account\n" +
            "      </button>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</form>\n" +
            "<div ng-show='show_thank_you'>\n" +
            "    <div ng-include='formTemplates.thank_you'></div>\n" +
            "</div>");

        $templateCache.put("areas/no_tab/accreditation/templates/thank_you.html", "<div class=\"questionaireCompleted row\">\n" +
            "    <span ng-bind-html=\"thankYouText\"></span>\n" +
            "</div>");

        $templateCache.put("areas/no_tab/components/investor_profile_top_nav/_top_nav.html", "<div class=\"\" ng-class=\"{'for-new-issue': showNewIssues, 'without-new-issue-issue': !showNewIssues}\">\n" +
            "  <div class=\"pafFundTopNav-container-step\">\n" +
            "    <div class=\"pafStepMarker\" style=\"text-align:center;\">\n" +
            "      <div class=\"pafSummary\">\n" +
            "        <span ng-class=\"{pafStepTextSelected: currentStep == 1, pafStepText: currentStep != 1}\">GENERAL<br>INFORMATION</span>\n" +
            "      </div>\n" +
            "      <div class=\"pafCont\">\n" +
            "        <div class=\"pafCompletedCircle\">\n" +
            "          <div class=\"pafInnerCircle\" ng-if=\"currentStep == 1\" ></div>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"pafFundTopNav-container-step\">\n" +
            "    <div class=\"pafStepMarker\">\n" +
            "      <div class=\"rectContainer\">\n" +
            "        <div class=\"pafCompletedRect\"  ng-if=\"currentStep >= 2\"></div>\n" +
            "        <div class=\"pafIncompleteRect\"  ng-if=\"currentStep < 2\"></div>\n" +
            "      </div>\n" +
            "      <div class=\"pafCont\">\n" +
            "        <div class=\"pafSummary\" style=\"margin-left: -15px;\">\n" +
            "          <span ng-class=\"{pafStepTextSelected: currentStep == 2, pafStepText: currentStep != 2}\">STATUS &<br>EXPERIENCE</span>\n" +
            "        </div>\n" +
            "        <div ng-class=\"{pafCurrentCircle: currentStep == 2}\" ng-if=\"currentStep == 2\">\n" +
            "          <div class=\"pafInnerCircle\"></div>\n" +
            "        </div>\n" +
            "        <div class=\"pafIncompleteCircle\" ng-if=\"currentStep < 2\"></div>\n" +
            "        <div class=\"pafCompletedCircle\" ng-if=\"currentStep > 2\"></div>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"pafFundTopNav-container-step\">\n" +
            "    <div class=\"pafStepMarker\">\n" +
            "      <div class=\"rectContainer\">\n" +
            "        <div class=\"pafCompletedRectFi\"  ng-if=\"currentStep >= 3\"></div>\n" +
            "        <div class=\"pafIncompleteRectFi\"  ng-if=\"currentStep < 3\"></div>\n" +
            "      </div>\n" +
            "      <div class=\"pafCont\">\n" +
            "        <div class=\"pafSummary\" style=\"margin-left: -15px;\">\n" +
            "          <span ng-class=\"{pafStepTextSelected: currentStep == 3, pafStepText: currentStep != 3}\">DOCUMENTS</span>\n" +
            "        </div>\n" +
            "        <div ng-class=\"{pafCurrentCircle: currentStep == 3}\" ng-if=\"currentStep == 3 && !showCompleteCircle\">\n" +
            "          <div class=\"pafInnerCircle\" ></div>\n" +
            "        </div>\n" +
            "        <div class=\"pafIncompleteCircle\" ng-if=\"currentStep < 3\"></div>\n" +
            "        <div class=\"pafCompletedCircle\" ng-if=\"currentStep > 3 || (currentStep == 3 && showCompleteCircle)\"></div>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"pafFundTopNav-container-step\" ng-show=\"showNewIssues\">\n" +
            "    <div class=\"pafStepMarker\">\n" +
            "      <div class=\"rectContainer\">\n" +
            "        <div class=\"pafCompletedRectFi\"  ng-if=\"currentStep > 3\"></div>\n" +
            "        <div class=\"pafIncompleteRectFi\"  ng-if=\"currentStep < 4\"></div>\n" +
            "      </div>\n" +
            "      <div class=\"pafCont\">\n" +
            "        <div class=\"pafSummary\" style=\"margin-left: -15px;\">\n" +
            "          <span ng-class=\"{pafStepTextSelected: currentStep == 4, pafStepText: currentStep != 4}\">NEW ISSUES</span>\n" +
            "        </div>\n" +
            "        <div ng-class=\"{pafCurrentCircle: currentStep == 4}\" ng-if=\"currentStep == 4 && !showCompleteCircle\">\n" +
            "          <div class=\"pafInnerCircle\" ></div>\n" +
            "        </div>\n" +
            "        <div class=\"pafIncompleteCircle\" ng-if=\"currentStep < 4\"></div>\n" +
            "        <div class=\"pafCompletedCircle\" ng-if=\"currentStep > 4 || (currentStep == 4 && showCompleteCircle)\"></div>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"contact-info\" ng-show=\"showContactInfo\">\n" +
            "    <p>Questions? Please contact Investor Relations at {{phoneNumber}} or <a href=\"mailto:{{emailAddress}}\">{{emailAddress}}</a>.</p>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/no_tab/investor_profile/components/advisor_information/advisor_information.html", "<div class=\"investorProfile-section-header\"\n" +
            "     ng-if=\"!isHiddenField('section_b_ria_access')\">\n" +
            "    Financial Advisor Information\n" +
            "</div>\n" +
            "\n" +
            "<div class=\"investorProfile-description\">\n" +
            "    <div class=\"row\" ng-if=\"!isHiddenField('section_b_ria_access')\">\n" +
            "        <input type=\"radio\" name=\"section_b_ria_access\" id=\"allow_ria_access\"\n" +
            "               ng-model=\"investorProfile.section_b_ria_access\"\n" +
            "               ng-disabled=\"isReadOnlyField('section_b_ria_access')\"\n" +
            "               value=\"0\"\n" +
            "               class=\"investorProfile-section-radio\">\n" +
            "        <span>Please provide my Financial Advisor with access to all correspondence from the Fund</span>\n" +
            "        </input>\n" +
            "        <br/><br/>\n" +
            "    </div>\n" +
            "    <div ng-if=\"investorProfile.section_b_ria_access == '0' && !isHiddenField('section_b_ria_access')\">\n" +
            "        <div class=\"row form-group\">\n" +
            "            <div class=\"col-md-5\">\n" +
            "                <label class=\"investorProfile-section-label\">Name of Firm:</label>\n" +
            "            </div>\n" +
            "            <div class=\"col-md-5 form-inline\">\n" +
            "                <input type=\"text\" name=\"section_b_ria_firm\"\n" +
            "                       ng-model=\"investorProfile.section_b_ria_firm\"\n" +
            "                       ng-disabled=\"isReadOnlyField('section_b_ria_firm')\"\n" +
            "                       class=\"form-control\"\n" +
            "                       ng-required=\"mustComplete && !checkFieldValidity(investorProfile.section_b_ria_firm, 'section_b_ria_firm', 'Name of Advisory Firm is required.')\" validator=\"{{requiredValidator}}\" ng-class=\"{submitted:submitted}\"/>\n" +
            "            </div>\n" +
            "        </div>\n" +
            "        <div class=\"row form-group\">\n" +
            "            <div class=\"col-md-5\">\n" +
            "                <label class=\"investorProfile-section-label\">Name of Financial Advisor:</label>\n" +
            "            </div>\n" +
            "            <div class=\"col-md-5 form-inline\">\n" +
            "                <input disable-field-validation=\"true\" type=\"text\" name=\"section_b_ria_name\"\n" +
            "                       ng-model=\"investorProfile.section_b_ria_name\"\n" +
            "                       ng-disabled=\"isReadOnlyField('section_b_ria_name')\"\n" +
            "                       class=\"form-control\" validator=\"{{requiredValidator}}\"\n" +
            "                       ng-required=\"mustComplete && !checkFieldValidity(investorProfile.section_b_ria_name, 'section_b_ria_name', 'Name of Financial Advisor is required.')\"\n" +
            "                       ng-class=\"{submitted:submitted}\"/>\n" +
            "            </div>\n" +
            "        </div>\n" +
            "        <div class=\"row form-group\">\n" +
            "            <div class=\"col-md-5\">\n" +
            "                <label class=\"investorProfile-section-label\">Contact Email:</label>\n" +
            "            </div>\n" +
            "            <div class=\"col-md-5 form-inline\">\n" +
            "                <input type=\"text\" name=\"section_b_ria_email\"\n" +
            "                       ng-model=\"investorProfile.section_b_ria_email\"\n" +
            "                       ng-disabled=\"isReadOnlyField('section_b_ria_email')\"\n" +
            "                       class=\"form-control\"\n" +
            "                       ng-required=\"mustComplete && !checkFieldValidity(investorProfile.section_b_ria_email, 'section_b_ria_email', 'Contact Email is required.')\" validator=\"[emailFormat]\" ng-class=\"{submitted:submitted}\"/>\n" +
            "            </div>\n" +
            "        </div>\n" +
            "        <div class=\"row form-group\" ng-if=\"!isHiddenField('section_b_email_only_ria')\">\n" +
            "          <div class=\"col-md-12 section-b-email-only-ria\">\n" +
            "            <input type=\"checkbox\" name=\"section_b_email_only_ria\"\n" +
            "                   ng-model=\"investorProfile.section_b_email_only_ria\"\n" +
            "                   ng-disabled=\"isReadOnlyField('section_b_email_only_ria')\"\n" +
            "                   ng-true-value=\"'1'\"\n" +
            "                   ng-false-value=\"'0'\">\n" +
            "            <span>To the extent possible, please send all correspondence from the Fund exclusively to the Financial Advisor listed above.  Please note that certain correspondence will still be sent to the Investor as required by law.</span>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "    </div>\n" +
            "    <div class=\"row\" ng-if=\"!isHiddenField('section_b_ria_access')\">\n" +
            "        <input type=\"radio\" name=\"section_b_ria_access\"\n" +
            "               ng-model=\"investorProfile.section_b_ria_access\"\n" +
            "               ng-disabled=\"isReadOnlyField('section_b_ria_access')\"\n" +
            "               ng-hide=\"isHiddenField('section_b_ria_access')\"\n" +
            "               value=\"1\"\n" +
            "               class=\"investorProfile-section-radio\">\n" +
            "        <span> I either (i) do not have a Financial Advisor or (ii) do not want my Financial Advisor copied on correspondence from the Fund</span></input>\n" +
            "    </div>\n" +
            "</div>");

        $templateCache.put("areas/no_tab/investor_profile/components/interested_parties/interested_parties.html", "<div class=\"investorProfile-description\"\n" +
            "     ng-class=\"{submitted: submitted, 'ng-invalid-required':!validInterestedParties()}\"\n" +
            "     ng-if=\"!isHiddenField('section_c_copy_interested_parties')\">\n" +
            "  <div class=\"row\">\n" +
            "    Are there additional individuals, other than your Financial Advisor listed above, who you would like copied on correspondence from the Fund with respect to your investment?<br/><br/>\n" +
            "  </div>\n" +
            "  <div class=\"row\">\n" +
            "    <input type=\"radio\" name=\"show_interested_parties\"\n" +
            "           ng-model=\"investorProfile.section_c_copy_interested_parties\"\n" +
            "           ng-disabled=\"isReadOnlyField('section_c_copy_interested_parties')\"\n" +
            "           class=\"investorProfile-section-radio\"\n" +
            "           value=\"1\"\n" +
            "           ng-click=\"initInterestedParties()\">\n" +
            "    <span>Yes</span>\n" +
            "    </input>\n" +
            "    <br/><br/>\n" +
            "  </div>\n" +
            "\n" +
            "  <div ng-if=\"showInterestedParties == true\">\n" +
            "    <span ng-repeat=\"party in investorProfile.interested_parties track by $index\">\n" +
            "      <investor-profile-interested-party interested-party-num=\"$index + 1\"\n" +
            "                                         party=\"party\"\n" +
            "                                         investor-profile=\"investorProfile\"\n" +
            "                                         is-read-only-field=\"isReadOnlyField\"\n" +
            "                                         is-hidden-field=\"isHiddenField\"\n" +
            "                                         submitted=\"submitted\">\n" +
            "      </investor-profile-interested-party>\n" +
            "    </span>\n" +
            "  </div>\n" +
            "  <div class=\"row\">\n" +
            "    <input type=\"radio\" name=\"show_interested_parties\"\n" +
            "           value=\"0\"\n" +
            "           class=\"investorProfile-section-radio\"\n" +
            "           ng-model=\"investorProfile.section_c_copy_interested_parties\"\n" +
            "           ng-disabled=\"isReadOnlyField('section_c_copy_interested_parties')\"\n" +
            "           ng-click=\"deleteAllInterestedParties()\">\n" +
            "    <span>No</span>\n" +
            "    </input>\n" +
            "    <br>\n" +
            "    <label class=\"control-label error\" style=\"padding-top: 20px;\" ng-if=\"!validInterestedParties() && submitted\">This selection is required.</label>\n" +
            "  </div>\n" +
            "\n" +
            "</div>");

        $templateCache.put("areas/no_tab/investor_profile/components/interested_parties/interested_party.html", "<div class=\"interested-party-section\" ng-attr-id=\"{{ 'interested-party-' + interestedPartyNum }}\">\n" +
            "  <div class=\"clearfix\"></div>\n" +
            "  <div class=\"row form-group\">\n" +
            "    <div class=\"col-md-5\">\n" +
            "      <label class=\"investorProfile-section-label\">Name:</label>\n" +
            "    </div>\n" +
            "    <div class=\"col-md-5 form-inline\">\n" +
            "      <input type=\"text\"\n" +
            "             ng-model=\"party.name\"\n" +
            "             ng-disabled=\"isReadOnlyField(fieldName('name'))\"\n" +
            "             ng-class=\"{submitted:submitted}\"\n" +
            "             validator=\"{{requiredValidator}}\"\n" +
            "             ng-required=\"!isInvestorRelations\"\n" +
            "             name=\"{{fieldName('name')}}\"\n" +
            "             class=\"form-control\"/>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"row form-group\">\n" +
            "    <div class=\"col-md-5\">\n" +
            "      <label class=\"investorProfile-section-label\">Email Address:</label>\n" +
            "    </div>\n" +
            "    <div class=\"col-md-5 form-inline\">\n" +
            "      <input type=\"text\"\n" +
            "             ng-model=\"party.email\"\n" +
            "             ng-disabled=\"isReadOnlyField(fieldName('email'))\"\n" +
            "             validator=\"[emailFormat]\"\n" +
            "             ng-class=\"{submitted:submitted}\"\n" +
            "             class=\"form-control\"\n" +
            "             name=\"{{fieldName('email')}}\"\n" +
            "             ng-required=\"!isPafAdmin\"/>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"row form-group\">\n" +
            "    <div class=\"col-md-5\">\n" +
            "      <label class=\"investorProfile-section-label\">Relationship to Investor:</label>\n" +
            "    </div>\n" +
            "    <div class=\"col-md-5 form-inline\">\n" +
            "      <input type=\"text\"\n" +
            "             ng-model=\"party.relationship_to_investor\"\n" +
            "             ng-disabled=\"isReadOnlyField(fieldName('relationship_to_investor'))\"\n" +
            "             ng-class=\"{submitted:submitted}\"\n" +
            "             ng-required=\"!isInvestorRelations\"\n" +
            "             validator=\"{{requiredValidator}}\"\n" +
            "             name=\"{{fieldName('relationship_to_investor')}}\"\n" +
            "             class=\"form-control\"/>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"row form-group\" ng-if=\"!isHiddenField(investorProfileFields['phone'])\">\n" +
            "    <div class=\"col-md-5\">\n" +
            "      <label class=\"investorProfile-section-label\">Telephone Number:</label>\n" +
            "    </div>\n" +
            "    <div class=\"col-md-5 form-inline\">\n" +
            "      <input type=\"text\"\n" +
            "             ui-mask=\"(999) 999-9999\"\n" +
            "             ng-model=\"party.phone\"\n" +
            "             ng-disabled=\"isReadOnlyField(fieldName('phone'))\"\n" +
            "             ng-class=\"{submitted:submitted}\"\n" +
            "             validator=\"{{phoneValidator}}\"\n" +
            "             class=\"form-control\"\n" +
            "             name=\"{{fieldName('phone')}}\"\n" +
            "             ng-required=\"!isInvestorRelations && !isReadOnlyField(investorProfileFields['phone'])\"/>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"inv-prof-horiz-line\">\n" +
            "    <a href=\"\" class=\"pull-left interested-party-add-another\" ng-click=\"addInterestedParty()\" ng-show=\"showAddAnother()\">\n" +
            "      Add another individual\n" +
            "    </a>\n" +
            "    <a href=\"\" class=\"pull-right interested-party-delete\" ng-click=\"deleteInterestedParty()\" ng-show=\"showDelete()\">\n" +
            "      Delete\n" +
            "    </a>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/no_tab/investor_profile/dialogs/investor_profile_save_dialog.html", "<common-modal modal-header=\"Profile Updates\">\n" +
            "  <div class=\"investor-profile-save\">\n" +
            "\n" +
            "    <p>Please select how the changes should be applied.</p>\n" +
            "\n" +
            "    <div class=\"options-profile\">\n" +
            "      <div class=\"row radio-options-profile\">\n" +
            "\n" +
            "        <div class=\"row\" id=\"current\">\n" +
            "          <input type=\"radio\" ng-model=\"profileType\" value=\"current\" ng-change=\"clearBatchUpdateOptions()\">\n" +
            "          Apply changes to this investment profile <span class=\"bold-italic\">only</span>.\n" +
            "        </div>\n" +
            "\n" +
            "        <div class=\"row\" id=\"other\">\n" +
            "          <input type=\"radio\" ng-model=\"profileType\" value=\"other\">\n" +
            "          Apply changes to other profiles.\n" +
            "        </div>\n" +
            "\n" +
            "        <div class=\"other-profiles-options\">\n" +
            "          <label class=\"live-profile\">\n" +
            "            <input class=\"text\" type=\"checkbox\" ng-model=\"batchUpdateOptions.live\" ng-disabled=\"profileType == 'current'\">\n" +
            "            Apply changes to the <span class=\"bold-italic\">related live profile</span> for the investor.\n" +
            "          </label>\n" +
            "\n" +
            "          <label class=\"frozen-profile\">\n" +
            "            <input type=\"checkbox\" ng-model=\"batchUpdateOptions.frozen\" ng-disabled=\"profileType == 'current'\">\n" +
            "            Apply changes to <span class=\"bold-italic\">all investment profiles</span> for the investor.\n" +
            "          </label>\n" +
            "        </div>\n" +
            "\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"row\">\n" +
            "    <button class=\"right-aligned-btn\" ng-click=\"sendUpdateOptions()\" ng-disabled=\"isDisabled(profileType)\">Done</button>\n" +
            "  </div>\n" +
            "\n" +
            "</common-modal>");

        $templateCache.put("areas/no_tab/new_issues_profile/new_issues_profile.html", "<investor-profile-top-nav inv-prof-id=\"invProfId\" investor-profile=\"investorProfile\" has-new-issues-step=\"true\"></investor-profile-top-nav>\n" +
            "\n" +
            "<div class=\"new-issues-profile-page\">\n" +
            "  <h2>Participating in New Issues</h2>\n" +
            "    The Fund may invest in “New Issues,” as defined in FINRA Rule 5130, and generally defined as an initial public offering (“IPO”)\n" +
            "    of any registered equity security.  To permit the Investor to participate in the profits and losses from such New Issues in\n" +
            "    compliance with FINRA Rules 5130 and 5131, the Investor has marked those statements below which apply to it and, if the Investor\n" +
            "    is acting as nominee for another person, which apply to such person for which the entity is acting as nominee.  Additionally,\n" +
            "    the Investor agrees to update the information below on an annual basis upon the reasonable request of the Fund.\n" +
            "\n" +
            "  <h3 class=\"green-header\">Please indicate below whether the Investor would like to participate in “new issues”:</h3>\n" +
            "  <form name=\"newIssues\">\n" +
            "    <div class=\"checkbox\">\n" +
            "      <label>\n" +
            "        <input type=\"checkbox\" name=\"noParticipation\"\n" +
            "               ng-disabled=\"!canManageInvestors()\"\n" +
            "               ng-checked=\"participation === false\"\n" +
            "               ng-click=\"setParticipation($event)\">\n" +
            "        The Investor elects not to participate in “new issues”.\n" +
            "      </label>\n" +
            "    </div>\n" +
            "    <div class=\"checkbox\">\n" +
            "      <label>\n" +
            "        <input type=\"checkbox\" name=\"participation\"\n" +
            "               ng-disabled=\"!canManageInvestors()\"\n" +
            "               ng-checked=\"participation === true\"\n" +
            "               ng-click=\"setParticipation($event)\">\n" +
            "        The Investor elects to participate in “new issues”.\n" +
            "      </label>\n" +
            "    </div>\n" +
            "    <div class=\"new-issues-questions-container\" ng-show=\"newIssuesProfile.choose_to_participate\">\n" +
            "      <div ng-include=\"'areas/no_tab/new_issues_profile/question1.html'\" ng-show=\"showQuestionOne()\"></div>\n" +
            "      <div ng-include=\"'areas/no_tab/new_issues_profile/question2.html'\" ng-show=\"showQuestionTwo()\"></div>\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"section-divider\"></div>\n" +
            "\n" +
            "    <investor-profile-prev-next\n" +
            "        user=\"user\"\n" +
            "        inv-prof-id=\"invProfId\"\n" +
            "        investor-profile=\"investorProfile\"\n" +
            "        save-form=\"save\"\n" +
            "        submit-disabled=\"submitDisabled\">\n" +
            "    </investor-profile-prev-next>\n" +
            "\n" +
            "  </form>\n" +
            "</div>");

        $templateCache.put("areas/no_tab/new_issues_profile/question1.html", "<div class=\"section-divider\"></div>\n" +
            "<h3 class=\"green-header\">Question 1: Are you any of the following:</h3>\n" +
            "\n" +
            "<div class=\"checkbox\">\n" +
            "  <label>\n" +
            "    <input type=\"checkbox\" class=\"question1\" name=\"is_broker_dealer_member\"\n" +
            "           ng-disabled=\"disableQuestion('question1')\"\n" +
            "           ng-model=\"newIssuesProfile.data.question1.is_broker_dealer_member\"\n" +
            "           ng-click=\"clearQuestion1Eligibility($event)\">\n" +
            "    <span class=\"main-bullet-text\"><span class=\"main-bullet\">A )</span>	Members or other Broker-Dealers.</span>\n" +
            "      Investor, or any person having a Beneficial Interest in Investor, is a member of FINRA or other broker-dealer.\n" +
            "  </label>\n" +
            "</div>\n" +
            "\n" +
            "<div class=\"checkbox\">\n" +
            "  <label>\n" +
            "    <input type=\"checkbox\" class=\"question1\" name=\"is_broker_dealer_personnel\"\n" +
            "           ng-disabled=\"disableQuestion('question1')\"\n" +
            "           ng-model=\"newIssuesProfile.data.question1.is_broker_dealer_personnel\"\n" +
            "           ng-click=\"toggleQuestion1B($event)\">\n" +
            "\n" +
            "    <span class=\"main-bullet-text\"><span class=\"main-bullet\">B )</span>	Broker-Dealer Personnel.</span>\n" +
            "      Investor, or any person having a Beneficial Interest in Investor, is:\n" +
            "    <ul>\n" +
            "      <li> i) an officer, director, general partner, Associated Person, employee, or agent of a FINRA member or any other broker-dealer</li>\n" +
            "      <li> ii) an Immediate Family Member of a person described in subparagraph (B)(i) if the person specified in subparagraph (B)(i):</li>\n" +
            "\n" +
            "      <div class=\"check-all\">Please check all that apply</div>\n" +
            "\n" +
            "      <ul>\n" +
            "        <li>\n" +
            "          <input type=\"checkbox\" class=\"question1\"\n" +
            "                ng-disabled=\"disableQuestion('question1')\"\n" +
            "                ng-model=\"newIssuesProfile.data.question1.has_broker_dealer_personnel_family_financial_support\"\n" +
            "                ng-click=\"toggleQuestion1BChildren($event)\">\n" +
            "          a) Materially Supports, or receives Material Support from, the Immediate Family Member;\n" +
            "        </li>\n" +
            "        <li>\n" +
            "          <input type=\"checkbox\" class=\"question1\"\n" +
            "                 ng-disabled=\"disableQuestion('question1')\"\n" +
            "                 ng-model=\"newIssuesProfile.data.question1.has_broker_dealer_personnel_family_broker_dealer_member\"\n" +
            "                 ng-click=\"toggleQuestion1BChildren($event)\">\n" +
            "          b) is employed by or is a Person Associated With a FINRA Member or Affiliate of a FINRA member; or\n" +
            "        </li>\n" +
            "        <li>\n" +
            "          <input type=\"checkbox\" class=\"question1\"\n" +
            "                 ng-disabled=\"disableQuestion('question1')\"\n" +
            "                 ng-model=\"newIssuesProfile.data.question1.has_broker_dealer_personnel_family_can_allocate_new_issues\"\n" +
            "                 ng-click=\"toggleQuestion1BChildren($event)\">\n" +
            "          c) has an ability to control the allocation of New Issues.\n" +
            "        </li>\n" +
            "      </ul>\n" +
            "    </ul>\n" +
            "  </label>\n" +
            "</div>\n" +
            "\n" +
            "<div class=\"checkbox\">\n" +
            "  <label>\n" +
            "    <input type=\"checkbox\" class=\"question1\" name=\"is_broker_dealer_member\"\n" +
            "           ng-disabled=\"disableQuestion('question1')\"\n" +
            "           ng-model=\"newIssuesProfile.data.question1.is_finder_or_fiduciary\"\n" +
            "           ng-click=\"clearQuestion1Eligibility($event)\">\n" +
            "\n" +
            "    <span class=\"main-bullet-text\"><span class=\"main-bullet\">C )</span>	Finders and Fiduciaries.</span>\n" +
            "      Investor, or any person having a Beneficial Interest in Investor, is:\n" +
            "    <ul>\n" +
            "      <li>i)	a finder or a person who is in the business of acting in a fiduciary capacity to a managing underwriter,\n" +
            "        including, but not limited to, attorneys, accountants and financial consultants; or\n" +
            "      </li>\n" +
            "      <li>ii)	an Immediate Family Member of a person specified in subparagraph (C)(i)</li>\n" +
            "    </ul>\n" +
            "  </label>\n" +
            "</div>\n" +
            "\n" +
            "\n" +
            "<div class=\"checkbox\">\n" +
            "  <label>\n" +
            "    <input type=\"checkbox\" class=\"question1\" name=\"is_broker_dealer_member\"\n" +
            "           ng-disabled=\"disableQuestion('question1')\"\n" +
            "           ng-model=\"newIssuesProfile.data.question1.is_portfolio_investment_manager\"\n" +
            "           ng-click=\"clearQuestion1Eligibility($event)\">\n" +
            "\n" +
            "    <span class=\"main-bullet-text\"><span class=\"main-bullet\">D )</span>  Investor, or any person having a Beneficial Interest in Investor:</span>\n" +
            "    <ul>\n" +
            "      <li>i)	has authority to buy or sell securities for a bank, savings and loan institution, insurance company, investment company,\n" +
            "        investment advisor, or Collective Investment Account; or</li>\n" +
            "      <li>ii) is an Immediate Family Member of a person specified in subparagraph (D)(i)</li>\n" +
            "    </ul>\n" +
            "  </label>\n" +
            "</div>\n" +
            "\n" +
            "<div class=\"checkbox\">\n" +
            "  <label>\n" +
            "    <input type=\"checkbox\" class=\"question1\" name=\"is_broker_dealer_member\"\n" +
            "           ng-disabled=\"disableQuestion('question1')\"\n" +
            "           ng-model=\"newIssuesProfile.data.question1.owns_broker_dealer\"\n" +
            "           ng-click=\"toggleQuestion1E($event)\">\n" +
            "\n" +
            "    <span class=\"main-bullet-text\"><span class=\"main-bullet\">E )</span> Persons Owning a Broker-Dealer.</span>\n" +
            "      Investor, or any person having a Beneficial Interest in Investor:\n" +
            "\n" +
            "    <div class=\"check-all\">Please check all that apply</div>\n" +
            "\n" +
            "\n" +
            "    <ul>\n" +
            "      <li>\n" +
            "        <input type=\"checkbox\" class=\"question1EChoices\"\n" +
            "               ng-disabled=\"disableQuestion('question1')\"\n" +
            "               ng-model=\"newIssuesProfile.data.question1.owns_broker_dealer_listed\"\n" +
            "               ng-click=\"toggleQuestion1EChildren($event)\">\n" +
            "        i)	is listed, or required to be listed, in Schedule A, Schedule B or Schedule C of a Form BD;</li>\n" +
            "      <li>\n" +
            "        <input type=\"checkbox\" class=\"question1EChoices\"\n" +
            "               ng-disabled=\"disableQuestion('question1')\"\n" +
            "               ng-model=\"newIssuesProfile.data.question1.owns_broker_dealer_owns_ten_percent_or_more\"\n" +
            "               ng-click=\"toggleQuestion1EChildren($event)\">\n" +
            "        ii)	directly or indirectly owns 10% or more of a public reporting company listed, or required to be listed, in Schedule A of a\n" +
            "        Form BD (other than a reporting company that is listed on a national securities exchange);</li>\n" +
            "      <li>\n" +
            "        <input type=\"checkbox\" class=\"question1EChoices\"\n" +
            "               ng-disabled=\"disableQuestion('question1')\"\n" +
            "               ng-model=\"newIssuesProfile.data.question1.owns_broker_dealer_owns_twenty_five_percent_or_more\"\n" +
            "               ng-click=\"toggleQuestion1EChildren($event)\">\n" +
            "        iii)	directly or indirectly owns 25% or more of a public reporting company listed, or required to be listed, in Schedule B of a Form BD\n" +
            "        (other than a reporting company that is listed on a national securities exchange);</li>\n" +
            "      <li>\n" +
            "        <input type=\"checkbox\" class=\"question1EChoices\"\n" +
            "               ng-disabled=\"disableQuestion('question1')\"\n" +
            "               ng-model=\"newIssuesProfile.data.question1.owns_broker_dealer_family_financial_support\"\n" +
            "               ng-click=\"toggleQuestion1EChildren($event)\">\n" +
            "        iv)	is an Immediate Family Member of a person specified in subparagraphs (E)(i) through (iii) unless the person owning the broker-dealer:</li>\n" +
            "      <ul>\n" +
            "        <li>a)	does not Materially Support, or receive Material Support from, the Immediate Family Member;</li>\n" +
            "        <li>b)	is not an owner of a FINRA member, or an Affiliate of a FINRA member; and</li>\n" +
            "        <li>c)	has no ability to control the allocation of New Issues.</li>\n" +
            "      </ul>\n" +
            "    </ul>\n" +
            "  </label>\n" +
            "</div>\n" +
            "\n" +
            "<div class=\"checkbox\">\n" +
            "  <label>\n" +
            "    <input type=\"checkbox\" class=\"question1\" name=\"is_broker_dealer_member\"\n" +
            "           ng-disabled=\"disableQuestion('question1')\"\n" +
            "           ng-model=\"newIssuesProfile.data.question1.is_executive_officer_of_select_companies\"\n" +
            "           ng-click=\"clearQuestion1Eligibility($event)\">\n" +
            "\n" +
            "    <span class=\"main-bullet-text\"><span class=\"main-bullet\">F )</span> Executive Officers or Directors of Certain Companies.</span>\n" +
            "      Subscriber, or any person having a Beneficial Interest in Subscriber, is\n" +
            "    <ul>\n" +
            "      <li>i) an executive officer or director of a Public Company or a Covered Non-Public Company, or </li>\n" +
            "      <li>ii) a person receiving Material Support from an executive officer or director of a Public Company or a Covered Non-Public Company.</li>\n" +
            "    </ul>\n" +
            "  </label>\n" +
            "</div>\n" +
            "\n" +
            "<div class=\"checkbox\">\n" +
            "  <label>\n" +
            "    <input type=\"checkbox\" class=\"noneQuestion1\" name=\"noneQuestion1\"\n" +
            "           ng-disabled=\"disableQuestion('question1')\"\n" +
            "           ng-model=\"newIssuesProfile.data.question1.none_applies\"\n" +
            "           ng-click=\"clearQuestion1Choices($event)\">\n" +
            "    <span class=\"main-bullet-text\"><span class=\"main-bullet\">G )</span>	None of the above statements apply.</span>\n" +
            "  </label>\n" +
            "</div>\n" +
            "\n" +
            "<div ng-show=\"question1Passed()\">\n" +
            "  <div class=\"section-divider\"></div>\n" +
            "  <div class=\"eligibility-notify-text\">\n" +
            "    You are eligible to participate in New Issues.  Please click the Submit Profile button below.\n" +
            "  </div>\n" +
            "</div>\n" +
            "\n" +
            "<div ng-show=\"anyFailingQuestion1Selected() && investorIsIndividual()\">\n" +
            "  <div class=\"section-divider\"></div>\n" +
            "  <div class=\"eligibility-notify-text\">\n" +
            "    You are not eligible to participate in New Issues based on the information provided.  Please click the Submit Profile button below.\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/no_tab/new_issues_profile/question2.html", "<div class=\"section-divider\"></div>\n" +
            "<h3 class=\"green-header\">Question 2.  An Investor may still be able to participate fully in New Issue investments if it indicates below that it is an Exempted Entity.\n" +
            "  Please indicate whether Investor is one or more of the following:</h3>\n" +
            "\n" +
            "<div class=\"check-all\">Please check all that apply</div>\n" +
            "\n" +
            "<div class=\"checkbox\">\n" +
            "  <label>\n" +
            "    <input type=\"checkbox\"\n" +
            "           ng-disabled=\"disableQuestion('question2')\"\n" +
            "           ng-model=\"newIssuesProfile.data.question2.is_company_registered_under_1940_act\"\n" +
            "           ng-click=\"clearQuestion2Eligibility($event)\">\n" +
            "    <span class=\"main-bullet-text\"><span class=\"main-bullet\">A )</span>	An investment company registered under the 1940 Act;</span>\n" +
            "  </label>\n" +
            "</div>\n" +
            "\n" +
            "<div class=\"checkbox\">\n" +
            "  <label>\n" +
            "    <input type=\"checkbox\"\n" +
            "           ng-disabled=\"disableQuestion('question2')\"\n" +
            "           ng-model=\"newIssuesProfile.data.question2.is_a_common_trust_fund\"\n" +
            "           ng-click=\"clearQuestion2Eligibility($event)\">\n" +
            "\n" +
            "    <span class=\"main-bullet-text\"><span class=\"main-bullet\">B )</span> A common trust fund or similar fund as\n" +
            "        described in Section 3(a)(12)(A)(iii) of the Exchange Act which:</span>\n" +
            "    <ul>\n" +
            "      <li>i) has investments from 1,000 or more accounts; and</li>\n" +
            "      <li>ii) does not limit beneficial interests in the fund principally to trust accounts of Restricted Persons;</li>\n" +
            "    </ul>\n" +
            "  </label>\n" +
            "</div>\n" +
            "\n" +
            "<div class=\"checkbox\">\n" +
            "  <label>\n" +
            "    <input type=\"checkbox\"\n" +
            "           ng-disabled=\"disableQuestion('question2')\"\n" +
            "           ng-model=\"newIssuesProfile.data.question2.is_insurance_company\"\n" +
            "           ng-click=\"clearQuestion2Eligibility($event)\">\n" +
            "\n" +
            "    <span class=\"main-bullet-text\"><span class=\"main-bullet\">C )</span> An insurance company general, separate or investment account which satisfies\n" +
            "      each of the following conditions:</span>\n" +
            "    <ul>\n" +
            "      <li>i) the account is funded by premiums from 1,000 or more policyholders, or, if a general account, the insurance company has 1,000 or more policyholders; and</li>\n" +
            "      <li>ii) the insurance company does not limit the policyholders whose premiums are used to fund the account principally to Restricted Persons, or, if a general\n" +
            "        account, the insurance company does not limit its policyholders principally to Restricted Persons;</li>\n" +
            "    </ul>\n" +
            "  </label>\n" +
            "</div>\n" +
            "\n" +
            "<div class=\"checkbox\">\n" +
            "  <label>\n" +
            "    <input type=\"checkbox\"\n" +
            "           ng-disabled=\"disableQuestion('question2')\"\n" +
            "           ng-model=\"newIssuesProfile.data.question2.is_non_restricted_account\"\n" +
            "           ng-click=\"clearQuestion2Eligibility($event)\">\n" +
            "    <span class=\"main-bullet-text\"><span class=\"main-bullet\">D )</span>	An account (such as a collective investment account or vehicle or other conduit)\n" +
            "      the Beneficial Interests of which are not owned exclusively by Restricted Persons;</span>\n" +
            "  </label>\n" +
            "</div>\n" +
            "\n" +
            "<div class=\"checkbox\">\n" +
            "  <label>\n" +
            "    <input type=\"checkbox\" class=\"question1\" name=\"is_a_publicly_traded_entity\"\n" +
            "           ng-disabled=\"disableQuestion('question2')\"\n" +
            "           ng-model=\"newIssuesProfile.data.question2.is_a_publicly_traded_entity\"\n" +
            "           ng-click=\"clearQuestion2Eligibility($event)\">\n" +
            "\n" +
            "    <span class=\"main-bullet-text\"><span class=\"main-bullet\">E )</span>	A publicly traded entity (other than a broker-dealer or an Affiliate of a broker-dealer\n" +
            "      where such broker-dealer is authorized to engage in the public offering of\n" +
            "      New Issues either as a selling group member or underwriter) that:</span>\n" +
            "    <ul>\n" +
            "      <li>i) is listed on a national securities exchange; or</li>\n" +
            "      <li>ii) is a foreign issuer whose securities meet the quantitative designation criteria for listing on a national securities exchange;</li>\n" +
            "    </ul>\n" +
            "  </label>\n" +
            "</div>\n" +
            "\n" +
            "<div class=\"checkbox\">\n" +
            "  <label>\n" +
            "    <input type=\"checkbox\"\n" +
            "           ng-disabled=\"disableQuestion('question2')\"\n" +
            "           ng-model=\"newIssuesProfile.data.question2.is_a_foreign_investment_company\"\n" +
            "           ng-click=\"clearQuestion2Eligibility($event)\">\n" +
            "\n" +
            "    <span class=\"main-bullet-text\"><span class=\"main-bullet\">F )</span> An investment company organized under the laws of a foreign jurisdiction which\n" +
            "        satisfies each of the following conditions:</span>\n" +
            "    <ul>\n" +
            "      <li>i) the investment company is listed on a foreign exchange for sale to the public or authorized for sale to the public by a foreign regulatory authority; and</li>\n" +
            "      <li>ii) no person owning more than 5% of the shares of the investment company is a Restricted Person;</li>\n" +
            "    </ul>\n" +
            "  </label>\n" +
            "</div>\n" +
            "\n" +
            "<div class=\"checkbox\">\n" +
            "  <label>\n" +
            "    <input type=\"checkbox\"\n" +
            "           ng-disabled=\"disableQuestion('question2')\"\n" +
            "           ng-model=\"newIssuesProfile.data.question2.is_an_erisa_benefits_plan\"\n" +
            "           ng-click=\"clearQuestion2Eligibility($event)\">\n" +
            "    <span class=\"main-bullet-text\"><span class=\"main-bullet\">G )</span>	An ERISA benefits plan that is qualified under Section 401(a) of the Code and is not\n" +
            "      sponsored solely by a broker-dealer;</div>\n" +
            "  </label>\n" +
            "</div>\n" +
            "\n" +
            "<div class=\"checkbox\">\n" +
            "  <label>\n" +
            "    <input type=\"checkbox\"\n" +
            "           ng-disabled=\"disableQuestion('question2')\"\n" +
            "           ng-model=\"newIssuesProfile.data.question2.is_a_government_benefits_plan\"\n" +
            "           ng-click=\"clearQuestion2Eligibility($event)\">\n" +
            "    <span class=\"main-bullet-text\"><span class=\"main-bullet\">H )</span> 	A state or municipal government benefits plan that is subject to state\n" +
            "      and/or municipal regulation;</span>\n" +
            "  </label>\n" +
            "</div>\n" +
            "\n" +
            "<div class=\"checkbox\">\n" +
            "  <label>\n" +
            "    <input type=\"checkbox\"\n" +
            "           ng-disabled=\"disableQuestion('question2')\"\n" +
            "           ng-model=\"newIssuesProfile.data.question2.is_a_tax_exempt_charitable_org\"\n" +
            "           ng-click=\"clearQuestion2Eligibility($event)\">\n" +
            "    <span class=\"main-bullet-text\"><span class=\"main-bullet\">I )</span> 	A tax exempt charitable organization under Section 501(c)(3) of the Code; or</span>\n" +
            "  </label>\n" +
            "</div>\n" +
            "\n" +
            "<div class=\"checkbox\">\n" +
            "  <label>\n" +
            "    <input type=\"checkbox\"\n" +
            "           ng-disabled=\"disableQuestion('question2')\"\n" +
            "           ng-model=\"newIssuesProfile.data.question2.is_a_church_plan\"\n" +
            "           ng-click=\"clearQuestion2Eligibility($event)\">\n" +
            "    <span class=\"main-bullet-text\"><span class=\"main-bullet\">J )</span> 	A church plan under Section 414(e) of the Code.</span>\n" +
            "  </label>\n" +
            "</div>\n" +
            "\n" +
            "<div class=\"checkbox\">\n" +
            "  <label>\n" +
            "    <input type=\"checkbox\" class=\"question2\"\n" +
            "           ng-disabled=\"disableQuestion('question2')\"\n" +
            "           ng-model=\"newIssuesProfile.data.question2.none_applies\"\n" +
            "           ng-click=\"clearQuestion2Choices($event)\">\n" +
            "    <span class=\"main-bullet-text\"><span class=\"main-bullet\">K )</span> None of the above statements apply.</span>\n" +
            "  </label>\n" +
            "</div>\n" +
            "\n" +
            "<div ng-show=\"question2Passed()\">\n" +
            "  <div class=\"section-divider\"></div>\n" +
            "  <div class=\"eligibility-notify-text\">\n" +
            "    You are eligible to participate in New Issues.  Please click the Submit Profile button below.\n" +
            "  </div>\n" +
            "</div>\n" +
            "\n" +
            "<div ng-show=\"anyFailingQuestion2Selected()\">\n" +
            "  <div class=\"section-divider\"></div>\n" +
            "  <div class=\"eligibility-notify-text\">\n" +
            "    You are not eligible to participate in New Issues based on the information provided.  Please click the Submit Profile button below.\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/paf_admin/components/fund_documents.html", "<h2>\n" +
            "  Fund Documents\n" +
            "  <select ng-model=\"documentSection\" name=\"document-section\">\n" +
            "    <option value=\"\">------</option>\n" +
            "    <option value=\"quarterlyReports\">Quarterly Reports</option>\n" +
            "    <option value=\"uploadDocuments\">Upload Documents</option>\n" +
            "    <option value=\"financialStatements\">Financial Statements</option>\n" +
            "  </select>\n" +
            "</h2>\n" +
            "<table class=\"icn-table\">\n" +
            "  <tr ng-show=\"documentSection == 'quarterlyReports'\">\n" +
            "    <td>Quarterly Reports</td>\n" +
            "    <td>\n" +
            "      <private-access-fund-quarterly-reports fund=\"vm.fund\"></private-access-fund-quarterly-reports>\n" +
            "    </td>\n" +
            "  </tr>\n" +
            "  <tr ng-show=\"documentSection == 'uploadDocuments'\">\n" +
            "    <td>Upload Document</td>\n" +
            "    <td>\n" +
            "      <private-access-fund-admin-upload-document fund=\"vm.fund\"></private-access-fund-admin-upload-document>\n" +
            "    </td>\n" +
            "  </tr>\n" +
            "  <tr ng-show=\"documentSection == 'financialStatements'\">\n" +
            "    <td>Financial Statements</td>\n" +
            "    <td>\n" +
            "      <private-access-fund-financial-document fund=\"vm.fund\"></private-access-fund-financial-document>\n" +
            "    </td>\n" +
            "  </tr>\n" +
            "  </tbody>\n" +
            "</table>");

        $templateCache.put("areas/paf_admin/components/investor_cell/investor_cell.html", "<div>\n" +
            "  {{ lastName }}, {{ firstName }}   <br />\n" +
            "  <div style=\"font-size:smaller\">\n" +
            "    Investment Id : {{ investmentId }}\n" +
            "    <br>\n" +
            "    <div ng-if=\"seriesName\">\n" +
            "      <span>Series: {{ seriesName }}</span>\n" +
            "      <br>\n" +
            "      <span>Fund Allocations:</span> <br>\n" +
            "      <span ng-repeat=\"allocation in allocations\">\n" +
            "        {{ allocation.fund_name }}: {{ allocation.amount | currency}}<br>\n" +
            "      </span>\n" +
            "    </div>\n" +
            "    <br>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/paf_admin/components/nav/nav.html", "<div class=\"vertical-margin paf-admin-nav\" ng-class=\"{ 'paf-admin-nav--investor-relations' : type == 'investor_relations' }\">\n" +
            "    <div class=\"row\" ng-show=\"type == 'hf' || type == 'pe'\">\n" +
            "        <div class=\"col-md-4\" ng-show=\"adminPE && adminHedge\" id=\"fundTypeSelector\">\n" +
            "            <select class=\"form-control\" ng-model=\"type\">\n" +
            "                <option value=\"pe\">PRIVATE EQUITY</option>\n" +
            "                <option value=\"hf\">HEDGE FUND</option>\n" +
            "            </select>\n" +
            "        </div>\n" +
            "        <div class=\"col-md-12\" ng-show=\"!(adminPE && adminHedge)\">\n" +
            "            <h2>{{navHeader}}</h2>\n" +
            "        </div>\n" +
            "    </div>\n" +
            "    <div class=\"row\">\n" +
            "        <div class=\"col-md-12\">\n" +
            "            <ul class=\"nav nav-tabs\" role=\"tablist\">\n" +
            "                <li ng-repeat=\"item in navItems\" ng-class=\"{active: item.active}\">\n" +
            "                    <a href=\"{{item.path}}\" target=\"{{item.target}}\">{{item.label}}</a>\n" +
            "                </li>\n" +
            "            </ul>\n" +
            "        </div>\n" +
            "    </div>\n" +
            "</div>");

        $templateCache.put("areas/paf_admin/components/notification/notification.html", "<div class=\"row vertical-margin\">\n" +
            "  <div class=\"col-md-12 no-column-padding\">\n" +
            "    <div class=\"alert alert-{{message.type}}\" ng-click=\"dismissError()\" ng-show=\"message\">\n" +
            "      <span ng-show=\"message.text\" ng-bind-html=\"message.text\">{{message.text}}</span>\n" +
            "      <ul ng-show=\"message.errors\">\n" +
            "        <li ng-repeat=\"error in message.errors\">\n" +
            "          <span ng-show=\"error.value\"> {{ error.value }}</span>\n" +
            "          <span ng-show=\"error.type\"> {{ error.type }}</span>\n" +
            "          <span ng-show=\"!error.value\">{{ error }}</span>\n" +
            "        </li>\n" +
            "      </ul>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/paf_admin/dialogs/bny/refresh_funds.html", "<common-modal modal-header=\"{{header}}\">\n" +
            "  <div class=\"row form-group\">\n" +
            "    <label class=\"control-label no-padding\" for=\"pafs\">Funds:</label>\n" +
            "    <ui-select\n" +
            "      multiple\n" +
            "      ng-model=\"fields.pafIds\"\n" +
            "      theme=\"bootstrap\"\n" +
            "      sortable=\"true\"\n" +
            "      close-on-select=\"false\"\n" +
            "      name=\"pafs\">\n" +
            "      <ui-select-match placeholder=\"Select private access fund...\">\n" +
            "        {{$item.name}}\n" +
            "      </ui-select-match>\n" +
            "      <ui-select-choices\n" +
            "        repeat=\"paf.id as paf in pafs | orderBy: 'name' | propsFilter: {name: $select.search}\">\n" +
            "        <div ng-bind-html=\"paf.name | highlight: $select.search\"></div>\n" +
            "      </ui-select-choices>\n" +
            "    </ui-select>\n" +
            "    <a href=\"\" ng-click=selectAllPafs()>Select All</a> |\n" +
            "    <a href=\"\" ng-click=deselectAllPafs()>Clear</a>\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"row form-group\">\n" +
            "    <label class=\"control-label no-padding\" for=\"publishTypes\">Publish Type:</label>\n" +
            "    <icn-select\n" +
            "      id=\"publishType\"\n" +
            "      ng-model=\"fields.publishType\"\n" +
            "      options=\"publishTypes\">\n" +
            "    </icn-select>\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"row form-group\">\n" +
            "    <label class=\"control-label no-padding\">Report Date:</label>\n" +
            "    <icn-date-picker ng-model=\"fields.reportDate\" errors=\"fields.reportDateErrors\"></icn-date-picker>\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"row more-head-room\">\n" +
            "    <button ng-disabled=\"!canSubmit()\" ng-click=\"submit(fields)\">{{confirmLabel}}</button>\n" +
            "    <button class=\"pull-right button__cancel\" ng-click=\"cancel()\" ng-if=\"cancelLabel\">{{cancelLabel}}</button>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/paf_admin/dialogs/cash_overcommitted/cash_overcommitted_export.html", "<common-modal modal-header=\"{{header}}\">\n" +
            "  <div class=\"row form-group select-date\">\n" +
            "    <div>\n" +
            "      <label class=\"control-label no-padding\" for=\"selectableMonths\">Reporting Quarter:</label>\n" +
            "    </div>\n" +
            "    <div class=\"col-md-6 no-padding\">\n" +
            "      <ui-select\n" +
            "        ng-model=\"selectedFields.month\"\n" +
            "        theme=\"bootstrap\"\n" +
            "        sortable=\"true\"\n" +
            "        close-on-select=\"true\"\n" +
            "        name=\"selectable-months\"\n" +
            "        ng-change=\"onMonthChange()\"\n" +
            "        ng-disabled=\"!selectableMonths.length\">\n" +
            "        <ui-select-match placeholder=\"Select a month\">{{$select.selected.name}}</ui-select-match>\n" +
            "        <ui-select-choices\n" +
            "          repeat=\"selectableMonth.id as selectableMonth in selectableMonths | propsFilter: {name: $select.search} | orderBy: 'id'\">\n" +
            "          <div ng-bind-html=\"selectableMonth.name | highlight: $select.search\"></div>\n" +
            "        </ui-select-choices>\n" +
            "      </ui-select>\n" +
            "    </div>\n" +
            "    <div class=\"col-md-6\">\n" +
            "      <ui-select\n" +
            "        ng-model=\"selectedFields.year\"\n" +
            "        theme=\"bootstrap\"\n" +
            "        sortable=\"true\"\n" +
            "        close-on-select=\"true\"\n" +
            "        name=\"selectable-years\"\n" +
            "        ng-change=\"onYearChange()\"\n" +
            "        ng-disabled=\"!selectableYears.length\">\n" +
            "        <ui-select-match placeholder=\"Select a year\">{{$select.selected.name}}</ui-select-match>\n" +
            "        <ui-select-choices\n" +
            "          repeat=\"selectableYear.id as selectableYear in selectableYears | propsFilter: {name: $select.search} | orderBy: 'name'\">\n" +
            "          <div ng-bind-html=\"selectableYear.name | highlight: $select.search\"></div>\n" +
            "        </ui-select-choices>\n" +
            "      </ui-select>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group reports-search\">\n" +
            "    <label class=\"control-label no-padding\" for=\"partners\">Integration Partners:</label>\n" +
            "    <ui-select\n" +
            "      multiple\n" +
            "      on-select='onSelectedItem($select)'\n" +
            "      ng-model=\"selectedFields.partners\"\n" +
            "      theme=\"bootstrap\"\n" +
            "      sortable=\"true\"\n" +
            "      close-on-select=\"false\"\n" +
            "      name=\"selectable-partners\">\n" +
            "      <ui-select-match placeholder=\"Select integration partner...\">\n" +
            "        {{$item.name}}\n" +
            "      </ui-select-match>\n" +
            "      <ui-select-choices\n" +
            "        repeat=\"partner.id as partner in selectablePartners | orderBy: 'name' | propsFilter: {name: $select.search}\">\n" +
            "        <div ng-bind-html=\"partner.name | highlight: $select.search\"></div>\n" +
            "      </ui-select-choices>\n" +
            "    </ui-select>\n" +
            "    <button class=\"btn-link\" ng-click=selectAllPartners()>Select All</button>\n" +
            "    <button class=\"btn-link\" ng-click=deselectAllPartners()>Clear</button>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group\">\n" +
            "    <label for=\"filename\" class=\"control-label no-padding\">Filename:</label>\n" +
            "    <input type=\"text\" ng-model=\"selectedFields.filename\" name=\"filename\" class=\"form-control\"/>\n" +
            "  </div>\n" +
            "  <div class=\"row more-head-room\">\n" +
            "    <a target=\"_blank\" ng-click=\"submit(selectedFields)\">\n" +
            "      <button ng-disabled=\"!validSelection()\">{{confirmLabel}}</button>\n" +
            "    </a>\n" +
            "    <button class=\"pull-right button__cancel\" ng-click=\"cancel()\" ng-if=\"cancelLabel\">{{cancelLabel}}</button>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/paf_admin/dialogs/distributor_export/distributor_export.html", "<common-modal modal-header=\"{{header}}\">\n" +
            "  <div class=\"row form-group select-transactions-types\">\n" +
            "    <label class=\"control-label no-padding\" for=\"fundGroups\">Fund Group:</label>\n" +
            "    <ui-select\n" +
            "      ng-model=\"selectedFields.fundGroupId\"\n" +
            "      theme=\"bootstrap\"\n" +
            "      close-on-select=\"true\"\n" +
            "      ng-change=\"onGroupChange()\"\n" +
            "      name=\"fundGroups\">\n" +
            "      <ui-select-match placeholder=\"Select a fund group\">{{$select.selected.name}}</ui-select-match>\n" +
            "      <ui-select-choices\n" +
            "      repeat=\"group.id as group in fundGroups | orderBy: 'name' | propsFilter: {name: $select.search}\">\n" +
            "      <div ng-bind-html=\"group.name | highlight: $select.search\"></div>\n" +
            "      </ui-select-choices>\n" +
            "    </ui-select>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group select-transactions-types\" ng-show=\"!!selectedFields.fundGroupId\">\n" +
            "    <label class=\"control-label no-padding\" for=\"whiteLabelPartners\">Distributor:</label>\n" +
            "    <ui-select\n" +
            "      ng-model=\"selectedFields.wlp\"\n" +
            "      theme=\"bootstrap\"\n" +
            "      sortable=\"true\"\n" +
            "      close-on-select=\"true\"\n" +
            "      ng-change=\"onWlpChange()\"\n" +
            "      name=\"partners-select\">\n" +
            "      <ui-select-match placeholder=\"Select a distributor\">{{$select.selected.label}}</ui-select-match>\n" +
            "      <ui-select-choices\n" +
            "        repeat=\"wlp as wlp in whiteLabelPartners | propsFilter: {label: $select.search}\">\n" +
            "        <div ng-bind-html=\"wlp.label | highlight: $select.search\"></div>\n" +
            "      </ui-select-choices>\n" +
            "    </ui-select>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group select-date\" ng-show=\"!!selectedFields.wlp\">\n" +
            "    <div>\n" +
            "      <label class=\"control-label no-padding\" for=\"selectableMonths\">Reporting Month:</label>\n" +
            "    </div>\n" +
            "    <div class=\"col-md-6 no-padding\">\n" +
            "      <ui-select\n" +
            "        ng-model=\"selectedFields.month\"\n" +
            "        theme=\"bootstrap\"\n" +
            "        sortable=\"true\"\n" +
            "        close-on-select=\"true\"\n" +
            "        name=\"selectable-months\"\n" +
            "        ng-change=\"onMonthChange()\"\n" +
            "        ng-disabled=\"!selectableMonths.length\">\n" +
            "        <ui-select-match placeholder=\"Select a month\">{{$select.selected.name}}</ui-select-match>\n" +
            "        <ui-select-choices\n" +
            "          repeat=\"selectableMonth.id as selectableMonth in selectableMonths | propsFilter: {name: $select.search} | orderBy: 'id'\">\n" +
            "          <div ng-bind-html=\"selectableMonth.name | highlight: $select.search\"></div>\n" +
            "        </ui-select-choices>\n" +
            "      </ui-select>\n" +
            "    </div>\n" +
            "    <div class=\"col-md-6\">\n" +
            "      <ui-select\n" +
            "        ng-model=\"selectedFields.year\"\n" +
            "        theme=\"bootstrap\"\n" +
            "        sortable=\"true\"\n" +
            "        close-on-select=\"true\"\n" +
            "        name=\"selectable-years\"\n" +
            "        ng-change=\"onYearChange()\"\n" +
            "        ng-disabled=\"!selectableYears.length\">\n" +
            "        <ui-select-match placeholder=\"Select a year\">{{$select.selected.name}}</ui-select-match>\n" +
            "        <ui-select-choices\n" +
            "          repeat=\"selectableYear.id as selectableYear in selectableYears | propsFilter: {name: $select.search} | orderBy: 'name'\">\n" +
            "          <div ng-bind-html=\"selectableYear.name | highlight: $select.search\"></div>\n" +
            "        </ui-select-choices>\n" +
            "      </ui-select>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group\">\n" +
            "    <label for=\"filename\" class=\"control-label no-padding\">Filename:</label>\n" +
            "    <input type=\"text\" ng-model=\"selectedFields.filename\" name=\"filename\" class=\"form-control\"/>\n" +
            "  </div>\n" +
            "  <div class=\"row more-head-room\">\n" +
            "    <a target=\"_blank\" ng-click=\"submit(selectedFields)\">\n" +
            "  		<button ng-disabled=\"!validSelection()\">{{confirmLabel}}</button>\n" +
            "  	</a>\n" +
            "      <button class=\"pull-right button__cancel\" ng-click=\"cancel()\" ng-if=\"cancelLabel\">{{cancelLabel}}</button>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/paf_admin/dialogs/dtcc_activity_file/dtcc_pe_activity_file.html", "<common-modal modal-header=\"{{header}}\">\n" +
            "  <div class=\"row form-group select-fund\">\n" +
            "    <label class=\"control-label no-padding\" for=\"fund\">Fund:</label>\n" +
            "    <ui-select\n" +
            "      ng-model=\"selectedOption.pafId\"\n" +
            "      theme=\"bootstrap\"\n" +
            "      sortable=\"true\"\n" +
            "      close-on-select=\"true\"\n" +
            "      ng-change=\"onSelectedFundChange()\"\n" +
            "      name=\"pafs\">\n" +
            "      <ui-select-match placeholder=\"Select private access fund\">{{$select.selected.name}}</ui-select-match>\n" +
            "      <ui-select-choices\n" +
            "        repeat=\"paf.id as paf in pafs | orderBy: 'name' | propsFilter: {name: $select.search}\">\n" +
            "        <div ng-bind-html=\"paf.name | highlight: $select.search\"></div>\n" +
            "      </ui-select-choices>\n" +
            "    </ui-select>\n" +
            "  </div>\n" +
            "  <p ng-bind-html=\"msg\"></p>\n" +
            "  <div class=\"row form-group\">\n" +
            "    <div class=\"row vertical-margin\">\n" +
            "      <label class=\"radio-label\" for=\"capital-calls-radio\">\n" +
            "        <input type=\"radio\" id=\"capital-calls-radio\" ng-model=\"selectedOption.activity\" value=\"capitalCalls\">\n" +
            "        Capital Calls&nbsp; &nbsp;\n" +
            "      </label>\n" +
            "      <label class=\"radio-label\" for=\"distributions-radio\">\n" +
            "        <input type=\"radio\" id=\"distributions-radio\" ng-model=\"selectedOption.activity\" value=\"distributions\">\n" +
            "        Distributions\n" +
            "      </label>\n" +
            "    </div>\n" +
            "\n" +
            "\n" +
            "    <div class='row'>\n" +
            "      <table class=\"table table-hover\" ng-show=\"selectedOption.activity=='capitalCalls'\">\n" +
            "        <thead>\n" +
            "          <th></th>\n" +
            "          <th>Capital Event ID</th>\n" +
            "          <th>Capital Call Date</th>\n" +
            "          <th>Total Amount</th>\n" +
            "        </thead>\n" +
            "        <tbody>\n" +
            "          <tr ng-repeat=\"cc in capitalCalls\">\n" +
            "            <td><input type=\"checkbox\" name=\"capitalCalls\" ng-model=\"selectedOption.capitalCalls[cc.id]\"/></td>\n" +
            "            <td>{{cc.id}}</td>\n" +
            "            <td>{{cc.call_date | date}}</td>\n" +
            "            <td>{{cc.wire_amount | currency}}</td>\n" +
            "          </tr>\n" +
            "        </tbody>\n" +
            "      </table>\n" +
            "      <table class=\"table table-hover\" ng-show=\"selectedOption.activity=='distributions'\">\n" +
            "        <thead>\n" +
            "          <th></th>\n" +
            "          <th>Capital Event ID</th>\n" +
            "          <th>Wire Date</th>\n" +
            "          <th>Total Amount</th>\n" +
            "        </thead>\n" +
            "        <tbody>\n" +
            "          <tr ng-repeat=\"d in distributions\">\n" +
            "            <td><input type=\"checkbox\" name=\"distributions\" ng-model=\"selectedOption.distributions[d.id]\"/></td>\n" +
            "            <td>{{d.id}}</td>\n" +
            "            <td>{{d.call_date | date}}</td>\n" +
            "            <td>{{d.wire_amount | currency}}</td>\n" +
            "          </tr>\n" +
            "        </tbody>\n" +
            "      </table>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group\">\n" +
            "    <label class=\"control-label no-padding\" for=\"custodians\">Custodians:</label>\n" +
            "    <ui-select\n" +
            "      multiple\n" +
            "      on-select='onSelectedItem($select)'\n" +
            "      ng-model=\"selectedOption.custodianIds\"\n" +
            "      theme=\"bootstrap\"\n" +
            "      sortable=\"true\"\n" +
            "      close-on-select=\"false\"\n" +
            "      name=\"custodians\">\n" +
            "      <ui-select-match placeholder=\"Select custodian...\">\n" +
            "        {{$item.name}}\n" +
            "      </ui-select-match>\n" +
            "      <ui-select-choices\n" +
            "        repeat=\"custodian.id as custodian in custodians | orderBy: 'name' | propsFilter: {name: $select.search}\">\n" +
            "        <div ng-bind-html=\"custodian.name | highlight: $select.search\"></div>\n" +
            "      </ui-select-choices>\n" +
            "    </ui-select>\n" +
            "    <a href=\"\" ng-click=selectAllCustodians()>Select All</a> |\n" +
            "    <a href=\"\" ng-click=deselectAllCustodians()>Clear</a>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group\">\n" +
            "    <label for=\"filename\" class=\"control-label no-padding\">Filename:</label>\n" +
            "    <input type=\"text\" ng-model=\"selectedOption.filename\" name=\"filename\" class=\"form-control\"/>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group\">\n" +
            "    <label for=\"filename\" class=\"control-label no-padding\">File Type:</label>\n" +
            "    <div class=\"row vertical-margin\">\n" +
            "      <label class=\"radio-label no-margin\" for=\"csv-radio\">\n" +
            "        <input type=\"radio\" id=\"csv-radio\" ng-model=\"selectedOption.fileType\" value=\"csv\">\n" +
            "        CSV&nbsp; &nbsp;\n" +
            "      </label>\n" +
            "      <label class=\"radio-label no-margin\" for=\"xlsx-radio\">\n" +
            "        <input type=\"radio\" id=\"xlsx-radio\" ng-model=\"selectedOption.fileType\" value=\"xlsx\">\n" +
            "        Excel\n" +
            "      </label>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"row more-head-room\">\n" +
            "	<a target=\"_blank\" ng-click=\"submit(selectedOption)\">\n" +
            "		<button ng-disabled=\"!validSelection()\">{{confirmLabel}}</button>\n" +
            "	</a>\n" +
            "    <button class=\"pull-right button__cancel\" ng-click=\"cancel()\" ng-if=\"cancelLabel\">{{cancelLabel}}</button>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/paf_admin/dialogs/dtcc_export/dtcc_export.html", "<common-modal modal-header=\"{{header}}\">\n" +
            "  <div class=\"row form-group\">\n" +
            "    <label class=\"control-label no-padding\" for=\"pafs\">Funds:</label>\n" +
            "    <ui-select\n" +
            "      multiple\n" +
            "      on-select='onSelectedItem($select)'\n" +
            "      ng-model=\"fields.pafIds\"\n" +
            "      theme=\"bootstrap\"\n" +
            "      sortable=\"true\"\n" +
            "      close-on-select=\"false\"\n" +
            "      name=\"pafs\">\n" +
            "      <ui-select-match placeholder=\"Select private access fund...\">\n" +
            "        {{$item.name}}\n" +
            "      </ui-select-match>\n" +
            "      <ui-select-choices\n" +
            "        repeat=\"paf.id as paf in pafs | orderBy: 'name' | propsFilter: {name: $select.search}\">\n" +
            "        <div ng-bind-html=\"paf.name | highlight: $select.search\"></div>\n" +
            "      </ui-select-choices>\n" +
            "    </ui-select>\n" +
            "    <a href=\"\" ng-click=selectAllPafs()>Select All</a> |\n" +
            "    <a href=\"\" ng-click=deselectAllPafs()>Clear</a>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group\">\n" +
            "    <label class=\"control-label no-padding\">Effective Date:</label>\n" +
            "    <icn-date-picker ng-model=\"fields.effectiveDate\" errors=\"fields.effectiveDateErrors\"></icn-date-picker>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group\">\n" +
            "    <label class=\"control-label no-padding\" for=\"custodians\">Custodians:</label>\n" +
            "    <ui-select\n" +
            "      multiple\n" +
            "      on-select='onSelectedItem($select)'\n" +
            "      ng-model=\"fields.custodianIds\"\n" +
            "      theme=\"bootstrap\"\n" +
            "      sortable=\"true\"\n" +
            "      close-on-select=\"false\"\n" +
            "      name=\"custodians\">\n" +
            "      <ui-select-match placeholder=\"Select custodian...\">\n" +
            "        {{$item.name}}\n" +
            "      </ui-select-match>\n" +
            "      <ui-select-choices\n" +
            "        repeat=\"custodian.id as custodian in custodians | orderBy: 'name' | propsFilter: {name: $select.search}\">\n" +
            "        <div ng-bind-html=\"custodian.name | highlight: $select.search\"></div>\n" +
            "      </ui-select-choices>\n" +
            "    </ui-select>\n" +
            "    <a href=\"\" ng-click=selectAllCustodians()>Select All</a> |\n" +
            "    <a href=\"\" ng-click=deselectAllCustodians()>Clear</a>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group\" ng-if=\"reportClosingShares\">\n" +
            "    <label for=\"report-closing-shares\" class=\"control-label no-padding\"></label>\n" +
            "    <label>\n" +
            "      <input type=\"checkbox\" id=\"reportClosingShares\" ng-model=\"fields.reportClosingShares\">\n" +
            "      Report Closing Shares\n" +
            "    </label>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group\">\n" +
            "    <label for=\"filename\" class=\"control-label no-padding\">Filename:</label>\n" +
            "    <input type=\"text\" ng-model=\"fields.filename\" name=\"filename\" class=\"form-control\"/>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group\">\n" +
            "    <label for=\"filename\" class=\"control-label no-padding\">File Type:</label>\n" +
            "    <div class=\"row vertical-margin\">\n" +
            "      <label class=\"radio-label no-margin\" for=\"csv-radio\">\n" +
            "        <input type=\"radio\" id=\"csv-radio\" ng-model=\"fields.fileType\" value=\"csv\">\n" +
            "        CSV&nbsp; &nbsp;\n" +
            "      </label>\n" +
            "      <label class=\"radio-label no-margin\" for=\"xlsx-radio\">\n" +
            "        <input type=\"radio\" id=\"xlsx-radio\" ng-model=\"fields.fileType\" value=\"xlsx\">\n" +
            "        Excel\n" +
            "      </label>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"row more-head-room\">\n" +
            "    <a target=\"_blank\" ng-click=\"submit(fields)\">\n" +
            "  		<button>{{confirmLabel}}</button>\n" +
            "  	</a>\n" +
            "      <button class=\"pull-right button__cancel\" ng-click=\"cancel()\" ng-if=\"cancelLabel\">{{cancelLabel}}</button>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/paf_admin/dialogs/hf_funds_capital_report/hf_funds_capital_report.html", "<common-modal modal-header=\"{{header}}\">\n" +
            "  <div class=\"row form-group reports-search\" ng-show=\"canShowFunds()\">\n" +
            "    <label class=\"control-label no-padding\" for=\"pafs\">Funds:</label>\n" +
            "    <ui-select\n" +
            "      multiple\n" +
            "      on-select='onSelectedItem($select)'\n" +
            "      ng-model=\"fields.pafIds\"\n" +
            "      theme=\"bootstrap\"\n" +
            "      sortable=\"true\"\n" +
            "      close-on-select=\"false\"\n" +
            "      name=\"pafs\">\n" +
            "      <ui-select-match placeholder=\"Select private access fund...\">\n" +
            "        {{$item.name}}\n" +
            "      </ui-select-match>\n" +
            "      <ui-select-choices\n" +
            "        repeat=\"paf.id as paf in pafs | orderBy: 'name' | propsFilter: {name: $select.search}\">\n" +
            "        <div ng-bind-html=\"paf.name | highlight: $select.search\"></div>\n" +
            "      </ui-select-choices>\n" +
            "    </ui-select>\n" +
            "    <button class=\"btn-link\" ng-click=selectAllPafs()>Select All</button> |\n" +
            "    <button class=\"btn-link\" ng-click=deselectAllPafs()>Clear</button>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group reports-search\" ng-show=\"canShowFundGroups()\">\n" +
            "    <label class=\"control-label no-padding\" for=\"pafGroups\">Groups:</label>\n" +
            "    <ui-select\n" +
            "      multiple\n" +
            "      on-select='onSelectedItem($select)'\n" +
            "      ng-model=\"fields.pafGroupsIds\"\n" +
            "      theme=\"bootstrap\"\n" +
            "      sortable=\"true\"\n" +
            "      close-on-select=\"false\"\n" +
            "      name=\"pafGroups\">\n" +
            "      <ui-select-match placeholder=\"Select private access fund...\">\n" +
            "        {{$item.name}}\n" +
            "      </ui-select-match>\n" +
            "      <ui-select-choices\n" +
            "        repeat=\"group.id as group in pafGroups | orderBy: 'name' | propsFilter: {name: $select.search}\">\n" +
            "        <div ng-bind-html=\"group.name | highlight: $select.search\"></div>\n" +
            "      </ui-select-choices>\n" +
            "    </ui-select>\n" +
            "    <button class=\"btn-link\" ng-click=selectAllGroups()>Select All</button> |\n" +
            "    <button class=\"btn-link\" ng-click=deselectAllGroups()>Clear</button>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group reports-search\">\n" +
            "    <label class=\"control-label no-padding\" for=\"firms\">Advisor Firms:</label>\n" +
            "    <ui-select\n" +
            "      multiple\n" +
            "      on-select='onSelectedItem($select)'\n" +
            "      ng-model=\"fields.firmIds\"\n" +
            "      theme=\"bootstrap\"\n" +
            "      sortable=\"true\"\n" +
            "      close-on-select=\"false\"\n" +
            "      name=\"advisors\">\n" +
            "      <ui-select-match placeholder=\"Select firm...\">\n" +
            "        {{$item.name}}\n" +
            "      </ui-select-match>\n" +
            "      <ui-select-choices\n" +
            "        repeat=\"firm.id as firm in firms | propsFilter: {name: $select.search}\">\n" +
            "        <div ng-bind-html=\"firm.name | highlight: $select.search\"></div>\n" +
            "      </ui-select-choices>\n" +
            "    </ui-select>\n" +
            "    <button class=\"btn-link\" ng-click=selectAllFirms()>Select All</button> |\n" +
            "    <button class=\"btn-link\" ng-click=deselectAllFirms()>Clear</button>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group reports-search\">\n" +
            "    <label class=\"control-label no-padding\" for=\"custodians\">Custodians:</label>\n" +
            "    <ui-select\n" +
            "      multiple\n" +
            "      on-select='onSelectedItem($select)'\n" +
            "      ng-model=\"fields.custodianIds\"\n" +
            "      theme=\"bootstrap\"\n" +
            "      sortable=\"true\"\n" +
            "      close-on-select=\"false\"\n" +
            "      name=\"custodians\">\n" +
            "      <ui-select-match placeholder=\"Select custodian...\">\n" +
            "        {{$item.name}}\n" +
            "      </ui-select-match>\n" +
            "      <ui-select-choices\n" +
            "        repeat=\"custodian.id as custodian in custodians | orderBy: 'name' | propsFilter: {name: $select.search}\">\n" +
            "        <div ng-bind-html=\"custodian.name | highlight: $select.search\"></div>\n" +
            "      </ui-select-choices>\n" +
            "    </ui-select>\n" +
            "    <button class=\"btn-link\" ng-click=selectAllCustodians()>Select All</button> |\n" +
            "    <button class=\"btn-link\" ng-click=deselectAllCustodians()>Clear</button>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group reports-search\">\n" +
            "    <label class=\"control-label no-padding\" for=\"wlps\">White Label Partners:</label>\n" +
            "    <ui-select\n" +
            "      multiple\n" +
            "      on-select='onSelectedItem($select)'\n" +
            "      ng-model=\"fields.wlpIds\"\n" +
            "      theme=\"bootstrap\"\n" +
            "      sortable=\"true\"\n" +
            "      close-on-select=\"false\"\n" +
            "      name=\"wlps\">\n" +
            "      <ui-select-match placeholder=\"Select white label partner...\">\n" +
            "        {{$item.name}}\n" +
            "      </ui-select-match>\n" +
            "      <ui-select-choices\n" +
            "        repeat=\"wlp.id as wlp in wlps | orderBy: 'name' | propsFilter: {name: $select.search}\">\n" +
            "        <div ng-bind-html=\"wlp.name | highlight: $select.search\"></div>\n" +
            "      </ui-select-choices>\n" +
            "    </ui-select>\n" +
            "    <button class=\"btn-link\" ng-click=selectAllWlp()>Select All</button> |\n" +
            "    <button class=\"btn-link\" ng-click=deselectAllWlp()>Clear</button>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group select-transactions-types\">\n" +
            "    <label class=\"control-label no-padding\" for=\"transactionTypes\">Report Type:</label>\n" +
            "    <ui-select\n" +
            "      ng-model=\"fields.transactionType\"\n" +
            "      theme=\"bootstrap\"\n" +
            "      sortable=\"true\"\n" +
            "      close-on-select=\"true\"\n" +
            "      name=\"transactionTypes\">\n" +
            "      <ui-select-match placeholder=\"Select a transaction type\">{{$select.selected.name}}</ui-select-match>\n" +
            "      <ui-select-choices\n" +
            "        repeat=\"transactionType.id as transactionType in transactionTypes | propsFilter: {name: $select.search}\">\n" +
            "        <div ng-bind-html=\"transactionType.name | highlight: $select.search\"></div>\n" +
            "      </ui-select-choices>\n" +
            "    </ui-select>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group select-date mt-1\" ng-show=\"showDatePicker()\">\n" +
            "    <div class=\"form-group col-sm-6 no-padding\">\n" +
            "      <label for=\"from-date\" class=\"col-sm-4 no-padding\">From Date</label>\n" +
            "      <input\n" +
            "        type=\"date\"\n" +
            "        class=\"form-control col-md-3\"\n" +
            "        ng-model=\"fields.fromDate\"\n" +
            "        ng-change=\"onFromDateChange()\"\n" +
            "      />\n" +
            "    </div>\n" +
            "    <div class=\"form-group col-sm-6\">\n" +
            "      <label for=\"to-date\" class=\"col-sm-4 control-label no-padding\">To Date</label>\n" +
            "      <input\n" +
            "        type=\"date\"\n" +
            "        class=\"form-control col-md-3\"\n" +
            "        ng-model=\"fields.toDate\"\n" +
            "        min=\"{{toMinDate | date:'yyyy-MM-dd'}}\"\n" +
            "      />\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group\">\n" +
            "    <label for=\"filename\" class=\"control-label no-padding\">Filename:</label>\n" +
            "    <input type=\"text\" ng-model=\"fields.filename\" name=\"filename\" class=\"form-control\"/>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group\">\n" +
            "    <label for=\"filename\" class=\"control-label no-padding\">File Type:</label>\n" +
            "    <div class=\"row vertical-margin\">\n" +
            "      <label class=\"radio-label no-margin\" for=\"csv-radio\">\n" +
            "        <input type=\"radio\" id=\"csv-radio\" ng-model=\"fields.fileType\" value=\"csv\">\n" +
            "        CSV&nbsp; &nbsp;\n" +
            "      </label>\n" +
            "      <label class=\"radio-label no-margin\" for=\"xlsx-radio\">\n" +
            "        <input type=\"radio\" id=\"xlsx-radio\" ng-model=\"fields.fileType\" value=\"xlsx\">\n" +
            "        Excel\n" +
            "      </label>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"row more-head-room\">\n" +
            "    <a target=\"_blank\" ng-click=\"submit(fields)\">\n" +
            "  		<button>{{confirmLabel}}</button>\n" +
            "  	</a>\n" +
            "      <button class=\"pull-right button__cancel\" ng-click=\"cancel()\" ng-if=\"cancelLabel\">{{cancelLabel}}</button>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/paf_admin/dialogs/nasdaq_export/nasdaq_export.html", "<common-modal modal-header=\"{{header}}\">\n" +
            "  <div class=\"row form-group select-fund\">\n" +
            "    <label class=\"control-label no-padding\" for=\"fund\">Fund:</label>\n" +
            "    <ui-select\n" +
            "      ng-model=\"fields.pafId\"\n" +
            "      theme=\"bootstrap\"\n" +
            "      sortable=\"true\"\n" +
            "      close-on-select=\"true\"\n" +
            "      ng-change=\"onSelectedFundChange()\"\n" +
            "      name=\"pafs\">\n" +
            "      <ui-select-match placeholder=\"Select a fund\">{{$select.selected.name}}</ui-select-match>\n" +
            "      <ui-select-choices\n" +
            "        repeat=\"paf.id as paf in pafs | orderBy: 'name' | propsFilter: {name: $select.search}\">\n" +
            "        <div ng-bind-html=\"paf.name | highlight: $select.search\"></div>\n" +
            "      </ui-select-choices>\n" +
            "    </ui-select>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group\">\n" +
            "    <label class=\"control-label no-padding\" for=\"quarters\">Effective Quarters:</label>\n" +
            "    <ui-select\n" +
            "      ng-model=\"fields.effectiveQuarter\"\n" +
            "      ng-disabled=\"isEmpty()\"\n" +
            "      name=\"quarters-select\">\n" +
            "      <ui-select-match\n" +
            "        allow-clear=\"true\"\n" +
            "        placeholder=\"{{placeholder}}\">\n" +
            "          {{$select.selected.id}}\n" +
            "      </ui-select-match>\n" +
            "      <ui-select-choices repeat=\"quarter in availableQuarters\">\n" +
            "        <div ng-bind-html=\"quarter.id | highlight: $select.search\"></div>\n" +
            "      </ui-select-choices>\n" +
            "    </ui-select>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group\">\n" +
            "    <label for=\"filename\" class=\"control-label no-padding\">Filename:</label>\n" +
            "    <input type=\"text\" ng-model=\"fields.filename\" name=\"filename\" class=\"form-control\"/>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group\">\n" +
            "    <label for=\"filename\" class=\"control-label no-padding\">File Type:</label>\n" +
            "    <div class=\"row vertical-margin\">\n" +
            "      <label class=\"radio-label no-margin\" for=\"csv-radio\">\n" +
            "        <input type=\"radio\" id=\"csv-radio\" ng-model=\"fields.fileType\" value=\"csv\">\n" +
            "        CSV&nbsp; &nbsp;\n" +
            "      </label>\n" +
            "      <label class=\"radio-label no-margin\" for=\"xlsx-radio\">\n" +
            "        <input type=\"radio\" id=\"xlsx-radio\" ng-model=\"fields.fileType\" value=\"xlsx\">\n" +
            "        Excel\n" +
            "      </label>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"row more-head-room\">\n" +
            "    <a target=\"_blank\" ng-click=\"submit(fields)\">\n" +
            "  		<button ng-disabled=\"!validSelection()\">{{confirmLabel}}</button>\n" +
            "  	</a>\n" +
            "      <button class=\"pull-right button__cancel\" ng-click=\"cancel()\" ng-if=\"cancelLabel\">{{cancelLabel}}</button>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/paf_admin/dialogs/pe_funds_capital_report/pe_funds_capital_report.html", "<common-modal modal-header=\"{{header}}\">\n" +
            "  <div class=\"row form-group reports-search\">\n" +
            "    <label class=\"control-label no-padding\" for=\"pafs\">Funds:</label>\n" +
            "    <ui-select\n" +
            "      multiple\n" +
            "      on-select='onSelectedItem($select)'\n" +
            "      ng-model=\"fields.pafIds\"\n" +
            "      theme=\"bootstrap\"\n" +
            "      sortable=\"true\"\n" +
            "      close-on-select=\"false\"\n" +
            "      name=\"pafs\"\n" +
            "      ng-change=\"onPafChange()\">\n" +
            "      <ui-select-match placeholder=\"Select private access fund...\">\n" +
            "        {{$item.name}}\n" +
            "      </ui-select-match>\n" +
            "      <ui-select-choices\n" +
            "        repeat=\"paf.id as paf in pafs | orderBy: 'name' | propsFilter: {name: $select.search}\">\n" +
            "        <div ng-bind-html=\"paf.name | highlight: $select.search\"></div>\n" +
            "      </ui-select-choices>\n" +
            "    </ui-select>\n" +
            "    <button class=\"btn-link\" ng-click=\"selectAllPafs()\">Select All</button> |\n" +
            "    <button class=\"btn-link\" ng-click=deselectAllPafs()>Clear</button>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group reports-search\">\n" +
            "    <label class=\"control-label no-padding\" for=\"firms\">Advisor Firm:</label>\n" +
            "    <ui-select\n" +
            "      multiple\n" +
            "      on-select='onSelectedItem($select)'\n" +
            "      ng-model=\"fields.firmIds\"\n" +
            "      theme=\"bootstrap\"\n" +
            "      sortable=\"true\"\n" +
            "      close-on-select=\"false\"\n" +
            "      name=\"firms\">\n" +
            "      <ui-select-match placeholder=\"Select firm...\">\n" +
            "        {{$item.name}}\n" +
            "      </ui-select-match>\n" +
            "      <ui-select-choices\n" +
            "        repeat=\"firm.id as firm in firms | propsFilter: {name: $select.search}\">\n" +
            "        <div ng-bind-html=\"firm.name | highlight: $select.search\"></div>\n" +
            "      </ui-select-choices>\n" +
            "    </ui-select>\n" +
            "    <button class=\"btn-link\" ng-click=selectAllFirms()>Select All</button> |\n" +
            "    <button class=\"btn-link\" ng-click=deselectAllFirms()>Clear</button>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group reports-search\">\n" +
            "    <label class=\"control-label no-padding\" for=\"custodians\">Custodians:</label>\n" +
            "    <ui-select\n" +
            "      multiple\n" +
            "      on-select='onSelectedItem($select)'\n" +
            "      ng-model=\"fields.custodianIds\"\n" +
            "      theme=\"bootstrap\"\n" +
            "      sortable=\"true\"\n" +
            "      close-on-select=\"false\"\n" +
            "      name=\"custodians\">\n" +
            "      <ui-select-match placeholder=\"Select custodian...\">\n" +
            "        {{$item.name}}\n" +
            "      </ui-select-match>\n" +
            "      <ui-select-choices\n" +
            "        repeat=\"custodian.id as custodian in custodians | orderBy: 'name' | propsFilter: {name: $select.search}\">\n" +
            "        <div ng-bind-html=\"custodian.name | highlight: $select.search\"></div>\n" +
            "      </ui-select-choices>\n" +
            "    </ui-select>\n" +
            "    <button class=\"btn-link\" ng-click=selectAllCustodians()>Select All</button> |\n" +
            "    <button class=\"btn-link\" ng-click=deselectAllCustodians()>Clear</button>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group reports-search\">\n" +
            "    <label class=\"control-label no-padding\" for=\"wlps\">White Label Partners:</label>\n" +
            "    <ui-select\n" +
            "      multiple\n" +
            "      on-select='onSelectedItem($select)'\n" +
            "      ng-model=\"fields.wlpIds\"\n" +
            "      theme=\"bootstrap\"\n" +
            "      sortable=\"true\"\n" +
            "      close-on-select=\"false\"\n" +
            "      name=\"wlps\">\n" +
            "      <ui-select-match placeholder=\"Select white label partner...\">\n" +
            "        {{$item.name}}\n" +
            "      </ui-select-match>\n" +
            "      <ui-select-choices\n" +
            "        repeat=\"wlp.id as wlp in wlps | orderBy: 'name' | propsFilter: {name: $select.search}\">\n" +
            "        <div ng-bind-html=\"wlp.name | highlight: $select.search\"></div>\n" +
            "      </ui-select-choices>\n" +
            "    </ui-select>\n" +
            "    <button class=\"btn-link\" ng-click=selectAllWlp()>Select All</button> |\n" +
            "    <button class=\"btn-link\" ng-click=deselectAllWlp()>Clear</button>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group select-transactions-types\">\n" +
            "    <label class=\"control-label no-padding\" for=\"transactionTypes\">Report Type:</label>\n" +
            "    <ui-select\n" +
            "      ng-model=\"fields.transactionType\"\n" +
            "      theme=\"bootstrap\"\n" +
            "      sortable=\"true\"\n" +
            "      close-on-select=\"true\"\n" +
            "      name=\"transactionTypes\">\n" +
            "      <ui-select-match placeholder=\"Select a report type\">{{$select.selected.name}}</ui-select-match>\n" +
            "      <ui-select-choices\n" +
            "        repeat=\"transactionTypeI.id as transactionTypeI in transactionTypes | propsFilter: {name: $select.search}\">\n" +
            "        <div ng-bind-html=\"transactionTypeI.name | highlight: $select.search\"></div>\n" +
            "      </ui-select-choices>\n" +
            "    </ui-select>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group select-date\" ng-show=\"showDatePicker()\">\n" +
            "    <div>\n" +
            "      <label class=\"control-label no-padding\" for=\"selectableMonths\">Reporting Quarter:</label>\n" +
            "    </div>\n" +
            "    <div class=\"col-md-6 no-padding\">\n" +
            "      <ui-select\n" +
            "        ng-model=\"selectedFields.month\"\n" +
            "        theme=\"bootstrap\"\n" +
            "        sortable=\"true\"\n" +
            "        close-on-select=\"true\"\n" +
            "        name=\"selectableMonths\"\n" +
            "        ng-change=\"onMonthChange()\"\n" +
            "        ng-disabled=\"!selectableMonths.length\">\n" +
            "        <ui-select-match placeholder=\"Select a month\">{{$select.selected.name}}</ui-select-match>\n" +
            "        <ui-select-choices\n" +
            "          repeat=\"selectableMonth.id as selectableMonth in selectableMonths | propsFilter: {name: $select.search} | orderBy: 'id'\">\n" +
            "          <div ng-bind-html=\"selectableMonth.name | highlight: $select.search\"></div>\n" +
            "        </ui-select-choices>\n" +
            "      </ui-select>\n" +
            "    </div>\n" +
            "    <div class=\"col-md-6\">\n" +
            "      <ui-select\n" +
            "        ng-model=\"selectedFields.year\"\n" +
            "        theme=\"bootstrap\"\n" +
            "        sortable=\"true\"\n" +
            "        close-on-select=\"true\"\n" +
            "        name=\"selectableYears\"\n" +
            "        ng-change=\"onYearChange()\"\n" +
            "        ng-disabled=\"!selectableYears.length\">\n" +
            "        <ui-select-match placeholder=\"Select a year\">{{$select.selected.name}}</ui-select-match>\n" +
            "        <ui-select-choices\n" +
            "          repeat=\"selectableYear.id as selectableYear in selectableYears | propsFilter: {name: $select.search} | orderBy: 'name'\">\n" +
            "          <div ng-bind-html=\"selectableYear.name | highlight: $select.search\"></div>\n" +
            "        </ui-select-choices>\n" +
            "      </ui-select>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div ng-show=\"showTimeInterval()\">\n" +
            "    <label class=\"control-label no-padding\" >Time Interval:</label>\n" +
            "    <div class=\"row vertical-margin\">\n" +
            "      <input type=\"checkbox\" id=\"checkQTD\" ng-model=\"fields.timeQTD\"/>\n" +
            "      QTD&nbsp; &nbsp;\n" +
            "      <input type=\"checkbox\" id=\"checkYTD\" ng-model=\"fields.timeYTD\"/>\n" +
            "      YTD&nbsp; &nbsp;\n" +
            "      <input type=\"checkbox\" id=\"checkITD\" ng-model=\"fields.timeITD\"/>\n" +
            "      ITD&nbsp; &nbsp;\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group\">\n" +
            "    <label for=\"filename\" class=\"control-label no-padding\">Numerical Values:</label>\n" +
            "    <div class=\"row vertical-margin\">\n" +
            "      <label class=\"radio-label no-margin\" for=\"non-rounded-radio\">\n" +
            "        <input type=\"radio\" id=\"non-rounded-radio\" ng-model=\"fields.isRounded\" value=\"false\">\n" +
            "        Non-Rounded&nbsp; &nbsp;\n" +
            "      </label>\n" +
            "      <label class=\"radio-label no-margin\" for=\"rounded-radio\">\n" +
            "        <input type=\"radio\" id=\"rounded-radio\" ng-model=\"fields.isRounded\" value=\"true\">\n" +
            "        Rounded\n" +
            "      </label>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group\" ng-show=\"showIncludeNavPerShare()\">\n" +
            "    <div class=\"row vertical-margin\">\n" +
            "      <label class=\"radio-label no-margin\" for=\"non-rounded-radio\">\n" +
            "          <input type=\"checkbox\" ng-model=\"fields.showNavPerShare\" />\n" +
            "          Include NAV Per Share&nbsp; &nbsp;\n" +
            "      </label>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group\">\n" +
            "    <label for=\"filename\" class=\"control-label no-padding\">Filename:</label>\n" +
            "    <input type=\"text\" ng-model=\"fields.filename\" name=\"filename\" class=\"form-control\"/>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group\">\n" +
            "    <label for=\"filename\" class=\"control-label no-padding\">File Type:</label>\n" +
            "    <div class=\"row vertical-margin\">\n" +
            "      <label class=\"radio-label no-margin\" for=\"csv-radio\">\n" +
            "        <input type=\"radio\" id=\"csv-radio\" ng-model=\"fields.fileType\" value=\"csv\">\n" +
            "        CSV&nbsp; &nbsp;\n" +
            "      </label>\n" +
            "      <label class=\"radio-label no-margin\" for=\"xlsx-radio\">\n" +
            "        <input type=\"radio\" id=\"xlsx-radio\" ng-model=\"fields.fileType\" value=\"xlsx\">\n" +
            "        Excel\n" +
            "      </label>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"row more-head-room\">\n" +
            "    <a target=\"_blank\" ng-click=\"submit(fields)\">\n" +
            "  		<button>{{confirmLabel}}</button>\n" +
            "  	</a>\n" +
            "      <button class=\"pull-right button__cancel\" ng-click=\"cancel()\" ng-if=\"cancelLabel\">{{cancelLabel}}</button>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/paf_admin/dialogs/tax_report/tax_report.html", "<common-modal modal-header=\"{{header}}\">\n" +
            "  <div class=\"row form-group select-date\">\n" +
            "    <div class=\"row form-group\">\n" +
            "      <label class=\"control-label no-padding\">Fund Type:</label>\n" +
            "      <div class=\"row vertical-margin\">\n" +
            "        <label class=\"radio-label no-margin\" for=\"non-open-ended-radio\">\n" +
            "          <input type=\"radio\" id=\"non-open-ended-radio\" ng-model=\"selectedFields.openEnded\" value=\"false\">\n" +
            "           Private Equity\n" +
            "        </label>\n" +
            "        <label class=\"radio-label no-margin\" for=\"open-ended-radio\">\n" +
            "          <input type=\"radio\" id=\"open-ended-radio\" ng-model=\"selectedFields.openEnded\" value=\"true\">\n" +
            "           Hedge-Fund\n" +
            "        </label>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "    <div>\n" +
            "      <label class=\"control-label no-padding\">Reporting Year:</label>\n" +
            "    </div>\n" +
            "    <div class=\"col-md-6\">\n" +
            "      <ui-select\n" +
            "              ng-model=\"selectedFields.year\"\n" +
            "              theme=\"bootstrap\"\n" +
            "              sortable=\"true\"\n" +
            "              close-on-select=\"true\"\n" +
            "              name=\"selectable-years\"\n" +
            "              ng-change=\"onYearChange()\"\n" +
            "              ng-disabled=\"!selectableYears.length\">\n" +
            "        <ui-select-match placeholder=\"Select a year\">{{ selectedFields.year }}</ui-select-match>\n" +
            "        <ui-select-choices\n" +
            "                repeat=\"selectableYear in selectableYears\">\n" +
            "          <div ng-bind-html=\"selectableYear | highlight: $select.search\"></div>\n" +
            "        </ui-select-choices>\n" +
            "      </ui-select>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group\">\n" +
            "    <label for=\"filename\" class=\"control-label no-padding\">Filename:</label>\n" +
            "    <input type=\"text\" ng-model=\"selectedFields.filename\" name=\"filename\" class=\"form-control\"/>\n" +
            "  </div>\n" +
            "  <div class=\"row more-head-room\">\n" +
            "    <a target=\"_blank\" ng-click=\"submit(selectedFields)\">\n" +
            "      <button ng-disabled=\"!validSelection()\">{{ confirmLabel }}</button>\n" +
            "    </a>\n" +
            "    <button class=\"pull-right button__cancel\" ng-click=\"cancel()\" ng-if=\"cancelLabel\">{{ cancelLabel }}</button>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/paf_admin/tabs/common/funds/pages/funds/components/share_classes/share_classes_table.html", "<div class=\"fund-share-class-list\" ng-if='fund'>\n" +
            "  <div class=\"col-md-12\">\n" +
            "    <table class=\"icn-table share-class-table\">\n" +
            "      <thead>\n" +
            "        <tr>\n" +
            "          <th> ID </th>\n" +
            "          <th> Name </th>\n" +
            "          <th> Description </th>\n" +
            "          <th> Default </th>\n" +
            "          <th> Status </th>\n" +
            "        </tr>\n" +
            "      </thead>\n" +
            "\n" +
            "      <tbody>\n" +
            "        <tr class=\"share-class-row\" ng-repeat=\"shareClass in fund.share_classes\">\n" +
            "          <!-- ID -->\n" +
            "          <td>\n" +
            "            <span>{{shareClass.id}}</span>\n" +
            "          </td>\n" +
            "\n" +
            "          <!-- Name -->\n" +
            "          <td>\n" +
            "            <span>{{shareClass.name}}</span>\n" +
            "          </td>\n" +
            "\n" +
            "          <!-- Description -->\n" +
            "          <td>\n" +
            "            <span>{{shareClass.description}}</span>\n" +
            "          </td>\n" +
            "\n" +
            "          <!-- Default -->\n" +
            "          <td>\n" +
            "            <span>{{defaultText(shareClass)}}</span>\n" +
            "          </td>\n" +
            "\n" +
            "          <!-- Status -->\n" +
            "          <td>\n" +
            "            <span class=\"text-capitalize\">{{shareClass.status}}</span>\n" +
            "          </td>\n" +
            "\n" +
            "          <!-- Controls -->\n" +
            "          <td>\n" +
            "            <div\n" +
            "              class=\"icon-container control view-share-class\"\n" +
            "              ng-click=\"readOnlyShareClassModal(shareClass)\">\n" +
            "              <span class=\"ui-icon ui-icon-document\"></span>\n" +
            "            </div>\n" +
            "            <div\n" +
            "              class=\"icon-container control edit-share-class\"\n" +
            "              ng-click=\"writeShareClassModal(shareClass)\"\n" +
            "              ng-show=\"canEditShareClasses()\">\n" +
            "              <span class=\"ui-icon ui-icon-pencil\"></span>\n" +
            "            </div>\n" +
            "            <div\n" +
            "              class=\"delete-share-class icon-container control\"\n" +
            "              ng-show=\"deletable(shareClass)\"\n" +
            "              ng-click=\"removalWarning(shareClass)\">\n" +
            "              <span class=\"ui-icon ui-icon-trash\"></span>\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "\n" +
            "        <tr ng-if=\"canEditShareClasses()\">\n" +
            "          <td>\n" +
            "            <div class=\"icon-container\" ng-click=\"writeShareClassModal()\">\n" +
            "              <a href=\"#\">\n" +
            "                <span class=\"pull-left ui-icon ui-icon-plus\"></span> Add Share Class\n" +
            "              </a>\n" +
            "            </div>\n" +
            "            <div class=\"icon-container\" ng-click=\"writeBulkUploadModal()\">\n" +
            "              <a href=\"#\">\n" +
            "                <span class=\"pull-left ui-icon ui-icon-plus\"></span> Share Class Bulk Upload\n" +
            "              </a>\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        <tr>\n" +
            "      </tbody>\n" +
            "    </table>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/paf_admin/tabs/common/redemptions/pages/approvals/approvals.html", "<div class=\"redemption-approvals hf-content\">\n" +
            "    <select class=\"hf-fund-select\"\n" +
            "            ng-model=\"fund\"\n" +
            "            ng-options=\"item.name for item in funds track by item.id\"\n" +
            "            ng-change=\"refreshList()\">\n" +
            "        <option value=\"\" disabled=\"disabled\">Select Option</option>\n" +
            "    </select>\n" +
            "\n" +
            "    <paf-administration-notification message=\"notification\" ng-show=\"notification\"></paf-administration-notification>\n" +
            "\n" +
            "    <div ng-show='funds.length == 0 || (redemptions.length == 0 && selectedPafId != null)'>\n" +
            "        <div class=\"alert alert-default\">\n" +
            "            There are no redemptions to approve.\n" +
            "        </div>\n" +
            "    </div>\n" +
            "\n" +
            "    <div ng-show='funds.length > 0 && selectedPafId == null'>\n" +
            "        <div class=\"alert alert-default\">\n" +
            "            Select a fund to view redemptions.\n" +
            "        </div>\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"hf-box\" ng-if=\"redemptions.length > 0\">\n" +
            "        <div class=\"header-bar\"></div>\n" +
            "        <div class=\"investmentList\">\n" +
            "            <table class=\"table table-hover\">\n" +
            "                <thead>\n" +
            "                <tr class=\"investmentList-columnsHeader\">\n" +
            "                    <th ng-click=\"orderBy('redeem_date')\">\n" +
            "                        REDEEM DATE\n" +
            "                        <span ng-show=\"orderByField=='redeem_date'\"\n" +
            "                              ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\"></span>\n" +
            "                    </th>\n" +
            "                    <th ng-click=\"orderBy('value_date')\">\n" +
            "                        EFFECTIVE DATE\n" +
            "                        <span ng-show=\"orderByField=='value_date'\"\n" +
            "                              ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\"></span>\n" +
            "                    </th>\n" +
            "                    <th ng-click=\"orderBy('investor_name')\">\n" +
            "                        INVESTOR INFO\n" +
            "                        <span ng-show=\"orderByField=='investor_name'\"\n" +
            "                              ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\"></span>\n" +
            "                    </th>\n" +
            "                    <th ng-click=\"orderBy('full')\">\n" +
            "                        FULL/PARTIAL\n" +
            "                        <span ng-show=\"orderByField=='full'\"\n" +
            "                              ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\"></span>\n" +
            "                    </th>\n" +
            "                    <th ng-click=\"orderBy('value_date_nav')\">\n" +
            "                        VALUE DATE NAV\n" +
            "                        <span ng-show=\"orderByField=='value_date_nav'\"\n" +
            "                              ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\"></span>\n" +
            "                    </th>\n" +
            "                    <th ng-click=\"orderBy('total_distribution')\">\n" +
            "                        AMOUNT REDEEMED\n" +
            "                        <span ng-show=\"orderByField=='total_distribution'\"\n" +
            "                              ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\"></span>\n" +
            "                    </th>\n" +
            "                    <th ng-click=\"orderBy('wired_amount')\">\n" +
            "                        NET WIRE\n" +
            "                        <span ng-show=\"orderByField=='wired_amount'\"\n" +
            "                              ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\"></span>\n" +
            "                    </th>\n" +
            "                    <th ng-click=\"orderBy('hold_back')\">\n" +
            "                        HOLD BACK\n" +
            "                        <span ng-show=\"orderByField=='hold_back'\"\n" +
            "                              ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\"></span>\n" +
            "                    </th>\n" +
            "                    <th ng-click=\"orderBy('wire_date')\">\n" +
            "                        WIRE DATE\n" +
            "                        <span ng-show=\"orderByField=='wire_date'\"\n" +
            "                              ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\"></span>\n" +
            "                    </th>\n" +
            "                    <th></th>\n" +
            "                </tr>\n" +
            "                </thead>\n" +
            "                <tbody>\n" +
            "                <tr class=\"investmentList-investmentItem\"\n" +
            "                    ng-repeat=\"redemption in redemptions|orderBy:orderByField:reverseSort\">\n" +
            "                    <td>\n" +
            "                        <div class=\"form-group\">\n" +
            "                            <span>{{redemption.redeem_date}}</span>\n" +
            "                        </div>\n" +
            "                    </td>\n" +
            "                    <td>\n" +
            "                        <div class=\"form-group\">\n" +
            "                            <span>{{redemption.value_date}}</span>\n" +
            "                        </div>\n" +
            "                    </td>\n" +
            "                    <td>{{redemption.investor_name}}</td>\n" +
            "                    <td>{{redemption.full ? \"Full\" : \"Partial\"}}</td>\n" +
            "                    <td>{{redemption.value_date_nav | noFractionCurrency}}</td>\n" +
            "                    <td>{{redemption.total_distribution | noFractionCurrency}}</td>\n" +
            "                    <td>{{redemption.wired_amount | noFractionCurrency}}</td>\n" +
            "                    <td>{{redemption.hold_back * 100 | number : 0}}%</td>\n" +
            "                    <td>{{redemption.wire_date}}</td>\n" +
            "                    <td>\n" +
            "                        <div class=\"dropdown\">\n" +
            "                            <a data-toggle=\"dropdown\" class=\"dropdown-toggle redemptions-actions-button\">\n" +
            "                                <img src=\"images/gear2.gif\">\n" +
            "                            </a>\n" +
            "                            <ul class=\"dropdown-menu redemption-approvals__actions\">\n" +
            "                                <li>\n" +
            "                                    <a ng-click=\"approve(redemption)\">Approve</a>\n" +
            "                                </li>\n" +
            "                                <li>\n" +
            "                                    <a ng-click=\"reject(redemption)\">Reject</a>\n" +
            "                                </li>\n" +
            "                            </ul>\n" +
            "                        </div>\n" +
            "                    </td>\n" +
            "                </tr>\n" +
            "                </tbody>\n" +
            "            </table>\n" +
            "        </div>\n" +
            "    </div>\n" +
            "</div>");

        $templateCache.put("areas/paf_admin/tabs/common/redemptions/pages/approvals/dialogs/set_rejection_reason/set_rejection_reason.html", "<common-modal modal-header=\"Rejecting Redemption\">\n" +
            "    <div>\n" +
            "        <form class=\"form-horizontal\">\n" +
            "            <div class=\"form-group\">\n" +
            "                <p>Enter the reason for rejection:</p>\n" +
            "              <textarea validator=\"[requiredBlur]\" ng-model=\"rejection.reason\"></textarea>\n" +
            "            </div>\n" +
            "        </form>\n" +
            "    </div>\n" +
            "    <div class=\"row\">\n" +
            "        <button class=\"pull-right\" ng-click=\"save()\">Save</button>\n" +
            "    </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/paf_admin/tabs/common/redemptions/pages/dashboard/components/redemption_list/redemption_list.html", "<div class=\"hf-box\">\n" +
            "    <div class=\"hf-actions clearfix mb-1\">\n" +
            "      <span style=\"float: right\">\n" +
            "        <button type=\"button\" ng-csv=\"activeRedemptions\"\n" +
            "            csv-header=\"['Fund Name','ID', 'Investor ID', 'Investor Name', 'Firm', 'Request Date', 'Effective Date', 'Full', 'Nav', 'Nav Date', 'Redemption Request', 'Hold Back %',\n" +
            "              'Net Wire', 'Wire Date', 'Status']\"\n" +
            "            csv-column-order=\"['fund_name','id','investor_id', 'investor_name', 'ria_firm_name', 'redeem_date','value_date','full','reportable_amount','reportable_amount_as_of_date','redemption_request','hold_back',\n" +
            "              'net_wired', 'wire_date','status']\"\n" +
            "            filename=\"redemptions.csv\">Export All\n" +
            "        </button>\n" +
            "      </span>\n" +
            "    </div>\n" +
            "    <div class=\"header-bar\"></div>\n" +
            "    <div class=\"investmentList\">\n" +
            "        <table class=\"table table-hover\">\n" +
            "            <thead>\n" +
            "            <tr class=\"investmentList-columnsHeader\">\n" +
            "                <th>\n" +
            "                    FUND NAME\n" +
            "                </th>\n" +
            "                <th>\n" +
            "                    EFFECTIVE DATE\n" +
            "                </th>\n" +
            "                <th>\n" +
            "                    # OF REDEMPTIONS\n" +
            "                </th>\n" +
            "                <th>\n" +
            "                    TOTAL REDEMPTIONS ($)\n" +
            "                </th>\n" +
            "                <th>\n" +
            "                    NET WIRED ($)\n" +
            "                </th>\n" +
            "                <th>\n" +
            "                    TOTAL HELD BACK ($)\n" +
            "                </th>\n" +
            "                <th class=\"investmentList-actions\"></th>\n" +
            "            </tr>\n" +
            "            </thead>\n" +
            "            <tbody>\n" +
            "            <tr class=\"investmentList-investmentItem\" ng-repeat=\"row in dashboardData | orderBy: 'value_date' : true\">\n" +
            "                <td>{{row.paf_name}}</td>\n" +
            "                <td>{{row.nav_value_date}}</td>\n" +
            "                <td>{{row.num_withdrawals}}</td>\n" +
            "                <td>{{row.total_withdrawals | noFractionCurrency}}</td>\n" +
            "                <td>{{row.net_wired | noFractionCurrency}}</td>\n" +
            "                <td>{{row.total_held_back | noFractionCurrency}}</td>\n" +
            "                <td class=\"investmentList-actions\">\n" +
            "                    <i class=\"fa fa-chevron-circle-right\" style=\"color:blue\"\n" +
            "                       ng-click=\"advanceToRedemptions(row.paf_id)\"></i>\n" +
            "                </td>\n" +
            "            </tr>\n" +
            "            </tbody>\n" +
            "            <tfoot>\n" +
            "            <tr>\n" +
            "                <td><strong>Total</strong></td>\n" +
            "                <td></td>\n" +
            "                <td><strong> {{totals.numWithdrawals | number:0}}</strong></td>\n" +
            "                <td><strong> ${{totals.totalWithdrawals | number:0}}</strong></td>\n" +
            "                <td><strong> ${{totals.netWired | number:0}}</strong></td>\n" +
            "                <td><strong> ${{totals.totalHeldBack | number:0}}</strong></td>\n" +
            "                <td></td>\n" +
            "            </tr>\n" +
            "            </tfoot>\n" +
            "\n" +
            "        </table>\n" +
            "    </div>\n" +
            "</div>");

        $templateCache.put("areas/paf_admin/tabs/common/redemptions/pages/dashboard/dashboard.html", "<div class=\"hf-content\">\n" +
            "\n" +
            "  <icn-paf-admin-nav type='{{fundType}}'></icn-paf-admin-nav>\n" +
            "  <div class=\"col-md-3\">\n" +
            "    <icn-select\n" +
            "      id=\"selectFundById\"\n" +
            "      label=\"Select a fund\"\n" +
            "      option-label=\"name\"\n" +
            "      ng-model=\"selectedFund\"\n" +
            "      on-change=\"selectId(object.id)\"\n" +
            "      options=\"funds\">\n" +
            "    </icn-select>\n" +
            "  </div>\n" +
            "  <div class=\"col-md-3\" ng-if=\"fundType == 'hf' && !hideFundGroups\">\n" +
            "    <icn-select\n" +
            "      id=\"selectFundByGroup\"\n" +
            "      label=\"Select a fund group\"\n" +
            "      option-label=\"name\"\n" +
            "      ng-model=\"selectedGroup\"\n" +
            "      on-change=\"selectGroup(object.id)\"\n" +
            "      options=\"fundGroups\">\n" +
            "    </icn-select>\n" +
            "  </div>\n" +
            "  <div class=\"col-md-3\">\n" +
            "    <i class=\"fa fa-calendar\" aria-hidden=\"true\"></i>\n" +
            "    <div class=\"heading-6 redemptions-dashboard-heading\">Start Date</div>\n" +
            "    <input\n" +
            "      name=\"startDate\"\n" +
            "      autocomplete=\"off\"\n" +
            "      type=\"text\"\n" +
            "      ui-date-format=\"yy-mm-dd\"\n" +
            "      class=\"form-control\"\n" +
            "      ui-date=\"minDateFormat\"\n" +
            "      ng-model=\"minDate\"\n" +
            "      ng-change=\"updateMinDate()\"\n" +
            "      placeholder=\"Start Redemption Date\"\n" +
            "    />\n" +
            "  </div>\n" +
            "  <div class=\"col-md-3\">\n" +
            "    <i class=\"fa fa-calendar\" aria-hidden=\"true\"></i>\n" +
            "    <div class=\"heading-6 redemptions-dashboard-heading\">End Date</div>\n" +
            "    <input\n" +
            "      name=\"endDate\"\n" +
            "      autocomplete=\"off\"\n" +
            "      type=\"text\"\n" +
            "      ui-date=\"maxDateFormat\"\n" +
            "      ui-date-format=\"yy-mm-dd\"\n" +
            "      class=\"form-control\"\n" +
            "      ng-model=\"maxDate\"\n" +
            "      ng-change=\"updateMaxDate()\"\n" +
            "      placeholder=\"End Redemption Date\"/>\n" +
            "  </div>\n" +
            "  <paf-administration-notification message=\"msg\"></paf-administration-notification>\n" +
            "\n" +
            "  <redemption-list\n" +
            "    ng-if=\"fundsReady || fundType == 'pe'\">\n" +
            "  </redemption-list>\n" +
            "\n" +
            "</div>");

        $templateCache.put("areas/paf_admin/tabs/common/redemptions/pages/redemptions/dialogs/cancel_redemption/cancel_redemption.html", "<common-modal modal-header=\"Cancel Redemption\">\n" +
            "  <div>\n" +
            "    <div class=\"row more-head-room form-group\">\n" +
            "      <label class=\"send-reminder-text\">\n" +
            "        Please confirm you want to cancel this Redemption. This action cannot be undone.\n" +
            "      </label>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"row\">\n" +
            "    <button class=\"pull-right\" ng-click=\"ok()\">Confirm</button>\n" +
            "    <button class=\"cancel-btn pull-right\" ng-click=\"cancel()\">Cancel</button>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/paf_admin/tabs/common/redemptions/pages/redemptions/dialogs/set_hold_back_wire_date/set_hold_back_wire_date.html", "<common-modal modal-header=\"Set Hold Back Wire Date for Redemption\">\n" +
            "    <div>\n" +
            "        <form class=\"form-horizontal\">\n" +
            "            <div class=\"form-group\">\n" +
            "                <p>Enter the new wire date.</p>\n" +
            "                <input name=\"wire_date\" type=\"text\" ui-date=\"dateOptions\" class=\"form-control\" ng-model=\"redemption.hold_back_wire_date\" />\n" +
            "            </div>\n" +
            "        </form>\n" +
            "    </div>\n" +
            "    <div class=\"row\">\n" +
            "        <button class=\"pull-right\" ng-click=\"save()\">Save</button>\n" +
            "    </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/paf_admin/tabs/common/redemptions/pages/redemptions/dialogs/set_value_date/set_value_date.html", "<common-modal modal-header=\"Set Effective Date for Redemption\">\n" +
            "    <div>\n" +
            "        <form class=\"form-horizontal\">\n" +
            "            <div class=\"modal-form form-group\">\n" +
            "                <p>Enter the new effective date.</strong></p>\n" +
            "                <input name=\"value_date\" type=\"text\" ui-date=\"dateOptions\" class=\"form-control\" ng-model=\"redemption.value_date\" />\n" +
            "            </div>\n" +
            "        </form>\n" +
            "    </div>\n" +
            "    <div class=\"row\">\n" +
            "        <button class=\"pull-right\" ng-click=\"save()\">Save</button>\n" +
            "    </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/paf_admin/tabs/common/redemptions/pages/redemptions/dialogs/set_wire_date/set_wire_date.html", "<common-modal modal-header=\"Set Wire Date for Redemption\">\n" +
            "    <div>\n" +
            "        <form class=\"form-horizontal\">\n" +
            "            <div class=\"form-group\">\n" +
            "                <p>Enter the new wire date.</p>\n" +
            "                <input name=\"wire_date\" type=\"text\" ui-date=\"dateOptions\" class=\"form-control\" ng-model=\"redemption.wire_date\" />\n" +
            "            </div>\n" +
            "        </form>\n" +
            "    </div>\n" +
            "    <div class=\"row\">\n" +
            "        <button class=\"pull-right\" ng-click=\"save()\">Save</button>\n" +
            "    </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/paf_admin/tabs/common/redemptions/pages/redemptions/redemptions.html", "<div class=\"redemptions hf-content\">\n" +
            "    <div class=\"row hf-fund-select-row\">\n" +
            "      <div class=\"col-md-3\">\n" +
            "        <icn-select\n" +
            "          id=\"selectFundById\"\n" +
            "          label=\"Select a fund\"\n" +
            "          option-label=\"name\"\n" +
            "          ng-model=\"selectedFund\"\n" +
            "          on-change=\"selectId(object.id)\"\n" +
            "          options=\"funds\">\n" +
            "        </icn-select>\n" +
            "      </div>\n" +
            "      <div class=\"col-md-3\" ng-if=\"fundType == 'hf' && !hideFundGroups\">\n" +
            "        <icn-select\n" +
            "          id=\"selectFundByGroup\"\n" +
            "          label=\"Select a fund group\"\n" +
            "          option-label=\"name\"\n" +
            "          ng-model=\"selectedGroup\"\n" +
            "          on-change=\"selectGroup(object.id)\"\n" +
            "          options=\"fundGroups\">\n" +
            "        </icn-select>\n" +
            "      </div>\n" +
            "      <div class=\"col-md-3\">\n" +
            "        <i class=\"fa fa-calendar\" aria-hidden=\"true\"></i>\n" +
            "        <div class=\"heading-6 redemptions-dashboard-heading\">Start Date</div>\n" +
            "        <input\n" +
            "          name=\"startDate\"\n" +
            "          autocomplete=\"off\"\n" +
            "          type=\"text\"\n" +
            "          ui-date-format=\"yy-mm-dd\"\n" +
            "          class=\"form-control\"\n" +
            "          ui-date=\"minDateFormat\"\n" +
            "          ng-model=\"minDate\"\n" +
            "          ng-change=\"updateMinDate()\"\n" +
            "          placeholder=\"Start Redemption Date\"\n" +
            "        />\n" +
            "      </div>\n" +
            "      <div class=\"col-md-3\">\n" +
            "        <i class=\"fa fa-calendar\" aria-hidden=\"true\"></i>\n" +
            "        <div class=\"heading-6 redemptions-dashboard-heading\">End Date</div>\n" +
            "        <input\n" +
            "          name=\"endDate\"\n" +
            "          autocomplete=\"off\"\n" +
            "          type=\"text\"\n" +
            "          ui-date=\"maxDateFormat\"\n" +
            "          ui-date-format=\"yy-mm-dd\"\n" +
            "          class=\"form-control\"\n" +
            "          ng-model=\"maxDate\"\n" +
            "          ng-change=\"updateMaxDate()\"\n" +
            "          placeholder=\"End Redemption Date\"/>\n" +
            "      </div>\n" +
            "        <paf-administration-notification message=\"notification\"\n" +
            "                                         ng-show=\"notification\"></paf-administration-notification>\n" +
            "    </div>\n" +
            "    <div class=\"hf-box\">\n" +
            "        <div class=\"hf-actions mb-1\">\n" +
            "            <i class=\"fa fa-chevron-circle-left\"\n" +
            "               ng-click=\"toRedemptionDashboard()\" id=\"back-to-redemption-dashboard\">&nbsp;BACK</i>\n" +
            "\n" +
            "          <span class=\"pull-right icn-dropdown-actions\">\n" +
            "            <div class=\"btn-group btn-group-sm\">\n" +
            "              <a data-toggle=\"dropdown\" class=\"dropdown-toggle\" ng-click=\"toggleMenu()\">\n" +
            "                <i class=\"redemption-hamburger fa fa-bars\"></i>\n" +
            "              </a>\n" +
            "              <div class=\"dropdown-menu text-left\">\n" +
            "                <ul>\n" +
            "                  <li ng-if=\"canUpdatePafRedemptions()\">\n" +
            "                    <a href=\"#\"\n" +
            "                      ng-click=\"setSelectedValueDate(redemption)\"\n" +
            "                      ng-class=\"{'disable-link-grey': !canSetSelectedValueDateAndWireDate()}\"\n" +
            "                    >Set Effective Date</a>\n" +
            "                  </li>\n" +
            "                  <li ng-if=\"canUpdatePafRedemptions()\">\n" +
            "                    <a href=\"#\"\n" +
            "                      ng-click=\"setSelectedWireDate(redemption)\"\n" +
            "                      ng-class=\"{'disable-link-grey': !canSetSelectedValueDateAndWireDate()}\"\n" +
            "                    >Set Wire Date</a>\n" +
            "                  </li>\n" +
            "                  <li ng-if=\"canUpdatePafRedemptions()\">\n" +
            "                    <a href=\"#\"\n" +
            "                      ng-click=\"setSelectedHoldBackWireDate(redemption)\"\n" +
            "                      ng-class=\"{'disable-link-grey': !canSetSelectedHoldBackWireDate()}\"\n" +
            "                    >Set Hold Back Wire Date</a>\n" +
            "                  </li>\n" +
            "                  <li ng-if=\"canUpdatePafRedemptions()\">\n" +
            "                    <a href=\"#\" ng-click=\"submitBulkApprovals()\"\n" +
            "                      ng-class=\"{'disable-link-grey': !canSubmitBulkApprovals()}\"\n" +
            "                    >Submit Approval</a>\n" +
            "                  </li>\n" +
            "                  <li>\n" +
            "                    <a href=\"#\"\n" +
            "                      ng-csv=\"redemptions\"\n" +
            "                      csv-header=\"['ID', 'Investor ID', 'Fund Name', 'Investor Name', 'Firm', 'Request Date', 'Effective Date', 'Full', 'Nav', 'Nav Date', 'Redemption Request', 'Hold Back %', 'Net Wire', 'Wire Date', 'Status']\"\n" +
            "                      csv-column-order=\"['id','investor_id', 'fund_name', 'investor_name', 'ria_firm_name', 'redeem_date','value_date','full','reportable_amount','reportable_amount_as_of_date','redemption_request','hold_back',\n" +
            "                        'net_wired', 'wire_date','status']\"\n" +
            "                      filename=\"redemptions.csv\"\n" +
            "                    >Export csv</a>\n" +
            "                  </li>\n" +
            "                </ul>\n" +
            "              </div>\n" +
            "            </div>\n" +
            "          </span>\n" +
            "        </div>\n" +
            "        <div class=\"header-bar\"></div>\n" +
            "        <div class=\"investmentList full-width\">\n" +
            "            <table class=\"table table-hover\">\n" +
            "                <thead>\n" +
            "                <tr class=\"investmentList-columnsHeader\">\n" +
            "                    <th>\n" +
            "                        <input type=\"checkbox\" ng-model=\"allSelected\" ng-change=\"selectAll()\"/>\n" +
            "                    </th>\n" +
            "                    <th ng-click=\"orderBy('fund_name')\">\n" +
            "                      FUND NAME\n" +
            "                      <div ng-if=\"orderByField=='fund_name'\" class=\"arrow-wrap\">\n" +
            "                        <span ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\"></span>\n" +
            "                      </div>\n" +
            "                    </th>\n" +
            "                    <th ng-click=\"orderBy('redeem_date')\">\n" +
            "                        REQUEST DATE\n" +
            "                        <div ng-if=\"orderByField=='redeem_date'\" class=\"arrow-wrap\">\n" +
            "                          <span ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\"></span>\n" +
            "                        </div>\n" +
            "                    </th>\n" +
            "                    <th ng-click=\"orderBy('requestor')\">\n" +
            "                        REQUESTOR\n" +
            "                        <div ng-if=\"orderByField=='requestor'\" class=\"arrow-wrap\">\n" +
            "                          <span ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\"></span>\n" +
            "                        </div>\n" +
            "                    </th>\n" +
            "                    <th ng-click=\"orderBy('value_date')\">\n" +
            "                        EFFECTIVE DATE\n" +
            "                        <div ng-if=\"orderByField=='value_date'\" class=\"arrow-wrap\">\n" +
            "                          <span ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\"></span>\n" +
            "                        </div>\n" +
            "                    </th>\n" +
            "                    <th ng-click=\"orderBy('investor_id')\">\n" +
            "                        INVESTOR ID\n" +
            "                        <div ng-if=\"orderByField=='investor_id'\" class=\"arrow-wrap\">\n" +
            "                          <span ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\"></span>\n" +
            "                        </div>\n" +
            "                    </th>\n" +
            "                    <th ng-click=\"orderBy('investor_name')\">\n" +
            "                        INVESTOR NAME\n" +
            "                        <div ng-if=\"orderByField=='investor_name'\" class=\"arrow-wrap\">\n" +
            "                          <span ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\"></span>\n" +
            "                        </div>\n" +
            "                    </th>\n" +
            "                    <th ng-click=\"orderBy('investor_custodian_name')\">\n" +
            "                        CUSTODIAN NAME\n" +
            "                        <div ng-if=\"orderByField=='investor_custodian_name'\" class=\"arrow-wrap\">\n" +
            "                          <span ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\"></span>\n" +
            "                        </div>\n" +
            "                    </th>\n" +
            "                    <th ng-click=\"orderBy('ria_firm_name')\">\n" +
            "                        FIRM\n" +
            "                        <div ng-if=\"orderByField=='ria_firm_name'\" class=\"arrow-wrap\">\n" +
            "                          <span ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\"></span>\n" +
            "                        </div>\n" +
            "                    </th>\n" +
            "                    <th ng-click=\"orderBy('full')\">\n" +
            "                        FULL/PARTIAL\n" +
            "                        <div ng-if=\"orderByField=='full'\" class=\"arrow-wrap\">\n" +
            "                          <span ng-class=\"{'arrow-up':reverseSort, 'arrow-down':!reverseSort}\"></span>\n" +
            "                        </div>\n" +
            "                    </th>\n" +
            "                    <th ng-click=\"orderBy('nav')\">\n" +
            "                        {{reportableAmountType}}\n" +
            "                        <div ng-if=\"orderByField=='nav'\" class=\"arrow-wrap\">\n" +
            "                          <span ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\"></span>\n" +
            "                        </div>\n" +
            "                    </th>\n" +
            "                    <th ng-click=\"orderBy('nav_date')\">\n" +
            "                        {{reportableAmountType}} DATE\n" +
            "                        <div ng-if=\"orderByField=='nav_date'\" class=\"arrow-wrap\">\n" +
            "                          <span ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\"></span>\n" +
            "                        </div>\n" +
            "                    </th>\n" +
            "                    <th ng-click=\"orderBy('redemption_request')\">\n" +
            "                        REDEMPTION REQUEST\n" +
            "                        <div ng-if=\"orderByField=='redemption_request'\" class=\"arrow-wrap\">\n" +
            "                          <span ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\"></span>\n" +
            "                        </div>\n" +
            "                    </th>\n" +
            "                    <th ng-click=\"orderBy('hold_back')\">\n" +
            "                        HOLD BACK %\n" +
            "                        <div ng-if=\"orderByField=='hold_back'\" class=\"arrow-wrap\">\n" +
            "                          <span ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\"></span>\n" +
            "                        </div>\n" +
            "                    </th>\n" +
            "                    <th ng-click=\"orderBy('net_wired')\">\n" +
            "                        NET WIRE\n" +
            "                        <div ng-if=\"orderByField=='net_wired'\" class=\"arrow-wrap\">\n" +
            "                          <span ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\"></span>\n" +
            "                        </div>\n" +
            "                    </th>\n" +
            "                    <th ng-click=\"orderBy('hold_back_wire_date')\">\n" +
            "                        HOLD BACK WIRE DATE\n" +
            "                        <div ng-if=\"orderByField=='hold_back_wire_date'\" class=\"arrow-wrap\">\n" +
            "                          <span ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\"></span>\n" +
            "                        </div>\n" +
            "                    </th>\n" +
            "                    <th ng-click=\"orderBy('wire_date')\">\n" +
            "                        WIRE DATE\n" +
            "                        <div ng-if=\"orderByField=='wire_date'\" class=\"arrow-wrap\">\n" +
            "                          <span ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\"></span>\n" +
            "                        </div>\n" +
            "                    </th>\n" +
            "                    <th ng-click=\"orderBy('display_status')\">\n" +
            "                        STATUS\n" +
            "                        <div ng-if=\"orderByField=='display_status'\" class=\"arrow-wrap\">\n" +
            "                          <span ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\"></span>\n" +
            "                        </div>\n" +
            "                    </th>\n" +
            "                    <th ng-click=\"orderBy('approver')\">\n" +
            "                        APPROVER\n" +
            "                        <div ng-if=\"orderByField=='approver'\" class=\"arrow-wrap\">\n" +
            "                          <span ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\"></span>\n" +
            "                        </div>\n" +
            "                    </th>\n" +
            "                    <th></th>\n" +
            "                </tr>\n" +
            "                </thead>\n" +
            "                <tbody>\n" +
            "                <tr class=\"investmentList-investmentItem\"\n" +
            "                    ng-repeat=\"redemption in redemptions|orderBy:orderByField:reverseSort\">\n" +
            "                    <td>\n" +
            "                        <input type=\"checkbox\" ng-model=\"redemption.selected\"/>\n" +
            "                    </td>\n" +
            "                    <td>\n" +
            "                      <div class=\"form-group\">\n" +
            "                        <span>{{redemption.fund_name}}</span>\n" +
            "                      </div>\n" +
            "                    </td>\n" +
            "                    <td>\n" +
            "                        <div class=\"form-group\">\n" +
            "                            <span>{{(redemption.redeem_date | date: 'MM/dd/yyyy') || \"-\" }}</span>\n" +
            "                        </div>\n" +
            "                    </td>\n" +
            "                    <td>{{redemption.requestor}}</td>\n" +
            "                    <td>\n" +
            "                        <div class=\"form-group\">\n" +
            "                            <span>{{(redemption.value_date | date: 'MM/dd/yyyy') || \"-\" }}</span>\n" +
            "                        </div>\n" +
            "                    </td>\n" +
            "                    <td>\n" +
            "                        {{redemption.investor_id}}\n" +
            "                    </td>\n" +
            "                    <td>\n" +
            "                      <a href =\"#\" ng-click=\"investorProfile(redemption)\">{{redemption.investor_name}}</a>\n" +
            "                    </td>\n" +
            "                    <td>\n" +
            "                        {{redemption.investor.custodian_name}}\n" +
            "                    </td>\n" +
            "                    <td>{{redemption.ria_firm_name}}</td>\n" +
            "                    <td>{{redemption.full ? \"Full\" : \"Partial\"}}</td>\n" +
            "                    <td>{{redemption.reportable_amount | noFractionCurrency}}</td>\n" +
            "                    <td>{{(redemption.reportable_amount_as_of_date | date: 'MM/dd/yyyy') || \"-\" }}</td>\n" +
            "                    <td>{{redemption.redemption_request | noFractionCurrency}}</td>\n" +
            "                    <td>{{redemption.hold_back * 100 | number : 2}}%</td>\n" +
            "                    <td>{{redemption.net_wired | noFractionCurrency}}</td>\n" +
            "                    <td>\n" +
            "                      <div class=\"form-group\">\n" +
            "                          <span>{{(redemption.hold_back_wire_date | date: 'MM/dd/yyyy') || \"-\" }}</span>\n" +
            "                      </div>\n" +
            "                    </td>\n" +
            "                    <td>\n" +
            "                      <div class=\"form-group\">\n" +
            "                          <span>{{(redemption.wire_date | date: 'MM/dd/yyyy') || \"-\" }}</span>\n" +
            "                      </div>\n" +
            "                    </td>\n" +
            "                    <td>{{redemption.display_status | titleCase}}</td>\n" +
            "                    <td>{{redemption.approver}}</td>\n" +
            "                    <td class=\"no-sort icn-dropdown-actions\">\n" +
            "                      <div ng-if=\"redemption.status != 'paid'\" class=\"btn-group btn-group-sm\" ng-class=\"{'open': redemption.menuOpen}\">\n" +
            "                        <a data-toggle=\"dropdown\" class=\"dropdown-toggle\" ng-click=\"toggleMenu()\">\n" +
            "                          <img src=\"images/options_dark.png\">\n" +
            "                        </a>\n" +
            "                        <div class=\"dropdown-menu text-left\">\n" +
            "                          <ul>\n" +
            "                            <li ng-if=\"canUpdatePafRedemptions()\">\n" +
            "                              <a href=\"#\" ng-click=\"setValueDate(redemption)\">Set Effective Date</a>\n" +
            "                            </li>\n" +
            "                            <li ng-if=\"canUpdatePafRedemptions()\">\n" +
            "                              <a href=\"#\" ng-click=\"setWireDate(redemption)\">Set Wire Date</a>\n" +
            "                            </li>\n" +
            "                            <li ng-if=\"canSetHoldBackWireDate(redemption)\">\n" +
            "                              <a href=\"#\" ng-click=\"setHoldBackWireDate(redemption)\">Set Hold Back Wire Date</a>\n" +
            "                            </li>\n" +
            "                            <li>\n" +
            "                              <a href=\"#\" ng-click=\"downloadAgreement(redemption)\">View Redemption Document</a>\n" +
            "                            </li>\n" +
            "                            <li ng-if=\"canSubmitApproval(redemption)\">\n" +
            "                              <a href=\"#\" ng-click=\"submitApproval(redemption)\" class=\"submit-approval-link\">Submit Approval</a>\n" +
            "                            </li>\n" +
            "                            <li ng-if=\"canCancelRedemption(redemption)\">\n" +
            "                              <a href=\"#\" ng-click=\"cancelRedemption(redemption)\" class=\"cancel-request-link\">Cancel Request</a>\n" +
            "                            </li>\n" +
            "                          </ul>\n" +
            "                        </div>\n" +
            "                      </div>\n" +
            "                    </td>\n" +
            "                </tr>\n" +
            "                </tbody>\n" +
            "\n" +
            "                <tfoot>\n" +
            "                <tr>\n" +
            "                    <td colspan=\"8\"><strong>Total</strong></td>\n" +
            "                    <td><strong>{{totals.nav | noFractionCurrency}}</strong></td>\n" +
            "                    <td></td>\n" +
            "                    <td></td>\n" +
            "                    <td></td>\n" +
            "                    <td><strong>{{totals.withdrawals | noFractionCurrency}}</strong></td>\n" +
            "                    <td></td>\n" +
            "                    <td><strong>{{totals.netWired | noFractionCurrency}}</strong></td>\n" +
            "                    <td></td>\n" +
            "                    <td></td>\n" +
            "                    <td></td>\n" +
            "                </tr>\n" +
            "                </tfoot>\n" +
            "            </table>\n" +
            "        </div>\n" +
            "    </div>\n" +
            "</div>");

        $templateCache.put("areas/paf_admin/tabs/hf/funds/pages/funds/components/admin_fund_documents/admin_fund_documents.html", "<div class=\"col-md-12\"></div>\n" +
            "  <div class=\"fund-document-upload\">\n" +
            "    <div class=\"row\">\n" +
            "      <div class=\"col-md-3 no-left-padding\">\n" +
            "        <select name=\"upload-document-type\" ng-model=\"fundDocument.documentType\" class=\"form-control\" ng-change=\"selectType()\">\n" +
            "          <option value=\"\">-- Select Type --</option>\n" +
            "          <option value=\"MonthlyReport\" ng-if=\"isOpenEndedFund()\">Monthly Report</option>\n" +
            "          <option value=\"AdminFundLegalDocument\">Legal Document</option>\n" +
            "        </select>\n" +
            "      </div>\n" +
            "      <div class=\"col-md-3\" ng-if=\"showDatePicker()\">\n" +
            "        <icn-date-picker ng-model=\"fundDocument.reportDate\" errors=\"fundDocument.dateErrors\"></icn-date-picker>\n" +
            "        <label>Report Date</label>\n" +
            "      </div>\n" +
            "      <div class=\"col-md-3\">\n" +
            "        <label class=\"file-upload-label btn btn-blue\"\n" +
            "               role=\"button\" ng-disabled=\"disableUploadButton()\">\n" +
            "          Upload File\n" +
            "          <input type=\"file\" id=\"admin-fund-document-file\" ng-file-select=\"uploadDocument($files)\">\n" +
            "        </label>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "    <div class=\"notifications-checkbox-container\">\n" +
            "      <input class=\"send-notifications-checkbox\" type=\"checkbox\" name=\"send-upload-notifications\" id=\"upload-notifications-chkbox\" ng-model=\"sendUploadNotifications\">\n" +
            "      <label for=\"send-upload-notifications\">Send notifications to investors\n" +
            "      </label>\n" +
            "    </div>\n" +
            "          <span ng-if=\"uploadSuccess\" class=\"text-success upload-status-msg\">\n" +
            "            File uploaded successfully.\n" +
            "          </span>\n" +
            "          <span ng-if=\"uploadError\" class=\"error upload-status-msg\">\n" +
            "            There was an error uploading the document.  Please try again.\n" +
            "          </span>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/paf_admin/tabs/hf/funds/pages/funds/funds.html", "<icn-paf-admin-nav type=\"hf\"></icn-paf-admin-nav>\n" +
            "\n" +
            "<icn-fund-selector fund-type=\"hf\" on-select-changed=\"vm.onSelectedFundChanged\"></icn-fund-selector>\n" +
            "\n" +
            "<div ng-show=\"vm.fund\" class=\"row\">\n" +
            "  <share-classes-table fund=\"vm.fund\"></share-classes-table>\n" +
            "  <div class=\"row vertical-margin\">\n" +
            "    <div class=\"col-md-12\">\n" +
            "      <a ng-click=\"vm.edit()\" ng-show=\"vm.fund\" class=\"btn btn-blue\">\n" +
            "        {{vm.editText()}}\n" +
            "      </a>\n" +
            "      <button ng-show=\"vm.editMode\" ng-click=\"vm.save()\" class=\"btn btn-blue pull-right\">Save</button>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "\n" +
            "  <paf-administration-notification message=\"errorMessage\" ng-show=\"errorMessage\"></paf-administration-notification>\n" +
            "\n" +
            "  <div class=\"col-md-12\">\n" +
            "    <table class=\"icn-table\">\n" +
            "      <thead>\n" +
            "        <tr>\n" +
            "          <th>Property</th>\n" +
            "          <th>Value</th>\n" +
            "        </tr>\n" +
            "      </thead>\n" +
            "      <tbody>\n" +
            "        <tr>\n" +
            "          <td>\n" +
            "            ID\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            {{ vm.fund.id }}\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>\n" +
            "            Name\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            {{ vm.fund.name }}\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>Short name</td>\n" +
            "          <td>\n" +
            "            <div class=\"form-group\">\n" +
            "              <input name=\"short_name\" type=\"text\" class=\"form-control\" ng-model=\"vm.fund.short_name\" ng-disabled=\"!vm.editable()\" />\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>Quarterly Report Cover Name</td>\n" +
            "          <td>\n" +
            "            <div class=\"form-group\">\n" +
            "              <input name=\"quarterly_report_cover_name\" type=\"text\" class=\"form-control\" ng-model=\"vm.fund.quarterly_report_cover_name\" ng-disabled=\"!vm.editable()\"/>\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>\n" +
            "            DTCC Information\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <div>\n" +
            "              <div class=\"form-group\">\n" +
            "                <label>DTCC Fund Number</label>\n" +
            "                <input id=\"dtcc_fund_number\" type=\"text\" class=\"form-control\" ng-model=\"vm.fund.dtcc_fund_number\" ng-disabled=\"!vm.editable()\" />\n" +
            "              </div>\n" +
            "              <div class=\"form-group\">\n" +
            "                <label>DTCC NSCC Security Issue Number</label>\n" +
            "                <input id=\"dtcc_nscc_security_issue_number\" type=\"text\" class=\"form-control\" ng-model=\"vm.fund.dtcc_nscc_security_issue_number\" ng-disabled=\"!vm.editable()\" />\n" +
            "              </div>\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>Approved Custodians</td>\n" +
            "          <td>\n" +
            "            <div class=\"form-group\">\n" +
            "              <table class=\"table table-default\">\n" +
            "                <thead>\n" +
            "                <th>Custodian</th>\n" +
            "                <th>Share Class</th>\n" +
            "                <th>Cusip</th>\n" +
            "                <th></th>\n" +
            "                </thead>\n" +
            "                <tr ng-repeat=\"appCus in vm.fund.custodian_approved_pafs\">\n" +
            "                  <td>\n" +
            "                    <select name=\"select-custodian\"\n" +
            "                            ng-model=\"appCus.custodian_id\"\n" +
            "                            ng-hide=\"appCus.name\"\n" +
            "                            class=\"pull-left\">\n" +
            "                      <option value=\"\" disabled selected>Select a Custodian</option>\n" +
            "                      <option ng-value=\"cust.id\"\n" +
            "                              ng-repeat=\"cust in vm.custodians\">\n" +
            "                        {{cust.name}}\n" +
            "                      </option>\n" +
            "                    </select>\n" +
            "                    <span ng-show=\"appCus.name\">{{appCus.name}}</span>\n" +
            "                  </td>\n" +
            "                  <td ng-show=\"vm.showInvestmentShareClassSelectBox()\">\n" +
            "                    <select name=\"select-investment-share-class\"\n" +
            "                            ng-model=\"appCus.investment_share_class_id\"\n" +
            "                            ng-hide=\"appCus.name || vm.emptyInvestmentShareClasses()\"\n" +
            "                            class=\"pull-left\">\n" +
            "                      <option value=\"\" disabled selected>Select an Investment Share Class</option>\n" +
            "                      <option ng-value=\"investment_share_class.id\"\n" +
            "                              ng-repeat=\"investment_share_class in vm.fund.investment_share_classes\">\n" +
            "                        {{investment_share_class.name}}\n" +
            "                      </option>\n" +
            "                    </select>\n" +
            "                    <span ng-show=\"appCus.name\">{{appCus.investment_share_class_name}}</span>\n" +
            "                  </td>\n" +
            "                  <td ng-hide=\"showInvestmentShareClassSelectBox()\">\n" +
            "                    <select name=\"select-sdd\"\n" +
            "                            ng-model=\"appCus.subscription_document_definition_id\"\n" +
            "                            ng-hide=\"appCus.name || vm.emptySDDS()\"\n" +
            "                            class=\"pull-left\">\n" +
            "                      <option value=\"\" disabled selected>Select a Share Class</option>\n" +
            "                      <option ng-value=\"sdd.id\"\n" +
            "                              ng-repeat=\"sdd in vm.fund.subscription_document_definitions_with_descriptions\">\n" +
            "                        {{sdd.subscription_document_description}}\n" +
            "                      </option>\n" +
            "                    </select>\n" +
            "                    <span ng-show=\"appCus.name\">{{appCus.share_class_name}}</span>\n" +
            "                  </td>\n" +
            "                  <td>\n" +
            "                    <input name=\"custodian-cusip\"\n" +
            "                           type=\"text\"\n" +
            "                           ng-trim=\"true\"\n" +
            "                           ng-model=\"appCus.cusip\"\n" +
            "                           class=\"form-control pull-left\"\n" +
            "                           ng-disabled=\"!vm.canEditFundCustodians()\" />\n" +
            "                  </td>\n" +
            "                  <td ng-show=\"vm.canEditFundCustodians()\">\n" +
            "                    <div class=\"icon-container\" ng-click=\"vm.removeApprovedCustodian($index)\">\n" +
            "                      <span class=\"ui-icon ui-icon-minus\"></span>\n" +
            "                    </div>\n" +
            "                  </td>\n" +
            "                </tr>\n" +
            "              </table>\n" +
            "            </div>\n" +
            "            <div class=\"icon-container\"\n" +
            "                 ng-show=\"vm.canEditFundCustodians()\"\n" +
            "                 ng-click=\"vm.addApprovedCustodian()\">\n" +
            "              <a href=\"#\">\n" +
            "                <span class=\"pull-left ui-icon ui-icon-plus\"></span> Add Approved Custodian\n" +
            "              </a>\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>\n" +
            "            PAF Admin Blacklist\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <div class=\"checkbox\">\n" +
            "              <label>\n" +
            "                <input class=\"success\" id=\"blacklisted\" type=\"checkbox\" ng-model=\"vm.fund.blacklisted\" ng-disabled=\"!vm.editMode\" />\n" +
            "              </label>\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>Investor gate</td>\n" +
            "          <td>\n" +
            "            <div class=\"form-group\">\n" +
            "              <input ui-percentage-mask name=\"investor_gate\" type=\"text\" class=\"form-control\" ng-model=\"vm.fund.paf_terms.investor_gate\" ng-disabled=\"!vm.editable()\" />\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>Investor gate penalty</td>\n" +
            "          <td>\n" +
            "            <div class=\"form-group\">\n" +
            "              <input ui-percentage-mask name=\"investor_gate_penalty\" type=\"text\" class=\"form-control\" ng-model=\"vm.fund.paf_terms.investor_gate_penalty\" ng-disabled=\"!vm.editable()\" />\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>Fund gate</td>\n" +
            "          <td>\n" +
            "            <div class=\"form-group\">\n" +
            "              <input ui-percentage-mask name=\"fund_gate\" type=\"text\" class=\"form-control\" ng-model=\"vm.fund.paf_terms.fund_gate\" ng-disabled=\"!vm.editable()\" />\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>Fund gate penalty</td>\n" +
            "          <td>\n" +
            "            <div class=\"form-group\">\n" +
            "              <input ui-percentage-mask name=\"fund_gate_penalty\" type=\"text\" class=\"form-control\" ng-model=\"vm.fund.paf_terms.fund_gate_penalty\" ng-disabled=\"!vm.editable()\" />\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>Number of Lock-ups</td>\n" +
            "          <td>\n" +
            "            <div class=\"form-group\">\n" +
            "              <input name=\"lock_up\" type=\"text\" class=\"form-control\" ng-model=\"vm.fund.paf_terms.lock_up\" ng-disabled=\"!vm.editable()\" />\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>Length of each lock-up</td>\n" +
            "          <td>\n" +
            "            <div class=\"form-group\">\n" +
            "              <input name=\"lock_up_period\" type=\"text\" class=\"form-control\" ng-model=\"vm.fund.paf_terms.lock_up_period\" ng-disabled=\"!vm.editable()\" />\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>Lock-up penalty</td>\n" +
            "          <td>\n" +
            "            <div class=\"form-group\">\n" +
            "              <input ui-percentage-mask name=\"lock_up_penalty\" type=\"text\" class=\"form-control\" ng-model=\"vm.fund.paf_terms.lock_up_penalty\" ng-disabled=\"!vm.editable()\" />\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>Redemption frequency</td>\n" +
            "          <td>\n" +
            "            <div class=\"form-group\">\n" +
            "              <div class=\"row\">\n" +
            "                <div class=\"col-md-2 no-padding\">\n" +
            "                  <select name=\"frequency-options\" ng-options=\"frequency.id as frequency.label for frequency in vm.redemptionFrequencyOptions\" ng-model=\"vm.fund.paf_terms.redemption_frequency\" ng-disabled=\"!vm.editable()\"></select>\n" +
            "                </div>\n" +
            "                  <div class=\"col-md-2 no-padding\"><label>Select Close Date</label></div>\n" +
            "                  <div class=\"col-md-2 no-padding\" ng-show=\"vm.fund.paf_terms.redemption_frequency == 'annually'\">\n" +
            "                      <input name=\"redemption_date\" type=\"text\" ui-date=\"vm.dateOptions\" ui-date-format=\"mm/dd/yy\" class=\"form-control\" ng-model=\"vm.fund.paf_terms.redemption_date\" ng-disabled=\"!vm.editable()\" />\n" +
            "                </div>\n" +
            "              </div>\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>Minimum Redemption Amount</td>\n" +
            "          <td>\n" +
            "            <div class=\"form-group\">\n" +
            "              <input\n" +
            "                ui-money-mask\n" +
            "                name=\"minimum_redemption_amount\"\n" +
            "                type=\"text\"\n" +
            "                class=\"form-control\"\n" +
            "                ng-model=\"vm.fund.paf_terms.minimum_redemption_amount\"\n" +
            "                ng-disabled=\"!vm.editable()\"\n" +
            "              />\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>Manager notice period</td>\n" +
            "          <td>\n" +
            "            <div class=\"form-group\">\n" +
            "              <div class=\"row\">\n" +
            "                <div class=\"col-md-8 no-padding\">\n" +
            "                  <input name=\"manager_notice_period\" type=\"number\" class=\"form-control\" ng-model=\"vm.fund.paf_terms.manager_notice_period\" ng-disabled=\"!vm.editable()\" />\n" +
            "                </div>\n" +
            "                <div class=\"col-md-4\">\n" +
            "                  business days\n" +
            "                </div>\n" +
            "              </div>\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>Investor notice period</td>\n" +
            "          <td>\n" +
            "            <div class=\"form-group\">\n" +
            "              <div class=\"row\">\n" +
            "                <div class=\"col-md-8 no-padding\">\n" +
            "                  <input name=\"investor_notice_period\" type=\"number\" class=\"form-control\" ng-model=\"vm.fund.paf_terms.investor_notice_period\" ng-disabled=\"!vm.editable()\" />\n" +
            "                </div>\n" +
            "                <div class=\"col-md-4\">\n" +
            "                  business days\n" +
            "                </div>\n" +
            "              </div>\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>Audit holdback</td>\n" +
            "          <td>\n" +
            "            <div class=\"form-group\">\n" +
            "              <input ui-percentage-mask name=\"audit_holdback\" type=\"text\" class=\"form-control\" ng-model=\"vm.fund.paf_terms.audit_holdback\" ng-disabled=\"!vm.editable()\" />\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>NFA/CPO Exemption</td>\n" +
            "          <td>\n" +
            "            <div class=\"form-group\">\n" +
            "              <input name=\"nfa_cpo_exemption\" type=\"text\" class=\"form-control\" ng-model=\"vm.fund.paf_terms.nfa_cpo_exemption\" ng-disabled=\"!vm.editable()\" />\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>NFA Pool ID</td>\n" +
            "          <td>\n" +
            "            <div class=\"form-group\">\n" +
            "              <input name=\"nfa_pool_id\" type=\"text\" class=\"form-control\" ng-model=\"vm.fund.paf_terms.nfa_pool_id\" ng-disabled=\"!vm.editable()\" />\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>Liquidation date</td>\n" +
            "          <td>\n" +
            "            <div class=\"form-group\">\n" +
            "              <input name=\"liquidation_date\" type=\"text\" ui-date=\"vm.dateOptions\" ui-date-format=\"mm/dd/yy\" class=\"form-control\" ng-model=\"vm.fund.paf_terms.liquidation_date\" ng-disabled=\"!vm.editable()\" />\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>Tax ID / EIIN</td>\n" +
            "          <td>\n" +
            "            <div class=\"form-group\">\n" +
            "              <input name=\"tax_id_eiin\" type=\"text\" class=\"form-control\" ng-model=\"vm.fund.paf_terms.tax_id_eiin\" ng-disabled=\"!vm.editable()\" />\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>GIIN</td>\n" +
            "          <td>\n" +
            "            <div class=\"form-group\">\n" +
            "              <input name=\"giin\" type=\"text\" class=\"form-control\" ng-model=\"vm.fund.paf_terms.giin\" ng-disabled=\"!vm.editable()\" />\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>\n" +
            "            Detailed PCAP Report\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <div class=\"checkbox\">\n" +
            "              <label>\n" +
            "                <input class=\"success\" id=\"detailed_pcap_report\" type=\"checkbox\" ng-model=\"vm.fund.detailed_pcap_report\" ng-disabled=\"!vm.editMode\" />\n" +
            "              </label>\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>\n" +
            "            Billing Method\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <div class=\"form-group\">\n" +
            "              <label>\n" +
            "                <select class=\"form-control\" id=\"billing_calc_method\" ng-model=\"vm.fund.billing_calc_method\" ng-disabled=\"!vm.editMode\">\n" +
            "                  <option>Committed Capital</option>\n" +
            "                  <option>Invested Capital</option>\n" +
            "                </select>\n" +
            "              </label>\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>\n" +
            "            Unitized\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <div class=\"checkbox\">\n" +
            "              <label>\n" +
            "                <input class=\"success\" id=\"unitized\" type=\"checkbox\" ng-model=\"vm.fund.unitized\" ng-disabled=\"!vm.editMode\" />\n" +
            "              </label>\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>\n" +
            "            DTCC Fund Level Consolidated Reporting\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <div class=\"checkbox\">\n" +
            "              <label>\n" +
            "                <input class=\"success\" id=\"dtcc_fund_level_consolidated_reporting\" type=\"checkbox\" ng-model=\"vm.fund.dtcc_fund_level_consolidated_reporting\" ng-disabled=\"!vm.editMode\" />\n" +
            "              </label>\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>BNY Accounting API</td>\n" +
            "          <td>\n" +
            "            <div class=\"form-group\">\n" +
            "              <input name=\"bny_external_share_class_id\" type=\"text\" class=\"form-control\" ng-model=\"vm.fund.bny_external_share_class_id\" ng-disabled=\"!vm.editable()\" />\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "      </tbody>\n" +
            "    </table>\n" +
            "    <button ng-show=\"vm.editMode\" ng-click=\"vm.save()\" class=\"btn btn-blue pull-right\">Save</button>\n" +
            "    <div ng-include=\"'areas/paf_admin/components/fund_documents.html'\"></div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/paf_admin/tabs/pe/funds/pages/funds/components/financial_document/financial_document.html", "<div class=\"col-md-12\">\n" +
            "  <div class=\"form-group\">\n" +
            "    <div ng-repeat=\"report in financialDocuments\" class=\"row vertical-margin\">\n" +
            "      <a class=\"col-md-9\" ng-href=\"{{report.url}}\" target=\"_blank\">Financial Statement for ({{report.report_date | date:'yyyy'}})</a>\n" +
            "      <a ng-click=\"deleteFinancialDocument(report)\" class=\"btn btn-blue\">delete</a>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"row vertical-margin\">Add new Financial Statement</div>\n" +
            "  <div class=\"form-group\">\n" +
            "    <div class=\"col-md-12\">\n" +
            "      <strong class=\"padded\">Year:</strong>\n" +
            "      <select style=\"margin-right: 10px; margin-left: 10px;\" name=\"report-year-select\" ng-model=\"financialDocument.reportDate\" ng-options=\"option for option in reportDateChoices()\"></select>\n" +
            "      <label class=\"file-upload-label btn btn-blue\" label=\"upload file\" role=\"button\" ng-disabled=\"dateErrors.hasErrors() || !financialDocument.reportDate\">\n" +
            "        Upload File\n" +
            "        <input type=\"file\" id=\"financial_document_file\" ng-file-select=\"uploadDocument($files)\" onclick=\"this.value=null\" name=\"financial_document_file\"/>\n" +
            "      </label>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <paf-administration-notification message=\"msg\"></paf-administration-notification>\n" +
            "\n" +
            "</div>");

        $templateCache.put("areas/paf_admin/tabs/pe/funds/pages/funds/components/quarterly_reports/quarterly_reports.html", "<div class=\"col-md-12\">\n" +
            "  <div class=\"form-group\">\n" +
            "    <div ng-repeat=\"report in quarterlyReports\" class=\"row vertical-margin\">\n" +
            "      <a class=\"col-md-9\" ng-href=\"{{report.url}}\" target=\"_blank\">Quarterly Report for Period Ending Date ({{report.report_date | date}})</a>\n" +
            "      <a ng-click=\"deleteQuarterlyReportFile(report)\" class=\"btn btn-blue\">delete</a>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"row vertical-margin\">Add new Quarterly Report</div>\n" +
            "  <div class=\"form-group\">\n" +
            "    <label class=\"col-md-5\">Quarterly Report Date</label>\n" +
            "    <icn-date-picker class=\"col-md-3\" ng-model=\"quarterlyReport.report_date\" errors=\"dateErrors\"></icn-date-picker>\n" +
            "    <div class=\"col-md-1\"></div>\n" +
            "    <label class=\"file-upload-label btn btn-blue\" label=\"upload file\" role=\"button\" ng-disabled=\"dateErrors.hasErrors() || !quarterlyReport.report_date\">\n" +
            "      Upload File\n" +
            "      <input type=\"file\" id=\"quarterly_report_upload_file\" ng-file-select=\"uploadQuarterlyReportFile($files)\" onclick=\"this.value=null\" name=\"quarterly_report_file\"/>\n" +
            "    </label>\n" +
            "  </div>\n" +
            "  <paf-administration-notification message=\"msg\"></paf-administration-notification>\n" +
            "\n" +
            "</div>");

        $templateCache.put("areas/paf_admin/tabs/pe/funds/pages/funds/funds.html", "<icn-paf-admin-nav type=\"pe\"></icn-paf-admin-nav>\n" +
            "\n" +
            "<icn-fund-selector fund-type=\"pe\" on-select-changed=\"vm.onSelectedFundChanged\"></icn-fund-selector>\n" +
            "\n" +
            "<div class=\"row vertical-margin\">\n" +
            "  <share-classes-table fund=\"vm.fund\"></share-classes-table>\n" +
            "  <div class=\"col-md-12\">\n" +
            "    <a ng-click=\"vm.edit()\" ng-show=\"vm.fund\" class=\"btn btn-blue\">\n" +
            "    {{vm.editText()}}</a>\n" +
            "  </div>\n" +
            "</div>\n" +
            "\n" +
            "<div ng-show=\"vm.editMode\" class=\"row vertical-margin\">\n" +
            "  <div class=\"col-md-12\">\n" +
            "    <button ng-show=\"vm.editMode\" ng-click=\"vm.save()\" class=\"btn btn-blue pull-right\">Save</button>\n" +
            "  </div>\n" +
            "</div>\n" +
            "\n" +
            "<div ng-show=\"vm.fund\" class=\"row\">\n" +
            "  <div class=\"col-md-12\">\n" +
            "    <table class=\"icn-table\">\n" +
            "      <thead>\n" +
            "        <tr>\n" +
            "          <th>Property</th>\n" +
            "          <th>Value</th>\n" +
            "        </tr>\n" +
            "      </thead>\n" +
            "      <tbody>\n" +
            "        <tr>\n" +
            "          <td>\n" +
            "            ID\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            {{ vm.fund.id }}\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>\n" +
            "            Name\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            {{ vm.fund.name }}\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>Short name</td>\n" +
            "          <td>\n" +
            "            <div class=\"form-group\">\n" +
            "              <input name=\"short_name\" type=\"text\" class=\"form-control\" ng-model=\"vm.fund.short_name\" ng-disabled=\"!vm.editMode\" />\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>Quarterly Report Cover Name</td>\n" +
            "          <td>\n" +
            "            <div class=\"form-group\">\n" +
            "              <input name=\"quarterly_report_cover_name\" type=\"text\" class=\"form-control\" ng-model=\"vm.fund.quarterly_report_cover_name\" ng-disabled=\"!vm.editMode\"/>\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>Cusip</td>\n" +
            "          <td>\n" +
            "            <div class=\"form-group\">\n" +
            "              <input name=\"cusip\" type=\"text\" ng-trim=\"true\"\n" +
            "                     class=\"form-control\" ng-model=\"vm.fund.cusip\" ng-disabled=\"!vm.editMode\" />\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>Approved Custodians</td>\n" +
            "          <td>\n" +
            "            <div class=\"form-group\">\n" +
            "              <table class=\"table table-default\">\n" +
            "                <thead>\n" +
            "                <th>Custodian</th>\n" +
            "                <th>Share Class</th>\n" +
            "                <th>Cusip</th>\n" +
            "                <th></th>\n" +
            "                </thead>\n" +
            "                <tr ng-repeat=\"appCus in vm.fund.custodian_approved_pafs\">\n" +
            "                  <td>\n" +
            "                    <select name=\"select-custodian\"\n" +
            "                            ng-model=\"appCus.custodian_id\"\n" +
            "                            ng-hide=\"appCus.name\"\n" +
            "                            class=\"pull-left\">\n" +
            "                      <option value=\"\" disabled selected>Select a Custodian</option>\n" +
            "                      <option ng-value=\"cust.id\"\n" +
            "                              ng-repeat=\"cust in vm.custodians\">\n" +
            "                        {{cust.name}}\n" +
            "                      </option>\n" +
            "                    </select>\n" +
            "                    <span ng-show=\"appCus.name\">{{appCus.name}}</span>\n" +
            "                  </td>\n" +
            "                  <td ng-show=\"vm.showInvestmentShareClassSelectBox()\">\n" +
            "                    <select name=\"select-investment-share-class\"\n" +
            "                            ng-model=\"appCus.investment_share_class_id\"\n" +
            "                            ng-hide=\"appCus.name || vm.emptyInvestmentShareClasses()\"\n" +
            "                            class=\"pull-left\">\n" +
            "                      <option value=\"\" disabled selected>Select an Investment Share Class</option>\n" +
            "                      <option ng-value=\"investment_share_class.id\"\n" +
            "                              ng-repeat=\"investment_share_class in vm.fund.investment_share_classes\">\n" +
            "                        {{investment_share_class.name}}\n" +
            "                      </option>\n" +
            "                    </select>\n" +
            "                    <span ng-show=\"appCus.name\">{{appCus.investment_share_class_name}}</span>\n" +
            "                  </td>\n" +
            "                  <td ng-hide=\"vm.showInvestmentShareClassSelectBox()\">\n" +
            "                    <select name=\"select-sdd\"\n" +
            "                            ng-model=\"appCus.subscription_document_definition_id\"\n" +
            "                            ng-hide=\"appCus.name || vm.emptySDDS()\"\n" +
            "                            class=\"pull-left\">\n" +
            "                      <option value=\"\" disabled selected>Select a Share Class</option>\n" +
            "                      <option ng-value=\"sdd.id\"\n" +
            "                              ng-repeat=\"sdd in vm.fund.subscription_document_definitions_with_descriptions\">\n" +
            "                        {{sdd.subscription_document_description}}\n" +
            "                      </option>\n" +
            "                    </select>\n" +
            "                    <span ng-show=\"appCus.name\">{{appCus.share_class_name}}</span>\n" +
            "                  </td>\n" +
            "                  <td>\n" +
            "                    <input name=\"custodian-cusip\"\n" +
            "                           type=\"text\"\n" +
            "                           ng-trim=\"true\"\n" +
            "                           ng-model=\"appCus.cusip\"\n" +
            "                           class=\"form-control pull-left\"\n" +
            "                           ng-disabled=\"!vm.canEditFundCustodians()\" />\n" +
            "                  </td>\n" +
            "                  <td ng-show=\"vm.canEditFundCustodians()\">\n" +
            "                    <div class=\"icon-container\" ng-click=\"vm.removeApprovedCustodian($index)\">\n" +
            "                      <span class=\"ui-icon ui-icon-minus\"></span>\n" +
            "                    </div>\n" +
            "                  </td>\n" +
            "                </tr>\n" +
            "              </table>\n" +
            "            </div>\n" +
            "            <div class=\"icon-container\"\n" +
            "                 ng-show=\"vm.canEditFundCustodians()\"\n" +
            "                 ng-click=\"vm.addApprovedCustodian()\">\n" +
            "              <a href=\"#\">\n" +
            "                <span class=\"pull-left ui-icon ui-icon-plus\"></span> Add Approved Custodian\n" +
            "              </a>\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>\n" +
            "            Closes\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <div>\n" +
            "              <table class=\"table table-default\">\n" +
            "              <tr ng-repeat=\"close in vm.fund.closes | orderBy: 'close_date'\">\n" +
            "                <td>{{ close.id }}</td>\n" +
            "                <td ng-if=\"close.close_date\">{{ close.close_date | date}}</td>\n" +
            "                <td ng-if=\"!close.close_date\">Next Close</td>\n" +
            "                <td><span class=\"label label-success\" ng-show=\"close.is_final_close\">Final Close</span></td>\n" +
            "              </tr>\n" +
            "              </table>\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>\n" +
            "            Status\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            {{ vm.fund.closed ? 'Closed' : 'Open' }}\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>\n" +
            "            Management Fees and Expenses Included in Capital Calls\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <div class=\"checkbox\">\n" +
            "              <label>\n" +
            "                <input type=\"checkbox\" ng-model=\"vm.fund.inclusive_fees\" ng-disabled=\"!vm.editable()\" />\n" +
            "              </label>\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>\n" +
            "            Wiring Instructions\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            {{ vm.fund.wiring_instructions }}\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>\n" +
            "            DTCC Information\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <div>\n" +
            "              <div class=\"form-group\">\n" +
            "                <label>DTCC Fund Number</label>\n" +
            "                <input name=\"dtcc_fund_number\" type=\"text\" class=\"form-control\" ng-model=\"vm.fund.dtcc_fund_number\" ng-disabled=\"!vm.editable()\" />\n" +
            "              </div>\n" +
            "              <div class=\"form-group\">\n" +
            "                <label>DTCC NSCC Security Issue Number</label>\n" +
            "                <input name=\"dtcc_nscc_security_issue_number\" type=\"text\" class=\"form-control\" ng-model=\"vm.fund.dtcc_nscc_security_issue_number\" ng-disabled=\"!vm.editable()\" />\n" +
            "              </div>\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>\n" +
            "            PAF Admin Blacklist\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <div class=\"checkbox\">\n" +
            "              <label>\n" +
            "                <input class=\"success\" id=\"blacklisted\" type=\"checkbox\" ng-model=\"vm.fund.blacklisted\" ng-disabled=\"!vm.editMode\" />\n" +
            "              </label>\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>\n" +
            "            Detailed PCAP Report\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <div class=\"checkbox\">\n" +
            "              <label>\n" +
            "                <input class=\"success\" id=\"detailed_pcap_report\" type=\"checkbox\" ng-model=\"vm.fund.detailed_pcap_report\" ng-disabled=\"!vm.editMode\" />\n" +
            "              </label>\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>\n" +
            "            Billing Method\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <div class=\"form-group\">\n" +
            "              <label>\n" +
            "                <select class=\"form-control\" id=\"billing_calc_method\" ng-model=\"vm.fund.billing_calc_method\" ng-disabled=\"!vm.editMode\">\n" +
            "                  <option>Committed Capital</option>\n" +
            "                  <option>Invested Capital</option>\n" +
            "                </select>\n" +
            "              </label>\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td>\n" +
            "            Unitized\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <div class=\"checkbox\">\n" +
            "              <label>\n" +
            "                <input class=\"success\" id=\"unitized\" type=\"checkbox\" ng-model=\"vm.fund.unitized\" ng-disabled=\"!vm.editMode\" />\n" +
            "              </label>\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "\n" +
            "        <tr>\n" +
            "          <td>\n" +
            "            MOIC Suppression\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <div class=\"checkbox\">\n" +
            "              <label>\n" +
            "                <input class=\"success\" id=\"moic_supression_pcap_report\" type=\"checkbox\" ng-model=\"vm.fund.moic_supression_pcap_report\" ng-disabled=\"!vm.editMode\" />\n" +
            "              </label>\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "\n" +
            "        <tr>\n" +
            "          <td>\n" +
            "            Components of Contribution Suppression\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <div class=\"checkbox\">\n" +
            "              <label>\n" +
            "                <input class=\"success\" id=\"components_of_contribution_supression_pcap_report\"\n" +
            "                        type=\"checkbox\" ng-model=\"vm.fund.components_of_contribution_supression_pcap_report\"\n" +
            "                        ng-disabled=\"!vm.editMode\" />\n" +
            "              </label>\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "\n" +
            "        <tr>\n" +
            "          <td>\n" +
            "            Components of Distribution Suppression\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <div class=\"checkbox\">\n" +
            "              <label>\n" +
            "                <input class=\"success\" id=\"components_of_distribution_supression_pcap_report\"\n" +
            "                        type=\"checkbox\" ng-model=\"vm.fund.components_of_distribution_supression_pcap_report\"\n" +
            "                        ng-disabled=\"!vm.editMode\" />\n" +
            "              </label>\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "\n" +
            "        <tr>\n" +
            "          <td>PCAP Foot notes</td>\n" +
            "          <td>\n" +
            "            <div class=\"form-group\">\n" +
            "              <input name=\"foot_notes_pcap_report\"\n" +
            "                     type=\"text\"\n" +
            "                     class=\"form-control\"\n" +
            "                     ng-model=\"vm.fund.foot_notes_pcap_report\"\n" +
            "                     ng-disabled=\"!vm.editMode\"\n" +
            "                     placeholder=\"Please note the above calculations may not reconcile exactly due to rounding.\"/>\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "      </tbody>\n" +
            "    </table>\n" +
            "    <button ng-show=\"vm.editMode\" ng-click=\"vm.save()\" class=\"btn btn-blue pull-right\">Save</button>\n" +
            "    <div ng-include=\"'areas/paf_admin/components/fund_documents.html'\"></div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/paf_admin/tabs/pe/reporting/pages/report/components/transactions/transactions.html", "<div ng-show=\"showTransactions()\" style=\"margin: 5px 0\">\n" +
            "  <div class=\"list-group\">\n" +
            "    <div class=\"list-group-item\" ng-repeat=\"report in selectedTransactions()\" style=\"font-size:smaller\">\n" +
            "      {{ report.date | date : 'shortDate' }} {{ report.amount | noFractionCurrency }}\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/private_equity_funds/private_access_funds/components/reminder_dialog/_reminder_dialog.html", "<common-modal modal-header=\"Send Reminder Email\">\n" +
            "  <div>\n" +
            "    <div class=\"row more-head-room form-group\">\n" +
            "      <div class=\"row\" ng-if=\"errors\" ng-class=\"{'alert error': errors}\">{{errors}}</div>\n" +
            "      <label class=\"send-reminder-text\">\n" +
            "        Please confirm that you would like to send a reminder email to {{name}} ({{email}}) to sign the agreement.\n" +
            "      </label>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"row\">\n" +
            "    <button class=\"pull-right\" ng-click=\"ok()\">Send</button>\n" +
            "    <button class=\"cancel-btn pull-right\" ng-click=\"cancel()\">Cancel</button>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/register/not_confirmed/thankyou.html", "<div class=\"registrationThankYou row\">\n" +
            "    <div class=\"registrationThankYou-title\">\n" +
            "        <h3>Thank you for registering with us, please verify your email address</h3>\n" +
            "    </div>\n" +
            "    <div class=\"registrationThankYou-body\">\n" +
            "        We sent a verification email to <strong>{{email}}</strong>.<br/>\n" +
            "        Once received, please click on the verify my email button in order to verify your account and gain access.\n" +
            "    </div>\n" +
            "    <div class=\"registrationThankYou-footer\">\n" +
            "        Haven't received your verification email yet?\n" +
            "        <a href=\"#\" ng-click=\"resendConfirmationEmail()\">Resend Verification Email</a> or\n" +
            "        <a href=\"mailto:support@icapitalnetwork.com\">Contact Support</a>\n" +
            "        <br>\n" +
            "        <b>Don't forget to check your Spam filter for your verification email!</b>\n" +
            "    </div>\n" +
            "</div>");

        $templateCache.put("areas/register/register/components/icn_registration/templates/blackrock.html", "<form id=\"login-form\"\n" +
            "      class=\"login-form blackrock-custom-registration-form\"\n" +
            "      name=\"registrationForm\"\n" +
            "      ng-model=\"user\"\n" +
            "      ng-submit=\"submitRegistration()\"\n" +
            "      role=\"form\"\n" +
            "      novalidate>\n" +
            "  <div class=\"row\">\n" +
            "    <div class=\"col-md-6 col-left-register\">\n" +
            "      <div class=\"area-title\">Account Registration</div>\n" +
            "      <icn-input type=\"email\"\n" +
            "                 field-disabled=\"{{hasEmail}}\"\n" +
            "                 model=\"user.email\"\n" +
            "                 name=\"email\"\n" +
            "                 inputclass=\"registration-input\"\n" +
            "                 class=\"has-message\"\n" +
            "                 ng-blur=\"validateEmail(registrationForm.email)\"\n" +
            "                 ng-class=\"{ 'email-error' : \n" +
            "                  serverValidations.available_email.length > 0 ||\n" +
            "                  (!serverValidations.passwordError && serverValidations.unknownError)\n" +
            "                 }\"\n" +
            "                 required>\n" +
            "      </icn-input>\n" +
            "      <div class=\"login-message-container\" ng-if=\"serverValidations.available_email.length > 0\">\n" +
            "        <p class=\"error confirmation-error\">\n" +
            "          This email is already registered. Click <a ng-href=\"/login\" target=\"_self\">Here</a> to log in.\n" +
            "        </p>\n" +
            "      </div>\n" +
            "      <div class=\"login-message-container\" ng-if=\"!serverValidations.passwordError && serverValidations.unknownError\">\n" +
            "        <p class=\"error confirmation-error\">\n" +
            "          This email could not be registered,\n" +
            "          <a href='mailto:support@icapitalnetwork.com'>Please Contact Support</a>.\n" +
            "        </p>\n" +
            "      </div>\n" +
            "      <icn-input type=\"password\"\n" +
            "                 model=\"user.password\"\n" +
            "                 inputclass=\"registration-input\"\n" +
            "                 class=\"has-message\"\n" +
            "                 label=\"Create Password\"\n" +
            "                 disable-autofill=\"true\"\n" +
            "                 ng-class=\"{ 'password-confirmation-error' : passwordHasError(registrationForm.password) }\"\n" +
            "                 required>\n" +
            "      </icn-input>\n" +
            "      <div class=\"login-message-container\" ng-if=\"user.isPasswordInvalid\">\n" +
            "        <p class=\"error confirmation-error\">\n" +
            "          Password does not meet security requirements. Please refrain from using common words, your email address, or references to iCapital.\n" +
            "        </p>\n" +
            "      </div>\n" +
            "      <icn-input type=\"password\"\n" +
            "                 model=\"user.password_confirmation\"\n" +
            "                 name=\"password_confirmation\"\n" +
            "                 inputclass=\"registration-input\"\n" +
            "                 label=\"Confirm Password\"\n" +
            "                 class=\"has-message\"\n" +
            "                 disable-autofill=\"true\"\n" +
            "                 ng-blur=\"confirmedPasswordHasError(registrationForm.password, registrationForm.password_confirmation)\"\n" +
            "                 ng-class=\"{ 'password-confirmation-error' : confirmPasswordFieldHasError }\"\n" +
            "                 required>\n" +
            "      </icn-input>\n" +
            "      <div class=\"login-message-container\" ng-show=\"confirmedPasswordHasError(registrationForm.password, registrationForm.password_confirmation)\">\n" +
            "        <p class=\"error confirmation-error\">\n" +
            "          Confirmed password does not match\n" +
            "        </p>\n" +
            "      </div>\n" +
            "      <icn-select ng-model=\"user.role\"\n" +
            "        options=\"userTypes\"\n" +
            "        name=\"role\"\n" +
            "        label=\"I am a:\"\n" +
            "        option-id=\"type\"\n" +
            "        option-label=\"name\"\n" +
            "        ng-disabled=\"hasRole\"\n" +
            "        required>\n" +
            "      </icn-select>\n" +
            "    </div>\n" +
            "    <div class=\"col-md-5 col-right-register\">\n" +
            "      <icn-password-steps showtext=\"false\" registerpage=\"true\" user=\"user\"></icn-password-steps>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <section class=\"accept-terms-checkbox-registration registration-agree\" ng-if=\"(hasWlpTerms && !enable_custom_wlp_page) || !hasWlpTerms\">\n" +
            "    <label for=\"accept-terms-checkbox\">\n" +
            "        <input id=\"accept-terms-checkbox\" type=\"checkbox\" name=\"agree\" ng-model=\"user.agree\" required>\n" +
            "        <div class=\"accept-terms-fake-checkbox\" for=\"accept-terms-checkbox\"></div>\n" +
            "        <span>I have read and agree to the {{hasWlpTerms ? 'iCapital' : ''}} \n" +
            "          <a target=\"_blank\" href=\"/terms_of_use\">terms of use</a>\n" +
            "        </span>\n" +
            "    </label>\n" +
            "  </section>\n" +
            "  <section class=\"accept-terms-checkbox-registration accept-terms-wlp registration-agree\" ng-if=\"hasWlpTerms\">\n" +
            "    <label for=\"accept-wlp-terms-checkbox\">\n" +
            "        <input id=\"accept-wlp-terms-checkbox\" type=\"checkbox\" name=\"wlp_agree\" ng-model=\"user.wlp_agree\" required>\n" +
            "        <div class=\"accept-terms-fake-checkbox\" for=\"accept-wlp-terms-checkbox\"></div>\n" +
            "        <span>I have read and agree to the {{enable_custom_wlp_page ? 'iCapital' : wlpName}} \n" +
            "          <a target=\"_blank\" href=\"/terms_of_use/{{wlp_id}}\">terms of use</a>\n" +
            "        </span>\n" +
            "    </label>\n" +
            "  </section>\n" +
            "  <div class=\"login-message-container\" ng-if=\"serverValidations.team_management_invite.length > 0\">\n" +
            "    <p class=\"error confirmation-error\">\n" +
            "      {{serverValidations.team_management_invite}}\n" +
            "    </p>\n" +
            "  </div>\n" +
            "  <div class=\"login-message-container\" ng-if=\"serverValidations.invitation_code.length > 0\">\n" +
            "    <p class=\"error confirmation-error\">\n" +
            "      The referral link for this registration is not valid.\n" +
            "    </p>\n" +
            "  </div>\n" +
            "  <div class=\"form-group registration-button-container\">\n" +
            "    <button type=\"submit\"\n" +
            "            id=\"register-button\"\n" +
            "            ng-disabled=\"registrationForm.$invalid || !allCompleted() || !validateEmail(registrationForm.email)\">\n" +
            "      SIGN UP\n" +
            "    </button>\n" +
            "  </div>\n" +
            "</form>");

        $templateCache.put("areas/register/register/components/icn_registration/templates/default.html", "<div class=\"registration-form-login\" ng-if=\"!fromSso\">\n" +
            "  Already Registered? <a target=\"_self\" href=\"/login\" class=\"login-link\">Login Here</a>\n" +
            "</div>\n" +
            "<img class=\"login-form-logo login-form-logo-icapital\" src=\"images/logo.svg\" alt=\"iCapital Network\">\n" +
            "<form id=\"login-form\"\n" +
            "    class=\"login-form registration-form\"\n" +
            "    name=\"registrationForm\"\n" +
            "    ng-model=\"user\"\n" +
            "    ng-submit=\"submitRegistration()\"\n" +
            "    ng-if=\"!ssoLoginEnabled\"\n" +
            "    novalidate>\n" +
            "<div class=\"registration-steps\" ng-if=\"showRegistrationSteps\">\n" +
            "  <div class=\"container\">\n" +
            "    <div class=\"login-form-header-container row\">\n" +
            "      <h1 class=\"login-form-header\">\n" +
            "        Welcome!\n" +
            "      </h1>\n" +
            "    </div>\n" +
            "    <div class=\"registration-note pb-2 row\">\n" +
            "      <span>\n" +
            "        If you are an advisor (or an advisor’s associate), you may register in just 3 simple steps.\n" +
            "        Once registered, you’ll have access to our professionally curated menu of alternative investments\n" +
            "        and leading-edge, secure technology to review fund offerings, invest, and manage your accounts on a\n" +
            "        single platform.\n" +
            "      </span>\n" +
            "    </div>\n" +
            "    <div class=\"row\">\n" +
            "      <div class=\"registration-step-box col-md-4\">\n" +
            "        <h1>Step 1</h1>\n" +
            "        <img src=\"images/registration_step1_graphic.svg\" class=\"mb-1\">\n" +
            "        <span>Provide a business email and create a password</span>\n" +
            "      </div>\n" +
            "      <div class=\"registration-step-box col-md-4\">\n" +
            "        <h1>Step 2</h1>\n" +
            "        <img src=\"images/registration_step2_graphic.svg\" class=\"mb-1\">\n" +
            "        <span>Check your inbox for the verification email</span>\n" +
            "      </div>\n" +
            "      <div class=\"registration-step-box col-md-4\">\n" +
            "        <h1>Step 3</h1>\n" +
            "        <img src=\"images/registration_step3_graphic.svg\" class=\"mb-1\">\n" +
            "        <span>Complete the onboarding <a href=\"\" ng-click=\"openQuestionnaireExample()\">questionnaire</a></span>\n" +
            "      </div>\n" +
            "      <div class=\"registration-step-box col-md-4\">\n" +
            "        <h1>Access</h1>\n" +
            "        <img src=\"images/registration_access_graphic.svg\" class=\"mb-1\">\n" +
            "        <span>Research and Invest</span>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "    <hr class=\"registration-line-large thick green row mb-1\">\n" +
            "  </div>\n" +
            "\n" +
            "</div>\n" +
            "\n" +
            "<div ng-if=\"!fromSso\" class=\"login-form-header-container\">\n" +
            "  <h1 class=\"login-form-header\">\n" +
            "    Account Registration\n" +
            "  </h1>\n" +
            "</div>\n" +
            "<div ng-if=\"!fromSso\" class=\"registration-note pb-2\">\n" +
            "  <span>Please complete the following information to register on the iCapital Network platform.</span>\n" +
            "</div>\n" +
            "\n" +
            "<div ng-if=\"fromSso\" class=\"login-form-header-container\">\n" +
            "  <h1 ng-if=\"fromSso\" class=\"login-form-header\">\n" +
            "    Enable Single Sign-On\n" +
            "  </h1>\n" +
            "  </div>\n" +
            "  <div ng-if=\"fromSso\" class=\"registration-note pb-2\">\n" +
            "    <span>In order to access this product on the iCapital Network platform, please complete the following information to enable Single Sign-On with your {{user.partner}} account.</span>\n" +
            "  </div>\n" +
            "</div>\n" +
            "\n" +
            "<div class=\"login-error-container\">\n" +
            "  <div class=\"error login-error\" ng-if=\"serverError\">\n" +
            "    <div class=\"alert-icon-container\">\n" +
            "      <img src=\"images/alert-icon.svg\">\n" +
            "    </div>\n" +
            "    <div class=\"login-error-content\" ng-bind-html=\"serverError\"></div>\n" +
            "  </div>\n" +
            "  <div class=\"error login-error\" ng-if=\"serverValidations.team_management_invite.length > 0\">\n" +
            "    <div class=\"alert-icon-container\">\n" +
            "      <img src=\"images/alert-icon.svg\">\n" +
            "    </div>\n" +
            "    <div class=\"login-error-content\">\n" +
            "      {{serverValidations.team_management_invite}}\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"error login-error\" ng-if=\"serverValidations.invitation_code.length > 0\">\n" +
            "    <div class=\"alert-icon-container\">\n" +
            "      <img src=\"images/alert-icon.svg\">\n" +
            "    </div>\n" +
            "    <div class=\"login-error-content\">\n" +
            "      The referral link for this registration is not valid.\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"error login-error\" ng-if=\"serverValidations.available_email.length > 0\">\n" +
            "    <div class=\"alert-icon-container\">\n" +
            "      <img src=\"images/alert-icon.svg\">\n" +
            "    </div>\n" +
            "    <div class=\"login-error-content\">\n" +
            "      This email is already registered. Log in <a ng-href=\"/login\" target=\"_self\">here</a>.\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"error login-error\" ng-if=\"serverValidations.unknownError\">\n" +
            "    <div class=\"alert-icon-container\">\n" +
            "      <img src=\"images/alert-icon.svg\">\n" +
            "    </div>\n" +
            "    <div class=\"login-error-content\">\n" +
            "      This email could not be registered. Please contact <a href='mailto:support@icapitalnetwork.com'>support</a>.\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</div>\n" +
            "<div class=\"row\">\n" +
            "  <div class=\"col-md-6\" ng-class=\"fromSso ? 'col-center-register' : 'col-left-register'\">\n" +
            "    <icn-input type=\"email\"\n" +
            "               field-disabled=\"{{hasEmail}}\"\n" +
            "               model=\"user.email\"\n" +
            "               name=\"email\"\n" +
            "               inputclass=\"registration-input\"\n" +
            "               class=\"has-message\"\n" +
            "               ng-blur=\"validateEmail(registrationForm.email)\"\n" +
            "               required>\n" +
            "    </icn-input>\n" +
            "    <icn-input ng-if=\"!fromSso\"\n" +
            "               type=\"password\"\n" +
            "               model=\"user.password\"\n" +
            "               inputclass=\"registration-input\"\n" +
            "               class=\"has-message\"\n" +
            "               label=\"Create Password\"\n" +
            "               disable-autofill=\"true\"\n" +
            "               ng-class=\"{ 'password-confirmation-error' : passwordHasError(registrationForm.password) }\"\n" +
            "               required>\n" +
            "    </icn-input>\n" +
            "    <div class=\"login-message-container\" ng-if=\"user.isPasswordInvalid\">\n" +
            "      <p class=\"error confirmation-error\">\n" +
            "        Password does not meet security requirements. Please refrain from using common words, your email address, or references to iCapital.\n" +
            "      </p>\n" +
            "    </div>\n" +
            "    <icn-input ng-if=\"!fromSso\"\n" +
            "               type=\"password\"\n" +
            "               model=\"user.password_confirmation\"\n" +
            "               name=\"password_confirmation\"\n" +
            "               inputclass=\"registration-input\"\n" +
            "               label=\"Confirm Password\"\n" +
            "               class=\"has-message\"\n" +
            "               disable-autofill=\"true\"\n" +
            "               ng-blur=\"confirmedPasswordHasError(registrationForm.password, registrationForm.password_confirmation)\"\n" +
            "               ng-class=\"{ 'password-confirmation-error' : confirmPasswordFieldHasError }\"\n" +
            "               required>\n" +
            "    </icn-input>\n" +
            "    <div class=\"login-message-container\" ng-show=\"confirmedPasswordHasError(registrationForm.password, registrationForm.password_confirmation)\">\n" +
            "      <p class=\"error confirmation-error\">\n" +
            "        Confirmed password does not match\n" +
            "      </p>\n" +
            "    </div>\n" +
            "    <icn-select ng-if=\"showUserTypeDropDown\"\n" +
            "                ng-model=\"user.role\"\n" +
            "                options=\"userTypes\"\n" +
            "                name=\"role\"\n" +
            "                label=\"{{fromSso ? 'Role' : 'I am a:'}}\"\n" +
            "                option-id=\"type\"\n" +
            "                option-label=\"name\"\n" +
            "                ng-disabled=\"hasRole\"\n" +
            "                required>\n" +
            "    </icn-select>\n" +
            "    <div ng-if=\"fromSso\" class=\"registration-select\">\n" +
            "      <icn-select ng-model=\"user.country\"\n" +
            "                  options=\"countryList\"\n" +
            "                  name=\"country\"\n" +
            "                  label=\"Country\"\n" +
            "                  option-id=\"name\"\n" +
            "                  option-label=\"name\"\n" +
            "                  required>\n" +
            "      </icn-select>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div ng-if=\"!fromSso\" class=\"col-md-5 col-right-register\">\n" +
            "    <icn-password-steps showtext=\"false\" registerpage=\"true\" user=\"user\"></icn-password-steps>\n" +
            "  </div>\n" +
            "</div>\n" +
            "<div class=\"row\">\n" +
            "  <div class=\"accept-terms-checkbox-registration registration-agree\">\n" +
            "    <label>\n" +
            "      <input id=\"accept-terms-checkbox\" type=\"checkbox\" name=\"agree\" ng-model=\"user.agree\" required>\n" +
            "      <div class=\"accept-terms-fake-checkbox\" ></div>\n" +
            "      <span>I have read and agree to the <a target=\"_blank\" href=\"/terms_of_use\">terms of use</a></span>\n" +
            "    </label>\n" +
            "  </div>\n" +
            "</div>\n" +
            "\n" +
            "<hr class=\"registration-line-large thick green\">\n" +
            "<div class=\"form-group registration-button-container\">\n" +
            "  <button type=\"submit\"\n" +
            "          id=\"register-button\"\n" +
            "          ng-disabled=\"registrationForm.$invalid || !allCompleted() || !validateEmail(registrationForm.email)\">\n" +
            "    {{fromSso ? 'CONTINUE' : 'REGISTER'}}\n" +
            "  </button>\n" +
            "</div>\n" +
            "<div class=\"form-group more-head-room\"></div>\n" +
            "<div ng-if=\"!fromSso\" class=\"footer-note\">\n" +
            "  <p class=\"bold-note\">Please note:</p>\n" +
            "  <p>\n" +
            "    Private placements require a pre-existing, substantive relationship between the issuer and the investor, for which iCapital Network may rely on its\n" +
            "relationship with an intermediary, such as an advisor firm. Therefore, advisor firms registering to the platform for the first time may be subject to a 90-day\n" +
            "“cooling off” period before any investment offerings can be offered to clients, and the advisor must also attest to a pre-existing relationship with each client.\n" +
            "  </p>\n" +
            "  <p>Registration is open to advisors and their associates. Existing investors and <a href=\"\" tooltip-placement=\"bottom\" tooltip-class=\"registration-tooltip inverted rounded\" tooltip-html=\"toolTips.fetch().registerInterestedParties\">interested parties</a> must be invited to register.</p>\n" +
            "  <p>If you need further assistance, please contact iCapital’s Investor Relations team at 212-994-7333 or <a href=\"mailto: ir@icapitalnetwork.com\">ir@icapitalnetwork.com</a>.</p>\n" +
            "</div>\n" +
            "</form>\n" +
            "<img src=\"images/samplequestionnaire.png\" style=\"display: none;\">");

        $templateCache.put("areas/register/register/components/icn_registration/templates/icn_password_steps.html", "<span class=\"password-steps-title\"> Your Password must contain: </span>\n" +
            "<div class=\"password-steps-group\">\n" +
            "  <span class=\"password-steps-text\"><i class=\"fa fa-check {{atLeastTen()}}\"> </i> at least 10 characters</span> <br>\n" +
            "  <span class=\"password-steps-text\"><i class=\"fa fa-check {{anUppercase()}}\"> </i> an uppercase letter</span> <br>\n" +
            "  <span class=\"password-steps-text\"><i class=\"fa fa-check {{aLowercase()}}\"></i> a lowercase letter</span> <br>\n" +
            "  <span class=\"password-steps-text\"><i class=\"fa fa-check {{oneDigit()}}\"></i> a number (ex. 0 - 9)</span> <br>\n" +
            "  <div class=\"password-steps-text\">\n" +
            "    <div class=\"last-step-left\"><i class=\"fa fa-check {{oneSpecialChar()}}\"></i> </div>\n" +
            "    <div class=\"last-step-right\">a special character using these <span class=\"options-password-hover\" tooltip-placement=\"bottom\" tooltip-class=\"registration-tooltip inverted rounded\" tooltip-html=\"toolTips.fetch().passwordSymbols\">options</span></div>\n" +
            "  </div>\n" +
            "  <div class=\"password-steps-text\" ng-if=\"showWarning\">\n" +
            "    <div class=\"password-steps-warning\">Note:  Registration is open to advisors and their team members. Investors with existing investments must be invited to register by their advisors. Interested Parties should contact the investor or advisor.</div>\n" +
            "  </div>\n" +
            "</div>\n" +
            "<img src=\"images/hover-symbols-tooltip.svg\" style=\"display:none;\" />\n" +
            "\n" +
            "<br>\n" +
            "<span class=\"password-steps-title\"> Your Password must not contain: </span>\n" +
            "<div class=\"password-steps-group\">\n" +
            "  <span\n" +
            "    tooltip-class=\"{{user.isPasswordInvalid ? 'password-error-tooltip' : 'password-info-tooltip' }}\"\n" +
            "    tooltip-html=\"toolTips.fetch().passwordInfo\"\n" +
            "    tooltip-placement=\"bottom\"\n" +
            "    tooltip-popup-delay=\"500\">\n" +
            "    <img ng-if=\"!user.isPasswordInvalid\"\n" +
            "      class=\"password-info-icon\"\n" +
            "      src='/images/information-icon.svg'\n" +
            "    >\n" +
            "    <i ng-if=\"user.isPasswordInvalid\" class=\"fa fa-times-circle-o password-error-icon\" aria-hidden=\"true\"></i>\n" +
            "  </span>\n" +
            "  <span class=\"password-info-text {{ user.isPasswordInvalid ? 'margin-error' : 'margin-info' }}\">Common words, your {{ !registerpage ? 'name, ' : '' }} email address, or references to iCapital</span>\n" +
            "</div>");

        $templateCache.put("areas/register/register/components/icn_registration/templates/wlp_default.html", "<div class=\"registration-form-login\">\n" +
            "    Already a member? <a target=\"_self\" href=\"/login\" class=\"login-link\">Login Here</a>\n" +
            "</div>\n" +
            "<img class=\"login-form-logo login-form-logo-icapital\" src=\"images/logo.svg\" alt=\"iCapital Network\">\n" +
            "<form id=\"login-form\"\n" +
            "      class=\"login-form registration-form wlp-register\"\n" +
            "      name=\"registrationForm\"\n" +
            "      ng-model=\"user\"\n" +
            "      ng-submit=\"submitRegistration()\"\n" +
            "      ng-if=\"!ssoLoginEnabled\"\n" +
            "      novalidate>\n" +
            "    <div class=\"login-form-header-container\">\n" +
            "      <h1 class=\"login-form-header\">\n" +
            "        Account Registration\n" +
            "      </h1>\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"login-error-container\">\n" +
            "      <div class=\"error login-error\" ng-if=\"serverError\">\n" +
            "        <div class=\"alert-icon-container\">\n" +
            "          <img src=\"images/alert-icon.svg\">\n" +
            "        </div>\n" +
            "        <div class=\"login-error-content\" ng-bind-html=\"serverError\"></div>\n" +
            "      </div>\n" +
            "      <div class=\"error login-error\" ng-if=\"serverValidations.team_management_invite.length > 0\">\n" +
            "        <div class=\"alert-icon-container\">\n" +
            "          <img src=\"images/alert-icon.svg\">\n" +
            "        </div>\n" +
            "        <div class=\"login-error-content\">\n" +
            "          {{serverValidations.team_management_invite}}\n" +
            "        </div>\n" +
            "      </div>\n" +
            "      <div class=\"error login-error\" ng-if=\"serverValidations.invitation_code.length > 0\">\n" +
            "        <div class=\"alert-icon-container\">\n" +
            "          <img src=\"images/alert-icon.svg\">\n" +
            "        </div>\n" +
            "        <div class=\"login-error-content\">\n" +
            "          The referral link for this registration is not valid.\n" +
            "        </div>\n" +
            "      </div>\n" +
            "      <div class=\"error login-error\" ng-if=\"serverValidations.available_email.length > 0\">\n" +
            "        <div class=\"alert-icon-container\">\n" +
            "          <img src=\"images/alert-icon.svg\">\n" +
            "        </div>\n" +
            "        <div class=\"login-error-content\">\n" +
            "          This email is already registered. Log in <a href=\"/login\" target=\"_self\">here</a>.\n" +
            "        </div>\n" +
            "      </div>\n" +
            "      <div class=\"error login-error\" ng-if=\"serverValidations.unknownError\">\n" +
            "        <div class=\"alert-icon-container\">\n" +
            "          <img src=\"images/alert-icon.svg\">\n" +
            "        </div>\n" +
            "        <div class=\"login-error-content\">\n" +
            "          This email could not be registered. Please contact <a href='mailto:support@icapitalnetwork.com'>support</a>.\n" +
            "        </div>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "    <div class=\"row\">\n" +
            "      <div class=\"col-md-4 col-left-register\">\n" +
            "        <icn-password-steps showtext=\"true\" registerpage=\"true\" user=\"user\"></icn-password-steps>\n" +
            "      </div>\n" +
            "      <div class=\"col-md-6 col-right-register\">\n" +
            "        <icn-input type=\"email\"\n" +
            "                   field-disabled=\"{{hasEmail}}\"\n" +
            "                   model=\"user.email\"\n" +
            "                   name=\"email\"\n" +
            "                   inputclass=\"registration-input\"\n" +
            "                   class=\"has-message\"\n" +
            "                   ng-blur=\"validateEmail(registrationForm.email)\"\n" +
            "                   required>\n" +
            "        </icn-input>\n" +
            "        <icn-input type=\"password\"\n" +
            "                  model=\"user.password\"\n" +
            "                  inputclass=\"registration-input\"\n" +
            "                  class=\"has-message\"\n" +
            "                  label=\"Create Password\"\n" +
            "                  disable-autofill=\"true\"\n" +
            "                  ng-class=\"{ 'password-confirmation-error' : passwordHasError(registrationForm.password) }\"\n" +
            "                  required>\n" +
            "        </icn-input>\n" +
            "        <div class=\"login-message-container\" ng-if=\"user.isPasswordInvalid\">\n" +
            "          <p class=\"error confirmation-error\">\n" +
            "            Password does not meet security requirements. Please refrain from using common words, your email address, or references to iCapital.\n" +
            "          </p>\n" +
            "        </div>\n" +
            "        <icn-input type=\"password\"\n" +
            "                  model=\"user.password_confirmation\"\n" +
            "                  name=\"password_confirmation\"\n" +
            "                  inputclass=\"registration-input\"\n" +
            "                  label=\"Confirm Password\"\n" +
            "                  class=\"has-message\"\n" +
            "                  disable-autofill=\"true\"\n" +
            "                  ng-blur=\"confirmedPasswordHasError(registrationForm.password, registrationForm.password_confirmation)\"\n" +
            "                  ng-class=\"{ 'password-confirmation-error' : confirmPasswordFieldHasError }\"\n" +
            "                  required>\n" +
            "        </icn-input>\n" +
            "        <div class=\"login-message-container\">\n" +
            "          <p class=\"error confirmation-error\"\n" +
            "            ng-show=\"confirmedPasswordHasError(registrationForm.password, registrationForm.password_confirmation)\">\n" +
            "            Confirmed password does not match\n" +
            "          </p>\n" +
            "        </div>\n" +
            "        <hr class=\"registration-line-small\" ng-class=\"{ 'line-error' : confirmedPasswordHasError(registrationForm.password, registrationForm.password_confirmation) }\"/>\n" +
            "        <icn-select ng-if=\"showUserTypeDropDown\"\n" +
            "                    ng-model=\"user.role\"\n" +
            "                    options=\"userTypes\"\n" +
            "                    name=\"role\"\n" +
            "                    label=\"I am a:\"\n" +
            "                    option-id=\"type\"\n" +
            "                    option-label=\"name\"\n" +
            "                    ng-disabled=\"hasRole\"\n" +
            "                    required>\n" +
            "        </icn-select>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "    <hr class=\"registration-line-large\">\n" +
            "    <section class=\"accept-terms-checkbox-registration registration-agree\" ng-if=\"(hasWlpTerms && !enable_custom_wlp_page) || !hasWlpTerms\">\n" +
            "      <label for=\"accept-terms-checkbox\">\n" +
            "          <input id=\"accept-terms-checkbox\" type=\"checkbox\" name=\"agree\" ng-model=\"user.agree\" required>\n" +
            "          <div class=\"accept-terms-fake-checkbox\" for=\"accept-terms-checkbox\"></div>\n" +
            "          <span>I have read and agree to the {{hasWlpTerms ? 'iCapital' : ''}} <a target=\"_blank\" href=\"/terms_of_use\">terms of use</a></span>\n" +
            "      </label>\n" +
            "    </section>\n" +
            "    <section class=\"accept-terms-checkbox-registration accept-terms-wlp registration-agree\" ng-if=\"hasWlpTerms\">\n" +
            "      <label for=\"accept-wlp-terms-checkbox\">\n" +
            "          <input id=\"accept-wlp-terms-checkbox\" type=\"checkbox\" name=\"wlp_agree\" ng-model=\"user.wlp_agree\" required>\n" +
            "          <div class=\"accept-terms-fake-checkbox\" for=\"accept-wlp-terms-checkbox\"></div>\n" +
            "          <span>I have read and agree to the {{enable_custom_wlp_page ? 'iCapital' : wlpName}}\n" +
            "            <a target=\"_blank\" href=\"/terms_of_use/{{wlp_id}}\">terms of use</a>\n" +
            "          </span>\n" +
            "      </label>\n" +
            "    </section>\n" +
            "    <hr class=\"registration-line-large\">\n" +
            "    <div class=\"form-group registration-button-container\">\n" +
            "      <button type=\"submit\"\n" +
            "              id=\"register-button\"\n" +
            "              ng-disabled=\"registrationForm.$invalid || !allCompleted() || !validateEmail(registrationForm.email)\"\n" +
            "      >\n" +
            "        REGISTER\n" +
            "      </button>\n" +
            "    </div>\n" +
            "    <div class=\"form-group more-head-room\"></div>\n" +
            "</form>");

        $templateCache.put("areas/register/register/dialogs/questionnaire_example/questionnaire_example.html", "<common-modal>\n" +
            "  <div class=\"registration-questionnaire-modal\">\n" +
            "    <div class=\"registration-modal-header\">\n" +
            "      <h1 class=\"pb-3\">\n" +
            "        Sample Questionnaire\n" +
            "      </h1>\n" +
            "    </div>\n" +
            "    <div class=\"registration-modal-note pb-3\">\n" +
            "      <span>\n" +
            "        Please view our sample questionnaire to see the types of Information required (e.g., CRD number for SEC\n" +
            "        registered firms and individual advisors, as well as the date registered: <a href=\"https://brokercheck.finra.org/\" target=\"_blank\">FINRA’s BrokerCheck website</a>).\n" +
            "      </span>\n" +
            "      <p>Submitting incomplete or incorrect information will delay the registration process.</p>\n" +
            "    </div>\n" +
            "    <img src=\"images/samplequestionnaire.png\" style=\"width:770px\">\n" +
            "    <hr class=\"registration-line-large\"/>\n" +
            "    <div class=\"registration-button-container pb-2\">\n" +
            "      <button ng-click=\"cancelDialog()\" >\n" +
            "        OK\n" +
            "      </button>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/register/register/dialogs/secondary_registration/secondary_registration.html", "<common-modal>\n" +
            "  <div class=\"secondary-registration-box registration-content\"\n" +
            "       ng-class=\"{'secondary-registration-box-icapital': type == 'iCapital Network'}\">\n" +
            "    <icn-registration user=\"user\" on-complete=\"onComplete(user)\" ng-show=\"type=='iCapital Network'\">\n" +
            "    </icn-registration>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/register/register/register_index.html", "<ng-include src=\"'areas/login/templates/unsupported_browser.html'\"></ng-include>\n" +
            "<div><div class=\"row\" ng-include=\"pageContent\"></div></div>");

        $templateCache.put("areas/register/register/templates/blackrock.html", "<div class=\"blackrock-custom\">\n" +
            "  <div class=\"blackrock-custom-top-divider\"></div>\n" +
            "  <div class=\"col-md-12 blackrock-custom-subtitle\">\n" +
            "    Welcome to BlackRock Alternative Investments Portal\n" +
            "  </div>\n" +
            "  <div class=\"col-md-12 blackrock-custom-description p-registration\">\n" +
            "    Register now for BlackRock Alternative Investments Portal, powered by iCapital Network.\n" +
            "  </div>\n" +
            "  <div class=\"row\">\n" +
            "    <div class=\"col-md-5 col-centered blackrock-custom-registration-box\">\n" +
            "        <div class=\"body\">\n" +
            "            <icn-registration user=\"user\"\n" +
            "                              on-complete=\"onComplete(user)\"\n" +
            "                              custom-template=\"blackrock\">\n" +
            "            </icn-registration>\n" +
            "        </div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"blackrock-custom-disclaimer-divider\"></div>\n" +
            "  <div class=\"centering-wrapper\">\n" +
            "    <div class=\"wlp-disclaimer-box\">\n" +
            "        <div class=\"messageCenter-message-body\" ng-bind-html=\"wlpDisclaimerHtml\" ng-show=\"wlpDisclaimerHtml\"></div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/register/register/templates/default.html", "<div class=\"row\">\n" +
            "    <div class=\"col-md-5 col-centered\" ng-class=\"isWlp ? 'registration-box-wlp' : 'registration-box'\">\n" +
            "        <div class=\"body\">\n" +
            "            <icn-registration user=\"user\"\n" +
            "                              on-complete=\"onComplete(user)\">\n" +
            "            </icn-registration>\n" +
            "        </div>\n" +
            "    </div>\n" +
            "</div>");

        $templateCache.put("areas/static/privacy_policy.html", "<div class=\"container privacy-policy-container\">\n" +
            "    <h1 class=\"heading-2-green\">\n" +
            "        <span>\n" +
            "            Privacy Policy\n" +
            "        </span>\n" +
            "        <small>\n" +
            "            Last Updated: Feb 22, 2017\n" +
            "        </small>\n" +
            "    </h1>\n" +
            "    <section class=\"privacy-policy-section\">\n" +
            "        <p>\n" +
            "            iCapital Network, Inc. and subsidiaries, iCapital Markets, LLC and iCapital Advisors, LLC\n" +
            "        </p>\n" +
            "    </section>\n" +
            "    <!--information we collect-->\n" +
            "    <section class=\"privacy-policy-section\">\n" +
            "        <h3 class=\"header-four--teal\">\n" +
            "            What information do we collect?\n" +
            "        </h3>\n" +
            "        <div class=\"privacy-policy-content-section\">\n" +
            "            <p class=\"privacy-policy-options-title\">\n" +
            "                We collect information from you when you take one of the following actions:\n" +
            "            </p>\n" +
            "            <ul class=\"privacy-policy-options\">\n" +
            "                <li>Register on our site</li>\n" +
            "                <li>Invest in a product offered on our site</li>\n" +
            "                <li>Respond to a survey or fill out a form</li>\n" +
            "            </ul>\n" +
            "        </div>\n" +
            "    </section>\n" +
            "    <!--information for-->\n" +
            "    <section class=\"privacy-policy-section\">\n" +
            "        <h3 class=\"header-four--teal\">\n" +
            "            What do we use your information for?\n" +
            "        </h3>\n" +
            "        <div class=\"privacy-policy-content-section\">\n" +
            "            <p class=\"privacy-policy-options-title\">\n" +
            "                Any of the information we collect from you may be used in one of the following ways:\n" +
            "            </p>\n" +
            "            <ul class=\"privacy-policy-options\">\n" +
            "                <li>To prove your identity and give you proper access to resources</li>\n" +
            "                <li>To improve our product and services</li>\n" +
            "                <li>To process transactions</li>\n" +
            "                <li>To administer our products</li>\n" +
            "            </ul>\n" +
            "            <p>\n" +
            "                We do not sell personal information obtained about you to companies that are not affiliated with\n" +
            "                iCapital\n" +
            "                for\n" +
            "                the purpose of marketing their products or services to you. We may aggregate survey responses for\n" +
            "                publication;\n" +
            "                however, this will not include any non-public personal information or any information identifiable to a\n" +
            "                particular site user.\n" +
            "            </p>\n" +
            "        </div>\n" +
            "    </section>\n" +
            "    <!--protect your non-public personal information-->\n" +
            "    <section class=\"privacy-policy-section\">\n" +
            "        <h3 class=\"header-four--teal\">\n" +
            "            How do we protect your non-public personal information?\n" +
            "        </h3>\n" +
            "        <div class=\"privacy-policy-content-section\">\n" +
            "            <p>\n" +
            "                We take generally accepted industry security measures in order to protect the loss, misuse and\n" +
            "                alteration of\n" +
            "                the\n" +
            "                non-public personal information we collect from you, both during transmission and once we receive it.\n" +
            "                When\n" +
            "                you\n" +
            "                enter\n" +
            "                non-public personal information on our site, we encrypt such information in transit via HTTPS with TLS\n" +
            "                v1.2.\n" +
            "                Additionally, all non-public personal information is encrypted when stored on our servers. Finally,\n" +
            "                passwords\n" +
            "                are\n" +
            "                hashed (one-way \"encrypted\") when stored in our database.\n" +
            "            </p>\n" +
            "            <p class=\"privacy-policy-options-title\">\n" +
            "                We are committed to keeping your information secure and using it appropriately. We have adopted this\n" +
            "                Privacy\n" +
            "                Policy\n" +
            "                to ensure that your personal information is handled safely and responsibly, and we will:\n" +
            "            </p>\n" +
            "            <ul class=\"privacy-policy-options\">\n" +
            "                <li>Respect your trust and the confidential nature of the information you provide through your financial\n" +
            "                    relationship with us;\n" +
            "                </li>\n" +
            "                <li>Limit the collection and use of client information to that which we believe is necessary to serve\n" +
            "                    you,\n" +
            "                    administer our business or tell you about our services;\n" +
            "                </li>\n" +
            "                <li>Restrict employee access to client information to those who need to know in order to provide\n" +
            "                    services to\n" +
            "                    you;\n" +
            "                </li>\n" +
            "                <li>Educate our employees to reinforce the importance of confidentiality and client privacy; and</li>\n" +
            "                <li>Maintain appropriate security standards and procedures to protect information about you.</li>\n" +
            "            </ul>\n" +
            "        </div>\n" +
            "    </section>\n" +
            "    <!--Do we use cookies-->\n" +
            "    <section class=\"privacy-policy-section\">\n" +
            "        <h3 class=\"header-four--teal\">\n" +
            "            Do we use cookies?\n" +
            "        </h3>\n" +
            "        <div class=\"privacy-policy-content-section\">\n" +
            "            <p>\n" +
            "                Yes. “Cookies” are a feature of web browser software that allows web servers to recognize the\n" +
            "                computer\n" +
            "                used\n" +
            "                to\n" +
            "                access a site. They are small pieces of data that are stored by a user’s web browser on the user’s\n" +
            "                hard\n" +
            "                drive.\n" +
            "                Information gathered through cookies and web server logs may include information such as the date\n" +
            "                and\n" +
            "                time\n" +
            "                of\n" +
            "                visits, the pages viewed, time spent at this site, and the websites visited just before and just\n" +
            "                after\n" +
            "                this\n" +
            "                site. Cookies can remember what information a user accesses on one web page to simplify subsequent\n" +
            "                interactions\n" +
            "                with that site by the same user or to use the information to streamline the user’s transactions on\n" +
            "                related\n" +
            "                web\n" +
            "                pages. This makes it easier for a user to move from web page to web page and to complete commercial\n" +
            "                transactions\n" +
            "                over the Internet. Cookies should make your online experience easier and more personalized. Cookies\n" +
            "                are\n" +
            "                only\n" +
            "                read by the server that placed them, and are unable to execute any code or virus.\n" +
            "            </p>\n" +
            "            <p>\n" +
            "                We use site browser software tools such as cookies and web server logs to gather information about\n" +
            "                this\n" +
            "                site’s\n" +
            "                users’ browsing activities in order to improve this site and better serve users of this site. This\n" +
            "                information\n" +
            "                helps us to design and arrange our web pages in the most user-friendly manner and to continually\n" +
            "                improve\n" +
            "                this\n" +
            "                site to better meet the needs of our users and prospective users. We do not link cookies to\n" +
            "                personally\n" +
            "                identifiable information.\n" +
            "            </p>\n" +
            "            <p>\n" +
            "                If you have turned off all cookies, many features of this site will not be available to you.\n" +
            "            </p>\n" +
            "        </div>\n" +
            "    </section>\n" +
            "    <!--Do we disclose-->\n" +
            "    <section class=\"privacy-policy-section\">\n" +
            "        <h3 class=\"header-four--teal\">\n" +
            "            Do we disclose any non-public personal information to outside parties?\n" +
            "        </h3>\n" +
            "        <div class=\"privacy-policy-content-section\">\n" +
            "            <p>\n" +
            "                We do not disclose any nonpublic personal information to anyone other than our affiliates, service\n" +
            "                providers\n" +
            "                and\n" +
            "                relevant counter-parties without your consent. Non-affiliated companies that assist us in providing\n" +
            "                services\n" +
            "                to\n" +
            "                you are required to maintain confidentiality of such information to the extent they receive it and use\n" +
            "                your\n" +
            "                personal information only in the course of providing such service and only for the purpose that we\n" +
            "                dictate.\n" +
            "                We\n" +
            "                may also disclose your personal information to third parties to fulfill your instructions or pursuant to\n" +
            "                your\n" +
            "                expressed consent.\n" +
            "            </p>\n" +
            "        </div>\n" +
            "    </section>\n" +
            "    <!--Third party links-->\n" +
            "    <section class=\"privacy-policy-section\">\n" +
            "        <h3 class=\"header-four--teal\">\n" +
            "            Third party links\n" +
            "        </h3>\n" +
            "        <div class=\"privacy-policy-content-section\">\n" +
            "            <p>\n" +
            "                Occasionally, at our discretion, we may include or offer third party products or services on our\n" +
            "                website.\n" +
            "                These\n" +
            "                third party sites have separate and independent privacy policies. We therefore have no responsibility or\n" +
            "                liability for the content and activities of these linked sites. Nonetheless, we seek to protect the\n" +
            "                integrity of\n" +
            "                our site and welcome any feedback about these sites.\n" +
            "            </p>\n" +
            "        </div>\n" +
            "    </section>\n" +
            "    <!--Changes-->\n" +
            "    <section class=\"privacy-policy-section\">\n" +
            "        <h3 class=\"header-four--teal\">\n" +
            "            Changes\n" +
            "        </h3>\n" +
            "        <div class=\"privacy-policy-content-section\">\n" +
            "            <p>\n" +
            "                In the future, we may make changes to this Privacy Policy, without prior notice. If we decide to change\n" +
            "                our\n" +
            "                Privacy Policy, we will post those changes to this Privacy Policy on the site so that you are aware of\n" +
            "                what\n" +
            "                information we collect, how we use it, and under what circumstances, if any, we disclose it. We reserve\n" +
            "                the\n" +
            "                right to modify this Privacy Policy at any time, so please review it frequently. If we make material\n" +
            "                changes\n" +
            "                to\n" +
            "                this Privacy Policy, we will notify you here, by email or by means of a notice on the site prior to the\n" +
            "                change\n" +
            "                becoming effective. You agree to accept posting of a revised Privacy Policy electronically on our\n" +
            "                website as\n" +
            "                actual notice to you. Any dispute over our Privacy Policy is subject to this notice.\n" +
            "            </p>\n" +
            "        </div>\n" +
            "    </section>\n" +
            "    <!--Minors-->\n" +
            "    <section class=\"privacy-policy-section\">\n" +
            "        <h3 class=\"header-four--teal\">\n" +
            "            Minors\n" +
            "        </h3>\n" +
            "        <div class=\"privacy-policy-content-section\">\n" +
            "            <p>\n" +
            "                This website is not directed toward minors. We do not promote our products or services online to\n" +
            "                children,\n" +
            "                and\n" +
            "                we do not intentionally collect any personally identifiable information from any person under 18.\n" +
            "            </p>\n" +
            "        </div>\n" +
            "    </section>\n" +
            "    <!--Opt-Out Notice-->\n" +
            "    <section class=\"privacy-policy-section\">\n" +
            "        <h3 class=\"header-four--teal\">\n" +
            "            Opt-Out Notice\n" +
            "        </h3>\n" +
            "        <div class=\"privacy-policy-content-section\">\n" +
            "            <p>\n" +
            "                You are not required to supply any of the personal information that we may request. However, failure to\n" +
            "                do so\n" +
            "                may result in our being unable to accept your business or provide services to you. We will make every\n" +
            "                effort\n" +
            "                to\n" +
            "                ensure that all information that we hold about you is accurate, complete and up to date. You can help in\n" +
            "                this\n" +
            "                regard by promptly notifying us if there are any changes to your personal information.\n" +
            "            </p>\n" +
            "            <p>\n" +
            "                If you do not wish to have your personal information disclosed to our affiliates or other third parties\n" +
            "                as\n" +
            "                described in this policy please contact us at the address listed below.\n" +
            "            </p>\n" +
            "        </div>\n" +
            "    </section>\n" +
            "    <!--Contacting Us-->\n" +
            "    <section class=\"privacy-policy-section privacy-policy-last-item-margin\">\n" +
            "        <h3 class=\"header-four--teal\">\n" +
            "            Contacting Us\n" +
            "        </h3>\n" +
            "        <div class=\"privacy-policy-content-section\">\n" +
            "            <span>\n" +
            "                info@icapitalnetwork.com\n" +
            "            </span>\n" +
            "            <br>\n" +
            "            <br>\n" +
            "            <span>\n" +
            "                iCapital Network, Inc.\n" +
            "            </span>\n" +
            "            <br>\n" +
            "            <span>\n" +
            "                60 East 42nd Street, 26th Floor\n" +
            "            </span>\n" +
            "            <br>\n" +
            "            <span>\n" +
            "                New York, NY 10165\n" +
            "            </span>\n" +
            "            <br>\n" +
            "            <br>\n" +
            "            <span>\n" +
            "                Phone: 212-994-7400\n" +
            "            </span>\n" +
            "        </div>\n" +
            "    </section>\n" +
            "</div>");

        $templateCache.put("areas/static/terms_of_use.html", "<div class=\"container terms-container\">\n" +
            "    <h1 class=\"heading-2-green\">\n" +
            "    <span ng-bind-html=\"termsTitle\"></span>\n" +
            "    <small>Last updated {{updatedTime[0]}} {{updatedTime[1]}}, {{updatedTime[2]}}</small>\n" +
            "    </h1>\n" +
            "    <span ng-bind-html=\"termsContent\"></span>\n" +
            "</div>");

        $templateCache.put("areas/user_management/assign_referral_code_dialog.html", "<common-modal modal-header=\"{{headerText}}\">\n" +
            "  <div class=\"modal-header-combined__content assign-referral-code-dialog\">\n" +
            "    <form name=\"assignReferralCodeForm\" id=\"assign-code-form\" class=\"form-horizontal\" novalidate>\n" +
            "      <div class=\"row form-group\">\n" +
            "        <label for=\"code\" class=\"col-sm-3 control-label\">Referral Code</label>\n" +
            "        <div class=\"col-sm-6\">\n" +
            "          <input type=\"text\" class=\"form-control\" id=\"code-input\" name=\"code\" ng-model=\"referralCode.code\" required>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "      <div class=\"row form-group\">\n" +
            "        <label for=\"primary\" class=\"col-sm-3 control-label\">Primary</label>\n" +
            "        <div class=\"\">\n" +
            "          <div class=\"col-sm-4\">\n" +
            "            <input type=\"checkbox\" id=\"primary-checkbox\" name=\"primary\" ng-model=\"referralCode.primary\">\n" +
            "          </div>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "      <div class=\"row modal-header-combined__buttons\">\n" +
            "        <button class=\"right-aligned-btn\" ng-click=\"assignReferralCode()\" ng-disabled=\"invalidForm()\">Assign</button>\n" +
            "        <button class=\"right-aligned-btn btn btn-gray-blue\" ng-click=\"cancelDialog()\">Cancel</button>\n" +
            "      </div>\n" +
            "    </form>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/user_management/dialogs/add_private_access_fund_access_dialog.html", "<common-modal modal-header=\"{{headerText}}\">\n" +
            "  <div class=\"modal-header-combined__content add-fund-access-dialog\">\n" +
            "    <form name=\"addFundAccessForm\" id=\"add-fund-access-form\" class=\"form-horizontal\" novalidate>\n" +
            "      <div class=\"row form-group\">\n" +
            "        <label for=\"fund\" class=\"col-sm-2 control-label\">Fund</label>\n" +
            "        <div class=\"col-sm-6\">\n" +
            "          <select class=\"form-control\" id=\"fund-field\" name=\"fund\" ng-model=\"fund.id\" ng-options=\"fund.id as fund.name for fund in accessFunds\">\n" +
            "          </select>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "      <div class=\"row form-group\">\n" +
            "        <label for=\"limited\" class=\"col-sm-2 control-label\">Limited</label>\n" +
            "        <div class=\"\">\n" +
            "          <div class=\"col-sm-4\">\n" +
            "            <input type=\"checkbox\" id=\"limited-checkbox\" name=\"limited\" ng-model=\"fund.limited\">\n" +
            "          </div>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "      <div class=\"row modal-header-combined__buttons\">\n" +
            "        <button class=\"right-aligned-btn\" id=\"add-button\" ng-click=\"addAccess()\" ng-disabled=\"invalidForm()\">Add</button>\n" +
            "        <button class=\"right-aligned-btn btn btn-gray-blue\" ng-click=\"cancelDialog()\">Cancel</button>\n" +
            "      </div>\n" +
            "    </form>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/user_management/dialogs/view_delegates.html", "<common-modal modal-header=\"Delegates\">\n" +
            "  <div class=\"delegates-modal\">\n" +
            "    <p class=\"sub-title\">\n" +
            "      Delegates for {{ selectedUser.email }}\n" +
            "    </p>\n" +
            "    <table class=\"table\">\n" +
            "      <thead>\n" +
            "        <tr>\n" +
            "          <th class=\"col-md-3\">\n" +
            "            First Name\n" +
            "          </th>\n" +
            "          <th class=\"col-md-3\">\n" +
            "            Last Name\n" +
            "          </th>\n" +
            "          <th class=\"col-md-6\">\n" +
            "            Email\n" +
            "          </th>\n" +
            "        </tr>\n" +
            "      </thead>\n" +
            "      <tbody class=\"custom-scrollbar\">\n" +
            "        <tr ng-repeat=\"delegate in delegates\">\n" +
            "          <td class=\"col-md-3\">\n" +
            "            {{delegate.first_name}}\n" +
            "          </td>\n" +
            "          <td class=\"col-md-3\">\n" +
            "            {{delegate.last_name}}\n" +
            "          </td>\n" +
            "          <td class=\"col-md-6\">\n" +
            "            {{delegate.email}}\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "      </tbody>\n" +
            "    </table>\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"row\">\n" +
            "    <button class=\"button__reject\" ng-click=\"closeDialog()\">Close</button>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/user_management/dialogs/view_investor_representative.html", "<common-modal modal-header=\"{{ isInvestor ? 'Investors' : 'Representatives' }}\">\n" +
            "  <div class=\"investor-representative-modal\">\n" +
            "    <p class=\"sub-title\">\n" +
            "      {{subTitle}}\n" +
            "    </p>\n" +
            "    <table class=\"table\">\n" +
            "      <thead>\n" +
            "        <tr>\n" +
            "          <th class=\"col-md-2\">\n" +
            "            First Name\n" +
            "          </th>\n" +
            "          <th class=\"col-md-2\">\n" +
            "            Last Name\n" +
            "          </th>\n" +
            "          <th class=\"col-md-5\">\n" +
            "            Email\n" +
            "          </th>\n" +
            "          <th class=\"col-md-3\">\n" +
            "            Status\n" +
            "          </th>\n" +
            "        </tr>\n" +
            "      </thead>\n" +
            "      <tbody class=\"custom-scrollbar\">\n" +
            "        <tr ng-repeat=\"user in users\">\n" +
            "          <td class=\"col-md-2\">\n" +
            "            {{user.first_name}}\n" +
            "          </td>\n" +
            "          <td class=\"col-md-2\">\n" +
            "            {{user.last_name}}\n" +
            "          </td>\n" +
            "          <td class=\"col-md-5\">\n" +
            "            {{user.email}}\n" +
            "          </td>\n" +
            "          <td class=\"col-md-3\">\n" +
            "            {{user.status_label}}\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "      </tbody>\n" +
            "    </table>\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"row\">\n" +
            "    <button class=\"button__reject\" ng-click=\"closeDialog()\">Close</button>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("areas/user_management/tabs/user/pages/export/user_export.html", "<icn-paf-admin-nav type=\"user_management\"></icn-paf-admin-nav>\n" +
            "<div class=\"alert alert-info\" ng-if=\"job_submission_status\">{{job_submission_status}}</div>\n" +
            "<div class=\"alert alert-danger\" ng-if=\"showExportErrorMessage\">You must select at least one of the following: White Label Partner, Firm, or Email Domain</div>\n" +
            "<div class=\"user-management\">\n" +
            "  <form class=\"form-horizontal well\" id=\"user-edit-form\" name=\"userEditForm\">\n" +
            "    <div class=\"form-header\">\n" +
            "      <h3>Export Users</h3>\n" +
            "    </div>\n" +
            "    <div class=\"form-group\">\n" +
            "      <label for=\"user_wlp\" class=\"col-sm-2 control-label wlp-label\">White Label Partner</label>\n" +
            "      <div class=\"col-sm-4\">\n" +
            "        <select class=\"form-control\" id=\"user-wlp\" name=\"user_wlp\"\n" +
            "                ng-model=\"searchParams.white_label_partner\"\n" +
            "                ng-options=\"wlp as wlp.name for wlp in whiteLabelPartners track by wlp.id\">\n" +
            "          <option value=\"\">All</option>\n" +
            "        </select>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"form-group user-export\">\n" +
            "      <label class=\"col-sm-2 control-label wlp-label\">Firm</label>\n" +
            "      <ui-select ng-model=\"searchParams.firm_options\" theme=\"bootstrap\" class=\"col-md-4\">\n" +
            "        <ui-select-match placeholder=\"Search\">{{$select.selected}}</ui-select-match>\n" +
            "        <ui-select-choices repeat=\"firmOption in firmOptions | filter: $select.search | limitTo:20\">\n" +
            "          <span ng-bind-html=\"firmOption\"></span>\n" +
            "      </ui-select>\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"form-group user-export\">\n" +
            "      <label class=\"col-sm-2 control-label wlp-label\">Email Domain</label>\n" +
            "      <ui-select ng-model=\"searchParams.mail_domain_options\" theme=\"bootstrap\" class=\"col-md-4\">\n" +
            "        <ui-select-match placeholder=\"Search\">{{$select.selected}}</ui-select-match>\n" +
            "        <ui-select-choices repeat=\"mailDomainOption in mailDomainOptions | filter: $select.search | limitTo:20\">\n" +
            "          <span ng-bind-html=\"mailDomainOption\"></span>\n" +
            "      </ui-select>\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"form-group\">\n" +
            "      <label for=\"user_last_name\" class=\"col-sm-2 control-label\">Accreditation</label>\n" +
            "      <div class=\"col-sm-4\">\n" +
            "        <select class=\"form-control\"\n" +
            "                name=\"user_wlp\"\n" +
            "                ng-model=\"searchParams.accreditation\"\n" +
            "                ng-options=\"option as option for option in accreditationOptions\">\n" +
            "          <option value=\"\">All</option>\n" +
            "        </select>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"form-group\">\n" +
            "      <label for=\"user_roles\" class=\"col-sm-2 control-label\">Select Roles</label>\n" +
            "      <div class=\"col-sm-4\">\n" +
            "        <select multiple class=\"form-control\"\n" +
            "                id=\"user-roles\" name=\"user_roles\"\n" +
            "                ng-model=\"searchParams.roles\"\n" +
            "                ng-options=\"role as role for role in rolesForManagement()\">\n" +
            "        </select>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"form-group\">\n" +
            "      <label class=\"col-sm-2 control-label wlp-label\">State</label>\n" +
            "      <div class=\"col-sm-4\">\n" +
            "        <select class=\"form-control\" name=\"section_a_individual_state\"\n" +
            "                ng-model=\"searchParams.state\"\n" +
            "                ng-options=\"state.abbreviation as state.abbreviation for state in us_states\">\n" +
            "          <option value=\"\">All</option>\n" +
            "        </select>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"form-group\">\n" +
            "      <label class=\"col-sm-2 control-label\">Total Value of Investments</label>\n" +
            "      <div class=\"col-sm-4\">\n" +
            "        <select multiple class=\"form-control\" name=\"investment_value_options\"\n" +
            "                ng-model=\"searchParams.investment_value_options\"\n" +
            "                ng-options=\"currOption for currOption in investmentValueOptions\">\n" +
            "        </select>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"form-group\">\n" +
            "        <label class=\"col-sm-2 control-label\">Net Worth</label>\n" +
            "        <div class=\"col-sm-4\">\n" +
            "            <select multiple class=\"form-control\" name=\"net_worth_options\"\n" +
            "                    ng-model=\"searchParams.net_worth_options\"\n" +
            "                    ng-options=\"currOption for currOption in netWorthOptions\">\n" +
            "            </select>\n" +
            "        </div>\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"form-group\">\n" +
            "      <div class=\"col-sm-offset-2 col-sm-10\">\n" +
            "        <button type=\"submit\" class=\"btn btn-blue\" ng-click=\"userExport()\">Run Report</button>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </form>\n" +
            "</div>");

        $templateCache.put("areas/user_management/tabs/user/pages/reverse_solicitation/reverse_solicitation.html", "<icn-paf-admin-nav type=\"user_management\"></icn-paf-admin-nav>\n" +
            "\n" +
            "<div class=\"reverse-solicitation\">\n" +
            "    <div class='row'>\n" +
            "        <div class='col-md-12'>\n" +
            "            <h2>\n" +
            "                Reverse Solicitation\n" +
            "            </h2>\n" +
            "            <div class=\"reverse-solicitation-listing\">\n" +
            "                <div class=\"reverse-solicitation-action-btn-wrapper\">\n" +
            "                    <button class=\"btn \" ng-click=\"deleteUnusedExceptions()\">Delete Unused</button>\n" +
            "                    <button class=\"btn\" ng-click=\"addNewException()\">Add New</button>\n" +
            "                </div>\n" +
            "                <table class=\"icn-table\" name=\"reverseSolicitationTable\" ng-show=\"whiteListExceptions.length > 0\">\n" +
            "                    <thead>\n" +
            "                    <tr class=\"reverseSolicitationList-columnsHeader\">\n" +
            "                        <th ng-click=\"orderBy('fund_name')\">\n" +
            "                            Fund Name\n" +
            "                            <span ng-show=\"orderByField=='fund_name'\"\n" +
            "                                  ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\"></span>\n" +
            "                        </th>\n" +
            "                        <th ng-click=\"orderBy('user_name')\">\n" +
            "                            User Name\n" +
            "                            <span ng-show=\"orderByField=='user_name'\"\n" +
            "                                  ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\"></span>\n" +
            "                        </th>\n" +
            "                        <th ng-click=\"orderBy('created_by')\">\n" +
            "                            Created By\n" +
            "                            <span ng-show=\"orderByField=='created_by'\"\n" +
            "                                  ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\"></span>\n" +
            "                        </th>\n" +
            "                        <th ng-click=\"orderBy('date_created')\">\n" +
            "                            Date Created\n" +
            "                            <span ng-show=\"orderByField=='date_created'\"\n" +
            "                                  ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\"></span>\n" +
            "                        </th>\n" +
            "                        <th ng-click=\"orderBy('date_used')\">\n" +
            "                            Date Used\n" +
            "                            <span ng-show=\"orderByField=='date_used'\"\n" +
            "                                  ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\"></span>\n" +
            "                        </th>\n" +
            "                        <th ng-click=\"orderBy('reason')\">\n" +
            "                            Reason\n" +
            "                            <span ng-show=\"orderByField=='reason'\"\n" +
            "                                  ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\"></span>\n" +
            "                        </th>\n" +
            "                    </tr>\n" +
            "                    </thead>\n" +
            "                    <tbody>\n" +
            "                    <tr class=\"reverse-solicitation-table-row\" ng-repeat=\"exception in whiteListExceptions|orderBy:orderByField:reverseSort track by $index\">\n" +
            "                        <td class=\"reverse-solicitation-table-fund-name\"}>{{exception.fund_name}}</td>\n" +
            "                        <td class=\"reverse-solicitation-table-user-name\">\n" +
            "                            <span>{{exception.user_name}}</span>\n" +
            "                            <br>\n" +
            "                            <span>{{exception.user_email}}</span>\n" +
            "                            <br>\n" +
            "                            <span>{{exception.wlp_name}}</span>\n" +
            "                        </td>\n" +
            "                        <td class=\"reverse-solicitation-table-creator-info\">\n" +
            "                            <span>{{exception.creator_name}}</span>\n" +
            "                            <br>\n" +
            "                            <span>{{exception.creator_email}}</span>\n" +
            "                        </td>\n" +
            "                        <td class=\"reverse-solicitation-table-date-created\">{{exception.date_created}}</td>\n" +
            "                        <td class=\"reverse-solicitation-table-status\" ng-if=\"exception.date_used == 'unused'\">Unused</td>\n" +
            "                        <td class=\"reverse-solicitation-table-status\" ng-if=\"exception.date_used != 'unused'\">{{exception.date_used}}</td>\n" +
            "                        <td class=\"reverse-solicitation-table-reason\">{{exception.reason}}</td>\n" +
            "                    </tr>\n" +
            "                    </tbody>\n" +
            "                </table>\n" +
            "            </div>\n" +
            "        </div>\n" +
            "    </div>\n" +
            "</div>");

        $templateCache.put("areas/user_management/tabs/user/pages/user_management/user_management.html", "<icn-paf-admin-nav type=\"user_management\"></icn-paf-admin-nav>\n" +
            "\n" +
            "<div class=\"user-management\">\n" +
            "  <div class='row'>\n" +
            "    <div class='col-md-12'>\n" +
            "      <h2>\n" +
            "        User Management\n" +
            "      </h2>\n" +
            "\n" +
            "      <div ng-if='message.text' ng-class='messageAlertClass()'>\n" +
            "          {{ message.text }}\n" +
            "      </div>\n" +
            "\n" +
            "      <div class=\"user-listing\"\n" +
            "           ng-show=\"!showAccreditation && !showEditForm && !showAdminGroups && !showAdminGroupFunds\">\n" +
            "        <form id='userSearch'>\n" +
            "          <div class='form-group'>\n" +
            "            <label for='user_search'>Search for User</label>\n" +
            "            <input class='form-control' id='user_search' ng-model='search'>\n" +
            "          </div>\n" +
            "          <button class=\"btn\" ng-click=\"startSearch()\" ng-disabled=\"!search\">Search</button>\n" +
            "          <button class=\"btn\" ng-click=\"clearSearch()\" ng-if=\"search\">Clear Search</button>\n" +
            "        </form>\n" +
            "\n" +
            "        <div class=\"search-message\" ng-show=\"usersToDisplay.length == 0\">\n" +
            "          No users found\n" +
            "        </div>\n" +
            "        <table class=\"icn-table\" name=\"userManagementTable\" ng-show=\"usersToDisplay.length > 0\">\n" +
            "          <thead>\n" +
            "            <tr class=\"userList-columnsHeader\">\n" +
            "              <th ng-click=\"orderBy('id')\">\n" +
            "                ID\n" +
            "                <span ng-show=\"orderByField=='id'\"\n" +
            "                  ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\">\n" +
            "                </span>\n" +
            "              </th>\n" +
            "              <th ng-click=\"orderBy('name')\">\n" +
            "                Name\n" +
            "                <span ng-show=\"orderByField=='name'\"\n" +
            "                  ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\">\n" +
            "                </span>\n" +
            "              </th>\n" +
            "              <th ng-click=\"orderBy('confirmed_at')\">\n" +
            "                Date Registered\n" +
            "                <span ng-show=\"orderByField=='confirmed_at'\"\n" +
            "                      ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\">\n" +
            "                </span>\n" +
            "              </th>\n" +
            "              <th ng-click=\"orderBy('current_sign_in_at')\">\n" +
            "                Last Login\n" +
            "                <span ng-show=\"orderByField=='current_sign_in_at'\"\n" +
            "                      ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\">\n" +
            "                </span>\n" +
            "              </th>\n" +
            "              <th ng-click=\"orderBy('email')\">\n" +
            "                Email\n" +
            "                <span ng-show=\"orderByField=='email'\"\n" +
            "                  ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\">\n" +
            "                </span>\n" +
            "              </th>\n" +
            "              <th ng-click=\"orderBy('firm.name')\">\n" +
            "                Firm\n" +
            "                <span ng-show=\"orderByField=='firm.name'\"\n" +
            "                  ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\">\n" +
            "                </span>\n" +
            "              </th>\n" +
            "              <th ng-click=\"orderBy('white_label_partner.name')\">\n" +
            "                White Label Partner\n" +
            "                <span ng-show=\"orderByField=='white_label_partner.name'\"\n" +
            "                  ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\">\n" +
            "                </span>\n" +
            "              </th>\n" +
            "              <th class=\"not-clickable\">Roles Assigned</th>\n" +
            "              <th class=\"not-clickable\">Groups Assigned</th>\n" +
            "              <th ng-click=\"orderBy('confirmed')\">\n" +
            "                Confirmed?\n" +
            "                 <span ng-show=\"orderByField=='confirmed'\"\n" +
            "                  ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\">\n" +
            "                </span>\n" +
            "              </th>\n" +
            "              <th class=\"not-clickable\">\n" +
            "                Registered?\n" +
            "              </th>\n" +
            "              <th ng-click=\"orderBy('cooled_off_at')\">\n" +
            "                Cool Off Date\n" +
            "                <span ng-show=\"orderByField=='cooled_off_at'\"\n" +
            "                  ng-class=\"{'arrow-up':!reverseSort, 'arrow-down':reverseSort}\">\n" +
            "                </span>\n" +
            "              </th>\n" +
            "              <th class=\"not-clickable\">\n" +
            "              </th>\n" +
            "            </tr>\n" +
            "          </thead>\n" +
            "          <tbody>\n" +
            "            <tr ng-repeat=\"user in usersToDisplay\">\n" +
            "              <td>{{user.id}}</td>\n" +
            "              <td>{{user.name}}</td>\n" +
            "              <td>{{user.confirmed_at}}</td>\n" +
            "              <td>{{user.last_sign_in_at}}</td>\n" +
            "              <td>{{user.email}}</td>\n" +
            "              <td>{{user.firm.name}}</td>\n" +
            "              <td>{{user.white_label_partner.name}}</td>\n" +
            "              <td><span class=\"role-name\" ng-repeat=\"role in user.roles\">{{role}}</span></td>\n" +
            "              <td><span class=\"role-name\" ng-repeat=\"group in user.groups\">{{group}}</span></td>\n" +
            "              <td>{{user.confirmed ? 'Yes' : 'No'}}</td>\n" +
            "              <td>{{user.accredited ? 'Yes' : 'No'}}</td>\n" +
            "              <td>{{user.cooled_off_at}}</td>\n" +
            "              <td>\n" +
            "                <div class=\"button-stack\">\n" +
            "                  <button class=\"btn view-accreditation-btn\"\n" +
            "                          ng-click=\"viewAccreditation(user)\">\n" +
            "                    View Registration Data\n" +
            "                  </button>\n" +
            "                  <button class=\"btn view-admin-groups-btn\"\n" +
            "                          ng-if=\"hasAdminGroups(user.admin_groups)\"\n" +
            "                          ng-click=\"viewAdminGruops(user)\">\n" +
            "                    View Admin Groups\n" +
            "                  </button>\n" +
            "                  <button class=\"btn edit-user-btn\"\n" +
            "                          ng-if=\"canEditUser(user)\"\n" +
            "                          ng-click=\"editUser(user)\">\n" +
            "                    <i class=\"user-management-edit-user-icon fa fa-pencil\"></i>Edit User\n" +
            "                  </button>\n" +
            "                  <button class=\"btn edit-user-btn\"\n" +
            "                          ng-if=\"hasDelegates(user)\"\n" +
            "                          ng-click=\"viewDelegates(user)\">\n" +
            "                    View Delegates\n" +
            "                  </button>\n" +
            "                  <button class=\"btn edit-user-btn\"\n" +
            "                          ng-if=\"hasInvestors(user)\"\n" +
            "                          ng-click=\"viewInvestors(user)\">\n" +
            "                    View Investors\n" +
            "                  </button>\n" +
            "                  <button class=\"btn edit-user-btn\"\n" +
            "                          ng-if=\"hasRepresentatives(user)\"\n" +
            "                          ng-click=\"viewRepresentatives(user)\">\n" +
            "                    View Representatives\n" +
            "                  </button>\n" +
            "                </div>\n" +
            "              </td>\n" +
            "            </tr>\n" +
            "          </tbody>\n" +
            "        </table>\n" +
            "      </div>\n" +
            "\n" +
            "      <div class=\"user-accreditation\" ng-show=\"showAccreditation\">\n" +
            "        <button class='btn return-to-listing-btn' ng-click='returnToListing()'>Return to Listing</button>\n" +
            "        <div>\n" +
            "          <span ng-repeat='role in selected.roles'><label class='label label-default'>{{role}}</label> </span>\n" +
            "        </div>\n" +
            "          <h3>Contact Card Information</h3>\n" +
            "          <div class='row'>\n" +
            "            <div class='col-md-2'>\n" +
            "              <strong>First Name:</strong>\n" +
            "            </div>\n" +
            "            <div class='col-md-10'>\n" +
            "              {{ selected.first_name }}\n" +
            "            </div>\n" +
            "          </div>\n" +
            "          <div class='row'>\n" +
            "            <div class='col-md-2'>\n" +
            "              <strong>Last Name:</strong>\n" +
            "            </div>\n" +
            "            <div class='col-md-10'>\n" +
            "              {{ selected.last_name }}\n" +
            "            </div>\n" +
            "          </div>\n" +
            "          <div class='row'>\n" +
            "            <div class='col-md-2'>\n" +
            "              <strong>Email:</strong>\n" +
            "            </div>\n" +
            "            <div class='col-md-10'>\n" +
            "              {{ selected.email }}\n" +
            "            </div>\n" +
            "          </div>\n" +
            "\n" +
            "          <div class=\"row\">\n" +
            "            <div class='col-md-2'>\n" +
            "              <strong>Confirmation Link</strong>\n" +
            "            </div>\n" +
            "            <div class=\"col-md-10\">\n" +
            "              <span ng-if=\"selected.confirmation_url\">{{selected.confirmation_url}}</span>\n" +
            "              <span ng-if=\"!selected.confirmation_url\">User confirmed</span>\n" +
            "            </div>\n" +
            "          </div>\n" +
            "\n" +
            "          <div ng-if='showRegistrationDataSection'>\n" +
            "            <h3>Registration Data</h3>\n" +
            "            <table class='icn-table'>\n" +
            "              <thead>\n" +
            "                <th>Key</th>\n" +
            "                <th>Value</th>\n" +
            "              </thead>\n" +
            "              <tr ng-if='selected.registration_answer'\n" +
            "                ng-repeat='(key, value) in selected.registration_answer[0].answers'>\n" +
            "                <td>{{ key }}</td>\n" +
            "                <td>{{ value }}</td>\n" +
            "              </tr>\n" +
            "            </table>\n" +
            "\n" +
            "            <button class='btn' ng-click='addResetAccreditationReason()' ng-if=\"isUserAdmin() && !hasInvestorRepresentativeRecord\"\n" +
            "              ng-disabled='!allowAccreditationReset'>\n" +
            "              Reset Registration\n" +
            "            </button>\n" +
            "            <span ng-if=\"hasInvestorRepresentativeRecord\">You are not able to reset the registration for this user because it is an\n" +
            "              investor role user that may represent investors or have representatives.</span>\n" +
            "          </div>\n" +
            "      </div>\n" +
            "\n" +
            "      <div class=\"user-admin-group\" ng-show=\"showAdminGroups\">\n" +
            "        <button class='btn return-to-listing-btn' ng-click='returnToListing()'>Return to Listing</button>\n" +
            "\n" +
            "        <div>\n" +
            "          <span ng-repeat='role in selectedUser.roles'><label class='label label-default'>{{role}}</label> </span>\n" +
            "        </div>\n" +
            "        <h3>Contact Card Information</h3>\n" +
            "        <div class='row'>\n" +
            "          <div class='col-md-2'>\n" +
            "            <strong>First Name:</strong>\n" +
            "          </div>\n" +
            "          <div class='col-md-10'>\n" +
            "            {{ selectedUser.first_name }}\n" +
            "          </div>\n" +
            "        </div>\n" +
            "        <div class='row'>\n" +
            "          <div class='col-md-2'>\n" +
            "            <strong>Last Name:</strong>\n" +
            "          </div>\n" +
            "          <div class='col-md-10'>\n" +
            "            {{ selectedUser.last_name }}\n" +
            "          </div>\n" +
            "        </div>\n" +
            "        <div class='row'>\n" +
            "          <div class='col-md-2'>\n" +
            "            <strong>Email:</strong>\n" +
            "          </div>\n" +
            "          <div class='col-md-10'>\n" +
            "            {{ selectedUser.email }}\n" +
            "          </div>\n" +
            "        </div>\n" +
            "\n" +
            "        <div class=\"row\">\n" +
            "          <div class='col-md-2'>\n" +
            "            <strong>Confirmation Link</strong>\n" +
            "          </div>\n" +
            "          <div class=\"col-md-10\">\n" +
            "            <span ng-if=\"selectedUser.confirmation_url\">{{selectedUser.confirmation_url}}</span>\n" +
            "            <span ng-if=\"!selectedUser.confirmation_url\">User confirmed</span>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "\n" +
            "        <div ng-if='Object.keys(selectedUser.admin_groups).length != 0'>\n" +
            "          <h3>Admin Groups</h3>\n" +
            "          <table class='icn-table'>\n" +
            "            <thead>\n" +
            "            <th>GROUP NAME</th>\n" +
            "            <th>NUMBER OF FUNDS</th>\n" +
            "            </thead>\n" +
            "            <tr ng-repeat='(key, value) in massagedSelectedUser.massaged_admin_groups_data'>\n" +
            "              <td ng-click=\"viewSelectedAdminGroupFunds(key, massagedSelectedUser.massaged_admin_groups_funds_data[key])\">\n" +
            "                <span class=\"user-management-admin-group-link\">\n" +
            "                  {{ key }}\n" +
            "                </span>\n" +
            "              </td>\n" +
            "              <td>\n" +
            "                {{ value }}\n" +
            "              </td>\n" +
            "            </tr>\n" +
            "          </table>\n" +
            "\n" +
            "          <button class='btn return-to-listing-btn'\n" +
            "                  ng-click='viewAllAdminGroupFunds(massagedSelectedUser.massaged_admin_groups_funds_data)'>\n" +
            "            VIEW ALL FUNDS\n" +
            "          </button>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "\n" +
            "      <div class=\"user-admin-group\" ng-show=\"showAdminGroupFunds\">\n" +
            "        <button class='btn return-to-listing-btn' ng-click='returnToListing()'>Return to Listing</button>\n" +
            "        <button class='btn return-to-listing-btn' ng-click='returnToAdminGroups()'>Return to Admin Groups</button>\n" +
            "        <div>\n" +
            "          <span ng-repeat='role in selectedUser.roles'><label class='label label-default'>{{role}}</label> </span>\n" +
            "        </div>\n" +
            "        <h3>Contact Card Information</h3>\n" +
            "        <div class='row'>\n" +
            "          <div class='col-md-2'>\n" +
            "            <strong>First Name:</strong>\n" +
            "          </div>\n" +
            "          <div class='col-md-10'>\n" +
            "            {{ selectedUser.first_name }}\n" +
            "          </div>\n" +
            "        </div>\n" +
            "        <div class='row'>\n" +
            "          <div class='col-md-2'>\n" +
            "            <strong>Last Name:</strong>\n" +
            "          </div>\n" +
            "          <div class='col-md-10'>\n" +
            "            {{ selectedUser.last_name }}\n" +
            "          </div>\n" +
            "        </div>\n" +
            "        <div class='row'>\n" +
            "          <div class='col-md-2'>\n" +
            "            <strong>Email:</strong>\n" +
            "          </div>\n" +
            "          <div class='col-md-10'>\n" +
            "            {{ selectedUser.email }}\n" +
            "          </div>\n" +
            "        </div>\n" +
            "\n" +
            "        <div class=\"row\">\n" +
            "          <div class='col-md-2'>\n" +
            "            <strong>Confirmation Link</strong>\n" +
            "          </div>\n" +
            "          <div class=\"col-md-10\">\n" +
            "            <span ng-if=\"selectedUser.confirmation_url\">{{selectedUser.confirmation_url}}</span>\n" +
            "            <span ng-if=\"!selectedUser.confirmation_url\">User confirmed</span>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "\n" +
            "        <div ng-if='Object.keys(adminGroupFundsForUser).length != 0'>\n" +
            "          <h3>Admin Groups | {{ adminGroupFundsPageTitle }}</h3>\n" +
            "          <table class='icn-table'>\n" +
            "            <thead>\n" +
            "            <th>FUND NAME</th>\n" +
            "            <th>PAF ID</th>\n" +
            "            <th ng-if=\"isShowALLAdminGroupFunds\">ADMIN GROUPS</th>\n" +
            "            </thead>\n" +
            "            <tr ng-repeat=\"fundObj in adminGroupFundsForUser | orderBy: 'fund_name'\">\n" +
            "              <td>{{ fundObj.fund_name }}</td>\n" +
            "              <td>{{ fundObj.paf_id }}</td>\n" +
            "              <td class=\"user-management-co-exist-admin-group-column\"\n" +
            "                  ng-if=\"isShowALLAdminGroupFunds\">\n" +
            "                <span ng-repeat=\"adminGroupName in fundObj.co_exist_admin_group | orderBy\">\n" +
            "                  {{ adminGroupName }}\n" +
            "                </span>\n" +
            "              </td>\n" +
            "            </tr>\n" +
            "          </table>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "\n" +
            "      <div class=\"user-edit\" ng-if=\"showEditForm\">\n" +
            "        <form class=\"form-horizontal well\" id=\"user-edit-form\" name=\"userEditForm\">\n" +
            "          <div class=\"form-header\">\n" +
            "            <h3>Edit User</h3>\n" +
            "            <button class='btn' ng-click='returnToListing()'>Return to Listing</button>\n" +
            "            <button class=\"btn edit-user-btn\" ng-if=\"userToEdit && userToEdit.account_locked\" ng-click=\"unlockAccount(userToEdit)\">Unlock Account</button>\n" +
            "            <button class=\"btn edit-user-btn\" ng-if=\"userToEdit && canEditUser(userToEdit) && userToEdit.is_ria\" ng-click=\"editReferralCodes(userToEdit)\">Referral Codes</button>\n" +
            "            <button class=\"btn edit-user-btn\" ng-if=\"userToEdit && canEditUser(userToEdit) || allowedProfile(userToEdit)\" ng-click=\"editAccessFunds(userToEdit)\">Private Access Funds</button>\n" +
            "          </div>\n" +
            "          <div class=\"form-group\">\n" +
            "              <label class=\"col-sm-2 control-label\">Created On:</label>\n" +
            "              <div class=\"col-sm-4\">\n" +
            "                  {{userToEdit.created_at}}\n" +
            "              </div>\n" +
            "          </div>\n" +
            "          <div class=\"form-group\">\n" +
            "            <label for=\"user_first_name\" class=\"col-sm-2 control-label\">First Name</label>\n" +
            "            <div class=\"col-sm-4\">\n" +
            "              <input type=\"text\" class=\"form-control\" id=\"user-first-name\" name=\"user_first_name\" ng-model=\"userToEdit.first_name\">\n" +
            "            </div>\n" +
            "          </div>\n" +
            "          <div class=\"form-group\">\n" +
            "            <label for=\"user_last_name\" class=\"col-sm-2 control-label\">Last Name</label>\n" +
            "            <div class=\"col-sm-4\">\n" +
            "              <input type=\"text\" class=\"form-control\" id=\"user-last-name\" name=\"user_last_name\" ng-model=\"userToEdit.last_name\">\n" +
            "            </div>\n" +
            "          </div>\n" +
            "          <div class=\"form-group\" ng-class=\"{'has-error': emailNotUnique}\">\n" +
            "            <label for=\"user_email\" class=\"col-sm-2 control-label\">Email *</label>\n" +
            "            <div class=\"col-sm-4\">\n" +
            "              <input type=\"email\" class=\"form-control\" id=\"user-email\" name=\"user_email\" ng-model=\"userToEdit.email\" ng-blur=\"emailUnique(userToEdit.email, userToEdit.white_label_partner.id)\" validator=\"[requiredBlur, emailFormat]\" required>\n" +
            "              <label class=\"control-label error\" for=\"cuser-email\" ng-if=\"emailNotUnique\">This email is already taken</label>\n" +
            "            </div>\n" +
            "          </div>\n" +
            "          <div class=\"form-group\">\n" +
            "            <label for=\"user_password\" class=\"col-sm-2 control-label wlp-label\">Password</label>\n" +
            "            <div class=\"col-sm-4\">\n" +
            "              <input type=\"password\" class=\"form-control\" name=\"user_password\" ng-model=\"userToEdit.password\" ng-attr-validator=\"{{!!userToEdit.password && '[password]'}}\" ng-change=\"passwordMatch(userToEdit.password, userToEdit.password_confirmation)\">\n" +
            "            </div>\n" +
            "          </div>\n" +
            "          <div class=\"form-group\">\n" +
            "            <label for=\"user_password_confirmation\" class=\"col-sm-2 control-label wlp-label\">Password Confirmation</label>\n" +
            "            <div class=\"col-sm-4\">\n" +
            "              <input type=\"password\" class=\"form-control\" name=\"user_password_confirmation\" ng-model=\"userToEdit.password_confirmation\" ng-attr-validator=\"{{!!userToEdit.password_confirmation && '[password]' && '[passwordConfirmation]'}}\" ng-change=\"passwordMatch(userToEdit.password, userToEdit.password_confirmation)\">\n" +
            "              <label class=\"control-label error\" for=\"user_password_confirmation\" ng-if=\"passwordDoesntMatch\">Passwords don't match</label>\n" +
            "            </div>\n" +
            "          </div>\n" +
            "          <div class=\"form-group\">\n" +
            "            <label for=\"needs_password_reset\" class=\"col-sm-2 control-label wlp-label\">Needs Password Reset</label>\n" +
            "            <div class=\"col-sm-4\">\n" +
            "             <input type=\"checkbox\" class=\"disable-checkbox\" ng-model=\"userToEdit.needs_password_reset\" />\n" +
            "            </div>\n" +
            "          </div>\n" +
            "          <div class=\"form-group\">\n" +
            "            <label for=\"disable_account\" class=\"col-sm-2 control-label wlp-label\">Disable Account</label>\n" +
            "            <div class=\"col-sm-4\">\n" +
            "             <input type=\"checkbox\" id=\"disable-checkbox\" class=\"disable-checkbox\" ng-model=\"userToEdit.disabled\" />\n" +
            "            </div>\n" +
            "          </div>\n" +
            "          <div class=\"form-group\" ng-if=\"userToEdit.disabled\">\n" +
            "            <label for=\"disabled_reason\" class=\"col-sm-2 control-label wlp-label\">Disabled Reason</label>\n" +
            "            <div class=\"col-sm-4\">\n" +
            "              <input type=\"text\" class=\"form-control\" name=\"disabled_reason\" ng-model=\"userToEdit.disabled_reason\">\n" +
            "            </div>\n" +
            "          </div>\n" +
            "          <div class=\"form-group\">\n" +
            "            <label for=\"user_wlp\" class=\"col-sm-2 control-label wlp-label\">White Label Partner</label>\n" +
            "            <div class=\"col-sm-4\">\n" +
            "              {{userToEdit.white_label_partner.name}}\n" +
            "            </div>\n" +
            "          </div>\n" +
            "          <div class=\"form-group\">\n" +
            "            <label for=\"user_firm_name\" class=\"col-sm-2 control-label wlp-label\">User Firm *</label>\n" +
            "            <div class=\"col-sm-4\">\n" +
            "              <input class=\"accreditation-input-advisorLegalName form-control\" name=\"user_firm_name\" style=\"width:100%;\"\n" +
            "                 ng-model=\"userToEdit.firm.name\" type=\"text\" ng-blur=\"formBlur.firm_blur=true\" autocomplete='off'\n" +
            "                 typeahead='firm.name for firm in firmsForRegistration($viewValue) | limitTo:5'\n" +
            "                 ng-model-options='{ debounce: 300 }'\n" +
            "                 typeahead-on-select='selectFirmRecord($item)'/>\n" +
            "              <label for=\"user_firm_crd\">CRD Number: {{crdNumber(userToEdit)}}</label>\n" +
            "            </div>\n" +
            "          </div>\n" +
            "          <div class=\"form-group\">\n" +
            "            <label for=\"manual_firm_entry\" class=\"col-sm-2 control-label wlp-label\">Manual Firm entry</label>\n" +
            "            <div class=\"col-sm-4\">\n" +
            "              <input class=\"accreditation-input-advisorLegalName form-control\" name=\"manual_firm_entry\" style=\"width:100%;\"\n" +
            "                ng-model=\"userToEdit.answer_firm_name\" type=\"text\" ng-disabled=\"true\"/>\n" +
            "            </div>\n" +
            "          </div>\n" +
            "          <div class=\"form-group\">\n" +
            "            <label for=\"manual_firm_crd_entry\" class=\"col-sm-2 control-label wlp-label\">Manual Firm CRD entry</label>\n" +
            "            <div class=\"col-sm-4\">\n" +
            "              <input class=\"accreditation-input-advisorLegalName form-control\" name=\"manual_firm_crd_entry\" style=\"width:100%;\"\n" +
            "                ng-value=\"manualCrdValue(userToEdit)\" type=\"text\" ng-disabled=\"true\"/>\n" +
            "            </div>\n" +
            "          </div>\n" +
            "          <div class=\"form-group\">\n" +
            "            <label for=\"generic_firm_given\" class=\"col-sm-2 control-label\">Generic Firm given to user?</label>\n" +
            "            <div class=\"col-sm-2\">\n" +
            "              <input type=\"radio\" value=\"true\" id=\"generic-yes-radio\" ng-model=\"userToEdit.generic_firm_given\" ng-disabled=\"true\"/>\n" +
            "              <label for=\"generic-yes-radio\">Yes</label>\n" +
            "            </div>\n" +
            "            <div class=\"col-sm-2\">\n" +
            "              <input type=\"radio\" value=\"false\" id=\"generic-no-radio\" ng-model=\"userToEdit.generic_firm_given\" ng-disabled=\"true\"/>\n" +
            "              <label for=\"generic-no-radio\">No</label>\n" +
            "            </div>\n" +
            "          </div>\n" +
            "\n" +
            "          <div class=\"form-group\" ng-if=\"userIsRiaWithAccreditation(userToEdit)\">\n" +
            "            <label for=\"manual_advisor_crd_number\" class=\"col-sm-2 control-label no-left-padding\">Manual Advisor CRD entry</label>\n" +
            "            <div class=\"col-sm-4\">\n" +
            "              <input type=\"text\" class=\"form-control\" id=\"manual-advisor-crd-number\" name=\"manual_advisor_crd_number\"\n" +
            "                ng-model=\"userToEdit.answer_advisor_crd_number\">\n" +
            "            </div>\n" +
            "          </div>\n" +
            "          <div class=\"form-group\" ng-class=\"{'has-error': !validDate()}\">\n" +
            "            <label for=\"cooled_off_at\" class=\"col-sm-2 control-label\">Cooled Off Date *</label>\n" +
            "            <div class=\"col-sm-4\">\n" +
            "              <icn-date-picker ng-model=\"userToEdit.cooled_off_at\" validate=\"validDate()\" errors=\"dateErrors\"></icn-date-picker>\n" +
            "              <label class=\"control-label error\" for=\"cooled-off-date\" ng-if=\"!userToEdit.cooled_off_at\">This field is required.</label>\n" +
            "              <label class=\"control-label error\" for=\"cooled-off-date\" ng-if=\"!validDate()\">Date must be in m/d/yy format.</label>\n" +
            "            </div>\n" +
            "          </div>\n" +
            "          <div class=\"form-group\" ng-show=\"showAccreditUser(userToEdit)\">\n" +
            "            <label for=\"user_accredit\" class=\"col-sm-2 control-label\">Approve User</label>\n" +
            "            <div class=\"col-sm-10\">\n" +
            "              <input type=\"checkbox\" id=\"accredit-checkbox\" ng-disabled=\"hasInvestorRepresentativeRecord\" ng-click=\"clearAccreditationFields()\" class=\"disable-checkbox\" ng-model=\"userToEdit.accredit\" />\n" +
            "              <span ng-if=\"hasInvestorRepresentativeRecord\" class=\"accredit-user-label\">You are not able to accredit this user because it is an investor role that may represent investors or have representatives.</span>\n" +
            "            </div>\n" +
            "          </div>\n" +
            "          <div class=\"form-group\" ng-show=\"isQpUnaccredited(userToEdit) && userToEdit.accredit\">\n" +
            "            <label for=\"qp_ai_accredit\" class=\"col-sm-2 control-label\">Approve As:</label>\n" +
            "            <div class=\"col-sm-2\">\n" +
            "              <input type=\"radio\" value='qp_default' id=\"accredit-qp-radio\" class=\"accredit-as-radio\" ng-model=\"userToEdit.accredit_role\"/>\n" +
            "              <label for=\"accredit-qp-radio\">QP User</label>\n" +
            "            </div>\n" +
            "            <div class=\"col-sm-2\">\n" +
            "              <input type=\"radio\" value='ai_default' id=\"accredit-ai-radio\" class=\"accredit-as-radio\" ng-model=\"userToEdit.accredit_role\"/>\n" +
            "              <label for=\"accredit-ai-radio\">AI User</label>\n" +
            "            </div>\n" +
            "          </div>\n" +
            "	        <div class=\"form-group\" ng-show=\"userToEdit.accredit\">\n" +
            "		        <label for=\"send_notification\" class=\"col-sm-2 control-label\">Send Welcome Email: *</label>\n" +
            "		        <div class=\"col-sm-1\">\n" +
            "			        <input type=\"radio\" value=\"false\" id=\"dont-send-welcome-email\" ng-model=\"userToEdit.send_welcome\"/>\n" +
            "			        <label for=\"dont-send-welcome-email\">No</label>\n" +
            "		        </div>\n" +
            "		        <div class=\"col-sm-1\">\n" +
            "			        <input type=\"radio\" value=\"true\" id=\"send-welcome-email\" ng-model=\"userToEdit.send_welcome\"/>\n" +
            "			        <label for=\"send-welcome-email\">Yes</label>\n" +
            "		        </div>\n" +
            "	        </div>\n" +
            "          <!-- Country -->\n" +
            "          <div class=\"form-group\" ng-show=\"showCountryList\">\n" +
            "            <label for=\"user_country\" class=\"col-sm-2 control-label\">Country *</label>\n" +
            "            <div class=\"col-sm-10\">\n" +
            "              <select class=\"form-control\" id=\"country-field\" name=\"country\" ng-model=\"userToEdit.country\"\n" +
            "                ng-options=\"country as country.name for country in countryList track by country.id\">\n" +
            "              </select>\n" +
            "            </div>\n" +
            "          </div>\n" +
            "          <!-- IQS -->\n" +
            "          <div class=\"form-group\">\n" +
            "            <label for=\"user_iqs\" class=\"col-sm-2 control-label\">Investor Qualification Standards</label>\n" +
            "            <div class=\"col-sm-10\">\n" +
            "              <table class='icn-table'>\n" +
            "                <thead>\n" +
            "                  <th>Name</th>\n" +
            "                  <th></th>\n" +
            "                </thead>\n" +
            "                <tr ng-repeat=\"iqsObj in investor_qualification_standards\">\n" +
            "                  <td>{{ iqsObj.name }}</td>\n" +
            "                  <td>\n" +
            "                    <input type=\"checkbox\" id=\"{{iqsObj.id}}\" ng-model=\"iqsObj.check\" class=\"disable-checkbox\" />\n" +
            "                  </td>\n" +
            "                </tr>\n" +
            "              </table>\n" +
            "            </div>\n" +
            "          </div>\n" +
            "          <div class=\"form-group\">\n" +
            "            <div class=\"col-sm-offset-2 col-sm-10\">\n" +
            "              <button type=\"submit\" class=\"btn btn-blue\" ng-disabled=\"invalidForm()\" ng-click=\"updateUser(userToEdit)\">Update</button>\n" +
            "              <button class=\"btn btn-blue\" ng-click=\"returnToListing()\">Cancel</button>\n" +
            "            </div>\n" +
            "          </div>\n" +
            "        </form>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"amlDocumentsList-pagging pull-right clearfix\" ng-hide=\"empty || showAdminGroups || showAdminGroupFunds\">\n" +
            "    <span class=\"amlDocumentsList-pagging-text\">Viewing <b>{{ pager.startingIndexOnCurrentPage(pagerName) }} -\n" +
            "      {{ pager.endingIndexOnCurrentPage(pagerName) }}</b></span>\n" +
            "    <button class=\"button__arrow-left\" ng-disabled=\"pager.isFirstPage(pagerName)\" ng-click=\"prevPage()\" />\n" +
            "    <button class=\"button__arrow-right\" ng-disabled=\"pager.isLastPage(pagerName)\" ng-click=\"nextPage()\" />\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/user_management/user_management_access_funds.html", "<div class=\"user-management-access-funds\">\n" +
            "  <div class='row'>\n" +
            "    <h2>\n" +
            "      User Management - Private Access Funds ({{userName}})\n" +
            "    </h2>\n" +
            "    <div class='col-md-6'>\n" +
            "      <div class=\"user-management-access-funds__top-buttons-section\">\n" +
            "        <button class=\"btn\" ng-click=\"returnToListing()\">Return To Listing</button>\n" +
            "        <button class=\"btn\" ng-click=\"openAddPrivateAccessFundAccessDialog()\">Add Fund Access</button>\n" +
            "      </div>\n" +
            "      <div ng-if=\"message.msg\" class=\"alert {{message.cssClass}}\">\n" +
            "        {{ message.msg }}\n" +
            "      </div>\n" +
            "      <table class=\"icn-table\" name=\"accessFundsTable\" ng-show=\"userAccessFunds.length > 0\">\n" +
            "        <thead>\n" +
            "          <tr class=\"userList-columnsHeader\">\n" +
            "            <th>\n" +
            "              Access Fund\n" +
            "            </th>\n" +
            "            <th class=\"text-center\">\n" +
            "              Limited\n" +
            "            </th>\n" +
            "            <th>\n" +
            "            </th>\n" +
            "          </tr>\n" +
            "        </thead>\n" +
            "        <tbody>\n" +
            "          <tr ng-repeat=\"fund in userAccessFunds|orderBy:name:reverseSort\">\n" +
            "            <td>{{fund.name}}</td>\n" +
            "            <td><input type=\"checkbox\" ng-model=\"fund.limited\" name=\"limited\" id=\"limited-access-checkbox\" value=\"{{fund.limited}}\" ng-checked=\"{{fund.limited}}\" ng-change=\"setLimited(fund)\"></td>\n" +
            "            <td>\n" +
            "              <button class=\"btn centered\" ng-click=\"removeFund(fund)\">Remove</button>\n" +
            "            </td>\n" +
            "          </tr>\n" +
            "        </tbody>\n" +
            "      </table>\n" +
            "      <div class=\"no-data-message\" ng-show=\"userAccessFunds.length == 0\">\n" +
            "        This user does not have access to any reverse inquiry funds.\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("areas/user_management/user_management_referral_codes.html", "<div class=\"user-management-referral-codes\">\n" +
            "  <div class='row'>\n" +
            "    <h2>\n" +
            "      User Management - Referral Codes ({{userName}})\n" +
            "    </h2>\n" +
            "    <div class='col-md-6'>\n" +
            "      <div class=\"user-management-referral-codes__top-buttons-section\">\n" +
            "        <button class=\"btn\" ng-click=\"returnToListing()\">Return To Listing</button>\n" +
            "        <button class=\"btn\" ng-click=\"openAssignReferralCodeDialog()\">Add Referral Code</button>\n" +
            "      </div>\n" +
            "      <div ng-if=\"message.msgs\" class=\"alert {{message.cssClass}}\">\n" +
            "        <ul>\n" +
            "          <li class=\"no-bullet\" ng-repeat=\"message in message.msgs\">{{message}}</li>\n" +
            "        </ul>\n" +
            "      </div>\n" +
            "      <table class=\"icn-table\" name=\"referralCodesTable\" ng-show=\"referralCodes.length > 0\">\n" +
            "        <thead>\n" +
            "          <tr class=\"userList-columnsHeader\">\n" +
            "            <th>\n" +
            "              Referral Code\n" +
            "            </th>\n" +
            "            <th class=\"text-center\">\n" +
            "              Primary\n" +
            "            </th>\n" +
            "            <th>\n" +
            "            </th>\n" +
            "          </tr>\n" +
            "        </thead>\n" +
            "        <tbody>\n" +
            "          <tr ng-repeat=\"code in referralCodes|orderBy:code:reverseSort\">\n" +
            "            <td>{{code.code}}</td>\n" +
            "            <td><input type=\"radio\" ng-model=\"code.primary\" name=\"code_primary\" id=\"primary-code-radio\" value=\"{{code.id}}\" ng-checked=\"{{code.primary}}\" ng-change=\"makePrimary(code)\"></td>\n" +
            "            <td>\n" +
            "              <button class=\"btn centered\" ng-click=\"unassignCode(code)\">Remove</button>\n" +
            "            </td>\n" +
            "          </tr>\n" +
            "        </tbody>\n" +
            "      </table>\n" +
            "      <div class=\"no-data-message\" ng-show=\"referralCodes.length == 0\">\n" +
            "        This user has no referral codes assigned.\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("ckeditor/plugins/htmlbuttons/docs/install.html", "<!DOCTYPE HTML PUBLIC \"-//W3C//DTD HTML 4.01 Transitional//EN\"\n" +
            "	\"http://www.w3.org/TR/html4/loose.dtd\">\n" +
            "<html lang=\"en\">\n" +
            "<head>\n" +
            "<meta http-equiv=\"Content-Type\" content=\"text/html; charset=utf-8\">\n" +
            "<title>HTML Buttons plugin</title>\n" +
            "<link href=\"styles.css\" rel=\"stylesheet\" type=\"text/css\">\n" +
            "</head>\n" +
            "\n" +
            "<body>\n" +
            "<h1>HTML Buttons Plugin for CKEditor</h1>\n" +
            "\n" +
            "<h2>Introduction</h2>\n" +
            "<p>This is plugin helps creating custom buttons to insert a block of desired HTML in <a href=\"http://www.ckeditor.com\">CKEditor</a>.</p>\n" +
            "<p>Sample icons from <a href='http://tango.freedesktop.org'>Tango!</a></p>\n" +
            "\n" +
            "<h3 id=\"contact\">Author:</h3>\n" +
            "<p><a href=\"mailto:amla70@gmail.com\">Alfonso Mart&iacute;nez de Lizarrondo</a></p>\n" +
            "<h3>Sponsored by:</h3>\n" +
            "<h3>Version history: </h3>\n" +
            "<ol>\n" +
            "  <li>1.0: 23-May-2012. First version.</li>\n" +
            "  <li>1.5.1: 02-Sep-2014<br>\n" +
            "		Includes changes proposed by Eric Arnol-Martin to wrap the existing selection with the HTML snippet (instead of only inserting and overwritting the selection).<br>\n" +
            "		It includes also the proposal by Julian Ivancsuk to use a menu-button dropdown instead of individual buttons so you don't clutter the toolbar if you have a lot of buttons.</li>\n" +
            "  <li>1.5.2: 29-Sep-2015. Adjusted so it doesn't break the \"Toolbar configurator\", although the icons won't show up below.</li>\n" +
            "</ol>\n" +
            "\n" +
            "<h2>Installation</h2>\n" +
            "<h3>1. Copying the files</h3>\n" +
            "<p>Extract the contents of the zip in you plugins directory, so it ends up like\n" +
            "    this<br>\n" +
            "    <!--<img src=\"installation.png\" alt=\"Screenshot of installation\" width=\"311\" height=\"346\" longdesc=\"#install\">-->\n" +
            "    </p>\n" +
            "<pre id=\"--install\">\n" +
            "ckeditor\\\n" +
            "	...\n" +
            "	images\\\n" +
            "	lang\\\n" +
            "	plugins\\\n" +
            "		...\n" +
            "		htmlbuttons\\\n" +
            "			plugin.js\n" +
            "			docs\\\n" +
            "				install.html\n" +
            "		...\n" +
            "	skins\\\n" +
            "	themes\\\n" +
            "</pre>\n" +
            "<h3>2. Adding it to CKEditor</h3>\n" +
            "<p>Now add the plugin in your <em>config.js</em> or custom js configuration\n" +
            "file:\n" +
            "<code>config.extraPlugins='htmlbuttons'; </code>\n" +
            "</p>\n" +
            "\n" +
            "<h3>3. Define your buttons</h3>\n" +
            "<p>You must add to your config a new entry defining the buttons that you want to use. For example:</p>\n" +
            "<pre>\n" +
            "config.htmlbuttons = [\n" +
            "	{\n" +
            "		name:'button1',\n" +
            "		icon:'icon1.png',\n" +
            "		html:'&lt;a href=\"http://www.google.com\">Search something&lt;/a>',\n" +
            "		title:'A link to Google'\n" +
            "	},\n" +
            "	{\n" +
            "		name:'button2',\n" +
            "		icon:'icon2.png',\n" +
            "		html:'&lt;table>&lt;tr>&lt;td>&nbsp;&lt;/td>&lt;td>&nbsp;&lt;/td>&lt;/tr>&lt;tr>&lt;td>&nbsp;&lt;/td>&lt;td>&nbsp;&lt;/td>&lt;/tr>&lt;/table>',\n" +
            "		title:'A simple table'\n" +
            "	},\n" +
            "	{\n" +
            "		name:'button3',\n" +
            "		icon:'icon3.png',\n" +
            "		html:'&lt;ol>&lt;li>Item 1 &lt;ol>&lt;li>Sub item 1&lt;/li>&lt;li>Sub item 2&lt;/li>&lt;/ol>&lt;/li>&lt;/ol>',\n" +
            "		title:'A nested list'\n" +
            "	},\n" +
            "	{\n" +
            "		name:'divs',\n" +
            "		icon:'puzzle.png',\n" +
            "		title:'Insert items',\n" +
            "		items : [\n" +
            "			{\n" +
            "				name:'button4',\n" +
            "				icon:'icon1.png',\n" +
            "				html:'&lt;div class=\"wrapper\">&lt;ol>&lt;li>Item 1&lt;/li>&lt;/ol>&lt;/div>',\n" +
            "				title:'A div with a list inside'\n" +
            "			},\n" +
            "			{\n" +
            "				name:'button5',\n" +
            "				icon:'icon2.png',\n" +
            "				html:'&lt;p class=\"heading\"> &lt;/p>',\n" +
            "				title:'A paragraph with a class'\n" +
            "			}\n" +
            "\n" +
            "		]\n" +
            "	}	\n" +
            "];\n" +
            "</pre>\n" +
            "\n" +
            "<h3>4. Add them to your toolbar</h3>\n" +
            "<p>In your toolbar configuration, add a new button for each item in the place where you want the list to show up.</p>\n" +
            "<p>Example</p>\n" +
            "<pre>config.toolbar_Basic = [[\"Bold\",\"Italic\",\"-\",\"NumberedList\",\"BulletedList\",\"-\",\"Link\",\"Unlink\",\"-\",\"Maximize\", \"About\", '-', 'button1', 'button2', 'button3']];\n" +
            "</pre>\n" +
            "\n" +
            "<h3>5. Use them</h3>\n" +
            "<p>Now empty the cache of your browser and reload the editor, the new buttons should show up and you can insert the HTML that you have configured with each button</p>\n" +
            "<!--\n" +
            "<h2>Final notes</h2>\n" +
            "-->\n" +
            "\n" +
            "<h2>Disclaimers</h2>\n" +
            "<p>CKEditor is  &copy; CKSource.com</p>\n" +
            "<p>Sample icons from <a href='http://tango.freedesktop.org'>Tango!</a></p>\n" +
            "</body>\n" +
            "</html>");

        $templateCache.put("components/aml_document_list/aml_document_list.html", "<div class=\"amlDocumentsList-empty alert-warning\" ng-hide=\"documents\"><p>Loading Documents...</p></div>\n" +
            "<div class=\"amlDocumentsList-empty\" ng-hide=\"documents.length\"><p>No Documents Found.</p></div>\n" +
            "<div ng-if=\"requestFailure\" class=\"well\">\n" +
            "  <p class=\"error\">{{requestFailure}}</p>\n" +
            "</div>\n" +
            "<table ng-show=\"documents.length\" class=\"amlDocumentsList-table\">\n" +
            "    <thead>\n" +
            "    <tr class=\"amlDocumentsList-columnsHeader\">\n" +
            "        <th>DOCUMENT TYPE</th>\n" +
            "        <th>UPLOAD DATE</th>\n" +
            "        <th>STATUS</th>\n" +
            "        <th>ACTIONS</th>\n" +
            "    </tr>\n" +
            "    </thead>\n" +
            "\n" +
            "    <tbody>\n" +
            "    <tr class=\"amlDocumentsList-item\" ng-class=\"{'last':$last}\" ng-repeat=\"document in documents\">\n" +
            "        <td class=\"amlDocumentsList-item-documentType\">\n" +
            "            <span>{{document.required_document_type.name}}</span>\n" +
            "        </td>\n" +
            "        <td class=\"amlDocumentsList-item-uploadDate\">{{document.uploaded_date | date: 'short'}}</td>\n" +
            "        <td class=\"amlDocumentsList-item-status\">{{document.status}}</td>\n" +
            "        <td class=\"amlDocumentsList-item-actions\">\n" +
            "            <button ng-click=\"viewDocument(document)\" class=\"button__secondary button__action\"\n" +
            "               ng-show=\"canView(document)\">View</button>\n" +
            "        </td>\n" +
            "    </tr>\n" +
            "    </tbody>\n" +
            "</table>");

        $templateCache.put("components/aml_document_list/dialogs/select_required_document_type/select_required_document_type.html", "<common-modal modal-header=\"Add Document\">\n" +
            "    <div ng-if=\"actionError\" class=\"well\">\n" +
            "      <div class=\"error\">There was an error encountered adding the document.  Please try again.</div>\n" +
            "    </div>\n" +
            "    <div>\n" +
            "        <label class=\"lone-select\"><strong>Choose a Document Type:</strong></label>\n" +
            "            <select ng-model=\"chosenAmlType\" ng-options=\"aml_type.name for aml_type in aml_types | orderBy: 'name'\"></select>\n" +
            "    </div>\n" +
            "\n" +
            "    <input ng-show=\"allowAddRequiredDocAndUpload()\" class=\"aml-upload-btn file-upload-btn\" type=\"file\" id=\"amlDocument\" name='amlDocument'/>\n" +
            "\n" +
            "    <div class=\"row\">\n" +
            "        <button class=\"pull-right\" ng-disable=\"!chosenAmlType\" ng-click=\"createAmlDocumentWithAmlType(chosenAmlType)\">Submit</button>\n" +
            "        <button class=\"button__reject\" ng-click=\"cancelDialog()\">Cancel</button>\n" +
            "    </div>\n" +
            "</common-modal>");

        $templateCache.put("components/common_modal/common_modal.html", "<div class=\"modal-header-combined {{customized}}\" ng-show=\"!waitLoad\">\n" +
            "  <div ng-if=\"!customized\" class=\"modal-header-combined__header heading-2\">\n" +
            "    {{modalHeader}}\n" +
            "  </div>\n" +
            "  <div class=\"modal-header-combined__content\" ng-transclude></div>\n" +
            "</div>");

        $templateCache.put("components/date_picker/icn_date_picker.html", "<span class=\"form-group\" ng-class=\"getClass()\" ng-form=\"my_form\">\n" +
            "  <input type=\"text\" ui-date=\"dateOptions\" ui-date-format=\"mm/dd/yy\" ng-model=\"ngModel\"\n" +
            "         class=\"form-control col-md-3\" name={{inputFormName()}} ng-required=\"true\" autocomplete=\"off\"/>\n" +
            "</span>");

        $templateCache.put("components/diligence_document_list/diligence_document_list.html", "<div class=\"icn-title\">Diligence Documents</div>\n" +
            "<div class=\"diligenceDocumentsList-empty\" ng-hide=\"documents.length\"><p>No Documents Found.</p></div>\n" +
            "<table ng-show=\"documents.length\" class=\"table icn-dotted-table\">\n" +
            "  <thead>\n" +
            "  <tr>\n" +
            "    <th>DOCUMENT</th>\n" +
            "    <th>UPLOAD DATE</th>\n" +
            "    <th>ACTIONS</th>\n" +
            "  </tr>\n" +
            "  </thead>\n" +
            "\n" +
            "  <tbody>\n" +
            "  <tr\n" +
            "      ng-repeat=\"document in documents\">\n" +
            "    <td>\n" +
            "      <span>{{document.file_name}}</span>\n" +
            "    </td>\n" +
            "    <td>{{document.uploaded_date | date: 'short'}}</td>\n" +
            "    <td>\n" +
            "      <button ng-click=\"viewDocument(document)\" class=\"button__secondary button__action\">View</button>\n" +
            "      <button ng-click=\"removeDocument(document)\" class=\"button__secondary button__action\">Remove</button>\n" +
            "    </td>\n" +
            "  </tr>\n" +
            "  </tbody>\n" +
            "</table>");

        $templateCache.put("components/dropdowns/icn_dropdown.html", "<div class=\"icn-dropdown-container\">\n" +
            "  <div class=\"icn-dropdown\">\n" +
            "    <span class=\"pointer\"></span>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("components/dropdowns/onshore_selector.html", "<div class=\"pull-left top-padded\" ng-hide=\"fundType == 'direct_deal'\">\n" +
            "  <div class=\"on-shore-selector-container\" ng-hide=\"hideShoreDropdown\">\n" +
            "    <icn-dropdown-wrapper>\n" +
            "        <select name=\"on-shore-selector\" ng-model=\"$parent.onShore\">\n" +
            "          <option value=\"true\" selected=\"selected\">Onshore Funds</option>\n" +
            "          <option value=\"false\" ng-if=\"!noneOffShore\">Offshore Funds</option>\n" +
            "          <option value=\"false\" disabled ng-if=\"noneOffShore\" class=\"offshore-disabled\">Offshore Funds</option>\n" +
            "        </select>\n" +
            "    </icn-dropdown-wrapper>\n" +
            "    <div class=\"on-shore-tooltip-container\">\n" +
            "      <span tooltip-class=\"on-shore-tooltip\"\n" +
            "            tooltip-html=\"toolTips.fetch().pafListOnshoreOffshore\"\n" +
            "            tooltip-placement=\"top\"\n" +
            "            tooltip-popup-delay=\"500\">\n" +
            "        <img class=\"on-shore-help-logo\" src=\"/images/icon_tooltip.svg\">\n" +
            "      </span>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("components/incident_status_alerts/bar.html", "<i ng-if-start=\"$ctrl.openAlerts.length\" class=\"fa fa-times isa-close-button isa-close-button-spacer\"></i>\n" +
            "\n" +
            "<div class=\"isa-bar-inner\">\n" +
            "  <div class=\"isa-header\">\n" +
            "    <i class=\"fa isa-alert-icon\" ng-class=\"{ 'isa-alert-icon-resolved': $ctrl.resolved, 'fa-check': $ctrl.resolved, 'fa-exclamation-triangle': !$ctrl.resolved }\"></i>\n" +
            "    <div class=\"isa-header-txt\">{{ $ctrl.resolved ? 'Resolved' : 'Alert' }}</div>\n" +
            "  </div>\n" +
            "\n" +
            "  <span class=\"isa-alerts-text\">\n" +
            "    <div class=\"isa-alert-text\" ng-repeat=\"alert in $ctrl.openAlerts\">\n" +
            "      <span class=\"isa-title\">{{ alert.title + ': ' }}</span>\n" +
            "      <span class=\"isa-description\">{{ alert.description }}</span>\n" +
            "    </div>\n" +
            "  </span>\n" +
            "</div>\n" +
            "\n" +
            "<i ng-if-end=\"\" class=\"fa fa-times isa-close-button\" ng-click=\"$ctrl.cancel()\"></i>");

        $templateCache.put("components/incident_status_alerts/incident_status_alerts.html", "<incident-status-alert-bar status=\"resolved\" alerts=\"$ctrl.resolvedAlerts\" class=\"isa-bar-resolved\" data-test-id=\"isa-bar\"></incident-status-alert-bar>\n" +
            "<incident-status-alert-bar status=\"active\" alerts=\"$ctrl.activeAlerts\" class=\"isa-bar-active\" data-test-id=\"isa-bar\"></incident-status-alert-bar>");

        $templateCache.put("components/investment_list/dialogs/approval_history_dialog/approval_history_dialog.html", "<common-modal modal-header=\"Approval History\">\n" +
            "    <div ng-if=\"!hasApprovalHistory()\" class=\"approval-default-content\">Approval history for this investment is not available.</div>\n" +
            "    <div ng-if=\"hasApprovalHistory()\" class=\"approval-history-info\">\n" +
            "        <b>Investor Name:&nbsp;</b>\n" +
            "        <span>{{meta.investor_name}}</span>\n" +
            "        <br>\n" +
            "        <b>Amount:&nbsp;</b>\n" +
            "        <span>${{meta.amount | number:0}}</span>\n" +
            "        <div class=\"workflow-rule\">\n" +
            "            <b>Workflow Rule:&nbsp;</b>\n" +
            "            <div ng-repeat=\"status in meta.workflow\" style=\"display: inline-block;\">\n" +
            "                <div class=\"workflow-status\">{{statusLabel(status)}}</div><span ng-if=\"!$last\">&nbsp;->&nbsp;</span>\n" +
            "            </div>\n" +
            "            <span ng-if=\"workflowChanged()\">*</span>\n" +
            "            <div class=\"note\" ng-if=\"workflowChanged()\">\n" +
            "                <span>* Note: The workflow rule for this investment has had at least one update. The most recent rule used is displayed.</span>\n" +
            "            </div>\n" +
            "        </div>\n" +
            "        <br>\n" +
            "        <div class=\"approvals-list-container\">\n" +
            "            <table>\n" +
            "                <thead>\n" +
            "                <tr>\n" +
            "                    <th>Approval Status</th>\n" +
            "                    <th>Updated By</th>\n" +
            "                    <th>Date</th>\n" +
            "                </tr>\n" +
            "                </thead>\n" +
            "                <tr ng-repeat=\"approval in approvalHistory\">\n" +
            "                    <td>{{statusLabel(approval.approval_status)}}</td>\n" +
            "                    <td>{{approval.created_by}}</td>\n" +
            "                    <td class=\"approval-date\">{{approval.created_at | date: 'dd-MMM-yyyy hh:mm a'}}</td>\n" +
            "                </tr>\n" +
            "            </table>\n" +
            "        </div>\n" +
            "    </div>\n" +
            "    <button class=\"right-aligned-btn\" ng-click=\"cancelDialog()\">OK</button>\n" +
            "</common-modal>");

        $templateCache.put("components/investment_list/dialogs/download_accepted_subdocs_dialog/download_accepted_subdocs_dialog.html", "<common-modal modal-header=\"Download Accepted Investment Subdocs\">\n" +
            "        <form class=\"form-horizontal\" name=\"downloadAcceptedSubdocsForm\" >\n" +
            "            <div class=\"row form-group\">\n" +
            "                <input class=\"all-subdocs-checkbox\" type=\"checkbox\" name=\"all_accepted_subdocs\" ng-model=\"formData.allAcceptedSubdocs\">\n" +
            "                <span>Download all accepted investment subdocs</span>\n" +
            "            </div>\n" +
            "            <div class=\"row form-group\" ng-if=\"!formData.allAcceptedSubdocs\">\n" +
            "              <label class=\"no-extra-margin\">Year:</label>\n" +
            "              <select id=\"year-selector\" ng-model=\"formData.valueDateYear\" ng-options=\"year as year for year in valueDateYearsAvailable\">\n" +
            "                <option value=\"\">Select Year</option>\n" +
            "              </select>\n" +
            "              <div class=\"month-selector\" ng-if=\"formData.valueDateYear\">\n" +
            "                <label class=\"no-extra-margin\">Month*:</label>\n" +
            "                <select id=\"month-selector\" ng-model=\"formData.valueDateMonth\">\n" +
            "                    <option value=\"\">Select Month</option>\n" +
            "                    <option value=\"01\">January</option>\n" +
            "                    <option value=\"02\">February</option>\n" +
            "                    <option value=\"03\">March</option>\n" +
            "                    <option value=\"04\">April</option>\n" +
            "                    <option value=\"05\">May</option>\n" +
            "                    <option value=\"06\">June</option>\n" +
            "                    <option value=\"07\">July</option>\n" +
            "                    <option value=\"08\">August</option>\n" +
            "                    <option value=\"09\">September</option>\n" +
            "                    <option value=\"10\">October</option>\n" +
            "                    <option value=\"11\">Movember</option>\n" +
            "                    <option value=\"12\">December</option>\n" +
            "                </select>\n" +
            "              </div>\n" +
            "            </div>\n" +
            "        </form>\n" +
            "        <p ng-if=\"formData.valueDateYear && !formData.allAcceptedSubdocs\">*If no month is selected, all accepted investment subdocs for the selected year will be downloaded.</p>\n" +
            "\n" +
            "    <div class=\"row\">\n" +
            "        <button class=\"pull-right\" ng-click=\"downloadAcceptedSubdocs()\">Submit</button>\n" +
            "    </div>\n" +
            "</common-modal>");

        $templateCache.put("components/investment_list/dialogs/investment_blocks_dialog/common/call_to_action.html", "<p ng-repeat=\"cta in block.call_to_actions\" class=\"subdoc-line\">\n" +
            "  <a ng-click=\"callToAction(cta.data)\" class=\"subdoc-link\" href=\"#\">\n" +
            "    {{cta.label}}\n" +
            "  </a>\n" +
            "</p>");

        $templateCache.put("components/investment_list/dialogs/investment_blocks_dialog/info/minimum_add_on_amount.html", "<script type=\"text/ng-template\" id=\"minimum_add_on_amount.html\">\n" +
            "  <div>The minimum add on is {{privateAccessFund.fund_currency.iso_code}} {{block.info.minimum_amount | number}}.</div>\n" +
            "</script>");

        $templateCache.put("components/investment_list/dialogs/investment_blocks_dialog/info/minimum_investment_amount.html", "<script type=\"text/ng-template\" id=\"minimum_investment_amount.html\">\n" +
            "  <div>The minimum investment is {{privateAccessFund.fund_currency.iso_code}} {{block.info.minimum_amount | number}}.</div>\n" +
            "</script>");

        $templateCache.put("components/investment_list/dialogs/investment_blocks_dialog/info/sophistication.html", "<script type=\"text/ng-template\" id=\"sophistication.html\">\n" +
            "  <div class=\"info_tooltip__title\">This includes the understanding that: </div>\n" +
            "  <ul class=\"info_tooltip__list\">\n" +
            "    <li class=\"info_tooltip__item\">\n" +
            "      This investment may be illiquid in nature and may have a\n" +
            "      long lock-up period and/or restrictions on transferring interests;\n" +
            "    </li>\n" +
            "    <li class=\"info_tooltip__item\">\n" +
            "      This investment carries significant risks, including the potential loss of all or a substantial portion of the\n" +
            "      investment, possibly due to leverage, short-selling or other speculative practices.\n" +
            "    </li>\n" +
            "  </ul>\n" +
            "</script>");

        $templateCache.put("components/investment_list/dialogs/investment_blocks_dialog/info/training_check.html", "<script type=\"text/ng-template\" id=\"training_check.html\">\n" +
            "  <div class=\"info_tooltip__title\">Pending courses</div>\n" +
            "  <ul class=\"info_tooltip__list\">\n" +
            "    <li class=\"info_tooltip__item\" ng-repeat=\"course in block.info.courses\" >\n" +
            "      {{course}}\n" +
            "    </li>\n" +
            "  </ul>\n" +
            "</script>");

        $templateCache.put("components/investment_list/dialogs/investment_blocks_dialog/info/unapproved_country.html", "<script type=\"text/ng-template\" id=\"unapproved_country.html\">\n" +
            "  <div class=\"info_tooltip__title\">Approved Countries</div>\n" +
            "  <ul class=\"info_tooltip__list\">\n" +
            "    <li class=\"info_tooltip__item\" ng-repeat=\"country in block.info.approved_countries\" >\n" +
            "      {{country}}\n" +
            "    </li>\n" +
            "  </ul>\n" +
            "</script>");

        $templateCache.put("components/investment_list/dialogs/investment_blocks_dialog/info/unapproved_custodian.html", "<script type=\"text/ng-template\" id=\"unapproved_custodian.html\">\n" +
            "  <ng-container ng-if=\"block.info.approved_custodians.length > 0\">\n" +
            "    <div class=\"info_tooltip__title\">Available Custodians</div>\n" +
            "    <ul class=\"info_tooltip__list\">\n" +
            "      <li class=\"info_tooltip__item\" ng-repeat=\"custodian in block.info.approved_custodians\" >\n" +
            "        {{custodian}}\n" +
            "      </li>\n" +
            "    </ul>\n" +
            "  </ng-container>\n" +
            "  <ng-container ng-if=\"block.info.approved_custodians.length == 0\">\n" +
            "    <div>There are no approved custodians for this fund.</div>\n" +
            "  </ng-container>\n" +
            "</script>");

        $templateCache.put("components/investment_list/dialogs/investment_blocks_dialog/info/unapproved_investor_qualification_type.html", "<script type=\"text/ng-template\" id=\"unapproved_investor_qualification_type.html\">\n" +
            "  <div>{{block.info.message}}</div>\n" +
            "</script>");

        $templateCache.put("components/investment_list/dialogs/investment_blocks_dialog/investment_blocks_dialog.html", "<common-modal customized=\"investment-block-dialog\">\n" +
            "  <ng-include src=\"currentState.template\">\n" +
            "  </ng-include>\n" +
            "\n" +
            "  <div class=\"row buttons\">\n" +
            "    <button ng-if=\"!currentState.next && currentState.isPotencialIssue\" ng-click=\" submit()\"\n" +
            "      ng-disabled=\"!currentState.accepted\">Submit</button>\n" +
            "    <button ng-if=\"currentState.next && currentState.isPotencialIssue\" ng-disabled=\"!currentState.accepted\"\n" +
            "      ng-click=\"next()\">Next</button>\n" +
            "    <button ng-if=\"currentState.previous\" ng-click=\"previous()\">Previous</button>\n" +
            "    <button ng-if=\"currentState.isSoftNode\" ng-click=\"next()\">Continue</button>\n" +
            "    <button class=\"cancel-btn\" ng-click=\"cancelDialog()\">Cancel</button>\n" +
            "  </div>\n" +
            "</common-modal>\n" +
            "\n" +
            "<ng-include src=\"'components/investment_list/dialogs/investment_blocks_dialog/info/minimum_investment_amount.html'\">\n" +
            "</ng-include>\n" +
            "<ng-include src=\"'components/investment_list/dialogs/investment_blocks_dialog/info/minimum_add_on_amount.html'\">\n" +
            "</ng-include>\n" +
            "<ng-include src=\"'components/investment_list/dialogs/investment_blocks_dialog/info/unapproved_country.html'\">\n" +
            "</ng-include>\n" +
            "<ng-include src=\"'components/investment_list/dialogs/investment_blocks_dialog/info/unapproved_custodian.html'\">\n" +
            "</ng-include>\n" +
            "<ng-include\n" +
            "  src=\"'components/investment_list/dialogs/investment_blocks_dialog/info/unapproved_investor_qualification_type.html'\">\n" +
            "</ng-include>\n" +
            "<ng-include src=\"'components/investment_list/dialogs/investment_blocks_dialog/info/sophistication.html'\">\n" +
            "</ng-include>\n" +
            "<ng-include src=\"'components/investment_list/dialogs/investment_blocks_dialog/info/training_check.html'\">\n" +
            "</ng-include>");

        $templateCache.put("components/investment_list/dialogs/investment_blocks_dialog/templates/common_block_modal.html", "<ul>\n" +
            "  <li class=\"investment-block\" ng-repeat=\"block in currentState.blocks\">\n" +
            "    <div>\n" +
            "      <div class=\"title\">{{block.title}}</div>\n" +
            "      <div>{{block.description}}</div>\n" +
            "\n" +
            "      <div ng-if=\"block.call_to_actions\">\n" +
            "        <ng-include src=\"'components/investment_list/dialogs/investment_blocks_dialog/common/call_to_action.html'\">\n" +
            "        </ng-include>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "    <span ng-if=\"block.info\" class=\"info\" tooltip-class=\"on-shore-tooltip inverted rounded large\"\n" +
            "      tooltip-template=\"'{{block.block_type}}.html'\" tooltip-placement=\"bottom\" tooltip-append-to-body=\"true\"\n" +
            "      tooltip-trigger=\"click\">\n" +
            "      <img src=\"/images/information-icon.svg\">\n" +
            "    </span>\n" +
            "\n" +
            "  </li>\n" +
            "</ul>\n" +
            "\n" +
            "<p class=\"contact-message\">\n" +
            "  <ng-include src=\"'components/investment_list/dialogs/investment_blocks_dialog/templates/contact_modal.html'\">\n" +
            "  </ng-include>\n" +
            "</p>");

        $templateCache.put("components/investment_list/dialogs/investment_blocks_dialog/templates/contact_modal.html", "<div ng-switch on='!!needAssistanceOverride'>\n" +
            "  <div ng-switch-when='true' ng-bind-html=\"needAssistanceOverride\"></div>\n" +
            "  <div ng-switch-default>\n" +
            "    <p>\n" +
            "      For questions, contact Investor Relations at <a href=\"mailto:ir@icapitalnetwork.com\">ir@icapitalnetwork.com</a>,\n" +
            "      or call us at 212 994 7333 to speak with a representative.\n" +
            "    </p>\n" +
            "    <br>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("components/investment_list/dialogs/investment_blocks_dialog/templates/hard_block_modal.html", "<div class=\"modal-header-combined__header heading-2 hard-block\">\n" +
            "  <i class=\"fa fa-exclamation-triangle\"></i>\n" +
            "  Subscription Not Permitted\n" +
            "</div>\n" +
            "\n" +
            "<p>\n" +
            "  Based on the information provided, this subscription does not meet the requirements for {{privateAccessFund.name}} for\n" +
            "  the reason(s) listed below:\n" +
            "</p>\n" +
            "\n" +
            "<ng-include src=\"'components/investment_list/dialogs/investment_blocks_dialog/templates/common_block_modal.html'\">\n" +
            "</ng-include>");

        $templateCache.put("components/investment_list/dialogs/investment_blocks_dialog/templates/potencial_issues_modal.html", "<div class=\"modal-header-combined__header heading-2\">\n" +
            "  Potential Issues <span class=\"potencial-issues\">({{currentState.index}} of {{currentState.size}})</span>\n" +
            "</div>\n" +
            "\n" +
            "<p class=\"title\">{{currentState.block.title}}</p>\n" +
            "\n" +
            "<p>{{currentState.block.description}}</p>\n" +
            "\n" +
            "<p ng-if=\"currentState.block.info\" class=\"alert alert-info\">\n" +
            "  <ng-include ng-repeat=\"block in [currentState.block]\" src=\"currentState.infoTemplate()\">\n" +
            "  </ng-include>\n" +
            "</p>\n" +
            "\n" +
            "<icn-checkbox class=\"accept-checkbox\" model=\"currentState.accepted\" ng-click=\"currentState.accept()\"\n" +
            "  label=\"I understand that this may cause processing delays or rejection and would like to proceed with this subscription\">\n" +
            "</icn-checkbox>");

        $templateCache.put("components/investment_list/dialogs/investment_blocks_dialog/templates/soft_block_modal.html", "<div class=\"modal-header-combined__header heading-2 soft-block\">\n" +
            "  <i class=\"fa fa-exclamation-triangle\"></i>\n" +
            "  Potential Subscription Issues\n" +
            "</div>\n" +
            "\n" +
            "<p>\n" +
            "  We have detected the following issue(s) which may cause processing delays or rejection if not resolved:\n" +
            "</p>\n" +
            "\n" +
            "<ng-include src=\"'components/investment_list/dialogs/investment_blocks_dialog/templates/common_block_modal.html'\">\n" +
            "</ng-include>");

        $templateCache.put("components/investment_list/dialogs/investment_events_dialog/investment_events_dialog.html", "<common-modal modal-header=\"{{type | capitalize}} Events\">\n" +
            "    <div class=\"events-list-container\">\n" +
            "        <span ng-if=\"!hasEvents()\">No events yet for this {{type}}.</span>\n" +
            "        <table ng-if=\"hasEvents()\">\n" +
            "            <thead>\n" +
            "              <tr>\n" +
            "                  <th>Action</th>\n" +
            "                  <th>Taken By</th>\n" +
            "                  <th>Date</th>\n" +
            "              </tr>\n" +
            "            </thead>\n" +
            "            <tr ng-repeat=\"event in events\">\n" +
            "                <td>{{getEventType(event)}}</td>\n" +
            "                <td>{{event.created_by}}</td>\n" +
            "                <td class=\"event-date\">{{event.created_at | date: 'dd-MMM-yyyy hh:mm a'}}</td>\n" +
            "            </tr>\n" +
            "        </table>\n" +
            "    </div>\n" +
            "    <div class=\"button-spacer\">\n" +
            "      <button class=\"right-aligned-btn\" ng-click=\"cancelDialog()\">OK</button>\n" +
            "    </div>\n" +
            "</common-modal>");

        $templateCache.put("components/investment_list/dialogs/pick_signers_dialog/pick_signers_dialog.html", "<common-modal modal-header=\"{{headerText}}\" id=\"pick-signers-dialog\">\n" +
            "\n" +
            "  <div class=\"signers_subtitle\" ng-if=\"hasSubtitle\">{{ subtitle }}</div>\n" +
            "  <div class=\"signers_note\" ng-if=\"hasNote1\">{{ note1 }}</div>\n" +
            "  <div class=\"signers_note secondary\" ng-if=\"hasNote2\">{{ note2 }}</div>\n" +
            "\n" +
            "  <form name=\"investorForm\" id=\"investor-form\" class=\"investor-form\" novalidate>\n" +
            "    <div class=\"icn-select-investment-modal selector\">\n" +
            "      <icn-select\n" +
            "        name=\"select-first-signer\"\n" +
            "        id=\"change-first-signer\"\n" +
            "        ng-model=\"firstSigner\"\n" +
            "        label=\"First Signer\"\n" +
            "        options=\"availableSigners\"\n" +
            "        option-label=\"display_name\"\n" +
            "        on-change=\"updateSigner(object, 'first')\"\n" +
            "        hide=\"[secondSigner, thirdSigner]\">\n" +
            "      </icn-select>\n" +
            "\n" +
            "      <button class=\"add-signer second\" ng-show=\"allowSecondSigner()\" ng-click=\"addSigner()\">+ Add Signer</button>\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"icn-select-investment-modal selector\" ng-show=\"showSecondSigner\">\n" +
            "      <icn-select\n" +
            "        name=\"select-second-signer\"\n" +
            "        id=\"change-second-signer\"\n" +
            "        ng-model=\"secondSigner\"\n" +
            "        label=\"Second Signer\"\n" +
            "        options=\"availableSigners\"\n" +
            "        option-label=\"display_name\"\n" +
            "        on-change=\"updateSigner(object, 'second')\"\n" +
            "        hide=\"[firstSigner, thirdSigner]\">\n" +
            "      </icn-select>\n" +
            "\n" +
            "      <button class=\"add-signer\" ng-show=\"allowThirdSigner()\" ng-click=\"addSigner()\">+ Add Signer</button>\n" +
            "      <button class=\"manage-button\" ng-hide=\"blockDeleteSigners()\" ng-click=\"deleteSigner()\">Delete</button>\n" +
            "      <button class=\"manage-button\" ng-show=\"showClearSigners()\" ng-click=\"clearSigners()\">Clear</button>\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"icn-select-investment-modal selector\" ng-show=\"showThirdSigner\">\n" +
            "      <icn-select\n" +
            "        name=\"select-third-signer\"\n" +
            "        id=\"change-third-signer\"\n" +
            "        ng-model=\"thirdSigner\"\n" +
            "        label=\"Third Signer\"\n" +
            "        options=\"availableSigners\"\n" +
            "        option-label=\"display_name\"\n" +
            "        on-change=\"updateSigner(object, 'third')\"\n" +
            "        hide=\"[firstSigner, secondSigner]\">\n" +
            "      </icn-select>\n" +
            "\n" +
            "      <button class=\"manage-button\" ng-click=\"deleteSigner()\">Delete</button>\n" +
            "      <button class=\"manage-button\" ng-click=\"clearSigners()\">Clear</button>\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"row buttons\">\n" +
            "      <button ng-disabled=\"!allowSubmit()\" class=\"subscription-btn round-btn\" type=\"submit\" ng-click=\"saveSigners()\">\n" +
            "        {{ hasNext ? 'NEXT' : 'SAVE' }}\n" +
            "      </button>\n" +
            "      <button class=\"cancel-btn round-btn\" ng-click=\"cancelDialog()\">CANCEL</button>\n" +
            "    </div>\n" +
            "  </form>\n" +
            "</common-modal>");

        $templateCache.put("components/investment_list/dialogs/placement_fee_dialog/placement_fee_dialog.html", "<common-modal modal-header=\"{{dialogHeader}}\">\n" +
            "    <div class=\"placement-fee_box alert alert-{{message.type}}\" ng-click=\"dismissError()\" ng-show=\"message\">\n" +
            "      <span ng-show=\"message.text\" ng-bind-html=\"message.text\">{{message.text}}</span>\n" +
            "      <ul ng-show=\"message.errors\">\n" +
            "        <li ng-repeat=\"error in message.errors\">\n" +
            "          <span ng-show=\"error.value\"> {{ error.value }}</span>\n" +
            "          <span ng-show=\"error.type\"> {{ error.type }}</span>\n" +
            "          <span ng-show=\"!error.value\">{{ error }}</span>\n" +
            "        </li>\n" +
            "      </ul>\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"placement-fee_box\">\n" +
            "      {{placementFeeDialogText}}\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"placement-fee_box\" ng-if=\"!withAllowedPlacementFees\">\n" +
            "      <label class=\"placement-fee_label\">Placement Fee:</label>\n" +
            "      <input ui-percentage-mask ui-percentage-value type=\"text\" name=\"placement_fee\" class=\"placement-fee_input\" ng-model=\"placementForm.placement_fee\" ng-class=\"{invalid: !validPlacementFee()}\">\n" +
            "      <span class=\"placement-fee_error error\" ng-if=\"placementFeeOverMaxValue() && !mandatoryPlacementFeeChanged()\">\n" +
            "        Maximum fee: {{placementFeeRange.max_placement_fee}}%\n" +
            "      </span>\n" +
            "      <span class=\"placement-fee_error error\" ng-if=\"placementFeeUnderMinValue()&& !mandatoryPlacementFeeChanged()\">\n" +
            "        Minimum placement fee: {{placementFeeRange.min_placement_fee}}%\n" +
            "      </span>\n" +
            "      <span class=\"placement-fee_error error\" ng-if=\"mandatoryPlacementFeeChanged()\">\n" +
            "        Mandatory placement fee: {{placementFeeRange.min_placement_fee}}%\n" +
            "      </span>\n" +
            "    </div>\n" +
            "    <div class=\"placement-fee_box text-center\" ng-if=\"withAllowedPlacementFees\">\n" +
            "      <icn-dropdown-wrapper class=\"select-container icn-dropdown col-md-12 edit-investment\">\n" +
            "        <select\n" +
            "          ng-model=\"placementForm.placement_fee\"\n" +
            "          ng-options=\"option for option in allowedPlacementFees\"\n" +
            "          class=\"form-control icn-select\">\n" +
            "        </select>\n" +
            "      </icn-dropdown-wrapper>\n" +
            "    </div>\n" +
            "\n" +
            "    <div ng-if=\"isNetPlacementFee\">\n" +
            "      <table class=\"net-placement-fee-table\">\n" +
            "        <thead>\n" +
            "          <th></th>\n" +
            "          <th>%</th>\n" +
            "          <th>Amount ({{currency}})</th>\n" +
            "        </thead>\n" +
            "        <tr>\n" +
            "          <td class=\"header-column\">{{grossAmountText}}</td>\n" +
            "          <td>-</td>\n" +
            "          <td>{{grossCommitment | currency : \"\" : 0}}</td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"header-column\">Net Placement Fee</td>\n" +
            "          <td>{{netPlacementFee()}}</td>\n" +
            "          <td class=\"net-fee-amount\">{{netFeeAmount()}}</td>\n" +
            "        </tr>\n" +
            "        <tr class=\"net-commitment-row\">\n" +
            "          <td class=\"header-column\">{{netAmountText}}</td>\n" +
            "          <td>-</td>\n" +
            "          <td class=\"net-commitment-amount\">{{netCommitmentAmount() | currency : \"\" : 0}}</td>\n" +
            "        </tr>\n" +
            "      </table>\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"placement-fee_box\" ng-if=\"!netPlacementFee\">\n" +
            "      Please see the subscription document for further instructions as to timing and wiring instructions for payment of this placement fee.\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"row ip-investment-buttons\">\n" +
            "        <button class=\"subscription-btn round-btn\" type=\"submit\" ng-click=\"updatePlacementFee()\" ng-disabled=\"!validPlacementFee()\">NEXT</button>\n" +
            "        <button class=\"cancel-btn round-btn\" ng-click=\"cancelDialog()\">CANCEL</button>\n" +
            "    </div>\n" +
            "</common-modal>");

        $templateCache.put("components/investment_list/dialogs/send_fund_admin_documents_dialog/send_documents_to_integration_partner_dialog.html", "<common-modal modal-header=\"Send Documents\">\n" +
            "  <div class=\"send-fund-admin-docs-options-modal\">\n" +
            "\n" +
            "    <div ng-dropdown-multiselect\n" +
            "         class=\"icn-select\"\n" +
            "         options=\"integrationPartnerOptions\"\n" +
            "         extra-settings=\"multiSelectSettings\"\n" +
            "         selected-model=\"integrationPartner\"\n" +
            "         translation-texts=\"integrationPartnerText\"\n" +
            "         disabled=\"integrationPartnerOptions.length == 1\"\n" +
            "         ng-if=\"integrationPartnerOptions.length > 0\"\n" +
            "    >\n" +
            "    </div>\n" +
            "    <div class=\"send-fund-admin-docs-options-message\">Please select the documents you would like to send to the integration partner.</div>\n" +
            "\n" +
            "    <div class=\"send-fund-admin-docs-options\">\n" +
            "      <div class=\"select-all-checkbox\">\n" +
            "        <icn-checkbox\n" +
            "          custom-id=\"{{selectAllCheckbox.id}}\"\n" +
            "          label=\"{{selectAllCheckbox.label}}\"\n" +
            "          model=\"selectAllCheckbox.selected\">\n" +
            "        </icn-checkbox>\n" +
            "      </div>\n" +
            "      <table>\n" +
            "        <tbody>\n" +
            "          <tr ng-repeat=\"doc in documents\">\n" +
            "            <td>\n" +
            "              <div class=\"doc-checkbox\">\n" +
            "                <icn-checkbox\n" +
            "                        custom-id=\"{{doc.id}}\"\n" +
            "                        label=\"\"\n" +
            "                        model=\"doc.selected\">\n" +
            "                </icn-checkbox>\n" +
            "                <div class=\"doc-link-label\">\n" +
            "                  <a ng-show=\"doc.uploaded\" href=\"\" ng-click=\"viewDocument(doc)\">{{doc.document_type}}</a>\n" +
            "                  <span ng-show=\"!doc.uploaded\">{{doc.document_type}}</span>\n" +
            "                </div>\n" +
            "              </div>\n" +
            "            </td>\n" +
            "          </tr>\n" +
            "        </tbody>\n" +
            "      </table>\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"row send-fund-admin-docs-options-buttons\">\n" +
            "      <button\n" +
            "        class=\"right-aligned-btn send_btn\"\n" +
            "        type=\"submit\"\n" +
            "        ng-disabled=\"disableSendDocs()\"\n" +
            "        ng-click=\"sendDocs()\"\n" +
            "      >\n" +
            "        SEND\n" +
            "      </button>\n" +
            "      <button class=\"right-aligned-btn button__decline cancel_btn\" ng-click=\"cancelDialog()\">CANCEL</button>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("components/investment_list/dialogs/send_subscription_dialog/send_subscription_attestation_dialog.html", "<common-modal modal-header=\"{{headerText}}\" id=\"send-subscription-dialog\">\n" +
            "  <div class=\"sign-message ria-attestation\" ng-show=\"showOptionalAttestation()\">\n" +
            "    <input type=\"checkbox\" class=\"attestation-checkbox\" ng-click=\"toggleAttestation()\">\n" +
            "    <div class=\"text-box limited-height\" ng-bind-html=\"attestationMsg\"></div>\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"clearfix\"></div>\n" +
            "\n" +
            "  <div class=\"row ip-investment-buttons buttons-separator\">\n" +
            "    <button ng-disabled=\"!allowSubmit()\" class=\"subscription-btn round-btn\" type=\"submit\" ng-click=\"confirm()\">NEXT</button>\n" +
            "    <button class=\"cancel-btn round-btn\" ng-click=\"cancelDialog()\">CANCEL</button>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("components/investment_list/dialogs/send_subscription_dialog/send_subscription_dialog.html", "<common-modal modal-header=\"{{headerText}}\" id=\"send-subscription-dialog\">\n" +
            "  <ng-include src=\"'components/investment_list/dialogs/send_subscription_dialog/steps/fund_acknowledgement.html'\" ng-show=\"currentStep == 'fund_acknowledgement'\"></ng-include>\n" +
            "  <ng-include src=\"'components/investment_list/dialogs/send_subscription_dialog/steps/sign_method.html'\" ng-if=\"currentStep == 'sign_method'\"></ng-include>\n" +
            "  <ng-include src=\"'components/investment_list/dialogs/send_subscription_dialog/steps/click_manual.html'\" ng-show=\"currentStep == 'click_manual'\"></ng-include>\n" +
            "</common-modal>");

        $templateCache.put("components/investment_list/dialogs/send_subscription_dialog/signer_box/subscription_signer_box.html", "<div class=\"signer-box\">\n" +
            "  <div class=\"signer-label-email-wrapper\"\n" +
            "       ng-class=\"{'signer-label-email-wrapper-auto-width': !(phoneService.signerPhoneShow(signerKey))}\">\n" +
            "    <div class=\"signer-label-container signer-nowrap\">\n" +
            "      <b>{{signerStruct.name}}:</b>\n" +
            "    </div>\n" +
            "    <div content-overflow=\"{enable: true, delimiter: '@', data: signerStruct, whichFieldInData: 'email', uniqId: 'sign-method-signer-email'}\"\n" +
            "         class=\"signer-email-container signer-nowrap\">\n" +
            "            <span id=\"sign-method-signer-email\"\n" +
            "                  class=\"signer-email\">\n" +
            "              {{signerStruct.email}}\n" +
            "            </span>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "\n" +
            "  <div ng-if=\"phoneService.showPhoneElement(signerKey)\" class=\"signer-phone-wrapper\"\n" +
            "        ng-class=\"{'signer-phone-disabled': phoneService.signerPhoneDisabled(signerKey)}\">\n" +
            "    <icn-select class=\"signer-phone\"\n" +
            "                ng-if=\"phoneService.signerPhoneShow(signerKey)\"\n" +
            "                ng-model=\"signerStruct.phone\"\n" +
            "                options=\"phoneNumberOptions\"\n" +
            "                ng-disabled=\"phoneService.signerPhoneDisabled(signerKey)\"\n" +
            "                name=\"signer_phone_number\"\n" +
            "                label=\"Phone Number\"\n" +
            "                option-label=\"for_display\">\n" +
            "    </icn-select>\n" +
            "    <div class=\"signer-phone\" ng-if=\"!phoneService.signerPhoneShow(signerKey)\">\n" +
            "        <icn-input  name=\"phone_number\"\n" +
            "                    label=\"Phone Number\"\n" +
            "                    blur=\"phoneValidate()\"\n" +
            "                    model=\"signerStruct.phoneInput\"\n" +
            "                    ng-class=\"{ 'error' : phoneNumberError }\"\n" +
            "                    directives=\"numbers-only\"\n" +
            "                    required>\n" +
            "        </icn-input>\n" +
            "      <div class=\"error-message\" ng-if=\"signerStruct.phoneErrors\">\n" +
            "        {{ signerStruct.phoneErrors }}\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("components/investment_list/dialogs/send_subscription_dialog/signer_configuration_box/signer_configuration_box.html", "<div class=\"signer-box\">\n" +
            "    <div>\n" +
            "        <span class=\"inv-sec\"><b>{{signerConfiguration.display_name}}</b></span>\n" +
            "        <div ng-if=\"signerConfiguration.email && !contactOptions\"\n" +
            "             content-overflow=\"{enable: true, delimiter: '@', data: signerConfiguration, whichFieldInData: 'email', uniqId: 'sign-method-signer-email'}\">\n" +
            "            <span id=\"sign-method-signer-email\"\n" +
            "                  class=\"signer-email\">\n" +
            "              {{signerConfiguration.email}}\n" +
            "            </span>\n" +
            "        </div>\n" +
            "    </div>\n" +
            "\n" +
            "    <div ng-if=\"!!contactOptions\" class=\"signer-info-spacing\">\n" +
            "        <icn-select\n" +
            "                    ng-if=\"contactOptions.length > 0\"\n" +
            "                    ng-model=\"signerConfiguration.email\"\n" +
            "                    options=\"contactOptions\"\n" +
            "                    name=\"signer_contact_options\"\n" +
            "                    label=\"Email\">\n" +
            "        </icn-select>\n" +
            "        <form ng-if=\"contactOptions.length == 0\">\n" +
            "            <icn-input\n" +
            "                    name=\"email\"\n" +
            "                    label=\"Email\"\n" +
            "                    blur=\"emailValidate()\"\n" +
            "                    model=\"emailInput.email\"\n" +
            "                    ng-class=\"{ 'error' : emailError }\"\n" +
            "                    required>\n" +
            "            </icn-input>\n" +
            "        </form>\n" +
            "        <div class=\"error-message\" ng-if=\"emailError\">\n" +
            "            Email is not in the correct format\n" +
            "        </div>\n" +
            "    </div>\n" +
            "\n" +
            "    <div ng-if=\"displayPhones\">\n" +
            "        <div\n" +
            "          class=\"signer-phone-wrapper signer-info-spacing\"\n" +
            "          ng-class=\"{'signer-phone-disabled': phoneDisabled()}\"\n" +
            "        >\n" +
            "            <icn-select\n" +
            "              ng-if=\"phoneOptions.length > 0\"\n" +
            "              ng-model=\"signerConfiguration.phone\"\n" +
            "              options=\"phoneOptions\"\n" +
            "              name=\"signer_phone_options\"\n" +
            "              ng-disabled=\"phoneDisabled()\"\n" +
            "              label=\"Phone\">\n" +
            "            </icn-select>\n" +
            "            <form ng-if=\"phoneOptions.length == 0\">\n" +
            "                <icn-input\n" +
            "                  name=\"phone\"\n" +
            "                  label=\"Phone\"\n" +
            "                  blur=\"phoneValidate()\"\n" +
            "                  model=\"phoneInput.phone\"\n" +
            "                  ng-class=\"{ 'error' : !phoneValid }\"\n" +
            "                  directives=\"numbers-only\"\n" +
            "                  required>\n" +
            "                </icn-input>\n" +
            "                <div class=\"error-message\" ng-if=\"phoneErrors\">\n" +
            "                    {{ phoneErrors }}\n" +
            "                </div>\n" +
            "            </form>\n" +
            "        </div>\n" +
            "    </div>\n" +
            "</div>");

        $templateCache.put("components/investment_list/dialogs/send_subscription_dialog/steps/click_manual.html", "<form name=\"investorForm\" id=\"investor-form\" class=\"investor-form\" novalidate>\n" +
            "  <div class=\"subdoc-box sign-message\">\n" +
            "    If you are unable to send the Subscription Agreement electronically, download and print the Subscription Agreement for manual signature by clicking Download. Then, to submit a signed agreement, select Upload Signed Subscription on Client Management.\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"clearfix\"></div>\n" +
            "\n" +
            "  <div class=\"row ip-investment-buttons buttons-separator\">\n" +
            "    <button ng-disabled=\"!allowSubmit()\" class=\"subscription-btn round-btn\" type=\"submit\" ng-click=\"manualSubBook()\">DOWNLOAD</button>\n" +
            "    <button class=\"cancel-btn round-btn\" ng-click=\"moveToStep('sign_method')\">BACK</button>\n" +
            "  </div>\n" +
            "</form>");

        $templateCache.put("components/investment_list/dialogs/send_subscription_dialog/steps/fund_acknowledgement.html", "<div class=\"sign-message ria-attestation\" ng-show=\"showOptionalAttestation()\">\n" +
            "  <input type=\"checkbox\" class=\"attestation-checkbox\" ng-click=\"toggleAttestation()\">\n" +
            "  <div class=\"text-box limited-height\" ng-bind-html=\"attestationMsg\"></div>\n" +
            "</div>\n" +
            "\n" +
            "<div class=\"clearfix\"></div>\n" +
            "\n" +
            "<div class=\"row ip-investment-buttons buttons-separator\">\n" +
            "  <div class=\"attestation-status\">\n" +
            "    <div class=\"currentAttestationCircle\"></div>\n" +
            "    <div class=\"attestationCircle\"></div>\n" +
            "  </div>\n" +
            "\n" +
            "  <button ng-disabled=\"!allowSubmit()\" class=\"subscription-btn round-btn\" type=\"submit\" ng-click=\"moveToStep('sign_method')\">NEXT</button>\n" +
            "  <button class=\"cancel-btn round-btn\" ng-click=\"cancelDialog()\">CANCEL</button>\n" +
            "</div>");

        $templateCache.put("components/investment_list/dialogs/send_subscription_dialog/steps/sign_method.html", "<form name=\"investorForm\" id=\"investor-form\" class=\"investor-form sign-message\" novalidate>\n" +
            "  <div class=\"subdoc-box\" ng-show=\"resetSubscription && !msg\">{{preText}}</div>\n" +
            "  <div class=\"subdoc-box\" ng-show=\"signatureRequirementWarning\">{{signatureRequirementWarning}}</div>\n" +
            "\n" +
            "  <div class=\"subdoc-box\" ng-show=\"preparerBeingUsed\">\n" +
            "    <span class=\"warning-message\"><b>Additional information is required for this subscription agreement.</b></span></br>\n" +
            "    <span>You will be directed to complete the subscription agreement when clicking the “Send” button below.</span>\n" +
            "  </div>\n" +
            "\n" +
            "  <div ng-if=\"faFeatureEnabled && (signer1Index >= 0 || !!signer2Index >= 0 || signer3Index >= 0)\">\n" +
            "    <span><b>Please select the preferred signer for this agreement:</b></span>\n" +
            "    <div class=\"content-default icn-radio-wrapper\">\n" +
            "      <div class=\"icn-radio\">\n" +
            "        <input class=\"icn-radio\"\n" +
            "               type=\"radio\"\n" +
            "               id=\"financial_advisor_yes\"\n" +
            "               name=\"financial_advisor\"\n" +
            "               ng-model=\"faFeature.faSelection\"\n" +
            "               ng-value=\"'investor'\"\n" +
            "        />\n" +
            "        <label for=\"financial_advisor_yes\" class=\"radio-label\">\n" +
            "          Investor\n" +
            "        </label>\n" +
            "      </div>\n" +
            "      <div class=\"icn-radio\">\n" +
            "        <input class=\"icn-radio\"\n" +
            "               type=\"radio\"\n" +
            "               id=\"financial_advisor_no\"\n" +
            "               name=\"financial_advisor\"\n" +
            "               ng-model=\"faFeature.faSelection\"\n" +
            "               ng-value=\"'fa'\"\n" +
            "        />\n" +
            "        <label for=\"financial_advisor_no\" class=\"radio-label\">\n" +
            "          {{faFeature.preferredSigner}}\n" +
            "        </label>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "    <br>\n" +
            "    <div ng-if=\"financialAdvisorSelected()\">\n" +
            "      <div class=\"subdoc-box\">\n" +
            "        <span ng-show=\"preparerBeingUsed\">After the subscription agreement is completed, it will be sent securely to the following email address(es) for electronic signature.</span>\n" +
            "        <span ng-hide=\"preparerBeingUsed\">The subscription agreement will be sent securely to the following email address(es) for electronic signature.</span>\n" +
            "        <div class=\"signer-boxes\">\n" +
            "          <div class=\"inv-box\" ng-repeat=\"signerConfiguration in processedPreviousSignerConfigurations()\">\n" +
            "            <signer-configuration-box signer-configuration=\"signerConfiguration\" phones-allowed=\"require2fa\"></signer-configuration-box>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "        <div class=\"signer-boxes\" ng-if=\"showDiscretionaryFa()\">\n" +
            "          <signer-configuration-box signer-configuration=\"faFeature\" phones-allowed=\"require2fa\"></signer-configuration-box>\n" +
            "          <div ng-if=\"!faFeature.email\">\n" +
            "            <label class=\"error\">Please add a Financial Advisor to the Account Profile.</label>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "        <div class=\"signer-boxes\">\n" +
            "          <div class=\"inv-box\" ng-repeat=\"signerConfiguration in processedNextSignerConfigurations()\">\n" +
            "            <signer-configuration-box signer-configuration=\"signerConfiguration\" phones-allowed=\"require2fa\"></signer-configuration-box>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "    <div ng-if=\"centralSignatorySelected()\">\n" +
            "      <div class=\"subdoc-box\">\n" +
            "        <span ng-show=\"preparerBeingUsed\">After the subscription agreement is completed, it will be sent securely to the following email address(es) for electronic signature.</span>\n" +
            "        <span ng-hide=\"preparerBeingUsed\">The subscription agreement will be sent securely to the following email address(es) for electronic signature.</span>\n" +
            "        <div class=\"signer-boxes\">\n" +
            "          <div class=\"inv-box\" ng-repeat=\"signerConfiguration in previousSignerConfigurations()\">\n" +
            "            <signer-configuration-box signer-configuration=\"signerConfiguration\" phones-allowed=\"require2fa\"></signer-configuration-box>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "        <div class=\"signer-boxes\">\n" +
            "          <div class=\"signer-box first-signer-box\">\n" +
            "            <div class=\"signer-label-email-wrapper\"\n" +
            "                 ng-class=\"{'signer-label-email-wrapper-auto-width': !(signerPhoneShow('centralSignatory'))}\">\n" +
            "              <div class=\"icn-select-wrapper\">\n" +
            "                <icn-select id=\"select-signatory\"\n" +
            "                            ng-model=\"faFeature.selectedSignatory\"\n" +
            "                            options=\"faFeature.centralSignatories\"\n" +
            "                            option-label=\"display_name\"\n" +
            "                            on-change=\"selectSignatory(object)\"\n" +
            "                            label=\"Central Signatory\">\n" +
            "                </icn-select>\n" +
            "              </div>\n" +
            "            </div>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "        <div class=\"signer-boxes\">\n" +
            "          <div class=\"inv-box\" ng-repeat=\"signerConfiguration in nextSignerConfigurations()\">\n" +
            "            <signer-configuration-box signer-configuration=\"signerConfiguration\" phones-allowed=\"require2fa\"></signer-configuration-box>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "        <div ng-if=\"showCsError()\">\n" +
            "          <label class=\"error\">Phone number is missing for this Central Signatory.</label>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "\n" +
            "  <div ng-if=\"showInvestor()\">\n" +
            "    <div class=\"subdoc-box\" ng-hide=\"manualSignatureOnly() || signerKeysArray().length == 0\">\n" +
            "      <span ng-show=\"preparerBeingUsed\">After the subscription agreement is completed, it will be sent securely to the following email address(es) for electronic signature.</span>\n" +
            "      <span ng-hide=\"preparerBeingUsed\">The subscription agreement will be sent securely to the following email address(es) for electronic signature.</span>\n" +
            "      <div class=\"signer-boxes\">\n" +
            "        <div class=\"inv-box\" ng-repeat=\"signerConfiguration in signerConfigurations\">\n" +
            "          <signer-configuration-box signer-configuration=\"signerConfiguration\" phones-allowed=\"require2fa\"></signer-configuration-box>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"subdoc-box\" ng-show=\"manualSignatureOnly()\">\n" +
            "      Download and print the Subscription Agreement for manual signature by clicking Download.\n" +
            "      Then, to submit a signed agreement, select Upload Signed Subscription on Client Management.\n" +
            "    </div>\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"subdoc-box\" ng-if=\"showOfferingDocumentInfo()\">\n" +
            "    <span class=\"underlined\">Note:</span>\n" +
            "    The signers on this investment will receive a separate email containing the required offering documents for this fund.\n" +
            "    <span ng-if=\"user.isRia\"><a href=\"#\" class=\"subdoc-link\"  ng-click=\"previewReferenceDocs()\"> Click here</a> to preview this email.</span>\n" +
            "  </div>\n" +
            "\n" +
            "  <h6 ng-if=\"error\"><label class=\"error\" ng-if=\"error\">There was an error creating an investment for this user. Please Contact Support.</label></h6>\n" +
            "\n" +
            "  <div class=\"clearfix\"></div>\n" +
            "\n" +
            "  <div class=\"subdoc-box\" ng-hide=\"hideManualSignature()\">\n" +
            "    If you are unable to send the subscription agreement electronically,\n" +
            "    <a href=\"#\" class=\"subdoc-link manual-link\"  ng-click=\"moveToStep('click_manual')\">click here</a>.\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"alert alert-danger\" ng-if=\"showMissingPhoneNumbersError\">Phone number(s) are missing and required for sending the subscription agreement. Please update and try again.</div>\n" +
            "\n" +
            "  <div class=\"alert alert-danger\" ng-if=\"docError\">There was an error sending the subscription document for this user. Please contact Support.</div>\n" +
            "\n" +
            "  <div class=\"row ip-investment-buttons buttons-separator\">\n" +
            "      <div ng-if=\"attestationChecked\" class=\"attestation-status\">\n" +
            "        <div class=\"attestationCircle\"></div>\n" +
            "        <div class=\"currentAttestationCircle\"></div>\n" +
            "      </div>\n" +
            "\n" +
            "    <button ng-disabled=\"!allowSubmit()\" class=\"subscription-btn round-btn\" type=\"submit\" ng-click=\"sendSubBook()\">{{submitButton}}</button>\n" +
            "    <button class=\"cancel-btn round-btn\" ng-click=\"cancelDialog()\">CANCEL</button>\n" +
            "  </div>\n" +
            "</form>");

        $templateCache.put("components/investment_list/dialogs/sub_advisory_fee_dialog/sub_advisory_fee_dialog.html", "<common-modal modal-header=\"Sub-Advisory Fee\">\n" +
            "  <div class=\"placement-fee_box alert alert-{{message.type}}\" ng-click=\"dismissError()\" ng-if=\"message\">\n" +
            "    <span ng-if=\"message.text\" ng-bind-html=\"message.text\">{{message.text}}</span>\n" +
            "    <ul ng-if=\"message.errors\">\n" +
            "      <li ng-repeat=\"error in message.errors\">\n" +
            "        <span ng-if=\"error.value\"> {{ error.value }}</span>\n" +
            "        <span ng-if=\"error.type\"> {{ error.type }}</span>\n" +
            "        <span ng-if=\"!error.value\">{{ error }}</span>\n" +
            "      </li>\n" +
            "    </ul>\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"placement-fee_box\">\n" +
            "    Your firm has allowed for the collection of an upfront, one-time sub-advisory fee for this investment as part of the\n" +
            "    subscription process. If you are intending to collect such sub-advisory fee for the investor, enter the % rate in\n" +
            "    the box below.\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"placement-fee_box\">\n" +
            "    <label class=\"placement-fee_label\">Sub-Advisory Fee:</label>\n" +
            "    <input ui-percentage-mask ui-percentage-value type=\"text\" name=\"sub_advisory_fee\" class=\"placement-fee_input\"\n" +
            "      ng-model=\"subAdvisoryForm.sub_advisory_fee\" ng-class=\"{invalid: !validSubAdvisoryFee()}\">\n" +
            "    <span class=\"placement-fee_error error\" ng-if=\"subAdvisoryFeeOverMaxValue()\">\n" +
            "      Maximum fee: {{investment.firm_fee_agreement.max_sub_advisory_fee}}%\n" +
            "    </span>\n" +
            "    <span class=\"placement-fee_error error\" ng-if=\"subAdvisoryFeeUnderMinValue()\">\n" +
            "      Minimum fee: {{investment.firm_fee_agreement.min_sub_advisory_fee}}%\n" +
            "    </span>\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"placement-fee_box\">\n" +
            "    Please see the subscription document for further instructions as to timing and wiring instructions for payment of\n" +
            "    this sub-advisory fee.\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"row ip-investment-buttons\">\n" +
            "    <button class=\"subscription-btn round-btn\" type=\"submit\" ng-click=\"updateSubAdvisoryFee()\"\n" +
            "      ng-disabled=\"!validSubAdvisoryFee()\">NEXT</button>\n" +
            "    <button class=\"cancel-btn round-btn\" ng-click=\"cancelDialog()\">CANCEL</button>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("components/investment_list/dialogs/ubs_hard_validations_dialog/ubs_hard_validations_dialog.html", "<common-modal modal-header=\"{{headerText}} ({{accountId}})\" id=\"ubs_hard_validations-dialog\">\n" +
            "  <form name=\"investorForm\" id=\"investor-form\" class=\"investor-form\" novalidate>\n" +
            "    <div class=\"validation-msg\">\n" +
            "      You may not proceed due to the errors below:\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"validation ml-0\" ng-if=\"validations.server_error\">\n" +
            "      <b>Server Error:</b> {{validations.server_error}}\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"validation ml-0\" ng-if=\"validations.account_name_error\">\n" +
            "      <b>Account Name:</b> {{validations.account_name_error}}\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"validation ml-0\" ng-if=\"validations.account_number_error\">\n" +
            "      <b>Account Number:</b> {{validations.account_number_error}}\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"validation ml-0\" ng-if=\"validations.account_number_not_matching_error\">\n" +
            "      <b>Error retrieving account data:</b> {{validations.account_number_not_matching_error}}\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"validation ml-0\" ng-if=\"validations.account_branch_error\">\n" +
            "      <b>Account Branch:</b> {{validations.account_branch_error}}\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"validation ml-0\" ng-if=\"validations.financial_advisor_error\">\n" +
            "      <b>Financial Advisor ID:</b> {{validations.financial_advisor_error}}\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"validation ml-0\" ng-if=\"validations.registration_code_error\">\n" +
            "      <b>Account Registration Code:</b> {{validations.registration_code_error}}\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"validation ml-0\" ng-if=\"validations.license_error\">\n" +
            "      <b>Advisor License/Registration:</b> {{validations.license_error}}\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"validation ml-0\" ng-if=\"validations.training_error\">\n" +
            "      <b>Advisor Training:</b> {{validations.training_error}}\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"validation ml-0\" ng-if=\"validations.subscription_error\">\n" +
            "      <b>Classification Code:</b> {{validations.subscription_error}}\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"validation ml-0\" ng-if=\"validations.tax_reporter_error\">\n" +
            "      <b>Tax Reporter:</b> {{validations.tax_reporter_error}}\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"validation ml-0\" ng-if=\"validations.signers_error\">\n" +
            "      <b>Missing Signers:</b> {{validations.signers_error}}\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"validation ml-0\" ng-if=\"validations.client_emails_error\">\n" +
            "      <b>Client Emails:</b> {{validations.client_emails_error}}\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"validation ml-0\">\n" +
            "      Please ensure the client and account information is accurate and complete in CAI before proceeding.\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"row buttons\">\n" +
            "      <button class=\"cancel-btn round-btn\" ng-click=\"confirm()\">OK</button>\n" +
            "    </div>\n" +
            "  </form>\n" +
            "</common-modal>");

        $templateCache.put("components/investment_list/dialogs/ubs_soft_validations_dialog/ubs_soft_validations_dialog.html", "<common-modal modal-header=\"{{headerText}} ({{accountId}})\" id=\"ubs_soft_validations-dialog\">\n" +
            "  <form name=\"investorForm\" id=\"investor-form\" class=\"investor-form\" novalidate>\n" +
            "    <div class=\"validation-msg\">\n" +
            "      This account is not currently eligible to invest in the fund due to the following validation failure(s):\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"validation\" ng-show=\"noAddedSigners == false\">\n" +
            "      <b>Added Signers:</b> The following signers were manually added to this account: {{addedSignersList}}\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"validation\" ng-show=\"validAccount == false || validAccount == null\">\n" +
            "      <b>World Sky Warning:</b> Please ensure the domicile of the account you wish to use for investment, and the location of the solicitation for this investment, are in an approved country. Your Series 9/10 will need to attest that the Rules of the Road have been followed.\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"validation\" ng-show=\"allowTaxExempt == false\">\n" +
            "      <b>Tax Exempt:</b> The fund does not allow tax exempt accounts to invest.\n" +
            "      Please either choose a different account or a fund that allows tax exempt investors in order to proceed.\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"validation\" ng-show=\"allowTaxExempt == null\">\n" +
            "      <b>Tax Exempt:</b> Unable to run this validation due to incomplete data.\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"validation\" ng-show=\"allowErisaAccount == false\">\n" +
            "      <b>ERISA:</b> The fund does not allow ERISA accounts to invest.\n" +
            "      Please either choose a different account or a fund that allows ERISA investors in order to proceed.\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"validation\" ng-show=\"allowErisaAccount == null\">\n" +
            "      <b>ERISA:</b> Unable to run this validation due to incomplete data.\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"validation\" ng-show=\"validAccountAddresses == false\">\n" +
            "      <b>Permanent Address:</b> Permanent address missing.\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"validation\" ng-show=\"validAccountAddresses == null\">\n" +
            "      <b>Permanent Address:</b> Unable to run this validation due to incomplete data.\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"validation\" ng-show=\"validNetWorth == false\">\n" +
            "      <b>Net Worth:</b> The account does not meet the minimum net worth required for the fund.\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"validation\" ng-show=\"validNetWorth == null\">\n" +
            "      <b>Net Worth:</b> Unable to run this validation due to incomplete data.\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"validation\" ng-show=\"validCountry == false\">\n" +
            "      <b>World Sky:</b> The fund does not allow accounts registered in this country.\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"validation\" ng-show=\"validCountry == null\">\n" +
            "      <b>World Sky:</b> Unable to run this validation due to incomplete data.\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"validation\" ng-show=\"validSuitability == false\">\n" +
            "      <b>Investor Suitability:</b> Investment will result in client holding more than 30% of his net worth as evidenced on CAI in Alternative Investments.\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"validation-msg\">\n" +
            "      Please ensure the client and account information is accurate in CAI before proceeding.\n" +
            "      This message is only a warning at this time, but the client will be blocked from investing if updates are not made.\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"validation-msg\">\n" +
            "      Would you like to proceed?\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"row buttons\">\n" +
            "      <button class=\"subscription-btn round-btn\" type=\"submit\" ng-click=\"accept()\">YES</button>\n" +
            "      <button class=\"cancel-btn round-btn\" ng-click=\"reject()\">NO</button>\n" +
            "    </div>\n" +
            "  </form>\n" +
            "</common-modal>");

        $templateCache.put("components/investment_list/templates/effective_date_tooltip.html", "<script type=\"text/ng-template\" id=\"effectiveDate-tooltip\">\n" +
            "  <div class=\"effectiveDate-tooltip--header\">Transfer</div>\n" +
            "  <div>\n" +
            "    <span class=\"effectiveDate-tooltip--key\">Effective Transfer Date:</span>\n" +
            "    <span class=\"effectiveDate-tooltip--date\">{{investment.transfer_date}}</span><br/>\n" +
            "    <span class=\"effectiveDate-tooltip--key\">Transferor:</span>\n" +
            "    <span class=\"effectiveDate-tooltip--transferor\">{{investment.transferor_name}}</span>\n" +
            "  </div>\n" +
            "</script>");

        $templateCache.put("components/investment_list/transfers/transfer_list_actions.html", "<div class=\"btn-group btn-group-sm\" ng-if=\"canManageInvestors()\">\n" +
            "  <a data-toggle=\"dropdown\" class=\"dropdown-toggle\" ng-click=\"gearClicked(transfer)\" ng-hide=\"ti || !canAccessTransfers()\"\n" +
            "     ng-if=\"!ti && canAccessTransfers() && subscription_review\">\n" +
            "    <img src=\"images/options_dark.png\">\n" +
            "  </a>\n" +
            "  <a data-toggle=\"dropdown\" class=\"dropdown-toggle\" ng-click=\"gearClicked(ti)\"\n" +
            "     ng-show=\"showChildActions(ti)\">\n" +
            "    <img src=\"images/options_dark.png\">\n" +
            "  </a>\n" +
            "  <!-- transfer group -->\n" +
            "  <div class=\"dropdown-menu text-left\" ng-if=\"!ti && canAccessTransfers() && subscription_review\">\n" +
            "    <ul>\n" +
            "      <li>\n" +
            "        <a href=\"#\" ng-click=\"cancelTransfer(transfer.investment)\">Cancel Transfer</a>\n" +
            "      </li>\n" +
            "      <li ng-if=\"transfer.completed && isAdminOrIr\">\n" +
            "        <a href=\"#\" ng-click=\"sendExecuteTransfer(transfer, subList)\">Execute Transfer</a>\n" +
            "      </li>\n" +
            "      <li ng-if=\"isAdminOrIr\">\n" +
            "        <a href=\"#\" ng-click=\"updateEffectiveDate(transfer, subList)\">Update Effective Date</a>\n" +
            "      </li>\n" +
            "    </ul>\n" +
            "  </div>\n" +
            "  <!-- transfer invetsments -->\n" +
            "  <div class=\"dropdown-menu text-left\" ng-if=\"ti\">\n" +
            "    <!-- without investment -->\n" +
            "    <div ng-hide=\"ti.investment\">\n" +
            "      <ul>\n" +
            "        <li ng-show=\"ti && !ti.investment && canAssignTransferInvestor() && !subscription_review\">\n" +
            "          <a href=\"#\"\n" +
            "             class=\"assignInvestor\"\n" +
            "             ng-click=\"addInvestors(transfer.investment, ti, $event, transfer)\">Assign Investor</a>\n" +
            "        </li>\n" +
            "      </ul>\n" +
            "    </div>\n" +
            "    <!-- with investment -->\n" +
            "    <div ng-show=\"ti.investment\">\n" +
            "      <div ng-if=\"!subscription_review && transfer.available\">\n" +
            "        <span class=\"menu-section-header\">Required Actions</span>\n" +
            "        <ol>\n" +
            "          <li>\n" +
            "            <span class=\"list-number-separator\"> | </span>\n" +
            "            <a href=\"#\" ng-if=\"canEditInvestorProfiles\" ng-click=\"editQpcProfile(ti.investment, $event)\">Complete Account Profile</a>\n" +
            "            <span ng-if=\"!canEditInvestorProfiles\" class=\"disabled-option\">Complete Account Profile</span>\n" +
            "            <i class=\"fa fa-check\" ng-show=\"ti.investment.investor_profile_completed || (fundIsOpenEnded() && !isInitialInvestment(ti.investment))\"></i>\n" +
            "          </li>\n" +
            "          <li ng-show=\"privateAccessFund.has_investment_supplemental_form\" class=\"submitSupplementalForm\">\n" +
            "            <span class=\"list-number-separator\"> | </span>\n" +
            "            <a ng-href=\"#\" ng-click=\"openSupplementalForm(ti.investment.id, $event)\">Submit Supplemental Form</a>\n" +
            "            <i class=\"fa fa-check\" ng-show=\"supplementalQuestionsCompleted(ti.investment)\"></i>\n" +
            "          </li>\n" +
            "          <li>\n" +
            "            <span class=\"list-number-separator\"> | </span>\n" +
            "            <a href=\"#\" ng-click=\"editRequiredDocuments(ti.investment, $event)\">Upload Required Documents</a>\n" +
            "            <i class=\"fa fa-check\" ng-show=\"ti.investment.aml_document_status != 'Upload'\"></i>\n" +
            "          </li>\n" +
            "          <li>\n" +
            "            <span class=\"list-number-separator\"> | </span>\n" +
            "            <a href=\"#\" ng-click=\"sendSubscription(ti.investment, $event)\" class=\"send-subscription-link\">\n" +
            "                Send Subscription\n" +
            "            </a>\n" +
            "            <i class=\"fa fa-check\" ng-show=\"agreementGenerated(ti.investment)\"></i>\n" +
            "          </li>\n" +
            "          <li>\n" +
            "            <span class=\"list-number-separator\"> | </span>\n" +
            "            <a href=\"#\" ng-if=\"hasTransferSignatureProcess(ti.investment)\" ng-click=\"sendTransferDocument(transfer.investment, ti, $event)\" class=\"send-subscription-link\">Send Transfer Documentation</a>\n" +
            "            <span ng-if=\"!hasTransferSignatureProcess(ti.investment)\" class=\"disabled-option\">Send Transfer Documentation</span>\n" +
            "            <i class=\"fa fa-check\" ng-show=\"hasTransferSignatureProcess(ti.investment)\"></i>\n" +
            "          </li>\n" +
            "        </ol>\n" +
            "      </div>\n" +
            "      <span class=\"menu-section-header\" ng-hide=\"subscription_review\">Other Actions</span>\n" +
            "      <ul>\n" +
            "        <li ng-show=\"transfer.available && ti && ti.investment && !ti.investment.accepted && canChangeTransferInvestor() && !subscription_review\">\n" +
            "          <a href=\"#\" ng-click=\"addInvestors(transfer.investment, ti, $event, transfer)\">Change Investor</a>\n" +
            "        </li>\n" +
            "        <li ng-if=\"transfer.available && !subscription_review && ((!ti.investment.completed && hasSubscriptionSignatureProcess(ti.investment)) || isPafAdmin)\">\n" +
            "          <a href=\"#\" ng-click=\"clickUploadDoc(ti.investment)\" class=\"signed-upload-sub-link\">Upload Signed Subscription</a>\n" +
            "        </li>\n" +
            "        <li ng-if=\"transfer.available && postInvestmentActionsAvailable(ti.investment) && !subscription_review\">\n" +
            "          <a href=\"#\" ng-click=\"initiateRedemption(ti.investment)\" class=\"initiate-redemption-request-link\">\n" +
            "            Initiate Redemption Request\n" +
            "          </a>\n" +
            "        </li>\n" +
            "        <li ng-if=\"transfer.available && postInvestmentActionsAvailable(ti.investment) && allowAddOns(privateAccessFund, ti.investment) && !subscription_review\">\n" +
            "          <a href=\"#\" ng-click=\"createAdditionalContribution(ti.investment)\">Additional Subscription</a>\n" +
            "        </li>\n" +
            "        <li ng-if=\"hasAgreementDocument(ti.investment) && !subscription_review\">\n" +
            "          <a href=\"#\" ng-click=\"downloadSubscription(ti.investment)\">Download Subscription</a>\n" +
            "        </li>\n" +
            "        <li ng-if=\"transfer.available && hasSubscriptionSignatureProcess(ti.investment) && !subscription_review\">\n" +
            "          <a href=\"#\" ng-click=\"openSendSubscriptionDialog(ti.investment, true)\">Send Updated Subscription</a>\n" +
            "        </li>\n" +
            "        <li ng-if=\"transfer.available && showSendReminderEmail(ti.investment) && !isHomeOfficeUser\">\n" +
            "          <a href=\"#\" ng-click=\"sendReminder(ti.investment.subscription_document, $event)\">Send Reminder</a>\n" +
            "        </li>\n" +
            "        <li ng-if=\"showSendTransferReminder(ti.investment)\">\n" +
            "          <a href=\"#\" ng-click=\"sendTransferReminder(ti, $event)\" class=\"transfer-reminder-email-link\">\n" +
            "            Send Transfer Reminder Email\n" +
            "          </a>\n" +
            "        </li>\n" +
            "        <li ng-if=\"transfer.available && !subscription_review && ((!ti.investment.completed && transferAgreementGenerated(ti.investment)) || isPafAdmin)\">\n" +
            "          <a href=\"#\" ng-click=\"clickUploadTransferDoc(transfer.investment, ti.investment)\" class=\"signed-upload-sub-link\">Upload Signed Transfer Document</a>\n" +
            "        </li>\n" +
            "        <li ng-if=\"(isQpUser || isRia || isPafAdmin) && !subscription_review && hasTransferSignatureProcess(ti.investment)\">\n" +
            "          <a href=\"#\" ng-if=\"transferAgreementGenerated(ti.investment)\" ng-click=\"sendTransferDocument(transfer.investment, ti, $event)\" class=\"send-subscription-link\">Send Updated Transfer Document</a>\n" +
            "          <span ng-if=\"!transferAgreementGenerated(ti.investment)\" class=\"disabled-option\">Send Updated Transfer Document</span>\n" +
            "        </li>\n" +
            "        <li ng-if=\"hasTransferDocument(ti.investment) && !subscription_review\">\n" +
            "          <a href=\"#\" ng-click=\"downloadTransferDocument(ti.investment)\">Download Transfer Document</a>\n" +
            "        </li>\n" +
            "        <li ng-if=\"transfer.available && !ti.investment.canceled && !ti.investment.completed\">\n" +
            "          <a href=\"#\" ng-click=\"cancelInvestment(ti.investment, $event)\">Cancel Transfer Investment</a>\n" +
            "        </li>\n" +
            "        <li ng-if=\"transfer.available && missingUserAccount(ti.investment) && !subscription_review\">\n" +
            "            <a href=\"#\" ng-click=\"sendRegistrationEmail(ti.investment)\">Send Investor Registration</a>\n" +
            "        </li>\n" +
            "        <li ng-if=\"transfer.available && (isRia || isPafAdmin) && !subscription_review\" >\n" +
            "          <a href=\"#\" ng-click=\"viewEvents(ti.investment)\">View Events History</a>\n" +
            "        </li>\n" +
            "        <li ng-if=\"transfer.available && isAdminOrIr && !subscription_review\">\n" +
            "          <a href=\"#\" ng-click=\"viewInvestorProfileEdits(ti.investment.investor_profile_id)\" class=\"download-profile-edits-link\">\n" +
            "            Download Profile Edits\n" +
            "          </a>\n" +
            "        </li>\n" +
            "      </ul>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("components/investment_list/transfers/transfer_list.html", "<div slidable\n" +
            "     closed=\"closeTransfer\"\n" +
            "     toggle=\"toggleTransfer(close)\"\n" +
            "     items=\"transferInvestments\"\n" +
            "     items-count=\"totalTransfersCount\"\n" +
            "     class=\"investment-list-table-container transferList\"\n" +
            "     ng-show=\"totalTransfersCount > 0\">\n" +
            "  <div slidable-header\n" +
            "       title=\"Transfers\"\n" +
            "       class=\"investment-list-status-header transfers\">\n" +
            "  </div>\n" +
            "  <div slidable-body>\n" +
            "    <table ng-controller=\"TransfersTableCtrl\"\n" +
            "           class=\"table table-hover investment-table transfers\"\n" +
            "           name=\"transfer-list\">\n" +
            "      <thead>\n" +
            "        <tr class=\"investment-table-header-row\">\n" +
            "          <th class=\"toggleIcon\"></th>\n" +
            "          <th cli-header order=\"investment.id\" class=\"investment-table-text investment-id\" ng-show=\"isPafAdmin\">\n" +
            "            ID\n" +
            "          </th>\n" +
            "          <th cli-header order=\"effective_date\" class=\"investment-table-text investment-effective-date\">\n" +
            "            Effective Date\n" +
            "          </th>\n" +
            "          <th cli-header order=\"investment.investor_profile.name\" class=\"investor investment-table-text\">\n" +
            "            Investor\n" +
            "          </th>\n" +
            "          <th cli-header order=\"investor_profile_completed\"\n" +
            "              use-number=\"true\"\n" +
            "              class=\"investment-table-text investmentList-pe-investor-profile\">\n" +
            "            Account Profile\n" +
            "          </th>\n" +
            "          <th cli-header order=\"supplemental_questions\"\n" +
            "              use-number=\"true\"\n" +
            "              class=\"investment-table-text investmentList-pe-investor-profile\"\n" +
            "              ng-show=\"privateAccessFund.has_investment_supplemental_form || selectedPaf.has_investment_supplemental_form\">\n" +
            "            Supplemental Form\n" +
            "          </th>\n" +
            "          <th cli-header order=\"percentage\">\n" +
            "            Percentage\n" +
            "          </th>\n" +
            "          <th cli-header order=\"units\" ng-show=\"showUnits()\">\n" +
            "            Units\n" +
            "          </th>\n" +
            "          <th cli-header order=\"amount\" class=\"investment-table-number\" >\n" +
            "            Amount ({{currency}})\n" +
            "          </th>\n" +
            "          <th cli-header order=\"investment.aml_document_status\" use-number=\"true\" class=\"investment-table-text\">\n" +
            "            Required Docs\n" +
            "          </th>\n" +
            "          <th cli-header order=\"investment.subscription_document.status\" use-number=\"true\" class=\"investment-table-text\">\n" +
            "            Agreement\n" +
            "          </th>\n" +
            "          <th\n" +
            "            cli-header\n" +
            "            use-number=\"true\"\n" +
            "            ng-if=\"wlpSpecificAttestation\"\n" +
            "            order=\"investment.wlp_attestation_completed\"\n" +
            "            class=\"investment-table-text\"\n" +
            "          >\n" +
            "            Advisor Attestation\n" +
            "          </th>\n" +
            "          <th cli-header order=\"transferAgreementText(investment)\" use-number=\"true\" class=\"investment-table-text\">\n" +
            "            Transfer Documentation\n" +
            "          </th>\n" +
            "          <th class=\"no-sort\" ng-hide=\"isHomeOfficeUser\">\n" +
            "            Actions\n" +
            "          </th>\n" +
            "        </tr>\n" +
            "      </thead>\n" +
            "      <tbody ng-repeat=\"subList in subLists\" ng-include=\"'components/investment_list/transfers/transfer_sub_list.html'\">\n" +
            "      </tbody>\n" +
            "    </table>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("components/investment_list/transfers/transfer_sub_list.html", "<tr class=\"investment-table-body-group transfer-table-header\"\n" +
            "    ng-click=\"subList.total == 0 || expandTransfers(subList)\"\n" +
            "    ng-class=\"{'executedTransfers': !subList.available}\"\n" +
            "    ng-disabled=\"subList.total == 0\">\n" +
            "  <!-- Group Header -->\n" +
            "  <td class=\"investment-table-text toggleIcon\"\n" +
            "      ng-class=\"{'expanded': subList.expanded}\">\n" +
            "    <img ng-src=\"{{sectionChevronIcon(subList)}}\">\n" +
            "  </td>\n" +
            "  <td class=\"investment-table-text\" ng-show=\"hasSupplementalForm()\" colspan=\"{{colspanForTotal()}}\">\n" +
            "    <span>{{subList.header}} ({{subList.total}})</span>\n" +
            "  </td>\n" +
            "  <td class=\"investment-table-text\" ng-hide=\"hasSupplementalForm()\" colspan=\"{{colspanForTotal()}}\">\n" +
            "    <span>{{subList.header}} ({{subList.total}})</span>\n" +
            "  </td>\n" +
            "  <td ng-show=\"isPafAdmin\"></td>\n" +
            "  <td>{{subList.totalAmount | number : 0}}</td>\n" +
            "  <td colspan=\"5\"></td>\n" +
            "</tr>\n" +
            "<!-- parent investment -->\n" +
            "<tr ng-repeat-start=\"transfer in subList.transfers\"\n" +
            "    id=\"transfer-{{transfer.id}}\" class=\"investment-table-body-row\"\n" +
            "    ng-class=\"{'investmentList-newItem': highlightRow(investment)}\"\n" +
            "    ng-click=\"expandTransfer(transfer)\"\n" +
            "    ng-show=\"subList.expanded\">\n" +
            "  <td class=\"investment-table-text toggleIcon\"\n" +
            "      ng-class=\"{'expanded': transfer.expanded}\">\n" +
            "    <img ng-src=\"{{transferChevronIcon(transfer)}}\">\n" +
            "  </td>\n" +
            "  <!-- id -->\n" +
            "  <td class=\"investment-table-text\" ng-show=\"isPafAdmin\">\n" +
            "    {{transfer.investment.id}}\n" +
            "  </td>\n" +
            "  <td class=\"investment-table-text\">\n" +
            "    {{transfer.effective_date | date: \"dd-MMM-yyyy\"}}\n" +
            "  </td>\n" +
            "  <!-- investor -->\n" +
            "  <td class=\"investor investment-table-text\">\n" +
            "    <span>{{ transfer.investment.investor_profile.name }}</span>\n" +
            "  </td>\n" +
            "  <!-- investor profile -->\n" +
            "  <td class=\"investment-table-text investmentList-pe-investor-profile\">\n" +
            "  </td>\n" +
            "  <!-- supplemental questions -->\n" +
            "  <td class=\"investment-table-text investmentList-pe-investor-profile\" ng-show=\"hasSupplementalForm()\">\n" +
            "  </td>\n" +
            "  <!-- transfer percentage -->\n" +
            "  <td>\n" +
            "    {{ transfer.percentage | percentage }}\n" +
            "  </td>\n" +
            "  <!-- units -->\n" +
            "  <td class=\"investment-table-number\" ng-show=\"showUnits()\">\n" +
            "    {{ transfer.units | number:0 }}\n" +
            "  </td>\n" +
            "  <!-- commitment -->\n" +
            "  <td class=\"investment-table-number\">\n" +
            "    {{ transfer.transfer_amount | number : 0 }}\n" +
            "  </td>\n" +
            "  <!-- aml status -->\n" +
            "  <td class=\"investment-table-text\">\n" +
            "  </td>\n" +
            "  <!-- agreement -->\n" +
            "  <td class=\"investment-table-text\">\n" +
            "  </td>\n" +
            "  <!-- advisor attestation -->\n" +
            "  <td class=\"investment-table-text\" ng-if=\"wlpSpecificAttestation\">\n" +
            "  </td>\n" +
            "  <!-- transfer documentation -->\n" +
            "  <td class=\"investment-table-text\">\n" +
            "  </td>\n" +
            "  <!-- actions -->\n" +
            "  <td class=\"no-sort investmentList investment-actions\" ng-hide=\"hideActionsMenuForHomeOfficeUser\">\n" +
            "    <ng-include src=\"'components/investment_list/transfers/transfer_list_actions.html'\" ng-show=\"transfer.available\">\n" +
            "    </ng-include>\n" +
            "  </td>\n" +
            "</tr>\n" +
            "<!-- transfer investments -->\n" +
            "<tr ng-repeat-end class=\"investment-table-body-row transferList-row\"\n" +
            "    id=\"transfer-investment-{{ti.id}}\"\n" +
            "    ng-repeat=\"ti in transfer.transfer_investments | orderBy:orderByField:reverseSort\"\n" +
            "    ng-class=\"{'investmentList-newItem': highlightRow(ti.investment)}\"\n" +
            "    ng-show=\"transfer.expanded\">\n" +
            "  <td class=\"investment-table-text toggleIcon\"></td>\n" +
            "  <!-- id -->\n" +
            "  <td class=\"investment-table-text\" ng-show=\"isPafAdmin\">\n" +
            "    {{ti.investment.id}}\n" +
            "  </td>\n" +
            "  <td class=\"investment-table-text\">\n" +
            "  </td>\n" +
            "  <!-- investor -->\n" +
            "  <td class=\"investor investment-table-text\">\n" +
            "    <span>{{ ti.investment.investor_profile.name }}</span>\n" +
            "  </td>\n" +
            "  <!-- investor profile -->\n" +
            "  <td class=\"investment-table-text investmentList-pe-investor-profile\">\n" +
            "    <div ng-show=\"!isInitialInvestment(ti.investment) || reverted(ti.investment)\"\n" +
            "         class=\"investor-profile-disabled\">\n" +
            "      {{ profileText(ti.investment) | titleCase }}\n" +
            "    </div>\n" +
            "    <a href=\"#\"\n" +
            "       class=\"{{ ti.investment.investor_profile_completed && 'profile-complete-status' || 'profile-incomplete-status'}}\"\n" +
            "       ng-class=\"{'disable-link':disabledLink(transfer) || !ti.modifiable_by_user }\"\n" +
            "       ng-show=\"showInvestorProfileNameLink(ti.investment) && !reverted(ti.investment)\"\n" +
            "       ng-click=\"editQpcProfile(ti.investment, $event, 'pe')\">\n" +
            "      <span>{{ profileText(ti.investment) | titleCase }}</span>\n" +
            "    </a>\n" +
            "    <span ng-show=\"showInvestorProfilePlainName()\">\n" +
            "      {{ ti.investment.investor_profile_completed && \"Complete\" || \"Incomplete\" }}\n" +
            "    </span>\n" +
            "  </td>\n" +
            "  <!-- supplemental questions -->\n" +
            "  <td class=\"investment-table-text investmentList-pe-investor-profile\" ng-show=\"hasSupplementalForm()\">\n" +
            "      <a ng-href=\"#\"\n" +
            "          ng-click=\"openSupplementalForm(ti.investment.id, $event)\"\n" +
            "          class=\"{{ ::(supplementalQuestionsCompleted(ti.investment) && 'form-complete-status' || 'form-incomplete-status') }}\"\n" +
            "          ng-class=\"{'disable-link':disabledLink(transfer) || !ti.modifiable_by_user}\"\n" +
            "          ng-hide=\"isHomeOfficeUser\">\n" +
            "        <span>{{ investmentSupplementalResponseStatusString(ti.investment) }}</span>\n" +
            "      </a>\n" +
            "      <span ng-show=\"isHomeOfficeUser\">{{ investmentSupplementalResponseStatusString(ti.investment) }}</span>\n" +
            "  </td>\n" +
            "  <!-- transfer percentage -->\n" +
            "  <td>\n" +
            "    {{ transfer.percentage * ti.amount / transfer.transfer_amount | percentage }}\n" +
            "  </td>\n" +
            "  <!-- units -->\n" +
            "  <td class=\"investment-table-number\" ng-show=\"showUnits()\">\n" +
            "    {{ ti.units | number : 0 }}\n" +
            "  </td>\n" +
            "  <!-- commitment -->\n" +
            "  <td class=\"investment-table-number\">\n" +
            "    {{ ti.amount | number : 0 }}\n" +
            "  </td>\n" +
            "  <!-- aml status -->\n" +
            "  <td class=\"investment-table-text\">\n" +
            "    <a href=\"#\"\n" +
            "       ng-click=\"editRequiredDocuments(ti.investment, $event)\"\n" +
            "       ng-class=\"{'disable-link':disabledLink(transfer) || !ti.modifiable_by_user, 'incomplete-status':ti.aml_document_status == 'Upload' && !subscription_review && transfer.available, 'complete-status':ti.aml_document_status != 'Upload' && !subscription_review && transfer.available}\"\n" +
            "       ng-hide=\"isHomeOfficeUser || reverted(ti.investment)\">\n" +
            "      {{ ti.investment.aml_document_status }}\n" +
            "    </a>\n" +
            "    <span ng-show=\"isHomeOfficeUser || reverted(ti.investment)\">\n" +
            "      {{ ti.investment.aml_document_status }}\n" +
            "    </span>\n" +
            "    <i class=\"fa fa-warning\" ng-show=\"adminDocsSent(ti.investment)\"></i>\n" +
            "  </td>\n" +
            "  <!-- agreement -->\n" +
            "  <td ng-class=\"{ 'investment-table-text agreement-status-text': !subscription_review, 'investment-table-text': subscription_review }\">\n" +
            "    <div ng-if=\"!showAgreementLink(ti.investment, true)\">{{ agreementText(ti.investment) | titleCase }}</div>\n" +
            "    <a href=\"#\"\n" +
            "       ng-click=\"handleSubscription(ti.investment)\"\n" +
            "       ng-if=\"showAgreementLink(ti.investment)\"\n" +
            "       ng-class=\"{'disable-link':disabledLink(transfer) || !ti.modifiable_by_user }\">\n" +
            "      {{ agreementText(ti.investment) | titleCase }}\n" +
            "    </a>\n" +
            "    <span ng-show=\"!hasSubscriptionSignatureProcess(ti.investment) && isHomeOfficeUser\"> Not Sent </span>\n" +
            "  </td>\n" +
            "  <!-- advisor attestation -->\n" +
            "  <td class=\"investment-table-text\" ng-if=\"wlpSpecificAttestation\">\n" +
            "    <a\n" +
            "      href=\"#\"\n" +
            "      class=\"wlp-attestation-incomplete-status\"\n" +
            "      ng-if=\"canCompleteWlpAttestation(ti.investment)\"\n" +
            "      ng-click=\"showWlpAttestationDialog(ti.investment, $event)\"\n" +
            "    >\n" +
            "      <span class=\"advisor-attestation-link\">Incomplete</span>\n" +
            "    </a>\n" +
            "    <span\n" +
            "      class=\"advisor-attestation-status\"\n" +
            "      ng-if=\"!canCompleteWlpAttestation(ti.investment) || ti.investment.wlp_attestation_completed\"\n" +
            "    >\n" +
            "      {{ ti.investment.wlp_attestation_completed && \"Complete\" || \"Incomplete\" }}\n" +
            "    </span>\n" +
            "  </td>\n" +
            "  <!-- transfer documentation -->\n" +
            "  <td ng-class=\"{\n" +
            "    'investment-table-text agreement-status-text': transferAgreementToBePrepared(ti.investment),\n" +
            "    'investment-table-text': !transferAgreementToBePrepared(ti.investment)\n" +
            "    }\">\n" +
            "    <div ng-hide=\"hideTransferTextDiv(ti.investment)\">\n" +
            "      {{ transferAgreementText(ti.investment) | titleCase }}\n" +
            "    </div>\n" +
            "    <a href=\"#\"\n" +
            "       ng-click=\"handleTransferDocument(transfer.investment, ti, $event)\"\n" +
            "       ng-show=\"showTransferAgreementLink(ti.investment)\"\n" +
            "       ng-class=\"{'disable-link':disabledLink(transfer) || !ti.modifiable_by_user, 'incomplete-status':!transferAgreementGenerated(ti.investment) && !subscription_review }\">\n" +
            "      {{ transferAgreementText(ti.investment) | titleCase }}\n" +
            "    </a>\n" +
            "    <span ng-show=\"showTransferAgreementSpan(ti.investment)\"> Not Sent </span>\n" +
            "  </td>\n" +
            "  <!-- actions -->\n" +
            "  <td class=\"no-sort investmentList investment-actions transfer-investment-actions\" ng-hide=\"hideActionsMenuForHomeOfficeUser\">\n" +
            "    <ng-include ng-if=\"!reverted(ti.investment) && ti.modifiable_by_user\" src=\"'components/investment_list/transfers/transfer_list_actions.html'\">\n" +
            "    </ng-include>\n" +
            "    <span class=\"text-danger\" ng-if=\"reverted(ti.investment)\">Attention Required</span>\n" +
            "  </td>\n" +
            "</tr>");

        $templateCache.put("components/investor_documents/components/echosign/esign_modal.html", "<common-modal modal-header=\"{{header}}\">\n" +
            "  <div class=\"request-esign-modal\" ng-form=\"emailForm\">\n" +
            "    <p ng-show=\"!deletable\">\n" +
            "      The W-9 Form will be sent securely to the following email address(es):\n" +
            "    </p>\n" +
            "    <p ng-show=\"deletable\">\n" +
            "      The W-9 Form will be sent securely to the following email address:\n" +
            "    </p>\n" +
            "\n" +
            "    <signers account-type=\"{{accountType}}\" set-signer=\"setSigner\" signers=\"signers\" hide-selectors=\"hideSelectors\"\n" +
            "    ng-show=\"!deletable\"></signers>\n" +
            "\n" +
            "    <div class=\"form-group email-address-field-wrapper\" ng-show=\"deletable\">\n" +
            "      <input id=\"email\"\n" +
            "        name=\"email\"\n" +
            "        ng-model=\"formData.email\"\n" +
            "        type=\"email\"\n" +
            "        class=\"form-control email-field investment-email\"/>\n" +
            "      <label class=\"common-label-positioning\"\n" +
            "          ng-class=\"{ 'common-label-positioning-floating' : emailForm.email.$error.email || emailInserted() }\">\n" +
            "          Email Address\n" +
            "      </label>\n" +
            "    </div>\n" +
            "    <span class=\"error\" ng-show=\"emailForm.email.$error.email\">Please provide a valid email address.</span>\n" +
            "\n" +
            "    <div class=\"esign-modal-buttons\" ng-class=\"{ 'no-border' : deletable }\">\n" +
            "      <button class=\"right-aligned-btn button__short button__wide\"\n" +
            "        type=\"submit\"\n" +
            "        ng-disabled=\"deletable && (emailForm.email.$error.email || !emailInserted() ) || !deletable && !selectionMade()\"\n" +
            "        ng-click=\"submit()\"> CONTINUE </button>\n" +
            "      <button class=\"right-aligned-btn button__short button__wide button__cancel\" type=\"close\" ng-click=\"cancel()\">CANCEL</button>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("components/investor_documents/components/echosign/signers.html", "<ul class=\"esigner-selection\">\n" +
            "  <li class=\"signer-option\" data-ng-repeat=\"sig in signers | orderBy: 'sig.id'\">\n" +
            "    <div ng-if=\"hideSelectors\">\n" +
            "      <i class=\"fa fa-envelope single-signer\" aria-hidden=\"true\"></i>\n" +
            "      {{sig.label}}\n" +
            "    </div>\n" +
            "    <div ng-if=\"!hideSelectors\" ng-switch=\"accountType\">\n" +
            "      <div ng-switch-when=\"joint\">\n" +
            "        <icn-checkbox\n" +
            "          label=\"{{sig.label}}\"\n" +
            "          custom_id=\"{{sig.id}}\"\n" +
            "          model=\"sig.selected\"\n" +
            "        />\n" +
            "      </div>\n" +
            "      <div class=\"icn-radio-container\" ng-switch-when=\"entity\">\n" +
            "        <label class=\"icn-radio-container\">\n" +
            "          <input id='{{sig.id}}' type=\"radio\" ng-click=\"setSigner(sig.id)\" name=\"signer\">\n" +
            "          <div class=\"icn-fake-radio\" for=\"{{customId}}\"></div>\n" +
            "          <span>{{sig.label}}</span>\n" +
            "        </label>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </li>\n" +
            "</ul>");

        $templateCache.put("components/investor_documents/components/required_document_upload/add_aml.html", "<div class=\"upload-wrap\">\n" +
            "  <div class=\"investorProfile-documents\" style=\"margin-top: 0;\">\n" +
            "    <div class=\"upload-container\" ng-class=\"{'inverse': loading}\">\n" +
            "      <img ng-hide=\"loading\" ng-click=\"closeAdd()\" src=\"images/button-close_24x24.svg\" class=\"ex\">\n" +
            "\n" +
            "      <div ng-show=\"loading\" class=\"loader-container\"><div class=\"loader inverse\"></div></div>\n" +
            "\n" +
            "      <div ng-hide=\"loading\" class=\"file-upload upload-border select-doc\" style=\"padding-top: 0;\">\n" +
            "        <h5 ng-hide=\"error\" class=\"aml-title\">Select the document-type</h5>\n" +
            "        <h4 ng-show=\"error\" class=\"aml-error\">Selection Failed</h4>\n" +
            "        <form id=\"aml-options\" class=\"aml-options\">\n" +
            "          <div class=\"container\">\n" +
            "            <ul>\n" +
            "              <li ng-repeat=\"type in types\">\n" +
            "                <input type=\"radio\" ng-model=\"model.selectedType\" ng-value=\"{{type.value}}\" id=\"{{type.id}}\"><label for=\"{{type.id}}\">{{type.label}}</label>\n" +
            "                <label for=\"{{type.id}}\" class=\"check\"></label>\n" +
            "              </li>\n" +
            "            </ul>\n" +
            "          </div>\n" +
            "          <div ng-show=\"showNext()\" ng-click=\"addRequiredDoc()\" class=\"next\"><a>next</a><img src=\"images/button-next-arrow_11x9.svg\"></div>\n" +
            "        </form>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("components/investor_documents/components/required_document_upload/dialogs/document_instructions_dialog/document_instructions_dialog.html", "<common-modal modal-header=\"Document Instructions\">\n" +
            "    <div ng-if=\"instructions === ''\" class=\"document-instructions__description\">\n" +
            "        {{document.required_document_type.description}}\n" +
            "    </div>\n" +
            "    <div ng-if=\"instructions !== ''\" ng-bind-html=\"instructions\"></div>\n" +
            "</common-modal>");

        $templateCache.put("components/investor_documents/components/required_document_upload/dialogs/download_countersign_document/download_countersign_document_dialog.html", "<common-modal class=\"gp-signature-dialog \" modal-header=\"Download Countersignature Template\">\n" +
            "  <div>\n" +
            "    <form name=\"generalPartnerForm\" ng-submit=\"onSubmit(generalPartnerForm)\" novalidate>\n" +
            "      Please provide the name and title of the signatory.\n" +
            "      <div class=\"row\">\n" +
            "        <div class=\"col-md-12\">\n" +
            "          <b>Fund Name:</b>{{paf.name}}\n" +
            "        </div>\n" +
            "        <div class=\"col-md-12\">\n" +
            "          <b>Close Date:</b>{{close.close_date}}\n" +
            "        </div>\n" +
            "        <div class=\"col-md-12\">\n" +
            "          <b># Investors:</b>{{investorCount}}\n" +
            "        </div>\n" +
            "        <div class=\"col-md-12\">\n" +
            "          <b>Amount:</b>{{totalCommitment | currency:undefined:0 }}\n" +
            "        </div>\n" +
            "      </div>\n" +
            "      <hr>\n" +
            "      <div class=\"general-partner-info\">\n" +
            "        <div class=\"row\">\n" +
            "          <label class=\"col-md-6\">Name:</label>\n" +
            "          <input id=\"name\" ng-model=\"data.name\" class=\"col-md-6\" type=\"text\" required/>\n" +
            "        </div>\n" +
            "        <div class=\"row\">\n" +
            "          <label class=\"col-md-6\">Title of Signatory:</label>\n" +
            "          <input id=\"title\" type=\"text\" class=\"col-md-6\"  ng-model=\"data.title\" required/>\n" +
            "        </div>\n" +
            "        <div class=\"row button-group\">\n" +
            "          <button class=\"link-button\" type=\"submit\">Download</button>\n" +
            "          <button class=\"link-button cancel-btn\" type=\"button\" ng-click=\"onCancel()\">Cancel</button>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "    </form>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("components/investor_documents/components/required_document_upload/dialogs/send_countersign_document/send_countersign_document_dialog.html", "<common-modal class=\"gp-signature-dialog \" modal-header=\"Send Countersignature Document for Electronic Signature\">\n" +
            "  <div>\n" +
            "    <form name=\"generalPartnerForm\" ng-submit=\"onSubmit(generalPartnerForm)\" novalidate>\n" +
            "      The Countersignature document will be sent securely through Adobe Sign to the email address provided.\n" +
            "      <div class=\"row\">\n" +
            "        <div class=\"col-md-12\">\n" +
            "          <b>Fund Name:</b>{{paf.name}}\n" +
            "        </div>\n" +
            "        <div class=\"col-md-12\">\n" +
            "          <b>Close Date:</b>{{close.close_date}}\n" +
            "        </div>\n" +
            "        <div class=\"col-md-12\">\n" +
            "          <b># Investors:</b>{{investorCount}}\n" +
            "        </div>\n" +
            "        <div class=\"col-md-12\">\n" +
            "          <b>Amount:</b>{{totalCommitment | currency:undefined:0 }}\n" +
            "        </div>\n" +
            "      </div>\n" +
            "      <hr>\n" +
            "      <div class=\"general-partner-info\">\n" +
            "        <div class=\"row\">\n" +
            "          <label class=\"col-md-6\">Name:</label>\n" +
            "          <input id=\"name\" ng-model=\"data.name\" class=\"col-md-6\" type=\"text\" required/>\n" +
            "        </div>\n" +
            "        <div class=\"row\">\n" +
            "          <label class=\"col-md-6\">Title of Signatory:</label>\n" +
            "          <input type=\"text\" class=\"col-md-6\"  ng-model=\"data.title\" required/>\n" +
            "        </div>\n" +
            "        <div class=\"row\">\n" +
            "          <label class=\"col-md-6\">Email Address:</label>\n" +
            "          <input id=\"email\" class=\"col-md-6\" type=\"email\" ng-model=\"data.email\" ng-required=\"true\"/>\n" +
            "          <span ng-show=\"form.email.email.$invalid\">\n" +
            "            Please enter a correct email address.\n" +
            "          </span>\n" +
            "        </div>\n" +
            "        <div class=\"row button-group\">\n" +
            "          <button class=\"link-button\" type=\"submit\">Send</button>\n" +
            "          <button class=\"link-button cancel-btn\" type=\"button\" ng-click=\"onCancel()\">Cancel</button>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "    </form>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("components/investor_documents/components/required_document_upload/required_document_upload.html", "<div ng-switch=\"uploadStatus\" class=\"upload-status\">\n" +
            "  <div class=\"upload-wrap\">\n" +
            "\n" +
            "    <div class=\"upload-container\" id=\"upload-container-{{idString}}\">\n" +
            "      <div class=\"description\" ng-show=\"showDescription()\">\n" +
            "        <div ng-bind-html=\"docDescription\"> </div>\n" +
            "        <div ng-show=\"esignable()\">\n" +
            "          <span ng-if=\"isCounterSignatureDocument()\">\n" +
            "            <a href=\"#\" ng-click=\"showManualCountersignDialog()\">Download template</a> for manual signature <br> or\n" +
            "            <a href=\"#\" ng-click=\"showCountersignDialog()\">send file</a> for electronic signature\n" +
            "          </span>\n" +
            "          <div ng-if=\"uploadStatus == 'signature_needed' || uploadStatus == 'required'\">\n" +
            "            or\n" +
            "            <span ng-if=\"isTaxDocument()\">\n" +
            "              <a class=\"esign-link\"\n" +
            "                ng-click=\"echosignClick()\"\n" +
            "                document=\"document\">\n" +
            "                send for electronic signature\n" +
            "              </a>\n" +
            "            </span>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "\n" +
            "      <div ng-switch-when=\"pending_subdoc_upload\" ondragover=\"angular.element(this).scope().fadeFileSelect()\" id=\"subdoc-file-{{idString}}\" class=\"file-upload upload-border\">\n" +
            "        <div class=\"file-input-mask\" id=\"subdoc-file-{{idString}}\"></div>\n" +
            "        <div class=\"vert-box left-box\">\n" +
            "          <div ng-click=\"picker()\" class=\"vert-box bkgrnd clickable thumbnail\">\n" +
            "            <object data=\"images/pending_upload_thumb.svg\"\n" +
            "                    type=\"image/png\" class=\"doc-image\">\n" +
            "              <img src=\"images/sub_thumb.png\">\n" +
            "            </object>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "        <div class=\"right-box\">\n" +
            "          <div class=\"title-and-status\">\n" +
            "            <h5 class=\"aml-title\">\n" +
            "                {{document.title | truncateByWord: titleLimit}}\n" +
            "            </h5>\n" +
            "            <span>Downloaded</span>\n" +
            "          </div>\n" +
            "          <ul class=\"action-links\">\n" +
            "            <li class=\"normal-wrap\">\n" +
            "              <a class=\"send-updated-link\" href=\"\" ng-click=\"openSendSubscriptionDialog(true)\">Send Updated Subscription</a>\n" +
            "              <i class=\"fa fa-check subdoc-check\"></i>\n" +
            "            </li>\n" +
            "            <li>\n" +
            "              <a class=\"upload-signed-link\" href=\"\" ng-click=\"clickUploadDoc()\">Upload Signed Subscription</a>\n" +
            "            </li>\n" +
            "          </ul>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "\n" +
            "      <div ng-switch-when=\"required_sub_doc\" ondragover=\"angular.element(this).scope().fadeFileSelect()\"\n" +
            "        id=\"subdoc-file-{{idString}}\" class=\"file-upload upload-border\">\n" +
            "        <div class=\"file-input-mask\" id=\"subdoc-file-{{idString}}\"></div>\n" +
            "        <div class=\"upload-error esign-error\" ng-show=\"esignFailure\">{{errorMessage}}</div>\n" +
            "        <div class=\"vert-box left-box\">\n" +
            "          <div class=\"vert-box bkgrnd clickable thumbnail disabled\">\n" +
            "            <object data=\"images/sub_thumb.svg\" type=\"image/png\" class=\"doc-image\">\n" +
            "              <img src=\"images/sub_thumb.png\">\n" +
            "            </object>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "        <div class=\"right-box\">\n" +
            "          <div class=\"title-and-status\">\n" +
            "            <h5 class=\"aml-title\">\n" +
            "              {{document.title | truncateByWord: titleLimit}}\n" +
            "            </h5>\n" +
            "            <span>Reverted</span>\n" +
            "          </div>\n" +
            "          <ul class=\"action-links\">\n" +
            "            <li class=\"normal-wrap\">\n" +
            "              <a class=\"send-updated-link\" href=\"\" ng-click=\"openSendSubscriptionDialog(true)\">Send Updated Subscription</a>\n" +
            "            </li>\n" +
            "            <li class=\"normal-wrap disabled\">\n" +
            "              Upload Signed Subscription\n" +
            "            </li>\n" +
            "          </ul>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "\n" +
            "      <div ng-switch-when=\"pending_upload\" ondragover=\"angular.element(this).scope().fadeFileSelect()\" id=\"subdoc-file-{{idString}}\" class=\"file-upload upload-border clickable\">\n" +
            "        <div class=\"file-input-mask\" id=\"subdoc-file-{{idString}}\"></div>\n" +
            "        <h5 ng-click=\"openDocumentInstructions()\" class=\"aml-title\">\n" +
            "          {{document.title | truncateByWord: titleLimit}}\n" +
            "        </h5>\n" +
            "        <img src=\"images/drag_drop.svg\" height=\"30\" width=\"48\">\n" +
            "        <div class=\"sm-mrg\">Drop <span ng-show=\"isCounterSignatureDocument()\">\n" +
            "          PDF</span> file here or <a class=\"clickable top\" ng-click=\"picker()\">browse files</a></div>\n" +
            "      </div>\n" +
            "\n" +
            "      <div ng-switch-when=\"required\" ondragover=\"angular.element(this).scope().fadeFileSelect()\" id=\"subdoc-file-{{idString}}\" class=\"file-upload upload-border clickable\">\n" +
            "        <div class=\"file-input-mask\" id=\"subdoc-file-{{idString}}\"></div>\n" +
            "        <h5 ng-click=\"openDocumentInstructions()\" class=\"aml-title\">\n" +
            "          {{document.title | truncateByWord: titleLimit}}\n" +
            "        </h5>\n" +
            "        <img src=\"images/drag_drop.svg\" height=\"30\" width=\"48\">\n" +
            "        <div class=\"sm-mrg\">Drop <span ng-show=\"isCounterSignatureDocument()\">\n" +
            "          PDF</span> file here or <a class=\"clickable top\" ng-click=\"picker()\">browse files</a>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "\n" +
            "      <div ng-switch-when=\"signature_needed\" ondragover=\"angular.element(this).scope().fadeFileSelect()\"  id=\"subdoc-file-{{idString}}\" class=\"file-upload upload-border clickable\">\n" +
            "        <div class=\"file-input-mask\" id=\"subdoc-file-{{idString}}\"></div>\n" +
            "        <div class=\"upload-error esign-error\" ng-show=\"esignFailure\">{{errorMessage}}</div>\n" +
            "        <h5 ng-click=\"openDocumentInstructions()\" class=\"aml-title\">\n" +
            "          {{document.title | truncateByWord: titleLimit}}\n" +
            "        </h5>\n" +
            "        <img src=\"images/drag_drop.svg\" height=\"30\" width=\"48\">\n" +
            "        <div class=\"sm-mrg\">Drop <span ng-show=\"isCounterSignatureDocument()\">\n" +
            "          PDF</span> file here or <a class=\"clickable top\" ng-click=\"picker()\">browse files</a></div>\n" +
            "      </div>\n" +
            "\n" +
            "      <div ng-switch-when=\"loading\" class=\"loader-container\"><div class=\"loader\"></div></div>\n" +
            "\n" +
            "      <div ng-switch-when=\"complete\" class=\"complete\">\n" +
            "        <div style=\"margin: 0 auto;\">\n" +
            "          <img class=\"check-splash\" src=\"images/checkmark.svg\">\n" +
            "          <div class=\"check-splash-text\">Upload Successful</div>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "\n" +
            "      <div class=\"check top-right-icon\" ng-switch-when=\"uploaded\">\n" +
            "        <span id=\"check-{{idString}}\" class=\"fa-stack\">\n" +
            "          <i class=\"fa fa-circle fa-stack-2x outer-green\" aria-hidden=\"true\"></i>\n" +
            "          <i class=\"fa fa-check fa-stack-1x inner-icon\" aria-hidden=\"true\"></i>\n" +
            "        </span>\n" +
            "      </div>\n" +
            "      <div ng-switch-when=\"uploaded\" id=\"subdoc-file-{{idString}}\" class=\"file-upload upload-border uploaded-file\">\n" +
            "        <div class=\"file-input-mask\" id=\"subdoc-file-{{idString}}\"></div>\n" +
            "        <div class=\"vert-box left-box\">\n" +
            "          <div ng-click=\"picker()\" class=\"vert-box bkgrnd clickable thumbnail\">\n" +
            "            <img ng-attr-src=\"{{document.thumb_vertical_url}}\"\n" +
            "                 type=\"image/png\" onerror=\"this.src='images/sub_thumb.png'\"/>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "        <div class=\"right-box\">\n" +
            "          <div class=\"title-and-status\">\n" +
            "              <h5 class=\"aml-title\"\n" +
            "                  ng-class=\"isTaxDocument() ? ['w8-w9-line-break'] : []\">\n" +
            "                  {{document.title | truncateByWord: titleLimit}}\n" +
            "              </h5>\n" +
            "              <span>Uploaded</span>\n" +
            "              <div class=\"on-shore-tooltip-container\" ng-if=\"showDestroyFile()\">\n" +
            "                <span\n" +
            "                  tooltip-class=\"on-shore-tooltip\"\n" +
            "                  tooltip-html=\"toolTips.fetch().deleteDocument\"\n" +
            "                  tooltip-placement=\"bottom\"\n" +
            "                  tooltip-append-to-body=\"false\"\n" +
            "                  tooltip-trigger=\"mouseenter\">\n" +
            "                  <span class=\"delete-document\" ng-click=\"removeFile(document)\"><i class=\"fa fa-times\" aria-hidden=\"true\"></i></span>\n" +
            "                </span>\n" +
            "              </div>\n" +
            "          </div>\n" +
            "\n" +
            "          <ul class=\"action-links clickable\">\n" +
            "            <li ng-if=\"isCounterSignatureDocument() && !close.merged_countersignature\">\n" +
            "              <a href=\"#\" ng-click=\"showManualCountersignDialog()\">Download template</a>\n" +
            "            </li>\n" +
            "            <li ng-if=\"!isTransferDocument() && ((isCounterSignatureDocument() && !close.merged_countersignature) || !isCounterSignatureDocument())\">\n" +
            "              <a ng-click=\"picker()\">Upload new file</a>\n" +
            "            </li>\n" +
            "            <li ng-if=\"isTransferDocument()\">\n" +
            "              <a ng-click=\"clickUploadTransferDoc()\">Upload Transfer Document</a>\n" +
            "            </li>\n" +
            "            <li ng-if=\"esignable() && isTaxDocument()\">\n" +
            "              <a ng-click=\"reSendForESignature()\">Send for Electronic Signature</a>\n" +
            "            </li>\n" +
            "            <li ng-if=\"isCounterSignatureDocument() && !close.merged_countersignature\">\n" +
            "              <a class=\"resend-esign\" ng-click=\"showCountersignDialog()\">Send for e-signature</a>\n" +
            "            </li>\n" +
            "            <li ng-if=\"showDownloadLink()\">\n" +
            "              <a ng-click=\"viewDocument(document)\">View file</a>\n" +
            "            </li>\n" +
            "          </ul>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "\n" +
            "      <div ng-if=\"generatingAgreement()\" ondragover=\"angular.element(this).scope().fadeFileSelect()\" id=\"subdoc-file-{{idString}}\" class=\"file-upload upload-border\">\n" +
            "        <div class=\"file-input-mask\" id=\"subdoc-file-{{idString}}\"></div>\n" +
            "        <div class=\"upload-error esign-error\" ng-show=\"esignFailure\">{{errorMessage}}</div>\n" +
            "\n" +
            "        <div class=\"vert-box left-box\">\n" +
            "          <div ng-click=\"picker()\" class=\"vert-box bkgrnd clickable thumbnail\">\n" +
            "            <object data=\"images/countersignature_thumb.png\"\n" +
            "                    type=\"image/png\" class=\"doc-image\">\n" +
            "              <img src=\"images/sub_thumb.png\">\n" +
            "            </object>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "        <div class=\"right-box\">\n" +
            "          <div class=\"title-and-status\">\n" +
            "            <h5 class=\"aml-title\">\n" +
            "              {{document.title | truncateByWord: titleLimit}}\n" +
            "            </h5>\n" +
            "            <span>Generating Agreement</span>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "\n" +
            "      <div ng-if=\"pendingSignature()\" ondragover=\"angular.element(this).scope().fadeFileSelect()\" id=\"subdoc-file-{{idString}}\" class=\"file-upload upload-border\">\n" +
            "        <div class=\"file-input-mask\" id=\"subdoc-file-{{idString}}\"></div>\n" +
            "        <div class=\"upload-error esign-error\" ng-show=\"esignFailure\">{{errorMessage}}</div>\n" +
            "\n" +
            "        <div class=\"vert-box left-box\">\n" +
            "          <div ng-click=\"picker()\" class=\"vert-box bkgrnd clickable thumbnail\">\n" +
            "            <object data=\"images/pending_esign_thumb.svg\" ng-if=\"!isCounterSignatureDocument()\"\n" +
            "                    type=\"image/png\" class=\"doc-image\">\n" +
            "              <img src=\"images/sub_thumb.png\">\n" +
            "            </object>\n" +
            "\n" +
            "            <object data=\"images/pending_esign_blank_thumb.svg\" ng-if=\"isCounterSignatureDocument()\"\n" +
            "                    type=\"image/png\" class=\"doc-image\">\n" +
            "              <img src=\"images/sub_thumb.png\">\n" +
            "            </object>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "        <div class=\"right-box\">\n" +
            "          <div class=\"title-and-status\">\n" +
            "            <h5 class=\"aml-title\"\n" +
            "                ng-class=\"isTaxDocument() ? ['w8-w9-line-break'] : []\">\n" +
            "                {{document.title | truncateByWord: titleLimit}}\n" +
            "            </h5>\n" +
            "            <span ng-if=\"isSubscriptionDocument() || isTaxDocument()\">Sent</span>\n" +
            "          </div>\n" +
            "          <ul class=\"action-links\">\n" +
            "            <li ng-if=\"isCounterSignatureDocument()\">\n" +
            "              <a href=\"#\" ng-click=\"showManualCountersignDialog()\">Download template</a>\n" +
            "            </li>\n" +
            "            <li ng-if=\"isCounterSignatureDocument()\">\n" +
            "              <a ng-click=\"picker()\">Upload new file</a>\n" +
            "            </li>\n" +
            "            <li ng-if=\"resendable()\">\n" +
            "              <a class=\"resend-esign\" ng-if=\"isTaxDocument()\" ng-click=\"reSendForESignature()\">Re-send for electronic signature</a>\n" +
            "              <a class=\"resend-esign\" ng-if=\"isCounterSignatureDocument()\" ng-click=\"showCountersignDialog()\">\n" +
            "                Resend Countersig Doc\n" +
            "              </a>\n" +
            "            </li>\n" +
            "            <li ng-if=\"isTaxDocument()\">\n" +
            "              <a ng-click=\"picker()\">Upload new W-9</a>\n" +
            "            </li>\n" +
            "            <li class=\"normal-wrap\" ng-if=\"isSubscriptionDocument()\">\n" +
            "              <a class=\"send-updated-link\" href=\"\" ng-click=\"openSendSubscriptionDialog(true)\">Send Updated Subscription</a>\n" +
            "              <i class=\"fa fa-check subdoc-check\"></i>\n" +
            "            </li>\n" +
            "            <li ng-if=\"isSubscriptionDocument()\">\n" +
            "              <a class=\"upload-signed-link\" href=\"\" ng-click=\"clickUploadDoc()\">Upload Signed Subscription</a>\n" +
            "            </li>\n" +
            "          </ul>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "\n" +
            "      <div ng-switch-when=\"reverted\" ondragover=\"angular.element(this).scope().fadeFileSelect()\" id=\"subdoc-file-{{idString}}\" class=\"file-upload upload-border approved\">\n" +
            "        <div class=\"file-input-mask\" id=\"subdoc-file-{{idString}}\"></div>\n" +
            "        <div class=\"vert-box left-box\">\n" +
            "          <div ng-click=\"picker()\"class=\"vert-box bkgrnd clickable thumbnail\">\n" +
            "            <img ng-attr-src=\"{{document.thumb_vertical_url}}\"\n" +
            "                 type=\"image/png\" onerror=\"this.src='images/sub_thumb.png'\"/>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "        <div class=\"right-box\">\n" +
            "            <div class=\"title-and-status\">\n" +
            "                <h5 class=\"aml-title\"\n" +
            "                    ng-class=\"isTaxDocument() ? ['w8-w9-line-break'] : []\">\n" +
            "                    {{document.title | truncateByWord: titleLimit}}\n" +
            "                </h5>\n" +
            "                <span>Reverted</span>\n" +
            "            </div>\n" +
            "            <ul class=\"action-links clickable\">\n" +
            "              <li ng-if=\"!isSubscriptionDocument() && !isTransferDocument()\">\n" +
            "                <a ng-click=\"picker()\">Upload new file</a>\n" +
            "              </li>\n" +
            "              <li ng-if=\"isTransferDocument()\">\n" +
            "                <a ng-click=\"clickUploadTransferDoc()\">Upload Transfer Document</a>\n" +
            "              </li>\n" +
            "              <li ng-if=\"esignable() && canViewInvestorProfiles()\">\n" +
            "                <a class=\"resend-esign\" ng-click=\"reSendForESignature()\">Send for Electronic Signature</a>\n" +
            "              </li>\n" +
            "              <li ng-if=\"showKeepFileLink()\">\n" +
            "                <a class=\"keep-file-link\" href=\"\" ng-click=\"keepRevertedDocument()\">Keep file</a>\n" +
            "              </li>\n" +
            "              <li ng-if=\"showDownloadLink()\">\n" +
            "                <a ng-click=\"viewDocument(document)\">View file</a>\n" +
            "              </li>\n" +
            "              <li ng-if=\"isSubscriptionDocument()\">\n" +
            "                <a class=\"send-updated-link\" href=\"\" ng-click=\"openSendSubscriptionDialog(true)\">Send Updated Subscription</a>\n" +
            "              </li>\n" +
            "              <li ng-if=\"isSubscriptionDocument()\">\n" +
            "                <a class=\"upload-signed-link\" href=\"\" ng-click=\"clickUploadDoc()\">Upload Signed Subscription</a>\n" +
            "              </li>\n" +
            "            </ul>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "\n" +
            "      <div class=\"check top-right-icon\" ng-switch-when=\"approved\">\n" +
            "        <span id=\"check-{{idString}}\" class=\"fa-stack\">\n" +
            "          <i class=\"fa fa-circle fa-stack-2x outer-green\" aria-hidden=\"true\"></i>\n" +
            "          <i class=\"fa fa-check fa-stack-1x inner-icon\" aria-hidden=\"true\"></i>\n" +
            "        </span>\n" +
            "      </div>\n" +
            "      <div ng-switch-when=\"approved\" ondragover=\"angular.element(this).scope().fadeFileSelect()\" id=\"subdoc-file-{{idString}}\" class=\"file-upload upload-border approved\">\n" +
            "        <div class=\"file-input-mask\" id=\"subdoc-file-{{idString}}\"></div>\n" +
            "        <div class=\"vert-box left-box\">\n" +
            "          <div ng-click=\"picker()\" class=\"vert-box bkgrnd clickable thumbnail\">\n" +
            "            <img ng-attr-src=\"{{document.thumb_vertical_url}}\"\n" +
            "                 type=\"image/png\" onerror=\"this.src='images/sub_thumb.png'\"/>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "        <div class=\"right-box\">\n" +
            "            <div class=\"title-and-status\">\n" +
            "                <h5 class=\"aml-title\"\n" +
            "                    ng-class=\"isTaxDocument() ? ['w8-w9-line-break'] : []\">\n" +
            "                    {{document.title | truncateByWord: titleLimit}}\n" +
            "                </h5>\n" +
            "                <span>Uploaded</span><span ng-if=\"showDestroyFile()\" class=\"delete-document\" ng-click=\"removeFile(document)\"><i class=\"fa fa-times\" aria-hidden=\"true\"></i></span>\n" +
            "            </div>\n" +
            "            <ul class=\"action-links\">\n" +
            "              <li>\n" +
            "                <a ng-click=\"picker()\" class=\"new-file\">Upload new file</a>\n" +
            "              </li>\n" +
            "              <li ng-if=\"esignable()\">\n" +
            "                <a class=\"resend-esign\" ng-click=\"reSendForESignature()\">Send for Electronic Signature</a>\n" +
            "              </li>\n" +
            "              <li ng-if=\"showDownloadLink()\">\n" +
            "                <a ng-click=\"viewDocument(document)\">View file</a>\n" +
            "              </li>\n" +
            "            </ul>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "\n" +
            "      <div ng-switch-when=\"rejected\" id=\"subdoc-file-{{idString}}\" class=\"file-upload upload-border clickable\">\n" +
            "        <div class=\"file-input-mask\" id=\"subdoc-file-{{idString}}\"></div>\n" +
            "        <div class=\"upload-error\">{{errorMessage}}</div>\n" +
            "        <div class=\"sm-mrg\">Drop file here or <a class=\"clickable top\" ng-click=\"picker()\">browse files</a></div>\n" +
            "      </div>\n" +
            "\n" +
            "      <div class=\"check top-right-icon\" ng-if=\"documentSigned()\">\n" +
            "        <span id=\"check-{{idString}}\" class=\"fa-stack\">\n" +
            "          <i class=\"fa fa-circle fa-stack-2x outer-green\" aria-hidden=\"true\"></i>\n" +
            "          <i class=\"fa fa-check fa-stack-1x inner-icon\" aria-hidden=\"true\"></i>\n" +
            "        </span>\n" +
            "      </div>\n" +
            "      <div ng-if=\"documentSigned()\" ondragover=\"angular.element(this).scope().fadeFileSelect()\" id=\"subdoc-file-{{idString}}\" class=\"file-upload upload-border esign-file approved\">\n" +
            "        <div class=\"file-input-mask\" id=\"subdoc-file-{{idString}}\"></div>\n" +
            "        <div class=\"vert-box left-box\">\n" +
            "          <div ng-click=\"picker()\" class=\"vert-box bkgrnd clickable thumbnail\">\n" +
            "            <object ng-if=\"isCounterSignatureDocument()\" data=\"images/countersignature_thumb.png\" type=\"image/png\" class=\"generic-w9-thumb\" >\n" +
            "              <img src=\"images/countersignature_thumb.png\">\n" +
            "            </object>\n" +
            "            <img ng-if=\"!isCounterSignatureDocument()\" ng-attr-src=\"{{document.thumb_vertical_url}}\"\n" +
            "                 type=\"image/png\" onerror=\"this.src='images/sub_thumb.png'\"/>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "        <div class=\"right-box\">\n" +
            "          <div class=\"title-and-status\">\n" +
            "            <h5 class=\"aml-title\"\n" +
            "                ng-class=\"isTaxDocument() ? ['w8-w9-line-break'] : []\">\n" +
            "                {{document.title | truncateByWord: titleLimit}}\n" +
            "            </h5>\n" +
            "            <span ng-if=\"!(isSubscriptionDocument() && uploadStatus === 'manual_signed')\">Signed</span>\n" +
            "            <span ng-if=\"isSubscriptionDocument() && uploadStatus === 'manual_signed'\">Uploaded</span>\n" +
            "            <span ng-if=\"showDestroyFile()\" class=\"delete-document\" ng-click=\"removeFile(document)\"><i class=\"fa fa-times\"\n" +
            "                aria-hidden=\"true\"></i></span>\n" +
            "          </div>\n" +
            "          <ul class=\"action-links\">\n" +
            "            <li ng-if=\"isCounterSignatureDocument() && close && !close.merged_countersignature\">\n" +
            "              <a href=\"#\" ng-click=\"showManualCountersignDialog()\">Download template</a>\n" +
            "            </li>\n" +
            "            <li ng-if=\"isCounterSignatureDocument() && close && !close.merged_countersignature\">\n" +
            "              <a ng-click=\"picker()\">Upload new file</a>\n" +
            "            </li>\n" +
            "            <li ng-if=\"resendable()\">\n" +
            "              <a class=\"resend-esign\" ng-if=\"isTaxDocument()\" ng-click=\"reSendForESignature()\">Re-send for electronic signature</a>\n" +
            "              <a class=\"resend-esign\" ng-if=\"isCounterSignatureDocument() && close && !close.merged_countersignature\" ng-click=\"showCountersignDialog()\">\n" +
            "                Resend Countersig Doc</a>\n" +
            "            </li>\n" +
            "            <li ng-if=\"showDownloadLink()\">\n" +
            "              <a ng-click=\"viewDocument(document)\">View file</a>\n" +
            "            </li>\n" +
            "            <li ng-if=\"isSubscriptionDocument() && uploadStatus === 'reverted' && investment.upload_status != 'Auto-Reverted Investment'\">\n" +
            "              <a>Keep File</a>\n" +
            "            </li>\n" +
            "            <li ng-if=\"isSubscriptionDocument()\">\n" +
            "              <a class=\"send-updated-link\" href=\"\" ng-click=\"openSendSubscriptionDialog(true)\">Send Updated Subscription</a>\n" +
            "            </li>\n" +
            "            <li class=\"normal-wrap\" ng-if=\"isSubscriptionDocument()\">\n" +
            "              <a class=\"upload-signed-link\" href=\"\" ng-click=\"clickUploadDoc()\">Upload Signed Subscription</a>\n" +
            "              <i class=\"fa fa-check subdoc-check\"></i>\n" +
            "            </li>\n" +
            "          </ul>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "\n" +
            "      <!-- This input is shared across all the uploaders above -->\n" +
            "      <input type=\"file\" class=\"file-input\" id=\"{{idString}}\" name='document-file-{{idString}}'\n" +
            "             accept=\"{{ isCounterSignatureDocument() ? 'application/pdf' : '*'}}\"\n" +
            "             onchange=\"angular.element(this).scope().uploadDoc(this.files)\"/>\n" +
            "\n" +
            "      <div class=\"on-shore-tooltip-container top-right-icon clickable\"\n" +
            "        ng-if=\"deletable() || showCustomRemoveButton()\">\n" +
            "        <span\n" +
            "          tooltip-class=\"on-shore-tooltip\"\n" +
            "          tooltip-html=\"showCustomRemoveButton() ? toolTips.fetch().deleteDocument : toolTips.fetch().deleteDocumentRequirement\"\n" +
            "          tooltip-placement=\"bottom\"\n" +
            "          tooltip-append-to-body=\"false\"\n" +
            "          tooltip-trigger=\"mouseenter\">\n" +
            "          <span ng-click=\"removeRequirementFile(document)\"\n" +
            "            class=\"fa-stack ex\" style=\"margin-bottom: 5px;\"\n" +
            "            id=\"document-required-remove-button\">\n" +
            "            <i class=\"fa fa-circle fa-stack-2x\" aria-hidden=\"true\"></i>\n" +
            "            <i class=\"fa fa-times fa-stack-1x inner-icon\" aria-hidden=\"true\"></i>\n" +
            "          </span>\n" +
            "        </span>\n" +
            "      </div>\n" +
            "        <div class=\"file-type\">\n" +
            "          <div ng-if=\"acceptedFileExtensions()\" class=\"content-xsmall-lite\">\n" +
            "            Allowed files:\n" +
            "              <span ng-repeat=\"allowed_file_types in document.required_document_type.supported_file_extensions\" >{{allowed_file_types}} </span>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("components/investor_relations/dialogs/ria_user_email_dialog/ria_user_email_dialog.html", "<div class=\"modal-header-combined\">\n" +
            "  <div class=\"modal-header-combined__header heading-2\">\n" +
            "    {{isEditing ? \"Edit\" : \"Add\"}} RIA User Email\n" +
            "  </div>\n" +
            "  <div class=\"modal-header-combined__content ria-user-email-dialog\">\n" +
            "    <div class=\"error\" ng-if=\"error\">\n" +
            "      {{error}}\n" +
            "    </div>\n" +
            "    <form class=\"form-horizontal modern-form ria-user-modal-form\" name=\"riaUserEmailForm\">\n" +
            "      <icn-input type=\"email\" model=\"riaEmail\" required></icn-input>\n" +
            "    </form>\n" +
            "  </div>\n" +
            "  <div class=\"row modal-header-combined__buttons\">\n" +
            "      <button class=\"right-aligned-btn\" ng-click=\"confirm()\" ng-disabled=\"!riaEmail || riaEmail.length == 0\">\n" +
            "        {{isEditing ? \"Save\" : \"Add\"}}\n" +
            "      </button>\n" +
            "      <button class=\"cancel-btn right-aligned-btn\" ng-click=\"cancelDialog()\">Cancel</button>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("components/private_access_fund_series/private_access_fund_series.html", "<div ng-if=\"showSeries\" class=\"series-selection\">\n" +
            "    <div class=\"heading-five\">\n" +
            "        <span class=\"with-tooltip\">Allocation</span>\n" +
            "        <span tooltip-class=\"allocation-tooltip\"\n" +
            "              tooltip-html=\"toolTips.fetch().pafSeries\"\n" +
            "              tooltip-placement=\"top\"\n" +
            "              tooltip-popup-delay=\"500\">\n" +
            "          <img class=\"on-shore-help-logo--small\" src=\"/images/icon_tooltip.svg\">\n" +
            "        </span>\n" +
            "    </div>\n" +
            "    <table class=\"table-icn set-commitment-table\">\n" +
            "        <thead>\n" +
            "        <tr>\n" +
            "            <th class=\"first-col\">Series</th>\n" +
            "            <th class=\"allocation-cell\" ng-repeat=\"allocation in privateAccessFund.series[0].allocations\">{{allocation.manager}}</th>\n" +
            "        </tr>\n" +
            "        </thead>\n" +
            "        <tr ng-repeat=\"series in activeSeries\">\n" +
            "            <td class=\"first-col\" ng-class=\"seriesRowClass(series)\">\n" +
            "                <div class=\"col-md-1 no-pad-left no-pad-right\">\n" +
            "                    <input type=\"radio\" name=\"selected-series\" ng-model=\"data.selectedSeries\" value=\"{{series.id}}\">\n" +
            "                </div>\n" +
            "                <div class=\"col-md-11 no-pad-left no-pad-right\">\n" +
            "                    {{series.name}}\n" +
            "                </div>\n" +
            "            </td>\n" +
            "            <td class=\"allocation-cell\" ng-class=\"seriesRowClass(series)\" ng-repeat=\"allocation in series.allocations\">{{formatAllocation(allocation)}}</td>\n" +
            "        </tr>\n" +
            "        <tr class=\"content-default\">\n" +
            "            <td class=\"first-col\" ng-show=\"data.onPage == 'set-commitment-dialog'\">Allocated Commitment</td>\n" +
            "            <td class=\"first-col\" ng-hide=\"data.onPage == 'set-commitment-dialog'\">Allocated Investment</td>\n" +
            "            <td class=\"allocation-cell\" ng-repeat=\"allocation in privateAccessFund.series[0].allocations\">{{data.amounts[$index] | currency:\"$\":0}}</td>\n" +
            "        </tr>\n" +
            "    </table>\n" +
            "</div>");

        $templateCache.put("components/profile_fund_list/profile_fund_list.html", "<div class='investmentList'>\n" +
            "  <div>\n" +
            "    <div ng-include=\"'components/profile_fund_list/templates/profiles_funds.html'\"></div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("components/profile_fund_list/templates/profiles_funds.html", "<div slidable items=\"approvedInvestments\" class=\"investment-list-table-container\">\n" +
            "  <div slidable-header\n" +
            "       title=\"APPROVED\"\n" +
            "       class=\"investment-list-status-header accepted\">\n" +
            "  </div>\n" +
            "  <div slidable-body>\n" +
            "    <table ng-controller=\"InvestmentsTableCtrl\"\n" +
            "           class=\"table table-hover investment-table under-review-table\"\n" +
            "           name=\"private-equity-list\"\n" +
            "           ng-show=\"approvedInvestments.length\">\n" +
            "      <thead>\n" +
            "        <tr class=\"investment-table-header-row\">\n" +
            "          <th class=\"col-md-1 investment-table-text\">\n" +
            "            CONTACT CARD ID\n" +
            "          </th>\n" +
            "          <th class=\"col-md-1 investment-table-text\">\n" +
            "            FIRST NAME\n" +
            "          </th>\n" +
            "          <th class=\"icol-md-1 investment-table-text\">\n" +
            "            LAST NAME\n" +
            "          </th>\n" +
            "          <th class=\"col-md-1 investment-table-text\">\n" +
            "            EMAIL\n" +
            "          </th>\n" +
            "          <th  class=\"col-md-2 investment-table-text\">\n" +
            "            PROFILE\n" +
            "          </th>\n" +
            "          <th class=\"col-md-4 investment-table-text\">\n" +
            "            FUND\n" +
            "          </th>\n" +
            "          <th class=\"col-md-1 investment-table-text\">\n" +
            "            DETAIL\n" +
            "          </th>\n" +
            "        </tr>\n" +
            "      </thead>\n" +
            "      <tbody>\n" +
            "        <tr class=\"investment-table-body-row\" ng-repeat=\"investment in approvedInvestments\">\n" +
            "          <!-- investor -->\n" +
            "          <td class=\"investment-table-text\">\n" +
            "            <span>{{ contact.id }}</span>\n" +
            "          </td>\n" +
            "          <td class=\"investment-table-text\">\n" +
            "            <span>{{ contact.first_name }}</span>\n" +
            "          </td>\n" +
            "          <td class=\"investment-table-text\">\n" +
            "            <span>{{ contact.last_name }}</span>\n" +
            "          </td>\n" +
            "          <td class=\"investment-table-text\">\n" +
            "            <span>{{ contact.email }}</span>\n" +
            "          </td>\n" +
            "          <td class=\"investment-table-text\">\n" +
            "            <span>{{ investment.investor_profile.name }}</span>\n" +
            "          </td>\n" +
            "          <td class=\"investment-table-text\">\n" +
            "            <span>{{ investment.fund_name }}</span>\n" +
            "          </td>\n" +
            "          <td class=\"investment-table-text detail-link\">\n" +
            "            <a href=\"#\"\n" +
            "              ng-href=\"/investor_relations/investor_search/detail/{{contact.id}}/{{investment.private_access_fund_id}}\">\n" +
            "              <i class=\"fa fa-angle-right fa-lg\" aria-hidden=\"true\"></i>\n" +
            "            </a>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "      </tbody>\n" +
            "    </table>\n" +
            "  </div>\n" +
            "</div>\n" +
            "\n" +
            "<div slidable items=\"pendingInvestments\" class=\"investment-list-table-container\">\n" +
            "  <div slidable-header\n" +
            "       title=\"PENDING\"\n" +
            "       class=\"investment-list-status-header pending\">\n" +
            "  </div>\n" +
            "  <div slidable-body>\n" +
            "    <table ng-controller=\"InvestmentsTableCtrl\"\n" +
            "           class=\"table table-hover investment-table accepted-table\"\n" +
            "           name=\"private-equity-list\"\n" +
            "           ng-show=\"pendingInvestments.length\">\n" +
            "      <thead>\n" +
            "        <tr class=\"investment-table-header-row\">\n" +
            "          <th class=\"col-md-1 investment-table-text\">\n" +
            "            CONTACT CARD ID\n" +
            "          </th>\n" +
            "          <th class=\"col-md-1 investment-table-text\">\n" +
            "            FIRST NAME\n" +
            "          </th>\n" +
            "          <th class=\"icol-md-1 investment-table-text\">\n" +
            "            LAST NAME\n" +
            "          </th>\n" +
            "          <th class=\"col-md-1 investment-table-text\">\n" +
            "            EMAIL\n" +
            "          </th>\n" +
            "          <th  class=\"col-md-2 investment-table-text\">\n" +
            "            PROFILE\n" +
            "          </th>\n" +
            "          <th class=\"col-md-4 investment-table-text\">\n" +
            "            FUND\n" +
            "          </th>\n" +
            "          <th class=\"col-md-1 investment-table-text\">\n" +
            "            DETAIL\n" +
            "          </th>\n" +
            "        </tr>\n" +
            "      </thead>\n" +
            "      <tbody>\n" +
            "       <tr class=\"investment-table-body-row\" ng-repeat=\"investment in pendingInvestments\">\n" +
            "         <!-- investor -->\n" +
            "         <td class=\"investment-table-text\">\n" +
            "           <span>{{ contact.id }}</span>\n" +
            "         </td>\n" +
            "         <td class=\"investment-table-text\">\n" +
            "           <span>{{ contact.first_name }}</span>\n" +
            "         </td>\n" +
            "         <td class=\"investment-table-text\">\n" +
            "           <span>{{ contact.last_name }}</span>\n" +
            "         </td>\n" +
            "         <td class=\"investment-table-text\">\n" +
            "           <span>{{ contact.email }}</span>\n" +
            "         </td>\n" +
            "         <td class=\"investment-table-text\">\n" +
            "           <span>{{ investment.investor_profile.name }}</span>\n" +
            "         </td>\n" +
            "         <td class=\"investment-table-text\">\n" +
            "           <span>{{ investment.fund_name }}</span>\n" +
            "         </td>\n" +
            "         <td class=\"investment-table-text detail-link\">\n" +
            "           <a href=\"#\"\n" +
            "            ng-href=\"/investor_relations/investor_search/detail/{{contact.id}}/{{investment.private_access_fund_id}}\">\n" +
            "             <i class=\"fa fa-angle-right fa-lg\" aria-hidden=\"true\"></i>\n" +
            "           </a>\n" +
            "         </td>\n" +
            "       </tr>\n" +
            "      </tbody>\n" +
            "    </table>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("components/redemption_list/dialogs/send_redemption_dialog.html", "<common-modal modal-header=\"Send Redemption Signature\" id=\"send-signature-dialog\">\n" +
            "  <div class=\"subdoc-box\" ng-show=\"preparerBeingUsed\">\n" +
            "    <span class=\"warning-message\"><b>Additional information is required for this agreement.</b></span>\n" +
            "    <span>You will be directed to complete the agreement when clicking the \"Send\" button below.</span>\n" +
            "  </div>\n" +
            "\n" +
            "  <h6 ng-show=\"!manualSignatureOnly()\">\n" +
            "    <span>The Redemption Request will be sent securely through DocuSign to the following email address(es) for electronic signature.</span>\n" +
            "    <span ng-show=\"errors && !validForm()\" class=\"error\">{{errors}}</span>\n" +
            "  </h6>\n" +
            "\n" +
            "  <div class=\"inv-box\" ng-repeat=\"signerConfiguration in signerConfigurations\">\n" +
            "    <signer-configuration-box class=\"signer-configuration-box\" signer-configuration=\"signerConfiguration\"></signer-configuration-box>\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"inv-box\">\n" +
            "    <div class=\"inv-sec\"><strong>Preferred signing method:</strong></div>\n" +
            "  </div>\n" +
            "\n" +
            "  <form name=\"investorForm\" id=\"investor-form\" class=\"investor-form\" novalidate>\n" +
            "    <div class=\"inv-box\">\n" +
            "      <div class=\"radio pull-left\" ng-hide=\"manualSignatureOnly()\">\n" +
            "        <input type=\"radio\" name=\"sign_method\" id=\"electronic\" ng-value=\"'electronic'\" ng-model=\"redemption.sign_method\">\n" +
            "        <label for=\"electronic\"><b>Electronic</b></label>\n" +
            "      </div>\n" +
            "\n" +
            "      <div class=\"radio pull-left radio-left\" ng-show=\"allowManualSignature()\">\n" +
            "        <input type=\"radio\" name=\"sign_method\" id=\"manual\" ng-value=\"'manual'\" ng-model=\"redemption.sign_method\">\n" +
            "        <label for=\"manual\"><b>Manual</b></label>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "\n" +
            "    <div class=\"clearfix\"></div>\n" +
            "\n" +
            "    <hr class=\"row\"/>\n" +
            "\n" +
            "    <div class=\"row ip-investment-buttons\">\n" +
            "      <button class=\"subscription-btn round-btn\" type=\"submit\" ng-disabled=\"submitDisabled()\" ng-click=\"sendRedemption()\">SEND</button>\n" +
            "      <button class=\"cancel-btn round-btn\" ng-click=\"cancelDialog()\">CANCEL</button>\n" +
            "    </div>\n" +
            "  </form>\n" +
            "</common-modal>");

        $templateCache.put("components/redemption_list/redemption_list.html", "<div slidable\n" +
            "     closed=\"closed\"\n" +
            "     toggle=\"toggle()\"\n" +
            "     items-count=\"redemptionsSize()\"\n" +
            "     items=\"activeRedemptions\"\n" +
            "     class=\"investment-list-table-container redemptions-list\"\n" +
            "     ng-show=\"redemptionsSize() > 0\">\n" +
            "  <div slidable-header\n" +
            "       title=\"Redemptions\"\n" +
            "       class=\"investment-list-status-header redemptions\">\n" +
            "  </div>\n" +
            "  <div slidable-body id=\"redemptionsTable\">\n" +
            "    <table class=\"table table-hover investment-table\">\n" +
            "      <thead>\n" +
            "        <tr class=\"investment-table-header-row\">\n" +
            "          <th class=\"redemptions-toggle-icon\"></th>\n" +
            "          <th cli-header order=\"investment_id\" class=\"investment-table-text redemptions-list-investment-id-col\" ng-show=\"isPafAdmin\">\n" +
            "            ID\n" +
            "          </th>\n" +
            "          <th cli-header order=\"created_at\" class=\"investment-table-text redemptions-list-request-date-col\">\n" +
            "            Request Date\n" +
            "          </th>\n" +
            "          <th cli-header order=\"raw_value_date\" class=\"investment-table-text redemptions-list-value-date-col\">\n" +
            "            Effective Date\n" +
            "          </th>\n" +
            "          <th cli-header order=\"investor_name\" class=\"investment-table-text redemptions-list-investor-col\">\n" +
            "            Investor\n" +
            "          </th>\n" +
            "          <th cli-header order=\"supplemental_form\" class=\"investment-table-text redemptions-list-supplemental-form-col\">\n" +
            "            Supplemental Form\n" +
            "          </th>\n" +
            "          <th cli-header order=\"full\" class=\"investment-table-text redemptions-list-full-partial-col\">\n" +
            "            Full/Partial\n" +
            "          </th>\n" +
            "          <th cli-header order=\"nav\" class=\"investment-table-number redemptions-list-nav-col\">\n" +
            "            {{reportableAmountType}} ({{currency}})\n" +
            "          </th>\n" +
            "          <th cli-header order=\"total_distribution\" class=\"investment-table-number redemptions-list-amount-payable-col\">\n" +
            "            Amount Payable ({{currency}})\n" +
            "          </th>\n" +
            "          <th class=\"redemptions-asterisk-col\"></th>\n" +
            "          <th\n" +
            "            cli-header\n" +
            "            order=\"total_distribution_percentage\"\n" +
            "            class=\"investment-table-number redemptions-list-amount-payable-percent-col\"\n" +
            "          >\n" +
            "            Amount Payable (%)\n" +
            "          </thcli-header>\n" +
            "          <th cli-header order=\"raw_wire_date\" class=\"investment-table-text redemptions-list-wire-date-col\">\n" +
            "            Wire Date\n" +
            "          </th>\n" +
            "          <th cli-header order=\"hold_back_amount\" class=\"investment-table-number redemptions-list-hold-back-col\">\n" +
            "            Hold Back ({{currency}})\n" +
            "          </th>\n" +
            "          <th class=\"redemptions-asterisk-col\"></th>\n" +
            "          <th cli-header order=\"hold_back\" class=\"investment-table-number redemptions-list-hold-back-percent-col\">\n" +
            "            Hold Back (%)\n" +
            "          </th>\n" +
            "          <th cli-header order=\"raw_hold_back_wire_date\" class=\"investment-table-text redemptions-list-hb-wire-date-col\">\n" +
            "            Hold Back Wire Date\n" +
            "          </th>\n" +
            "          <th cli-header order=\"units\" class=\"investment-table-number redemptions-list-units-col\" ng-show=\"isUnitizedFund\">\n" +
            "            Units\n" +
            "          </th>\n" +
            "          <th cli-header order=\"status\" class=\"investment-table-text redemptions-list-status-col\">\n" +
            "            Status\n" +
            "          </th>\n" +
            "          <th class=\"no-sort redemptions-list-actions-col\">\n" +
            "            Actions\n" +
            "          </th>\n" +
            "        </tr>\n" +
            "      </thead>\n" +
            "      <tbody redemption-sub-list private-access-fund=\"privateAccessFund\"\n" +
            "                                 count=\"activeRedemptionsSize\"\n" +
            "                                 redemptions=\"activeRedemptions\"\n" +
            "                                 header=\"Redemptions\"\n" +
            "                                 open=\"false\"\n" +
            "                                 order=\"orderByField\"\n" +
            "                                 reverse=\"reverseSort\"\n" +
            "                                 fetch-redemptions=\"fetchActiveRedemptions()\">\n" +
            "      </tbody>\n" +
            "      <tbody redemption-sub-list private-access-fund=\"privateAccessFund\"\n" +
            "                                 count=\"holdbackRedemptionsSize\"\n" +
            "                                 redemptions=\"holdbackRedemptions\"\n" +
            "                                 header=\"Hold Back\"\n" +
            "                                 open=\"false\"\n" +
            "                                 order=\"orderByField\"\n" +
            "                                 reverse=\"reverseSort\"\n" +
            "                                 fetch-redemptions=\"fetchHoldbackRedemptions()\">\n" +
            "      </tbody>\n" +
            "      <tbody redemption-sub-list private-access-fund=\"privateAccessFund\"\n" +
            "                                 count=\"completedRedemptionsSize\"\n" +
            "                                 redemptions=\"completedRedemptions\"\n" +
            "                                 header=\"Completed\"\n" +
            "                                 open=\"false\"\n" +
            "                                 order=\"orderByField\"\n" +
            "                                 reverse=\"reverseSort\"\n" +
            "                                 fetch-redemptions=\"fetchCompletedRedemptions()\">\n" +
            "      </tbody>\n" +
            "    </table>\n" +
            "    <p class=\"estimated-row\">*Estimated</p>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("components/redemption_list/redemption_sub_list/redemption_sub_list.html", "<tbody class=\"redemptions-tbody\">\n" +
            "  <tr class=\"investment-table-body-row redemptions-table-body-group\"\n" +
            "      ng-click=\"count == 0 || expandRedemptions()\"\n" +
            "      ng-disabled=\"count == 0\">\n" +
            "    <!-- Group Header -->\n" +
            "    <td class=\"redemptions-toggle-icon\">\n" +
            "        <img ng-src=\"{{sectionChevronIcon(subList)}}\">\n" +
            "    </td>\n" +
            "    <td class=\"investment-table-text redemptions-row-header-2\" colspan=\"{{ isPafAdmin ? 5 : 4 }}\">\n" +
            "      <span>{{header}} ({{redemptions.length || count}})</span>\n" +
            "    </td>\n" +
            "    <td class=\"redemptions-row-header-6\">\n" +
            "      <span class=\"investment-list-table-total\">{{totalValueDateNav | number:0}}</span>\n" +
            "    </td>\n" +
            "    <td class=\"redemptions-row-header-7\">\n" +
            "      <span class=\"investment-list-table-total\">{{totalAmountPayable | number:0}}</span>\n" +
            "    </td>\n" +
            "    <td colspan=\"3\"></td>\n" +
            "    <td class=\"redemptions-row-header-9 redemptions-list-hold-back-col\">\n" +
            "      <span class=\"investment-list-table-total\">{{totalHoldBack | number:0}}</span>\n" +
            "    </td>\n" +
            "    <td colspan=\"6\"></td>\n" +
            "  </tr>\n" +
            "  <tr class=\"redemptions-table-body-row\" ng-show=\"opened\"\n" +
            "  ng-repeat=\"redemption in redemptions | orderBy:order:reverse\">\n" +
            "    <td class=\"redemptions-toggle-icon\"></td>\n" +
            "    <!-- id -->\n" +
            "    <td class=\"investment-table-text redemptions-list-investment-id-col\" ng-show=\"isPafAdmin\">\n" +
            "      {{redemption.investment_id}}\n" +
            "    </td>\n" +
            "    <!-- redeem date -->\n" +
            "    <td class=\"investment-table-text redemptions-list-request-date-col\">\n" +
            "      {{convert_to_date(redemption.redeem_date) | date: \"dd-MMM-yyyy\"}}\n" +
            "    </td>\n" +
            "    <!-- value date -->\n" +
            "    <td class=\"investment-table-text redemptions-list-value-date-col\">\n" +
            "      {{convert_to_date(redemption.value_date) | date: \"dd-MMM-yyyy\"}}\n" +
            "    </td>\n" +
            "    <!-- investor -->\n" +
            "    <td class=\"investment-table-text redemptions-list-investor-col\">\n" +
            "      {{redemption.investor_name}}\n" +
            "    </td>\n" +
            "    <!-- redemption supplemental questions -->\n" +
            "    <td class=\"investment-table-text redemptions-list-supplemental-form-col\" >\n" +
            "      <a ng-href=\"#\"\n" +
            "          ng-class=\"{ 'incomplete-status': !redemption.supplemental_form_completed }\"\n" +
            "          ng-click=\"openSupplementalForm(redemption, $event)\"\n" +
            "          ng-show=\"redemption.has_supplemental_form\">\n" +
            "        {{ redemptionSupplementalResponseStatusString(redemption) }}\n" +
            "      </a>\n" +
            "    </td>\n" +
            "    <!-- full/partial -->\n" +
            "    <td class=\"investment-table-text redemptions-list-full-partial-col\">\n" +
            "      {{redemption.full ? 'Full' : 'Partial'}}\n" +
            "    </td>\n" +
            "    <!-- nav ($) -->\n" +
            "    <td class=\"investment-table-number redemptions-list-nav-col\">\n" +
            "      {{(calculate_nav_amount(redemption) | number: 0) || \"NA\"}}\n" +
            "    </td>\n" +
            "    <!-- amount payable amount -->\n" +
            "    <td class=\"investment-table-number redemptions-list-amount-payable-col\">\n" +
            "      {{(calculate_amount_payable(redemption) | number: 0) || \"NA\"}}\n" +
            "    </td>\n" +
            "    <!-- asterisk -->\n" +
            "    <td\n" +
            "      class=\"redemptions-asterisk-col redemptions-list-amount-payable-asterisk-col\"\n" +
            "    >\n" +
            "      <strong ng-if=\"showAsterisk(redemption, 'amount_payable')\">*</strong>\n" +
            "    </td>\n" +
            "    <!-- amount payable percentage -->\n" +
            "    <td class=\"investment-table-number redemptions-list-amount-payable-percent-col\" ng-if=\"enableCustomRedemptions\">\n" +
            "      {{calculate_amount_payable_percentage(redemption)}}\n" +
            "    </td>\n" +
            "    <!-- wire date -->\n" +
            "    <td class=\"investment-table-text redemptions-list-wire-date-col\">\n" +
            "      {{convert_to_date(redemption.wire_date) | date: \"dd-MMM-yyyy\"}}\n" +
            "    </td>\n" +
            "    <!-- hold back amount -->\n" +
            "    <td class=\"investment-table-number redemptions-list-hold-back-col\">\n" +
            "      {{(calculate_hold_back_amount(redemption) | number: 0) || \"NA\"}}\n" +
            "    </td>\n" +
            "    <!-- asterisk -->\n" +
            "    <td\n" +
            "      class=\"redemptions-asterisk-col redemptions-list-hold-back-asterisk-col\"\n" +
            "    >\n" +
            "      <strong ng-if=\"showAsterisk(redemption, 'hold_back_amount')\">*</strong>\n" +
            "    </td>\n" +
            "    <!-- hold back percentage -->\n" +
            "    <td class=\"investment-table-number redemptions-list-hold-back-percent-col\">\n" +
            "      {{calculate_hold_back_percentage(redemption)}}\n" +
            "    </td>\n" +
            "    <!-- hold back wire date -->\n" +
            "    <td class=\"investment-table-text redemptions-list-hb-wire-date-col\">\n" +
            "      {{formatHoldBackWireDate(redemption) | date: \"dd-MMM-yyyy\"}}\n" +
            "    </td>\n" +
            "    <!-- units -->\n" +
            "    <td class=\"investment-table-number redemptions-list-units-col\" ng-if=\"isUnitizedFund\">\n" +
            "      {{calculate_units(redemption) | number: 0}}\n" +
            "    </td>\n" +
            "    <!-- status -->\n" +
            "    <td class=\"investment-table-text agreement-status-text redemptions-list-status-col\">\n" +
            "      <a href=\"#\"\n" +
            "          ng-if=\"requiresSignature(redemption)\"\n" +
            "          ng-click=\"handleSignature(redemption)\">\n" +
            "          {{ agreementText(redemption) | titleCase }}\n" +
            "      </a>\n" +
            "      <div ng-if=\"!requiresSignature(redemption)\">{{ agreementText(redemption) | titleCase }}</div>\n" +
            "    </td>\n" +
            "    <!-- actions -->\n" +
            "    <td class=\"no-sort icn-dropdown-actions redemptions-list-actions-col\">\n" +
            "      <div class=\"btn-group btn-group-sm\" ng-class=\"{'open': redemption.menuOpen}\">\n" +
            "        <a data-toggle=\"dropdown\" class=\"dropdown-toggle\" ng-click=\"toggleMenu()\">\n" +
            "          <img src=\"images/options_dark.png\">\n" +
            "        </a>\n" +
            "        <div class=\"dropdown-menu text-left\">\n" +
            "          <ul>\n" +
            "            <li ng-if=\"canDownloadAgreement(redemption)\">\n" +
            "              <a href=\"#\" ng-click=\"downloadAgreement(redemption)\">Download Agreement</a>\n" +
            "            </li>\n" +
            "            <li ng-if=\"showSendRedemptionReminder(redemption)\">\n" +
            "              <a href=\"#\" ng-click=\"sendRedemptionReminder(redemption)\" class=\"reminder-email\">Send Reminder Email</a>\n" +
            "            </li>\n" +
            "            <li ng-if=\"canUploadDocument(redemption)\">\n" +
            "              <a href=\"#\" ng-click=\"uploadRedemptionDoc(redemption)\" class=\"signed-upload-sub-link\">Upload Signed Document</a>\n" +
            "            </li>\n" +
            "            <li ng-if=\"canCancelRedemption(redemption)\">\n" +
            "              <a href=\"#\" ng-click=\"cancelRedemption(redemption)\" class=\"cancel-request-link\">Cancel Request</a>\n" +
            "            </li>\n" +
            "            <li ng-if=\"canViewEventsHistory(redemption)\" >\n" +
            "              <a href=\"#\" ng-click=\"viewEvents(redemption)\">View Events History</a>\n" +
            "            </li>\n" +
            "          </ul>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "    </td>\n" +
            "  </tr>\n" +
            "</tbody>");

        $templateCache.put("components/scroll_up/scroll_up.html", "<div id=\"scroller\" ng-controller=\"ScrollUpCtrl\" ng-click=\"scrollUp()\">\n" +
            "    <img src=\"images/back_to_top.svg\" width=\"30px\" height=\"30px\"/>\n" +
            "    <br>\n" +
            "    <small><strong>BACK TO TOP</strong></small>\n" +
            "</div>");

        $templateCache.put("components/slidable/templates/slidable_body.html", "<div class=\"slidable\">\n" +
            "  <ng-transclude></ng-transclude>\n" +
            "</div>");

        $templateCache.put("components/slidable/templates/slidable_header.html", "<div class=\"slide-toggle\"\n" +
            "     ng-class=\"{ 'disabled': countItems() <= 0 }\">\n" +
            "  <div class=\"investment-list-status-container\" ng-click=\"toggleClosed()\">\n" +
            "    <div class=\"investment-list-header-text\">\n" +
            "      <img class=\"slide-toggle-icon\"\n" +
            "           ng-src=\"{{ slidable.closed && '/images/btn-show.svg' || '/images/btn-hide.svg' }}\" />\n" +
            "      <span ng-if=\"slidable.fromSubReview\">\n" +
            "        {{ title }} ({{ slidable.itemsCount | number:0 }})\n" +
            "        <span ng-if=\"slidable.totalAmount\">- ${{ slidable.totalAmount | number:0 }}</span>\n" +
            "      </span>\n" +
            "      <span ng-if=\"!slidable.fromSubReview\">\n" +
            "          <span ng-if=\"slidable.itemsCount\">{{ title }} ({{ slidable.itemsCount | number:0 }})</span>\n" +
            "          <span ng-if=\"!slidable.itemsCount\">{{ title }} ({{ slidable.items.length }})</span>\n" +
            "    </div>\n" +
            "    <div ng-class=\"{'inline-circle': loading}\"></div>\n" +
            "  </div>\n" +
            "  <ng-transclude></ng-transclude>\n" +
            "</div>");

        $templateCache.put("dialogs/accept_terms_dialog/accept_terms_dialog.html", "<common-modal modal-header=\"{{header}}\">\n" +
            "  <h4 class=\"accept-terms-title\" ng-bind-html=\"termsTitle\"></h4>\n" +
            "  <p ng-if=\"userSignInCount != 1\" class=\"accept-terms-existing-user-notice\">\n" +
            "    We have updated our Terms and Conditions. Please read and agree in order to proceed.\n" +
            "  </p>\n" +
            "  <section class=\"accept-terms-modal-content accepted-terms-margin-top-10\">\n" +
            "    <div class=\"container terms-container\">\n" +
            "      <span ng-bind-html=\"termsContent\"></span>\n" +
            "    </div>\n" +
            "  </section>\n" +
            "  <section class=\"accept-terms-checkbox-section\">\n" +
            "    <label for=\"accept-terms-checkbox\">\n" +
            "      <input id=\"accept-terms-checkbox\" type=\"checkbox\" ng-model=\"termsAccepted\" ng-if=\"wlp_id_term == 0\" ng-click=\"flipValue()\">\n" +
            "      <input id=\"accept-terms-checkbox\" type=\"checkbox\" ng-model=\"wlpTermsAccepted\" ng-if=\"wlp_id_term != 0\" ng-click=\"flipValue()\">\n" +
            "      <div class=\"accept-terms-fake-checkbox\" for=\"accept-terms-checkbox\"></div>\n" +
            "      <span ng-bind-html=\"termsAckText\"></span>\n" +
            "    </label>\n" +
            "  </section>\n" +
            "  <section class=\"accept-terms-error-message-section\">\n" +
            "    <p class=\"accept-terms-error-message\">{{termsErrorMessage}}</p>\n" +
            "  </section>\n" +
            "  <section class=\"accept-terms-action\">\n" +
            "    <button class=\"pull-right\"\n" +
            "      ng-disabled=\"needToAcceptMainSiteTerms() || needToAcceptWlpSiteTerms()\"\n" +
            "      ng-click=\"accept()\"\n" +
            "    >\n" +
            "      {{ terms.length > 1 &&  (!termsAccepted || (!wlpTermsAccepted && wlp_id_term == 0)) ? 'Continue' : 'Confirm' }}\n" +
            "    </button>\n" +
            "    <button class=\"previous-btn pull-right\" ng-if=\"terms.length > 1 && wlp_id_term != 0\" ng-click=\"showTerms()\">Previous</button>\n" +
            "    <button class=\"cancel-btn right-aligned-btn\" ng-click=\"cancel()\">Cancel</button>\n" +
            "  </section>\n" +
            "</common-modal>");

        $templateCache.put("dialogs/add_reverse_solicitation_dialog/add_reverse_solicitation_dialog.html", "<common-modal modal-header=\"Reverse Solicitation Details\">\n" +
            "    <div class=\"add-reverse-solicitation\">\n" +
            "        <form name=\"reverseSolicitationForm\" id=\"reverse-solicitation\" class=\"css-form\" novalidate>\n" +
            "            <!--<div class=\"labelish heading-6\">Primary Signer's Name and Email Address</div>-->\n" +
            "            <!-- private access fund -->\n" +
            "            <div class=\"reverse-solicitation-label heading-6\">Private Access Fund</div>\n" +
            "            <div class=\"reverse-solicitation-field form-group\">\n" +
            "                <input type=\"text\"\n" +
            "                       class=\"form-control add-new-exception-paf-input\"\n" +
            "                       name=\"privateAccessFund\"\n" +
            "                       placeholder=\"Enter a Fund Name\"\n" +
            "                       ng-model=\"reverseSolicitation.private_access_fund\"\n" +
            "                       ng-blur=\"fieldOnBlurCheck('paf')\"\n" +
            "                       validator=\"[requiredBlur]\"\n" +
            "                       autocomplete=\"off\"\n" +
            "                       typeahead='fund as fund.fund_name for fund in openFunds | filter:$viewValue | limitTo:5'\n" +
            "                       ng-required=\"true\"/>\n" +
            "                <label class=\"control-label error\" ng-if=\"pafNotOnList && reverseSolicitationForm.privateAccessFund\">This Private Access Fund is Not on the list.</label>\n" +
            "            </div>\n" +
            "            <!-- user -->\n" +
            "            <div class=\"reverse-solicitation-label heading-6\">User</div>\n" +
            "            <div class=\"reverse-solicitation-user-input-field form-group\">\n" +
            "                <input type=\"text\"\n" +
            "                       class=\"form-control add-new-exception-user-name-input\"\n" +
            "                       name=\"user\"\n" +
            "                       placeholder=\"Enter a Valid User Email\"\n" +
            "                       ng-model=\"reverseSolicitation.user\"\n" +
            "                       autocomplete=\"off\"\n" +
            "                       ng-change=\"searchUsers()\"\n" +
            "                       validator=\"[loginFormat]\"\n" +
            "                       typeahead-wait-ms=1000\n" +
            "                       typeahead='user as user.login for user in availableUserList'\n" +
            "                       typeahead-template-url=\"dialogs/add_reverse_solicitation_dialog/add_reverse_solicitation_typeahead_template.html\"\n" +
            "                       ng-required=\"true\"/>\n" +
            "            </div>\n" +
            "            <!-- reason -->\n" +
            "            <div class=\"reverse-solicitation-label heading-6\">Reason</div>\n" +
            "            <div class=\"reverse-solicitation-field form-group\">\n" +
            "                <input type=\"text\"\n" +
            "                       class=\"form-control add-new-exception-reason-input\"\n" +
            "                       name=\"reason\"\n" +
            "                       autocomplete=\"off\"\n" +
            "                       ng-model=\"reverseSolicitation.reason\"\n" +
            "                       placeholder=\"Enter a Reason\"/>\n" +
            "            </div>\n" +
            "            <label class=\"control-label error\" ng-if=\"errorAddingException\">{{errorMessage}}</label>\n" +
            "            <div class=\"row investment-buttons\">\n" +
            "                <button type=\"submit\" class=\"right-aligned-btn save-button\"\n" +
            "                        ng-disabled=\"reverseSolicitationForm.$invalid || pafNotOnList || !reverseSolicitationForm.user\"\n" +
            "                        ng-click=\"addNewException()\">\n" +
            "                    Create Exception\n" +
            "                </button>\n" +
            "                <button class=\"cancel-btn right-aligned-btn\" ng-click=\"cancelDialog()\">\n" +
            "                    CANCEL\n" +
            "                </button>\n" +
            "            </div>\n" +
            "        </form>\n" +
            "    </div>\n" +
            "</common-modal>");

        $templateCache.put("dialogs/add_reverse_solicitation_dialog/add_reverse_solicitation_typeahead_template.html", "<a class=\"reverse-solicitation-user-list-block\">\n" +
            "    <p class=\"user-list-block-name\">{{match.model.full_name}}</p>\n" +
            "</a>");

        $templateCache.put("dialogs/approval_history_export_dialog/approval_history_export_dialog.html", "<common-modal modal-header=\"{{header}}\">\n" +
            "  <div class=\"modal-header-combined__content investmentSubscription\">\n" +
            "      <div class=\"row\">\n" +
            "        {{detailMessage}}\n" +
            "      </div>\n" +
            "\n" +
            "      <div class=\"row ip-investment-buttons\">\n" +
            "        <button\n" +
            "          class=\"right-aligned-btn\"\n" +
            "          type=\"submit\"\n" +
            "          ng-click=\"export()\"\n" +
            "        >\n" +
            "          EXPORT\n" +
            "        </button>\n" +
            "        <button\n" +
            "          class=\"right-aligned-btn button__cancel\"\n" +
            "          ng-click=\"cancel()\"\n" +
            "        >\n" +
            "          CANCEL\n" +
            "        </button>\n" +
            "      </div>\n" +
            "\n" +
            "      <table\n" +
            "        class=\"icn-table\"\n" +
            "        name=\"subscriptionReviewExportsTable\"\n" +
            "        id=\"subscriptionReviewExportsTable\"\n" +
            "        ng-show=\"exports.length > 0\"\n" +
            "      >\n" +
            "        <thead>\n" +
            "          <th>ID</th>\n" +
            "          <th>Generated At</th>\n" +
            "          <th>Options</th>\n" +
            "        </thead>\n" +
            "        <tbody>\n" +
            "          <tr ng-repeat=\"export in exports | orderBy: '-created_at'\">\n" +
            "            <td>{{export.id}}</td>\n" +
            "            <td>{{export.created_at | date:'yyyy-MM-dd HH:mm:ss'}}</td>\n" +
            "            <td><a target=\"_self\" href=\"{{export.download_url}}\" download>Download</a></td>\n" +
            "          </tr>\n" +
            "        </tbody>\n" +
            "      </table>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("dialogs/custom_confirm_dialog/custom_confirm.html", "<common-modal modal-header=\"{{header}}\">\n" +
            "  <h5 ng-bind-html=\"msg\"></h5>\n" +
            "  <div class=\"row more-head-room\">\n" +
            "    <button class=\"pull-right\" ng-click=\"yes()\">{{confirm_label}}</button>\n" +
            "    <button class=\"cancel-btn pull-right\" ng-click=\"no()\" ng-if=\"cancel_label\">{{cancel_label}}</button>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("dialogs/custom_notify_dialog/custom_notify_dialog.html", "<common-modal modal-header=\"{{header}}\">\n" +
            "    <div class=\"modal-body {{custom_body_class}}\" ng-bind-html=\"msg\" ng-if=\"msg\"></div>\n" +
            "    <button class=\"right-aligned-btn\" type=\"submit\" ng-click=\"close()\">\n" +
            "      OK\n" +
            "    </button>\n" +
            "</common-modal>");

        $templateCache.put("dialogs/custom_notify_dialog/custom_notify_no_indentation_dialog.html", "<common-modal modal-header=\"{{header}}\">\n" +
            "    <div ng-bind-html=\"msg\" ng-if=\"msg\" class=\"custom-no-indent-dialog\"></div>\n" +
            "</common-modal>");

        $templateCache.put("dialogs/delete_unused_reverse_solicitation_dialog/delete_unused_reverse_solicitation_dialog.html", "<common-modal modal-header=\"Reverse Solicitation Details\">\n" +
            "    <div class=\"delete-unused-reverse-solicitation\">\n" +
            "        <form name=\"reverseSolicitationForm\" id=\"reverse-solicitation\" class=\"css-form\" novalidate>\n" +
            "            <!-- private access fund -->\n" +
            "            <div class=\"reverse-solicitation-label heading-6\">Private Access Fund</div>\n" +
            "            <div class=\"reverse-solicitation-field form-group\">\n" +
            "                <input type=\"text\"\n" +
            "                       class=\"form-control add-new-exception-paf-input\"\n" +
            "                       name=\"privateAccessFund\"\n" +
            "                       placeholder=\"Enter a Fund Name\"\n" +
            "                       ng-model=\"reverseSolicitation.private_access_fund\"\n" +
            "                       ng-blur=\"fieldOnBlurCheck('paf')\"\n" +
            "                       validator=\"[requiredBlur]\"\n" +
            "                       autocomplete=\"off\"\n" +
            "                       typeahead='fund as fund.fund_name for fund in openFunds | filter:$viewValue | limitTo:5'\n" +
            "                       ng-required=\"true\"/>\n" +
            "                <label class=\"control-label error\"\n" +
            "                       ng-if=\"pafNotOnList && reverseSolicitationForm.privateAccessFund\">This Private Access Fund is Not on the list.</label>\n" +
            "            </div>\n" +
            "            <!-- Unused exception -->\n" +
            "            <div class=\"reverse-solicitation-label heading-6\">Unused Exceptions</div>\n" +
            "            <div class=\"reverse-solicitation-user-input-field form-group\">\n" +
            "                <select id=\"unused-reverse-solicitation-drop-down\"\n" +
            "                        class=\"form-control\"\n" +
            "                        name=\"unusedException\"\n" +
            "                        ng-model=\"reverseSolicitation.selectedException\"\n" +
            "                        validator=\"[requiredBlur]\"\n" +
            "                        ng-disabled=\"pafNotOnList || reverseSolicitation.unusedExceptionList.length == 0\"\n" +
            "                        ng-options=\"unusedException.exception_id as unusedException.full_info for unusedException in reverseSolicitation.unusedExceptionList\"\n" +
            "                        required>\n" +
            "                    <option value=\"\">\n" +
            "                        <p>Unused Exceptions</p>\n" +
            "                    </option>\n" +
            "                </select>\n" +
            "            </div>\n" +
            "            <label class=\"control-label error\"\n" +
            "                   ng-if=\"errorAddingException\">\n" +
            "                {{errorMessage}}\n" +
            "            </label>\n" +
            "            <div class=\"row investment-buttons\">\n" +
            "                <button type=\"submit\"\n" +
            "                        class=\"right-aligned-btn delete-button\"\n" +
            "                        ng-disabled=\"reverseSolicitationForm.$invalid || pafNotOnList || !reverseSolicitationForm.unusedException\"\n" +
            "                        ng-click=\"deleteUnusedException()\">\n" +
            "                    Delete Selected\n" +
            "                </button>\n" +
            "                <button class=\"cancel-btn right-aligned-btn\"\n" +
            "                        ng-click=\"cancelDialog()\">\n" +
            "                    CANCEL\n" +
            "                </button>\n" +
            "            </div>\n" +
            "        </form>\n" +
            "    </div>\n" +
            "</common-modal>");

        $templateCache.put("dialogs/document_export_dialog/document_export_dialog.html", "<common-modal modal-header=\"{{modalTitle}} available exported documents\">\n" +
            "    <div class=\"investment-exports-list-container\">\n" +
            "      <table class=\"table-icn\">\n" +
            "        <tr>\n" +
            "          <th>File</th>\n" +
            "          <th>Created at</th>\n" +
            "        </tr>\n" +
            "        <tr ng-repeat=\"doc in documentExports\">\n" +
            "          <td><a href=\"{{doc.file_url}}\"}>{{doc.filename}}</a></td>\n" +
            "          <td>{{doc.created_at | date: 'short'}}</td>\n" +
            "        </tr>\n" +
            "      </table>\n" +
            "    </div>\n" +
            "</common-modal>");

        $templateCache.put("dialogs/investor_acknowledgement_dialog/investor_acknowledgement_dialog.html", "<common-modal modal-header=\"{{header}}\" id=\"investor_acknowledgement_modal\">\n" +
            "  <p>\n" +
            "    {{acknowledgementInfo()}}\n" +
            "  </p>\n" +
            "\n" +
            "  <div ng-repeat=\"question in qualificationQuestions\" class=\"question\">\n" +
            "    <div class=\"checkbox\">\n" +
            "      <label>\n" +
            "        <input type=\"checkbox\" ng-checked=\"question.checked\" ng-click=\"setQualificationQuestion($event, question)\" />\n" +
            "        <div ng-if=\"hasTitle(question)\">{{question.title}}</div>\n" +
            "        <div ng-bind-html=\"question.description\"></div>\n" +
            "      </label>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "\n" +
            "\n" +
            "  <div class=\"footer row modal-header-combined__buttons\">\n" +
            "    <div class=\"callout callout-warning\" ng-if=\"warning\">\n" +
            "      Your attestation differs from what we currently have on file.\n" +
            "      Please review before submitting.\n" +
            "    </div>\n" +
            "    <button class=\"btn-agree pull-right\" ng-click=\"accept()\" ng-disabled=\"disabledAgreeButton()\">\n" +
            "      I AGREE\n" +
            "    </button>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("dialogs/locked_account_dialog/locked_account_dialog.html", "<common-modal modal-header=\"{{header}}\" id=\"disabled_account_modal\">\n" +
            "  <p>Your account has been locked due to a change in your Investor Acknowledgement. Please contact Investor Relations at 212 994 7333 or <a>ir@icapitalnetwork.com</a>.</p>\n" +
            "  <div class=\"row modal-header-combined__buttons\">\n" +
            "    <button class=\"pull-right\" ng-click=\"accept()\">OK</button>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("dialogs/non_disclosure_agreement_dialog/non_disclosure_agreement_dialog.html", "<div class=\"accept-statement\" ng-class=\"{ 'accepted' : currentDisclaimer.accepted }\">\n" +
            "  <span>\n" +
            "    <p>By accessing this site (this “Website”), you understand that Institutional Capital Network, Inc., a Delaware\n" +
            "    corporation (“iCapital” and, together with its affiliates, the “iCapital Parties”), is prepared to make available\n" +
            "    to you Information (as defined below) concerning {{currentDisclaimer.fundName}} (collectively, the “Access Fund”) \n" +
            "    and one or more investment funds into which the Access Fund may invest from time to time (collectively, the\n" +
            "    “Underlying Fund”), and you agree to the following terms as of the date on which you first access this Website (the\n" +
            "    “Effective Date”).</p>\n" +
            "    <p>1.	You hereby acknowledge and agree (i) that the information made available through the use of this Website,\n" +
            "    including any posted materials (collectively, the “Materials”) regarding the Access Fund and the Underlying\n" +
            "    Fund (such information, collectively with the Materials, the “Information”), is being furnished to you for\n" +
            "    the sole purpose of providing information about, and documents with respect to, the Access Fund in connection\n" +
            "    with your (or in the case of a Representative (as defined below), an investor’s) evaluation of a possible\n" +
            "    investment in the Access Fund and may not be used for any other purpose, (ii) that, by accessing this Website,\n" +
            "    you will be in possession of confidential information (including as it relates to the Materials, which may\n" +
            "    contain material nonpublic information); (iii) to use the Information for the sole purpose of evaluating a\n" +
            "    potential investment in the Access Fund (and, in the case of a Representative, to assist an investor in\n" +
            "    connection with his, her or its evaluation of a potential investment in the Access Fund), and for no other\n" +
            "    purpose; and (iv) to keep all Information strictly confidential (unless such Information is or becomes readily\n" +
            "    ascertainable from public information or trade sources or is already in your possession on a non-confidential\n" +
            "    basis prior to your receipt thereof through the use of this Website) and not to disclose, reproduce,\n" +
            "    redistribute, forward or otherwise pass, directly or indirectly, any such Information to any other person, (x)\n" +
            "    other than to your affiliates, officers, employees, attorneys, accountants, consultants and other advisors\n" +
            "    (each, a “Representative”) who (A) have a bona fide need to know such Information for purposes of monitoring\n" +
            "    or managing your (or in the case of a Representative, an investor’s) potential investment in the Access Fund,\n" +
            "    or for financial, legal or accounting purposes, and (B) have agreed to, or are otherwise subject to, a duty to\n" +
            "    keep such Information confidential and to not otherwise use such Information in a manner inconsistent with this\n" +
            "    Non-Disclosure Agreement (this “Agreement”), it being understood that you shall be responsible and liable for\n" +
            "    any disclosure or use by any such Representative, (y) only in the case of a Representative, other than to an\n" +
            "    investor who has agreed to, or is otherwise subject to, a duty to keep such Information confidential and to not\n" +
            "    otherwise use such Information in a manner inconsistent with this Agreement, it being understood that you shall\n" +
            "    be responsible and liable for any disclosure or use by any such investor, or (z) unless such disclosure is\n" +
            "    required by applicable law, governmental rule or regulation, or by any regulatory or judicial authority having\n" +
            "    jurisdiction over you, in which case you shall provide as much prior written notice to the iCapital Parties as\n" +
            "    is practicable under the circumstances.</p>\n" +
            "    <p>2.	You acknowledge and agree that the iCapital Parties may be irreparably harmed if their obligations\n" +
            "    hereunder are not specifically enforced and that they would not have an adequate remedy at law in the event\n" +
            "    of an actual or threatened violation by you or your Representative of your or its obligations hereunder.\n" +
            "    Therefore, you agree and consent that the iCapital Parties shall be entitled to seek an injunction or any\n" +
            "    appropriate decree of specific performance for any actual or threatened violation or breach by you or your\n" +
            "    Representative of this Agreement, without the posting of any bond, and such other relief as may be available\n" +
            "    at law or equity, including the right to recover all losses or damages suffered by the iCapital Parties\n" +
            "    resulting from any such breach or threatened breach.</p>\n" +
            "    <p>3.	This Agreement will be governed by and interpreted in accordance with the laws of the State of New York,\n" +
            "    without regard to principles of conflicts of law.  Any action or proceeding brought by any party relating in\n" +
            "    any way to this Agreement shall be brought and enforced in the courts of the Southern District of New York,\n" +
            "    and you irrevocably submit to the jurisdiction for such federal courts in respect of any such action or\n" +
            "    proceeding.</p>\n" +
            "    <p>4.	This Agreement is effective as of the Effective Date and shall continue in full force and effect for so\n" +
            "    long as this Agreement is not terminated by the iCapital Parties.</p>\n" +
            "    <p>5.	If any provision or provisions of this Agreement shall be held to be invalid, illegal or unenforceable,\n" +
            "    the validity, legality and enforceability of the remaining provisions shall not in any way be affected or\n" +
            "    impaired thereby. This Agreement may be executed in counterparts, each of which shall be deemed an original,\n" +
            "    but all of which, taken together, shall constitute one agreement.</p>\n" +
            "  </span>\n" +
            "</div>");

        $templateCache.put("dialogs/notification/notification.html", "<div id=\"{{id}}\" class=\"notification-container\">\n" +
            "  <div class=\"notification-content\">\n" +
            "    <div ng-html-compile=\"message\"></div>\n" +
            "  </div>\n" +
            "  <button type=\"button\" ng-click=\"kill()\" class=\"btn-close\">\n" +
            "    <i class=\"fa fa-times-thin\" aria-hidden=\"true\"></i>\n" +
            "  </button>\n" +
            "</div>");

        $templateCache.put("dialogs/one_time_popup_dialog/one_time_popup_dialog.html", "<common-modal modal-header=\"{{header}}\">\n" +
            "    <h4 class=\"one-time-popup-title\" ng-bind-html=\"modalTitle\"></h4>\n" +
            "    <p class=\"one-time-popup-sub-title\">\n" +
            "      {{publishDate}}\n" +
            "    </p>\n" +
            "    <section class=\"one-time-popup-modal-content\" ng-bind-html=\"modalBody\"></section>\n" +
            "    <section class=\"one-time-popup-action\">\n" +
            "        <button class=\"pull-right\" ng-click=\"accept()\">OK</button>\n" +
            "    </section>\n" +
            "</common-modal>");

        $templateCache.put("dialogs/qualification_dialog/qualification_dialog.html", "<common-modal modal-header=\"{{header}}\">\n" +
            "  <section>\n" +
            "    <div class=\"container\">\n" +
            "      <h4>Please select all that apply to you or the clients you manage.</h4>\n" +
            "\n" +
            "      <div ng-if=\"qualificationQuestions.length > 0\">\n" +
            "        <div ng-repeat=\"question in qualificationQuestions\">\n" +
            "          <div class=\"checkbox\">\n" +
            "            <label>\n" +
            "              <input type=\"checkbox\" ng-checked=\"question.checked\"\n" +
            "                ng-click=\"setQualificationQuestion($event, question)\">\n" +
            "              <div ng-if=\"hasTitle(question)\">{{question.title}}</div>\n" +
            "              <div ng-bind-html=\"question.description\"></div>\n" +
            "            </label>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </section>\n" +
            "  <div class=\"footer row\">\n" +
            "    <div class=\"callout callout-warning\" ng-if=\"showError\">\n" +
            "      There was an unexpected error, you are not able to update your qualification at this time. Please email\n" +
            "      support@icapitalnetwork.com or call 212-994-7333 for assistance.\n" +
            "    </div>\n" +
            "    <button class=\"btn-agree pull-right\" ng-click=\"saveQualifications()\" ng-disabled=\"!isAnyQuestionChecked()\">\n" +
            "      Save\n" +
            "    </button>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("dialogs/roadshow_chapter_dialog/roadshow_chapter_dialog.html", "<div class=\"modalContainer\">\n" +
            "    <icn-brightcove-player video=\"{{videoURL}}\" \n" +
            "      video-id=\"{{chapter.video_id}}\" \n" +
            "      chapter=\"chapter\" \n" +
            "      fund=\"fund\" \n" +
            "      height=\"400\" \n" +
            "      width=\"711\"\n" +
            "      auto-play=\"true\">\n" +
            "    </icn-brightcove-player>\n" +
            "    <div class=\"roadshow-dialog__description\">\n" +
            "        <span class=\"roadshow-dialog__chapter\">Chapter {{chapter.position}}</span><span class=\"roadshow-dialog__title\">{{chapter.title}}</span>\n" +
            "        <div class=\"roadshow-dialog__fund\">{{fund.name}}</div>\n" +
            "    </div>\n" +
            "</div>");

        $templateCache.put("dialogs/send_remediation_notification_dialog/send_remediation_notification.html", "<common-modal modal-header=\"{{header}}\">\n" +
            "  <h5>\n" +
            "    <span ng-if=\"data.no_blacklisted && data.is_delegate_blacklisted\">\n" +
            "      The delegate(s) have elected not to receive remediation notifications.\n" +
            "      If you wish to override this preference, the notification will be sent to:\n" +
            "    </span>\n" +
            "    <span ng-if=\"data.no_blacklisted && data.is_representative_blacklisted\">\n" +
            "      The representative(s) have elected not to receive remediation notifications.\n" +
            "      If you wish to override this preference, the notification will be sent to:\n" +
            "    </span>\n" +
            "    <span ng-if=\"data.no_blacklisted && !data.is_delegate_blacklisted && !data.is_representative_blacklisted\">\n" +
            "      Send Remediation Notification to:\n" +
            "    </span>\n" +
            "    <span ng-if=\"!data.no_blacklisted\">\n" +
            "      {{data.recipient_info}}{{data.delegates_text}}{{data.representatives_text}}{{helper_text}}\n" +
            "      elected not to receive remediation notifications.\n" +
            "      If you wish to override this preference, the notification will be sent to:\n" +
            "    </span>\n" +
            "    <p>Primary Signer:</p>\n" +
            "    <ul>\n" +
            "      <li>{{data.recipient_info}}</li>\n" +
            "    </ul>\n" +
            "    <p ng-if=\"!!data.delegates_info\">Delegate(s):</p>\n" +
            "    <ul>\n" +
            "      <span ng-bind-html=\"data.delegates_info\"></span>\n" +
            "    </ul>\n" +
            "    <p ng-if=\"!!data.representatives_info\">Representative(s):</p>\n" +
            "    <ul>\n" +
            "      <span ng-bind-html=\"data.representatives_info\"></span>\n" +
            "    </ul>\n" +
            "  </h5>\n" +
            "  <div class=\"row more-head-room\">\n" +
            "    <button class=\"pull-right\" ng-click=\"yes()\">{{confirm_label}}</button>\n" +
            "    <button class=\"cancel-btn pull-right\" ng-click=\"no()\" ng-if=\"cancel_label\">{{cancel_label}}</button>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("dialogs/set_share_class_dialog/set_share_class_dialog.html", "<common-modal class=\"set-share-class-modal\" modal-header={{headerText}}>\n" +
            "  <div ng-if=\"showSetShareClass\">\n" +
            "    <h4>Select A Share Class for investment {{investment.id}}</h4>\n" +
            "    <table class=\"redemption-dialog_table\">\n" +
            "      <thead>\n" +
            "        <th>ID</th>\n" +
            "        <th>Type</th>\n" +
            "        <th>Account Name</th>\n" +
            "        <th>Subscription ($)</th>\n" +
            "      </thead>\n" +
            "      <tbody class=\"custom-scrollbar\">\n" +
            "        <tr ng-if=\"initial\">\n" +
            "          <td>{{initial.id}}</td>\n" +
            "          <td>Initial</td>\n" +
            "          <td>{{initial.investor_profile.name}}</td>\n" +
            "          <td>{{initial.amount | currency}}</td>\n" +
            "        </tr>\n" +
            "        <tr ng-repeat=\"addOn in addOns\">\n" +
            "          <td>{{addOn.id}}</td>\n" +
            "          <td>Add On</td>\n" +
            "          <td>{{addOn.investor_profile.name}}</td>\n" +
            "          <td>{{addOn.amount | currency}}</td>\n" +
            "        </tr>\n" +
            "      </tbody>\n" +
            "      <tfoot>\n" +
            "        <td></td>\n" +
            "        <td class=\"redemption-dialog_table_footer_total text-right\">Total: {{totalCommitment() | currency}}</td>\n" +
            "      </tfoot>\n" +
            "    </table>\n" +
            "    <select\n" +
            "      name=\"share_class_select\"\n" +
            "      class=\"form-control\"\n" +
            "      id=\"share-class-select\"\n" +
            "      ng-model=\"selectedShareClassId\"\n" +
            "      ng-options=\"shareClass.id as shareClass.name for shareClass in shareClasses | orderBy: 'name'\"\n" +
            "      ng-change=\"setSelectedShareClass(selectedShareClassId)\"\n" +
            "      >\n" +
            "    </select>\n" +
            "    <table ng-show=\"!!selectedShareClassId\" class=\"table\">\n" +
            "      <tr>\n" +
            "        <td class=\"field-name\">Short Name:</td>\n" +
            "        <td>{{selectedShareClass.short_name}}</td>\n" +
            "      </tr>\n" +
            "      <tr>\n" +
            "        <td class=\"field-name\">Description:</td>\n" +
            "        <td>{{selectedShareClass.description}}</td>\n" +
            "      </tr>\n" +
            "    </table>\n" +
            "    <div ng-if=\"!!errorMessage\" style=\"color: red\">\n" +
            "      <p>{{errorMessage}}</p>\n" +
            "    </div>\n" +
            "    <div class=\"share-class-buttons\">\n" +
            "      <button\n" +
            "        class=\"pull-right\"\n" +
            "        ng-click=\"setShareClass(selectedShareClassId)\"\n" +
            "        ng-disabled=\"!selectedShareClass\"\n" +
            "        >\n" +
            "        SAVE\n" +
            "      </button>\n" +
            "      <button class=\"pull-right\" ng-click=\"closeModal()\">CANCEL</button>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div ng-if=\"!showSetShareClass\">\n" +
            "    <h4>Share Class Assigned ({{changed_sc}})</h4>\n" +
            "    <div class=\"share-class-buttons\">\n" +
            "        <button class=\"pull-right\" ng-click=\"closeModal()\">OK</button>\n" +
            "      </div>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("dialogs/share_class_dialog/bulk_upload_dialog.html", "<common-modal modal-header=\"Share Class Bulk Upload\" class=\"share-class-dialog\">\n" +
            "  <div ng-switch=\"uploadStatus\">\n" +
            "    <div class=\"no-extra-margin\">\n" +
            "      <div class=\"upload-container\">\n" +
            "        <button ng-click=\"picker()\" ng-switch-when=\"none\" id=\"upload-select-btn\" class=\"file-upload upload-border\">\n" +
            "          <input onclick=\"event.stopPropagation()\" class=\"file-input\" type=\"file\" id=\"bulk-upload\" name='bulk_upload'\n" +
            "                 onchange=\"angular.element(this).scope().uploadShareClasses(this.files)\" />\n" +
            "          <img src=\"images/drag_drop.svg\" height=\"30\" width=\"48\">\n" +
            "          <div class=\"sm-mrg\">Drop file here or <br><a>browse files</a></div>\n" +
            "        </button>\n" +
            "\n" +
            "        <div ng-switch-when=\"loading\" class=\"loader-container\"><div class=\"loader\"></div></div>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "    <a class=\"bulk_template_link\" href=\"#\" ng-click=\"downloadTemplate()\">Download the template file</a>\n" +
            "  </div>\n" +
            "  <div ng-if=\"bulkUploadResults.length > 0\" class=\"bulk_upload_latest_results\">\n" +
            "    <span class=\"bulk_upload_latest_results_tittle\">Latest Results</span>\n" +
            "    <div class=\"scrollable bulk_upload_results\">\n" +
            "      <table class=\"table-icn\">\n" +
            "        <tr>\n" +
            "          <th>File</th>\n" +
            "          <th>Created at</th>\n" +
            "        </tr>\n" +
            "        <tr ng-repeat=\"doc in bulkUploadResults\">\n" +
            "          <td><a href=\"{{doc.file_url}}\"}>{{doc.filename}}</a></td>\n" +
            "          <td>{{doc.created_at | date: 'short'}}</td>\n" +
            "        </tr>\n" +
            "      </table>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"clearfix\">\n" +
            "    <button class=\"right-aligned-btn\" ng-click=\"closeModal()\">Close</button>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("dialogs/share_class_dialog/share_class_dialog.html", "<common-modal modal-header=\"{{header}}\" class=\"share-class-dialog\">\n" +
            "  <h4 ng-show=\"readOnly\">\n" +
            "    {{shareClass.name}}\n" +
            "    <span class=\"default-marker\" ng-show=\"shareClass.is_default\">(DEFAULT SHARE CLASS)</span>\n" +
            "  </h4>\n" +
            "  <div ng-if=\"!readOnly\" class=\"form-errors\">\n" +
            "    <div ng-show=\"errorMsg\">\n" +
            "      {{errorMsg}}\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div ng-class=\"{'scrollable': readOnly}\">\n" +
            "    <table class=\"table\">\n" +
            "      <tbody>\n" +
            "        <tr ng-hide=\"readOnly\">\n" +
            "          <td class=\"field-name\"> \n" +
            "            Default:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              class=\"set-default\"\n" +
            "              type=\"checkbox\"\n" +
            "              ng-model=\"shareClass.is_default\"\n" +
            "              name=\"default_class\"\n" +
            "              />\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Status:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <select\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              ng-class=\"{'required-input': errors.status}\"\n" +
            "              class=\"form-control\"\n" +
            "              id=\"status\"\n" +
            "              ng-model=\"shareClass.status\"\n" +
            "              ng-change=\"clearErrors('status')\"\n" +
            "              name=\"status\"\n" +
            "              >\n" +
            "              <option value='closed'>Closed</option>\n" +
            "              <option value='open'>Open</option>\n" +
            "            </select>\n" +
            "            <div ng-show=\"readOnly\" class=\"text-capitalize\">\n" +
            "              {{shareClass.status}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td ng-class=\"{'required-label': errors.name}\" class=\"field-name\">\n" +
            "            Name:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              class=\"form-control\"\n" +
            "              ng-model=\"shareClass.name\"\n" +
            "              ng-class=\"{'required-input': errors.name}\"\n" +
            "              ng-change=\"clearErrors('name')\"\n" +
            "              name=\"name\"\n" +
            "              />\n" +
            "            <div ng-show=\"readOnly\">\n" +
            "              {{shareClass.name}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name field-short-name\">\n" +
            "            Short Name:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              class=\"form-control\"\n" +
            "              ng-model=\"shareClass.short_name\"\n" +
            "              ng-class=\"{'required-input': errors.short_name}\"\n" +
            "              ng-change=\"clearErrors('short_name')\"\n" +
            "              maxlength=\"15\"\n" +
            "              name=\"short_name\"\n" +
            "              />\n" +
            "            <div ng-show=\"readOnly\">\n" +
            "              {{shareClass.short_name}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Description:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              ng-class=\"{'required-input': errors.description}\"\n" +
            "              class=\"form-control\"\n" +
            "              ng-model=\"shareClass.description\"\n" +
            "              ng-change=\"clearErrors('description')\"\n" +
            "              name=\"description\"\n" +
            "              />\n" +
            "            <div ng-show=\"readOnly\">\n" +
            "              {{shareClass.description}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Inception Date:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "                    ng-hide=\"readOnly\"\n" +
            "                    name=\"inception_date\"\n" +
            "                    type=\"text\"\n" +
            "                    ui-date=\"inceptionDateOptions\"\n" +
            "                    ui-date-format=\"mm/dd/yy\"\n" +
            "                    class=\"form-control\"\n" +
            "                    ng-model=\"shareClass.inception_date\"\n" +
            "            />\n" +
            "            <div ng-show=\"readOnly\">\n" +
            "              {{shareClass.inception_date}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td  ng-class=\"{'required-label': errors.bny_external_mapping_id}\" class=\"field-name\">\n" +
            "            BNY Accounting API:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              name=\"bny_external_share_class_id\"\n" +
            "              type=\"text\"\n" +
            "              class=\"form-control\"\n" +
            "              ng-class=\"{'required-input': errors.bny_external_mapping_id}\"\n" +
            "              ng-change=\"clearErrors('bny_external_mapping_id')\"\n" +
            "              ng-model=\"shareClass.bny_external_share_class_id\"\n" +
            "              />\n" +
            "            <div ng-show=\"readOnly\">\n" +
            "              {{shareClass.bny_external_share_class_id}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Subscription Type:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <select\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              class=\"form-control\"\n" +
            "              id=\"brokerage_advisory\"\n" +
            "              name=\"brokerage_advisory\"\n" +
            "              ng-model=\"shareClass.brokerage_advisory\">\n" +
            "              <option value=\"brokerage\">Brokerage</option>\n" +
            "              <option value=\"advisory\">Advisory</option>\n" +
            "            </select>\n" +
            "            <div ng-show=\"readOnly\" class=\"text-capitalize\">\n" +
            "              {{shareClass.brokerage_advisory}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Interest Class:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <select\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              class=\"form-control\"\n" +
            "              id=\"distribution_reinvestment\"\n" +
            "              name=\"distribution_reinvestment\"\n" +
            "              ng-model=\"shareClass.distribution_reinvestment\">\n" +
            "              <option value=\"distribution\">Distribution</option>\n" +
            "              <option value=\"reinvestment\">Reinvestment</option>\n" +
            "            </select>\n" +
            "            <div ng-show=\"readOnly\" class=\"text-capitalize\">\n" +
            "              {{shareClass.distribution_reinvestment}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Lock-up Type:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <select\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              class=\"form-control\"\n" +
            "              id=\"lock_up_type\"\n" +
            "              name=\"lock_up_type\"\n" +
            "              ng-model=\"shareClass.lock_up_type\" >\n" +
            "              <option value=''>None</option>\n" +
            "              <option value='hard'>Hard</option>\n" +
            "              <option value='soft'>Soft</option>\n" +
            "            </select>\n" +
            "            <div ng-show=\"readOnly\" class=\"text-capitalize\">\n" +
            "              {{shareClass.lock_up_type}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Lock-up Period<span ng-hide=\"readOnly\"> (Months)</span>:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              class=\"form-control\"\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              name=\"lock_up_period\"\n" +
            "              ng-model=\"shareClass.lock_up_period\"/>\n" +
            "            <div ng-show=\"readOnly\">\n" +
            "              {{shareClass.lock_up_period}} <span ng-show=\"{{!!shareClass.lock_up_period}}\">Months</span>\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Notice Period <span ng-hide=\"readOnly\"> (Business Days)</span>:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              class=\"form-control\"\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              name=\"notice_period\"\n" +
            "              ng-model=\"shareClass.notice_period\"/>\n" +
            "            <div ng-show=\"readOnly\">\n" +
            "              {{shareClass.notice_period}} <span ng-show=\"{{!!shareClass.notice_period}}\">Business Days</span>\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td ng-class=\"{'required-label': errors.frequency}\" class=\"field-name\">\n" +
            "            Redemption Frequency:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <div ng-hide=\"readOnly\">\n" +
            "              <select\n" +
            "                ng-class=\"{'shorten': shareClass.redemption_frequency == 'annually'}\"\n" +
            "                class=\"form-control\"\n" +
            "                id=\"redemption_frequency\"\n" +
            "                name=\"redemption_frequency\"\n" +
            "                ng-model=\"shareClass.redemption_frequency\"\n" +
            "                ng-change=\"clearErrors('frequency')\"\n" +
            "                >\n" +
            "                <option value='monthly'>Monthly</option>\n" +
            "                <option value='quarterly'>Quarterly</option>\n" +
            "                <option value='annually'>Annually</option>\n" +
            "              </select>\n" +
            "                <div class=\"col-md-4\" ng-show=\"shareClass.redemption_frequency == 'annually'\">\n" +
            "                    <label>Select Close Date</label>\n" +
            "                    <input\n" +
            "                            class=\"redemption-date\"\n" +
            "                            ng-class=\"{'required-input': errors.frequency}\"\n" +
            "                            ng-change=\"clearErrors('frequency')\"\n" +
            "                            name=\"redemption_date\"\n" +
            "                            type=\"text\"\n" +
            "                            ui-date=\"vm.dateOptions\"\n" +
            "                            ui-date-format=\"mm/dd/yy\"\n" +
            "                            class=\"form-control\"\n" +
            "                            ng-model=\"shareClass.redemption_date\"\n" +
            "                    />\n" +
            "                </div>\n" +
            "            </div>\n" +
            "            <div ng-show=\"readOnly\" class=\"text-capitalize\">\n" +
            "              {{shareClass.redemption_frequency}}\n" +
            "              <span ng-show=\"shareClass.redemption_date\" class=\"text-capitalize\">\n" +
            "                on {{shareClass.redemption_date}}\n" +
            "              </span>\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Retro Rates %:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              ui-percentage-mask\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              name=\"retro_rate\"\n" +
            "              type=\"text\"\n" +
            "              class=\"form-control\"\n" +
            "              ng-model=\"shareClass.retro_rate\"\n" +
            "              />\n" +
            "            <div ng-show=\"readOnly\">\n" +
            "              {{toPercentage(shareClass.retro_rate)}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Gate %:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              ui-percentage-mask\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              name=\"gate\"\n" +
            "              type=\"text\"\n" +
            "              class=\"form-control\"\n" +
            "              ng-model=\"shareClass.gate\"\n" +
            "              />\n" +
            "            <div ng-show=\"readOnly\">\n" +
            "              {{toPercentage(shareClass.gate)}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr> \n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Investor Gate %:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              ui-percentage-mask\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              name=\"investor_gate\"\n" +
            "              type=\"text\"\n" +
            "              class=\"form-control\"\n" +
            "              ng-model=\"shareClass.investor_gate\"\n" +
            "              />\n" +
            "            <div ng-show=\"readOnly\">\n" +
            "              {{toPercentage(shareClass.investor_gate)}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Investor Gate Penalty:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              ui-percentage-mask\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              name=\"investor_gate_penalty\"\n" +
            "              type=\"text\"\n" +
            "              class=\"form-control\"\n" +
            "              ng-model=\"shareClass.investor_gate_penalty\"\n" +
            "              />\n" +
            "            <div ng-show=\"readOnly\">\n" +
            "              {{toPercentage(shareClass.investor_gate_penalty)}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Gate Penalty:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              ui-percentage-mask\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              name=\"gate_penalty\"\n" +
            "              type=\"text\"\n" +
            "              class=\"form-control\"\n" +
            "              ng-model=\"shareClass.gate_penalty\"\n" +
            "              />\n" +
            "            <div ng-show=\"readOnly\">\n" +
            "              {{toPercentage(shareClass.gate_penalty)}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Early Penalty:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              ui-percentage-mask\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              name=\"early_penalty\"\n" +
            "              type=\"text\"\n" +
            "              class=\"form-control\"\n" +
            "              ng-model=\"shareClass.early_penalty\"\n" +
            "              />\n" +
            "            <div ng-show=\"readOnly\">\n" +
            "              {{toPercentage(shareClass.early_penalty)}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td ng-class=\"{'required-label': errors.aum}\" class=\"field-name\">\n" +
            "            AUM:\n" +
            "            <div ng-show=\"errors.aum\" class=\"required-field\">\n" +
            "              Minimum cannot exceed Maxium\n" +
            "            </div>\n" +
            "          </td>\n" +
            "          <td class=\"split-cell\">\n" +
            "            <div class=\"sub-row top\">\n" +
            "              <div class=\"text-bold split-field\">Minimum</div>\n" +
            "              <div class=\"text-bold split-field\">Maximum</div>\n" +
            "            </div>\n" +
            "            <div class=\"sub-row bottom\">\n" +
            "              <input\n" +
            "                ui-money-mask\n" +
            "                ng-hide=\"readOnly\"\n" +
            "                ng-class=\"{'required-input': errors.aum}\"\n" +
            "                ng-model=\"shareClass.aum_min\"\n" +
            "                class=\"form-control split-field\"\n" +
            "                name=\"aum_min\"\n" +
            "                ng-change=\"clearErrors('aum')\"\n" +
            "                />\n" +
            "              <div ng-show=\"readOnly\" class=\"split-field\">\n" +
            "                {{shareClass.aum_min | currency}}\n" +
            "              </div>\n" +
            "              <input\n" +
            "                ui-money-mask\n" +
            "                ng-hide=\"readOnly\"\n" +
            "                ng-class=\"{'required-input': errors.aum}\"\n" +
            "                ng-model=\"shareClass.aum_max\"\n" +
            "                class=\"form-control split-field\"\n" +
            "                name=\"aum_max\"\n" +
            "                ng-change=\"clearErrors('aum')\"\n" +
            "                />\n" +
            "              <div ng-show=\"readOnly\" class=\"split-field\">\n" +
            "                {{shareClass.aum_max | currency}}\n" +
            "              </div>\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Management Fee:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              ui-percentage-mask\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              name=\"management_fee\"\n" +
            "              type=\"text\"\n" +
            "              class=\"form-control\"\n" +
            "              ng-model=\"shareClass.management_fee\"\n" +
            "              />\n" +
            "            <div ng-show=\"readOnly\">\n" +
            "              {{toPercentage(shareClass.management_fee)}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Service Fee:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              ui-percentage-mask\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              name=\"service_fee\"\n" +
            "              type=\"text\"\n" +
            "              class=\"form-control\"\n" +
            "              ng-model=\"shareClass.service_fee\"\n" +
            "              />\n" +
            "            <div ng-show=\"readOnly\">\n" +
            "              {{toPercentage(shareClass.service_fee)}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Notes:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              name=\"note\"\n" +
            "              type=\"text_area\"\n" +
            "              class=\"form-control\"\n" +
            "              ng-model=\"shareClass.note\"\n" +
            "              />\n" +
            "            <div ng-show=\"readOnly\">\n" +
            "              {{shareClass.note}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Drawdown:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              type=\"checkbox\"\n" +
            "              ng-model=\"shareClass.drawdown\"\n" +
            "              name=\"drawdown\"\n" +
            "            />\n" +
            "            <div ng-show=\"readOnly\">\n" +
            "              {{shareClass.drawdown}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Fact card reportable:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              type=\"checkbox\"\n" +
            "              ng-model=\"shareClass.fact_card_reportable\"\n" +
            "              name=\"fact_card_reportable\"\n" +
            "            />\n" +
            "            <div ng-show=\"readOnly\">\n" +
            "              {{shareClass.fact_card_reportable}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Pro Forma:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              type=\"checkbox\"\n" +
            "              ng-model=\"shareClass.pro_forma\"\n" +
            "              name=\"pro_forma\"\n" +
            "            />\n" +
            "            <div ng-show=\"readOnly\">\n" +
            "              {{shareClass.pro_forma}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Minimum Redemption Amount:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              ui-money-mask\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              ng-model=\"shareClass.minimum_redemption_amount\"\n" +
            "              class=\"form-control\"\n" +
            "              name=\"minimum_redemption_amount\"\n" +
            "              />\n" +
            "            <div ng-show=\"readOnly\" class=\"split-field\">\n" +
            "              {{shareClass.minimum_redemption_amount | currency}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Payment Terms:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              ng-model=\"shareClass.payment_terms\"\n" +
            "              class=\"form-control\"\n" +
            "              name=\"payment_terms\"\n" +
            "              disabled\n" +
            "            />\n" +
            "            <div ng-show=\"readOnly\" class=\"split-field\">\n" +
            "              {{shareClass.payment_terms}} <span ng-show=\"{{!!shareClass.payment_terms}}\">Days </span>\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Hot Issue:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              ng-model=\"shareClass.hot_issue\"\n" +
            "              class=\"form-control\"\n" +
            "              name=\"hot_issue\"\n" +
            "              disabled\n" +
            "            />\n" +
            "            <div ng-show=\"readOnly\" class=\"split-field\">\n" +
            "              {{shareClass.hot_issue}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Redemption Notice Requirement Type:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              ng-model=\"shareClass.redemption_notice_requirement_type\"\n" +
            "              class=\"form-control\"\n" +
            "              name=\"redemption_notice_requirement_type\"\n" +
            "              disabled\n" +
            "            />\n" +
            "            <div ng-show=\"readOnly\" class=\"split-field\">\n" +
            "              {{shareClass.redemption_notice_requirement_type}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Investor Gate Type:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              ng-model=\"shareClass.investor_gate_type\"\n" +
            "              class=\"form-control\"\n" +
            "              name=\"investor_gate_type\"\n" +
            "              disabled\n" +
            "            />\n" +
            "            <div ng-show=\"readOnly\" class=\"split-field\">\n" +
            "              {{shareClass.investor_gate_type}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Tax Status:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              ng-model=\"shareClass.tax_status\"\n" +
            "              class=\"form-control\"\n" +
            "              name=\"tax_status\"\n" +
            "              disabled\n" +
            "            />\n" +
            "            <div ng-show=\"readOnly\" class=\"split-field\">\n" +
            "              {{shareClass.tax_status}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Fund Master id:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              ng-model=\"shareClass.fm_share_class_id\"\n" +
            "              class=\"form-control\"\n" +
            "              name=\"fm_share_class_id\"\n" +
            "              disabled\n" +
            "            />\n" +
            "            <div ng-show=\"readOnly\" class=\"split-field\">\n" +
            "              {{shareClass.fm_share_class_id}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Close Date:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              name=\"close_date\"\n" +
            "              type=\"text\"\n" +
            "              ui-date=\"inceptionDateOptions\"\n" +
            "              ui-date-format=\"mm/dd/yy\"\n" +
            "              class=\"form-control\"\n" +
            "              ng-model=\"shareClass.close_date\"\n" +
            "              disabled\n" +
            "            />\n" +
            "            <div ng-show=\"readOnly\" class=\"split-field\">\n" +
            "              {{shareClass.close_date}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Starting Share Price:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              ui-money-mask\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              ng-model=\"shareClass.starting_share_price\"\n" +
            "              class=\"form-control\"\n" +
            "              name=\"starting_share_price\"\n" +
            "              disabled\n" +
            "            />\n" +
            "            <div ng-show=\"readOnly\" class=\"split-field\">\n" +
            "              {{shareClass.starting_share_price | currency}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Lock Up Provision:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              ng-model=\"shareClass.lock_up_provision\"\n" +
            "              class=\"form-control\"\n" +
            "              name=\"lock_up_provision\"\n" +
            "              disabled\n" +
            "            />\n" +
            "            <div ng-show=\"readOnly\" class=\"split-field\">\n" +
            "              {{shareClass.lock_up_provision}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Lock Up Count:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              ng-model=\"shareClass.lock_up_count\"\n" +
            "              class=\"form-control\"\n" +
            "              name=\"lock_up_count\"\n" +
            "              disabled\n" +
            "            />\n" +
            "            <div ng-show=\"readOnly\" class=\"split-field\">\n" +
            "              {{shareClass.lock_up_count}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Investor Gate Provision:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              ng-model=\"shareClass.investor_gate_provision\"\n" +
            "              class=\"form-control\"\n" +
            "              name=\"investor_gate_provision\"\n" +
            "              disabled\n" +
            "            />\n" +
            "            <div ng-show=\"readOnly\" class=\"split-field\">\n" +
            "              {{shareClass.investor_gate_provision}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Investor Gate Type:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              ng-model=\"shareClass.investor_gate_type\"\n" +
            "              class=\"form-control\"\n" +
            "              name=\"investor_gate_type\"\n" +
            "              disabled\n" +
            "            />\n" +
            "            <div ng-show=\"readOnly\" class=\"split-field\">\n" +
            "              {{shareClass.investor_gate_type}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Sidepocket:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              ng-model=\"shareClass.sidepocket\"\n" +
            "              class=\"form-control\"\n" +
            "              name=\"sidepocket\"\n" +
            "              disabled\n" +
            "            />\n" +
            "            <div ng-show=\"readOnly\" class=\"split-field\">\n" +
            "              {{shareClass.sidepocket}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Sidepocket Considerations:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              ng-model=\"shareClass.sidepocket_considerations\"\n" +
            "              class=\"form-control\"\n" +
            "              name=\"sidepocket_considerations\"\n" +
            "              disabled\n" +
            "            />\n" +
            "            <div ng-show=\"readOnly\" class=\"split-field\">\n" +
            "              {{shareClass.sidepocket_considerations}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Client Id:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              ng-model=\"shareClass.client_id\"\n" +
            "              class=\"form-control\"\n" +
            "              name=\"client_id\"\n" +
            "              disabled\n" +
            "            />\n" +
            "            <div ng-show=\"readOnly\" class=\"split-field\">\n" +
            "              {{shareClass.client_id}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td class=\"field-name\">\n" +
            "            Investment Share Class id:\n" +
            "          </td>\n" +
            "          <td>\n" +
            "            <input\n" +
            "              ng-hide=\"readOnly\"\n" +
            "              ng-model=\"shareClass.investment_share_class_id\"\n" +
            "              class=\"form-control\"\n" +
            "              name=\"investment_share_class_id\"\n" +
            "              disabled\n" +
            "            />\n" +
            "            <div ng-show=\"readOnly\" class=\"split-field\">\n" +
            "              {{shareClass.investment_share_class_id}}\n" +
            "            </div>\n" +
            "          </td>\n" +
            "        </tr>\n" +
            "      </tbody>\n" +
            "    </table>\n" +
            "  </div>\n" +
            "  <div class=\"row share-class-buttons\">\n" +
            "    <button class=\"pull-right\" ng-click=\"saveShareClass(shareClass)\" ng-hide=\"readOnly\">SAVE</button>\n" +
            "    <button class=\"pull-right\" ng-click=\"closeModal()\">{{readOnly ? 'OK' : 'CANCEL'}}</button>\n" +
            "    <button class=\"pull-right\" ng-click=\"cloneShareClass(shareClass)\" ng-show=\"cloneable()\">CLONE</button>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("dialogs/simple_alert/simple_alert_dialog.html", "<common-modal modal-header=\"{{headerText}}\">\n" +
            "  <div class=\"simple-alert-dialog\">\n" +
            "    <p class=\"message-text\">{{messageText}}</p>\n" +
            "    <section class=\"actions\">\n" +
            "      <button class=\"pull-right\" ng-click=\"accept()\">{{acceptText}}</button>\n" +
            "      <button class=\"cancel-btn right-aligned-btn\" ng-click=\"decline()\">{{declineText}}</button>\n" +
            "    </section>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("dialogs/subscription_review_exports_dialog/subscription_review_exports_dialog.html", "<common-modal modal-header=\"{{header}}\">\n" +
            "  <div class=\"modal-header-combined__content investmentSubscription\">\n" +
            "\n" +
            "      <div class=\"row\">\n" +
            "        {{detailMessage}}\n" +
            "      </div>\n" +
            "\n" +
            "      <div class=\"row ip-investment-buttons\">\n" +
            "        <button class=\"right-aligned-btn\" type=\"submit\" ng-click=\"exportSubscriptionReviews()\" ng-disabled=\"disableExportButton()\">\n" +
            "          EXPORT\n" +
            "        </button>\n" +
            "        <button class=\"right-aligned-btn button__cancel\" ng-click=\"cancelDialog()\">\n" +
            "          CANCEL\n" +
            "        </button>\n" +
            "      </div>\n" +
            "\n" +
            "      <table class=\"icn-table\"\n" +
            "             name=\"subscriptionReviewExportsTable\"\n" +
            "             id=\"subscriptionReviewExportsTable\"\n" +
            "             ng-show=\"generatedExports.length > 0\">\n" +
            "        <thead>\n" +
            "          <th>ID</th>\n" +
            "          <th ng-if=\"showCloseDate()\">Close Date</th>\n" +
            "          <th>Generated At</th>\n" +
            "          <th>Options</th>\n" +
            "        </thead>\n" +
            "        <tbody>\n" +
            "          <tr ng-repeat=\"export in generatedExports | orderBy: '-created_at'\">\n" +
            "            <td>{{export.id}}</td>\n" +
            "            <td ng-if=\"showCloseDate()\">{{export.close_date}}</td>\n" +
            "            <td>{{export.created_at | date:'yyyy-MM-dd HH:mm:ss'}}</td>\n" +
            "            <td><a target=\"_self\" href=\"{{export.generic_file.url}}\" download>Download</a></td>\n" +
            "          </tr>\n" +
            "        </tbody>\n" +
            "      </table>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("dialogs/text_input_dialog/text_input_dialog.html", "<div class=\"modalContainer\">\n" +
            "    <div class=\"modalHeaderBar\">\n" +
            "        <div class=\"modalCloseButton\">\n" +
            "            <a id=\"closeButton\" class=\"btn\" ng-click=\"cancel()\"><i class=\"fa fa-times fa-2\"></i></a>\n" +
            "        </div>\n" +
            "    </div>\n" +
            "    <div class=\"modalContent\">\n" +
            "        <form class=\"form-horizontal\">\n" +
            "            <h2>{{prompt}}</h2>\n" +
            "            <div class=\"modal-form form-group\">\n" +
            "              <input type='text' ng-model=\"inputs.text\" style=\"width:100%\"/>\n" +
            "            </div>\n" +
            "        </form>\n" +
            "    </div>\n" +
            "    <div class=\"modal-actions-section\">\n" +
            "        <button ng-click=\"submit()\">Submit</button>\n" +
            "        <button ng-click=\"cancel()\">Cancel</button>\n" +
            "    </div>\n" +
            "</div>");

        $templateCache.put("dialogs/transfer_dialog/update_effective_date_dialog.html", "<common-modal class=\"transfer-dialog\" modal-header=\"Update Effective Date\">\n" +
            "  <div class=\"transfer-effective_date_dialog_content\">\n" +
            "    <div class=\"transfer-dialog_content_top\">\n" +
            "      <div class=\"transfer-dialog_row\">\n" +
            "        <div class=\"transfer-dialog_column\">\n" +
            "          <div class=\"transfer-dialog_column_title\">Effective Transfer Date</div>\n" +
            "          <div class=\"transfer-dialog_column_label\">Future dates only</div>\n" +
            "        </div>\n" +
            "        <div class=\"transfer-dialog_column col-right\">\n" +
            "          <icn-dropdown-wrapper>\n" +
            "            <select id=\"transfer-dialog_effective_date_month\" name=\"effective_date_month\" ng-model=\"transfer.effective_date_month\">\n" +
            "              <option ng-repeat=\"month in months | filter: filterMonth\" ng-value=\"month.id\" ng-selected=\"{{month.id == transfer.effective_date_month}}\" ng-disabled=\"invalidMonth(month)\">{{month.name}}</option>\n" +
            "            </select>\n" +
            "          </icn-dropdown-wrapper>\n" +
            "          <icn-dropdown-wrapper>\n" +
            "            <select id=\"transfer-dialog_effective_date_year\" name=\"effective_date_year\" ng-model=\"transfer.effective_date_year\">\n" +
            "              <option ng-repeat=\"year in years\" ng-value=\"year\" ng-selected=\"{{year == transfer.effective_date_year}}\">{{year}}</option>\n" +
            "            </select>\n" +
            "          </icn-dropdown-wrapper>\n" +
            "          <div ng-if=\"canShowEndOfQuarterCheckbox()\">\n" +
            "            <input type=\"checkbox\"\n" +
            "                   class=\"cb-end-of-quarter\"\n" +
            "                   name=\"is_end_of_quarter\"\n" +
            "                   ng-model=\"transfer.is_end_of_quarter\">\n" +
            "            <span>Is this an end of quarter transfer?</span>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "      <div class=\"transfer-dialog_footer row\">\n" +
            "        <button class=\"pull-right\" ng-click=\"updateEffectiveDate()\">UPDATE</button>\n" +
            "        <button class=\"pull-right button__cancel\" ng-click=\"cancel()\">CANCEL</button>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("dialogs/upload_doc_dialog/upload_doc_dialog.html", "<common-modal modal-header=\"{{header}}\">\n" +
            "  <div ng-switch=\"uploadStatus\" class=\"upload-status\">\n" +
            "    <div class=\"no-extra-margin\">\n" +
            "      <p>Upload a signed subscription agreement for <strong>{{investment.investor_profile.name}}</strong> for <strong>{{investment.fund_name}}</strong>.</p>\n" +
            "      <div class=\"callout callout-warning\">\n" +
            "        <p><strong>NOTE:</strong> This action will <strong>overwrite</strong> any existing subscription agreement for this investment.</p>\n" +
            "      </div>\n" +
            "      <div class=\"upload-container\">\n" +
            "\n" +
            "        <button ng-click=\"picker()\" ng-switch-when=\"none\" id=\"upload-select-btn\" class=\"file-upload upload-border\">\n" +
            "          <input onclick=\"event.stopPropagation()\" class=\"file-input\" type=\"file\" id=\"subdoc-file{{investment.id}}\" name='subdoc-file{{investment.id}}' onchange=\"angular.element(this).scope().uploadDoc(this.files)\" />\n" +
            "          <img src=\"images/drag_drop.svg\" height=\"30\" width=\"48\">\n" +
            "          <div class=\"sm-mrg\">Drop file here or <br><a>browse files</a></div>\n" +
            "        </button>\n" +
            "\n" +
            "        <div ng-switch-when=\"loading\" class=\"loader-container\"><div class=\"loader\"></div></div>\n" +
            "\n" +
            "        <div ng-switch-when=\"complete\" class=\"vert-box\">\n" +
            "          <div style=\"display:inline-block;\">\n" +
            "            <img class=\"check-splash\" src=\"images/checkmark.svg\">\n" +
            "            <div class=\"check-splash-text\">Upload Successful</div>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "\n" +
            "        <button ng-click=\"picker()\" ng-switch-when=\"success\"  id=\"upload-select-btn\" class=\"file-upload upload-border\" ng-class=\"{transparent : fade}\">\n" +
            "          <input onclick=\"event.stopPropagation()\" class=\"file-input\" type=\"file\" id=\"subdoc-file{{investment.id}}\" name='subdoc-file{{investment.id}}' onchange=\"angular.element(this).scope().uploadDoc(this.files)\" />\n" +
            "          <div class=\"vert-box left-box\">\n" +
            "            <div class=\"vert-box bkgrnd\">\n" +
            "              <img ng-if=\"preview\" ng-src=\"{{preview}}\">\n" +
            "              <img ng-if=\"!preview\" ng-src=\"images/sub_thumb.png\">\n" +
            "            </div>\n" +
            "          </div>\n" +
            "          <div class=\"right-box\">\n" +
            "            <span>Uploaded</span>\n" +
            "            <div></div>\n" +
            "            <a>Upload new file</a>\n" +
            "          </div>\n" +
            "        </button>\n" +
            "\n" +
            "        <button ng-click=\"picker()\" ng-switch-when=\"error\" id=\"upload-select-btn\" class=\"file-upload upload-border transparent\">\n" +
            "          <input onclick=\"event.stopPropagation()\" class=\"file-input\" type=\"file\" id=\"subdoc-file{{investment.id}}\" name='subdoc-file{{investment.id}}' onchange=\"angular.element(this).scope().uploadDoc(this.files)\" />\n" +
            "          <div class=\"upload-error\">There was an error uploading your document. Please upload a file in .pdf format.</div>\n" +
            "          <div class=\"sm-mrg\">Drop file here or <a>browse files</a></div>\n" +
            "        </button>\n" +
            "\n" +
            "      </div>\n" +
            "    </div>\n" +
            "    <div class=\"clearfix\">\n" +
            "      <button class=\"right-aligned-btn\" ng-click=\"cancelDialog()\" ng-disabled=\"!docUploadedSuccess()\">Done</button>\n" +
            "      <button class=\"cancel-btn right-aligned-btn\" ng-click=\"cancelDialog(true)\">Cancel</button>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("dialogs/upload_hf_accounting_report_dialog/upload_dialog.html", "<common-modal modal-header=\"Accounting Report Upload\" class=\"share-class-dialog\">\n" +
            "  <div ng-switch=\"uploadStatus\">\n" +
            "    <div class=\"no-extra-margin\">\n" +
            "      <div class=\"upload-container\">\n" +
            "        <button ng-click=\"picker()\" ng-switch-when=\"none\" id=\"upload-select-btn\" class=\"file-upload upload-border\">\n" +
            "          <input onclick=\"event.stopPropagation()\" class=\"file-input\" type=\"file\" id=\"bulk-upload\" name='bulk_upload'\n" +
            "                 onchange=\"angular.element(this).scope().uploadInvestorsReport(this.files)\" />\n" +
            "          <img src=\"images/drag_drop.svg\" height=\"30\" width=\"48\">\n" +
            "          <div class=\"sm-mrg\">Drop file here or browse files</div>\n" +
            "          <div class=\"sm-mrg\">Accepted file format include CSV</div>\n" +
            "        </button>\n" +
            "\n" +
            "        <div ng-switch-when=\"loading\" class=\"loader-container\"><div class=\"loader\"></div></div>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "    <a class=\"bulk_template_link\" href=\"#\" ng-click=\"downloadTemplate()\">Download the template file</a>\n" +
            "  </div>\n" +
            "  <div ng-if=\"bulkUploadResults.length > 0\" class=\"bulk_upload_latest_results\">\n" +
            "    <span class=\"bulk_upload_latest_results_tittle\">Latest Results</span>\n" +
            "    <div class=\"scrollable bulk_upload_results\">\n" +
            "      <table class=\"table-icn\">\n" +
            "        <tr>\n" +
            "          <th>File</th>\n" +
            "          <th>Created at</th>\n" +
            "        </tr>\n" +
            "        <tr ng-repeat=\"doc in bulkUploadResults\">\n" +
            "          <td><a href=\"{{doc.file_url}}\"}>{{doc.filename}}</a></td>\n" +
            "          <td>{{doc.created_at | date: 'short'}}</td>\n" +
            "        </tr>\n" +
            "      </table>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"clearfix\">\n" +
            "    <button class=\"right-aligned-btn\" ng-click=\"closeModal()\">Close</button>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("dialogs/upload_pe_accounting_report_dialog/upload_dialog.html", "<common-modal modal-header=\"Accounting Report Upload\" class=\"share-class-dialog\">\n" +
            "  <div ng-switch=\"uploadStatus\">\n" +
            "    <div class=\"no-extra-margin\">\n" +
            "      <div class=\"upload-container\">\n" +
            "        <label ng-click=\"picker()\" ng-switch-when=\"none\" id=\"upload-select-btn\"\n" +
            "                class=\"file-upload upload-border upload-button-label\" role=\"button\">\n" +
            "          <input onclick=\"event.stopPropagation()\" class=\"file-input\" type=\"file\" id=\"bulk-upload\"\n" +
            "                 name='bulk_upload'\n" +
            "                 onchange=\"angular.element(this).scope().uploadInvestorsReport(this.files)\"/>\n" +
            "          <img src=\"images/drag_drop.svg\" height=\"30\" width=\"48\">\n" +
            "          <div class=\"sm-mrg\">Drop file here or browse files</div>\n" +
            "          <div class=\"sm-mrg\">Accepted file format include CSV</div>\n" +
            "        </label>\n" +
            "        <div ng-switch-when=\"loading\" class=\"loader-container\">\n" +
            "          <div class=\"loader\"></div>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "    <a class=\"bulk_template_link\" href=\"#\" ng-click=\"downloadTemplate()\">Download the template file</a>\n" +
            "  </div>\n" +
            "  <div ng-if=\"bulkUploadResults.length > 0\" class=\"bulk_upload_latest_results\">\n" +
            "    <span class=\"bulk_upload_latest_results_tittle\">Latest Results</span>\n" +
            "    <div class=\"scrollable bulk_upload_results\">\n" +
            "      <table class=\"table-icn\">\n" +
            "        <tr>\n" +
            "          <th>File</th>\n" +
            "          <th>Created at</th>\n" +
            "        </tr>\n" +
            "        <tr ng-repeat=\"doc in bulkUploadResults\">\n" +
            "          <td><a href=\"{{doc.file_url}}\" }>{{doc.filename}}</a></td>\n" +
            "          <td>{{doc.created_at | date: 'short'}}</td>\n" +
            "        </tr>\n" +
            "      </table>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"clearfix\">\n" +
            "    <button class=\"right-aligned-btn\" ng-click=\"closeModal()\">Close</button>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("dialogs/upload_redemption_doc_dialog/upload_redemption_doc_dialog.html", "<common-modal modal-header=\"{{header}}\">\n" +
            "  <div ng-switch=\"uploadStatus\" class=\"upload-status\">\n" +
            "    <div class=\"no-extra-margin\">\n" +
            "      <p>Upload a signed document by {{redemption.investor.name}} for {{privateAccessFund.name}}.</p>\n" +
            "      <div class=\"callout callout-warning\">\n" +
            "        <p><strong>NOTE:</strong> This action will <strong>overwrite</strong> any existing document for this redemption.</p>\n" +
            "      </div>\n" +
            "      <div class=\"upload-container\">\n" +
            "\n" +
            "        <button ng-click=\"picker()\" ng-switch-when=\"none\" id=\"upload-select-btn\" class=\"file-upload upload-border\">\n" +
            "          <input onclick=\"event.stopPropagation()\" class=\"file-input\" type=\"file\" id=\"subdoc-file{{redemption.id}}\" name='subdoc-file{{redemption.id}}' onchange=\"angular.element(this).scope().uploadDoc(this.files)\" />\n" +
            "          <img src=\"images/drag_drop.svg\" height=\"30\" width=\"48\">\n" +
            "          <div class=\"sm-mrg\">Drop file here or <br><a>browse files</a></div>\n" +
            "        </button>\n" +
            "\n" +
            "        <div ng-switch-when=\"loading\" class=\"loader-container\"><div class=\"loader\"></div></div>\n" +
            "\n" +
            "        <div ng-switch-when=\"complete\" class=\"vert-box\">\n" +
            "          <div style=\"display:inline-block;\">\n" +
            "            <img class=\"check-splash\" src=\"images/checkmark.svg\">\n" +
            "            <div class=\"check-splash-text\">Upload Successful</div>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "\n" +
            "        <button ng-click=\"picker()\" ng-switch-when=\"success\"  id=\"upload-select-btn\" class=\"file-upload upload-border\" ng-class=\"{transparent : fade}\">\n" +
            "          <input onclick=\"event.stopPropagation()\" class=\"file-input\" type=\"file\" id=\"subdoc-file{{redemption.id}}\" name='subdoc-file{{redemption.id}}' onchange=\"angular.element(this).scope().uploadDoc(this.files)\" />\n" +
            "          <div class=\"vert-box left-box\">\n" +
            "            <div class=\"vert-box bkgrnd\">\n" +
            "              <img ng-if=\"preview\" ng-src=\"{{preview}}\">\n" +
            "              <img ng-if=\"!preview\" ng-src=\"images/sub_thumb.png\">\n" +
            "            </div>\n" +
            "          </div>\n" +
            "          <div class=\"right-box\">\n" +
            "            <span>Uploaded</span>\n" +
            "            <div></div>\n" +
            "            <a>Upload new file</a>\n" +
            "          </div>\n" +
            "        </button>\n" +
            "\n" +
            "        <button ng-click=\"picker()\" ng-switch-when=\"error\" id=\"upload-select-btn\" class=\"file-upload upload-border transparent\">\n" +
            "          <input onclick=\"event.stopPropagation()\" class=\"file-input\" type=\"file\" id=\"subdoc-file{{redemption.id}}\" name='subdoc-file{{redemption.id}}' onchange=\"angular.element(this).scope().uploadDoc(this.files)\" />\n" +
            "          <div class=\"upload-error\">There was an error uploading your document. Please try again.</div>\n" +
            "          <div class=\"sm-mrg\">Drop file here or <a>browse files</a></div>\n" +
            "        </button>\n" +
            "\n" +
            "      </div>\n" +
            "    </div>\n" +
            "    <div class=\"clearfix\">\n" +
            "      <button class=\"right-aligned-btn\" ng-click=\"cancelDialog()\">Done</button>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("dialogs/upload_transfer_doc_dialog/upload_transfer_doc_dialog.html", "<common-modal modal-header=\"{{header}}\">\n" +
            "  <div ng-switch=\"uploadStatus\" class=\"upload-status\">\n" +
            "    <div class=\"no-extra-margin\">\n" +
            "      <p>Upload a signed transfer document by {{transfereeInvestment.investor_profile.name}} for {{transfereeInvestment.fund_name}}.</p>\n" +
            "      <div class=\"callout callout-warning\">\n" +
            "        <p><strong>NOTE:</strong> This action will <strong>overwrite</strong> any existing transfer document for this investment.</p>\n" +
            "      </div>\n" +
            "      <div class=\"upload-container\">\n" +
            "\n" +
            "        <button ng-click=\"picker()\" ng-switch-when=\"none\" id=\"upload-select-btn\" class=\"file-upload upload-border\">\n" +
            "          <input onclick=\"event.stopPropagation()\" class=\"file-input\" type=\"file\" id=\"subdoc-file{{transfereeInvestment.id}}\" name='subdoc-file{{transfereeInvestment.id}}' onchange=\"angular.element(this).scope().uploadDoc(this.files)\" />\n" +
            "          <img src=\"images/drag_drop.svg\" height=\"30\" width=\"48\">\n" +
            "          <div class=\"sm-mrg\">Drop file here or <br><a>browse files</a></div>\n" +
            "        </button>\n" +
            "\n" +
            "        <div ng-switch-when=\"loading\" class=\"loader-container\"><div class=\"loader\"></div></div>\n" +
            "\n" +
            "        <div ng-switch-when=\"complete\" class=\"vert-box\">\n" +
            "          <div style=\"display:inline-block;\">\n" +
            "            <img class=\"check-splash\" src=\"images/checkmark.svg\">\n" +
            "            <div class=\"check-splash-text\">Upload Successful</div>\n" +
            "          </div>\n" +
            "        </div>\n" +
            "\n" +
            "        <button ng-click=\"picker()\" ng-switch-when=\"success\"  id=\"upload-select-btn\" class=\"file-upload upload-border\" ng-class=\"{transparent : fade}\">\n" +
            "          <input onclick=\"event.stopPropagation()\" class=\"file-input\" type=\"file\" id=\"subdoc-file{{transfereeInvestment.id}}\" name='subdoc-file{{transfereeInvestment.id}}' onchange=\"angular.element(this).scope().uploadDoc(this.files)\" />\n" +
            "          <div class=\"vert-box left-box\">\n" +
            "            <div class=\"vert-box bkgrnd\">\n" +
            "              <img ng-if=\"preview\" ng-src=\"{{preview}}\">\n" +
            "              <img ng-if=\"!preview\" ng-src=\"images/sub_thumb.png\">\n" +
            "            </div>\n" +
            "          </div>\n" +
            "          <div class=\"right-box\">\n" +
            "            <span>Uploaded</span>\n" +
            "            <div></div>\n" +
            "            <a>Upload new file</a>\n" +
            "          </div>\n" +
            "        </button>\n" +
            "\n" +
            "        <button ng-click=\"picker()\" ng-switch-when=\"error\" id=\"upload-select-btn\" class=\"file-upload upload-border transparent\">\n" +
            "          <input onclick=\"event.stopPropagation()\" class=\"file-input\" type=\"file\" id=\"subdoc-file{{transfereeInvestment.id}}\" name='subdoc-file{{transfereeInvestment.id}}' onchange=\"angular.element(this).scope().uploadDoc(this.files)\" />\n" +
            "          <div class=\"upload-error\">There was an error uploading your document. Please try again.</div>\n" +
            "          <div class=\"sm-mrg\">Drop file here or <a>browse files</a></div>\n" +
            "        </button>\n" +
            "\n" +
            "      </div>\n" +
            "    </div>\n" +
            "    <div class=\"clearfix\">\n" +
            "      <button class=\"right-aligned-btn\" ng-click=\"cancelDialog()\">Done</button>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("directives/domain_toggle/domain_toggle.html", "<div class=\"domain-toggle__container\" ng-click=\"toggleMenu()\">\n" +
            "    <svg class=\"domain-toggle__circle\" ng-class=\"{'domain-toggle__circle--active': menuVisible}\" focusable=\"false\" viewBox=\"0 0 24 24\">\n" +
            "        <path d=\"M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z\"></path>\n" +
            "    </svg>\n" +
            "\n" +
            "    <div class=\"domain-toggle__dropdown\" ng-show=\"menuVisible\">\n" +
            "        <div class=\"domain-toggle__dropdown__title\">\n" +
            "            PLATFORM\n" +
            "        </div>\n" +
            "        <ul class=\"domain-toggle__dropdown__list\">\n" +
            "            <li class=\"domain-toggle__dropdown__item\">\n" +
            "                <div class=\"domain-toggle__item__box domain-toggle__item__box-selected\">\n" +
            "                    <div class=\"domain-toggle__description\">\n" +
            "                        Alternative Investments\n" +
            "                    </div>\n" +
            "                    <div class=\"domain-toggle__item__icon__box\">\n" +
            "                        <svg class=\"domain-toggle__item__icon\" focusable=\"false\" aria-hidden=\"true\" viewBox=\"0 0 24 24\" data-testid=\"CheckIcon\"><path d=\"M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z\"></path></svg>\n" +
            "                    </div>\n" +
            "                </div>\n" +
            "                <div class=\"domain-toggle__item__box\" ng-click=\"togglePlatform()\">\n" +
            "                    <div class=\"domain-toggle__description\">\n" +
            "                        Structured Investments, Annuities, BETA+, and ETFs\n" +
            "                    </div>\n" +
            "                </div>\n" +
            "            </li>\n" +
            "        </ul>\n" +
            "    </div>\n" +
            "</div>");

        $templateCache.put("directives/fund_selector/fund_selector.html", "<div class=\"vertical-margin fund-selector\" ng-class=\"{'row': !displayInline}\">\n" +
            "  <div ng-class=\"{'col-md-4': !displayInline, 'no-padding icn-select': nonAdmin}\">\n" +
            "    <span class=\"pointer\"></span>\n" +
            "    <ui-select ng-model=\"selectorFund\" on-select=\"selectFund($item)\" name=\"fund_selector\">\n" +
            "      <ui-select-match allow-clear=\"true\" placeholder=\"--- All Funds ---\">{{$select.selected.name}}</ui-select-match>\n" +
            "      <ui-select-choices group-by=\"'status'\" repeat=\"fund in fundSelectorService.funds | orderBy: ['status', 'name'] | propsFilter: {name: $select.search}\">\n" +
            "        <div ng-bind-html=\"fund.name | highlight: $select.search\" ui-disable-choice=\"fund.disabled\" ng-show=\"!fund.disabled || showDisabled\"></div>\n" +
            "      </ui-select-choices>\n" +
            "    </ui-select>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("directives/icn_checkbox/icn_checkbox.html", "<div class=\"icn-checkbox-container\">\n" +
            "  <label for=\"{{customId}}\">\n" +
            "    <input id=\"{{customId}}\" type=\"checkbox\" ng-model=\"model\">\n" +
            "    <div id=\"checkbox\" class=\"icn-fake-checkbox\" for=\"{{customId}}\"></div>\n" +
            "    <span>{{label}}</span>\n" +
            "  </label>\n" +
            "</div>");

        $templateCache.put("directives/icn_multiselect/icn_multiselect.html", "<div\n" +
            "  class=\"icn-multiselect dropdown {{ dropdownClass }}\"\n" +
            "  style=\"width: {{ width || '100%' }}\"\n" +
            ">\n" +
            "  <button\n" +
            "    class=\"btn icn-multiselect-btn\"\n" +
            "    ng-disabled=\"disabled\"\n" +
            "    type=\"button\"\n" +
            "    ng-click=\"handleToggleDropdown($event)\"\n" +
            "  >\n" +
            "    <span>\n" +
            "      {{ label }}\n" +
            "\n" +
            "      <span ng-if=\"selected.length > 0\">\n" +
            "        <span ng-if=\"selectedText\">({{ selected.length }} {{ selectedText }})</span>\n" +
            "        <span ng-if=\"!selectedText\">({{ selected.length }})</span>\n" +
            "      </span>\n" +
            "    </span>\n" +
            "\n" +
            "    <div class=\"caret\"></div>\n" +
            "  </button>\n" +
            "  <ul\n" +
            "    class=\"dropdown-menu\"\n" +
            "    role=\"menu\"\n" +
            "  >\n" +
            "    <li\n" +
            "      ng-if=\"hasAction\"\n" +
            "      class=\"action\"\n" +
            "      role=\"presentation\"\n" +
            "    >\n" +
            "      <label class=\"checkbox-row\" ng-click=\"action.callback()\">\n" +
            "        <div class=\"checkbox-row__right\">\n" +
            "          <span>{{ action.label }}</span>\n" +
            "        </div>\n" +
            "      </label>\n" +
            "    </li>\n" +
            "    <li\n" +
            "      ng-if=\"hasAction\"\n" +
            "      class=\"divider\"\n" +
            "    >\n" +
            "      <div></div>\n" +
            "    </li>\n" +
            "    <li\n" +
            "      ng-if=\"options.length <= 0\"\n" +
            "      class=\"no-options\"\n" +
            "      role=\"presentation\"\n" +
            "    >\n" +
            "      <label class=\"checkbox-row\">\n" +
            "        <div class=\"checkbox-row__right\">\n" +
            "          <span>No options available.</span>\n" +
            "        </div>\n" +
            "      </label>\n" +
            "    </li>\n" +
            "    <li\n" +
            "      ng-if=\"options.length > 0\"\n" +
            "      class=\"select-all\"\n" +
            "      role=\"presentation\"\n" +
            "    >\n" +
            "      <label\n" +
            "        class=\"checkbox-row\"\n" +
            "        ng-click=\"handleSelectorStatusClick($event)\"\n" +
            "      >\n" +
            "        <div class=\"checkbox-row__left\">\n" +
            "          <div class=\"checkbox-row__box checkbox-row__box--{{selectorStatusSuffix}}\"></div>\n" +
            "        </div>\n" +
            "        <div class=\"checkbox-row__right\">\n" +
            "          <span>Select All</span>\n" +
            "        </div>\n" +
            "      </label>\n" +
            "    </li>\n" +
            "    <li\n" +
            "      ng-if=\"options.length > 0\"\n" +
            "      class=\"divider\"\n" +
            "    >\n" +
            "      <div></div>\n" +
            "    </li>\n" +
            "    <li\n" +
            "      ng-repeat=\"item in options track by $index\"\n" +
            "      role=\"presentation\"\n" +
            "    >\n" +
            "      <label class=\"checkbox-row\">\n" +
            "        <div class=\"checkbox-row__left\">\n" +
            "          <input\n" +
            "            type=\"checkbox\"\n" +
            "            ng-model=\"selectedModel[item[optionKey]]\"\n" +
            "            ng-change=\"handleItemSelection(item)\"\n" +
            "            ng-disabled=\"isItemDisabled(item)\"\n" +
            "          />\n" +
            "          <div class=\"checkbox-row__box\"></div>\n" +
            "        </div>\n" +
            "        <div class=\"checkbox-row__right\">\n" +
            "          <span>{{ item[optionLabel] }}</span>\n" +
            "        </div>\n" +
            "      </label>\n" +
            "    </li>\n" +
            "  </ul>\n" +
            "</div>");

        $templateCache.put("directives/icn_radio/icn_radio.html", "<label class=\"radio-row\">\n" +
            "  <div class=\"radio-row__left\">\n" +
            "    <input\n" +
            "      type=\"radio\"\n" +
            "      ng-model=\"model\"\n" +
            "      ng-value=\"key\"\n" +
            "    />\n" +
            "    <div class=\"radio-row__box\"></div>\n" +
            "  </div>\n" +
            "  <div class=\"radio-row__right\">\n" +
            "    <span>{{ label }}</span>\n" +
            "  </div>\n" +
            "</label>");

        $templateCache.put("directives/icn_select/icn_select.html", "<div class=\"icn-select dropdown {{dropdownClass}}\" style=\"width: {{width || '100%'}}\">\n" +
            "  <button class=\"btn icn-select-btn\" ng-class=\"{'selected': (!!model || !!defaultValue)}\" ng-disabled=\"disabled\" type=\"button\" ng-click=\"onClick($event)\" title={{getButtonTitle()}}>\n" +
            "    {{label}}\n" +
            "    <span class=\"icn-select_value\">{{selectedLabel}}</span>\n" +
            "    <span ng-hide=\"hideCaret\" class=\"caret\"></span>\n" +
            "  </button>\n" +
            "  <ul class=\"dropdown-menu\" role=\"menu\" style=\"width: {{width || '100%'}}\">\n" +
            "    <li ng-repeat=\"item in options track by $index\" role=\"presentation\" ng-hide=\"hideItem(item)\">\n" +
            "      <a role=\"menuitem\" tabindex=\"-1\" href=\"#\" data-object-id=\"{{getObjectId(item)}}\" ng-click=\"setValue(item)\" title=\"{{getDropdownTitle(item)}}\">{{getLabel(item)}}</a>\n" +
            "    </li>\n" +
            "  </ul>\n" +
            "</div>\n" +
            "<input type=\"hidden\" name=\"{{name}}\" ng-model=\"model\"/>");

        $templateCache.put("directives/paf_list_item/paf_list_item.html", "<div class=\"heading-5 paf-list-item-strategy\">{{displayStrategy}}</div>\n" +
            "<div class=\"row paf-list-item-box\">\n" +
            "  <a class=\"default-hover-underline\" ng-click=\"viewFundProfile($event)\">\n" +
            "    <div class=\"row\" ng-class=\"multiManagerFund ? 'paf-list-item-multi-logo' : 'paf-list-item-logo'\" style=\"margin: auto;\">\n" +
            "      <img ng-src=\"{{logoUrl()}}\">\n" +
            "    </div>\n" +
            "  </a>\n" +
            "  <div class=\"paf-list-item-details\">\n" +
            "    <div>\n" +
            "      <div class=\"content-default\">Fund Name</div>\n" +
            "      <div title=\"{{name}}\" class=\"content-small-gray paf-list-item-name\">{{name}}</div>\n" +
            "    </div>\n" +
            "    <div class=\"paf-list-item-manager\">\n" +
            "      <div class=\"content-default\">Underlying Fund Manager</div>\n" +
            "      <div class=\"content-small-gray paf-list-item-name\">{{manager}}</div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"paf-list-item-links\">\n" +
            "    <div class=\"view-profile-link\">\n" +
            "      <img class=\"view-profile-icon\" src=\"/images/icon_viewprofile.svg\"></span>\n" +
            "      <a class=\"link-5 default-hover-underline\"  id=\"view-profile-link\" ng-click=\"viewFundProfile($event)\">View Profile</a>\n" +
            "    </div>\n" +
            "    <div ng-show=\"showInvest()\">\n" +
            "      <img class=\"investment-action-icon\" src=\"/images/icon_invite.svg\">\n" +
            "      <a class=\"link-5 default-hover-underline\" id=\"investment-action-link\" ng-click=\"viewInvestmentDetails($event)\">{{viewInvestmentDetailsText}}</a>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("directives/quarter_selector/quarter_selector.html", "<div class=\"vertical-margin\" ng-class=\"{'row': !displayInline}\">\n" +
            "  <div class=\"col-md-3\">\n" +
            "    <select class=\"form-control\"\n" +
            "            name='quarter-selector'\n" +
            "            ng-model=\"selectedQuarter\"\n" +
            "            ng-options=\"quarter_id as quarter_id for (quarter_id, end_date) in quarters\">\n" +
            "        <option value=\"\">--- Choose quarter ---</option>\n" +
            "    </select>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("services/register/dialogs/terms_and_conditions/terms_and_conditions.html", "<common-modal modal-header=\"Terms and Conditions\">\n" +
            "  <div ng-include=\"'areas/static/terms_of_use.html'\"></div>\n" +
            "</common-modal>");

        $templateCache.put("templates/bootstrap/datepicker/datepicker.html", "<table>\n" +
            "  <thead>\n" +
            "    <tr>\n" +
            "      <th><button class=\"btn btn-default btn-sm pull-left\" ng-click=\"move(-1)\"><i class=\"fa fa-chevron-left\"></i></button></th>\n" +
            "      <th colspan=\"{{rows[0].length - 2 + showWeekNumbers}}\"><button class=\"btn btn-default btn-sm btn-block\" ng-click=\"toggleMode()\"><strong>{{title}}</strong></button></th>\n" +
            "      <th><button class=\"btn btn-default btn-sm pull-right\" ng-click=\"move(1)\"><i class=\"fa fa-chevron-right\"></i></button></th>\n" +
            "    </tr>\n" +
            "    <tr ng-show=\"labels.length > 0\" class=\"h6\">\n" +
            "      <th ng-show=\"showWeekNumbers\" class=\"text-center\">#</th>\n" +
            "      <th ng-repeat=\"label in labels\" class=\"text-center\">{{label}}</th>\n" +
            "    </tr>\n" +
            "  </thead>\n" +
            "  <tbody>\n" +
            "    <tr ng-repeat=\"row in rows\">\n" +
            "      <td ng-show=\"showWeekNumbers\" class=\"text-center\"><em>{{ getWeekNumber(row) }}</em></td>\n" +
            "      <td ng-repeat=\"dt in row\" class=\"text-center\">\n" +
            "        <button style=\"width:100%;\" class=\"btn btn-default btn-sm\" ng-class=\"{'btn-info': dt.selected}\" ng-click=\"select(dt.date)\" ng-disabled=\"dt.disabled\"><span ng-class=\"{'text-muted': dt.secondary}\">{{dt.label}}</span></button>\n" +
            "      </td>\n" +
            "    </tr>\n" +
            "  </tbody>\n" +
            "</table>");

        $templateCache.put("templates/bootstrap/datepicker/popup.html", "<ul class=\"dropdown-menu\"\n" +
            "    ng-style=\"{display: (isOpen && 'block') || 'none', top: position.top+'px', left: position.left+'px', width: '300px'}\">\n" +
            "    <li ng-transclude></li>\n" +
            "    <li ng-show=\"showButtonBar\" style=\"padding:10px 9px 2px\">\n" +
            "            <span class=\"btn-group\">\n" +
            "            <button class=\"btn btn-sm btn-info\" ng-click=\"today()\">{{currentText}}</button>\n" +
            "            <button class=\"btn btn-sm btn-default\" ng-click=\"showWeeks = ! showWeeks\" ng-class=\"{active: showWeeks}\">\n" +
            "                {{toggleWeeksText}}\n" +
            "            </button>\n" +
            "            <button class=\"btn btn-sm btn-danger\" ng-click=\"clear()\">{{clearText}}</button>\n" +
            "        </span>\n" +
            "        <button class=\"btn btn-sm btn-success pull-right\" ng-click=\"isOpen = false\">{{closeText}}</button>\n" +
            "    </li>\n" +
            "</ul>");

        $templateCache.put("templates/bootstrap/popover/popover-html-unsafe-popup.html", "<div class=\"popover {{placement}}\" ng-class=\"{ in: isOpen(), fade: animation() }\">\n" +
            "  <div class=\"arrow\"></div>\n" +
            "\n" +
            "  <div class=\"popover-inner\">\n" +
            "      <h3 class=\"popover-title\" ng-bind=\"title\" ng-show=\"title\"></h3>\n" +
            "      <div class=\"popover-content\" ng-bind-html=\"content\"></div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("templates/bootstrap/popover/popover.html", "<div class=\"popover {{placement}}\" ng-class=\"{ in: isOpen(), fade: animation() }\">\n" +
            "  <div class=\"arrow\"></div>\n" +
            "  {{customerInfo.vara}}\n" +
            "  <div class=\"popover-inner\">\n" +
            "      <h3 class=\"popover-title\" ng-bind=\"title\" ng-show=\"title\"></h3>\n" +
            "      <div class=\"popover-content\" ng-include=\"content\"></div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("templates/bootstrap/tooltip/tooltip-html-unsafe-popup.html", "<div class=\"tooltip {{placement}}\" ng-class=\"{ in: isOpen(), fade: animation() }\">\n" +
            "  <div class=\"tooltip-arrow\"></div>\n" +
            "  <div class=\"tooltip-inner\" bind-html-unsafe=\"content\"></div>\n" +
            "</div>");

        $templateCache.put("templates/bootstrap/tooltip/tooltip-popup.html", "<div class=\"tooltip {{placement}}\" ng-class=\"{ in: isOpen(), fade: animation() }\">\n" +
            "  <div class=\"tooltip-arrow\"></div>\n" +
            "  <div class=\"tooltip-inner\" ng-bind=\"content\"></div>\n" +
            "</div>");

        $templateCache.put("templates/bootstrap/typeahead/typeahead-match.html", "<a tabindex=\"-1\" bind-html-unsafe=\"match.label | typeaheadHighlight:query\"></a>");

        $templateCache.put("templates/bootstrap/typeahead/typeahead-popup.html", "<ul class=\"typeahead dropdown-menu\" ng-style=\"{display: isOpen()&&'block' || 'none', top: position.top+'px', left: position.left+'px'}\">\n" +
            "	<li ng-repeat=\"match in matches\" ng-class=\"{active: isActive($index) }\" ng-mouseenter=\"selectActive($index)\" ng-click=\"selectMatch($index)\">\n" +
            "	  <typeahead-match index=\"$index\" match=\"match\" query=\"query\" template-url=\"templateUrl\"></typeahead-match>\n" +
            "	</li>\n" +
            "</ul>");

        $templateCache.put("templates/components/banner_text.html", "<div class=\"banner-text\" ng-bind-html=\"$ctrl.bannerText\"></banner-text>");

        $templateCache.put("templates/components/header_container.html", "<div class=\"header\">\n" +
            "  <div ng-controller=\"MenuCtrl\" class=\"header__container container\">\n" +
            "    <div class=\"header__logo-container\">\n" +
            "      <a\n" +
            "        id=\"dashboard-link\"\n" +
            "        class=\"header__dashboard_link\"\n" +
            "        ng-href=\"{{pendingMfaRegistration ? 'logout' : menu.main_menu.main.href}}\"\n" +
            "      >\n" +
            "        <img\n" +
            "          ng-src=\"{{$ctrl.headerLogoSrc}}\"\n" +
            "          class=\"{{isMainSite() ? 'header__logo' : 'header__logo--wlp'}}\"\n" +
            "        />\n" +
            "        <span\n" +
            "          ng-if=\"$ctrl.environmentLabel != 'production'\"\n" +
            "          class=\"header__environment_label\"\n" +
            "          >{{$ctrl.environmentLabel}}</span\n" +
            "        >\n" +
            "      </a>\n" +
            "      <div class=\"header__domain_nav\">\n" +
            "        <domain-toggle ng-if=\"canToggleDomains\"></domain-toggle>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "    <main-menu ng-if=\"$ctrl.loggedIn()\"></main-menu>\n" +
            "    <user-menu ng-if=\"$ctrl.loggedIn()\"></user-menu>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("templates/components/impersonation_header.html", "<div ng-if=\"!routeLoading && $ctrl.loggedIn() && $ctrl.currentlyImpersonating()\" class=\"impersonation-header-container\">\n" +
            "  <div id=\"impersonation-header\">\n" +
            "    <div class=\"message-container\">\n" +
            "      <svg class=\"alert-icon\" focusable=\"false\" aria-hidden=\"true\" viewBox=\"0 0 24 24\" data-testid=\"ReportProblemOutlinedIcon\"><path d=\"M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z\"></path></svg>\n" +
            "      <span class=\"text\">{{$ctrl.impersonateText()}} {{$ctrl.userDisplayName}}</span>.\n" +
            "    </div>\n" +
            "    <button class=\"stop-impersonating\" ng-click=\"$ctrl.stopImpersonate()\" name=\"btn-stop-acting\">\n" +
            "      {{$ctrl.stopImpersonateText()}}\n" +
            "    </button>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("templates/components/login_banner.html", "<div class=\"login-banner  {{$ctrl.isMainSite() ? 'wlp-banner' : ''}}\" style=\"{{$ctrl.getBannerBackgroundInlineStyles()}}\">\n" +
            "  <div class=\"row\">\n" +
            "    <div class=\"col-centered col-md-{{$ctrl.getBannerTextColSize()}}\">\n" +
            "      <a target=\"_self\" href=\"/login\" ng-if=\"$ctrl.showWlpLogo()\">\n" +
            "        <img ng-src=\"{{$ctrl.publicLogoSrc}}\" class=\"login-logo {{!!$ctrl.bannerText ? 'login-logo-with-text' : ''}}\">\n" +
            "      </a>\n" +
            "      <div class=\"banner-only-text\" ng-if=\"!!$ctrl.bannerText && !$ctrl.showWlpLogo()\">\n" +
            "        <banner-text ng-if=\"!!$ctrl.bannerText\"></banner-text>\n" +
            "      </div>\n" +
            "      <banner-text ng-if=\"!!$ctrl.bannerText && $ctrl.showWlpLogo()\"></banner-text>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("templates/components/main_content.html", "<div id=\"wrap\">\n" +
            "  <header ng-if=\"$ctrl.enableSitenavMFE() && !routeLoading && $ctrl.loggedIn()\">\n" +
            "    <div id=\"single-spa-application:unified-sitenav\"></div>\n" +
            "    <impersonation-header></impersonation-header>\n" +
            "    <incident-status-alerts data-test-id=\"isa-bars\"></incident-status-alerts>\n" +
            "  </header>\n" +
            "\n" +
            "  <header ng-if=\"!$ctrl.enableSitenavMFE() && !routeLoading && $ctrl.loggedIn()\">\n" +
            "    <header-container></header-container>\n" +
            "    <impersonation-header></impersonation-header>\n" +
            "    <incident-status-alerts data-test-id=\"isa-bars\"></incident-status-alerts>\n" +
            "  </header>\n" +
            "\n" +
            "  <header class=\"login-header\" style=\"display: none\" ng-if=\"!$ctrl.loggedIn() && $ctrl.containerFullWidth() && !$ctrl.shouldLoadNewLoginPage()\"></header>\n" +
            "  <header class=\"login-header\" ng-if=\"!routeLoading && !$ctrl.loggedIn() && !$ctrl.containerFullWidth() && !$ctrl.shouldLoadNewLoginPage()\">\n" +
            "    <login-banner></login-banner>\n" +
            "  </header>\n" +
            "\n" +
            "  <div id=\"content-container\" class=\"container fixed-container\" ng-class=\"{'public-container': publicPage, 'container--fund-profile': onFundProfile, 'wider-container': widerContainer}\"}>\n" +
            "    <div id=\"content\" style=\"min-height: 590px;\" ng-class=\"clearfix\" ng-view=\"\" ng-if=\"!routeLoading\"></div>\n" +
            "  </div>\n" +
            "\n" +
            "  <butterbar id=\"butterbar\" ng-class=\"{ 'sitenav-mfe-butterbar': $ctrl.enableSitenavMFE() }\" ng-show=\"show || $ctrl.shouldLoadNewLoginPage()\"></butterbar>\n" +
            "\n" +
            "  <div id=\"footer__container\" class=\"container\" ng-if=\"!$ctrl.shouldLoadNewLoginPage()\">\n" +
            "    <div id=\"footer\" ng-class=\"{ 'wider-footer': widerFooter }\" ng-if=\"!routeLoading\">\n" +
            "      <icn-disclaimer class=\"footer\" ng-if=\"!routeLoading\"></icn-disclaimer>\n" +
            "      <icn-footer></icn-footer>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "\n" +
            "  <back-to-top></back-to-top>\n" +
            "<div>");

        $templateCache.put("templates/dialogs/reset_accreditation.html", "<common-modal modal-header=\"Reset Registration\">\n" +
            "  <div>\n" +
            "    <div class=\"paf-notify-inputs\">\n" +
            "      <p>\n" +
            "      Reason for resetting the Registration for {{ selected.contact_card.first_name }} {{ selected.contact_card.last_name }} \n" +
            "      </p>\n" +
            "      <div class=\"form-group no-extra-margin\" ng-form='form'>\n" +
            "        <label for=\"accreditation_reset_reason\">Note:\n" +
            "            <textarea rows=\"3\" class=\"form-control\" required id='accreditation_reset_reason' ng-model=\"resetReason.message\"></textarea>\n" +
            "        </label>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "\n" +
            "  </div>\n" +
            "\n" +
            "  <div class=\"row\">\n" +
            "    <button class=\"pull-right\" ng-click=\"resetAccreditation();\" ng-disabled='form.$invalid'>\n" +
            "      Reset Registration\n" +
            "    </button>\n" +
            "    <button class=\"button__reject\" ng-click=\"closeDialog()\">Cancel</button>\n" +
            "  </div>\n" +
            "</common-modal>");

        $templateCache.put("templates/directives/butterbar.html", "<div class=\"sk-fading-circle\">\n" +
            "  <div class=\"sk-circle1 sk-circle\"></div>\n" +
            "  <div class=\"sk-circle2 sk-circle\"></div>\n" +
            "  <div class=\"sk-circle3 sk-circle\"></div>\n" +
            "  <div class=\"sk-circle4 sk-circle\"></div>\n" +
            "  <div class=\"sk-circle5 sk-circle\"></div>\n" +
            "  <div class=\"sk-circle6 sk-circle\"></div>\n" +
            "  <div class=\"sk-circle7 sk-circle\"></div>\n" +
            "  <div class=\"sk-circle8 sk-circle\"></div>\n" +
            "  <div class=\"sk-circle9 sk-circle\"></div>\n" +
            "  <div class=\"sk-circle10 sk-circle\"></div>\n" +
            "  <div class=\"sk-circle11 sk-circle\"></div>\n" +
            "  <div class=\"sk-circle12 sk-circle\"></div>\n" +
            "</div>\n" +
            "<div class=\"waiting-animation-message\">{{butterbarConfig.message}}</div>");

        $templateCache.put("templates/directives/components.html", "<div ng-controller=\"MenuCtrl\" id=\"account-info\" class=\"drop-down header__actions\">\n" +
            "  <a id=\"user-menu-dropdown\" href='#'>\n" +
            "    <i class=\"fa fa-bars nav-hamburger-color\"></i>\n" +
            "    <div class=\"header__actions-info\">\n" +
            "      <div class=\"header__actions-name\">\n" +
            "        {{user_name | truncate : 25}}\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </a>\n" +
            "  <div class=\"dropdown-menu-spacer\"></div>\n" +
            "  <ul class=\"dropdown-menu fade\">\n" +
            "    <li role=\"presentation\" ng-repeat=\"item in menu.user_menu\" ng-if=\"!pendingMfaRegistration\">\n" +
            "      <a ng-if=\"!checkSupportForUrl(item)\" role=\"menuitem\" href=\"{{item.href}}\" target=\"{{item.target}}\">\n" +
            "        {{item.name}}\n" +
            "      </a>\n" +
            "      <a ng-if=\"checkSupportForUrl(item)\" href=\"#\" ng-click=\"openContactUsModal()\">{{item.name}}</a>\n" +
            "    </li>\n" +
            "    <li role=\"presentation\" class=\"header__actions-logout\">\n" +
            "      <a role=\"menuitem\" href=\"logout\">Log Out</a>\n" +
            "    </li>\n" +
            "  </ul>\n" +
            "</div>");

        $templateCache.put("templates/directives/country_list_dropdown.html", "<select name={{fieldName}}\n" +
            "        ng-model=\"selected\"\n" +
            "        ng-change=\"updateInvestorProfile()\"\n" +
            "        class={{classNames}}\n" +
            "        ng-class=\"{submitted:submitted, 'ng-invalid-required': isUsEntityCountry(fieldName) && (modelName == '' || modelName == null) && !isPafAdmin}\"\n" +
            "        ng-disabled=\"isReadOnlyField()\"\n" +
            "        ng-options=\"country as country.name for country in viewableCountriesArray track by country.name\"\n" +
            "        ng-required=\"checkIfRequired()\"\n" +
            "        validator=\"{{$parent.requiredValidator}}\"}>\n" +
            "    <option value=\"\" ng-if=\"defaultOption\">{{defaultOption}}</option>\n" +
            "</select>");

        $templateCache.put("templates/directives/footer.html", "<div ng-controller=\"MainCtrl\" class=\"footer-margin\">\n" +
            "  <div class=\"footer__links\">\n" +
            "    <ul class=\"list-inline\">\n" +
            "      <li>\n" +
            "        <a href=\"{{getPrivacyPolicyHref()}}\" target=\"{{loggedIn() ? '' : '_blank'}}\"\">PRIVACY POLICY</a>\n" +
            "      </li>\n" +
            "      <li>\n" +
            "        <a href=\"{{getTermsOfUseHref()}}\" target=\"{{loggedIn() ? '' : '_blank'}}\"\">TERMS OF SERVICE</a>\n" +
            "      </li>\n" +
            "      <li>\n" +
            "        <a href=\"https://icapital.com/glba-notice/\" target=\"{{loggedIn() ? '' : '_blank'}}\">GLBA NOTICE</a>\n" +
            "      </li>\n" +
            "      <li>\n" +
            "        <a href=\"/icn_react/static/disclosures\" target=\"{{loggedIn() ? '' : '_blank'}}\">DISCLOSURES</a>\n" +
            "      </li>\n" +
            "      <li ng-if=\"showThirdPartyVendors()\">\n" +
            "        <a href=\"/icn_react/static/third_party_vendors\" target=\"''\">THIRD PARTY VENDORS</a>\n" +
            "      </li>\n" +
            "      <li ng-if=\"showFooterList()\">\n" +
            "        <a href=\"http://www.icapitalnetwork.com/about-us\" target=\"_blank\">ABOUT US</a>\n" +
            "      </li>\n" +
            "      <li ng-if=\"showFooterList()\">\n" +
            "        <a href=\"http://www.icapitalnetwork.com/education\" target=\"_blank\">BLOG</a>\n" +
            "      </li>\n" +
            "      <li ng-if=\"showFooterList()\">\n" +
            "        <a href=\"http://www.icapitalnetwork.com/about-us/careers\" target=\"_blank\">CAREERS</a>\n" +
            "      </li>\n" +
            "      <li ng-if=\"showFooterList()\">\n" +
            "        <a href=\"http://www.icapitalnetwork.com/contact-us\" target=\"_blank\">CONTACT US</a>\n" +
            "      </li>\n" +
            "    </ul>\n" +
            "    <div class=\"muted\" ng-if=\"isMainSite()\">\n" +
            "      &copy; {{ currentdate | date:'yyyy'}} INSTITUTIONAL CAPITAL NETWORK, INC. ALL RIGHTS RESERVED.\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("templates/directives/fund_documents.html", "<div class=\"fundDocuments-container sidebar-section\" ng-hide=\"documents.length == 0 || hideFundDocuments\">\n" +
            "    <div class=\"sidebar-section__header\">{{title}}</div>\n" +
            "\n" +
            "    <ul class=\"fundDocuments-documentList\">\n" +
            "        <li class=\"fundDocuments-documentItem clearfix\"\n" +
            "            ng-repeat=\"document in documents | orderBy:'position':false | limitTo:limitDocuments\">\n" +
            "            <div class=\"fundDocuments-documentName pull-left\">{{document.document_type}}</div>\n" +
            "            <div class=\"pull-right\">\n" +
            "                <button class=\"button__reject button__action\" ng-click=\"viewDocument(document)\">\n" +
            "                    View\n" +
            "                </button>\n" +
            "            </div>\n" +
            "        </li>\n" +
            "    </ul>\n" +
            "    <div class=\"fundProfile-documents-toggle\" ng-show=\"documents.length > 3\">\n" +
            "        <a class=\"doc-view-more\" ng-show=\"documents.length > limitDocuments\"\n" +
            "           ng-click=\"limitDocuments = documents.length\">view more</a>\n" +
            "        <a class=\"doc-view-more\" ng-show=\"documents.length == limitDocuments\"\n" +
            "           ng-click=\"limitDocuments = 3\">view less</a>\n" +
            "    </div>\n" +
            "</div>");

        $templateCache.put("templates/directives/hedge_fund_historical_performance.html", "<table class=\"historical-performance hedge\" ng-show=\"fund.hedge_fund_historical_performance\">\n" +
            "  <thead>\n" +
            "    <th>YEAR</th>\n" +
            "    <th ng-repeat=\"month in months\">{{month | uppercase}}</th>\n" +
            "    <th tooltip=\"Year to Date\">YTD</th>\n" +
            "  </thead>\n" +
            "  <tbody>\n" +
            "    <tr ng-repeat=\"year in fund.hedge_fund_historical_performance | orderBy : 'year' : reverse = true\">\n" +
            "      <td class=\"side\">{{year.year}}</td>\n" +
            "      <td ng-repeat=\"month in months\" class=\"month\"\n" +
            "          ng-class=\"{'empty' : !year[month]}\">\n" +
            "        {{year[month] | number : 1 }}\n" +
            "      </td>\n" +
            "      <td class=\"side\" ng-class=\"{'empty' : !year.ytd}\">{{year.ytd | number : 1 }}</td>\n" +
            "    </tr>\n" +
            "  </tbody>\n" +
            "</table>");

        $templateCache.put("templates/directives/hedge_fund_historical_performance/percentage.html", "<div class='ngCellText' ng-class=\"{'fund-profile__historical-performance-blank-cell': row.getProperty(col.field) == null}\" ng-class=\"col.colIndex()\">\n" +
            "    <span ng-cell-text style=\"color: rgb(124, 150, 161);\">\n" +
            "        <span ng-switch=\"(row.getProperty(col.field) == null)\">\n" +
            "            <div ng-switch-when='false' class='center'>\n" +
            "                <div ng-show=\"parseFloat(row.getProperty(col.field)) < 0\">\n" +
            "                  ({{abs(row.getProperty(col.field))|number:1}}%)\n" +
            "                </div>\n" +
            "                <div ng-show=\"parseFloat(row.getProperty(col.field)) >= 0\">\n" +
            "                  {{row.getProperty(col.field)|number:1}}%\n" +
            "                </div>\n" +
            "            </div>\n" +
            "            <span ng-switch-when='true'>\n" +
            "                <div class=\"fund-profile__historical-performance-blank-cell\">&nbsp;</div>\n" +
            "            </span>\n" +
            "        </span>\n" +
            "    </span>\n" +
            "</div>");

        $templateCache.put("templates/directives/hedge_fund_historical_performance/year.html", "<div class='ngCellText' ng-class=\"col.colIndex()\">\n" +
            "    <span ng-cell-text style=\"color: rgb(124, 150, 161);\">\n" +
            "      <strong>{{row.getProperty(col.field)}}</strong>\n" +
            "    </span>\n" +
            "</div>");

        $templateCache.put("templates/directives/hedge_fund_historical_performance/ytd.html", "<div class='ngCellText' ng-class=\"{'fund-profile__historical-performance-blank-cell': row.getProperty(col.field) == null}\" ng-class=\"col.colIndex()\">\n" +
            "    <span ng-cell-text style=\"color: rgb(124, 150, 161);\">\n" +
            "        <span ng-switch=\"(row.getProperty(col.field) == null)\">\n" +
            "            <div ng-switch-when='false' class='center'>\n" +
            "              <div ng-show=\"parseFloat(row.getProperty(col.field)) < 0\">\n" +
            "                <strong>({{abs(row.getProperty(col.field))|number:1}}%)</strong>\n" +
            "              </div>\n" +
            "              <div ng-show=\"parseFloat(row.getProperty(col.field)) > 0\">\n" +
            "                <strong>{{row.getProperty(col.field)|number:1}}%</strong>\n" +
            "              </div>\n" +
            "            </div>\n" +
            "            <span ng-switch-when='true'>\n" +
            "                <div class=\"fund-profile__historical-performance-blank-cell\">&nbsp;</div>\n" +
            "            </span>\n" +
            "        </span>\n" +
            "    </span>\n" +
            "</div>");

        $templateCache.put("templates/directives/historical_performance.html", "<div ng-if=\"firmOwner\"\n" +
            "     class=\"fund-profile__historical-performance-container\"\n" +
            "     allowed-to-edit=\"firmOwner\"\n" +
            "     locked-for-approval=\"lockedForApproval\"\n" +
            "     fund=\"fund\"\n" +
            "     field-name=\"siblings\"\n" +
            "     on-add=\"addSibling()\"\n" +
            "     is-grid=\"true\"\n" +
            "     header-height=\"35\"\n" +
            "     row-height=\"40\"\n" +
            "     editing-mode=\"editable.editing\">\n" +
            "\n" +
            "    <div name=\"fund-profile__historical-performance\" class=\"gridStyle\" ng-grid=\"historicPerformance\" width=\"978\"></div>\n" +
            "</div>\n" +
            "\n" +
            "<ng-include name=\"fund-profile__historical-performance\" ng-if=\"!firmOwner\" src=\"'areas/fund_profile/templates/center_sections/historical_performance_table.html'\"></ng-include>");

        $templateCache.put("templates/directives/historical_performance/as_at_date.html", "<div ng-hide='editable.editing' class='ngCellText ng-scope' ng-class='col.colIndex()'>\n" +
            "    <span ng-cell-text class='ng-binding'>\n" +
            "        {{row.getProperty(col.field)|date:'shortDate'}}\n" +
            "    </span>\n" +
            "</div>\n" +
            "<input datepicker-popup\n" +
            "       datepicker-options='dateOptions'\n" +
            "       datepicker-append-to-body='true'\n" +
            "       ng-show='editable.editing'\n" +
            "       ng-class=\"'colt' + col.index\"\n" +
            "       ng-input=\"COL_FIELD\"\n" +
            "       ng-model='row.entity[col.field]' />");

        $templateCache.put("templates/directives/historical_performance/currency.html", "<div ng-hide='editable.editing' class='ngCellText' ng-class=\"col.colIndex()\">\n" +
            "    <span ng-cell-text>\n" +
            "      <span>\n" +
            "        $\n" +
            "      </span>\n" +
            "\n" +
            "        <span ng-switch=\"(row.getProperty(col.field) == 'empty' || row.getProperty(col.field) == 0)\">\n" +
            "            <span ng-switch-when='false' class='pull-right'>\n" +
            "                {{row.getProperty(col.field)|number:0}}\n" +
            "            </span>\n" +
            "            <span ng-switch-when='true' class='pull-right'>\n" +
            "                --\n" +
            "            </span>\n" +
            "        </span>\n" +
            "    </span>\n" +
            "</div>\n" +
            "<input ng-show='editable.editing' ng-class=\"'colt' + col.index\" ng-input='COL_FIELD' ng-model=\"row.entity[col.field]\"/>");

        $templateCache.put("templates/directives/historical_performance/header.html", "<div class='ngHeaderSortColumn' ng-style=\"{'cursor': col.cursor}\" ng-class=\"{ 'ngSorted': !noSortVisible }\">\n" +
            "    <div ng-click=\"col.sort($event)\" ng-class=\"'colt' + col.index\" class='ngHeaderText' tooltip=\"{{col.headerClass}}\">\n" +
            "        {{col.displayName}}\n" +
            "    </div>\n" +
            "    <div class='ngSortButtonDown' ng-show=\"col.showSortButtonDown()\">\n" +
            "    </div>\n" +
            "    <div class='ngSortButtonUp' ng-show=\"col.showSortButtonUp()\">\n" +
            "    </div>\n" +
            "    <div class='ngSortPriority'>\n" +
            "        {{col.sortPriority}}\n" +
            "    </div>\n" +
            "    <div ng-class=\"{ ngPinnedIcon: col.pinned, ngUnPinnedIcon: !col.pinned }\" ng-click=\"togglePin(col)\"\n" +
            "         ng-show=\"col.pinnable\">\n" +
            "    </div>\n" +
            "</div>\n" +
            "<div ng-show=\"col.resizable\" class='ngHeaderGrip' ng-click=\"col.gripClick($event)\"\n" +
            "     ng-mousedown=\"col.gripOnMouseDown($event)\">\n" +
            "</div>");

        $templateCache.put("templates/directives/historical_performance/multiplier.html", "<div ng-hide='editable.editing' class='ngCellText' ng-class=\"col.colIndex()\">\n" +
            "    <span ng-cell-text>\n" +
            "        <span ng-switch=\"(row.getProperty(col.field) == 'empty' || row.getProperty(col.field) == 0)\">\n" +
            "            <span ng-switch-when='false' class='pull-right'>\n" +
            "                {{row.getProperty(col.field)|number:2}}x\n" +
            "            </span>\n" +
            "            <span ng-switch-default class='pull-right'>\n" +
            "                --\n" +
            "            </span>\n" +
            "        </span>\n" +
            "    </span>\n" +
            "</div>\n" +
            "<input ng-show='editable.editing' ng-class=\"'colt' + col.index\" ng-input='COL_FIELD' ng-model='row.entity[col.field]'  />");

        $templateCache.put("templates/directives/historical_performance/name.html", "<div ng-hide='editable.editing' class='ngCellText ng-scope' ng-class='col.colIndex()'>\n" +
            "    <span ng-cell-text class='ng-binding'>\n" +
            "        {{row.getProperty(col.field) | truncateCenter:33}}\n" +
            "    </span>\n" +
            "</div>\n" +
            "<input id=\"hp-fund-{{row.rowIndex}}\" ng-show='editable.editing' ng-class=\"'colt' + col.index\" ng-input=\"COL_FIELD\" ng-model='row.entity[col.field]'  />");

        $templateCache.put("templates/directives/historical_performance/number.html", "<div ng-hide='editable.editing' class='ngCellText' ng-class=\"col.colIndex()\">\n" +
            "    <span ng-cell-text>\n" +
            "        <span ng-switch=\"row.getProperty(col.field) == 'empty'\">\n" +
            "            <span ng-switch-when='false' class='pull-right'>\n" +
            "                {{row.getProperty(col.field)|number:0}}\n" +
            "            </span>\n" +
            "            <span ng-switch-when='true' class='pull-right'>\n" +
            "                --\n" +
            "            </span>\n" +
            "        </span>\n" +
            "    </span>\n" +
            "</div>\n" +
            "<input ng-show='editable.editing' ng-class=\"'colt' + col.index\" ng-input='COL_FIELD' ng-model='row.entity[col.field]'  />");

        $templateCache.put("templates/directives/historical_performance/percentage.html", "<div ng-hide='editable.editing' class='ngCellText' ng-class=\"col.colIndex()\">\n" +
            "    <span ng-cell-text>\n" +
            "        <span ng-switch=\"(row.getProperty(col.field) == 'empty' || row.getProperty(col.field) == 0)\">\n" +
            "            <span ng-switch-when='false' class='pull-right'>\n" +
            "                {{row.getProperty(col.field)|number:1}}%\n" +
            "            </span>\n" +
            "            <span ng-switch-when='true' class='pull-right'>\n" +
            "                --\n" +
            "            </span>\n" +
            "        </span>\n" +
            "    </span>\n" +
            "</div>\n" +
            "<input ng-show='editable.editing' ng-class=\"'colt' + col.index\" ng-input='COL_FIELD' ng-model='row.entity[col.field]'  />");

        $templateCache.put("templates/directives/historical_performance/quartile.html", "<div ng-hide='editable.editing' class='ngCellText' ng-class=\"col.colIndex()\">\n" +
            "    <span ng-cell-text>\n" +
            "        <span ng-switch='row.getProperty(col.field) > 0'>\n" +
            "            <span ng-switch-when='true' class='pull-right'>\n" +
            "                <span ng-switch='row.getProperty(col.field)|number:0'>\n" +
            "                    <span ng-switch-when='1'>\n" +
            "                        1st\n" +
            "                    </span>\n" +
            "                    <span ng-switch-when='2'>\n" +
            "                        2nd\n" +
            "                    </span>\n" +
            "                    <span ng-switch-when='3'>\n" +
            "                        3rd\n" +
            "                    </span>\n" +
            "                    <span ng-switch-when='4'>\n" +
            "                        4th\n" +
            "                    </span>\n" +
            "                </span>\n" +
            "            </span>\n" +
            "            <span ng-switch-default class='pull-right'>\n" +
            "                --\n" +
            "            </span>\n" +
            "        </span>\n" +
            "    </span>\n" +
            "</div>\n" +
            "<input ng-show='editable.editing' ng-class=\"'colt' + col.index\" ng-input='COL_FIELD' ng-model='row.entity[col.field]'  />");

        $templateCache.put("templates/directives/historical_performance/vintage.html", "<div ng-hide='editable.editing' class='ngCellText ng-scope' ng-class='col.colIndex()'>\n" +
            "    <span ng-cell-text class='ng-binding'>\n" +
            "        {{row.getProperty(col.field)}}\n" +
            "    </span>\n" +
            "</div>\n" +
            "<input id=\"hp-vintage-{{row.rowIndex}}\" ng-show='editable.editing' ng-class=\"'colt' + col.index\" ng-input=\"COL_FIELD\" ng-model='row.entity[col.field]'  />");

        $templateCache.put("templates/directives/icn_input.html", "<div class=\"form-group icn-input-container\">\n" +
            "  <label class=\"icn-input-label\"\n" +
            "        ng-class=\"{ 'minimized': !inputEmpty || model.length > 0 || focused }\"\n" +
            "        for=\"{{ name }}\">\n" +
            "        {{ label }}\n" +
            "  </label>\n" +
            "  <i ng-if=\"checkbox == 'true'\" id=\"input-check\" class=\"fa fa-check completed\" aria-hidden=\"true\"></i>\n" +
            "  <input type=\"{{ type || name }}\"\n" +
            "         id=\"{{ customId }}\"\n" +
            "         class=\"icn-input {{ inputclass }}\"\n" +
            "         name=\"{{ name }}\"\n" +
            "         ng-model=\"model\"\n" +
            "         ng-disabled=\"disabled\"\n" +
            "         ng-focus=\"changeFocus(true)\"\n" +
            "         ng-blur=\"changeFocus(false)\"\n" +
            "         ng-keyup=\"updateInput($event)\"\n" +
            "         ng-class=\"{ 'placeholder-not-shown': !inputEmpty || model.length > 0 || focused }\"\n" +
            "         ng-pattern=\"matchPattern\"\n" +
            "         ng-required=\"required\"/>\n" +
            "</div>");

        $templateCache.put("templates/directives/impersonation_header.html", "<div ng-controller=\"MainCtrl\" class=\"impersonation-header-container\">\n" +
            "  <div id=\"impersonation-header\">\n" +
            "    <div class=\"message-container\">\n" +
            "      <svg class=\"alert-icon\" focusable=\"false\" aria-hidden=\"true\" viewBox=\"0 0 24 24\" data-testid=\"ReportProblemOutlinedIcon\"><path d=\"M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z\"></path></svg>\n" +
            "      <span class=\"text\">{{::impersonateText() ::userDisplayName()}}</span>.\n" +
            "    </div>\n" +
            "    <button class=\"stop-impersonating\" ng-click=\"stopImpersonate()\" name=\"btn-stop-acting\">\n" +
            "      {{::stopImpersonateText()}}\n" +
            "    </button>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("templates/directives/investment_detail_popover.html", "<div class=\"investment-detail-popover hide\" id=\"investmentDetailPopover\">\n" +
            "  <div id=\"investmentPopoverArrow\">\n" +
            "    <div class=\"row investment-detail\">\n" +
            "      <h4>{{investment.contact_card.display_name}}</h4>\n" +
            "      <div class=\"attribute\">\n" +
            "        <span class=\"attr-label\">ID: </span>\n" +
            "        <span class=\"attr-value\">{{ investment.id }}</span>\n" +
            "      </div>\n" +
            "      <div class=\"attribute\">\n" +
            "        <span class=\"attr-label\">FUND: </span>\n" +
            "        <span class=\"attr-value\">{{paf.name | truncate:40 | uppercase}}</span>\n" +
            "      </div>\n" +
            "      <div class=\"attribute\">\n" +
            "        <span class=\"attr-label\">COMMITMENT AMOUNT: </span>\n" +
            "        <span class=\"attr-value\">{{investment.amount | currency:\"$\" | uppercase}}</span>\n" +
            "      </div>\n" +
            "      <div class=\"attribute\">\n" +
            "        <span class=\"attr-label\">PHONE: </span>\n" +
            "        <span class=\"attr-value\">{{investment.contact_card.phone_number | uppercase}}</span>\n" +
            "      </div>\n" +
            "      <div class=\"attribute\">\n" +
            "        <span class=\"attr-label\">EMAIL: </span>\n" +
            "        <span class=\"attr-value\">{{investment.contact_card.email | uppercase}}</span>\n" +
            "      </div>\n" +
            "      <div class=\"attribute\">\n" +
            "        <span class=\"attr-label\">RIA NAME: </span>\n" +
            "        <span class=\"attr-value\">{{investment.ria.display_name | uppercase}}</span>\n" +
            "      </div>\n" +
            "      <div class=\"attribute\">\n" +
            "        <span class=\"attr-label\">RIA PHONE: </span>\n" +
            "        <span class=\"attr-value\">{{investment.ria.phone_number | uppercase}}</span>\n" +
            "      </div>\n" +
            "      <div class=\"attribute\">\n" +
            "        <span class=\"attr-label\">RIA EMAIL: </span>\n" +
            "        <span class=\"attr-value\">{{investment.ria.email | uppercase}}</span>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</div>\n" +
            "\n" +
            "<div ng-transclude></div>");

        $templateCache.put("templates/directives/main_menu.html", "<nav ng-controller=\"MenuCtrl\" class=\"header__nav\">\n" +
            "   <ul class=\"header__nav-list\" ng-if=\"!pendingMfaRegistration\">\n" +
            "      <li name=\"invest\" class=\"header__nav-item\" ng-if=\"menu.main_menu.invest.length > 0\"\n" +
            "          ng-class=\"{'header__nav-item--last': menu.main_menu.research.length == 0 && menu.main_menu.manage.length == 0 && menu.main_menu.admin.length == 0}\">\n" +
            "           <a class=\"header__nav-item-label\" ng-href=\"\">INVEST</a>\n" +
            "           <section name=\"invest\" class=\"header__submenu header__submenu-background\">\n" +
            "            <div class=\"container\">\n" +
            "              <div class=\"row header__submenu-links\">\n" +
            "                <div ng-repeat=\"item in menu.main_menu.invest\" >\n" +
            "                  <a ng-if=\"item.target\" ng-href=\"{{item.href}}\" target=\"{{item.target}}\">{{item.name}}</a>\n" +
            "                  <a ng-if=\"!item.target\" ng-href=\"{{item.href}}\">{{item.name}}</a>\n" +
            "                </div>\n" +
            "              </div>\n" +
            "            </div>\n" +
            "          </section>\n" +
            "      </li>\n" +
            "      <li name=\"research\" class=\"header__nav-item\" ng-if=\"menu.main_menu.research.length > 0\"\n" +
            "          ng-class=\"{'header__nav-item--last': menu.main_menu.reporting.length == 0 &&  menu.main_menu.manage.length == 0 && menu.main_menu.admin.length == 0}\">\n" +
            "          <a class=\"header__nav-item-label\" ng-href=\"\">RESEARCH</a>\n" +
            "          <section name=\"research\" class=\"header__submenu\">\n" +
            "            <div class=\"container\">\n" +
            "              <div class=\"row header__submenu-links\">\n" +
            "                <div ng-repeat=\"item in menu.main_menu.research\" >\n" +
            "                  <a ng-href=\"{{item.href}}\">\n" +
            "                    {{item.name}}\n" +
            "                  </a>\n" +
            "                  <ul ng-if=\"item.submenu.length > 0\" class=\"dropdown-menu\">\n" +
            "                    <li ng-repeat=\"subitem in item.submenu\" class=\"header__submenu-dropdown-item\">\n" +
            "                      <a ng-href=\"{{subitem.href}}\"><img ng-src=\"{{subitem.logo_url}}\"></a>\n" +
            "                    </li>\n" +
            "                  </ul>\n" +
            "                </div>\n" +
            "              </div>\n" +
            "            </div>\n" +
            "          </section>\n" +
            "      </li>\n" +
            "\n" +
            "      <li name=\"reporting\" class=\"header__nav-item\" ng-if=\"menu.main_menu.reporting.length > 0\"\n" +
            "          ng-class=\"{'header__nav-item--last': menu.main_menu.manage.length == 0 && menu.main_menu.admin.length == 0}\">\n" +
            "          <a class=\"header__nav-item-label\" ng-href=\"\">REPORTING</a>\n" +
            "          <section name=\"reporting\" class=\"header__submenu header__submenu-background\">\n" +
            "            <div class=\"container\">\n" +
            "              <div class=\"row header__submenu-links\">\n" +
            "                <div ng-repeat=\"item in menu.main_menu.reporting\">\n" +
            "                  <a ng-if=\"item.target\" ng-href=\"{{item.href}}\" target=\"{{item.target}}\">{{item.name}}</a>\n" +
            "                  <a ng-if=\"!item.target\" ng-href=\"{{item.href}}\">{{item.name}}</a>\n" +
            "                </div>\n" +
            "              </div>\n" +
            "            </div>\n" +
            "          </section>\n" +
            "      </li>\n" +
            "\n" +
            "      <li name=\"manage\" class=\"header__nav-item\" ng-if=\"menu.main_menu.manage.length > 0\"\n" +
            "          ng-class=\"{'header__nav-item--last': menu.main_menu.admin.length == 0}\">\n" +
            "          <a class=\"header__nav-item-label\" ng-href=\"\">MANAGE</a>\n" +
            "          <section name=\"manage\" class=\"header__submenu header__submenu-background\">\n" +
            "            <div class=\"container\">\n" +
            "              <div class=\"row header__submenu-links\">\n" +
            "                <div ng-repeat=\"item in menu.main_menu.manage\">\n" +
            "                  <a ng-if=\"item.target\" ng-href=\"{{item.href}}\" target=\"{{item.target}}\">{{item.name}}</a>\n" +
            "                  <a ng-if=\"!item.target\" ng-href=\"{{item.href}}\">{{item.name}}</a>\n" +
            "                </div>\n" +
            "              </div>\n" +
            "            </div>\n" +
            "          </section>\n" +
            "      </li>\n" +
            "\n" +
            "      <li name=\"analyze\" class=\"header__nav-item\" ng-if=\"menu.main_menu.analyze\"\n" +
            "          ng-mouseover=\"hideSubMenu()\" ng-mouseleave=\"showSubMenu()\"\n" +
            "          ng-class=\"{'header__nav-item--last': menu.main_menu.admin.length == 0}\">\n" +
            "          <a class=\"header__nav-item-label\" ng-href=\"{{menu.main_menu.analyze.href}}\"\n" +
            "             target=\"{{menu.main_menu.analyze.target}}\"\n" +
            "             data-cta=\"analyzeButton\">\n" +
            "              <div style=\"flex-flow: row; display: inline-flex\">\n" +
            "                  <span>ANALYZE</span>\n" +
            "              </div>\n" +
            "          </a>\n" +
            "      </li>\n" +
            "\n" +
            "      <li name=\"learn\" class=\"header__nav-item header__nav-item--last\" ng-if=\"menu.main_menu.learn.length > 0\">\n" +
            "        <a class=\"header__nav-item-label\" ng-href=\"\">LEARN</a>\n" +
            "        <section name=\"learn\" class=\"header__submenu header__submenu-background\">\n" +
            "          <div class=\"container\">\n" +
            "            <div class=\"row header__submenu-links\">\n" +
            "              <div ng-repeat=\"item in menu.main_menu.learn\" >\n" +
            "                <a ng-if=\"item.target\" ng-href=\"{{item.href}}\" target=\"{{item.target}}\">{{item.name}}</a>\n" +
            "                <a ng-if=\"!item.target\" ng-href=\"{{item.href}}\">{{item.name}}</a>\n" +
            "              </div>\n" +
            "            </div>\n" +
            "          </div>\n" +
            "        </section>\n" +
            "      </li>\n" +
            "\n" +
            "      <li name=\"admin\" class=\"header__nav-item\" ng-if=\"menu.main_menu.admin.length > 0\">\n" +
            "        <a class=\"header__nav-item-label\" ng-href=\"\">ADMIN</a>\n" +
            "        <section name=\"admin\" class=\"header__submenu header__submenu-background\">\n" +
            "          <div class=\"container\">\n" +
            "            <div class=\"row header__submenu-links\">\n" +
            "              <div ng-repeat=\"item in menu.main_menu.admin\" >\n" +
            "                <a ng-if=\"item.target\" ng-href=\"{{item.href}}\" target=\"{{item.target}}\">{{item.name}}</a>\n" +
            "                <a ng-if=\"!item.target\" ng-href=\"{{item.href}}\">{{item.name}}</a>\n" +
            "              </div>\n" +
            "            </div>\n" +
            "          </div>\n" +
            "        </section>\n" +
            "      </li>\n" +
            "\n" +
            "      <li name=\"subdoc\" class=\"header__nav-item\" ng-if=\"menu.main_menu.subdocs.length > 0\">\n" +
            "        <a class=\"header__nav-item-label\" ng-href=\"\">Subdocs</a>\n" +
            "        <section name=\"subdocs\" class=\"header__submenu header__submenu-background\">\n" +
            "          <div class=\"container\">\n" +
            "            <div class=\"row header__submenu-links\">\n" +
            "              <div ng-repeat=\"item in menu.main_menu.subdocs\" >\n" +
            "                <a ng-if=\"item.target\" ng-href=\"{{item.href}}\" target=\"{{item.target}}\">{{item.name}}</a>\n" +
            "                <a ng-if=\"!item.target\" ng-href=\"{{item.href}}\">{{item.name}}</a>\n" +
            "              </div>\n" +
            "            </div>\n" +
            "          </div>\n" +
            "        </section>\n" +
            "      </li>\n" +
            "\n" +
            "      <li name=\"management-reporting\" class=\"header__nav-item header__nav-item--last\" ng-if=\"menu.main_menu.management_reporting.length > 0\">\n" +
            "        <a class=\"header__nav-item-label\" ng-href=\"\">MGMT REPORTING</a>\n" +
            "        <section name=\"management-reporting\" class=\"header__submenu header__submenu-background\">\n" +
            "          <div class=\"container\">\n" +
            "            <div class=\"row header__submenu-links\">\n" +
            "              <div ng-repeat=\"item in menu.main_menu.management_reporting\" >\n" +
            "                <a ng-if=\"item.target\" ng-href=\"{{item.href}}\" target=\"{{item.target}}\">{{item.name}}</a>\n" +
            "                <a ng-if=\"!item.target\" ng-href=\"{{item.href}}\">{{item.name}}</a>\n" +
            "              </div>\n" +
            "            </div>\n" +
            "          </div>\n" +
            "        </section>\n" +
            "      </li>\n" +
            "    </ul>\n" +
            "\n" +
            "    <button\n" +
            "      id=\"sso-return-button\"\n" +
            "      ng-show=\"ssoPartnerLogin\"\n" +
            "      name=\"sso-return-button\"\n" +
            "      class=\"sso-return-button pull-right\"\n" +
            "      ng-click=\"ssoReturnButtonBehavior.action()\"\n" +
            "    >\n" +
            "      {{ssoReturnButtonBehavior.name}}\n" +
            "    </button>\n" +
            "\n" +
            "    <a\n" +
            "      id=\"impersonate\"\n" +
            "      ng-class=\"impersonateButtonState()\"\n" +
            "      ng-show=\"userCanImpersonate()\"\n" +
            "      name=\"impersonate\"\n" +
            "      class=\"impersonate-link pull-right\"\n" +
            "      ng-click=\"impersonateButtonBehavior.action()\"\n" +
            "    >\n" +
            "      {{impersonateButtonBehavior.text}}\n" +
            "    </a>\n" +
            "    <section class=\"header__submenu-background\" ng-class=\"{'hide-submenu': subMenuInvisible}\"></section>\n" +
            "</nav>");

        $templateCache.put("templates/directives/master_feeder_fields.html", "<div class=\"fund-relationships-form\">\n" +
            "  <div class=\"row form-group\">\n" +
            "    <div class=\"col-md-12 no-left-padding\">\n" +
            "      <div class=\"col-md-offset-3\">\n" +
            "        <ui-select\n" +
            "          class=\"master-select\"\n" +
            "          ng-model=\"masterSelected.paf\"\n" +
            "          theme=\"bootstrap\"\n" +
            "          name=\"master-selector\">\n" +
            "          <ui-select-match placeholder=\"Master Fund\">\n" +
            "            {{$select.selected.name}}\n" +
            "          </ui-select-match>\n" +
            "          <ui-select-choices\n" +
            "            group-by=\"'category'\"\n" +
            "            repeat=\"paf as paf in availableMasterPafs | orderBy: ['-category', 'name'] | propsFilter: {name: $select.search}\">\n" +
            "            <div ng-bind-html=\"paf.name | highlight: $select.search\"></div>\n" +
            "          </ui-select-choices>\n" +
            "        </ui-select>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group\">\n" +
            "    <div class=\"col-md-12 no-left-padding\">\n" +
            "      <div class=\"col-md-offset-3\">\n" +
            "        <ui-select\n" +
            "          class=\"feeder-select\"\n" +
            "          multiple\n" +
            "          ng-model=\"selectedFeederIds.feeders\"\n" +
            "          theme=\"bootstrap\"\n" +
            "          sortable=\"true\"\n" +
            "          close-on-select=\"false\"\n" +
            "          name=\"feeders-selector\">\n" +
            "          <ui-select-match placeholder=\"Feeder Fund(s)\">\n" +
            "            {{$item.name}}\n" +
            "          </ui-select-match>\n" +
            "          <ui-select-choices\n" +
            "            group-by=\"'category'\"\n" +
            "            repeat=\"paf.id as paf in feederPafs | orderBy: ['-category', 'name'] | propsFilter: {name: $select.search}\">\n" +
            "            <div ng-bind-html=\"paf.name | highlight: $select.search\"></div>\n" +
            "          </ui-select-choices>\n" +
            "        </ui-select>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "  <div class=\"row form-group\">\n" +
            "    <div class=\"col-md-12\">\n" +
            "      <div class=\"col-md-offset-5\">\n" +
            "        <button class=\"action-button inverted-button\" type=\"submit\" ng-click=\"cancel()\">Cancel</button>\n" +
            "        <button class=\"action-button\" type=\"submit\" ng-click=\"save()\">Save</button>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("templates/directives/offerings_book.html", "<div class=\"partnerShow-offeringBook-container row pull-right\" ng-hide=\"!partner.offering_book_url\">\n" +
            "  <img class=\"partnerShow-offeringBook-icon col-md-4\" src=\"https://icn-development-files.s3.amazonaws.com/contact_card/photo/92d6c3089f7e56c4eea6a6d8886ae27e/sampleofferbookcover.jpg\">\n" +
            "  <div class=\"partnerShow-offeringBook-title col-md-8\">Fund Offerings book</div>\n" +
            "  <a style=\"color:#FFF;\" href=\"{{partner.offering_book_url}}\" target=\"_blank\" class=\"col-md-4 strong\">DOWNLOAD</a>\n" +
            "</div>");

        $templateCache.put("templates/directives/progress-bar.html", "<div class=\"line_container\">\n" +
            "  <div class=\"line_1\">\n" +
            "    <span class=\"percent_complete\"></span>\n" +
            "  </div>\n" +
            "  <div class=\"target_meter\"></div>\n" +
            "  <div class=\"target_amount\">target {{target}}<br/>100%</div>\n" +
            "</div>");

        $templateCache.put("templates/directives/sales_account_manager.html", "<div class=\"fund-profile__account-manager\" ng-if=\"user.sales_account_manager\">\n" +
            "    <div class=\"sidebar-section\">\n" +
            "        <div class=\"sidebar-section__header\">{{main_contact_label}}</div>\n" +
            "        <div class=\"fund-profile__account-manager-subtext\">Questions? Please contact:</div>\n" +
            "        <div class=\"row\">\n" +
            "            <div class=\"col\">\n" +
            "                {{user.sales_account_manager.name}}\n" +
            "                <br/>{{user.sales_account_manager.firm_name}}\n" +
            "                <br/><a href=\"mailto:{{user.sales_account_manager.email}}\">{{user.sales_account_manager.email}}</a>\n" +
            "                <br/>{{user.sales_account_manager.phone_number}}\n" +
            "            </div>\n" +
            "        </div>\n" +
            "    </div>\n" +
            "</div>");

        $templateCache.put("templates/directives/user_menu.html", "<div ng-controller=\"MenuCtrl\" id=\"account-info\" class=\"drop-down header__actions\">\n" +
            "  <a id=\"user-menu-dropdown\" href='#'>\n" +
            "    <i class=\"fa fa-bars nav-hamburger-color\"></i>\n" +
            "    <div class=\"header__actions-info\">\n" +
            "      <div class=\"header__actions-name\">\n" +
            "        {{user_name | truncate : 25}}\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </a>\n" +
            "  <div class=\"dropdown-menu-spacer\"></div>\n" +
            "  <ul class=\"dropdown-menu fade\">\n" +
            "    <li role=\"presentation\" ng-repeat=\"item in menu.user_menu\" ng-if=\"!pendingMfaRegistration\">\n" +
            "      <a ng-if=\"!checkSupportForUrl(item)\" role=\"menuitem\" href=\"{{item.href}}\" target=\"{{item.target}}\">\n" +
            "        {{item.name}}\n" +
            "      </a>\n" +
            "      <a ng-if=\"checkSupportForUrl(item)\" href=\"#\" ng-click=\"openContactUsModal()\">{{item.name}}</a>\n" +
            "    </li>\n" +
            "    <li role=\"presentation\" class=\"header__actions-logout\">\n" +
            "      <a role=\"menuitem\" ng-click=\"logout($event)\" href=\"logout\">Log Out</a>\n" +
            "    </li>\n" +
            "  </ul>\n" +
            "</div>");

        $templateCache.put("templates/home/index.html", "<div>\n" +
            "	&nbsp;\n" +
            "	<div style=\"font-size: 200px;color:#E7E7E7;padding:100px 0px 200px 0px; text-align:center;\n" +
            "\">Welcome</div>\n" +
            "</div>");

        $templateCache.put("templates/investment_tabs.html", "<div class=\"investorProfileTabs\" ng-show=\"investments.length > 1\">\n" +
            "    <div class=\"profileTab\"\n" +
            "         ng-repeat=\"investment in investments\"\n" +
            "         ng-click=\"setInvestment(investment)\"\n" +
            "         ng-class=\"{'profileTabSelected': selected(investment)}\">\n" +
            "          {{investorDisplayName(investment)}}\n" +
            "    </div>\n" +
            "</div>");

        $templateCache.put("templates/roadshows/brightcove_player.html", "<iframe id=\"video-wrapper\" ng-src=\"{{videoURL}}\" width=\"{{width}}\" height=\"{{height}}\" frameborder=\"0\" \n" +
            "  allowfullscreen \n" +
            "  webkitallowfullscreen \n" +
            "  mozallowfullscreen>\n" +
            "</iframe>");

        $templateCache.put("templates/roadshows/roadshow_chapter_detail.html", "<div class=\"roadshowChapterDetail\">\n" +
            "  <div class=\"roadshowChapterDetail-info\">\n" +
            "    <h5 class=\"roadshowChapterDetail-position\">Chapter {{chapter.position}} ({{chapter.video_length}})</h5>\n" +
            "    <h3 class=\"roadshowChapterDetail-title\">{{chapter.title}}</h3>\n" +
            "  </div>\n" +
            "\n" +
            "  <button class=\"button__reject roadshowChapterDetail-closeBtn\" ng-click=\"close()\">\n" +
            "    <i class=\"fa fa-chevron-left\"></i>&nbsp;&nbsp;\n" +
            "    return to chapter selection\n" +
            "  </button>\n" +
            "\n" +
            "  <div class=\"roadshowChapterDetail-media\">\n" +
            "    <icn-brightcove-player video-id=\"{{chapter.video_id}}\" chapter=\"chapter\" fund=\"fund\" height=\"400\" width=\"980\" auto-play=\"true\"></icn-brightcove-player>\n" +
            "  </div>\n" +
            "</div>");

        $templateCache.put("templates/roadshows/roadshow_chapter_list.html", "<div class=\"roadshowChapterList\" ng-class=\"{'is-collapsed': isCollapsed}\">\n" +
            "  <ul class=\"list-unstyled\">\n" +
            "    <li ng-repeat=\"chapter in chapters\">\n" +
            "      <div class=\"roadshowChapterListItem\" ng-class=\"{'is-hidden': isChapterHidden($index)}\">\n" +
            "        <a class=\"roadshowChapterListItem-thumb\" href=\"#\" ng-click=\"selectChapter(chapter, $event)\">\n" +
            "          <img class=\"roadshowChapterListItem-thumb-img\" ng-src=\"{{chapter.thumbnail_url}}\">\n" +
            "            <img src=\"images/playbutton.png\" class=\"roadshowChapterListItem-thumb-play\">\n" +
            "        </a>\n" +
            "\n" +
            "        <div class=\"roadshowChapterListItem-info\">\n" +
            "          <a href=\"#\" ng-click=\"selectChapter(chapter, $event)\">\n" +
            "            <div class=\"roadshowChapterListItem-position\" ng-if=\"fund.show_video_chapter_text\">\n" +
            "              Chapter {{chapter.position}}\n" +
            "              <span class=\"roadshowChapterListItem-time\">{{chapter.video_length}}</span>\n" +
            "            </div>\n" +
            "            <div class=\"roadshowChapterListItem-title\">{{chapter.title}}</div>\n" +
            "          </a>\n" +
            "        </div>\n" +
            "      </div><!-- /media -->\n" +
            "\n" +
            "    </li>\n" +
            "  </ul>\n" +
            "\n" +
            "</div>");

        $templateCache.put("templates/roadshows/roadshow.html", "<div class=\"roadshow\">\n" +
            "    <h2>{{roadshow.title}}</h2>\n" +
            "\n" +
            "    <div>\n" +
            "        <icn-roadshow-chapter-list chapters=\"roadshow.chapters\" is-collapsed=\"isChapterListCollapsed\" fund=\"fund\">\n" +
            "        </icn-roadshow-chapter-list>\n" +
            "    </div>\n" +
            "\n" +
            "    <a class=\"roadshowChapterListItem-toggle\" ng-click=\"toggleCollapse($event)\" href=\"#\">\n" +
            "        <span class=\"roadshowChapterListItem-toggle-icon--plus\" ng-show=\"isChapterListCollapsed\">\n" +
            "            more videos\n" +
            "        </span>\n" +
            "\n" +
            "        <span class=\"roadshowChapterListItem-toggle-icon--minus\" ng-hide=\"isChapterListCollapsed\">\n" +
            "            less videos\n" +
            "        </span>\n" +
            "    </a>\n" +
            "</div>");
    }
]);
