angular.module('services').factory "CurrentUser", ($resource) ->
  $resource "/api/v1/current_user/:resource/:resourceId",
    resource: "@resource"
    id: "@id"
    resourceId: "@resourceId"
  ,
    update:
      method: "PATCH"

    getFundBucketLabels:
      url: "/api/v1/current_user/fund_bucket_labels"
      method: "GET"
