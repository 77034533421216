// Generated by CoffeeScript 2.4.1
(function() {
  angular.module('controllers').controller("esignModalCtrl", function($scope, $modal, $modalInstance, $validator, investorProfile, docId, initialRequest, RequiredDocument, deletable, manuallyAddedEmail) {
    var allSigners, formatSigners, listSigners, visibleSigners;
    listSigners = function(signers) {
      var mapped;
      mapped = [];
      if (signers) {
        signers.forEach(function(sig) {
          var s;
          if (!!sig) {
            s = {
              id: `${sig.id}`,
              label: `${sig.full_name} (${sig.email})`,
              selected: false
            };
            if (sig.primary_relationship) {
              return mapped.unshift(s);
            } else {
              return mapped.push(s);
            }
          }
        });
      }
      return mapped;
    };
    allSigners = function() {
      return investorProfile.signers;
    };
    visibleSigners = function() {
      if (initialRequest) {
        return investorProfile.available_signers;
      } else {
        return allSigners();
      }
    };
    formatSigners = function() {
      if (investorProfile.account_type === "individual") {
        return listSigners(investorProfile.primary_signer);
      } else {
        return listSigners(visibleSigners());
      }
    };
    $scope.deletable = deletable;
    if (manuallyAddedEmail) {
      $scope.formData = {
        email: manuallyAddedEmail
      };
    } else {
      $scope.formData = {
        email: null
      };
    }
    $scope.multipleSelections = function() {
      var ids;
      ids = [];
      _.each($scope.signers, function(sig) {
        if (sig.selected) {
          return ids.push(sig.id);
        }
      });
      return ids.join(",");
    };
    $scope.selectedSigners = function() {
      if ($scope.selection) {
        return $scope.selection;
      } else {
        return $scope.multipleSelections();
      }
    };
    $scope.setSigner = function(id) {
      return $scope.selection = id;
    };
    $scope.selectionMade = function() {
      return $scope.selection || $scope.multipleSelections().length;
    };
    $scope.emailInserted = function() {
      return $scope.formData.email && $scope.formData.email.length > 0;
    };
    $scope.selection;
    $scope.header = "Send for Electronic Signature";
    $scope.accountType = investorProfile.account_type;
    $scope.signers = formatSigners();
    if ($scope.signers.length === 1) {
      $scope.selection = $scope.signers[0].id;
      $scope.hideSelectors = true;
    }
    $scope.selectedEmail = function() {
      return false;
    };
    $scope.submit = function() {
      if (!deletable || $scope.emailInserted()) {
        return RequiredDocument.requestEsigns({
          investor_profile_id: investorProfile.id,
          signer_ids: $scope.selectedSigners(),
          selected_document_id: docId,
          email: $scope.formData.email
        }).$promise.then(function(response) {
          return $modalInstance.close(response);
        }, function(error) {
          return $modalInstance.close(error);
        });
      }
    };
    return $scope.cancel = function() {
      return $modalInstance.dismiss();
    };
  });

}).call(this);
