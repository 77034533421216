angular.module('directives').directive "icnBrightcovePlayer", ($timeout, $sce, Hits, IcnIdentity) ->
  restrict: "E"
  templateUrl: "templates/roadshows/brightcove_player.html"
  scope:
    videoId: "@"
    chapter: "="
    fund: "="
    height: "="
    width: "="
    autoPlay: "="
  link: (scope, element, attrs) ->
    scope.originalVideoURL = "https://players.brightcove.net/3875446849001/default_default/index.html?videoId=#{scope.videoId}"
    scope.videoURL = $sce.trustAsResourceUrl(scope.originalVideoURL)

    if (scope.fund.brightcove_video_id)
      Hits.create 'brightcove_video_view', 'Fund', scope.fund.id
    else
      Hits.create "view", "RoadshowVideo", scope.chapter.roadshow_video_id, "", {fund_id:scope.fund.id}

    $timeout ->
      brightcove.createExperiences()
