angular.module("icn.paf_admin")
.factory "DistributorFilesService", ($resource, $http, $q) ->
  DistributorFilesService =
    $resource("/api/v1/distributor_reports", {},
      exportDistributorFile:
        url: "/api/v1/distributor_reports/export"
        method: "POST"
      getAvailableWLPs:
        url: "/api/v1/distributor_reports/white_label_partners"
        method: "GET"
        isArray: false
      getValidDates:
        url: "/api/v1/distributor_reports/valid_reporting_periods"
        method: "GET"
        isArray: false
    )

  DistributorFilesService::exportDistributorFile = (closeDate, filename, wlpId, fundGroupId, reportSlug) ->
    DistributorFilesService.exportDistributorFile({
      close_date: closeDate
      filename: filename
      wlp_id: wlpId
      fund_group_id: fundGroupId,
      report_slug: reportSlug,
    }).$promise

  DistributorFilesService::getAvailableWLPs = (fundGroupId) ->
    DistributorFilesService.getAvailableWLPs({
      fund_group_id: fundGroupId
    }).$promise
  
  DistributorFilesService::getValidDates = (wlpId) ->
    DistributorFilesService.getValidDates(wlp_id: wlpId).$promise

  return new DistributorFilesService()
