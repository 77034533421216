angular.module('services').factory "UserAcknowledgementService", ($http) ->
  create = (questionIds) ->
    $http({
      url: "/api/v1/user_acknowledgements",
      method: "POST",
      data: {
        acknowledgement_type: "investor",
        iqs_question_ids: questionIds,
      },
    })

  { create: create }
