angular.module('directives').directive('domainToggle', function() {
    return {
        restrict: 'E',
        templateUrl: 'directives/domain_toggle/domain_toggle.html',
        link: function($scope, $element) {
            $scope.menuVisible = false

            $scope.toggleMenu = function() {
                $scope.menuVisible = !$scope.menuVisible;
            };

            $scope.togglePlatform = function() {
                window.location = "/icn_react/static/architect?toggle=true";
            };
        }
    };
});
