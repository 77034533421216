angular.module('services').factory "Users", ($resource, $q) ->
  fields = [
    "id"
    "first_name"
    "last_name"
    "firm"
    "name"
    "email"
    "contact_card"
    "roles"
    "phone"
    "disabled"
    "disabled_reason"
    "title"
    "display_name"
    "password_confirmation"
  ]
  resource = $resource("/api/v1/users/basic",
    "fields[]": fields,
    id: '@id'
  ,
    query:
      method: "GET"
      isArray: true
      headers:
        "Butter-Bar": false

    riaClients:
      url: "/api/v1/users/:id/ria_clients"
      method: 'GET'
      isArray: true
      headers:
        'Butter-Bar': false

    update:
      url: "/api/v1/users/:id"
      method: 'PATCH'

    purchasePathPageViewed:
      url: "/api/v1/users/purchase_path_page_viewed"
      method: 'PATCH'

    search:
      url: "/api/v1/users/search"
      method: "GET"

    unlockAccount:
      url: "/api/v1/users/:id/unlock"
      method: "PATCH"

    irByLogin:
      url: "/api/v1/users/ir_by_login"
      isArray: true
      method: "GET"

    usersListForPafException:
      url: "/api/v1/users/users_list_for_paf_exception"
      params:
        email: "@email"
        "fields[]": ""
      isArray: true
      method: "GET"

    impersonate:
      url: "/api/v1/users/:id/impersonate"
      method: "POST"

    stop_impersonate:
      url: "/api/v1/users/:id/stop_impersonate"
      method: "POST"

    search_for_impersonate:
      url: "/api/v1/users/:id/search_for_impersonate"
      method: "GET"

    referral_codes:
      url: "/api/v1/users/:id/referral_codes"
      method: "GET"
      isArray: true
  )

  irByLogin = ->
    resource.irByLogin().$promise

  query = (params) ->
    resource.query(params).$promise

  riaClients = (userId, pafId, with_canceled = false, wlpId = null) ->
    deferred = $q.defer()

    resource.riaClients(
      id: userId
      private_access_fund_id: pafId
      with_canceled: with_canceled
      wlp_id: wlpId
    ).$promise.then (response) ->
      deferred.resolve response

    deferred.promise

  update = (userId, firstName, lastName, email, password, passwordConfirmation, disabled, disabled_reason, wlpId,
    cooledOffAt, accredit, accreditRole, sendWelcome, iqs_ids, firmId, needsPasswordReset, userCountry,
    answerFirmName, answerCrdNumber, advisorCrdNumber) ->
      resource.update(
        id: userId
        accredit: accredit
        accredit_role: accreditRole
        send_welcome: sendWelcome
        user_iqs: iqs_ids
        ria_accreditation:
          firm_name: answerFirmName
          crd_number: answerCrdNumber
          advisor_crd_number: advisorCrdNumber
          country: userCountry
        user:
          id: userId
          email: email
          password: password
          password_confirmation: passwordConfirmation
          disabled: disabled
          disabled_reason: disabled_reason
          cooled_off_at: cooledOffAt
          firm_id: firmId,
          needs_password_reset: needsPasswordReset,
          contact_card_attributes:
            first_name: firstName
            last_name: lastName
            white_label_partner_id: wlpId
      ).$promise

  purchasePathPageViewed = (userId, page, fundId) ->
    resource.purchasePathPageViewed(
      id: userId
      page: page
      fund_id: fundId
    ).$promise

  find = (id) ->
    resource.search(
      id: id
      ).$promise

  search = (term) ->
    resource.search(
      term: term
      ).$promise

  unlockAccount = (userId) ->
    resource.unlockAccount(
      id: userId)

  impersonate = (id) ->
    resource.impersonate(
      id: id).$promise

  stop_impersonate = (id) ->
    resource.stop_impersonate(
      id: id).$promise

  search_for_impersonate = (id, term) ->
    resource.search_for_impersonate(
      id: id, term: term).$promise

  usersListForPafException = (email) ->
    resource.usersListForPafException(
      email: email
    ).$promise

  referralCodes = (userId) ->
    resource.referral_codes(id: userId).$promise

  query: query
  riaClients: riaClients
  update: update
  purchasePathPageViewed: purchasePathPageViewed
  find: find
  unlockAccount: unlockAccount
  irByLogin: irByLogin
  impersonate: impersonate
  stop_impersonate: stop_impersonate
  usersListForPafException: usersListForPafException
  search_for_impersonate: search_for_impersonate
  referralCodes: referralCodes
