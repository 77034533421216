angular.module('filters').filter "money", ->
  (number, suffix, actual, currency, precision, lowercase, no_space) ->
    currency = (if currency is undefined then "$" else currency)
    number_str = ""
    output = undefined
    prefix = currency
    suffix = (if suffix then suffix else "m")
    number = parseFloat(number, 10)  if _.isString(number)
    space = (if no_space then "" else " ")
    if number is undefined or number is null or _.isNaN(number)
      return ""
    else unless actual
      if number >= 1000
        default_precision = (if precision then precision else 2)
        number_str = (number / 1000).toFixed(default_precision)
        suffix = (if suffix is "m" then "B" else "Billion")
      else if number is 0
        number_str = "--"
      else
        number_str = number
        suffix = (if suffix is "m" then "M" else "Million")
    else
      if number >= 1000000000
        number_str = (number / 1000000000)
        suffix = (if suffix is "m" then "B" else "Billion")
      else if number >= 1000000
        number_str = (number / 1000000)
        suffix = (if suffix is "m" then "M" else "Million")
      else if number >= 1000
        number_str = (number / 1000)
        suffix = "K"
      else
        number_str = number.toLocaleString()
        suffix = ""

    if precision and _.isNumber(precision) and _.isNumber(number_str)
      number_str = number_str.toFixed(precision)

    if lowercase
      suffix = suffix.toLowerCase()

    (prefix + number_str + space + suffix).trim()
