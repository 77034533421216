angular.module('controllers').controller "InvestmentApprovalsDialogCtrl",
  ($scope, $modal, $modalInstance, ModalDialogInstancesObserver, InvestmentStatusLabels, InvestmentReviewStatusService,
  IcnIdentity, approvalHistory, meta, selectedPaf) ->
    $scope.selectedPaf = selectedPaf
    $scope.approvalHistory = approvalHistory
    $scope.meta = meta
    $scope.whiteLabelPartner = IcnIdentity.whiteLabelPartner()

    $scope.workflowChanged = -> meta.workflow_changed
    $scope.hasApprovalHistory = -> $scope.approvalHistory.length > 0

    InvestmentStatusLabels.wlpPafLabels({
      paf_id: $scope.selectedPaf.id,
      wlp_id: $scope.whiteLabelPartner.id
    }).then (labels) ->
      $scope.wlpPafLabels = labels.data

    $scope.$watch "wlpPafLabels", ->
      $scope.statusLabel = InvestmentReviewStatusService.statusLabel(IcnIdentity, $scope, "selectedPaf")

    $scope.cancelDialog = -> $modalInstance.dismiss()

    # dismiss modal dialog box when navigate to other page
    ModalDialogInstancesObserver.setModalDialogInstance($modalInstance)
