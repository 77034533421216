angular.module('controllers').controller 'simpleAlertDialogCtrl',
  ($q, $scope, $modalInstance, acceptCallback, options) ->
    $scope.headerText = options.header || "Warning"
    $scope.messageText = options.message || "Are you sure?"
    $scope.declineText = options.declineText || "CANCEL"
    $scope.acceptText = options.acceptText || "OK"

    $scope.accept = ->
      acceptCallback(options.modelId)
      $modalInstance.close()

    $scope.decline = ->
      if options.declineCallback then options.declineCallback()
      $modalInstance.close()
