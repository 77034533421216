angular.module('controllers').controller "WlpAccreditationCtrl",
  ($scope, UserAccreditation, $window, $validator, ReferralCode, IcnIdentity, WhiteLabelPartnerService, $sce) ->
    $scope.user = IcnIdentity.user
    $scope.user_accreditation = {}
    $scope.show_thank_you = false

    $scope.accreditationComplete = (valid) ->
      if (!valid && $scope.user.isInvestor)
        $window.location.href = '/accreditation/qp'
      else
        $window.location.href = IcnIdentity.defaultPage()

    $scope.formTemplates =
      thank_you: "areas/no_tab/accreditation/templates/thank_you.html"

    showThankYou = (referral_code_id) ->
      ReferralCode.$get({id: referral_code_id}, (data) ->
        if data.referral_code.thank_you_text
          $scope.thankYouText = $sce.trustAsHtml(data.referral_code.thank_you_text)
          $("html,body").scrollTop 0
          $scope.show_thank_you = true
        else
          $scope.accreditationComplete(true)
      ,
        (e) ->
          $scope.msg = 'error occured'
      )

    $scope.submitAccreditations = ->
      $scope.submitted = true
      UserAccreditation.save($scope.user.id, $scope.user_accreditation).then (d) ->
        if d.thank_you_redirect > 0
          showThankYou(d.thank_you_redirect)
        else
          $scope.accreditationComplete(d.valid)

    $scope.date = new Date()

    setOptions = ->
      WhiteLabelPartnerService.current().then (d) ->
        if d.white_label_partner == undefined
          $scope.onboardingTerms = ""
        else
          $scope.onboardingTerms = $sce.trustAsHtml(d.white_label_partner.onboarding_terms)

    setOptions()
