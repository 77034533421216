angular.module('controllers').controller "taxReportCtrl",
  ($scope, $modalInstance, header, confirmLabel, cancelLabel, CashOverCommittedService) ->

    $scope.header = header
    $scope.confirmLabel = confirmLabel
    $scope.cancelLabel = cancelLabel
    $scope.selectableYears = []
    $scope.selectedFields =  {
      year: '',
      filename: '',
      openEnded: 'false',
    }

    loadAvailableYears = ->
      currenYear =  new Date().getFullYear()
      for i in [0..2]
        $scope.selectableYears.push(currenYear - i)

    $scope.validSelection = ->
      return !!$scope.selectedFields.year

    $scope.cancel = ->
      $modalInstance.dismiss "no"

    $scope.submit = (selectedFields) ->
      year = $scope.selectedFields.year
      $modalInstance.close(selectedFields)

    init = ->
      loadAvailableYears()
    init()
