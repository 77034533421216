angular.module('services').factory "TermsOfUseModal",
  ($q, $location, $modal, IcnIdentity) ->

    isModalOpen = false

    isMainSite = IcnIdentity.whiteLabelPartner().id == 0
    isWhiteLabel = IcnIdentity.isWhiteLabel()
    noMainSiteTermsAccepted = !IcnIdentity.user?.active_main_site_terms_accepted

    isWlpWithDisabledCustomPage = ->
      isWhiteLabel && IcnIdentity.user.enable_custom_wlp_page

    isWlpWithCustomTermsNotAccepted = ->
      customWlpTerms = IcnIdentity.user.wlp_has_terms
      notAcceptedWlpTerms = !IcnIdentity.user.active_wlp_terms_accepted

      isWhiteLabel && customWlpTerms && notAcceptedWlpTerms

    needsToAcceptTerms = ->
      notNonUser = !IcnIdentity.user.non_user
      isNotPrivacyPolicyPath = $location.path() != '/privacy_policy'

      (noMainSiteTermsAccepted && (isMainSite || isWlpWithDisabledCustomPage()) || isWlpWithCustomTermsNotAccepted()) &&
      !IcnIdentity.is_imposter &&
      notNonUser &&
      isNotPrivacyPolicyPath &&
      !isModalOpen

    show = ->
      defer = $q.defer()

      if !needsToAcceptTerms()
        defer.resolve()
      else
        isModalOpen = true
        opts =
          templateUrl: "dialogs/accept_terms_dialog/accept_terms_dialog.html"
          controller: "acceptTermsDialogCtrl"
          windowClass: "accept-terms-modal"
          backdrop: "static"
          keyboard: false

        $modal.open(opts).result.then((result) ->
          defer.resolve(result)
        , (err) ->
          defer.reject(err)
        )

      defer.promise

    { show: show }
