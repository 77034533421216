angular.module('directives').directive "slidableBody", ->
  scope: {}
  templateUrl: "components/slidable/templates/slidable_body.html"
  transclude: true
  require: "^slidable"
  link: (scope, el, attr, slidableCtrl) ->
    scope.slidable = slidableCtrl

    getMaxHeight = ->
      # Plus two below to account for header and total rows
      itemLength = slidableCtrl.countItems()
      slidableItems = if itemLength > 0 then itemLength + 2 else 0

      # 100 px/item is more than enough for current and only use of slidable
      # This may need to be reconsidered if slidable is used elsewhere/in other ways
      "#{slidableItems * 100}px"

    el.css({
      "transitionProperty": "all",
      "transitionDuration": "300ms",
      "transitionTimingFunction": "cubic-bezier(0.94, 0.04, 0.03, 0.94)"
    })

    bodyOpenCSS = ->
      {
        "overflow": "visible",
        "max-height": getMaxHeight(),
        "opacity": "1"
      }

    bodyClosedCSS = {
      "overflow": "hidden",
      "max-height": "0",
      "opacity": "0"
    }

    displayBody = ->
      el.css(bodyOpenCSS())

    hideBody = ->
      el.css(bodyClosedCSS)

    setBodyDisplay = (closed) ->
      if closed then hideBody() else displayBody()

    scope.$watch ->
      slidableCtrl
    , (newVal) ->
      setBodyDisplay(newVal.closed)
    , true
