angular.module('services').factory 'ToolTips', ($sce) ->
  class IcnToolTips
    constructor: ->
      @toolTips = {}
      # Registration
      @toolTips.passwordInfo = $sce.trustAsHtml('As a security measure, we check against a database ' +
        'of real world passwords that have been exposed in various data breaches. Passwords that ' +
        'appear on this list may not be used.')

      # Investor Profile Tooltips
      @toolTips.USPerson = $sce.trustAsHtml('A U.S. person is any natural person resident in the United States.')
      @toolTips.USEntity = $sce.trustAsHtml('A U.S. person can include any trust of which any trustee is a natural ' +
        'person resident in the United States.')
      @toolTips.NextClose = $sce.trustAsHtml('Your documents are due before the close date.  This date may ' +
        'vary depending on your firm, Owl Rock or iCapital procedures. Please reach out to your representative' +
        ' to confirm.')
      @toolTips.individualName = $sce.trustAsHtml('Should include the full name of the investing account followed by ' +
        'the account type (e.g., Joint Tenants in Common, Joint with Rights of Survivorship, IRA). Joint accounts ' +
        'must include both account owners\' names.')
      @toolTips.entityName = $sce.trustAsHtml('Should include the full legal name of the investing entity.')
      @toolTips.entityFOIA = $sce.trustAsHtml( '“FOIA Investor” generally means any Investor that is directly or ' +
        'indirectly subject to either section 552(a) of Title 5, United States Code (commonly known as the ' +
        '“Freedom of Information Act”) or any similar federal, state, county or municipal public disclosure law, ' +
        'whether foreign or domestic, or any similar regulation or obligation.')
      @toolTips.entityDomicile = $sce.trustAsHtml('State (or if non-US, typically territory/province and country)' +
        ' where the entity has its legal address or principal place of business.')
      @toolTips.entityJurisdiction = $sce.trustAsHtml('State (or if non-US, typically territory/province and country)' +
        ' under whose laws the entity was formed.')
      @toolTips.entityJurisdictionCountry = $sce.trustAsHtml('Country under whose ' +
        'laws the entity was formed.')
      @toolTips.entityDomicileCountry = $sce.trustAsHtml('Country where the entity ' +
        'has its legal address or principal place of business.')
      @toolTips.clientAccountName = $sce.trustAsHtml('Individuals should include the full name of the account ' +
        '(both account owners if a joint account), followed by the type of account ( e.g., JWROS, IRA). Entities ' +
        'should include the full legal name of the entity. (Contact the custodian or Investor Relations for further ' +
        'instructions.)')
      @toolTips.custodianAccountAddendum = $sce.trustAsHtml('The Alternative Investments Addendum and Custody ' +
        'Agreement (“Addendum”) supplements the customer agreement between the investor and Fidelity and is a ' +
        'one-time requirement. If you have already submitted an Addendum to Fidelity for this particular account ' +
        'registration, check this box. If you do not check this box, an Addendum form will be generated.')
      @toolTips.custodianName = $sce.trustAsHtml('If your custodian does not appear in the list, please contact' +
        ' Investor Relations at 212 994 7333 or <a class=\'hover-underline\'>ir@icapitalnetwork.com</a>.' +
        '<br /><br />' +
        'If this list appears empty, the fund does not accept investments through custodians.' +
        ' Please change your response to the above question to \'No\' to proceed with a direct investment.')
      @toolTips.clientAccountNameSectionB = $sce.trustAsHtml('To comply with anti-money laundering policies and ' +
        'procedures, all payments must be sent from an account in the name of the Investor. For example, if the ' +
        'Investor is an individual account, the wire instructions must reflect the account belonging to that ' +
        'individual and not a jointly held account or trust account. Any cash distributions or proceeds will ' +
        'be paid to the same account.')
      @toolTips.retirementPlan = $sce.trustAsHtml('For IRAs, it is common for this response to be 100%')
      @toolTips.entityNFAAndCFTC = $sce.trustAsHtml('<strong>NFA:</strong> The National Futures Association' +
        ' (“NFA”) is the industry-wide, self-regulatory organization for the U.S. derivatives industry.<br><br>' +
        '<strong>CFTC:</strong> The Commodity Futures Trading Commission (“CFTC”) is a federal agency ' +
        'created by Congress to regulate futures trading through the Commodities Exchange Act.')

      #PAF List Tooltips
      @toolTips.pafListOnshoreOffshore = $sce.trustAsHtml('Please identify the specific vehicle in which you wish ' +
        'to invest. Typically, Onshore vehicles ' +
        'are generally designed for United States taxable investors while Offshore vehicles are most ' +
        'often for investors who are not residents of the United States. ' +
        'Certain exceptions apply and investors should consult their tax advisors.')

      #Create Investment Tooltips
      @toolTips.subbookType = $sce.trustAsHtml('<strong>Advisory/Individual Limited Partner:</strong> For clients ' +
        'of investment advisors who do not charge a brokerage fee, or for individual investors with no ' +
        'advisor.<br><br><strong>Brokerage:</strong> For clients of investment advisors or broker-dealers who ' +
        'charge a brokerage fee.')
      @toolTips.requestCapacity = $sce.trustAsHtml('Please contact our sales team in order to request capacity' +
        ' in this fund at sales@icapitalnetwork.com')

      #PAF Tooltips
      @toolTips.pafSeries = $sce.trustAsHtml('The amount of your investment committed to each fund manager.')

      #Partner Tooltips
      @toolTips.ubsProspect = $sce.trustAsHtml('To proceed with this investment, you must open a UBS account ' +
        'then select <b>"Change Account"</b> in the Actions menu.')

      #Registration Tooltips
      @toolTips.passwordSymbols = $sce.trustAsHtml('<img src="images/hover-symbols-tooltip.svg">')

      @toolTips.registerInterestedParties = $sce.trustAsHtml('Interested Parties are additional ' +
        'contacts (e.g., an accountant or lawyer) ' +
        'authorized to view an investment.')

      #Reporting Header Definitions
      @toolTips.unrealizedValueOfInvestments = $sce.trustAsHtml('<br><b>Unrealized Value of Investments' +
        '</b><br><br>The Unrealized Value of Investments is the current Net Asset Value remaining in the Private' +
        ' Access Fund, excluding Management Fees, and reflects how much the Private Access Fund is currently ' +
        'worth.<br><br>')
      @toolTips.totalValueOfInvestments = $sce.trustAsHtml('<br><b>Total Value of Investments</b><br><br>The ' +
        'Total Value of Investments represents the total realized and unrealized value the Private Access Fund has ' +
        'yielded to the investor at the current date.  It is calculated as the current total Distributions less ' +
        'Management Fee plus Unrealized Value of Investments.<br><br>')
      @toolTips.multiple = $sce.trustAsHtml('<br><b>Multiple</b><br><br>The Multiple is a private equity ' +
        'performance metric calculated by dividing the Total Value of Investments by the Amount Called.  ' +
        'Often used as an alternative to IRR, the Multiple reflects how much your investment has grown.<br><br>')
      @toolTips.managementFees = $sce.trustAsHtml('<br><b>Management Fees</b><br><br>Management Fees are quarterly ' +
        'payments made by investors to the Private Access Fund’s manager for investment and portfolio management ' +
        'services.  In the investment stage of the Private Access Fund, the fee is based on the capital commitment; ' +
        'as the fund matures, the fee is then based on the invested capital of the Private Access Fund.  Specific ' +
        'details on management fees are provided in the Limited Partnership Agreement of the Private Access ' +
        'Fund.<br><br>')
      @toolTips.irr = $sce.trustAsHtml('<br><b>IRR</b><br><br>IRR is the performance calculation that is used for ' +
        'private equity investments.  Technically, it is the net present value of a set of cash flows when the sum ' +
        'of those flows is set to zero.  An IRR is the return you have earned from the Private Access Fund\’s ' +
        'activity from inception to the current effective date.  The IRR is calculated as an annualized effective ' +
        'compounded rate of return measure and takes the time value of money into account. Although an IRR ' +
        'calculation result is often similar to the Time Weighted Return used in the public markets, they are two ' +
        'different performance calculations.  IRR is the approved calculation for private equity performance by the ' +
        'CFA Institute.<br><br>')
      @toolTips.distributions = $sce.trustAsHtml('<br><b>Distributions</b><br><br>Distributions represent the total ' +
        'funds that the Private Access Fund has return to the investors.  This is how much you have received from ' +
        'your investment in the Private Access Fund to-date.  Distributions occur primarily after investment ' +
        'realization, but can also occur after income events, such as dividend on interest payments.<br><br>')
      @toolTips.amountCalled = $sce.trustAsHtml('<br><b>Amount Called</b><br><br>Amount Called represents the total ' +
        'funds that the Private Access Fund has called from an investor’s commitment during the life of the Private ' +
        'Access Fund.  This is how much you have invested into the Private Access Fund.<br><br>')
      @toolTips.investorException = $sce.trustAsHtml('Investor exception.')
      @toolTips.investmentNotes = $sce.trustAsHtml('Notes')
      @toolTips.custodianTooltip = $sce.trustAsHtml('')
      @toolTips.sendToFidelityModal = $sce.trustAsHtml('If you select a future settlement, the ' +
      'investment amount will be wired to the fund sponsor on the date selected.<br><br>'  +
      'If no future funding date is selected, funds will be wired to the fund sponsor the same ' +
      'day the transaction is processed by Fidelity and determined to be in good order.')
      @toolTips.investmentNewRemediatonComment = $sce.trustAsHtml('New Remediaton Comment')
      @toolTips.beneficialOwnerDescription = $sce.trustAsHtml('Any authorized signer and/or control person ' +
        'who is also a beneficial owner should be included.')
      @toolTips.addressSpecifications = $sce.trustAsHtml('Residential or business street addresses are accepted. ' +
        'P.O. boxes are not accepted.')
      @toolTips.individualIncome = $sce.trustAsHtml('For purposes of this item, “net worth” means the excess of ' +
        'total assets at fair market value, including automobiles and other personal property and ' +
        'property owned by a spouse, but excluding the value of the primary residence of such natural person, ' +
        'over total liabilities. For this purpose, the amount of any mortgage or ' +
        'other indebtedness secured by an Investor’s primary residence should not be included ' +
        'as a “liability”, except to the extent the fair market value of the ' +
        'residence is less than the amount of such mortgage or other indebtedness.')
      @toolTips.viewWireInstructionsBase = $sce.trustAsHtml('View wire instructions')
      @toolTips.deleteDocumentRequirement = $sce.trustAsHtml('Delete Document Requirement')
      @toolTips.deleteDocument = $sce.trustAsHtml('Delete Document')
    fetch: ->
      @toolTips

  new IcnToolTips()
