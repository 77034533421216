angular.module('directives').directive 'updateOnEnter', ->
  restrict: 'A'
  require: 'ngModel'

  link: (scope, element, attrs, ctrl) ->
    element.on("keyup", (ev) ->
      if ev.keyCode == 13
        ctrl.$commitViewValue()
        scope.$apply(ctrl.$setDirty())
    )
