angular.module("icn.paf_admin")
.factory "DtccFilesService", ($resource, $http, $q) ->
  DtccFilesService =
    $resource("/api/v1/dtcc_files", {},
      exportDtccPositionFiles:
        url: "/api/v1/dtcc_files/export_dtcc_position_files"
        method: "GET"
        isArray: false
      exportHfDtccActivityFiles:
        url: "/api/v1/dtcc_files/export_hf_dtcc_activity_files"
        method: "GET"
        isArray: false
      exportPeDtccActivityFiles:
        url: "/api/v1/dtcc_files/export_pe_dtcc_activity_files"
        method: "GET"
        isArray: false
      exportRedemptionsFiles:
        url: "/api/v1/dtcc_files/export_redemption_files"
        method: "GET"
        isArray: false
    )

  DtccFilesService::exportDtccPositionFiles = (pafIds, custodianIds, effectiveDate, filename, fileType, reportClosingShares) ->
    DtccFilesService.exportDtccPositionFiles({
      'paf_ids[]': pafIds
      'custodian_ids[]': custodianIds
      date: effectiveDate
      filename: filename
      file_type: fileType
      report_closing_shares: reportClosingShares
    }).$promise

  DtccFilesService::exportHfDtccActivityFiles = (
    pafIds
    custodianIds
    effectiveDate
    filename
    fileType
    reportClosingShares
  ) ->
    DtccFilesService.exportHfDtccActivityFiles({
      'paf_ids[]': pafIds
      'custodian_ids[]': custodianIds
      date: effectiveDate
      filename: filename
      file_type: fileType
      report_closing_shares: reportClosingShares
    }).$promise

  DtccFilesService::exportPeDtccActivityFiles = (
    pafId
    activityType
    activityIds
    custodianIds
    filename
    fileType
  ) ->
    DtccFilesService.exportPeDtccActivityFiles({
      paf_id: pafId
      activity: activityType
      'activity_ids[]': activityIds
      'custodian_ids[]': custodianIds
      filename: filename
      file_type: fileType
    }).$promise

  DtccFilesService::exportRedemptionsFiles = (
    pafIds
    custodianIds
    effectiveDate
    filename
    fileType
  ) ->
    DtccFilesService.exportRedemptionsFiles({
      'paf_ids[]': pafIds
      'custodian_ids[]': custodianIds
      date: effectiveDate
      filename: filename
      file_type: fileType
    }).$promise

  return new DtccFilesService
