angular.module('directives').directive 'fixedTableHeader', ($timeout, $compile) ->
  restrict: 'A'
  scope:
    items: '='
    full: '='
  link:
    post: (scope, elem, attrs) ->
      $.fn.fixTableHeader = ->
        @each ->
          $this = $(this)
          $t_fixed = undefined

          init = ->
            if !scope.full
              $this.wrap '<div class="container"></div>'
              
            $t_fixed = angular.element($this).clone()
            $compile($t_fixed.find('thead'))(scope.$parent)
            $t_fixed.find('tbody').remove().end().addClass('fixed-header').insertBefore $this
            $t_fixed.find('tfoot').remove().end().addClass('fixed-header').insertBefore $this
            resizeFixed()
            initEventListeners()
            return

          resizeFixed = ->
            $t_fixed.find('th').each (index) ->
              $(this).css 'width', $this.find('th').eq(index).outerWidth() + 'px'
              return
            return

          scrollFixed = ->
            offset = $(this).scrollTop()
            tableOffsetTop = $this.offset().top - 75
            tableOffsetBottom = tableOffsetTop + $this.height() - $this.find('thead').height()
            if offset < tableOffsetTop or offset > tableOffsetBottom
              $t_fixed.hide()
            else if offset >= tableOffsetTop and offset <= tableOffsetBottom and $t_fixed.is(':hidden')
              $t_fixed.show()
            return

          initEventListeners = ->
            $t_fixed.on 'click', 'thead th', (e) ->
              resizeFixed()

          $(window).resize resizeFixed

          scope.$watch 'items', (oldV, newV) ->
            $timeout(resizeFixed, 0)

          elem.on 'click', 'thead th', (e) ->
            resizeFixed()

          $(window).scroll scrollFixed
          init()
          return

      elem.fixTableHeader()
