angular.module('controllers').controller "LockedAccountCtrl",
  ($scope, $modalInstance) ->
    $scope.header = 'Account Locked'

    $scope.$on('$routeChangeStart', (event, next, current) ->
      unless next.originalPath == '/logout'
        event.preventDefault()
    )

    $scope.accept = ->
      $modalInstance.dismiss('logout')

    $scope.$on('modal.closing', (event, reason) ->
      event.preventDefault() unless reason == 'logout'
    )
