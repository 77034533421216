angular.module('services').factory "QualificationModal",
  ($q, $location, $modal, IcnIdentity) ->

    isModalOpen = false

    needsToUpdateQualification = ->
      isNotPrivacyPolicyPath = $location.path() != '/privacy_policy'
      noQualificationsOrAcknowledgementInvestor = _.isEmpty(IcnIdentity.user.investor_qualification_standard_ids) ||
        IcnIdentity.user.user_acknowledgements.investor

      IcnIdentity.user &&
      !IcnIdentity.is_imposter &&
      IcnIdentity.user.isInvestor &&
      noQualificationsOrAcknowledgementInvestor &&
      isNotPrivacyPolicyPath &&
      !isModalOpen

    show = ->
      defer = $q.defer()

      if !needsToUpdateQualification()
        defer.resolve()
      else
        isModalOpen = true

        opts =
          templateUrl: "dialogs/qualification_dialog/qualification_dialog.html"
          controller: "QualificationDialogCtrl"
          windowClass: "qualification-modal"
          backdrop: "static"

        $modal.open(opts).result.catch((response) -> defer.resolve())

      defer.promise

    { show: show }
