angular.module('controllers').controller "SetRejectionReasonCtrl",
  ($scope, $modalInstance, Redemptions, redemption, pafId) ->
    $scope.cancelDialog = -> $modalInstance.dismiss()

    $scope.rejection =
      reason: ""

    $scope.save = ->
      Redemptions.reject(pafId, redemption, $scope.rejection.reason).then( ->
        $modalInstance.close()
      , (response) ->
        $modalInstance.dismiss(response.data)
      )
