angular.module("icn.paf_admin").factory "PrivateAccessFund",
  ($q, IcnIdentity, PafAdminSvcApi, Investment, InvestmentService,
   IcnUtility, IcnBase, ContactCardModel) ->

    class PrivateAccessFund
      constructor: (config) ->
        IcnBase.setup this, config
      isFundWithSeries: ->
        @series.length > 0

      getInvestments: ->
        Investment.fetch this

      getContactCardsByInvestments: (query) ->
        dfd = $q.defer()
        InvestmentService.forInvestorRelations(this.id, query).then (investments) ->
          not_canceled = _.filter investments, (investment) -> !investment.canceled

          unique_not_canceled = _.uniq not_canceled, (investment) ->
            investment.contact_card.id

          dfd.resolve unique_not_canceled.map (investment) ->
            investment.contact_card.profile_name = investment.investor_profile.name
            new ContactCardModel(investment.contact_card)
        dfd.promise

      getInvestmentsCsv: ->
        dfd = $q.defer()
        @getInvestments().then (investments) ->
          header = "Investor Name, Investor Email, Entity Name, Fund ID, Investor ID, Investment ID, Commitment Amount"
          lines = _.map investments, (investment) ->
            investment.toCsv()
          dfd.resolve "#{header}\n#{lines.join('\n')}"
        dfd.promise

      callToApprove: ->
        dfd = $q.defer()
        PafAdminSvcApi.getCallToApprove(this).then (res) ->
          dfd.resolve res.callToApprove
        dfd.promise

      showLockingMessage: ->
        !@allLockedOrNotScheduledOrApproved() && !@closed

      inclusiveFeeText: ->
        return "Note this fund uses inclusive fees" if @inclusive_fees
        "Note this fund uses exclusive fees"

      configURL: ->
        "/paf_admin/pe/funds/#{@id}"

    _fundsPromise = null

    PrivateAccessFund.fetchAllPrivateEquities = ->
      _fundsPromise = null
      return _fundsPromise if _fundsPromise
      dfd = $q.defer()
      PafAdminSvcApi.getPrivateEquityFunds().then (funds) ->
        dfd.resolve _.map funds, (fund) -> new PrivateAccessFund(fund)
      _fundsPromise = dfd.promise

    PrivateAccessFund.fetchOne = (id) ->
      dfd = $q.defer()
      if !id
        dfd.resolve null
      else
        PafAdminSvcApi.getPrivateAccessFund(id).then (fund) ->
          dfd.resolve if fund then new PrivateAccessFund(fund) else null

      dfd.promise

    PrivateAccessFund
