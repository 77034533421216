angular.module('services').factory "UploadLimit", ($modal) ->

  numberUploadedDocuments = (documents) ->
    docs = documents.filter (i) ->
      i.file
    docs.length

  full = (fund, documents) ->
    !fund.private_access_fund && numberUploadedDocuments(documents) >= 3

  showNotification = ->
    opts =
      templateUrl: "dialogs/custom_notify_dialog/custom_notify_dialog.html"
      controller: "customNotifyDialogCtrl"
      windowClass: "small-modal"
      resolve:
        header: ->
          "Document Limit Reached"

        msg: ->
          "Premium Profiles are allowed a maximum of 3 documents added to their profile. Please contact your account representative if more documents are needed."

        btn_label: -> ""

    $modal.open opts

  showNotification: showNotification
  full: full
