(() => {
  angular
    .module('services')
    .factory('FundDocuments', function ($resource, $q, $http) {
      const resourceParameters = {
        url: '/api/v1/funds/:fundId/fund_documents',

        defaults: {
          fundId: '@fundId',
        },

        actions: {
          all: {
            method: 'GET',
            isArray: true,
          },
        },
      };

      const resource = $resource(
        resourceParameters.url,
        resourceParameters.defaults,
        resourceParameters.actions
      );

      const all = (fundId) => resource.all({ fundId }).$promise;

      const clients = (fundId, params, timeout = null) =>
        $http({
          method: 'get',
          url: `/api/v1/funds/${fundId}/fund_documents/clients`,
          params,
          timeout,
        });

      return {
        all,
        clients,
      };
    });
}).call(this);
