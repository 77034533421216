angular.module('directives').directive "currencyCell", ->
  scope:
    type: "="
    amount: "="
  link: (scope, el, attrs) ->
    currencies = {
      "CAD": "C$",
      "EUR": "€",
      "GBP": "£",
      "JPY": "¥",
      "USD": "$",
    }
    scope.symbol = currencies[scope.type] || scope.type
  template: "<span class='pull-left'>{{ symbol }}</span>{{ amount | currency : '' : 0 }}"
