angular.module('services').factory 'SiteContentService', ($resource) ->
  class SiteContentService
    service =
      $resource('/api/v1/site_contents/:id',
        id: "@id"
      ,
        current:
          url: "/api/v1/site_contents/current"
          method: "GET"
          isArray: false
          params:
            approval: "@approval"
    )

    current: (approval) ->
      service.current(
        {}
      ,
        approval: approval
      ).$promise

  return new SiteContentService()
