angular.module('services').factory "InvestorProfileService", [
  "$resource", "$q", "IcnIdentity"
  ($resource, $q, IcnIdentity) ->
    resource = $resource("/api/v1/investor_profiles/:id",
      id: "@id"
    ,
      update:
        method: "PATCH"
        url: "/api/v1/investor_profiles/:id"
        params:
          id: "@id"
          batchupdate: "#@batch_update"

      create:
        method: "POST"
        url: "/api/v1/investor_profiles"

      remove:
        method: "DELETE"
        url: "/api/v1/investor_profiles/:id"

      show:
        method: "GET"
        url: "/api/v1/investor_profiles"
        id: "@qpcId"

      profileCompleted:
        method: "GET"
        url: "/api/v1/investor_profiles/is_completed"

      mine:
        url: "/api/v1/investor_profiles"
        method: "GET"

      current_user_has_profile:
        url: "/api/v1/investor_profiles/current_user_has_profile"
        method: "GET"

      fields:
        method: "GET"
        url: "/api/v1/investor_profiles/fields"

      wireInstructions:
        method: "GET"
        url: "/api/v1/investor_profiles/:id/wire_instructions"

      updateReferralCode:
        method: "PATCH"
        url: "/api/v1/investor_profiles/:id/update_referral_code"
        params:
          id: "@id"

      prospectInvestorProfiles:
        method: "GET"
        url: "/api/v1/investor_profiles"

      signerTypes:
        method: "GET"
        url: "/api/v1/investor_profiles/signer_types"
    )

    signerTypes = ->
      resource.signerTypes()

    prospectInvestorProfiles = (private_access_fund_id) ->
      params = {
        private_access_fund_id: private_access_fund_id,
        prospect_status: true
      }
      resource.prospectInvestorProfiles(params).$promise

    mine = (private_access_fund_id = null) ->
      if private_access_fund_id
        params = {private_access_fund_id: private_access_fund_id}
      else
        params = {}
      resource.mine(params)

    current_user_has_profile = (private_access_fund_id = null) ->
      if private_access_fund_id
        params = { private_access_fund_id: private_access_fund_id }
      else
        params = {}
      resource.current_user_has_profile(params)

    show = (id) ->
      resource.get
        id: id

    remove = (id) ->
      resource.remove
        id: id

    update = (investor_profile, invProfId, batchUpdateOptions) ->
      if invProfId
        if investor_profile.hasOwnProperty('entity_domicile_country') &&
           investor_profile.hasOwnProperty('entity_domicile_state')
          if investor_profile.entity_domicile_state == "" ||
              _.isNull(investor_profile.entity_domicile_state)
            investor_profile.entity_domicile = investor_profile.entity_domicile_country
          else
            investor_profile.entity_domicile = investor_profile.entity_domicile_state.concat(", ")
              .concat(investor_profile.entity_domicile_country)

        if investor_profile.hasOwnProperty('entity_jurisdiction_of_organization_country') &&
           investor_profile.hasOwnProperty('entity_jurisdiction_of_organization_state')
          if investor_profile.entity_jurisdiction_of_organization_state == "" ||
              _.isNull(investor_profile.entity_jurisdiction_of_organization_state)
            investor_profile.entity_jurisdiction_of_organization = investor_profile.entity_jurisdiction_of_organization_country
          else
            investor_profile.entity_jurisdiction_of_organization = investor_profile.entity_jurisdiction_of_organization_state.concat(", ")
              .concat(investor_profile.entity_jurisdiction_of_organization_country)

        resource.update
          id: invProfId
          profile: investor_profile
          batch_update: batchUpdateOptions
      else
        resource.create
          profile: investor_profile

    profileCompleted = ->
      resource.profileCompleted()

    fields = ->
      resource.fields()

    userMustCompleteProfile = (user) ->
      !user.isAdminOrIr

    enforceFieldRequirements = (user) ->
      !user.isAdminOrIr

    typeShortened = (type) ->
      switch type
        when 'Joint Account: Tenants in Common' then type = 'JTIC'
        when 'Joint Account: Rights of Survivorship' then type = 'JTWROS'
        when 'Joint Account: Community Property' then type = 'JTCP'
        else type

    generateIpName = (ip) ->
      if IcnIdentity.showAccountNum() && !_.isEmpty(ip.account_num)
        return ip.account_num + ': ' + ip.name
      else
        return ip.name

    generateIpNameFromNameAndAccountNum = (ipName, accountNum) ->
      if IcnIdentity.showAccountNum() && !_.isEmpty(accountNum)
        return accountNum + ': ' + ipName
      else
        return ipName

    generateIpNameWithAccountNum = (ip) ->
      if IcnIdentity.showAccountNum() && !_.isEmpty(ip.account_num)
        return ip.account_num + ': ' + ip.name
      else
        if !IcnIdentity.showAccountTypeInAddInvestorModal() || ip.id == 0 || ip.section_a_individual_type == ''
          return ip.name
        else
          return typeShortened(ip.section_a_individual_type) + " | " +  ip.name

    updateFormPfClassification = (invProfId, classification) ->
      resource.update
        id: invProfId
        profile: { form_pf_classification: classification }

    wireInstructions = (invProfId) ->
      resource.wireInstructions(id: invProfId).$promise

    updateReferralCode = (id, referral_code) ->
      resource.updateReferralCode(
        id: id,
        referral_code: referral_code
      )

    return {
      show: show
      update: update
      profileCompleted: profileCompleted
      mine: mine
      current_user_has_profile: current_user_has_profile
      remove: remove
      fields: fields
      userMustCompleteProfile: userMustCompleteProfile
      generateIpName: generateIpName,
      generateIpNameFromNameAndAccountNum: generateIpNameFromNameAndAccountNum,
      generateIpNameWithAccountNum: generateIpNameWithAccountNum,
      updateFormPfClassification: updateFormPfClassification,
      enforceFieldRequirements: enforceFieldRequirements,
      wireInstructions: wireInstructions,
      updateReferralCode: updateReferralCode,
      prospectInvestorProfiles: prospectInvestorProfiles,
      signerTypes: signerTypes
    }
]
