angular.module('directives').directive 'fixTableHeadAndFoot', ($timeout, $compile) ->
  restrict: 'A'
  scope:
    headItems: '='
    footItems: '='
  link:
    post: (scope, elem, attrs) ->
      $.fn.fixTableHeadAndFoot = ->
        @each ->
          $this = $(this)
          $header_fixed = undefined
          $foot_fixed = undefined

          init = ->
            $this.wrap '<div class="container"></div>'
            duplicateHeader()
            resizeFixedHeader()
            duplicateFooter()
            resizeFixedFooter()
            initEventListeners()
            return

          duplicateHeader = ->
            $header_fixed = angular.element($this).clone()
            $compile($header_fixed.find('thead'))(scope.$parent)
            $header_fixed.find('tbody').remove().end().addClass('fixed-header').insertBefore $this
            $header_fixed.find('tfoot').remove().end().addClass('fixed-header').insertBefore $this

          duplicateFooter = ->
            $foot_fixed = angular.element($this).clone()
            $compile($foot_fixed.find('tfoot'))(scope.$parent)
            $foot_fixed.find('tbody').remove().end().addClass('footer-fixed-at-bottom').insertBefore $this
            $foot_fixed.find('thead').remove().end().addClass('footer-fixed-at-bottom').insertBefore $this

          resizeFixedHeader = ->
            $header_fixed.find('th').each (index) ->
              $(this).css 'width', $this.find('th').eq(index).outerWidth() + 'px'
              return
            return

          resizeFixedFooter = ->
            $foot_fixed.find('td').each (index) ->
              $(this).css 'width', $this.find('td').eq(index).outerWidth() + 'px'
              return
            return

          scrollFixedHeader = ->
            offset = $(this).scrollTop()
            tableOffsetTop = $this.offset().top - 75
            tableOffsetBottom = tableOffsetTop + $this.height() - $this.find('thead').height()
            if offset < tableOffsetTop
              $header_fixed.hide()
            else if offset >= tableOffsetTop and offset <= tableOffsetBottom and $header_fixed.is(':hidden')
              $header_fixed.show()
            return

          scrollFixedFooter = ->
            if insideTable()
              stickFooterAtBottom()
              $foot_fixed.show()
            else if afterTable()
              stickFooterOnTop()
              $foot_fixed.show()
            else
              stickFooterAtBottom()
              $foot_fixed.hide()
            return

          insideTable = ->
            screen_height_offset = $(this).scrollTop() + $(this).innerHeight()
            tableOffsetTop = $this.offset().top
            tableOffsetBottom = tableOffsetTop + $this.height()
            compensation = $this.find('thead').height() * 3
            return screen_height_offset - compensation >= tableOffsetTop and screen_height_offset <= tableOffsetBottom

          afterTable = ->
            tableOffsetTop = $this.offset().top
            tableOffsetBottom = tableOffsetTop + $this.height()
            compensation =  75 + $this.find('tfoot').height() + $this.find('thead').height()
            return $(this).scrollTop() > tableOffsetBottom - compensation

          stickFooterAtBottom = ->
            $foot_fixed.addClass('footer-fixed-at-bottom').removeClass('footer-fixed-on-top')

          stickFooterOnTop = ->
            $foot_fixed.addClass('footer-fixed-on-top').removeClass('footer-fixed-at-bottom')

          initEventListeners = ->
            $header_fixed.on 'click', 'thead th', (e) ->
              resizeFixedHeader()

          $(window).resize ->
            resizeFixedHeader()
            resizeFixedFooter()

          scope.$watch 'headItems', (oldV, newV) ->
            $timeout(resizeFixedHeader, 0)

          scope.$watch 'footItems', (oldV, newV) ->
            $timeout(resizeFixedFooter, 0)

          elem.on 'click', 'thead th', (e) ->
            resizeFixedHeader()

          $(window).scroll ->
            scrollFixedHeader()
            scrollFixedFooter()
          init()
          return

      elem.fixTableHeadAndFoot()
