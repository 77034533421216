// Generated by CoffeeScript 2.4.1
(function() {
  angular.module('controllers').controller("SendCountersignDialog", function($scope, $modalInstance, $window, close, Close, privateAccessFund) {
    $scope.data = {
      sign_method: 'electronic'
    };
    $scope.paf = privateAccessFund;
    $scope.close = close;
    $scope.investorCount = close.investment_count;
    $scope.totalCommitment = close.investments_commitment_total;
    $scope.onCancel = function() {
      return $modalInstance.dismiss();
    };
    return $scope.onSubmit = function(form) {
      var data, params;
      if (form.$valid) {
        data = $scope.data;
        params = {
          id: $scope.close.id,
          sign_method: data.sign_method,
          gp_signatory_name: data.name,
          gp_signatory_title: data.title,
          gp_signatory_email: data.email
        };
        return Close.sendCounterSignature(params).$promise.then(function(result) {
          return $modalInstance.close(result);
        });
      }
    };
  });

}).call(this);
