angular.module('services').factory "SubscriptionReviewExportInvestmentsService", ($resource) ->
  resource = $resource "/api/v1/subscription_exported_files/:private_access_fund_id", {private_access_fund_id: '@id'},
    index:
      url: '/api/v1/subscription_exported_files',
      method: 'GET'
    create:
      url: '/api/v1/subscription_exported_files',
      method: 'POST'

    create = (pafId, type = 'investments', closeId = null, approvalStatus = null) ->
      resource.create(
        private_access_fund_id: pafId
        close_id: closeId
        approval_status: approvalStatus
        export_type: type
      ).$promise

    index = (pafId, type = 'investments', closeId = null, approvalStatus = null) ->
      resource.index(
        private_access_fund_id: pafId
        close_id: closeId
        approval_status: approvalStatus
        export_type: type
      ).$promise

  return {
    create: create
    index: index
  }
