angular.module("icn.paf_admin")
.factory "FundsCapitalReportsService", ($resource, $http, $q) ->
  FundsCapitalReportsService =
    $resource("/api/v1/funds_capital_reports/", {},
      exportHFundsCapitalReports:
        url: "/api/v1/funds_capital_reports/export_hfunds_capital_reports"
        method: "GET"
        isArray: false
      exportPEFundsCapitalReports:
        url: "/api/v1/funds_capital_reports/export_pe_funds_capital_reports"
        method: "GET"
        isArray: false
      filtersByPafs:
        url: "/api/v1/funds_capital_reports/filters_by_pafs"
        method: "GET"
        isArray: false
      custodianByPaf:
        url: "/api/v1/funds_capital_reports/custodians_by_pafs"
        method: "GET"
        isArray: false
    )

  FundsCapitalReportsService::exportHFundsCapitalReports = (
    pafIds,
    custodianIds,
    fromDate,
    toDate,
    filename,
    firmIds,
    pafGroupsIds,
    wlpIds,
    transactionType,
    fileType
  ) ->
    FundsCapitalReportsService.exportHFundsCapitalReports({
      'paf_ids[]': pafIds
      'custodian_ids[]': custodianIds
      'firm_ids[]': firmIds
      'paf_groups_ids[]': pafGroupsIds
      'wlp_ids[]': wlpIds
      transaction_type: transactionType
      filename: filename
      file_type: fileType
      from_date: fromDate
      to_date: toDate
    }).$promise

  FundsCapitalReportsService::exportPEFundsCapitalReports = (
    pafIds,
    custodianIds,
    closedDate,
    filename,
    firmIds,
    wlpIds,
    transactionType,
    fileType,
    isRounded,
    showNavPerShare,
    timeQTD,
    timeYTD,
    timeITD
  ) ->
    FundsCapitalReportsService.exportPEFundsCapitalReports({
      'paf_ids[]': pafIds
      'custodian_ids[]': custodianIds
      'firm_ids[]': firmIds
      'wlp_ids[]': wlpIds
      transaction_type: transactionType
      filename: filename
      file_type: fileType
      closed_date: closedDate
      is_rounded: isRounded,
      show_nav_per_share: showNavPerShare,
      time_QTD: timeQTD,
      time_YTD: timeYTD,
      time_ITD: timeITD
    }).$promise

  FundsCapitalReportsService::filtersByPafs = (
    pafIds
  ) ->
    FundsCapitalReportsService.filtersByPafs({
      'paf_ids': (pafIds || []).join(',')
    }).$promise

  FundsCapitalReportsService::custodianByPaf = (
    pafIds
  ) ->
    FundsCapitalReportsService.custodianByPaf({
      'paf_ids': (pafIds || []).join(',')
    }).$promise

  return new FundsCapitalReportsService
