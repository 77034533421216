angular.module('services').factory "AmlDocumentService", [
  "$resource"
  "ngDialog"
  "$q"
  "Session"
  "$upload"
  "Users"
  ($resource, ngDialog, $q, Session, $upload, Users) ->
    service = $resource("/api/v1/approvers/aml_document_request/:id",
      id: "@id"
      message_for_user: "@message_for_user"
      status: "@status"
    ,
      forUser:
        url: "/api/v1/users/:user_id/aml_document_requests"
        method: "GET"
        isArray: false
        params:
          user_id: "@user_id"
    )

    getFileType = (file_name) ->
      return 'empty' if file_name == ''
      ext = file_name.split('.').pop()
      return 'doc' if _.contains ['doc', 'docx'], ext
      return 'xls' if _.contains ['xls', 'xlsx'], ext
      return 'ppt' if _.contains ['ppt', 'pptx'], ext
      return 'pdf' if _.contains ['pdf'], ext
      return 'other'

    statusMap = (document) ->
      switch document.status
        when 'uploaded'
          return 'primary'
        when 'signed'
          return 'primary'
        when 'required'
          return 'warning'
        when 'signature_needed'
          return 'warning'

    loadDocuments = (user_id) ->
      dfd = $q.defer()
      if user_id
        service.forUser(user_id: user_id).$promise.then (docs) -> dfd.resolve(docs)
      else
        Session.requestCurrentUser()
        .then (d) ->
          service.forUser(user_id: d.id).$promise.then (docs) -> dfd.resolve(docs)
      dfd.promise

    return (
      getFileType: getFileType
      statusMap: statusMap
      loadDocuments: loadDocuments
    )
]
