angular.module('services').factory "Hits", ($resource) ->
  service = $resource("/api/v1/hits")

  create = (key, hitable, hitableId, note, associated_keys) ->
    service.save
      key: key
      hitable: hitable
      hitable_id: hitableId
      note: note
      associated_keys: associated_keys
  {
    create: create
  }
