angular.module('filters').filter 'numeric', (numberFilter) ->
  determineDecimalsNeeded = (val, minDecimals, maxDecimals) ->
    flexDecimals = undefined
    # Set limits to zero if undefined
    minDecimals = minDecimals or 0
    maxDecimals = maxDecimals or 0
    # Check if there's a value and a decimal range
    if val and maxDecimals
      # Start with 10 to power of maxDecimals
      startFractal = 10 ** maxDecimals
      # Decrease until we reach one decimal place (called fractal here)
      while startFractal >= 1
        # Multiply and check last figure
        if Math.round(val * startFractal) % 10 != 0
          # Not a zero, we need this figure
          break
        # Next round
        startFractal /= 10
      # Number of decimals equals number of zeros of startFractal
      flexDecimals = startFractal.toString().length - 1
      if flexDecimals <= minDecimals
        flexDecimals = minDecimals
    else
      flexDecimals = minDecimals
    flexDecimals

  return (val, minDecimals, maxDecimals) ->
    val = val || 0

    decimals = determineDecimalsNeeded(val, minDecimals, maxDecimals)

    return numberFilter(val, decimals)
