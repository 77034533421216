angular.module('services').factory "ButterbarService", ($rootScope) ->
  force = ->
    $rootScope.butterbarConfig.force = true

  removeForce = ->
    $rootScope.butterbarConfig.force = false

  setMessage = (message) ->
    $rootScope.butterbarConfig.message = message

  clearMessage = ->
    $rootScope.butterbarConfig.message = ''

  return {
    force: force,
    removeForce: removeForce,
    setMessage: setMessage,
    clearMessage: clearMessage
  }
