angular.module('controllers').controller "sendRedemptionDialogCtrl",
  ($scope, $modal, $modalInstance, ModalDialogInstancesObserver, redemption,
  privateAccessFundId, Redemptions, TrackingService, SubscriptionDocumentDefinitionService,
  $location, urlBuilder, AgreementEmbeddedExperience) ->
    $scope.redemption = redemption
    $scope.investment = $scope.redemption.investment
    $scope.paf_strict_signature_method = $scope.investment.paf_strict_signature_method
    $scope.paf_sign_method = $scope.investment.paf_subscription_sign_method
    $scope.privateAccessFundId = privateAccessFundId
    $scope.errors = null

    # dismiss modal dialog box when navigate to other page
    ModalDialogInstancesObserver.setModalDialogInstance($modalInstance)

    init = ->
      getSignerConfigurations()

      if $scope.manualSignatureOnly()
        $scope.redemption.sign_method = 'manual'
      else
        $scope.redemption.sign_method = 'electronic'

    $scope.cancelDialog = ->
      $modalInstance.dismiss()

    $scope.custodianManualSignatureOnly = ->
      ($scope.investment.custodian &&
        $scope.investment.custodian.manual_signature &&
        !$scope.paf_strict_signature_method)

    $scope.fundManualSignatureOnly = ->
      $scope.paf_sign_method == 'manual_only'

    $scope.fundManualAndElectronic = ->
      $scope.paf_sign_method == 'manual_and_electronic'

    $scope.manualSignatureOnly = ->
      $scope.custodianManualSignatureOnly() || $scope.fundManualSignatureOnly()

    $scope.allowManualSignature = ->
      $scope.manualSignatureOnly() || $scope.fundManualAndElectronic()

    $scope.validForm = ->
      valid = false
      if !$scope.redemption.sign_method
        $scope.errors = "Please choose Electronic or Manual signing method."
      else
        valid = true
      valid

    $scope.sendRedemption = ->
      return unless $scope.validForm()
      Redemptions.sendAgreement($scope.privateAccessFundId, $scope.redemption.id, {
        sign_method: $scope.redemption.sign_method, from_angular: true, signer_data: signerConfigurationData(),
      }).then (data) ->
        redemption.redemption_document = data.redemption.redemption_document
        redemption.status = data.redemption.status
        redemption.display_status = data.redemption.display_status
        redemption.signature_vendor = data.redemption.signature_vendor

        if $scope.preparerBeingUsed
          AgreementEmbeddedExperience.handlePreparation(
            $scope.privateAccessFundId,
            redemption.redemption_document.id,
            redemption.sign_method,
            currentPageName(),
          )
        else if redemption.redemption == 'manual' && redemption.redemption == 'docusign'
          AgreementEmbeddedExperience.handleManualGeneration(
            $scope.privateAccessFundId,
            redemption.redemption_document.id,
            currentPageName(),
          )
        else if redemption.sign_method == 'manual'
          window.open(data.redemption.redemption_temp_file_url)

        $modalInstance.close(redemption)

      TrackingService.clickLink('Fund ID: ' + $scope.privateAccessFundId, redemption.id +
        "/Click in Send Redemption with " + redemption.sign_method + " signing")

    currentPageName = ->
      regex_investment_management = new RegExp('investment_management')
      if regex_investment_management.test($location.path())
        "investment_management"
      else
        "client_management"

    getSignerConfigurations = ->
      return unless $scope.redemption.subscription_document_definition?.id

      SubscriptionDocumentDefinitionService.retrieveSignerConfigurations(
        id: $scope.redemption.subscription_document_definition.id, investment_id: $scope.investment.id,
      ).$promise.then (response) ->
        $scope.signerConfigurations = response.signer_configurations
        $scope.preparerBeingUsed = response.preparer_being_used

    signerConfigurationData = () ->
      signerData = {};

      _.each($scope.signerConfigurations, (signerConfiguration) ->
        signerData[signerConfiguration.role_name] = {
          phone: signerConfiguration.phone?.phone_iso,
          email: signerConfiguration.email,
        }
      )

      signerData;

    $scope.submitDisabled = () ->
      $scope.signerConfigurations?.some((configuration) -> !configuration.email)

    init()
