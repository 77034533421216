angular.module('controllers').controller 'IR-InvestorProfileContactInformation-EditInvestorProfile-Ctrl',
  ($scope, $modal, $routeParams, $location, InvestmentResource, InvestorProfileService, IcnIdentity) ->

    $scope.batchUpdateOptions = {}

    init = ->
      refreshBatchUpdateOptions()
      $scope.investment = InvestmentResource.get(id: $routeParams.investmentId)

    refreshBatchUpdateOptions = ->
      $scope.batchUpdateOptions.live = ($routeParams.live == 'true')
      $scope.batchUpdateOptions.frozen = ($routeParams.frozen == 'true')

    openBatchUpdateModal = ->
      if $scope.investment.investor_profile.original_id && IcnIdentity.isPafAdmin() && ($routeParams.live == undefined || $routeParams.frozen == undefined)
        $modal.open(
          templateUrl: "areas/no_tab/investor_profile/dialogs/investor_profile_save_dialog.html"
          controller: "InvestorProfileSaveDialogCtrl"
          windowClass: "small-modal"
          backdrop  : 'static'
          keyboard  : false
        ).result.then (batchUpdateOptions) ->
          $scope.batchUpdateOptions.live = batchUpdateOptions.live
          $scope.batchUpdateOptions.frozen = batchUpdateOptions.frozen

    $scope.saveInvestorProfile = ->
      InvestorProfileService.update($scope.investment.investor_profile, $scope.investment.investor_profile.id, $scope.batchUpdateOptions).$promise.then (res) ->
        $location.search('live', $scope.batchUpdateOptions.live.toString())
        $location.search('frozen', $scope.batchUpdateOptions.frozen.toString())
        init()

    $scope.updateRiaUser = ->
      InvestmentResource.updateRiaUser
        id: $scope.investment.id
        ria_email: $scope.investment.ria.email
      .$promise.catch (res) ->
        $scope.errorMsgRiaUser = res.data.error

    $scope.updateReferralCode = ->
      InvestorProfileService.updateReferralCode(
        $scope.investment.investor_profile.id
        $scope.investment.investor_profile.referral_code
      ).$promise.then((res) ->
        $scope.errorMsgReferralCode = null
      ).catch((res) ->
        if (res.data.message)
          message = res.data.message.split('Referral code ').pop()
        else
          message = res.data.error
        $scope.errorMsgReferralCode = message
      )


    init()
