angular.module('controllers').controller "ViewInvestorRepresentative", ($scope, $modalInstance, selectedUser, isInvestor, InvestorRepresentativeService) ->

  $scope.selectedUser = selectedUser
  $scope.isInvestor = isInvestor

  loadInvestors = ->
    InvestorRepresentativeService.getInvestors(selectedUser.id).then((response) ->
      $scope.users = response.data
    )

  loadRepresentatives = ->
    InvestorRepresentativeService.getRepresentatives(selectedUser.id).then((response) ->
      $scope.users = response.data
    )

  $scope.closeDialog = ->
    $modalInstance.close()

  if $scope.isInvestor
    loadInvestors()
    $scope.subTitle = "Below is a list of investors for #{selectedUser.email}. Note: when Status is 'Incomplete' or 'Under Review', the Investor has not been notified."
  else
    loadRepresentatives()
    $scope.subTitle = "Below is the list of representatives that have added #{selectedUser.email} as an investor. Note: when Status is 'Incomplete' or 'Under Review', the Investor has not been notified."
