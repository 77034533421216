angular.module('services').factory "Redemptions", ($resource, $http, $q, $upload) ->
  resource = $resource("/api/v1/private_access_funds/:pafId/redemptions/:id",
    {pafId: '@pafId', redemptionId: '@redemptionId'},
    query:
      method: 'GET'
      isArray: false
    search:
      url: '/api/v1/private_access_funds/:pafId/redemptions/search'
      method: 'POST'
      isArray: false
    dates:
      url: '/api/v1/private_access_funds/:pafId/redemptions/dates'
      method: 'GET'
      isArray: false
    update:
      method: 'PATCH'
      isArray: false
    updateStatus:
      url: '/api/v1/private_access_funds/:pafId/redemptions/:redemptionId/update_status'
      method: 'POST'
      isArray: false
    bulkUpdateStatus:
      url: '/api/v1/private_access_funds/:pafId/redemptions/bulk_update_status'
      method: 'POST'
      isArray: false
    bulkUpdate:
      url: '/api/v1/private_access_funds/:pafId/redemptions/bulk_update'
      method: 'POST'
      isArray: true
    sendAgreement:
      url: '/api/v1/private_access_funds/:pafId/redemptions/:redemptionId/send_agreement'
      method: 'POST'
      isArray: false
    redemptionEvents:
      url: '/api/v1/private_access_funds/:pafId/redemptions/:redemptionId/events'
      method: 'GET'
      isArray: false
    totals:
      url: '/api/v1/private_access_funds/:pafId/redemptions/totals'
      method: 'GET'
      isArray: false
    effectiveDates:
      method: 'GET'
      url: '/api/v1/private_access_funds/:pafId/redemptions/effective_dates'
      isArray: false
    calculationTypes:
      method: 'GET'
      url: '/api/v1/private_access_funds/:pafId/redemptions/calculation_types'
      isArray: false
    forApprovals:
      method: 'GET'
      url: '/api/v1/private_access_funds/:pafId/redemptions/for_approvals'
      isArray: false
  )
  resource_dashboard = $resource("/api/v1/redemptions",
    {},
    dashboard:
      url: '/api/v1/redemptions/dashboard/'
      method: 'GET'
      isArray: true
    advanced_dashboard:
      url: '/api/v1/redemptions/advanced_dashboard/'
      method: 'GET'
      isArray: true
    calculateAmount:
      method: 'GET'
      url: '/api/v1/redemptions/calculate_amount'
  )

  dashboard = (group_id, fundId, fundType, minDate, maxDate) ->
    resource_dashboard.dashboard(
      group_id: group_id
      private_access_fund_id: fundId
      fundType: fundType
      minDate: minDate
      maxDate: maxDate
    ).$promise

  advanced_dashboard = (group_id, fundId, fundType, minDate, maxDate) ->
    resource_dashboard.advanced_dashboard(
      group_id: group_id
      private_access_fund_id: fundId
      fundType: fundType
      minDate: minDate
      maxDate: maxDate
    ).$promise

  getCalculationTypes = (pafId, investmentId) ->
    resource.calculationTypes(pafId: pafId, investment_id: investmentId).$promise

  getEffectiveDates = (pafId, investmentId) ->
    resource.effectiveDates(pafId: pafId, investment_id: investmentId).$promise

  calculateAmount = (params) ->
    resource_dashboard.calculateAmount(params).$promise

  dates = (params) ->
    resource.dates(params).$promise

  all = (params) ->
    resource.search(params).$promise

  getAllForApprovals = (params) ->
    resource.forApprovals(params).$promise

  create = (pafId, params) ->
    resource.save(pafId: pafId, params).$promise

  update = (pafId, redemption) ->
    resource.update(
      pafId: pafId,
      id: redemption.id
    ,
      redemption
    ).$promise

  reject = (pafId, redemption, reason) ->
    resource.updateStatus(
      pafId: pafId,
      redemptionId: redemption.id
    ,
      status_action: 'reject'
      redemption: { rejection_reason: reason }
    ).$promise

  approve = (pafId, redemption) ->
    resource.updateStatus(
      pafId: pafId,
      redemptionId: redemption.id
    ,
      status_action: 'approve'
      redemption: { wire_date: redemption.wire_date }
    ).$promise

  bulkApprove = (pafId, redemptions) ->
    redemptionIds = redemptions.map (redemption) ->
      redemption.id
    resource.bulkUpdateStatus(
      pafId: pafId,
      redemption_ids: redemptionIds,
    ).$promise

  cancel = (pafId, redemption) ->
    resource.updateStatus(
      pafId: pafId,
      redemptionId: redemption.id
    ,
      status_action: 'cancel'
    ).$promise

  uploadDoc = (pafId, redemption, files) ->
    dfd = $q.defer()
    params =
      method: 'POST',
      url: "/api/v1/private_access_funds/#{pafId}/redemptions/#{redemption.id}/upload_document",
      file: files[0]
    $upload.upload(params).success(
      (res) ->
        dfd.resolve(res)
    ).error (errors) ->
      dfd.reject(errors)
    dfd.promise

  bulkUpdate = (pafId, redemptionIds, params) ->
    resource.bulkUpdate(pafId: pafId, _.extend(params, { ids: redemptionIds })).$promise

  sendAgreement = (pafId, redemptionId, params) ->
    resource.sendAgreement(
      pafId: pafId,
      redemptionId: redemptionId,
      params
    ).$promise

  redemptionEvents = (pafId, redemptionId) ->
    resource.redemptionEvents(
      pafId: pafId,
      redemptionId: redemptionId
    ).$promise

  totals = (params) ->
    resource.totals(params).$promise

  {
    dashboard: dashboard
    advanced_dashboard: advanced_dashboard
    dates: dates
    all: all
    create: create
    update: update
    reject: reject
    approve: approve
    bulkApprove: bulkApprove
    cancel: cancel
    uploadDoc: uploadDoc
    bulkUpdate: bulkUpdate
    sendAgreement: sendAgreement
    redemptionEvents: redemptionEvents
    getCalculationTypes: getCalculationTypes,
    getEffectiveDates: getEffectiveDates,
    calculateAmount: calculateAmount,
    totals: totals,
    getAllForApprovals: getAllForApprovals
  }
