angular.module('directives').directive 'indeterminate', ->
  restrict: 'A'
  link: (scope, element, attrs) ->

    watcher = scope.$watch attrs.indeterminate, (value) ->
      element[0].indeterminate = value

    # Remove the watcher when the directive is destroyed
    scope.$on '$destroy', ->
      watcher()
