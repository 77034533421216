angular.module('directives').directive "investorProfileDetails",
(InvestorProfileService, FormDataService, IcnGeoCountriesService, IcnUtility,
  $location, $routeParams, IcnIdentity, Custodian,
    ToolTips, CustodianAccountName, $timeout, $window, $q, $modal, UserPafExceptionService) ->
    restrict: "E"
    templateUrl: "areas/no_tab/investor_profile/components/investor_profile_details/details.html"
    scope:
      user: "="
      invProfId: "="
      pafId: "="
      qpcContact: "="
      investorProfile: "="
      batchUpdateOptions: "="
      isReadOnlyField: "="
      isHiddenField: "="
      pafOverride: "="
      whiteLabelPartner: "="
    link: (scope, element, attrs) ->
      scope.isAdminOrIr = IcnIdentity.isAdminOrIr()
      scope.isInvestorRelations = IcnIdentity.isInvestorRelations()
      scope.isRia = IcnIdentity.isRia()
      scope.isExternalApprover = IcnIdentity.isExternalApprover()
      scope.us_states = FormDataService.usStates
      scope.canadaProvinces = FormDataService.canadaProvinces
      scope.employment_status = FormDataService.employmentStatuses
      scope.individual_types_of_investor = FormDataService.types_of_investor
      scope.types_of_entity = FormDataService.types_of_entity
      scope.titlesOfSignatory = FormDataService.titlesOfSignatory
      scope.viewableCountries = []
      scope.pafWhitelistedCountries = []
      scope.allCountries = []
      scope.lockCountryFields = false
      scope.entityPermanentAddressCountries = []
      scope.domicileCountries = []
      scope.lockPermanentAddressCountryField = false
      scope.lockDomicileCountryField = false
      scope.nameLabel = ""
      scope.interestedPartiesErrors = {}
      scope.submitted = false
      scope.selectedCustodian = {}
      scope.custodianAccountNumberInValid = false
      scope.toolTips = ToolTips
      scope.mustComplete = InvestorProfileService.userMustCompleteProfile(IcnIdentity.user)
      scope.exceptionApplied = false
      scope.custodianAccountNumberError = false

      showInvalidCustodianAccountNumber = true

      scope.checkCustodianNumber = -> '*********' if isCustodianOfInterest() && !accountNumberMaskOverride()
      scope.hideCustodianNumber = -> scope.custodianAccountNumberInValid = false

      scope.maskFields = {
        clientAccountNumber: true,
        internationalBankAccountNumber: true,
        taxIdentificationNumber: true,
        forFurtherCredit: true,
        reference: true,
      }

      maskFieldsNameMapping = {
        clientAccountNumber: ['section_b_recipient_account_number', 'custodian_account_number']
        internationalBankAccountNumber: 'wire_instructions_international_bank_account',
        taxIdentificationNumber: 'section_a_entity_tin',
        forFurtherCredit: 'section_b_recipient_ffc',
        reference: 'section_b_recipient_reference',
      }

      scope.maskHideOrShowToggle = ($event, fieldName) ->
        if scope.maskFields[fieldName]
          scope.maskFields[fieldName] = false
        else
          scope.maskFields[fieldName] = true
        $($event.target.parentNode).find('input')[0].focus()

      isInsideClick = (e, name) ->
        unmaskedEle = element.find("input[name='#{name}']").parent().get(0)
        if !_.isUndefined(unmaskedEle) && $.contains(unmaskedEle, e.target)
          return true
        false

      handleClick = (e) ->
        outsideClick = true
        name = ''
        angular.forEach scope.maskFields, (value, key) ->
          if !value
            name = maskFieldsNameMapping[key]
        if _.isArray(name)
          name.forEach( (oneName) ->
            outsideClick = !isInsideClick(e, oneName) && outsideClick
          )
        else
          outsideClick = !isInsideClick(e, name)

        if outsideClick
          scope.$apply( ->
            scope.maskFields = {
              clientAccountNumber: true,
              internationalBankAccountNumber: true,
              taxIdentificationNumber: true,
              forFurtherCredit: true,
              reference: true,
            }
          )

      handleKeyDown = (event) ->
        # handle "Tab" key
        if (event.keyCode == 9)
          scope.$apply( ->
            scope.maskFields = {
              clientAccountNumber: true,
              internationalBankAccountNumber: true,
              taxIdentificationNumber: true,
              forFurtherCredit: true,
              reference: true,
            }
          )

      bindClickEventListener = ->
        document.addEventListener('mousedown', handleClick)
        document.addEventListener('keydown', handleKeyDown)

      bindClickEventListener()

      scope.$on("$destroy", ->
        document.removeEventListener('mousedown', handleClick)
        document.removeEventListener('keydown', handleKeyDown)
      )

      scope.isTaxIdentificationNumberDisabled = ->
        !(scope.isInvestorRelations ||
          !(scope.investorProfile.number_of_investments > 0 &&
            scope.investorProfile.section_a_entity_tin.length > 0 &&
            !scope.investor_profile_details.section_a_entity_tin.$dirty)) || scope.isReadOnlyField('section_a_entity_tin')

      accountNumberMaskOverride = ->
        if scope.investor_profile_details.custodian_account_number != undefined
          regex = /[a-zA-Z0-9]{9}/
          custodianNumber = scope.investor_profile_details.custodian_account_number
          isInvalidPristineAccountNumber = !custodianNumber.$dirty && !regex.test(custodianNumber.$modelValue)
          if isInvalidPristineAccountNumber and showInvalidCustodianAccountNumber
            scope.investor_profile_details.custodian_account_number.$setValidity('required', false)
            angular.element("input[name=custodian_account_number]").val(custodianNumber.$modelValue)
            angular.element("input[name=custodian_account_number]").addClass('submitted')
            scope.custodianAccountNumberInValid = true
            showInvalidCustodianAccountNumber = false
          isInvalidPristineAccountNumber

      isCustodianOfInterest = ->
        return false if !scope.selectedCustodian

        custodianName = scope.selectedCustodian.name
        custodianName == 'Fidelity RIAs / Family Office' || custodianName == 'NFS Broker-Dealers'

      scope.custodianNumberValidity = ->
        if scope.investor_profile_details.custodian_account_number != undefined
          regex = /[a-zA-Z0-9]{9}/
          custodianNumber = scope.investor_profile_details.custodian_account_number
          scope.custodianAccountNumberInValid = custodianNumber.$dirty && !regex.test(custodianNumber.$viewValue) && isCustodianOfInterest()

      scope.dateOptions = {
        changeYear: true,
        changeMonth: true,
        yearRange: '1800:-0',
        dateFormat: "MM-dd-yy"
      }

      scope.birthdate = { date: undefined }
      scope.formationdate = { date: undefined }

      scope.errors_on_form = false
      scope.formErrorMsgs = {}

      scope.isEntity = ->
        scope.investorProfile && scope.investorProfile.signer_type == '1'

      scope.isTrustEntity = ->
        scope.isEntity() &&
        (scope.investorProfile.section_a_entity_type == 'Grantor Trust' ||
          scope.investorProfile.section_a_entity_type == 'Non-Grantor Trust')

      scope.usAddress = ->
        if scope.isEntity()
          scope.investorProfile.section_a_entity_state_country == 'United States of America'
        else
          scope.investorProfile.section_a_individual_state_country == 'United States of America'

      scope.canadaAddress = ->
        if scope.isEntity()
          scope.investorProfile.section_a_entity_state_country == 'Canada'
        else
          scope.investorProfile.section_a_individual_state_country == 'Canada'

      scope.canEditSigner = ->
        scope.isRia || scope.isInvestorRelations || scope.isExternalApprover

      scope.usEntityAddress = ->
        scope.investorProfile.section_a_entity_state_country == 'United States of America'

      scope.canadaEntityAddress = ->
        scope.investorProfile.section_a_entity_state_country == 'Canada'

      scope.isUsEntityCountry = (fieldKey) ->
        scope.investorProfile[fieldKey] == 'United States of America'

      scope.isCanadaEntityCountry = (fieldKey) ->
        scope.investorProfile[fieldKey] == 'Canada'

      scope.usMailingAddress = ->
        scope.investorProfile.section_a_mailing_state_country == 'United States of America'

      scope.canadaMailingAddress = ->
        scope.investorProfile.section_a_mailing_state_country == 'Canada'

      scope.internationalAddress = ->
        !scope.usAddress() && !scope.canadaAddress()

      scope.internationalMailingAddress = ->
        !scope.usMailingAddress() && !scope.canadaMailingAddress()

      scope.internationalEntityAddress = ->
        !scope.usAddress() && !scope.canadaAddress()

      scope.isIndividual = ->
        scope.investorProfile && scope.investorProfile.signer_type == '0'

      scope.investorProfileId = ->
        scope.invProfId ||  $routeParams.invProfId

      scope.mailingAddressChecked = ->
        if scope.investorProfile.mailing_address_same_as_permanent == '0'
          delete scope.formErrorMsgs['section_a_mailing_address']
          delete scope.formErrorMsgs['section_a_mailing_city']
          delete scope.formErrorMsgs['section_a_mailing_state']
          delete scope.formErrorMsgs['section_a_mailing_zip']
          delete scope.formErrorMsgs['section_a_mailing_state_country']
          scope.investorProfile.section_a_mailing_address = ''
          scope.investorProfile.section_a_mailing_city = ''
          scope.investorProfile.section_a_mailing_state = ''
          scope.investorProfile.section_a_mailing_zip = ''
          scope.investorProfile.section_a_mailing_state_country = ''

      scope.$watch 'investorProfile.signer_type', (newValue, oldValue) ->
        return unless newValue
        scope.errors_on_form = false
        scope.formErrorMsgs = {}
        if newValue == "0"
          scope.nameLabel = "Account Name"
          scope.nameTooltip = ToolTips.fetch().individualName
        else
          scope.nameLabel = "Entity Name"
          scope.nameTooltip = ToolTips.fetch().entityName
        setupConditionalCountries()

      scope.$watch 'investorProfile.section_a_entity_type', (newValue, oldValue) ->
        return unless newValue
        setupConditionalCountries()

      scope.$watch 'investorProfile.custodian_account.account_name', (newVal, oldVal) ->
        return unless newVal
        if scope.profileHasCustodian()
          scope.investorProfile.section_a_name_of_investor = scope.decoratedCustodianAccountName()
          delete scope.formErrorMsgs['investor_name']

      scope.$watch 'investorProfile.custodian_account.custodian_id', (newVal, oldVal) ->
        return unless scope.investorProfile
        return unless scope.investorProfile.custodian_account

        unless newVal
          scope.investorProfile.custodian_account.addendum_on_file = false
          return

        scope.investorProfile.custodian_account.addendum_on_file = false if oldVal

        if scope.custodians
          setSelectedCustodian(newVal, false)
          scope.investorProfile.section_a_name_of_investor = scope.decoratedCustodianAccountName()

      scope.$watch 'investorProfile.entity_jurisdiction_of_organization_country', (newVal, oldVal) ->
        return if _.isUndefined(oldVal)
        countryFieldKey = 'entity_jurisdiction_of_organization_country'
        stateFieldKey = 'entity_jurisdiction_of_organization_state'
        resetStateField(countryFieldKey, stateFieldKey, newVal)

      scope.$watch 'investorProfile.entity_domicile_country', (newVal, oldVal) ->
        return if _.isUndefined(oldVal)
        countryFieldKey = 'entity_domicile_country'
        stateFieldKey = 'entity_domicile_state'
        resetStateField(countryFieldKey, stateFieldKey, newVal)

      resetStateField = (countryFieldKey, stateFieldKey, newVal) ->
        return unless scope.investorProfile && scope.investorProfile.hasOwnProperty(stateFieldKey)
        scope.investorProfile[stateFieldKey] = ''
        delete scope.formErrorMsgs[stateFieldKey]

      scope.$watch('investorProfile.section_a_entity_formation_date', (newVal) ->
        return unless newVal
        $timeout( -> # Run in a new digest so the $watch on birthdate doesn't trigger
          scope.formationdate.date = newVal
        )
      )

      scope.$watch('investorProfile.section_a_name_of_investor', (newVal, oldVal) ->
        return unless newVal && newVal != oldVal && scope.investor_profile_details.section_a_name_of_investor
        investorName = scope.investor_profile_details.section_a_name_of_investor
        if !scope.investorProfile.section_b_recipient_account_name
          scope.investorProfile.section_b_recipient_account_name = newVal
        else if investorName.$dirty && scope.investorProfile.section_b_recipient_account_name != scope.investorProfile.section_a_name_of_investor
          scope.investorProfile.section_b_recipient_account_name = newVal
      )

      scope.displayFidelityFields = ->
        isFidelity()

      scope.displayAddendum = ->
        scope.displayFidelityFields()

      scope.displayGNumber = ->
        isFidelityRia()

      isFidelityRia = ->
        scope.selectedCustodian && scope.selectedCustodian.name == 'Fidelity RIAs / Family Office'

      isFidelity = ->
        scope.selectedCustodian && (scope.selectedCustodian.name == 'Fidelity RIAs / Family Office' ||
          scope.selectedCustodian.name == 'NFS Broker-Dealers')

      isValidDate = (dateAttrs) ->
        return false if typeof(dateAttrs) == 'undefined'

        if typeof(dateAttrs) == 'string'
          string = dateAttrs
          date = moment(dateAttrs, "MMDDYYYY")
        else
          string = [dateAttrs.month, dateAttrs.day, dateAttrs.year].join("/")
          date = moment(string, "MM/DD/YYYY")

        return true if !scope.mustComplete && string == "//"
        return date && date.isValid()

      setSelectedCustodian = (id, first_load) ->
        scope.selectedCustodian = _.findWhere(scope.custodians, {id: id} )
        scope.toggleSetCustodian()

        unless first_load
          # Clear account type selection to make sure that an unavailable option
          # doens't remain selected
          scope.investorProfile.custodian_account.custodian_account_type_id = ''

        scope.custodianAccountTypes = scope.selectedCustodian.custodian_account_types

      scope.adjustCustodianAccountName = ->
        !scope.isReadOnlyField('custodian_account_name') && !scope.isHiddenField('custodian_account_name')

      scope.custodianAccountNamePrefix = ->
        custodianAccountType = scope.selectedCustodianAccountType()
        if scope.adjustCustodianAccountName() && scope.selectedCustodian && custodianAccountType
          CustodianAccountName.prefix(scope.selectedCustodian.name, custodianAccountType.name)

      scope.custodianAccountNameSuffix = ->
        custodianAccountType = scope.selectedCustodianAccountType()
        if scope.adjustCustodianAccountName() && scope.selectedCustodian && custodianAccountType
          CustodianAccountName.suffix(scope.selectedCustodian.name, custodianAccountType.name)

      scope.selectedCustodianAccountType = ->
        return unless scope.investorProfile.custodian_account

        _.find scope.custodianAccountTypes, (type) ->
          type.id == scope.investorProfile.custodian_account.custodian_account_type_id

      padWithZero = (val) ->
        return false if isNaN(val)
        Array(Math.max(2 - String(val).length + 1, 0)).join(0) + val

      noBatchUpdate = ->
        _.isNull(scope.batchUpdateOptions.live) && _.isNull(scope.batchUpdateOptions.frozen)

      openBatchUpdateModal = ->
        if scope.isInvestorRelations && noBatchUpdate() && scope.investorProfile.is_frozen
          $modal.open(
            templateUrl: "areas/no_tab/investor_profile/dialogs/investor_profile_save_dialog.html"
            controller: "InvestorProfileSaveDialogCtrl"
            windowClass: "small-modal"
            backdrop: 'static'
            keyboard: false
          ).result.then (batchUpdateOptions) ->
            scope.batchUpdateOptions.live = batchUpdateOptions.live
            scope.batchUpdateOptions.frozen = batchUpdateOptions.frozen
            loadAvailableCountries(scope.investorProfile.id)

      clearFields = ->
        unless scope.investorProfile.mailing_address_same_as_permanent
          scope.investorProfile.mailing_address_same_as_permanent = '0'
        if scope.investorProfile.signer_type is "0"
          scope.investorProfile.section_a_entity_type = ""
          scope.investorProfile.section_a_entity_tin = ""
          scope.investorProfile.section_a_entity_address = ""
          scope.investorProfile.section_a_entity_city = ""
          scope.investorProfile.section_a_entity_state = ""
          scope.investorProfile.section_a_entity_zip = ""
          scope.investorProfile.section_a_entity_formation_date = ""
          scope.investorProfile.section_a_entity_phone = ""
          scope.investorProfile.section_a_entity_email = ""
          scope.investorProfile.section_a_entity_tax_year_end = ""
          scope.investorProfile.section_a_entity_foia_partner = ""
        if scope.investorProfile.signer_type is "1"
          scope.investorProfile.section_a_individual_ssn = ""
          scope.investorProfile.section_a_individual_address = ""
          scope.investorProfile.section_a_individual_city = ""
          scope.investorProfile.section_a_individual_state = ""
          scope.investorProfile.section_a_individual_zip = ""
          scope.investorProfile.section_a_individual_email = ""
          scope.investorProfile.section_a_individual_state_country = ""
          scope.investorProfile.section_a_individual_birthdate = ""
          scope.investorProfile.section_a_individual_pone = ""
          scope.investorProfile.section_a_individual_employment_status = ""
          scope.investorProfile.section_a_individual_employer = ""
          scope.investorProfile.section_a_individual_occupation = ""
        if scope.investorProfile.section_b_ria_access is "1"
          scope.investorProfile.section_b_ria_firm = ""
          scope.investorProfile.section_b_ria_name = ""
          scope.investorProfile.section_b_ria_email = ""
          scope.investorProfile.section_b_email_only_ria = "0"
        return

      loadPafWhiteListedCountries = (countries) ->
        scope.pafWhitelistedCountries = countries
        scope.lockCountryFields = _.isEmpty(scope.pafWhitelistedCountries)

      loadAllCountries = (countries) ->
        scope.allCountries = countries

      loadUserViewableCountries = (countries) ->
        scope.viewableCountries = countries

      hasLiveBatchUpdate = ->
        scope.batchUpdateOptions && scope.batchUpdateOptions.live

      loadAvailableCountries = (profileId) ->
        promises = [
          IcnGeoCountriesService.forInvestorProfile(profileId, hasLiveBatchUpdate()).$promise,
          IcnGeoCountriesService.getAllCountries().$promise,
          IcnGeoCountriesService.getViewableCountries().$promise
        ]

        if IcnIdentity.user.isRia
          promises.push(UserPafExceptionService.checkExceptionApplied(scope.invProfId).$promise)

        $q.all(promises).then (replies) ->
          loadPafWhiteListedCountries (replies[0])
          loadAllCountries (replies[1])
          loadUserViewableCountries (replies[2])
          if IcnIdentity.user.isRia
            scope.exceptionApplied = replies[3].result
            if scope.exceptionApplied == true
              loadPafWhiteListedCountries(scope.allCountries)
              loadUserViewableCountries(scope.allCountries)
            setupConditionalCountries()
          else
            setupConditionalCountries()


      setupConditionalCountries = ->
        if scope.isTrustEntity()
          scope.entityPermanentAddressCountries = scope.pafWhitelistedCountries
          scope.lockPermanentAddressCountryField = scope.lockCountryFields
          scope.domicileCountries = scope.viewableCountries
          scope.lockDomicileCountryField = false
        else
          scope.entityPermanentAddressCountries = scope.viewableCountries
          scope.lockPermanentAddressCountryField = false
          scope.domicileCountries = scope.pafWhitelistedCountries
          scope.lockDomicileCountryField = scope.lockCountryFields
        clearInvalidChoices()

      isValidCountry = (field, options) ->
        return if _.isUndefined(scope.investorProfile)
        currentCountry = scope.investorProfile[field]
        return false unless _.find(scope.allCountries, (o) -> o.name == currentCountry)
        return !!_.find(options, (o) -> o.name == currentCountry)

      resetCountryField = (field, options) ->
        if _.find(options, (o) -> o.name == 'United States of America')
          scope.investorProfile[field] = 'United States of America'
        else
          scope.investorProfile[field] = _.first(options)?.name || ''

      clearInvalidCountryChoice = (field, options) ->
        if !isValidCountry(field, options)
          resetCountryField(field, options)

      clearInvalidChoices = ->
        return unless scope.allCountries.length && !_.isUndefined(scope.investorProfile)

        clearInvalidCountryChoice('section_a_individual_state_country', scope.pafWhitelistedCountries)
        clearInvalidCountryChoice('section_a_entity_state_country', scope.entityPermanentAddressCountries)
        clearInvalidCountryChoice('section_a_mailing_state_country', scope.viewableCountries)
        if scope.isEntity()
          clearInvalidCountryChoice('entity_jurisdiction_of_organization_country', scope.viewableCountries)
          clearInvalidCountryChoice('entity_domicile_country', scope.domicileCountries)

        if IcnUtility.hasNoValue(scope.investorProfile.wire_instructions_account_with_us_bank)
          scope.investorProfile.wire_instructions_account_with_us_bank = '0'

      scope.$watch('investorProfile', (profile) ->
        if profile
          if !_.isEmpty(profile)
            cc_id = scope.investorProfile.contact_card.id
          else
            cc_id = IcnIdentity.user.contact_card_id

          loadAvailableCountries(profile.id)
          clearFields()
          clearInvalidChoices()

          Custodian.index(
            paf_override: scope.pafOverride
            private_access_fund_id: scope.pafId
          ).$promise.then((arr) ->
            scope.custodians = arr
            if scope.investorProfile.custodian_account
              setSelectedCustodian(scope.investorProfile.custodian_account.custodian_id, true)
            openBatchUpdateModal()
          )

          if typeof(scope.investorProfile.signers) == "undefined"
            scope.investorProfile.signers = []

          if typeof(scope.investorProfile.section_a_individual_birthdate) == "undefined"
            scope.investorProfile.section_a_individual_birthdate = ""

          if typeof(scope.investorProfile.section_a_entity_formation_date) == "undefined"
            scope.investorProfile.section_a_entity_formation_date = ""

          scope.investorProfile.signer_type = "0" unless scope.investorProfile.signer_type
          scope.investorProfile.section_b_ria_access = "0" if scope.investorProfile.section_b_ria_access == ""

          if scope.investorProfile.custodian_account_id > 0
            scope.investorProfile.hasCustodian = "true"
          else
            scope.investorProfile.hasCustodian = "false"

      )

      saved = false

      formatDate = (date) ->
        if !date
          return null
        else if isValidDate(date)
          if typeof(date) == 'string'
            return $.datepicker.formatDate('mm/dd/yy', moment(date, 'MMDDYYYY').toDate())
          else if !!date.day && !!date.month && !!date.year
            return $.datepicker.formatDate('MM-dd-yy', new Date(date.year, date.month - 1, date.day))
        else
          ''

      urlPath = (url) ->
        start = url.indexOf("/",8)
        end = url.indexOf("?",start)
        if end == -1
          url.substr(start)
        else
          url.substr(start, end - start)

      revalidateWireInstructions = ->
        if scope.profileHasCustodian()
          delete scope.formErrorMsgs['bank_name']
          delete scope.formErrorMsgs['bank_location']
          delete scope.formErrorMsgs['aba_number']
          delete scope.formErrorMsgs['account_name']
          delete scope.formErrorMsgs['account_number']
          delete scope.formErrorMsgs['wire_instructions_us_correspondent_bank_name']
          delete scope.formErrorMsgs['wire_instructions_us_correspondent_aba_number']
          delete scope.formErrorMsgs['wire_instructions_us_correspondent_swift_code']
          delete scope.formErrorMsgs['wire_instructions_us_correspondent_aba_or_swift']
          delete scope.formErrorMsgs['wire_instructions_international_bank_account']
          delete scope.formErrorMsgs['wire_instructions_account_with_us_bank']

      revalidateRiaFields = ->
        if scope.investorProfile.section_b_ria_access == '1'
          delete scope.formErrorMsgs['section_b_ria_firm']
          delete scope.formErrorMsgs['section_b_ria_name']
          delete scope.formErrorMsgs['section_b_ria_email']

      scope.profileHasCustodian = ->
        scope.investorProfile && scope.investorProfile.hasCustodian == 'true'

      scope.maskAccountNumber = ->
        scope.investorProfile.custodian_account.account_number
          .split('').map((_) -> '*').join('')

      scope.ffcForCustodian = ->
        return '[Community National Bank CUST FBO ' + scope.investorProfile.custodian_account.account_name +
            ', ' + scope.maskAccountNumber() + ']' if scope.selectedCustodian.name == "Community National Bank"
        return scope.selectedCustodian.overriden_ffc_value

      scope.calculateInterestedPartiesErrors = ->
        return unless scope.mustComplete

        result = _.find(scope.investor_profile_details.$error.required, (i) ->
          i.$name.match(/^interested_party_.*$/)
        )

        scope.addErrorMessage(!result, "interested_parties_section", "Interested Parties section is required.")

      scope.calculateRetirementPlanErrors = (i) ->
        _ip = scope.investorProfile

        return true if !scope.mustComplete
        return true if _ip.signer_type == '1' || scope.isHiddenField('investing_in_retirement_plan')
        return true if _ip.investing_in_retirement_plan == '1' && _ip.declare_as_assets_of_plan_investor == '1'

        firstInvalid = _ip.investing_in_retirement_plan == ''
        if _ip.investing_in_retirement_plan == '0'
          firstInvalid = _ip.plan_investor_accounting_arrangement_code_1974 == '' ||
            _ip.plan_investor_assets_value == ''

        if _ip.investing_in_retirement_plan == '1'
          secondInvalid = _ip.declare_as_assets_of_plan_investor == ''

        if firstInvalid || secondInvalid
          scope.addErrorMessage(false, "investing_in_retirement_plan", "Retirement Plan selection is required.")
          return false

        true

      scope.validateUsCorrespondentABAandSwift = ->
        return unless scope.noAccountWithUsBank() || !scope.mustComplete
        delete(scope.formErrorMsgs['wire_instructions_us_correspondent_aba_number'])
        delete(scope.formErrorMsgs['wire_instructions_us_correspondent_swift_code'])
        delete(scope.formErrorMsgs['wire_instructions_us_correspondent_aba_or_swift'])

        if scope.usCorrespondentABAandSwiftInValid()
          scope.addErrorMessage(false, "wire_instructions_us_correspondent_aba_or_swift",
                "U.S. Intermediary ABA or Swift is required.")

      scope.usCorrespondentABAandSwiftInValid = ->
        return false unless scope.mustComplete

        isEmptyField('wire_instructions_us_correspondent_aba_number') &&
          isEmptyField('wire_instructions_us_correspondent_swift_code')

      scope.validateBeneficiaryABAandSwift = ->
        return unless scope.noAccountWithUsBank() || !scope.mustComplete

        delete(scope.formErrorMsgs['section_b_recipient_bank_aba'])
        delete(scope.formErrorMsgs['section_b_recipient_bank_swift_code'])
        delete(scope.formErrorMsgs['wire_instructions_beneficiary_aba_or_swift'])

        if scope.beneficiaryABAandSwiftInValid()
          scope.addErrorMessage(false, "wire_instructions_beneficiary_aba_or_swift",
            "Beneficiary ABA or Swift is required.")

      scope.beneficiaryABAandSwiftInValid = ->
        return false unless scope.mustComplete

        isEmptyField('section_b_recipient_bank_aba') &&
          isEmptyField('section_b_recipient_bank_swift_code')

      validateMailingAddressStateAndZip = ->
        return if scope.investorProfile.mailing_address_same_as_permanent == '0' || !scope.mustComplete

        delete(scope.formErrorMsgs['section_a_mailing_state'])
        delete(scope.formErrorMsgs['section_a_mailing_zip'])

        if scope.canadaMailingAddress() && isEmptyField('section_a_mailing_state')
          scope.addErrorMessage(false, "section_a_mailing_state", "Mailing Address Province is required.")
        else if scope.usMailingAddress() && isEmptyField('section_a_mailing_state')
          scope.addErrorMessage(false, "section_a_mailing_state", "Mailing Address State is required.")

        if scope.canadaMailingAddress() && isEmptyField('section_a_mailing_zip')
          scope.addErrorMessage(false, "section_a_mailing_zip", "Mailing Address Postal code is required.")
        else if scope.usMailingAddress() && isEmptyField('section_a_mailing_zip')
          scope.addErrorMessage(false, "section_a_mailing_zip", "Mailing Address Zip code is required.")

      validateStateAndZip = ->
        return true unless scope.mustComplete

        delete scope.formErrorMsgs['state']
        delete scope.formErrorMsgs['zip']

        if scope.investorProfile.signer_type == '1'
          if scope.canadaAddress() && isEmptyField('section_a_entity_state')
            scope.addErrorMessage(false, "state", "Province is required.")
          else if scope.usAddress() && isEmptyField('section_a_entity_state')
            scope.addErrorMessage(false, "state", "State is required.")
          if scope.canadaAddress() && isEmptyField('section_a_entity_zip')
            scope.addErrorMessage(false, "zip", "Postal Code is required.")
          else if scope.usAddress() && isEmptyField('section_a_entity_zip')
            scope.addErrorMessage(false, "zip", "Zip code is required.")
        else
          if scope.canadaAddress() && isEmptyField('section_a_individual_state')
            scope.addErrorMessage(false, "state", "Province is required.")
          else if scope.usAddress() && isEmptyField('section_a_individual_state')
            scope.addErrorMessage(false, "state", "State is required.")
          if scope.canadaAddress() && isEmptyField('section_a_individual_zip')
            scope.addErrorMessage(false, "zip", "Postal Code is required.")
          else if scope.usAddress() && isEmptyField('section_a_individual_zip')
            scope.addErrorMessage(false, "zip", "Zip code is required.")

      isEmptyField = (field) ->
        !scope.isHiddenField(field) && !scope.investorProfile[field]

      scope.normalizeMailingAddress = ->
        if scope.investorProfile.mailing_address_same_as_permanent == '0'
          scope.investorProfile.section_a_mailing_address = ''
          scope.investorProfile.section_a_mailing_city = ''
          scope.investorProfile.section_a_mailing_state = ''
          scope.investorProfile.section_a_mailing_zip = ''
          scope.investorProfile.section_a_mailing_state_country = ''

      scope.resetCustodianYes = ->
        if scope.investorProfile.hasCustodian == 'true'
          delete scope.formErrorMsgs['aba_number']
          delete scope.formErrorMsgs['account_name']
          delete scope.formErrorMsgs['account_number']
          delete scope.formErrorMsgs['bank_name']
          delete scope.formErrorMsgs['bank_location']
          delete scope.formErrorMsgs['wire_instructions_us_correspondent_bank_name']
          delete scope.formErrorMsgs['wire_instructions_us_correspondent_aba_number']
          delete scope.formErrorMsgs['wire_instructions_us_correspondent_swift_code']
          delete scope.formErrorMsgs['wire_instructions_international_bank_account']
          scope.rebuildErrors()

      stripCustodianAccountNumber = ->
        str = scope.investorProfile.custodian_account.account_number
        str = str.replace(/[-_ ]/g, '') if str?
        scope.investorProfile.custodian_account.account_number = str
        scope.investorProfile.section_b_recipient_account_number = str

      scope.toggleSetCustodian = ->
        if scope.investorProfile.hasCustodian == 'true'
          scope.investorProfile.section_b_recipient_bank_name = scope.selectedCustodian.bank_name
          scope.investorProfile.section_b_recipient_bank_swift_code = scope.selectedCustodian.bank_aba
          scope.investorProfile.section_b_recipient_bank_aba = scope.selectedCustodian.bank_aba
          scope.investorProfile.section_b_recipient_account_name = scope.selectedCustodian.bank_account_name
          scope.investorProfile.section_b_recipient_account_number = scope.selectedCustodian.bank_account_number
          scope.investorProfile.section_b_recipient_reference = scope.selectedCustodian.bank_reference
          scope.investorProfile.wire_instructions_us_correspondent_bank_name = ''
          scope.investorProfile.wire_instructions_us_correspondent_aba_number = ''
          scope.investorProfile.wire_instructions_us_correspondent_swift_code = ''
          scope.investorProfile.wire_instructions_international_bank_account = ''
        else
          scope.investorProfile.section_a_name_of_investor = ''
          scope.investorProfile.section_b_recipient_bank_name = ''
          scope.investorProfile.section_b_recipient_bank_swift_code = ''
          scope.investorProfile.section_b_recipient_bank_aba = ''
          scope.investorProfile.section_b_recipient_account_name = ''
          scope.investorProfile.section_b_recipient_account_number = ''
          scope.investorProfile.section_b_recipient_reference = ''
          scope.investorProfile.section_b_recipient_ffc = ''
          scope.selectedCustodian = null

          if scope.investorProfile.custodian_account
            scope.investorProfile.custodian_account.custodian_id = ''
            scope.investorProfile.custodian_account.custodian_account_type_id = ''
            scope.investorProfile.custodian_account.account_name = ''
            scope.investorProfile.custodian_account.account_number = ''
            scope.investorProfile.custodian_account.group_number = ''
            scope.investorProfile.custodian_account.addendum_on_file = ''

          scope.investorProfile.section_b_recipient_client_account_name = ''
          scope.investorProfile.section_b_recipient_client_account_number = ''

      decorateCustodianSpecificFields = ->
        if scope.investorProfile.hasCustodian == "true"
          scope.investorProfile.custodian_account.account_name_suffix = scope.custodianAccountNameSuffix()
          scope.investorProfile.custodian_account.account_name_prefix = scope.custodianAccountNamePrefix()
          scope.investorProfile.section_b_recipient_account_name = scope.decoratedCustodianAccountName()
          scope.investorProfile.section_a_name_of_investor = scope.decoratedCustodianAccountName()
          delete scope.formErrorMsgs['investor_name']

          if scope.profileHasCustodian() && scope.selectedCustodian.bank_ffc
            scope.investorProfile.section_b_recipient_ffc = scope.selectedCustodian.bank_ffc

      scope.decoratedCustodianAccountName = ->
        custodianAccountType = scope.selectedCustodianAccountType()

        if scope.investorProfile?.custodian_account && custodianAccountType
          account_name = scope.investorProfile.custodian_account.account_name
          if scope.adjustCustodianAccountName()
            CustodianAccountName.decorated(scope.selectedCustodian.name, custodianAccountType.name, account_name)
          else
            account_name

      scope.save = ->
        return unless IcnIdentity.canEditInvestorProfiles()
        validateStateAndZip()
        validateMailingAddressStateAndZip()
        scope.validateBeneficiaryABAandSwift()
        scope.validateUsCorrespondentABAandSwift()
        scope.validateABA()

        validateEntityJurisdictionDomicile()

        revalidateWireInstructions()
        decorateCustodianSpecificFields()
        scope.calculateInterestedPartiesErrors()
        scope.calculateRetirementPlanErrors()
        revalidateRiaFields()

        scope.normalizeMailingAddress()
        if scope.investorProfile.hasCustodian == 'true'
          stripCustodianAccountNumber()

        scope.validateAccountNumber()
        scope.validateAccountName()

        delete scope.formErrorMsgs['server_error']
        scope.serverErrorEmail = false
        delete scope.formErrorMsgs['custodian_account_number']
        scope.custodianAccountNumberError = false
        if ((scope.investor_profile_details.$invalid ||
             Object.keys(scope.formErrorMsgs).length > 0) && !scope.isAdminOrIr)
          scope.submitted = true
          scope.errors_on_form = true
          $window.scrollTo(0, 0)
          return $q.reject()
        else if !saved

          for signer in scope.investorProfile.signers
            signer.birthdate = formatDate(signer.birthdate)

          scope.investorProfile.section_a_entity_formation_date = formatDate(scope.formationdate.date)

          if scope.investorProfile.hasCustodian == 'false'
            scope.investorProfile.custodian_account = {}

          scope.investorProfile.from = $routeParams.from
          scope.investorProfile.investment_id = $routeParams.investment_id
          p = InvestorProfileService.update(scope.investorProfile, scope.invProfId, scope.batchUpdateOptions).$promise
          p.then (data) ->
            scope.invProfId = data.investorProfile.id if data.investorProfile
            unless $location.search().invProfId
              $location.search('invProfId', scope.invProfId)

            $location.search('live', scope.batchUpdateOptions.live) if scope.batchUpdateOptions.live
            $location.search('frozen', scope.batchUpdateOptions.frozen) if scope.batchUpdateOptions.frozen

            # If we is are in the presence of an Individual investor and we change the
            # primary signers email address
            if IcnIdentity.isInvestor() && scope.investorProfile.signers[0].email != IcnIdentity.user.email
              IcnIdentity.user.email = scope.investorProfile.signers[0].email


            saved = true
          , (error) ->
            serverError = error.data? && error.data.error?
            emailError = error.data.error.email? && error.data.error.email[0] == 'has already been taken'
            if serverError
              if emailError
                scope.formErrorMsgs['server_error'] = 'Primary Signer Email Address: Email is already in use'
                scope.serverErrorEmail = true
              else
                if error.data.error[0] == 'custodian_account_number'
                  scope.addErrorMessage(false, error.data.error[0], error.data.error[1])
                  scope.custodianAccountNumberError = true
                if error.data.error[0] == 'email'
                  scope.formErrorMsgs['server_error'] = 'Primary Signer Email Address: Email is already in use'
                  scope.serverErrorEmail = true
            scope.submitted = true
            scope.errors_on_form = true
            $window.scrollTo(0, 0)
            return $q.reject()

          scope.submitted = true
          clearFields() unless scope.investor_profile_details.$invalid
          return p

      ###############
      # Validations #
      ###############
      scope.errorMessageSortOrderKeys = [
        'server_error',
        'entity_type',
        'section_a_individual_type',
        'section_a_entity_type',
        'section_a_individual_us_person',
        'section_a_entity_us_person',
        'investor_name',
        'birthdate_month',
        'birthdate_day',
        'birthdate_year',
        'signer_1_birthdate',
        'signer_2_birthdate',
        'signer_3_birthdate',
        'formationdate_month',
        'formationdate_day',
        'formationdate_year',
        'formationdate',
        'ssn',
        'section_a_individual_ssn',
        'address',
        'city',
        'state',
        'zip',
        'country',
        'section_a_mailing_address',
        'section_a_mailing_city',
        'section_a_mailing_state',
        'section_a_mailing_state_country',
        'section_a_mailing_zip',
        'phone',
        'entity_jurisdiction_of_organization_country',
        'entity_jurisdiction_of_organization_state',
        'entity_domicile_country',
        'entity_domicile_state',
        'email',
        'employment_status',
        'tax_id_num',
        'entity_address',
        'entity_city',
        'entity_state',
        'entity_zip',
        'entity_phone',
        'entity_email',
        'foia_partner_classification',
        'investing_in_retirement_plan',
        'plan_investor_assets_value',
        'signer_1_fields',
        'signer_2_fields',
        'signer_3_fields',
        'signers_email',
        'section_b_ria_firm',
        'section_b_ria_name',
        'section_b_ria_email',
        'interested_parties',
        'wire_instructions_account_with_us_bank',
        'bank_location',
        'bank_name', # think this type of error can get added in two places.. they would overwrite each other...
        'wire_instructions_us_correspondent_bank_name',
        'wire_instructions_us_correspondent_aba_number',
        'wire_instructions_us_correspondent_swift_code',
        'wire_instructions_us_correspondent_aba_or_swift',
        'wire_instructions_beneficiary_aba_or_swift',
        'aba_number',
        'account_name',
        'account_number'
        'interested_parties_section',
        'occupation',
        'individual_employer',
        'custodian_account_number'
      ]

      scope.noAccountWithUsBank = ->
        scope.investorProfile && scope.investorProfile.wire_instructions_account_with_us_bank == '1'

      scope.$watchCollection 'formErrorMsgs', (oldMsgs, msgs) ->
        if (oldMsgs != msgs)
          scope.rebuildErrors()

      scope.$watch 'investorProfile.wire_instructions_account_with_us_bank', (oldVal, newVal) ->
        if oldVal && newVal && oldVal != newVal
          scope.clearUsBankFields()


      scope.clearUsBankFields = ->
        scope.investorProfile.section_b_recipient_bank_name = ""
        scope.investorProfile.section_b_recipient_bank_location = ""
        scope.investorProfile.section_b_recipient_bank_aba = ""
        scope.investorProfile.section_b_recipient_bank_swift_code = ""
        scope.investorProfile.section_b_recipient_account_number = ""
        scope.investorProfile.section_b_recipient_ffc = ""
        scope.investorProfile.section_b_recipient_reference = ""
        scope.investorProfile.wire_instructions_us_correspondent_bank_name = ""
        scope.investorProfile.wire_instructions_us_correspondent_aba_number = ""
        scope.investorProfile.wire_instructions_us_correspondent_swift_code = ""
        scope.investorProfile.wire_instructions_international_bank_account = ""
        delete scope.formErrorMsgs['bank_name']
        delete scope.formErrorMsgs['bank_location']
        delete scope.formErrorMsgs['aba_number']
        delete scope.formErrorMsgs['account_number']
        delete(scope.formErrorMsgs['section_b_recipient_bank_aba'])
        delete(scope.formErrorMsgs['section_b_recipient_bank_swift_code'])
        delete(scope.formErrorMsgs['wire_instructions_beneficiary_aba_or_swift'])
        delete scope.formErrorMsgs['wire_instructions_us_correspondent_bank_name']
        delete scope.formErrorMsgs['wire_instructions_us_correspondent_aba_number']
        delete scope.formErrorMsgs['wire_instructions_us_correspondent_swift_code']
        delete scope.formErrorMsgs['wire_instructions_international_bank_account']
        delete(scope.formErrorMsgs['wire_instructions_us_correspondent_aba_or_swift'])

      scope.rebuildErrors = ->
        scope.sortedFormErrorMessages = []

        _.each scope.errorMessageSortOrderKeys, (k) ->
          if scope.formErrorMsgs.hasOwnProperty(k)
            scope.sortedFormErrorMessages.push(scope.formErrorMsgs[k])


      scope.addErrorMessage = (valid, error_key, message) ->
        scope.formErrorMsgs[error_key] = message if !valid
        delete scope.formErrorMsgs[error_key] if valid

      scope.bankingInformationRequired = ->
        scope.mustComplete && !scope.profileHasCustodian()

      scope.internationalIntermediaryInfoRequired = ->
        scope.mustComplete && scope.noAccountWithUsBank()

      scope.validateAccountNumber = ->
        delete scope.formErrorMsgs['account_number']
        return true if scope.noAccountWithUsBank() || scope.isHiddenField('section_b_recipient_account_number')
        value = scope.investorProfile && scope.investorProfile.section_b_recipient_account_number
        value = "" if typeof value is "undefined"
        valid = (value.search(/[0-9]+/g) > -1) || !scope.mustComplete
        scope.addErrorMessage(valid, "account_number", "Client Account Number is required.")
        return valid

      scope.validateABA = ->
        delete scope.formErrorMsgs['aba_number']
        return true if scope.noAccountWithUsBank()
        value = scope.investorProfile && scope.investorProfile.section_b_recipient_bank_aba
        value = "" if typeof value is "undefined"
        valid = (value.search(/^\d{9}$/g) > -1) || !scope.mustComplete || scope.isHiddenField('section_b_recipient_bank_aba')
        scope.addErrorMessage(valid, "aba_number", "Beneficiary bank ABA number is required.")
        return valid

      entityJurisdictionDomicileSetErrorMessage = (usOrCanadaEntryFlag,
        country_field_key,
        state_field_key,
        state_error_message,
        province_error_message) ->
          if usOrCanadaEntryFlag
            if !scope.investorProfile.hasOwnProperty(state_field_key) ||
               scope.investorProfile[state_field_key] == ''
              if scope.isUsEntityCountry(country_field_key)
                scope.addErrorMessage(false, state_field_key, state_error_message)
              else if scope.isCanadaEntityCountry(country_field_key)
                scope.addErrorMessage(false, state_field_key, province_error_message)


      validateEntityJurisdictionDomicile = ->
        delete scope.formErrorMsgs['entity_jurisdiction_of_organization_state']
        delete scope.formErrorMsgs['entity_domicile_state']

        return if !scope.isEntity() || scope.isAdminOrIr

        isJurisdictionUsOrCanadaEntry = scope.isUsEntityCountry('entity_jurisdiction_of_organization_country') ||
          scope.isCanadaEntityCountry('entity_jurisdiction_of_organization_country')

        isDomicileUsOrCanadaEntry = scope.isUsEntityCountry('entity_domicile_country') ||
          scope.isCanadaEntityCountry('entity_domicile_country')

        entityJurisdictionDomicileSetErrorMessage(isJurisdictionUsOrCanadaEntry,
          'entity_jurisdiction_of_organization_country',
          'entity_jurisdiction_of_organization_state',
          'Jurisdiction State is required.',
          'Jurisdiction of Organization Province / Territory is required.')

        entityJurisdictionDomicileSetErrorMessage(isJurisdictionUsOrCanadaEntry,
          'entity_domicile_country',
          'entity_domicile_state',
          'Domicile State is required.',
          'Domicile Province / Territory is required.')

      scope.validateAccountName = ->
        value = scope.investorProfile && scope.investorProfile.section_b_recipient_account_name
        value = "" if typeof value is "undefined"
        valid = !!value || !scope.mustComplete
        scope.addErrorMessage(valid, "account_name", "Client Account Name is required.")
        return valid

      scope.isDatePartInvalid = (date, date_field, error_msg) ->
        moment_date = moment(date, 'MMDDYYYY')

        if (typeof(date) == 'undefined' || !moment_date.isValid() || moment_date.year() < 1899)
          scope.addErrorMessage(false, date_field, error_msg)
          return true

        delete scope.formErrorMsgs[date_field]
        return false

      scope.formationdateError = ->
        scope.isDatePartInvalid(scope.formationdate.date, 'formationdate', 'Date of Formation is invalid')

      scope.checkFieldValidity = (model, error_key, message) ->
        valid = !!model
        scope.formErrorMsgs[error_key] = message if !valid && scope.mustComplete
        delete scope.formErrorMsgs[error_key] if valid
        return valid || !scope.mustComplete

      scope.validFOIAPartner = ->
        scope.checkFieldValidity(scope.investorProfile.section_a_entity_foia_partner,
          'foia_partner_classification', 'FOIA partner classification is required.')

      scope.validIndividualUSPerson = ->
        scope.checkFieldValidity(scope.investorProfile.section_a_individual_us_person,
          'section_a_individual_us_person', 'US Person is required.')

      scope.usBankSelectionSet = ->
        return true unless scope.mustComplete
        scope.investorProfile &&
          scope.bankingInformationRequired() &&
          scope.checkFieldValidity(scope.investorProfile.wire_instructions_account_with_us_bank,
          'wire_instructions_account_with_us_bank', 'Account with a U.S. bank is required.')

      scope.validEntityUSPerson = ->
        scope.checkFieldValidity(scope.investorProfile.section_a_entity_us_person,
          'section_a_entity_us_person', 'US Person is required.')

      scope.employedByAnother = ->
        scope.investorProfile.section_a_individual_employment_status == 'Employed'

      scope.selfEmployed = ->
        scope.investorProfile.section_a_individual_employment_status == 'Self Employed'

      scope.employed = ->
        scope.employedByAnother() || scope.selfEmployed()

      scope.ssnValidator = if !scope.mustComplete then "[]" else "[ssnFormat]"
      scope.phoneValidator = if !scope.mustComplete then "[]" else "[phoneFormat]"
      scope.requiredValidator = if !scope.mustComplete then "[]" else "[required]"
      scope.blankRequiredValidator = if !scope.mustComplete then "[]" else "[requiredBlurNoMessage]"

      clearNonPrimarySigners = ->
        for signer, index in scope.investorProfile.signers
          unless signer.primary_relationship
            scope.investorProfile.signers = _.without(scope.investorProfile.signers, signer)

      scope.updateTypeOfInvestor = ->
        if scope.investorProfile.section_a_individual_type == "IRA"
          scope.investorProfile.investing_in_retirement_plan = "0"
        else if scope.investorProfile.section_a_individual_type == "Individual"
          clearNonPrimarySigners()
