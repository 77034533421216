angular.module('services').factory "QualificationQuestionsService", ($resource, $http, $q) ->
  getQualificationQuestions = ->
    $http({
      url: "/api/v1/user_investor_qualification_standard_questions",
      method: "GET",
      transformResponse: (response) ->
        return response
    })

  saveQualifications = (questionIds) ->
    $http({
      url: "api/v1/investor_qualification_standard/save",
      method: "PUT",
      params: { iqs_question_ids: questionIds },
    })

  return {
    getQualificationQuestions: getQualificationQuestions,
    saveQualifications: saveQualifications,
  }
