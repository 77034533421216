angular.module("icn.paf_admin").factory 'QuarterlyReport', ($q, PafAdminSvcApi, IcnBase) ->
  class QuarterlyReport
    constructor: (config) ->
      IcnBase.setup @, config

    destroy: () ->
      PafAdminSvcApi.deleteQuarterlyReport @

  QuarterlyReport.create = (pafId, files, date) ->
      dfd = $q.defer()
      PafAdminSvcApi.createQuarterlyReport(pafId, files, date).then((res) ->
        quarterlyReport = new QuarterlyReport(res)
        dfd.resolve quarterlyReport
      , (error) -> dfd.reject(error))
      dfd.promise

  QuarterlyReport.fetch = (pafId) ->
    dfd = $q.defer()
    PafAdminSvcApi.getQuarterlyReports(pafId).then (items) ->
      dfd.resolve _.map items, (item) ->
        quarterlyReport = new QuarterlyReport(item)
    dfd.promise

  QuarterlyReport