angular.module('controllers').controller "DeleteNoteCtrl",
  ($scope, $modal, $modalInstance, selectedPaf, note, NoteService, IcnIdentity) ->
    $scope.selectedPaf = selectedPaf
    $scope.note = note

    $scope.deleteNote = ->
      NoteService.delete({ id: $scope.note.id }).$promise.then ->
        $modalInstance.close()

    $scope.dismissModal = ->
      $modalInstance.dismiss()
