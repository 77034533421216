angular.module 'icn.paf_admin.funds'
  .directive 'privateAccessFundQuarterlyReports', (QuarterlyReport, $modal) ->
    restrict: 'E'
    templateUrl: 'areas/paf_admin/tabs/pe/funds/pages/funds/components/quarterly_reports/quarterly_reports.html'
    scope:
      fund: '='
    controller: ($scope, $routeParams) ->

      $scope.dateErrors = null

      $scope.updateQuarterlyReports = ->
        QuarterlyReport.fetch($routeParams.private_access_fund_id).then (res) ->
          $scope.quarterlyReports = res

      $scope.$watch 'fund', (fund) ->
        if fund
          QuarterlyReport.fetch(fund.id).then (res) ->
            $scope.quarterlyReports = res

      $scope.uploadQuarterlyReportFile = (files) ->
        if $scope.quarterlyReport.report_date
          QuarterlyReport.create($routeParams.private_access_fund_id, files,
            $scope.quarterlyReport.report_date).then((res) ->
              if(!res.errors)
                $scope.updateQuarterlyReports()
                $scope.msg =
                  text: 'File uploaded successfully'
                  type: 'info'
              else
                $scope.msg =
                  type: 'danger'
                  errors: res.errors
          , (error) ->
            $scope.msg =
              type: 'danger'
              text: error.message
          )
          return

      $scope.deleteQuarterlyReportFile = (quarterlyReport) ->
        opts =
          templateUrl: "dialogs/custom_confirm_dialog/custom_confirm.html"
          controller: "customConfirmDialogCtrl"
          resolve:
            header: ->
              "Delete Quarterly Report"
            msg: ->
              "Are you sure you want to delete this report?"
            confirm_label: ->
              "Confirm"
            cancel_label: ->
              "Cancel"
        $modal.open(opts).result.then ->
          quarterlyReport.destroy().then (res) ->
            if(!res.errors)
              $scope.updateQuarterlyReports()
              $scope.msg =
                  text: 'File deleted successfully'
                  type: 'info'
            else
              $scope.msg =
                type: 'danger'
                errors: res.errors
