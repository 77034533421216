angular.module 'icn.paf_admin.funds'
  .controller 'PafAdminFundsCtrl', ($scope, $location, $routeParams, $q, $modal, FundSelectorService, PafAdminSvcApi,
                                    IcnUtility, IcnIdentity, AdminFundDocument, $window, Custodian, urlBuilder, ExternalInternalMappingsService) ->

    vm = @
    vm.original = null
    vm.editMode = false
    vm.close_date_for_current_call = null
    vm.fundSelector = FundSelectorService
    vm.dataToSubmit = null
    vm.fund = null
    vm.redemptionFrequencyOptions = [
      {id: 'monthly', label: 'Monthly'},
      {id: 'quarterly', label: 'Quarterly'},
      {id: 'annually', label: 'Annually'}
    ]

    Custodian.query null,  (res) ->
      vm.custodians = res

    vm.addApprovedCustodian = ->
      vm.fund.custodian_approved_pafs.push({cusip: ''})

    vm.emptySDDS = ->
      _.isEmpty(vm.fund.subscription_document_definitions_with_descriptions)

    vm.emptyInvestmentShareClasses = ->
      _.isEmpty(vm.fund.investment_share_classes)

    vm.showInvestmentShareClassSelectBox = ->
      !vm.emptyInvestmentShareClasses()

    vm.removeApprovedCustodian = (index) ->
      vm.fund.custodian_approved_pafs.splice(index, 1)

    vm.editText = ->
      return "Exit Edit Mode" if vm.editMode
      return "Edit Mode" if !vm.editMode

    vm.edit = ->
      vm.editMode = !vm.editMode
      if(!vm.editMode)
        for key of vm.original
          vm.fund[key] = vm.original[key]

    vm.save = ->
      if !vm.fund.open_ended || (!!vm.fund.bny_external_share_class_id == false && !!vm.fund.bny_external_mapping_id == false)
        saveFund()
      else
        bnyExternalMappingConditionValid()


    vm.isOpenEndedFund = ->
      if scope.fund
        scope.fund.open_ended

    vm.editable = ->
      vm.editMode && !vm.fund.closed

    vm.canEditFundCustodians = ->
      vm.editMode && IcnIdentity.user.canEditFundCustodians

    vm.onSelectedFundChanged = (fund) ->
      if fund
        vm.fund = angular.copy(fund)
        vm.original = angular.copy(fund)

    init = ->
      vm.editMode = false

    prepareDataToSubmit = ->
      vm.dataToSubmit = angular.copy(vm.fund)
      vm.dataToSubmit.paf_terms_attributes = vm.dataToSubmit.paf_terms
      vm.dataToSubmit.custodian_approved_pafs_attributes = vm.dataToSubmit.custodian_approved_pafs
      delete vm.dataToSubmit.paf_terms
      delete vm.dataToSubmit.custodian_approved_pafs


    externalInternalMappings = ->
      parameters = { id: vm.fund.bny_external_mapping_id, record_type: "Fund", record_id: vm.fund.fund_id, external_id: vm.fund.bny_external_share_class_id, context: "BNY Accounting API" }
      if vm.fund.bny_external_mapping_id && vm.fund.bny_external_share_class_id
        mapping_action = ExternalInternalMappingsService.update(parameters)
      else if vm.fund.bny_external_mapping_id && !vm.fund.bny_external_share_class_id
        mapping_action = ExternalInternalMappingsService.delete(vm.fund.bny_external_mapping_id)
      else
        mapping_action = ExternalInternalMappingsService.create(parameters)
      mapping_action.$promise.then((resp) ->
        vm.fundSelector.refreshFunds()
      ).catch((resp) ->
        $scope.errorMessage = {
          type: 'danger',
          text: 'The fund was updated, but was not possible to create/update the BNY External ID'
        }
        vm.fundSelector.refreshFunds()
      )

    saveFund = ->
      clearMessage()
      prepareDataToSubmit()
      PafAdminSvcApi.updatePaf(vm.dataToSubmit).then ->
        if vm.fund.open_ended && !(!!vm.fund.bny_external_share_class_id == false && !!vm.fund.bny_external_mapping_id == false)
          externalInternalMappings()
        else
          vm.fundSelector.refreshFunds()

    bnyExternalMappingConditionValid = ->
      ExternalInternalMappingsService.mapping_by_external_id({
        external_id: vm.fund.bny_external_share_class_id
        context: "BNY Accounting API"
      }).$promise.then((resp) ->
        existence = false
        if resp.mapping != null && vm.fund.bny_external_mapping_id != resp.mapping.id
          existence = true

        if existence
          $scope.errorMessage = {
            type: 'danger',
            text: 'BNY External ID already exist'
          }
        else
          saveFund()

      ).catch((resp) ->
        $scope.errorMessage = {
          type: 'danger',
          text: 'There was a problem checking the mapping on the server'
        }
      )

    clearMessage = ->
      $scope.errorMessage = null

    init()

    return @
