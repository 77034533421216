import OktaAuth from '@okta/okta-auth-js';

angular.module('controllers').controller "MenuCtrl", ($scope, $rootScope, $location, $window, $route, IcnIdentity, MenuAnimation, Session,
  PafAdminNavSvc, $modal, IcnUtility, Users, Delegation) ->
    $scope.menu = {}

    $scope.$on "$routeChangeStart", (next, current) ->
      $rootScope.$emit "premiumFund:tabChange"
      Session.resetSessionTimer()
      return

    $scope.user_name = null

    $scope.checkSupportForUrl = (menu_item) ->
      !menu_item.href && menu_item.name == 'Support'

    $scope.openContactUsModal = ->
      $modal.open(
        templateUrl: 'areas/layout/components/dialogs/contact_us.html',
        controller: 'ContactUsDialog',
        windowClass: 'small-modal'
      )

    $scope.logout = (e) ->
      if IcnIdentity.user.isSynchronized
        e.preventDefault();
        oktaClient = new OktaAuth({
          ...IcnIdentity.data.okta,
          tokenManager: {
            syncStorage: false,
            storage: 'sessionStorage',
          }
        });
        await oktaClient.revokeAccessToken();
        await Promise.allSettled([
          oktaClient.closeSession(),
          fetch('/simon/auth/logout', {
            credentials: 'include',
            # don't follow the redirection, just cleanup Simon session
            redirect: 'manual',
          })
        ]);
        redirectRoute('/logout')

    $scope.subMenuInvisible = false

    $scope.hideSubMenu = ->
      $scope.subMenuInvisible = true

    $scope.showSubMenu = ->
      $scope.subMenuInvisible = false


    prependPartnerProxyAPIPrefixToReactLinks = (main_menu) ->
      Object.keys(main_menu).forEach (k) ->
        if Array.isArray(main_menu[k])
          links = main_menu[k]
          links.forEach (item) ->
            if item.href.match(/icn_react/)
              if IcnUtility.hasPartnerProxyAPIPrefix()
                item.href = IcnUtility.getPartnerProxyAPIPrefix() + item.href
            return
        return

    setMainMenu = (d) ->
      if IcnUtility.hasPartnerProxyAPIPrefix()
        prependPartnerProxyAPIPrefixToReactLinks(d.main_menu)

      $scope.menu.main_menu = d.main_menu

      if IcnIdentity.isInvestorRelations() && PafAdminNavSvc.navItems('investor_relations').length > 0
        ir_link = PafAdminNavSvc.navItems('investor_relations')[0]['url']

        index = _.find($scope.menu.main_menu['admin'], (item) ->
          item['name'] == 'Investor Relations'
        )

        index['href'] = ir_link if index

    $scope.setScopeAndRedirect = (d) ->
      $scope.menu.user_menu = d.user_menu
      if (d.user?) and (d.user isnt "")
        setMainMenu(d)
        $scope.user_name = d.user.name
        $rootScope.user = d.user
        $rootScope.feature_flags = d.feature_flags
        $rootScope.first_run = d.user.first_run
      $scope.is_white_label = d.white_label_partner.id != 0
      return

    $scope.pendingMfaRegistration = IcnIdentity.pendingMfaRegistration()
    $scope.canToggleDomains = IcnIdentity.user.canDomainToggle

    if $scope.pendingMfaRegistration
      $scope.IcnIdentity = IcnIdentity

      mfaRegistrationWatch = $scope.$watch("IcnIdentity.pendingMfaRegistration()", (registrationPending) ->
        if !registrationPending
          $scope.pendingMfaRegistration = registrationPending
          mfaRegistrationWatch()
      , true)

    $scope.userCanImpersonate = ->
      IcnIdentity.user.canImpersonate || (IcnIdentity.user.canDelegate && !IcnIdentity.user.canInvestorRepresentation)

    determineImpersonateButtonBehavior = ->
      impersonateButtonBehavior = { text: '', action: -> undefined }

      if IcnIdentity.user && IcnIdentity.user.canImpersonate
        impersonateButtonBehavior.text = "IMPERSONATE"
        impersonateButtonBehavior.action = $scope.redirectToImpersonateRoute

        if IcnIdentity.user.canDelegate
          impersonateButtonBehavior.text = impersonateButtonBehavior.text + "/DELEGATE"
      else if IcnIdentity.user && IcnIdentity.user.canDelegate
        impersonateButtonBehavior.text = "ACT AS DELEGATE"
        impersonateButtonBehavior.action = $scope.impersonateDefaultUser
      impersonateButtonBehavior

    $scope.redirectToImpersonateRoute = ->
      reloadRoute('/icn_react/static/impersonation')

    $scope.impersonateDefaultUser = ->
      Delegation.getDelegators().then (response) ->
        if response.delegators.length >= 1
          defaultUserIdToImpersonate = response.delegators[0].delegator_user_id

          Users.impersonate(defaultUserIdToImpersonate).then (response) ->
            reloadRoute('/')

    $scope.impersonateButtonState = ->
      if $location.path() == '/icn_react/static/impersonation'
        'active'
      else
        'inactive'

    determineSsoReturnButtonBehavior = ->
      ssoReturnButtonBehavior = { name: "", action: -> undefined }
      ssoReturnButtonBehavior.name = $scope.navReturnName
      ssoReturnButtonBehavior.action = $scope.redirectToSso
      ssoReturnButtonBehavior

    if IcnIdentity.user
      $scope.navReturnName = IcnIdentity.user.navReturnName
      $scope.navReturnUrl = IcnIdentity.user.navReturnUrl
      $scope.ssoPartnerLogin = $scope.navReturnName && $scope.navReturnUrl
      $scope.redirectToSso = -> redirectRoute($scope.navReturnUrl)

    reloadRoute = (path) ->
      $window.location.href = path
      $location.path(path)
      $route.reload()

    redirectRoute = (path) ->
      $window.location.href = path

    $rootScope.first_run = {}
    $scope.user_name = null
    $rootScope.user = null
    $scope.menu = {}
    $scope.impersonateButtonBehavior = determineImpersonateButtonBehavior()
    $scope.ssoReturnButtonBehavior = determineSsoReturnButtonBehavior()
    $scope.setScopeAndRedirect IcnIdentity.data
    setTimeout(MenuAnimation.initiate, 0)
