angular.module('directives').directive 'incidentStatusAlertBar', ->
  restrict: 'E',
  templateUrl: 'components/incident_status_alerts/bar.html',
  scope: { status: '@', alerts: '=' },
  bindToController: true,
  controllerAs: '$ctrl',
  controller: ($scope) ->
    $ctrl = this

    angular.extend $ctrl,
      resolved: resolved = $ctrl.status == 'resolved',

      cancel: ->
        try
          delete $ctrl.openAlerts

          alertsData = JSON.parse localStorage.icnIncidentStatusAlert || '{}'
          $ctrl.alerts.forEach ({ id, title }) ->
            alertsData["#{title}-#{id}"] = "#{$ctrl.status} seen"
          localStorage.icnIncidentStatusAlert = JSON.stringify alertsData
        catch
          # either localStorage is disabled for iCapital or we exceeded our quota

    $scope.$watch ->
      $ctrl.alerts
    , ->
      try
        alertsData = JSON.parse localStorage.icnIncidentStatusAlert || '{}'
        openAlerts = $ctrl.alerts.filter ({ id, title }) ->
          alertData = alertsData["#{title}-#{id}"]
          alertData != "#{$ctrl.status} seen"
        $ctrl.openAlerts = openAlerts
      catch
        # either localStorage is disabled for iCapital or we exceeded our quota
