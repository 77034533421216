angular.module('directives').directive 'icnPasswordSteps', ($validator, CustomPageTemplateService, IcnIdentity, ToolTips) ->
  restrict: 'E'
  templateUrl: 'areas/register/register/components/icn_registration/templates/icn_password_steps.html'
  scope:
    showtext: "="
    user: "="
    registerpage: "="

  link: (scope) ->
    scope.showWarning = !IcnIdentity.isWhiteLabel() && scope.showtext
    user = scope.user
    scope.toolTips = ToolTips

    scope.atLeastTen = ->
      if user.password && user.password.length >= 10
        'password-step-completed'

    scope.anUppercase = ->
      if user.password && /^(?=.*[A-Z]).+$/.test(user.password)
        'password-step-completed'

    scope.aLowercase = ->
      if user.password && /^(?=.*[a-z]).+$/.test(user.password)
        'password-step-completed'

    scope.oneDigit = ->
      if user.password && /^(?=.*\d).+$/.test(user.password)
        'password-step-completed'

    scope.oneSpecialChar = ->
      regex = /^(?=^.*[\~!@#$%^&*_\-\+=\`:;"\'<>,.?\/\[\]{}()])[a-zA-Z\d\~!@#$%^&*_\-\+=\`:;\"\'<>,.?\/\[\]{}()]+$/
      if user.password && regex.test(user.password)
        'password-step-completed'
