angular.module('controllers').controller "EditInvestment",
  ($scope, $modalInstance, $routeParams, IcnIdentity, investment,
  InvestmentService, paf, LibraryDocumentCache, pafSeries, NetFeeAgreementService,
  PlacementFeeRangeSelector) ->

    init = ->
      LibraryDocumentCache.fetchInvestmentDocs(investment.id).then (subBooks) ->
        $scope.subBooks = subBooks
        _.each(subBooks, (sb) ->
          if sb.id == $scope.investment.private_access_fund_echosign_library_document_id
            $scope.subDocTypeUpdated = sb
        )

      _.each(pafSeries, (s) ->
        if s.name == $scope.investment.series_name
          $scope.seriesNameUpdated = {
            name: $scope.investment.series_name
          }
      )

    $scope.paf = paf
    $scope.investment = investment
    $scope.pafSeries = pafSeries
    $scope.placementFeeRange =
      PlacementFeeRangeSelector.forAmount($scope.investment, $scope.investment.amount) || {}
    $scope.allowedPlacementFees = $scope.placementFeeRange.allowed_placement_fees

    $scope.ubsDevelopment = IcnIdentity.whiteLabelPartner()?.slug == 'ubs'
    $scope.placementFeeRange.max_placement_fee =
      if $scope.ubsDevelopment then 2 else $scope.placementFeeRange?.max_placement_fee

    $scope.canEditSubAdvisoryFee = ->
      !$scope.ubsDevelopment &&
        $scope.investment.ria_firm &&
          (
            $scope.investment.firm_fee_agreement.max_sub_advisory_fee ||
            $scope.investment.firm_fee_agreement.min_sub_advisory_fee
          )

    $scope.hasPlacementFees = ->
      PlacementFeeRangeSelector.hasPlacementFee($scope.investment)

    $scope.canEditPlacementFee = ->
      !$scope.canEditSubAdvisoryFee() &&
        ($scope.ubsDevelopment && $scope.investment.placement_fee) ||
          (
            $scope.investment.sub_doc_type_name == 'Brokerage' &&
            $scope.investment.ria_firm && $scope.hasPlacementFees()
          )

    $scope.seriesNameUpdate = (seriesNameUpdated) ->
      $scope.seriesNameUpdated = seriesNameUpdated
      $scope.investment.private_access_fund_series_id = seriesNameUpdated?.id

    $scope.subDocTypeUpdate = (subDocTypeUpdated) ->
      $scope.subDocTypeUpdated = subDocTypeUpdated
      $scope.pafSubBookId = subDocTypeUpdated?.id

    $scope.disableUpdateInvestmentButton = ->
      return false unless $scope.canEditPlacementFee() || $scope.canEditSubAdvisoryFee()
      $scope.canEditPlacementFee() && !$scope.validPlacementFee() ||
        $scope.canEditSubAdvisoryFee() && !$scope.validSubAdvisoryFee()

    determinePlacementFee = ->
      return $scope.investment.placement_fee if $scope.investment.placement_fee
      return $scope.placementFeeRange.allowed_placement_fees[0] if $scope.placementFeeRange.
      allowedPlacementFees?.lenght > 0

      if $scope.placementFeeRange.max_placement_fee &&
         $scope.placementFeeRange.max_placement_fee == $scope.placementFeeRange.min_placement_fee
        $scope.placementFeeRange.max_placement_fee
      else
        0.0

    determineSubAdvisoryFee = ->
      if $scope.investment.sub_advisory_fee then $scope.investment.sub_advisory_fee else 0.0

    $scope.placementForm = {
      placement_fee: determinePlacementFee(),
      sub_advisory_fee: determineSubAdvisoryFee()
    }

    $scope.withAllowedPlacementFees =
      $scope.placementFeeRange.allowed_placement_fees?.length > 0

    $scope.mandatoryPlacementFeeChanged = ->
      $scope.placementFeeRange.max_placement_fee &&
        $scope.placementFeeRange.max_placement_fee == $scope.placementFeeRange.min_placement_fee &&
        $scope.placementForm.placement_fee != $scope.placementFeeRange.min_placement_fee

    $scope.placementFeeInsideBounds = ->
      $scope.placementForm.placement_fee >= 0 &&
        !$scope.placementFeeOverMaxValue() && !$scope.placementFeeUnderMinValue()

    subAdvisoryFeeInsideBounds = ->
      $scope.placementForm.sub_advisory_fee >= 0 &&
        !$scope.subAdvisoryFeeOverMaxValue() && !$scope.subAdvisoryFeeUnderMinValue()

    validWithBoundsOrAllowedPlacementFees = ->
      ($scope.placementFeeInsideBounds() && !$scope.mandatoryPlacementFeeChanged()) || $scope.withAllowedPlacementFees

    $scope.validPlacementFee = ->
      $scope.placementForm.placement_fee != null && ($scope.ubsDevelopment || validWithBoundsOrAllowedPlacementFees())

    $scope.validSubAdvisoryFee = ->
      $scope.placementForm.sub_advisory_fee != null && ($scope.ubsDevelopment || subAdvisoryFeeInsideBounds())

    $scope.placementFeeOverMaxValue = ->
      $scope.placementFeeRange.max_placement_fee &&
        $scope.placementForm.placement_fee > $scope.placementFeeRange.max_placement_fee

    $scope.placementFeeUnderMinValue = ->
      $scope.placementFeeRange.min_placement_fee &&
        $scope.placementForm.placement_fee < $scope.placementFeeRange.min_placement_fee

    $scope.subAdvisoryFeeOverMaxValue = ->
      $scope.investment.firm_fee_agreement.max_sub_advisory_fee &&
        $scope.placementForm.sub_advisory_fee > $scope.investment.firm_fee_agreement.max_sub_advisory_fee

    $scope.subAdvisoryFeeUnderMinValue = ->
      $scope.investment.firm_fee_agreement.min_sub_advisory_fee &&
        $scope.placementForm.sub_advisory_fee < $scope.investment.firm_fee_agreement.min_sub_advisory_fee

    updatePlacementFee = ->
      if NetFeeAgreementService.isNetFeeAgreement($scope.investment)
        updatedCommitment = NetFeeAgreementService.applyNetFee(
          $scope.investment.commitment,
          $scope.placementForm.placement_fee,
          $scope.investment.placement_fee
        )
        $scope.investment.commitment = updatedCommitment
        $scope.investment.amount = updatedCommitment
        if updatedCommitment
          $scope.investment.has_net_commitment = true
        InvestmentService.setCommitmentAmount($scope.investment.private_access_fund_id, $scope.investment)

      InvestmentService.setPlacementFee($scope.investment, $scope.placementForm.placement_fee).then (res) ->
        $scope.investment.placement_fee = $scope.placementForm.placement_fee
      , (res) ->
        $scope.message = {
          type: 'danger',
          errors: res.data.errors
        }

    updateSubAdvisoryFee = ->
      InvestmentService.setSubAdvisoryFee($scope.investment, $scope.placementForm.sub_advisory_fee).then (res) ->
        $scope.investment.sub_advisory_fee = $scope.placementForm.sub_advisory_fee
      , (res) ->
        $scope.message = {
          type: 'danger',
          errors: res.data.errors
        }


    updatePafSeries = ->
      InvestmentService.setClassOfInterests(paf.id, investment).then (res) ->
        $scope.investment.series_name = res.investment.series_name

    updateSubBooks = ->
      InvestmentService.setSubBooks(paf.id, investment, $scope.pafSubBookId).then (res) ->
        $scope.investment.sub_doc_type_name = res.simplified_sub_doc_type_name
        $scope.investment.private_access_fund_echosign_library_document_id = res.private_access_fund_echosign_library_document_id

    $scope.dismissModal = ->
      $modalInstance.dismiss()

    canUpdatePlacementFee = ->
      $scope.validPlacementFee() && $scope.placementForm?.placement_fee != $scope.investment.placement_fee

    canUpdateSubAdvisoryFee = ->
      $scope.validSubAdvisoryFee() && $scope.placementForm?.sub_advisory_fee != $scope.investment.sub_advisory_fee

    canUpdatePafSeries = ->
      $scope.seriesNameUpdated && $scope.seriesNameUpdated?.name != $scope.investment.series_name

    canUpdateSubBooks = ->
      $scope.subDocTypeUpdated?.id != $scope.investment.private_access_fund_echosign_library_document_id

    $scope.updateInvestment = ->
      updatePlacementFee() if $scope.canEditPlacementFee() && canUpdatePlacementFee()
      updateSubAdvisoryFee() if $scope.canEditSubAdvisoryFee() && canUpdateSubAdvisoryFee()
      updatePafSeries() if canUpdatePafSeries()
      updateSubBooks() if canUpdateSubBooks()
      $modalInstance.close()

    init()
