angular.module('services').service "FormDataService", ->
  {
    types_of_investor: [
      'Individual',
      'Joint Account: Tenants in Common',
      'Joint Account: Rights of Survivorship',
      'Joint Account: Community Property',
      'Joint Account: Tenants by Entirety',
      'IRA',
      'SEP IRA',
    ],

    types_of_entity: [
      'Partnership',
      'Public Pension',
      'Corporation',
      'S-Corporation',
      'Estate',
      'Non-Grantor Trust',
      'Grantor Trust',
      'Exempt Organization',
      'LLP',
      'LLC',
      'Nominee-EIN',
      'Nominee-SSN',
      'Profit Sharing Plan',
      'Non-Profit Organization',
      'Disregarded Entity',
      '401(k)',
      'Bank or Corporate Trustee',
      'Other',
    ]

    titlesOfSignatory: [
      'Chief Investment Officer',
      'Director',
      'Executive Officer',
      'General Partner',
      'Manager',
      'Partner/Member',
      'Principal',
      'Trustee',
      'Other Authorized Signatory'
    ]

    publiclyTradedExchangeNames: [
      'Deutsche Borse',
      'Euronext',
      'Hong Kong Stock Exchange',
      'Japan Exchange Group',
      'London Stock Exchange Group',
      'NASDAQ',
      'New York Stock Exchange',
      'Shanghai Stock Exchange',
      'Shenzhen Stock Exchange',
      'Toronto Stock Exchange',
      'Other (please specify below)'
    ]

    assetsOptions:
      [
        {
          value: "Less than $500,000"
        }
        {
          value: "$500,000 - $999,999"
        }
        {
          value: "$1,000,000 - $4,999,999"
        }
        {
          value: "$5,000,000 - $9,999,999"
        }
        {
          value: "$10,000,000 or greater"
        }
      ]
    employmentStatuses:
      _.sortBy [
        {
          status: "Employed"
        }
        {
          status: "Unemployed"
        }
        {
          status: "Retired"
        }
        {
          status: "Self Employed"
        }
      ], "status"

    incomeOptions:
      [
        {
          value: "Less than $200,000"
        }
        {
          value: "$200,000 - $299,999"
        }
        {
          value: "$300,000 - $499,999"
        }
        {
          value: "$500,000 or greater"
        }
      ]

    investmentOptions:
      [
        {
          value: "Less than $5,000,000"
        }
        {
          value: "$5,000,000 - $10,000,000"
        }
        {
          value: "More than $10,000,000"
        }
      ]

    canadaProvinces:
      _.sortBy [
        {
          name: "Alberta"
        }
        {
          name: "British Columbia"
        }
        {
          name: "Manitoba"
        }
        {
          name: "New Brunswick"
        }
        {
          name: "Newfoundland and Labrador"
        }
        {
          name: "Northwest Territories"
        }
        {
          name: "Nova Scotia"
        }
        {
          name: "Nunavut"
        }
        {
          name: "Ontario"
        }
        {
          name: "Prince Edward Island"
        }
        {
          name: "Quebec"
        }
        {
          name: "Saskatchewan"
        }
        {
          name: "Yukon"
        }
      ], "name"

    usStates:
      _.sortBy [
        {
          name: "ALABAMA"
          abbreviation: "AL"
        }
        {
          name: "ALASKA"
          abbreviation: "AK"
        }
        {
          name: "AMERICAN SAMOA"
          abbreviation: "AS"
        }
        {
          name: "ARIZONA"
          abbreviation: "AZ"
        }
        {
          name: "ARKANSAS"
          abbreviation: "AR"
        }
        {
          name: "CALIFORNIA"
          abbreviation: "CA"
        }
        {
          name: "COLORADO"
          abbreviation: "CO"
        }
        {
          name: "CONNECTICUT"
          abbreviation: "CT"
        }
        {
          name: "DELAWARE"
          abbreviation: "DE"
        }
        {
          name: "DISTRICT OF COLUMBIA"
          abbreviation: "DC"
        }
        {
          name: "FEDERATED STATES OF MICRONESIA"
          abbreviation: "FM"
        }
        {
          name: "FLORIDA"
          abbreviation: "FL"
        }
        {
          name: "GEORGIA"
          abbreviation: "GA"
        }
        {
          name: "GUAM"
          abbreviation: "GU"
        }
        {
          name: "HAWAII"
          abbreviation: "HI"
        }
        {
          name: "IDAHO"
          abbreviation: "ID"
        }
        {
          name: "ILLINOIS"
          abbreviation: "IL"
        }
        {
          name: "INDIANA"
          abbreviation: "IN"
        }
        {
          name: "IOWA"
          abbreviation: "IA"
        }
        {
          name: "KANSAS"
          abbreviation: "KS"
        }
        {
          name: "KENTUCKY"
          abbreviation: "KY"
        }
        {
          name: "LOUISIANA"
          abbreviation: "LA"
        }
        {
          name: "MAINE"
          abbreviation: "ME"
        }
        {
          name: "MARSHALL ISLANDS"
          abbreviation: "MH"
        }
        {
          name: "MARYLAND"
          abbreviation: "MD"
        }
        {
          name: "MASSACHUSETTS"
          abbreviation: "MA"
        }
        {
          name: "MICHIGAN"
          abbreviation: "MI"
        }
        {
          name: "MINNESOTA"
          abbreviation: "MN"
        }
        {
          name: "MISSISSIPPI"
          abbreviation: "MS"
        }
        {
          name: "MISSOURI"
          abbreviation: "MO"
        }
        {
          name: "MONTANA"
          abbreviation: "MT"
        }
        {
          name: "NEBRASKA"
          abbreviation: "NE"
        }
        {
          name: "NEVADA"
          abbreviation: "NV"
        }
        {
          name: "NEW HAMPSHIRE"
          abbreviation: "NH"
        }
        {
          name: "NEW JERSEY"
          abbreviation: "NJ"
        }
        {
          name: "NEW MEXICO"
          abbreviation: "NM"
        }
        {
          name: "NEW YORK"
          abbreviation: "NY"
        }
        {
          name: "NORTH CAROLINA"
          abbreviation: "NC"
        }
        {
          name: "NORTH DAKOTA"
          abbreviation: "ND"
        }
        {
          name: "NORTHERN MARIANA ISLANDS"
          abbreviation: "MP"
        }
        {
          name: "OHIO"
          abbreviation: "OH"
        }
        {
          name: "OKLAHOMA"
          abbreviation: "OK"
        }
        {
          name: "OREGON"
          abbreviation: "OR"
        }
        {
          name: "PALAU"
          abbreviation: "PW"
        }
        {
          name: "PENNSYLVANIA"
          abbreviation: "PA"
        }
        {
          name: "PUERTO RICO"
          abbreviation: "PR"
        }
        {
          name: "RHODE ISLAND"
          abbreviation: "RI"
        }
        {
          name: "SOUTH CAROLINA"
          abbreviation: "SC"
        }
        {
          name: "SOUTH DAKOTA"
          abbreviation: "SD"
        }
        {
          name: "TENNESSEE"
          abbreviation: "TN"
        }
        {
          name: "TEXAS"
          abbreviation: "TX"
        }
        {
          name: "UTAH"
          abbreviation: "UT"
        }
        {
          name: "VERMONT"
          abbreviation: "VT"
        }
        {
          name: "VIRGIN ISLANDS"
          abbreviation: "VI"
        }
        {
          name: "VIRGINIA"
          abbreviation: "VA"
        }
        {
          name: "WASHINGTON"
          abbreviation: "WA"
        }
        {
          name: "WEST VIRGINIA"
          abbreviation: "WV"
        }
        {
          name: "WISCONSIN"
          abbreviation: "WI"
        }
        {
          name: "WYOMING"
          abbreviation: "WY"
        }
      ], "abbreviation"

    form_pf_classifications: [
      {
        key: 'us_individuals',
        label: '(a) Individuals that are United States persons (including their trusts)'
      },
      {
        key: 'non_us_individuals',
        label: '(b) Individuals that are not United States persons (including their trusts)' }
      {
        key: 'broker_dealers',
        label: '(c) Broker-dealers'
      },
      {
        key: 'insurance_companies',
        label: '(d) Insurance companies'
      },
      {
        key: 'investment_companies',
        label: '(e) Investment companies registered with the SEC'
      },
      {
        key: 'private_funds',
        label: '(f) Private funds'
      },
      {
        key: 'non_profits',
        label: '(g) Non-profits'
      },
      {
        key: 'pension_plans',
        label: '(h) Pension plans (excluding governmental pension plans)'
      },
      {
        key: 'banking_institutions',
        label: '(i) Banking or thrift institutions (proprietary)'
      },
      {
        key: 'state_entities',
        label: '(j) State or municipal government entities (excluding governmental pension plans)'
      },
      {
        key: 'state_pension_plans',
        label: '(k) State or municipal governmental pension plans'
      },
      {
        key: 'sovereign_wealth_funds',
        label: '(l) Sovereign wealth funds and foreign official institutions'
      },
      {
        key: 'not_known',
        label: '(m) Investors that are not United States persons and about which the foregoing
                  beneficial ownership information is not known and cannot reasonably be obtained
                  because the beneficial interest is held through a chain involving one or more thirdparty
                  intermediaries'
      },
      {
        key: 'other',
        label: '(n) Other'
      }
    ]
  }
