angular.module('services').factory "IntegrationPartnerService",
  ($resource, $q, $upload) ->
    IntegrationPartnerService = ->
      $resource("/api/v1/integration_partners", {},
        forPaf:
          url: '/api/v1/integration_partners/for_paf/:privateAccessFundId'
          method: 'GET'
          privateAccessFundId: "@privateAccessFundId"
      )
    return new IntegrationPartnerService()


