angular.module('services').factory "ModalDialogInstancesObserver", [() ->
    # modal instances recorder array
    instances = []

    # retrive current active modal instances
    _getModalDialogInstance = () ->
      return instances

    # add one more active modal instance
    _setModalDialogInstance = (instance) ->
      instances.push(instance)
      undefined

    # clear all records
    _clearAllModalDialogInstances = () ->
      instances = []

    return (
      setModalDialogInstance: _setModalDialogInstance
      getModalDialogInstance: _getModalDialogInstance
      clearAllModalDialogInstances: _clearAllModalDialogInstances
    )
]
