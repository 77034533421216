angular.module('controllers').controller "SetHoldBackWireDateCtrl",
  ($scope, $modalInstance, Redemptions, redemption, pafId) ->
    $scope.cancelDialog = -> $modalInstance.dismiss()

    $scope.redemption = jQuery.extend({}, redemption)

    $scope.dateOptions = {
      dateFormat: "mm/dd/yy"
    }

    $scope.save = ->
      Redemptions.update(pafId, $scope.redemption).then( ->
        $modalInstance.close()
      , (response) ->
        $modalInstance.dismiss(response.data)
      )
