angular.module('controllers').controller "sendRemediationNotificationDialogCtrl",
  ($scope, $modalInstance, ModalDialogInstancesObserver, header, data, confirm_label, cancel_label) ->

    $scope.header = (if (angular.isDefined(header)) then header else "Confirmation")
    $scope.data = (if (angular.isDefined(data)) then data else "Confirmation required.")
    $scope.confirm_label = (if (angular.isDefined(confirm_label)) then confirm_label else "Yes")
    $scope.cancel_label = (if (angular.isDefined(cancel_label)) then cancel_label else "No")
    $scope.delegates_text = (if !!data.is_delegate_blacklisted then " and the delegate(s)" else "")
    $scope.representatives_text = (if !!data.is_representative_blacklisted then " and the representatives(s)" else "")
    $scope.helper_text = (if !!data.is_representative_blacklisted || !!data.is_delegate_blacklisted then " have" else " has")

    $scope.no = ->
      $modalInstance.dismiss "no"
    $scope.yes = ->
      $modalInstance.close "yes"

    # dismiss modal dialog box when navigate to other page
    ModalDialogInstancesObserver.setModalDialogInstance($modalInstance)
