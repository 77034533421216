angular.module('services').factory "NewIssuesProfileService", [
  "$resource", "$q"
  ($resource, $q) ->
    resource = $resource("/api/v1/new_issues_profiles/:id",
      id: "@id"
    ,

      questionGroups:
        method: "GET"
        url: "/api/v1/new_issues_profiles/question_groups"
    )

    questionGroups = ->
      resource.questionGroups()

    return {
      questionGroups: questionGroups
    }
]
