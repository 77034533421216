angular.module('services').factory "ExternalInternalMappingsService", ($resource, $upload) ->
  resource = $resource "/api/v1/external_internal_mappings/:id", { id: "@id" },
    create:
      url: '/api/v1/external_internal_mappings'
      method: 'POST'

    update:
      url: '/api/v1/external_internal_mappings/:id'
      method: 'PATCH'

    mapping_by_external_id:
      url: '/api/v1/external_internal_mappings/mapping_by_external_id'
      method: 'GET'

  create = (params) ->
    resource.create(params)

  update = (params) ->
    resource.update(params)

  mapping_by_external_id = (params) ->
    resource.mapping_by_external_id(params)

  delete_mapping = (mapping_id) ->
    resource.delete({id: mapping_id})

  {
    create: create
    update: update
    mapping_by_external_id: mapping_by_external_id
    delete: delete_mapping
  }
